import api from 'services/api';
import {
  SupportTicketList,
  SupportTicketPriority,
  SupportTicketResponse,
  SupportTicketStatus,
  SupportTicketType,
} from 'models/supportTickets.model';
import { ListResponseComplete } from 'models/general.model';
import { SupportTicketStatusType } from 'core/domain/supportTickets/models/supportTicketsPayloadModel';

export const getSupportTicket = async (techSupportId: string): Promise<SupportTicketResponse> => {
  return await api.get(`/support-tickets/${techSupportId}`);
};

interface GetSupportTicketsOptions {
  assetId?: string;
  pageNumber?: number;
  pageSize?: number;
  priority?: SupportTicketPriority;
  sort?: string;
  status?: SupportTicketStatus;
  type?: SupportTicketType;
}

export const getSupportTickets = async (options?: GetSupportTicketsOptions): Promise<ListResponseComplete<SupportTicketList>> => {
  const params = new URLSearchParams();
  if (options?.assetId) params.append('asset_id', `${options.assetId}`);
  if (options?.pageNumber) params.append('page[number]', `${options.pageNumber}`);
  if (options?.pageSize) params.append('page[size]', `${options.pageSize}`);
  if (options?.priority) params.append('priority', `${options.priority}`);
  if (options?.sort) params.append('page[sort]', `${options.sort}`);
  if (options?.status) params.append('status', `${options.status}`);
  if (options?.type) params.append('type', `${options.type}`);

  api.defaults.headers.common['Accept-version'] = '2';
  const response: any = await api.get('/support-tickets', { params });
  api.defaults.headers.common['Accept-version'] = '1';
  return response;
};

export const updateSupportTicketStatus = async (
  techSupportId: string,
  newData: { status: SupportTicketStatusType; description: string }
): Promise<void> => {
  const transformedStatus = newData.status.toLowerCase();
  const data = { ...newData, status: transformedStatus };

  return await api.patch(`/support-tickets/${techSupportId}`, {
    data,
  });
};
