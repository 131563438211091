import { DEFAULT_PAGE_SIZE } from 'components/template/AlfredTableTemplate';
import {
  CheckInBasicDataModel,
  CheckInDetailResponseModel,
  CheckInDetailResponsePayload,
  CheckInHistoryAssetModel,
  CheckInHistoryAssetPayload,
  CheckInHistoryDataModel,
  CheckInHistoryDataPayload,
  CheckInHistoryLinksModel,
  CheckInHistoryLinksPayload,
  CheckInHistoryMetaModel,
  CheckInHistoryMetaPayload,
  CheckInHistoryResponseModel,
  CheckInHistoryResponsePayload,
  CheckInResponseModel,
  CheckInResponsePayload,
  CheckInRoomModel,
  CheckInRoomPayload,
} from '../models';
import { decryptFromBase64 } from 'utils/base64';

export const checkInRoomToPayload = ({ attributes }: CheckInRoomModel): CheckInRoomPayload => {
  const { validFrom, validUntil, codes, emails, forceSaveMode, assetId } = attributes;
  const digital = emails
    .map((email) => ({ email }))
    .filter((item) => !!item.email);

  const nfc = codes.map(code => ({ code }));
  return {
    attributes: {
      valid_from: validFrom,
      valid_until: validUntil,
      access_keys: {
        nfc,
        digital,
      },
      allocations: [{ asset_id: assetId }],
      force_save_mode: forceSaveMode,
    }
  }
}

const payloadToCheckInRoomDetail = ({ user_email, access_id }: CheckInDetailResponsePayload): CheckInDetailResponseModel => {
  return {
    accessId: access_id ?? '',
    userEmail: user_email ?? '',
  };
};

export const payloadToCheckInRoom = ({ access, reservation_id }: CheckInResponsePayload): CheckInResponseModel => {
  const transformedAccess = access.map((item) => payloadToCheckInRoomDetail(item));

  return {
    access: transformedAccess ?? [],
    reservationId: reservation_id ?? '',
  };
};

const payloadToCheckInHistoryDataAsset = ({ alias, asset_id, type }: CheckInHistoryAssetPayload): CheckInHistoryAssetModel => {
  return {
    alias: alias ?? '',
    assetId: asset_id ?? '',
    type: type ?? '',
  };
};

const ID_CONCAT_ACCESS_SENSOR = '######';
const NUMBER_OF_ITEMS_NFC_PASS_CODE = 2;
const INDEX_ITEM_NFC_PASS_CODE = 1;

const decryptNfcCodes = (nfcCodes: string[]): string[] => {
  return nfcCodes.map(uuidNfcEncoded => decryptFromBase64({
          uuidNfcEncoded,
          idConcat: ID_CONCAT_ACCESS_SENSOR,
          itemIndex: INDEX_ITEM_NFC_PASS_CODE,
          numberOfItems: NUMBER_OF_ITEMS_NFC_PASS_CODE,
  }))
}

const payloadToCheckInHistoryData = ({
  assets,
  created_at,
  id,
  is_digital,
  is_nfc,
  nfc_codes,
  reservation_id,
  state,
  url,
  valid_from,
  valid_until,
}: CheckInHistoryDataPayload): CheckInHistoryDataModel => {
  const transformedAssets = assets.map((item) => payloadToCheckInHistoryDataAsset(item)) ?? [];

  return {
    assets: transformedAssets,
    createdAt: created_at ?? 0,
    id: id ?? '',
    isDigital: is_digital ?? true,
    isNfc: is_nfc ?? false,
    nfcCodes: nfc_codes ? decryptNfcCodes(nfc_codes) : [],
    reservationId: reservation_id ?? '',
    state: state ?? '',
    url: url ?? '',
    validFrom: valid_from ?? 0,
    validUntil: valid_until ?? 0,
  };
};

const payloadToCheckInHistoryMeta = ({
  all_results,
  page_size,
  results,
  total_pages,
}: CheckInHistoryMetaPayload): CheckInHistoryMetaModel => {
  return {
    allResults: all_results ?? 0,
    pageSize: page_size ?? DEFAULT_PAGE_SIZE,
    results: results ?? 0,
    totalPages: total_pages ?? 1,
  };
};

const payloadToCheckInHistoryLinks = ({ first, last, next, prev, self }: CheckInHistoryLinksPayload): CheckInHistoryLinksModel => {
  return {
    first: first ?? '',
    last: last ?? '',
    next: next ?? '',
    prev: prev ?? '',
    self: self ?? '',
  };
};

export const payloadToCheckInHistoryList = ({ data, meta, links }: CheckInHistoryResponsePayload): CheckInHistoryResponseModel => {
  const transformedData = data.map((item) => payloadToCheckInHistoryData(item));
  const transformedMeta = payloadToCheckInHistoryMeta(meta);
  const transformedLinks = payloadToCheckInHistoryLinks(links);

  return {
    data: transformedData,
    meta: transformedMeta,
    links: transformedLinks,
  };
};

export const checkInHistoryDataToCheckInBasicDataModel = (data: CheckInHistoryDataModel[]): CheckInBasicDataModel[] => {
  return data.map(({ assets, ...rest }) => ({
    rooms: assets, ...rest
  }))
}
