import { FC } from 'react';
import Error from 'assets/svg/icon/Error';
import './styles.scss';

interface Props {
  title: string;
  dataSubtitle: string;
  totalGatewaysText: string;
  chartSubtitle: string;
  chartErrorTitle: string;
  chartErrorDescription: string;
}

const DashboardActiveGatewaysErrorTemplate: FC<Props> = ({
  title,
  dataSubtitle,
  totalGatewaysText,
  chartSubtitle,
  chartErrorTitle,
  chartErrorDescription,
}) => {
  return (
    <div className={'DashboardActiveGatewaysErrorTemplate__wrapper'}>
      <div className={'DashboardActiveGatewaysErrorTemplate__data'}>
        <div className='DashboardActiveGatewaysErrorTemplate__data-header'>
          <p className='DashboardActiveGatewaysErrorTemplate__title'>{title}</p>
          <p className='DashboardActiveGatewaysErrorTemplate__subtitle'>{dataSubtitle}</p>
        </div>
        <div className='DashboardActiveGatewaysErrorTemplate__data-content'>
          <p className='DashboardActiveGatewaysErrorTemplate__data-content-info'>{totalGatewaysText}</p>
          <p className='DashboardActiveGatewaysErrorTemplate__data-content-info-value'>-</p>
        </div>
      </div>
      <div className='DashboardActiveGatewaysErrorTemplate__chart'>
        <div className='DashboardActiveGatewaysErrorTemplate__chart-header'>
          <p className='UsersErrorTemplate__subtitle'>{chartSubtitle}</p>
        </div>
        <div className='DashboardActiveGatewaysErrorTemplate__chart-content'>
          <div className='DashboardActiveGatewaysErrorTemplate__chart-content-info'>
            <Error />
            <p className='DashboardActiveGatewaysErrorTemplate__chart-content-info-title'>{chartErrorTitle}</p>
            <p className='DashboardActiveGatewaysErrorTemplate__chart-content-info-description'>{chartErrorDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardActiveGatewaysErrorTemplate;