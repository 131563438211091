import { FC } from 'react';
import TabEmpty from 'components/molecules/TabEmpty';
import './styles.scss';

interface Props {
  emptyMessageTitle: string;
  emptyMessageText: string;
  emptyMessageLinkText: string;
  onCreateCommonArea: () => void;
}

const CommonAreasAndBookingsListEmptyTemplate: FC<Props> = ({
  emptyMessageTitle,
  emptyMessageText,
  emptyMessageLinkText,
  onCreateCommonArea,
}) => {
  return (
    <div className={'CommonAreasAndBookingsListEmptyTemplate__body'}>
      <div className={'CommonAreasAndBookingsListEmptyTemplate__body-message'}>
        <TabEmpty
          title={emptyMessageTitle}
          text={emptyMessageText}
          linkText={emptyMessageLinkText}
          onClick={onCreateCommonArea}
        />
      </div>
    </div>
  )
}

export default CommonAreasAndBookingsListEmptyTemplate;