import { RouteConfig } from 'react-router-config';
import AdminPage from 'components/pages/AdminPage';
import LoginPage from 'components/pages/LoginPage';
import RegisterUserPage from 'components/pages/RegisterUserPage';
import MaintenancePage from 'components/pages/MaintenancePage';
import LegalDocumentationPage from 'components/pages/LegalDocumentationPage';
import { adminRoutes } from './admin';

export const ROUTE_PATH_LOGIN = '/login';
export const ROUTE_PATH_MAINTENANCE = '/maintenance';
export const ROUTE_PATH_INVITATION_USER = '/invitation/:roleUser';
export const ROUTE_PATH_LEGAL_DOCUMENTATION_CHECK = '/legal-documentation';

const routes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: AdminPage,
  },
  {
    path: ROUTE_PATH_LOGIN,
    component: LoginPage,
  },
  {
    path: ROUTE_PATH_MAINTENANCE,
    component: MaintenancePage,
  },
  {
    path: ROUTE_PATH_INVITATION_USER,
    component: RegisterUserPage,
  },
  {
    path: ROUTE_PATH_LEGAL_DOCUMENTATION_CHECK,
    component: LegalDocumentationPage,
  },
  adminRoutes,
];

export default routes;
