export const createDocumentsByAssetIdPath = (assetId: string) => {
  return `/assets/${assetId}/document`;
};

export const createDocumentsByProjectIdPath = (projectId: string) => {
  return `/projects/${projectId}/document`;
};

export const deleteDocumentPath = (documentId: string) => {
  return `/documents/${documentId}`;
};

export const getDocumentListByAssetIdPath = (assetId: string) => {
  return `/assets/${assetId}/documents`;
};

export const getDocumentListByProjectIdPath = (projectId: string) => {
  return `/projects/${projectId}/documents`;
};
