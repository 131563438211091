import { GatewaySensorEntityModel } from '../model/gatewaysSensors/gatewaySensorEntityModel';
import { GatewaySensorPayloadModel } from '../model/gatewaysSensors/gatewaysSensorPayloadModel';

export const payloadToGatewaySensor = ({ id, name, room, usage, sensor_type }: GatewaySensorPayloadModel): GatewaySensorEntityModel => {
  return {
    id: id ?? '',
    name: name ?? '',
    room: room ?? '',
    usage: usage ?? '',
    sensorType: sensor_type ?? '',
  };
};
