import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Pagination, Row, Col, Input } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import './styles.scss';

export interface AlfredTableColumn<RecordType> extends ColumnType<RecordType> {
  editable?: boolean;
  onCell?: (record: RecordType) => { record: RecordType; dataIndex: any; title: any; isEditing: boolean };
}

export interface AlfredTableResponse<T> {
  data: T[];
  totalPages: number;
}

export type AlfredTableFetch<T> = (page: number, size: number, sort?: string, searchQuery?: string) => Promise<AlfredTableResponse<T>>;

export interface AlfredTableProps<T> {
  actionSection?: JSX.Element;
  columns: AlfredTableColumn<T>[];
  fetch: AlfredTableFetch<T>;
  onRowClick?: (data: T) => void;
  search?: boolean;
}

export const DEFAULT_PAGE_SIZE = 50;
const PAGE_SIZE_OPTIONS = ['50', '75', '100'];
const MAX_SIZE_RESPONSIVE_SCREEN = 600;

/**
 * @deprecated substitute for AlfredDetailTemplate
 */
const AlfredTable = <T extends {}>({ columns, fetch, onRowClick, actionSection, search = true }: AlfredTableProps<T>) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [pageCurrent, setPageCurrent] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [charging, setCharging] = useState(false);
  const [data, setData] = useState<Array<T>>([]);
  const pageSort = '-';

  useEffect(() => {
    setCharging(true);
    fetch(pageCurrent, pageSize, pageSort, searchQuery)
      .then(({ data, totalPages }) => {
        setData(data);
        setTotalPages(totalPages);
      })
      .finally(() => {
        setCharging(false);
      });
  }, [fetch, pageCurrent, pageSize, pageSort, searchQuery]);

  const handlePaginationOnChange = (page: number, pageSize?: number) => {
    setPageCurrent(page);
    setPageSize(pageSize!);
  };

  const handleRowClick = (item: T): void => {
    onRowClick && onRowClick(item);
  };

  const handleOnSearch = (value: string) => {
    setSearchQuery(value);
    setPageCurrent(1);
  };

  return (
    <div className='AlfredTable'>
      <Row className='AlfredTable__toolbar'>
        <Col flex='auto'>{actionSection}</Col>
        <Col>{search && <Input.Search allowClear placeholder={t('search_with_ellipsis')} onSearch={handleOnSearch} />}</Col>
      </Row>
      <Table<T>
        loading={charging}
        columns={columns}
        dataSource={data}
        rowKey='id'
        pagination={false}
        scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        onRow={(item) => {
          return {
            onClick: () => handleRowClick(item),
          };
        }}
      />
      <Pagination
        className='AlfredTable__pagination'
        size='small'
        showSizeChanger={true}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        current={pageCurrent}
        total={pageSize * totalPages}
        pageSize={pageSize}
        onChange={handlePaginationOnChange}
      />
    </div>
  );
};

export default AlfredTable;
