import { FC } from 'react';
import { CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface TitleDescriptionContentProps {
  values: CommonAreaModel[];
}

export const TitleDescriptionContent: FC<TitleDescriptionContentProps> = ({ values }) => {
  const { t } = useTranslation();
  const { infoUserTitle, infoUserDescription } = values[0];
  return (
    <>
      {(infoUserTitle || infoUserDescription) && (
        <div>
          {infoUserTitle && <div className={'TitleDescriptionContent__info-user-title'}>{infoUserTitle}</div>}
          {infoUserDescription && <div>{infoUserDescription}</div>}
        </div>
      )}
      {!infoUserTitle && !infoUserDescription && t('_COMMON_AREA_NO_DATA')}
    </>
  );
};
