import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import { Icon } from 'components/atoms/Icons';
import { hasIntercomsCreatingPermission } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/config';
import { IntercomEmptyTemplateTranslations } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/translations';
import './styles.scss';

interface Props {
  intercomEmptyTemplateTranslations: IntercomEmptyTemplateTranslations;
  onAddIntercom: () => void;
}

const ProjectIntercomsEmptyTemplate: FC<Props> = ({ intercomEmptyTemplateTranslations, onAddIntercom }) => {
  const { title, text, buttonText } = intercomEmptyTemplateTranslations;

  return (
    <Paper>
      <div className={'IntercomsEmptyTemplate__container'}>
        <div className={'IntercomsEmptyTemplate__empty'}>
          <div className='IntercomsEmptyTemplate__container-info'>
            <div className='IntercomsEmptyTemplate__icon'>
              <Icon.Intercom />
            </div>
            <h2 className='IntercomsEmptyTemplate__title'>{title}</h2>
            <p className='IntercomsEmptyTemplate__text'>{text}</p>
            {hasIntercomsCreatingPermission && (
              <div className='IntercomsEmptyTemplate__wrapper-link IntercomsEmptyTemplate__link' onClick={onAddIntercom}>
                {buttonText}
              </div>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default ProjectIntercomsEmptyTemplate;
