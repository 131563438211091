import { FC } from 'react';
import TabCommonAreasAndBookingsMenu, { TabCommonAreasAndBookingsMenuItem } from 'components/molecules/TabCommonAreasAndBookingsMenu';
import { CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import { FormValuesResponse } from 'components/molecules/CommonAreaForm/models';
import { BookingModel } from 'core/domain/bookingsAndCommonAreas/models/bookingEntityModels';
import { CommonAreasAndBookingsVisorViews } from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabCommonAreasAndBookings/hook/useCommonAreasAndBookings';
import CommonAreasAndBookingsVisorManager from 'components/molecules/CommonAreasAndBookingsVisorManager';
import './styles.scss';

interface CommonAreasAndBookingsTemplateProps {
  commonAreaData: CommonAreaModel;
  bookingListData: BookingModel[];
  menuListItems: TabCommonAreasAndBookingsMenuItem[];
  selectedItemId?: string;
  sensorOptionList: SensorByProjectModel[];
  visibleType: CommonAreasAndBookingsVisorViews;
  onEnableArea: (checked: boolean) => void;
  onClickMenuItem: (id: string) => void;
  onCreateArea: (values: FormValuesResponse) => void;
  onInvisibleCreate: () => void;
  onInvisibleUpdate: () => void;
  onVisibleBookingList: () => void;
  onRemoveArea: (id: string) => void;
  onUpdateArea: ({ id, values }: { id: string; values: FormValuesResponse }) => void;
  onVisibleCreate: () => void;
  onVisibleUpdate: () => void;
  onDeleteBooking: (bookingId: string) => void;
  isSaving: boolean;
}

const CommonAreasAndBookingsTemplate: FC<CommonAreasAndBookingsTemplateProps> = (props) => (
  <div className={'CommonAreasAndBookingsTemplate__container'}>
    <div className={'CommonAreasAndBookingsTemplate__menu'}>
      <TabCommonAreasAndBookingsMenu {...props} />
    </div>
    <div className={'CommonAreasAndBookingsTemplate__content'}>
      <CommonAreasAndBookingsVisorManager {...props} />
    </div>
  </div>
);

export default CommonAreasAndBookingsTemplate;
