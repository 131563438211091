import { FC } from 'react';
import { useRentComingSoon } from './resources/useRentComingSoon';
import ComingSoonTemplate from 'components/template/ComingSoonTemplate';

const RentComingSoonPage: FC = () => {
  const { forbiddenText } = useRentComingSoon();

  return <ComingSoonTemplate text={forbiddenText} />
}

export default RentComingSoonPage;