import { FC } from 'react';
import LogoAlfred from 'assets/svg/LogoAlfred';
import './styles.scss';

interface Props {
  logoWidth: string;
  logoColor: string;
}

const ALFRED_SMART_WEB_LINK = 'https://alfredsmart.com/';

const WaterMark: FC<Props> = ({ logoWidth, logoColor }) => {
  return (
    <a className={'WaterMark_wrapper'} href={ALFRED_SMART_WEB_LINK} target={'_blank'} rel={'noreferrer'}>
      <p className={'WaterMark_text'}>powered by</p>
      <LogoAlfred color={logoColor} width={logoWidth} />
    </a>
  );
}

export default WaterMark;