import { FC } from 'react';
import TabContentLoading from 'components/molecules/TabContentLoading';
import TabCommonAreasAndBookingsMenu, { TabCommonAreasAndBookingsMenuItem } from 'components/molecules/TabCommonAreasAndBookingsMenu';
import './styles.scss';
import type { CommonAreasAndBookingsVisorViews } from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabCommonAreasAndBookings/hook/useCommonAreasAndBookings';

interface CommonAreasAndBookingsLoadingTemplateProps {
  menuListItems: TabCommonAreasAndBookingsMenuItem[];
  selectedItemId?: string;
  visibleType: CommonAreasAndBookingsVisorViews;
  onVisibleCreate: () => void;
  onClickMenuItem: (id: string) => void;
  onVisibleBookingList: () => void;
}

const CommonAreasAndBookingsLoadingTemplate: FC<CommonAreasAndBookingsLoadingTemplateProps> = ({ ...props }) => {
  return (
    <div className={'CommonAreasAndBookingsLoadingTemplate__container'}>
      <div className={'CommonAreasAndBookingsLoadingTemplate__menu'}>
        <TabCommonAreasAndBookingsMenu {...props} />
      </div>
      <div className={'CommonAreasAndBookingsLoadingTemplate__content'}>
        <TabContentLoading />
      </div>
    </div>
  );
};

export default CommonAreasAndBookingsLoadingTemplate;
