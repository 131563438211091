import { CommonAreaTimeAvailabilityModel } from '../models/commonAreaEntityModels';
import { PayloadCommonAreaTimeAvailabilityModel } from '../models/payloadCommonAreaModels';

export const payloadToCommonAreaTimeAvailability = ({
  time_availability_end,
  time_availability_start,
}: PayloadCommonAreaTimeAvailabilityModel): CommonAreaTimeAvailabilityModel => {
  return {
    start: time_availability_start ?? '',
    end: time_availability_end ?? '',
  };
};
