import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import CommonAreaFormGrid from 'components/molecules/CommonAreaForm/components/CommonAreaFormGrid';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import { FormValues, FormValuesResponse } from 'components/molecules/CommonAreaForm/models';
import { useModalStatus } from 'components/molecules/CommonAreaForm/hooks/useModalStatus';
import ModalFormConfirm from 'components/molecules/CommonAreaForm/components/ModalFormConfirm';
import { useUpdateCommonAreasForm } from 'components/molecules/CommonAreaForm/hooks/useUpdateCommonAreasForm';
import './styles.scss';

interface CommonAreaFormUpdateTemplateProps {
  commonAreaData: CommonAreaModel;
  sensorOptionList: SensorByProjectModel[];
  onRemoveArea: (id: string) => void;
  onUpdateArea: ({ id, values }: { id: string; values: FormValuesResponse }) => void;
  onInvisibleUpdate: () => void;
  isSaving: boolean;
}

const CommonAreaFormUpdateTemplate: FC<CommonAreaFormUpdateTemplateProps> = ({
  commonAreaData,
  sensorOptionList,
  onUpdateArea,
  onRemoveArea,
  onInvisibleUpdate,
  isSaving,
}) => {
  const { t } = useTranslation();
  const { configModal, onLoadAndConfigModal, onConfirm, onCancel, isVisible, isLoading } = useModalStatus();
  const {
    form,
    Form,
    errors,
    selectedDays,
    bookingDurationOptionList,
    timeAvailability,
    backgroundImage,
    onChangeInputFieldsValues,
    onSelectDay,
    onUnselectDay,
    onSelectSensors,
    onSelectBookingDuration,
    onChangeTimeAvailabilityInterval,
    onParseValues,
    onAddTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    getTimeAvailabilityErrorMessage,
    onChangeBackgroundImage,
    areThereChanges,
    areThereMandatoryChanges
  } = useUpdateCommonAreasForm({ commonAreaData });

  const confirmUpdateArea = ({ id, values, isConfirmModalShown }: { id: string; values: FormValuesResponse; isConfirmModalShown: boolean }) => {
    isConfirmModalShown &&
      onLoadAndConfigModal(values, {
        title: t('_COMMON_AREA_MODAL_TITLE_SAVE'),
        text: t('_COMMON_AREA_MODAL_INFO_SAVE'),
        buttonSuccess: t('_COMMON_AREA_MODAL_BUTTON_SAVE'),
        onConfirm: () => onUpdateArea({ id, values }),
      });

    !isConfirmModalShown && onUpdateArea({ id, values });
  };

  const handleOnFinish = ({ id, values }: { id: string; values: FormValues }) => {
    const isConfirmModalShown = areThereMandatoryChanges({ ...values });
    const parsedValues = onParseValues(values);
    parsedValues && id && confirmUpdateArea({ id, values: parsedValues, isConfirmModalShown })
  };

  const handleOnConfirmRemoveArea = (id: string) => {
    onLoadAndConfigModal(id, {
      title: t('_COMMON_AREA_MODAL_TITLE_REMOVE'),
      text: t('_COMMON_AREA_MODAL_INFO_REMOVE'),
      buttonSuccess: t('_COMMON_AREA_MODAL_BUTTON_REMOVE'),
      onConfirm: () => onRemoveArea(id),
    });
  };

  return (
    <div className={'CommonAreaFormUpdateTemplate__container'}>
      <div className={'CommonAreaFormUpdateTemplate__required-info'}>
        <p>{t('_COMMON_AREA_REQUIRED_FIELDS')}</p>
      </div>
      <CommonAreaFormGrid
        Form={Form}
        form={form}
        errors={errors}
        selectedDays={selectedDays}
        id={commonAreaData.id}
        selectedSensors={commonAreaData.sensors}
        timeAvailability={timeAvailability}
        backgroundImage={backgroundImage}
        onClose={onInvisibleUpdate}
        onRemoveArea={handleOnConfirmRemoveArea}
        onFinish={({ id, values }) => id && handleOnFinish({ id, values })}
        onSelectDay={onSelectDay}
        onUnselectDay={onUnselectDay}
        onChangeTimeAvailability={onChangeTimeAvailabilityInterval}
        onSelectBookingDuration={onSelectBookingDuration}
        onSelectSensors={onSelectSensors}
        onAddTimeAvailabilityInterval={onAddTimeAvailabilityInterval}
        onDeleteTimeAvailabilityInterval={onDeleteTimeAvailabilityInterval}
        onChangeBackgroundImage={onChangeBackgroundImage}
        isSaving={isSaving}
        sensorOptionList={sensorOptionList}
        bookingDurationOptionList={bookingDurationOptionList}
        onChangeInputFieldsValues={onChangeInputFieldsValues}
        isDisabledSaved={!areThereChanges}
        getTimeAvailabilityErrorMessage={getTimeAvailabilityErrorMessage}
      />
      <ModalFormConfirm<FormValuesResponse>
        onCancel={onCancel}
        onClickConfirm={onConfirm}
        isLoading={isLoading}
        isVisible={isVisible}
        {...configModal}
      />
    </div>
  );
};

export default CommonAreaFormUpdateTemplate;
