import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { FormProps } from 'antd/lib/form';

const ClientNewForm: FC<FormProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Form {...props} layout='vertical'>
      <Form.Item name='name' label={`${t('name')}:`} rules={[{ required: true, message: t('field_required') }]}>
        <Input name='name' />
      </Form.Item>
      <Form.Item
        name={'email'}
        label={`${t('email')}:`}
        hasFeedback
        rules={[
          {
            message: t('email_no_valid'),
            type: 'email',
          },
        ]}
      >
        <Input autoComplete='on' name='email' />
      </Form.Item>
      <Form.Item name='description' label={`${t('description')}:`} hasFeedback>
        <Input.TextArea value='description' autoSize={{ minRows: 3, maxRows: 5 }} />
      </Form.Item>
    </Form>
  );
};

export default ClientNewForm;
