import api from 'services/api';
import { getUrlSearchParams } from 'utils/url';
import { getInformedParams } from 'utils/object';
import { DataAcquisition, SensorType, AssetTimeSeriesData } from 'models/dataAcquisition.model';

interface GetAssetTimeSeriesDataOptions {
  from: string;
  until: string;
  sensor?: SensorType;
  device?: string;
  assetId: string;
  measure?: 'day' | 'week' | 'month';
  accumulated?: '0' | '1';
}

export const getAssetTimeSeriesData = async ({ assetId, ...options }: GetAssetTimeSeriesDataOptions): Promise<DataAcquisition> => {
  const informedParams = getInformedParams(options);
  const searchParams = getUrlSearchParams(informedParams).toString();

  api.defaults.headers.common['Accept-version'] = '1';
  return await api.get(`/data/assets/time-series/${assetId}?${searchParams}`);
};

interface GetAssetTimeSeriesDataDemoOptions {
  from: string;
  until: string;
  sensor?: string;
  device?: string;
  assetId: string;
  measure?: 'day' | 'week' | 'month';
  sensorUuid?: string;
}

export const getAssetTimeSeriesDataDemo = async ({
  from,
  until,
  sensor,
  device,
  assetId,
  measure,
  sensorUuid,
}: GetAssetTimeSeriesDataDemoOptions): Promise<AssetTimeSeriesData> => {
  const params = new URLSearchParams();
  from && params.append('from', `${from}`);
  until && params.append('until', `${until}`);
  sensor && params.append('sensor', `${sensor}`);
  device && params.append('device', `${device}`);
  measure && params.append('measure', `${measure}`);
  sensorUuid && params.append('sensor_uuid', `${sensorUuid}`);

  api.defaults.headers.common['Accept-version'] = '1';
  return await api.get(`/data/assets/time-series/${assetId}?${params}`);
};
