import { FC } from 'react';
import {
  Col,
  Form,
  FormProps,
  Input,
  Row,
  Tooltip
} from 'antd';
import { useTranslation } from 'react-i18next';
import ImagePickerDisplay from 'components/molecules/ImagePickerDisplay';
import { Icon } from 'components/atoms/Icons';
import './styles.scss';
import { AreaType, SecondLevelHorizontalArea } from 'core/domain/projectLayoutConfig/model/projectLayoutConfigModels';

export interface ProjectConfigSecondLevelAreaFormValues {
  type: AreaType.HORIZONTAL;
  nameEs: string;
  nameEn: string;
  imageURL?: string;
  image?: string;
}
interface Props extends FormProps {
  initialValues?: ProjectConfigSecondLevelAreaFormValues;
}

export const ProjectConfigSecondLevelAreaForm: FC<Props> = ({
  initialValues,
  onFinish,
  ...props
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<SecondLevelHorizontalArea>();

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      {...props}>
      <Row gutter={24}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={t('_PROJECT_LAYOUT_CONFIG_FORM_LABEL_NAME_ES')}
            name={'nameEs'}
            initialValue={initialValues?.nameEs || ''}
            rules={[{ required: true, message: t('field_required'), type: 'string' }]}
          >
            <Input placeholder={t('_PROJECT_LAYOUT_CONFIG_FORM_PLACEHOLDER_NAME_ES')} autoFocus />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={t('_PROJECT_LAYOUT_CONFIG_FORM_LABEL_NAME_EN')}
            name={'nameEn'}
            rules={[{ required: true, message: t('field_required'), type: 'string' }]}
            initialValue={initialValues?.nameEn || ''}
          >
            <Input placeholder={`${t('_PROJECT_LAYOUT_CONFIG_FORM_PLACEHOLDER_NAME_EN')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={(
              <>
                <span className={'ProjectConfigSecondLevelAreaForm__image-info'}>{'Imagen'}</span>
                <Tooltip
                  title={t('_PROJECT_LAYOUT_CONFIG_FORM_IMAGE_RESTRICTION_INFO_TEXT')}
                  overlayInnerStyle={{ width: 210, textAlign: 'center', marginLeft: 55 }}>
                  <Icon.InfoCircleFilled />
                </Tooltip>
              </>
            )}
            name={'image'}
          >
            <ImagePickerDisplay
              onChange={() => null}
              temporaryImageUrl={''}
              initialValues={initialValues?.imageURL || ''}
              sizeLimit={1}
              acceptedTypes={['jpg', 'jpeg', 'png']}
              disabled={false}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};