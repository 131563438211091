import { FC, useState } from 'react';
import { registerUser } from 'services/api/users';
import RegisterUserForm, { RegisterUserFormValuesType } from 'components/organisms/RegisterUserForm';
import RegisterConfirmation from 'components/organisms/RegisterUserForm/RegisterUserConfirmation';
import { IMAGES } from 'constants/images';
import './styles.scss';

const RegisterUserPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const [registering, setRegistering] = useState(true);
  const alignment: string = registering ? 'RegisterUserPage--alignTop' : '';

  const handleOnForm = async (values: RegisterUserFormValuesType): Promise<void> => {
    try {
      setLoading(true);
      await registerUser(values);
      setRegistering(false);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`RegisterUserPage ${alignment}`}>
      <img src={IMAGES.logoYellowGrey} title='Alfred Smart Systems' alt='Alfred Smart Systems' className='RegisterUserPage__logo' />
      {registering ? (
        <RegisterUserForm onSubmit={handleOnForm} loading={loading} linkLopd='https://auth.alfredsmartdata.com/terms-of-service/es' />
      ) : (
        <RegisterConfirmation />
      )}
    </div>
  );
};

export default RegisterUserPage;
