import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import i18n from 'i18next';
import { message } from 'antd';
import { LocalStorageKeys } from 'constants/localStorage';
import { InstanceError, serviceResponseErrorManager } from './errors';

export type ApiResponse<T> = AxiosResponse<T>;

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_APIHOST,
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem(LocalStorageKeys.TOKEN);
  token && (config.headers['Authorization'] = `Bearer ${token}`);
  return config;
});

const rejectAxiosResponse = (error: AxiosError) => {
  message.error(i18n.t('_GENERAL_ERROR_MESSAGE') as string, 3);
  return Promise.reject(error);
};

api.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    if (error.response) {
      const controlledErrorValues = Object.values(InstanceError) as string[];
      const isErrorInsideManager = controlledErrorValues.includes(String(error.response.status));
      isErrorInsideManager && serviceResponseErrorManager(error.response.status);
      rejectAxiosResponse(error);
    }
    !error.response && rejectAxiosResponse(error);
  }
);

export default api;
