import { FC } from 'react';
import { Svg } from '../model/svg';

const DoorOpen: FC<Svg> = ({ width = '24', height = '24', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d='M20 19H19V5C19 4.45 18.55 4 18 4H14C14 3.45 13.55 3 13 3H6C5.45 3 5 3.45 5 4V19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21H13C13.55 21 14 20.55 14 20V6H17V20C17 20.55 17.45 21 18 21H20C20.55 21 21 20.55 21 20C21 19.45 20.55 19 20 19ZM11 13C10.45 13 10 12.55 10 12C10 11.45 10.45 11 11 11C11.55 11 12 11.45 12 12C12 12.55 11.55 13 11 13Z'
    />
  </svg>
)

export default DoorOpen;