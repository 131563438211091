import { createContext, FC, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { LocalStorageKeys } from 'constants/localStorage';
import { CurrentUserModel } from 'core/domain/users/models/userModel';

export interface UserProfileContextState {
  userProfile: CurrentUserModel | null;
  userProfileLoading: boolean;
  setUserProfile: Dispatch<SetStateAction<CurrentUserModel | null>>;
  setIsUserProfileLoading: Dispatch<SetStateAction<boolean>>;
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
}

interface UserProfileContextProviderProps {
  children: React.ReactNode;
}

const INITIAL_TOKEN = `Bearer ${localStorage.getItem(LocalStorageKeys.TOKEN)}` || '';

const UserProfileContext = createContext<any>({});

export const UserProfileContextProvider: FC<UserProfileContextProviderProps> = ({ children }) => {
  const [userProfile, setUserProfile] = useState<CurrentUserModel | null>(null);
  const [isUserProfileLoading, setIsUserProfileLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>(INITIAL_TOKEN);

  useEffect(() => {
    userProfile && setIsUserProfileLoading(false);
  }, [userProfile])

  const value = {
    userProfileLoading: isUserProfileLoading,
    setIsUserProfileLoading,
    userProfile,
    setUserProfile,
    token,
    setToken,
  };

  return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
};

export default UserProfileContext;

