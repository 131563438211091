import api from 'services/api';
import { updateEventsAndNewsPath } from 'core/endspoints/eventsAndNews';
import { uploadEventsAndNewsToPayload } from '../mappers/uploadEventsAndNewsToPayload';
import { EventsAndNewsModel } from '../models/eventsAndNewsEntityModels';

type UpdateModel = (values: EventsAndNewsModel) => Promise<void>;

export const UpdateEventsAndNews: UpdateModel = async (values) => {
  try {
    const path = updateEventsAndNewsPath(values.id);
    const data = uploadEventsAndNewsToPayload(values);
    await api.patch(path, { data });
  } catch (error) {
    console.error(error);
  }
};
