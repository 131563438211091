import { FC } from 'react';
import { Svg } from '../model/svg';

const Attention: FC<Svg> = ({ width = '96', height = '96', ...props }) => (
  <svg width={width} height={height} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.15" d="M46.3903 -2.02779e-06C55.6166 -2.43108e-06 64.4298 1.80392 72 6.6C78.1626 10.5043 83.5014 16.7345 87.5686 22.8C94.2071 32.7 96 40.0273 96 49.8C96 63.2175 93 75 84 84C75.9049 92.0546 63.4085 96 48 96C33.2614 96 19.322 89.502 10.8 79.8C5.00269 73.2 -1.59745e-06 59.4545 -2.1506e-06 46.8C-2.69531e-06 34.3385 1.24987 21.4513 8.4 13.2C19.8383 -8.49656e-06 31.2874 -1.36762e-06 46.3903 -2.02779e-06Z" fill="#96C0C4"
    />
    <path
      d="M0.499989 49.6097C0.49999 40.4462 2.29175 31.7344 7.02237 24.2676C10.8739 18.1882 17.0404 12.8955 23.0785 8.84667C32.8927 2.26566 40.1224 0.499995 49.8 0.499996C63.1456 0.499997 74.7754 3.48292 83.6458 12.3529C91.5729 20.3199 95.5 32.6658 95.5 48C95.5 62.6001 89.0599 76.4007 79.47 84.8243C76.2357 87.6653 71.2151 90.338 65.4103 92.3003C59.611 94.2608 53.0652 95.5 46.8 95.5C34.3287 95.5 21.623 94.2372 13.5274 87.2221C6.9989 81.5649 3.75588 75.9282 2.13307 69.8501C0.503146 63.7454 0.499988 57.1766 0.499989 49.6097Z"
      stroke="#96C0C4"
    />
    <g clip-path="url(#clip0_11443_2831)">
      <path
        d="M28.7941 61.4881L57.8878 69.2838C60.8628 70.0809 63.5818 67.3517 62.7828 64.3837L54.9704 35.3525C54.1713 32.3846 50.4622 31.3907 48.2862 33.5615L27.0049 54.7971C24.8289 56.9679 25.819 60.691 28.7941 61.4881ZM46.9644 51.863C45.9019 51.5783 45.2655 50.476 45.5502 49.4135L46.5854 45.5498C46.8701 44.4873 47.9724 43.8509 49.0349 44.1356C50.0974 44.4203 50.7338 45.5226 50.4491 46.5851L49.4139 50.4488C49.1292 51.5113 48.0269 52.1477 46.9644 51.863ZM46.8257 60.108L42.962 59.0728L43.9973 55.2091L47.861 56.2443L46.8257 60.108Z"
        fill="#96C0C4"
      />
    </g>
    <defs>
      <clipPath id="clip0_11443_2831">
        <rect x="31.0293" y="18.6055" width="48" height="48" rx="4" transform="rotate(15 31.0293 18.6055)" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Attention;
