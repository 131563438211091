import { useEffect, useState } from 'react';

export const useWeekDays = (days: number[] = []) => {
  const [selectedDays, setSelectedDays] = useState<number[]>(days);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    setSelectedDays(days);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days.length]);

  const onUnselectDay = (day: number) => {
    setSelectedDays(() => {
      const state = selectedDays.filter((item) => item !== day);
      state.length === 0 && setIsError(true);
      state.length !== 0 && setIsError(false);
      return state;
    });
  };

  const onSelectDay = (day: number) => {
    setSelectedDays([day, ...selectedDays].sort());
    setIsError((state) => state && false);
  };

  return { selectedDays, isError, onUnselectDay, onSelectDay };
};
