import { GatewayDevices } from '../model/gatewayDevices';
import { GatewayDevicesUnparsed } from '../model/gatewayDevices/gatewayDevicesUnparsed';

export const payloadToGatewayDevices = ({
  device_id,
  device_type,
  label,
  health,
  active_issues,
  battery_level,
  connectivity,
  latency,
  internal_temperature,
  created_at,
  updated_at,
}: GatewayDevicesUnparsed): GatewayDevices => {
  return {
    deviceId: device_id,
    deviceType: device_type,
    label: '' ?? label,
    health,
    activeIssues: active_issues ?? [],
    batteryLevel: battery_level,
    connectivity,
    latency,
    internalTemperature: internal_temperature,
    createdAt: created_at,
    updatedAt: updated_at,
  };
};
