import { ReactNode, VFC } from 'react';
import ModalAtom, { ModalAtomProps } from 'components/atoms/ModalAtom';
import SpaceAtom from 'components/atoms/SpaceAtom';
import { useTranslation } from 'react-i18next';
import TextButton from 'components/atoms/Button/TextButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';

interface AddCameraModalProps extends ModalAtomProps {
  formId: string;
  children: ReactNode;
  loading: boolean;
}

const AddCameraModal: VFC<AddCameraModalProps> = ({ formId, children, loading, ...props }) => {
  const { t } = useTranslation();

  return (
    <ModalAtom
      {...props}
      title={<SpaceAtom>{t('add_camera')}</SpaceAtom>}
      footer={[
        <TextButton key='back' onClick={props.onCancel} title={t('to_cancel')} disabled={loading} />,
        <PrimaryButton key='submit' loading={loading} htmlType='submit' form={formId} title={t('add')} />,
      ]}
      destroyOnClose
    >
      {children}
    </ModalAtom>
  );
};

export default AddCameraModal;
