import { FC, ReactNode } from 'react';

export interface MenuItemProps {
  className?: string;
  icon?: ReactNode;
  hidden?: boolean;
  key: string;
  children: ReactNode;
}

const MenuItem: FC<MenuItemProps> = ({ children }) => <>{children}</>;

export default MenuItem;
