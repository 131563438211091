import { FC } from 'react';
import AsChart from 'components/atoms/AsChart';
import { configuration } from './resources/config';
import { CustomActiveGatewaysStatisticsModel } from 'components/pages/DashboardPage/resources/useActiveGatewaysStatistics';
import './styles.scss';

interface Props {
  title: string;
  dataSubtitle: string;
  totalGatewaysText: string;
  chartSubtitle: string;
  gateways: CustomActiveGatewaysStatisticsModel;
}

const DashboardActiveGatewaysTemplate: FC<Props> = ({
  title,
  dataSubtitle,
  totalGatewaysText,
  chartSubtitle,
  gateways,
}) => {
  const { data: { datasets } } = configuration;
  const { labels, values, totalGatewaysOnline } = gateways;
  const data = { datasets, labels };
  data.datasets[0].data = values;

  return (
    <div className={'DashboardActiveGatewaysTemplate__wrapper'}>
      <div className={'DashboardActiveGatewaysTemplate__data'}>
        <div className='DashboardActiveGatewaysTemplate__data-header'>
          <p className='DashboardActiveGatewaysTemplate__title'>{title}</p>
          <p className='DashboardActiveGatewaysTemplate__subtitle'>{dataSubtitle}</p>
        </div>
        <div className='DashboardActiveGatewaysTemplate__data-content'>
          <p className='DashboardActiveGatewaysTemplate__data-content-info'>{totalGatewaysText}</p>
          <p className='DashboardActiveGatewaysTemplate__data-content-info-value'>{totalGatewaysOnline}</p>
        </div>
      </div>
      <div className='DashboardActiveGatewaysTemplate__chart'>
        <div className='DashboardActiveGatewaysTemplate__chart-header'>
          <p className='UsersTemplate__subtitle'>{chartSubtitle}</p>
        </div>
        <div className='DashboardActiveGatewaysTemplate__chart-content'>
          <div className={'DashboardActiveGatewaysTemplate__chart-content-limit'}>
            <AsChart
              configuration={{ ...configuration, data }}
              canvasWidth={'700'}
              canvasHeight={'260'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardActiveGatewaysTemplate;