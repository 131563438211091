import moment, { Moment } from 'moment';

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const disabledDate = (current: Moment) => {
  return current < moment().subtract(1, 'days').endOf('day');
};

export const disabledDateTime = (date: Moment | null) => {
  const itIsAfterNow = date && date.diff(moment()) < 0;
  if (itIsAfterNow) {
    const currentDate = new Date();
    return {
      disabledHours: () => range(0, 24).splice(0, currentDate.getHours()),
      disabledMinutes: () => range(0, currentDate.getMinutes()),
    };
  }
  return {};
};
