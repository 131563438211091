import { getErrorFormat } from 'core/utils/errors';
import api from 'services/api';

const getUrl = (projectId: string) => {
  return `/data/projects/time-series/${projectId}/access-control`;
}

export const GetDownloadLogCsvFileByProjectId = async ({
  projectId,
  fromDate,
  untilDate,
}: {
  projectId: string;
  fromDate: number;
  untilDate: number;
}): Promise<string>=> {
  try {
    const url = getUrl(projectId);

    const params = new URLSearchParams();
    params.append('format', 'csv');
    fromDate && params.append('from', String(fromDate));
    untilDate && params.append('until', String(untilDate));

    const data = await api.get(url, { params }) as string;
    return data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
