import { FC } from 'react';
import ModalAtom from 'components/atoms/ModalAtom';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';

interface Props {
  title: string;
  text: string;
  mainButtonText: string;
  secondaryButtonText: string;
  onClickMainButton: () => Promise<void>;
  onClickSecondaryButton: () => void;
  onCancel: () => void;
  visible: boolean;
  loading: boolean;

}

const ConfirmCloseAccessPasswordModal: FC<Props> = ({
  title,
  text,
  mainButtonText,
  secondaryButtonText,
  onClickMainButton,
  onClickSecondaryButton,
  onCancel,
  visible,
  loading,
}) => {
  return (
    <ModalAtom
      title={title}
      onCancel={onCancel}
      visible={visible}
      getContainer={false}
      destroyOnClose
      footer={[
        <DefaultButton key={'back'} onClick={onClickSecondaryButton} title={secondaryButtonText} />,
        <PrimaryButton key={'confirm'} loading={loading} title={mainButtonText} onClick={onClickMainButton} />,
      ]}
    >
      <div>{text}</div>
    </ModalAtom>
  );
};

export default ConfirmCloseAccessPasswordModal;
