import { SupportTicketModel } from 'core/domain/supportTickets/models/supportTicketsModel';

export const sortSupportTicketsBySourceType = (a: SupportTicketModel, b: SupportTicketModel) => {
  if (a.createdBy.type < b.createdBy.type) return -1;
  if (a.createdBy.type > b.createdBy.type) return 1;
  return 0;
};

export const sortSupportTicketsByCategoryType = (a: SupportTicketModel, b: SupportTicketModel) => {
  if (a.category < b.category) return -1;
  if (a.category > b.category) return 1;
  return 0;
};

export const sortSupportTicketsByStatusType = (a: SupportTicketModel, b: SupportTicketModel) => {
  if (a.status < b.status) return -1;
  if (a.status > b.status) return 1;
  return 0;
};

export const sortSupportTicketsByCreatedBy = (a: SupportTicketModel, b: SupportTicketModel) => {
  if (a.createdBy.name < b.createdBy.name) return -1;
  if (a.createdBy.name > b.createdBy.name) return 1;
  return 0;
};
