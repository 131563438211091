import { FC } from 'react';
import './styles.scss';

interface Props {
  values: string[];
}

const CommonAreaThumbnail: FC<Props> = ({ values }) => {
  return (
    <div className={'Thumbnail__container'}>
      <img className={'Thumbnail__image'} src={values[0]} alt={''} />
    </div>
  );
}

export default CommonAreaThumbnail;