import { ThirdPartyServicesByAsset } from '../model/thirdPartyServicesByAsset';
import { ThirdPartyServicesByAssetUnparsed } from '../model/thirdPartyServicesByAssetUnparsed';

export const payloadToThirdPartyServicesByAsset = ({
  id,
  title,
  description,
  link,
}: ThirdPartyServicesByAssetUnparsed): ThirdPartyServicesByAsset => {
  return {
    id: id ?? '',
    title: title ?? '',
    description: description ?? '',
    link: link ?? '',
  };
};
