import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { payloadToInvitationListResponse } from '../mappers';
import { InvitationListResponseModel, InvitationListResponsePayloadModel } from '../models';
import { InvitationListResponseEntity } from '../entities';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = ({ host, assetId }: { host: string; assetId: string }) => {
  return `${host}/access/asset/${assetId}/all`;
};

export const GetInvitationsList = async ({
  host,
  token,
  assetId,
  pageNumber,
  pageSize,
}: {
  host: string;
  token: string;
  assetId: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<InvitationListResponseModel> => {
  try {
    const url = getUrl({ host, assetId });

    const params = new URLSearchParams();
    pageNumber && params.append('page[number]', String(pageNumber));
    pageSize && params.append('page[size]', String(pageSize));

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { params },
    };

    const { data: responseData } = (await axios(options)) as AxiosResponse<InvitationListResponsePayloadModel>;
    const mappedData = payloadToInvitationListResponse(responseData);
    return InvitationListResponseEntity(mappedData);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
