import { Form } from 'antd';
import { CreateFormValues, RequiredFormValues } from '../models';
import { useFieldsEventsAndNewsFormErrors } from './useFieldsEventsAndNewsFormErrors';

export const useCreateEventsAndNewsForm = () => {
  const [form] = Form.useForm<CreateFormValues>();
  const { errors, resetErrors, setErrors } = useFieldsEventsAndNewsFormErrors();

  const checkAllRequiredFields = ({ title, image, description }: RequiredFormValues) => {
    if (!title || !image || !description) {
      throw new Error('There are some fields not completed');
    }
  };

  const onParseValues = ({ title, image, description, sendNotification, ...values }: CreateFormValues): CreateFormValues | undefined => {
    try {
      checkAllRequiredFields({ title, image, description, ...values });
      resetErrors();
      return {
        title,
        image,
        description,
        sendNotification: sendNotification || false,
        ...values,
      };
    } catch (error: any) {
      setErrors({ title, image, description, ...values });
    }
  };

  return {
    errors,
    form,
    Form,
    onParseValues,
  };
};
