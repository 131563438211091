import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { FormValues, FormValuesResponse, RequiredFormValues } from 'components/molecules/CommonAreaForm/models';
import { useWeekDays } from 'components/molecules/CommonAreaForm/hooks/useWeekDays';
import { GetCommonAreaById } from 'core/domain/bookingsAndCommonAreas/repositories/getCommonAreaById';
import { useFieldsCommonAreasFormErrors } from 'components/molecules/CommonAreaForm/hooks/useFieldsCommonAreasFormErrors';
import { useTimeAvailability } from 'components/molecules/CommonAreaForm/hooks/useTimeAvailability';
import { BOOKING_DURATION_OPTIONS } from 'components/pages/CommonAreaCreatePage/resources/utils';
import { useSensorsProject } from 'hooks/useSensorsProject';
import { SensorModel } from 'core/domain/bookingsAndCommonAreas/models/sensorEntityModels';
import { getCommonAreaDetailPath, getCommonAreasBookingsListPath } from 'components/pages/App/routes/commonAreas/config';
import {
  BookingDuration,
  CommonAreaModel,
  CommonAreaTimeAvailabilityModel,
  UpdateCommonAreaModel,
} from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { useModalStatus } from 'components/molecules/CommonAreaForm/hooks/useModalStatus';
import { UpdateCommonArea } from 'core/domain/bookingsAndCommonAreas/repositories/updateCommonArea';
import { messageAtom } from 'components/atoms/MessageAtom';
import { RemoveCommonArea } from 'core/domain/bookingsAndCommonAreas/repositories/removeCommonArea';

const formInput = {
  NAME: 'name',
  ACCESS: 'access',
  CAPACITY: 'capacity',
  DAYS_AVAILABILITY: 'daysAvailability',
  TIME_AVAILABILITY: 'timeAvailability',
  BOOKING_DURATION: 'bookingDuration',
  BACKGROUND_IMAGE: 'backgroundImage',
  INFO_USER_TITLE: 'infoUserTitle',
  INFO_USER_DESCRIPTION: 'infoUserDescription',
  MAXIMUM_BOOKINGS_BY_USER: 'maximumBookingsByUser',
  MAXIMUM_TIME_AVAILABILITY_PERIOD: 'maximumTimeAvailabilityPeriod',
  MAXIMUM_TIME_AVAILABILITY_VALUE: 'maximumTimeAvailabilityValue',
};

const areArraysEquals = (a: any, b: any): boolean => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((value, index) => value === b[index]);
};

export const useCommonAreaUpdate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, projectId, commonAreaId } = useParams<{ clientId: string; projectId: string; commonAreaId: string }>();
  const [form] = Form.useForm<FormValues>();
  const [isLoadingCommonArea, setIsLoadingCommonArea] = useState<boolean>(true);
  const [areThereChanges, setAreThereChanges] = useState<boolean>(false);
  const [commonArea, setCommonArea] = useState<CommonAreaModel>();
  const [temporaryBackgroundImage, setTemporaryBackgroundImage] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { sensorList } = useSensorsProject({ projectId });
  const { selectedDays, onSelectDay, onUnselectDay } = useWeekDays(commonArea?.daysAvailability);
  const { configModal, onLoadAndConfigModal, onConfirm, onCancel, isVisible, isLoading } = useModalStatus();
  const { errors, resetErrors, setErrors, getTimeAvailabilityErrorMessage } = useFieldsCommonAreasFormErrors();
  const {
    timeAvailability,
    onAddTimeAvailabilityInterval,
    onChangeTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    onFilterValidTimeAvailabilityIntervals,
    onParsedTimeAvailabilityToBeSent,
    areTimeAvailabilityArraysEquals,
    haveTimeAvailabilityInputsErrors,
  } = useTimeAvailability(commonArea?.timeAvailability);

  const selectedSensors: SensorModel[] = commonArea?.sensors || [];

  const requiredText: string = t('_COMMON_AREA_REQUIRED_FIELDS');

  const getCommonArea = async () => {
    setIsLoadingCommonArea(true);
    try {
      const commonAreaDetail = await GetCommonAreaById(commonAreaId);
      !!commonAreaDetail && setCommonArea(commonAreaDetail);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingCommonArea(false);
    }
  };

  const getInitialFormValues = (commonAreaData: CommonAreaModel) => {
    form.setFieldsValue({
      name: commonAreaData.name,
      capacity: String(commonAreaData.capacity),
      daysAvailability: commonAreaData.daysAvailability,
      timeAvailability: onFilterValidTimeAvailabilityIntervals(commonAreaData.timeAvailability),
      bookingDuration: BOOKING_DURATION_OPTIONS.find((item) => item.id === commonAreaData.bookingDuration)?.id,
      infoUserTitle: commonAreaData.infoUserTitle,
      infoUserDescription: commonAreaData.infoUserDescription,
      maximumBookingsByUser: commonAreaData.maximumBookingsByUser || 1000,
      maximumTimeAvailabilityPeriod: commonAreaData.maximumTimeAvailability.timePeriod || 'month',
      maximumTimeAvailabilityValue: String(commonAreaData.maximumTimeAvailability.value) || '6',
      backgroundImage: commonAreaData.backgroundImage,
    });
  };

  const onChangeBackgroundImage = (backgroundImage: string) => {
    form.setFieldsValue({ backgroundImage });
  };

  const onSelectSensors = (sensorIds: string[]) => {
    form.setFieldsValue({ access: sensorIds });
  };

  const onSelectBookingDuration = (bookingDurationId: BookingDuration) => {
    form.setFieldsValue({ bookingDuration: bookingDurationId });
  };

  const resetFormNullTimeAvailabilityIntervals = (time: (CommonAreaTimeAvailabilityModel | null)[]) => {
    const filteredValidIntervals = onFilterValidTimeAvailabilityIntervals(time);
    form.setFieldsValue({ timeAvailability: filteredValidIntervals });
  };

  const checkAllRequiredFields = ({
    name,
    capacity,
    daysAvailability,
    timeAvailability,
    bookingDuration,
    maximumBookingsByUser,
    maximumTimeAvailabilityPeriod,
    maximumTimeAvailabilityValue,
  }: RequiredFormValues) => {
    const haveTimeAvailabilityErrors = haveTimeAvailabilityInputsErrors(timeAvailability);

    if (
      !name ||
      !capacity ||
      !daysAvailability.length ||
      haveTimeAvailabilityErrors ||
      !bookingDuration ||
      !maximumBookingsByUser ||
      !maximumTimeAvailabilityPeriod ||
      !maximumTimeAvailabilityValue
    ) {
      throw new Error('There are some fields not completed');
    }
  };

  const areThereMandatoryChanges = ({
    name,
    capacity,
    daysAvailability,
    timeAvailability,
    bookingDuration,
    maximumBookingsByUser,
    maximumTimeAvailabilityPeriod,
    maximumTimeAvailabilityValue,
  }: FormValues): boolean => {
    const hasNameChanged = name !== commonArea?.name;
    const hasCapacityChanged = Number(capacity) !== commonArea?.capacity;
    const hasDaysAvailabilityChanged = JSON.stringify(daysAvailability) !== JSON.stringify(commonArea?.daysAvailability);
    const haveTimeAvailabilityIntervalsChanged = haveTimeAvailabilityInputsErrors(timeAvailability);
    const hasBookingDurationChanged = bookingDuration !== commonArea?.bookingDuration;
    const hasMaximumBookingsByUserChanged = maximumBookingsByUser !== commonArea?.maximumBookingsByUser;
    const hasMaximumTimeAvailabilityPeriodChanged = maximumTimeAvailabilityPeriod !== commonArea?.maximumTimeAvailability.timePeriod;
    const hasMaximumTimeAvailabilityValueChanged = Number(maximumTimeAvailabilityValue) !== commonArea?.maximumTimeAvailability.value;

    return [
      hasNameChanged,
      hasCapacityChanged,
      hasDaysAvailabilityChanged,
      haveTimeAvailabilityIntervalsChanged,
      hasBookingDurationChanged,
      hasMaximumBookingsByUserChanged,
      hasMaximumTimeAvailabilityPeriodChanged,
      hasMaximumTimeAvailabilityValueChanged,
    ].some((item) => item);
  };

  const onParseValues = ({ capacity, access, timeAvailability, ...values }: FormValues): FormValuesResponse | undefined => {
    resetFormNullTimeAvailabilityIntervals(timeAvailability);
    const transformedTimeAvailability = onParsedTimeAvailabilityToBeSent(timeAvailability);

    try {
      checkAllRequiredFields({ capacity, timeAvailability, ...values });
      resetErrors();
      return {
        capacity: Number(capacity),
        sensors: access,
        timeAvailability: transformedTimeAvailability,
        ...values,
      };
    } catch (error) {
      setErrors({ capacity, timeAvailability: transformedTimeAvailability, ...values });
    }
  };

  const navigateToCommonAreaDetail = () => {
    const updateCommonAreaRoute = getCommonAreaDetailPath({ clientId, projectId, commonAreaId });
    history.push(updateCommonAreaRoute);
  };

  const navigateToCommonAreasBookings = () => {
    const commonAreasBookingsRoute = getCommonAreasBookingsListPath({ clientId, projectId });
    history.push(commonAreasBookingsRoute);
  };

  const onUpdateCommonArea = async (values: FormValuesResponse): Promise<void> => {
    setIsSaving(true);
    const commonArea: Omit<UpdateCommonAreaModel, 'enabled' | 'hasBooking'> = {
      ...values,
      timeAvailability: values.timeAvailability,
      maximumTimeAvailability: { timePeriod: values.maximumTimeAvailabilityPeriod, value: Number(values.maximumTimeAvailabilityValue) },
      maximumBookingsByUser: Number(values.maximumBookingsByUser),
      id: commonAreaId,
      backgroundImage: values.backgroundImage || null,
    };

    try {
      await UpdateCommonArea(commonArea);
      const messageSuccess = t('_COMMON_AREA_MESSAGE_SAVED');
      messageAtom.success(messageSuccess);
      navigateToCommonAreaDetail();
    } catch (error) {
      console.warn(error);
    } finally {
      setIsSaving(false);
    }
  };

  const onConfirmUpdateCommonArea = ({ values, isConfirmModalShown }: { values: FormValuesResponse; isConfirmModalShown: boolean }) => {
    isConfirmModalShown &&
      onLoadAndConfigModal(values, {
        title: t('_COMMON_AREA_MODAL_TITLE_SAVE'),
        text: t('_COMMON_AREA_MODAL_INFO_SAVE'),
        buttonSuccess: t('_COMMON_AREA_MODAL_BUTTON_SAVE'),
        onConfirm: () => onUpdateCommonArea(values),
      });

    !isConfirmModalShown && onUpdateCommonArea(values);
  };

  const onFinishUpdateCommonArea = (values: FormValues) => {
    const isConfirmModalShown = areThereMandatoryChanges({ ...values });
    const parsedValues = onParseValues(values);
    parsedValues && onConfirmUpdateCommonArea({ values: parsedValues, isConfirmModalShown });
  };

  const onCloseUpdateCommonArea = () => {
    navigateToCommonAreaDetail();
  };

  const updateFormManager = {
    [formInput.NAME]: (values: string[]): boolean => values[0] !== commonArea?.name,
    [formInput.ACCESS]: (values: string[]): boolean => !areArraysEquals(values, commonArea?.sensors),
    [formInput.CAPACITY]: (values: string[]): boolean => values[0] !== JSON.stringify(commonArea?.capacity),
    [formInput.DAYS_AVAILABILITY]: (values: string[]): boolean => values[0] !== JSON.stringify(commonArea?.daysAvailability),
    [formInput.TIME_AVAILABILITY]: (values: string[]): boolean => {
      const parsedTimeAvailability = values[0].split(' ');
      const newTimeAvailability: (CommonAreaTimeAvailabilityModel | null)[] = [
        { start: parsedTimeAvailability[0], end: parsedTimeAvailability[1] },
      ];
      return !areTimeAvailabilityArraysEquals(newTimeAvailability, commonArea?.timeAvailability || []);
    },
    [formInput.BOOKING_DURATION]: (values: string[]): boolean => values[0] !== JSON.stringify(commonArea?.bookingDuration),
    [formInput.INFO_USER_TITLE]: (values: string[]): boolean => {
      const infoUserTitle = commonArea?.infoUserTitle || '';
      return values[0] !== infoUserTitle;
    },
    [formInput.INFO_USER_DESCRIPTION]: (values: string[]): boolean => {
      const infoUserDescription = commonArea?.infoUserDescription || '';
      return values[0] !== infoUserDescription;
    },
    [formInput.MAXIMUM_BOOKINGS_BY_USER]: (values: string[]) => Number(values[0]) !== commonArea?.maximumBookingsByUser,
    [formInput.MAXIMUM_TIME_AVAILABILITY_PERIOD]: (values: string[]) => values[0] !== commonArea?.maximumTimeAvailability.timePeriod,
    [formInput.MAXIMUM_TIME_AVAILABILITY_VALUE]: (values: string[]) => Number(values[0]) !== commonArea?.maximumTimeAvailability.value,
    [formInput.BACKGROUND_IMAGE]: (values: string[]) => values[0] !== commonArea?.backgroundImage,
  };

  const onChangeInputFieldsValues = ({ label, values }: { label: string; values: string[] }): void => {
    const item = updateFormManager[label];
    const isChange = item(values);
    setAreThereChanges(isChange);
  };

  const onConfirmRemoveCommonArea = async (id: string): Promise<void> => {
    setIsSaving(true);
    try {
      await RemoveCommonArea({ id });
      const messageSuccess = t('_COMMON_AREA_MESSAGE_REMOVED');
      messageAtom.success(messageSuccess);
      navigateToCommonAreasBookings();
    } finally {
      setIsSaving(false);
    }
  };

  const onRemoveCommonArea = (id: string) => {
    onLoadAndConfigModal(id, {
      title: t('_COMMON_AREA_MODAL_TITLE_REMOVE'),
      text: t('_COMMON_AREA_MODAL_INFO_REMOVE'),
      buttonSuccess: t('_COMMON_AREA_MODAL_BUTTON_REMOVE'),
      onConfirm: () => onConfirmRemoveCommonArea(id),
    });
  };

  useEffect(() => {
    if (commonArea) {
      const haveDaysChanged = JSON.stringify(selectedDays) !== JSON.stringify(commonArea.daysAvailability);
      setAreThereChanges(haveDaysChanged);
      form.setFieldsValue({ daysAvailability: selectedDays });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonArea, selectedDays]);

  useEffect(() => {
    if (commonArea) {
      const haveTimeAvailabilityChanged = !areTimeAvailabilityArraysEquals(timeAvailability, commonArea.timeAvailability);
      setAreThereChanges(haveTimeAvailabilityChanged);
      form.setFieldsValue({ timeAvailability });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonArea, timeAvailability]);

  useEffect(() => {
    !!commonArea && getInitialFormValues(commonArea);
    !!commonArea && setTemporaryBackgroundImage(commonArea.backgroundImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonArea]);

  useEffect(() => {
    getCommonArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonAreaId]);

  return {
    form,
    Form,
    commonAreaId,
    selectedSensors,
    sensorList,
    errors,
    selectedDays,
    bookingDurationOptionList: BOOKING_DURATION_OPTIONS,
    timeAvailability,
    backgroundImage: temporaryBackgroundImage,
    configModal,
    requiredText,
    onSelectDay,
    onUnselectDay,
    getTimeAvailabilityErrorMessage,
    onAddTimeAvailabilityInterval,
    onChangeTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    onChangeBackgroundImage,
    onSelectSensors,
    onSelectBookingDuration,
    onCloseUpdateCommonArea,
    onFinishUpdateCommonArea,
    onChangeInputFieldsValues,
    onConfirmModal: onConfirm,
    onCancelModal: onCancel,
    onRemoveCommonArea,
    modalVisible: isVisible,
    loadingModal: isLoading,
    loadingCommonArea: isLoadingCommonArea,
    areThereChanges,
    savingCommonArea: isSaving,
  };
};
