import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { ProjectConfigSecondLevelAreaForm, ProjectConfigSecondLevelAreaFormValues } from 'components/molecules/ProjectConfigSecondLevelAreaForm';

const FORM_ID = 'form_projectConfigSecondLevelArea';

interface Props extends ModalProps {
  onAdd: (values: any) => void;
  onEdit: (values: any) => void;
  initialValues?: ProjectConfigSecondLevelAreaFormValues;
  loading: boolean;
}

export const ProjectConfigSecondLevelHorizontalModal: FC<Props> = ({
  title,
  initialValues,
  onCancel,
  onAdd,
  onEdit,
  loading,
  ...props
}) => {
  const { t } = useTranslation();

  const handleOnCancel = (event: any) => {
    onCancel && onCancel(event);
  };

  return (
    <Modal
      {...props}
      title={title}
      width={650}
      closable={false}
      maskClosable={false}
      onCancel={handleOnCancel}
      footer={[
        <Button key={'back'} onClick={handleOnCancel}>
          {t('to_cancel')}
        </Button>,
        <Button key={'submit'} type={'primary'} loading={loading} htmlType={'submit'} form={FORM_ID}>
          {t('save')}
        </Button>,
      ]}
      destroyOnClose
      getContainer={false}
    >
      <ProjectConfigSecondLevelAreaForm
        id={FORM_ID}
        initialValues={initialValues}
        onFinish={initialValues ? onEdit : onAdd}
        preserve={false}
      />
    </Modal>
  );
};