import { FC } from 'react'
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './styles.scss';

export interface DeleteGatewayFormValues {
  gid: string;
  qid: string;
}

export enum DeleteGatewayFormValuesNames {
  GID = 'gid',
  QID = 'qid',
}

export interface DeleteGatewayFormTranslations {
  gidInputText: string;
  gidPlaceholder: string;
  qidInputText: string;
  qidPlaceholder: string;
}

interface Props {
  formTranslations: any;
  form: FormInstance<DeleteGatewayFormValues>;
  errorGidMessage: string;
  errorQidMessage: string;
  onFinish: (values: DeleteGatewayFormValues) => void;
  deleting: boolean;
  qidValidationVisible: boolean;
  errorGid: boolean;
  errorQid: boolean;
}

const DeleteGatewayForm: FC<Props> = ({
  formTranslations,
  form,
  errorGidMessage,
  errorQidMessage,
  onFinish,
  deleting,
  qidValidationVisible,
  errorGid,
  errorQid,
}) => {
  const { gidInputText, gidPlaceholder, qidInputText, qidPlaceholder } = formTranslations;

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      requiredMark={false}
    >
      <div>
        <Form.Item
          label={gidInputText}
          name={DeleteGatewayFormValuesNames.GID}
          validateStatus={errorGid ? 'error' : undefined}
        >
          <Input disabled={deleting} placeholder={gidPlaceholder} />
        </Form.Item>
        {errorGid && <span className={'DeleteGatewayForm__error'}>{errorGidMessage}</span>}
        {qidValidationVisible && (
          <>
            <Form.Item
              label={qidInputText}
              name={DeleteGatewayFormValuesNames.QID}
              validateStatus={errorQid ? 'error' : undefined}
            >
              <Input disabled={deleting} placeholder={qidPlaceholder} />
            </Form.Item>
            {errorQid && <span className={'DeleteGatewayForm__error'}>{errorQidMessage}</span>}
          </>
        )}
      </div>
    </Form>
  );
}

export default DeleteGatewayForm