import { RequestResponse } from 'core/models/requestResponse';
import { ResponseThirdPartySevicesUnparser } from '../model/responseThirdPartySevices';

export const responseToThirdPartyServicesByProject = ({ 
  id, 
  title 
}: ResponseThirdPartySevicesUnparser): Readonly<RequestResponse> => {
  return Object.freeze({
    id: id ?? '',
    text: title ?? '',
  })
}