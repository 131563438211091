import { RouteConfig } from 'react-router-config';
import DigitalSignagePage from 'components/pages/DigitalSignage';
import { ROUTE_PATH_DIGITAL_SIGNAGE } from './config';

export const digitalSignageRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_DIGITAL_SIGNAGE,
    exact: true,
    component: DigitalSignagePage,
  },
];
