import { CameraParsed } from '../models/cameraParsed';
import { CameraUnparsed } from '../models/cameraUnparsed';

export const payloadToCameras = ({
  name,
  remote,
  local,
  vendor,
  model,
  comments,
  device_serial,
  enabled,
  snapshot,
}: CameraUnparsed): CameraParsed => {
  return {
    name: name ?? '',
    remote: remote ?? '',
    local: local ?? '',
    vendor: vendor ?? '',
    model: model ?? '',
    comments: comments ?? '',
    deviceSerial: device_serial ?? '',
    enabled,
    snapshot: snapshot ?? '',
  };
};
