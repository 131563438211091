import { FC } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import CommonAreaFormGrid from 'components/molecules/CommonAreaForm/components/CommonAreaFormGrid';
import { BookingDuration, CommonAreaTimeAvailabilityModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { FormValues } from 'components/molecules/CommonAreaForm/models';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import './styles.scss';
import { SensorModel } from 'core/domain/bookingsAndCommonAreas/models/sensorEntityModels';

interface Props {
  commonAreaId: string;
  commonAreaRequiredFieldsText: string;
  bookingDurationOptionList: { id: BookingDuration, name: string }[];
  errors: any;
  form: FormInstance<FormValues>;
  Form: any;
  selectedDays: number[];
  selectedSensors: SensorModel[];
  sensorOptionList: SensorByProjectModel[];
  timeAvailability: (CommonAreaTimeAvailabilityModel | null)[];
  backgroundImage: string;
  onChangeInputFieldsValues: ({ label, values }: { label: string; values: string[] }) => void;
  onChangeTimeAvailability: (values: [moment.Moment, moment.Moment] | null, index: number) => void;
  onAddTimeAvailabilityInterval: () => void;
  onDeleteTimeAvailabilityInterval: (index: number) => void;
  getTimeAvailabilityErrorMessage: () => void
  onCloseUpdateCommonArea: () => void;
  onFinishUpdateCommonArea: (values: FormValues) => void;
  onRemoveCommonArea: (commonAreaId: string) => void;
  onSelectBookingDuration: (bookingDurationId: BookingDuration) => void;
  onSelectDay: (day: number) => void;
  onUnSelectDay: (day: number) => void;
  onSelectSensors: (sensorIds: string[]) => void;
  onChangeBackgroundImage: (image: string) => void;
  saving: boolean;
  saveButtonDisabled: boolean;
}

const CommonAreaUpdateTemplate: FC<Props> = ({
  commonAreaId,
  commonAreaRequiredFieldsText,
  bookingDurationOptionList,
  errors,
  form,
  Form,
  selectedDays,
  selectedSensors,
  sensorOptionList,
  timeAvailability,
  backgroundImage,
  onChangeInputFieldsValues,
  onChangeTimeAvailability,
  onAddTimeAvailabilityInterval,
  onDeleteTimeAvailabilityInterval,
  onRemoveCommonArea,
  getTimeAvailabilityErrorMessage,
  onCloseUpdateCommonArea,
  onFinishUpdateCommonArea,
  onSelectBookingDuration,
  onSelectDay,
  onUnSelectDay,
  onSelectSensors,
  onChangeBackgroundImage,
  saving,
  saveButtonDisabled,
}) => {
  return (
    <div className={'CommonAreaUpdateTemplate__body'}>
      <div className={'CommonAreaUpdateTemplate__body-required'}>
        <p>{commonAreaRequiredFieldsText}</p>
      </div>
      <CommonAreaFormGrid
        id={commonAreaId}
        bookingDurationOptionList={bookingDurationOptionList}
        errors={errors}
        form={form}
        Form={Form}
        selectedDays={selectedDays}
        selectedSensors={selectedSensors}
        sensorOptionList={sensorOptionList}
        timeAvailability={timeAvailability}
        backgroundImage={backgroundImage}
        onChangeTimeAvailability={onChangeTimeAvailability}
        onClose={onCloseUpdateCommonArea}
        onFinish={({ values }) => onFinishUpdateCommonArea(values)}
        onSelectBookingDuration={onSelectBookingDuration}
        onRemoveArea={onRemoveCommonArea}
        onSelectDay={onSelectDay}
        onSelectSensors={onSelectSensors}
        onUnselectDay={onUnSelectDay}
        onAddTimeAvailabilityInterval={onAddTimeAvailabilityInterval}
        onDeleteTimeAvailabilityInterval={onDeleteTimeAvailabilityInterval}
        getTimeAvailabilityErrorMessage={getTimeAvailabilityErrorMessage}
        onChangeBackgroundImage={onChangeBackgroundImage}
        onChangeInputFieldsValues={onChangeInputFieldsValues}
        isSaving={saving}
        isDisabledSaved={saveButtonDisabled}
      />
    </div>
  )
}

export default CommonAreaUpdateTemplate;