import { CreateEventsAndNewsModel } from '../models/eventsAndNewsEntityModels';
import { PayloadCreateEventsAndNewsModel } from '../models/payloadEventsAndNews';

const STATUS = 'publish';
const TYPE = 'new';

export const createEventsAndNewsToPayload = ({
  title,
  description,
  image,
  link,
  linkTitle,
  sendNotification,
}: CreateEventsAndNewsModel): PayloadCreateEventsAndNewsModel => {
  return {
    title,
    description,
    image,
    link: link || null,
    link_title: linkTitle || null,
    send_notification: sendNotification || false,
    status: STATUS,
    type: TYPE,
  };
};
