import api from 'services/api';
import { commonAreasUpdatedPath } from 'core/endspoints/commonAreas';
import { commonAreaToPayload } from '../mappers/commonAreaToPayload';
import { UpdateCommonAreaModel } from '../models/commonAreaEntityModels';

interface EnableCommonAreaParams {
  commonArea: Omit<UpdateCommonAreaModel, 'enabled'>;
  checked: boolean;
}

export const EnableCommonArea = async ({ commonArea: { id, ...rest }, checked }: EnableCommonAreaParams): Promise<void> => {
  try {
    const path = commonAreasUpdatedPath(id);
    const data = commonAreaToPayload({ id, ...rest, enabled: checked });
    api.defaults.headers.common['Accept-version'] = '1';
    await api.patch(path, { data });
  } catch (error: any) {
    throw new Error(error);
  }
};
