import { useContext } from 'react';
import ClientProjectContext, { ClientProjectContextState } from 'context/ClientProjectContext';
import { ProjectClientModel } from 'core/domain/project/model/projectModel';

export const useClientProject = () => {
  const { clientProject, setClientProject } = useContext<ClientProjectContextState>(ClientProjectContext);

  const onSetClientProject = (data: ProjectClientModel) => {
    setClientProject(data);
  };

  return { data: clientProject, onSetData: onSetClientProject };
};
