import { FC } from 'react';
import { useProjectIntercoms } from './resources/useProjectIntercoms';
import { ProjectIntercomsColumns } from 'components/organisms/ProjectIntercomsColumns';
import ProjectIntercomsTemplatesManager from './resources/ProjectIntercomsTemplatesManager';

const TabIntercoms: FC = () => {
  const {
    intercomEmptyTemplateTranslations,
    intercomsListTemplateTranslations,
    intercomsErrorTemplateTranslations,
    intercomFormTranslations,
    intercoms,
    IntercomState,
    form,
    providers,
    onAddIntercom,
    onEditIntercom,
    onDeleteIntercom,
    onSubmitCreating,
    onSubmitCreatingFailed,
    onSubmitEditing,
    onSubmitEditingFailed,
    onClose,
    processing,
    loadingIntercoms,
  } = useProjectIntercoms();
  const { name, provider, deleteText, edit } = intercomsListTemplateTranslations;
  const columns = ProjectIntercomsColumns({ onEditIntercom, onDeleteIntercom, name, provider, edit, deleteText });

  return (
    <ProjectIntercomsTemplatesManager
      stateModel={IntercomState}
      intercomEmptyTemplateTranslations={intercomEmptyTemplateTranslations}
      intercomsListTemplateTranslations={intercomsListTemplateTranslations}
      intercomsErrorTemplateTranslations={intercomsErrorTemplateTranslations}
      intercomFormTranslations={intercomFormTranslations}
      intercoms={intercoms}
      columns={columns}
      form={form}
      providers={providers}
      onAddIntercom={onAddIntercom}
      onEditIntercom={onEditIntercom}
      onDeleteIntercom={onDeleteIntercom}
      onSubmitCreating={onSubmitCreating}
      onSubmitCreatingFailed={onSubmitCreatingFailed}
      onSubmitEditing={onSubmitEditing}
      onSubmitEditingFailed={onSubmitEditingFailed}
      onClose={onClose}
      processing={processing}
      loading={loadingIntercoms}
    />
  );
};

export default TabIntercoms;
