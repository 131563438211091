import { FC } from 'react';
import { ChromePicker, Color } from 'react-color';
import { Input } from 'antd';
import { Icon } from 'components/atoms/Icons';
import { COLORS } from 'constants/colors';
import { useColorPicker } from './resources/useColorPicker';
import './styles.scss';
import ColorSample from 'components/atoms/ColorSample';

interface Props {
  color: string;
  onChangeColor: (color: string) => void;
  disabled: boolean;
}

const ColorPickerWithSample: FC<Props> = ({ color, onChangeColor, disabled }) => {
  const { colorValue, isVisible, onChangeColorComplete, onClickColorButton } = useColorPicker(color);

  return (
    <div className={'ColorPicker__container'}>
      <div className={'ColorPicker__input'}>
        <Input
          value={color}
          disabled={disabled}
          onChange={(value) => onChangeColor(value.target.value)}
          suffix={(
            <button className={'ColorPicker__input-button'} disabled={disabled} onClick={onClickColorButton}>
              <Icon.ColorPicker style={{ color: COLORS.gray_extra_light }} />
            </button>
          )}
        />
        {isVisible && (
          <article className={'ColorPicker__popover'}>
            <ChromePicker
              color={colorValue}
              onChangeComplete={(newColor: typeof Color) => onChangeColorComplete({ color: newColor, onChangeColor })}
              disabled={disabled}
            />
          </article>
        )}
      </div>
      <ColorSample color={color} onClick={onClickColorButton} disabled={disabled} />
    </div>
  );

}

export default ColorPickerWithSample;
