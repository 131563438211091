import { AssetsOccupationModel } from '../models/assetsModel';
import { PayloadAssetsOccupationModel } from '../models/assetsPayloadModel';

export const payloadToAssetsOccupationStatistics = ({
  assets_linked_to_users,
  assets_not_linked_to_users,
  total_assets,
}: PayloadAssetsOccupationModel): AssetsOccupationModel => {
  return {
    assetsLinkedToUsers: assets_linked_to_users ?? 0,
    assetsNotLinkedToUsers: assets_not_linked_to_users ?? 0,
    totalAssets: total_assets ?? 0,
  };
};
