import { useEffect, useState } from 'react';
import { GetSensorsListByAssetId } from 'core/domain/sensors/repositories/getSensorsListByAssetId';
import { SensorEntityModel, SensorEditEntityModel } from 'core/domain/sensors/models/sensorEntityModels';
import { EditSensorById } from 'core/domain/sensors/repositories/editSensorById';

export const useSensorsAsset = (assetId: string, showAll: boolean) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sensors, setSensors] = useState<SensorEntityModel[]>([]);
  const [selectedSensor, setSelectedSensor] = useState<SensorEntityModel | undefined>(undefined);

  const getSensorList = async (assetId: string, showAll: boolean) => {
    setIsLoading(true);
    try {
      const data = await GetSensorsListByAssetId(assetId, showAll);
      data && setSensors(data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSensorList(assetId, showAll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const getSensorById = (id: string): SensorEntityModel => {
    const selected = sensors.find((item) => item.id === id);

    if (selected) {
      return selected;
    }

    return {
      id: '',
      uid: '',
      name: '',
      sensorType: '',
      room: '',
      usage: '',
      areValuesAccumulated: false,
      canDataBeFetched: true,
    };
  };

  const onSelectSensorAsset = (sensor: SensorEntityModel) => {
    setSelectedSensor(sensor);
  };

  const onEditSensor = async (id: string, sensor: SensorEditEntityModel) => {
    setIsLoading(true);
    try {
      await EditSensorById(id, sensor);
    } finally {
      setIsLoading(false);
    }
  };

  const onRefresh = async (assetId: string) => {
    await getSensorList(assetId, showAll);
  };

  return {
    data: sensors,
    selectedSensor,
    getSensorById,
    onSelectSensorAsset,
    onEdit: onEditSensor,
    onRefresh,
    loading: isLoading,
  };
};
