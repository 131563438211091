import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string) => {
  return `${host}/users/logout`;
};

export const SetLogout = async (host: string): Promise<void> => {
  try {
    const url = getUrl(host);

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<void>;
    localStorage.clear();
    return data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
