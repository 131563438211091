import { FC } from 'react';
import { PageHeader, Select } from 'antd';
import Paper from 'components/atoms/Paper';
import { Icon } from 'components/atoms/Icons';
import { ClientAndProjectCustomData } from 'components/pages/CommonAreasClientAndProjectSelectorPage/resources/useCommonAreasClientAndProjectSelector';
import './styles.scss';

interface Props {
  title: string;
  clientId: string | null;
  projectId: string | null;
  clientListOptions: ClientAndProjectCustomData[];
  clientPlaceholderText: string;
  projectPlaceholderText: string;
  projectListOptions: ClientAndProjectCustomData[];
  emptyText: string;
  onSelectClient: (value: string) => void;
  onSelectProject: (value: string) => void;
  loadingClientsList: boolean;
  loadingProjectsList: boolean;
  showEmptyMessage: boolean;
  children?: React.ReactNode;
}

const CommonAreasClientAndProjectSelectorTemplate: FC<Props> = ({
  title,
  clientId,
  projectId,
  clientListOptions,
  clientPlaceholderText,
  projectPlaceholderText,
  projectListOptions,
  emptyText,
  children,
  onSelectClient,
  onSelectProject,
  loadingClientsList,
  loadingProjectsList,
  showEmptyMessage,
}) => {
  return (
    <>
      <PageHeader
        ghost={false}
        title={title}
        extra={
          <>
            <Select
              className={'CommonAreasClientAndProjectSelectorTemplate__header--selector'}
              value={clientId}
              showSearch
              placeholder={clientPlaceholderText}
              onChange={onSelectClient}
              options={clientListOptions}
              loading={loadingClientsList}
              filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
            <Select
              className={'CommonAreasClientAndProjectSelectorTemplate__header--selector'}
              value={projectId}
              placeholder={projectPlaceholderText}
              onChange={onSelectProject}
              options={projectListOptions}
              loading={loadingProjectsList}
            />
          </>
        }
      >
      </PageHeader>
      {showEmptyMessage && (
        <Paper className={'CommonAreasClientAndProjectSelectorTemplate__body'}>
          <div className={'CommonAreasClientAndProjectSelectorTemplate__body-empty-container'}>
            <div className={'CommonAreasClientAndProjectSelectorTemplate__body-empty-icon'}>
              <Icon.EmptyBooking />
            </div>
            <p className={'CommonAreasClientAndProjectSelectorTemplate__body-empty-text'}>{emptyText}</p>
          </div>
        </Paper>
      )}
      {!showEmptyMessage && children}
    </>


  );
}

export default CommonAreasClientAndProjectSelectorTemplate;