import { FC } from 'react';
import CommonAreasAndBookingsTemplate from 'components/template/CommonAreasAndBookingsTemplate';
import CommonAreasAndBookingsLoadingTemplate from 'components/template/CommonAreasAndBookingsLoadingTemplate';
import { useCommonAreasAndBookings } from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabCommonAreasAndBookings/hook/useCommonAreasAndBookings';

interface SubTabCommonAreasProps {
  projectId: string;
}

const SubTabCommonAreas: FC<SubTabCommonAreasProps> = ({ projectId }) => {
  const {
    bookingList,
    menuListItems,
    selectedCommonArea,
    sensorList,
    visibleType,
    onEnableArea,
    onRemoveArea,
    onCreateArea,
    onUpdateArea,
    onSelectArea,
    onInvisibleCreate,
    onVisibleCreate,
    onInvisibleUpdate,
    onVisibleUpdate,
    onVisibleBookingList,
    onDeleteBooking,
    isLoading,
    isSaving,
  } = useCommonAreasAndBookings({ projectId });

  return isLoading ? (
    <CommonAreasAndBookingsLoadingTemplate
      visibleType={visibleType}
      menuListItems={menuListItems}
      selectedItemId={selectedCommonArea?.id}
      onVisibleCreate={onVisibleCreate}
      onClickMenuItem={onSelectArea}
      onVisibleBookingList={onVisibleBookingList}
    />
  ) : (
    <CommonAreasAndBookingsTemplate
      visibleType={visibleType}
      commonAreaData={selectedCommonArea}
      bookingListData={bookingList}
      menuListItems={menuListItems}
      selectedItemId={selectedCommonArea?.id}
      sensorOptionList={sensorList}
      onEnableArea={onEnableArea}
      onClickMenuItem={onSelectArea}
      onRemoveArea={onRemoveArea}
      onInvisibleUpdate={onInvisibleUpdate}
      onCreateArea={onCreateArea}
      onUpdateArea={onUpdateArea}
      onVisibleCreate={onVisibleCreate}
      onInvisibleCreate={onInvisibleCreate}
      onVisibleUpdate={onVisibleUpdate}
      onVisibleBookingList={onVisibleBookingList}
      onDeleteBooking={onDeleteBooking}
      isSaving={isSaving}
    />
  );
};

export default SubTabCommonAreas;
