import { createMaintenanceActionPath } from 'core/endspoints/gatewaysPath';
import api from 'services/api';
import { reportMaintenanceToPayload } from '../mappers/reportMaintenanceToPayload';
import { ReportMaintenance } from '../model/reportMaintenance';

const CreateGatewayMaintenanceAction = async (gatewayId: string, actionData: ReportMaintenance): Promise<void> => {
  const actionType = actionData.action;
  const path = createMaintenanceActionPath(gatewayId, actionType);
  const data = reportMaintenanceToPayload({ ...actionData });

  const response = await api.post(path, { data });
  return response.data;
};

export default CreateGatewayMaintenanceAction;
