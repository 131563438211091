import { PremiumUser } from '../model/premiumUser';
import { PremiumUserUnparsed } from '../model/premiumUserUnparsed';

export const payloadToPremiumUsers = ({ billable_users, client_email, client_id, client_name }: PremiumUserUnparsed): PremiumUser => {
  return {
    billableUsers: billable_users ?? [],
    clientEmail: client_email ?? '',
    clientId: client_id ?? '',
    clientName: client_name ?? '',
  };
};
