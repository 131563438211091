import { ROUTE_PATH_ADMIN } from '../../configRoutes';

export const ROUTE_PATH_USERS = ROUTE_PATH_ADMIN + '/users';
export const ROUTE_PATH_USER_NEW = ROUTE_PATH_ADMIN + '/user/new';
export const ROUTE_PATH_USER = ROUTE_PATH_ADMIN + '/users/:userId/:tab';

export const getUserPathById = (userId: string) => `${ROUTE_PATH_USERS}/${userId}/assets`;

export const getUsersWithPaginationPath = ({ page, size }: { page: string; size: string }) =>
  `${ROUTE_PATH_ADMIN}/users?page=${page}&size=${size}`;
