import { FC } from 'react';
import { Input, PageHeader, Pagination } from 'antd';
import Paper from 'components/atoms/Paper';
import TableAtom from 'components/atoms/TableAtom';
import { CustomGatewaysListDataModel, GatewaysOptions } from 'components/pages/GatewaysListPage/resources/utils';
import './styles.scss';

interface Props {
  title: string;
  gatewaysOptions: GatewaysOptions;
}

const MAX_SIZE_RESPONSIVE_SCREEN = 600;

const GatewaysListTemplate: FC<Props> = ({ title, gatewaysOptions }) => {
  const {
    gateways,
    columns,
    totalPages,
    currentPage,
    pageSize,
    defaultPageSize,
    pageSizeOptions,
    searchButtonText,
    inputRef,
    searchValue,
    onChangePage,
    onSearch,
    onUpdateSearchValue,
    loading,
  } = gatewaysOptions;

  return (
    <>
      <PageHeader ghost={false} title={title} />
      <Paper className={'GatewaysListTemplate__wrapper'}>
        <div className='GatewaysListTemplate__extra-button'>
          <Input.Search
            ref={inputRef}
            value={searchValue}
            autoFocus
            allowClear
            placeholder={searchButtonText}
            onSearch={onSearch}
            onChange={(event) => onUpdateSearchValue(event.target.value)}
          />
        </div>
        <TableAtom<CustomGatewaysListDataModel>
          loading={loading}
          columns={columns}
          dataSource={gateways}
          rowKey='id'
          pagination={false}
          scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        />
        <Pagination
          className={'GatewaysListTemplate__pagination'}
          size={'small'}
          showSizeChanger={true}
          defaultPageSize={Number(defaultPageSize)}
          pageSizeOptions={pageSizeOptions}
          current={currentPage}
          total={pageSize * totalPages}
          pageSize={pageSize}
          onChange={onChangePage}
        />
      </Paper >
    </>
  );
}

export default GatewaysListTemplate;