export const eventsAndNewsPath = (projectId: string): string => {
  return `/projects/${projectId}/events-news`;
};

export const updateEventsAndNewsPath = (eventsAndNewsId: string): string => {
  return `/events-news/${eventsAndNewsId}`;
};

export const removeEventsAndNewsPath = (eventsAndNewsId: string) => {
  return `/events-news/${eventsAndNewsId}`;
};
