import { gatewaysPath } from 'core/endspoints/gatewaysPath';
import api from 'services/api';
import { GatewayEntity } from '../entities/gatewayEntity';
import { payloadToGateway } from '../mappers/payloadToGateway';
import { GatewayUnparsed } from '../model/gateway/gatewayUnparsed';
import { GatewayParsed } from '../model/gateway/gatewayParsed';

export const GetGatewayDetail = async (gatewayId: string): Promise<GatewayParsed> => {
  const path = gatewaysPath(gatewayId);

  try {
    const { data } = await api.get<GatewayUnparsed>(path);
    const dataParser = payloadToGateway(data);
    return GatewayEntity(dataParser);
  } catch (error: any) {
    throw new Error(error);
  }
};
