import { FC } from 'react';
import AsChart from 'components/atoms/AsChart';
import { configuration } from './resources/config';
import { OccupationTranslationTypes } from 'components/pages/DashboardPage/resources/translations';
import './styles.scss';

interface Props {
  assetsLinked: number;
  assetsNotLinked: number;
  translations: OccupationTranslationTypes;
}

const DashboardOccupationTemplate: FC<Props> = ({ assetsLinked, assetsNotLinked, translations }) => {
  const { TITLE, TITLE_TIME_PERIOD, OCCUPIED, UNOCCUPIED } = translations
  const data = { ...configuration.data, labels: [UNOCCUPIED, OCCUPIED] };
  data.datasets[0].data = [assetsNotLinked, assetsLinked];

  return (
    <div className='OccupationTemplate__container'>
      <div className='OccupationTemplate__header'>
        <p className='OccupationTemplate__title'>{TITLE}</p>
        <p className='OccupationTemplate__subtitle'>{TITLE_TIME_PERIOD}</p>
      </div>
      <div className='OccupationTemplate__chart-container'>
        <div className='OccupationTemplate__chart-content'>
          <AsChart
            configuration={{ ...configuration, data }}
            canvasWidth={'240'}
            canvasHeight={'240'}
          />
        </div>
      </div>
    </div>
  )
};

export default DashboardOccupationTemplate;
