import { GetActiveUsersOccupationStatistics } from 'core/domain/assets/repositories/getAssetsOccupationStatistics';
import { useEffect, useState } from 'react';

export const useOccupation = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [assetsLinked, setAssetsLinked] = useState<number>(0);
  const [assetsNotLinked, setAssetsNotLinked] = useState<number>(0);

  const getActiveUsersOccupationStatistics = async () => {
    setIsLoading(true);
    try {
      const statistics = await GetActiveUsersOccupationStatistics();
      statistics && setAssetsLinked(statistics.assetsLinkedToUsers);
      statistics && setAssetsNotLinked(statistics.assetsNotLinkedToUsers);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActiveUsersOccupationStatistics();
  }, []);

  return {
    occupationLoading: isLoading,
    occupationError: isError,
    assetsLinked,
    assetsNotLinked,
  };
};
