import { useEffect, useState, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { message, PageHeader, Popconfirm, Tabs, Descriptions, Skeleton } from 'antd';
import { DeleteOutlined, HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { User, UserStatus } from 'models/users.model';
import { getUser, deleteUser, getProfileUser } from 'services/api/users';
import { DATE_TIME_FORMAT } from 'constants/date';
import TabAssets from './TabAssets';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { ROUTE_PATH_USERS } from '../App/routes/users/config';
import './styles.scss';

const STATUS_USER: UserStatus[] = ['pending', 'confirmation', 'withdrawn'];

const UserPage: FC = () => {
  const { userId, tab } = useParams<{ userId: string; tab: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showingPop, setShowingPop] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [user, setUser] = useState<User>();
  const [currentUser, setCurrentUser] = useState('');
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  useEffect(() => {
    setLoading(true);
    getUser(userId)
      .then(({ data }) => setUser(data))
      .finally(() => setLoading(false));
  }, [userId]);

  useEffect(() => {
    setLoading(true);
    getProfileUser()
      .then(({ id }) => setCurrentUser(id))
      .finally(() => setLoading(false));
  }, []);

  const goBack = () => history.goBack();

  const handleDeleteUser = async (user: User) => {
    setDeletingUser(true);
    try {
      const response = await deleteUser(user.id);
      message.success(t('deleted_user'), 3);
      history.push(ROUTE_PATH_USERS);
      return response;
    } catch (error) {
      message.error(t('delete_error_message'), 3);
    } finally {
      setDeletingUser(false);
    }
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title={`${t('user')}: ${user?.username && user.username.toLowerCase() !== 'unknown' ? user.username : t('unknown')}`}
        onBack={goBack}
        className='UserPage__header'
        extra={[
          <Popconfirm
            key='1'
            title={t('delete_user_confirm')}
            visible={showingPop}
            onConfirm={() => handleDeleteUser(user!)}
            okButtonProps={{ loading: deletingUser }}
            onCancel={() => setShowingPop(false)}
          >
            <DefaultButton
              icon={<DeleteOutlined />}
              onClick={() => setShowingPop(true)}
              disabled={user?.id === currentUser}
              title={t('delete_user')}
            />
          </Popconfirm>,
        ]}
        footer={
          <Tabs defaultActiveKey='TabAsset' onChange={(activeTab: string) => history.push(activeTab)}>
            <Tabs.TabPane
              tab={
                <span>
                  <HomeOutlined /> {t('assets')}
                </span>
              }
              key='assets'
            />
          </Tabs>
        }
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <Descriptions column={3} size='small'>
            <Descriptions.Item key='username' label={<b>{t('username')}</b>}>
              {user?.username && user.username.toLowerCase() !== 'unknown' ? user.username : t('unknown')}
            </Descriptions.Item>
            <Descriptions.Item key='email' label={<b>{t('email')}</b>}>
              {user?.email || t('not_reported')}
            </Descriptions.Item>
            <Descriptions.Item key='roles' label={<b>{t('roles')}</b>}>
              {user?.roles.map((role) => t(`ROLES.${role}`)).join(', ') || t('not_reported')}
            </Descriptions.Item>
            <Descriptions.Item key='lang' label={<b>{t('lang')}</b>}>
              {user?.lang ? t(`LANG.${user.lang}`) : t('not_reported')}
            </Descriptions.Item>
            <Descriptions.Item key='status' label={<b>{t('status')}</b>}>
              {user?.status ? t(STATUS_USER[user.status]) : t('not_reported')}
            </Descriptions.Item>
            <Descriptions.Item key='created_at' label={<b>{t('created_at')}</b>}>
              {user?.created_at ? moment.unix(user.created_at).format(DATE_TIME_FORMAT) : t('not_reported')}
            </Descriptions.Item>
            <Descriptions.Item key='confirmed_at' label={<b>{t('confirmed_at')}</b>}>
              {user?.confirmed_at ? moment.unix(user.confirmed_at).format(DATE_TIME_FORMAT) : t('not_reported')}
            </Descriptions.Item>
          </Descriptions>
        )}
      </PageHeader>
      <div className='UserPage__tab'>{activeTab === 'assets' && <TabAssets key='assets' />}</div>
    </>
  );
};

export default UserPage;
