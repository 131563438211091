import TableAtom, { TableActionsMenuProps } from 'components/atoms/TableAtom';
import { ConciergeTabColumns } from './ConciergeTabColumns';

interface ConciergeContentTableProps<T> {
  data: T[];
  usefulContactsTableActionsMenu: TableActionsMenuProps[];
  loading: boolean;
}

const ConciergeContentTable = <T extends {}>({
  data,
  usefulContactsTableActionsMenu,
  loading,
}: ConciergeContentTableProps<T>) => {
  const columns = ConciergeTabColumns<T>({ usefulContactsTableActionsMenu });

  return (
    <TableAtom<T>
      columns={columns}
      dataSource={data}
      pagination={false}
      loading={loading}
    />
  );
};

export default ConciergeContentTable;
