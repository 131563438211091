import { BookingDuration, CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import i18n from 'i18next';
import { DefaultContent } from '../components/DefaultContent';
import { TitleDescriptionContent } from '../components/TitleDescriptionContent';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import CommonAreaThumbnail from 'components/molecules/CommonAreaThumbnail';

const days = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

enum TimeAvailability {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

const translationDaysManager = {
  [days.MONDAY]: '_COMMON_AREA_WEEK_DAY_MONDAY',
  [days.TUESDAY]: '_COMMON_AREA_WEEK_DAY_TUESDAY',
  [days.WEDNESDAY]: '_COMMON_AREA_WEEK_DAY_WEDNESDAY',
  [days.THURSDAY]: '_COMMON_AREA_WEEK_DAY_THURSDAY',
  [days.FRIDAY]: '_COMMON_AREA_WEEK_DAY_FRIDAY',
  [days.SATURDAY]: '_COMMON_AREA_WEEK_DAY_SATURDAY',
  [days.SUNDAY]: '_COMMON_AREA_WEEK_DAY_SUNDAY',
};

const translationTimeAvailabilityManager = {
  [TimeAvailability.DAY]: (value: number) => i18n.t(value > 1 ? '_TIME_PERIOD_DAYS' : '_TIME_PERIOD_DAY').toLowerCase(),
  [TimeAvailability.WEEK]: (value: number) => i18n.t(value > 1 ? '_TIME_PERIOD_WEEKS' : '_TIME_PERIOD_WEEK').toLowerCase(),
  [TimeAvailability.MONTH]: (value: number) => i18n.t(value > 1 ? '_TIME_PERIOD_MONTHS' : '_TIME_PERIOD_MONTH').toLowerCase(),
};

const removeSeconds = (time: string) => {
  const positions = 5;
  return time.slice(0, positions);
};

const getAvailabilityParsed = (daysAvailability: number[]) => {
  const symbol = '-';
  return daysAvailability.map((item) => i18n.t(translationDaysManager[item])).join(symbol);
};

const BOOKING_DURATION_MAX_HOURS = 12;

const getBookingDurationTimeUnits = (bookingDuration: BookingDuration): string => {
  return bookingDuration > BOOKING_DURATION_MAX_HOURS ? '_COMMON_AREA_MINUTES' : '_COMMON_AREA_HOURS';
};

interface Columns {
  title: string;
  values: any[];
  renderComponent: any;
}

export const getColumns = ({
  commonAreaData,
  sensorOptionList,
}: {
  commonAreaData: CommonAreaModel;
  sensorOptionList: SensorByProjectModel[];
}): Columns[] => {
  const timeAvailability = (!!commonAreaData.timeAvailability.length &&
    commonAreaData.timeAvailability.map((time) => {
      const start = removeSeconds(time.start);
      const end = removeSeconds(time.end);
      return `${start}h - ${end}h`;
    })) || [''];
  const availabilityParsed = !!commonAreaData.daysAvailability && getAvailabilityParsed(commonAreaData.daysAvailability);
  const selectedSensorsNames = commonAreaData.sensors?.map((sensor) => sensor.label) || [];
  const capacityValues = [commonAreaData.capacity];
  const infoValues = [commonAreaData];

  return [
    {
      title: i18n.t('_COMMON_AREA_SPACE_NAME'),
      values: [commonAreaData.name],
      renderComponent: DefaultContent,
    },
    {
      title: i18n.t('_COMMON_AREA_ACCESS'),
      values: !!selectedSensorsNames.length ? selectedSensorsNames : [i18n.t('_COMMON_AREA_NO_SELECTED')],
      renderComponent: DefaultContent,
    },
    {
      title: i18n.t('_COMMON_AREA_CAPACITY'),
      values: !!capacityValues.length ? capacityValues : [`${i18n.t('_COMMON_AREA_NO_SELECTED')}`],
      renderComponent: DefaultContent,
    },
    {
      title: i18n.t('_COMMON_AREA_AVAILABILITY'),
      values: [availabilityParsed],
      renderComponent: DefaultContent,
    },
    {
      title: i18n.t('_COMMON_AREA_TIME_AVAILABILITY'),
      values: timeAvailability,
      renderComponent: DefaultContent,
    },
    {
      title: i18n.t('_COMMON_AREA_RANGE'),
      values: [`${commonAreaData.bookingDuration} ${i18n.t(getBookingDurationTimeUnits(commonAreaData.bookingDuration))}`],
      renderComponent: DefaultContent,
    },
    {
      title: i18n.t('_COMMON_AREA_MAXIMUM_BOOKINGS_BY_USER'),
      values: [commonAreaData.maximumBookingsByUser],
      renderComponent: DefaultContent,
    },
    {
      title: i18n.t('_COMMON_AREA__MAXIMUM_TIME_AVAILABILITY'),
      values: [
        `${commonAreaData.maximumTimeAvailability.value} ${
          translationTimeAvailabilityManager[commonAreaData.maximumTimeAvailability.timePeriod as TimeAvailability](
            commonAreaData.maximumTimeAvailability.value
          ) || i18n.t('_TIME_PERIOD_MONTH')
        }`,
      ],
      renderComponent: DefaultContent,
    },
    {
      title: i18n.t('_COMMON_AREA_BACKGROUND_IMAGE'),
      values: [commonAreaData.backgroundImage],
      renderComponent: CommonAreaThumbnail,
    },
    {
      title: i18n.t('_COMMON_AREA_INFORMATION_TO_USERS'),
      values: !!infoValues.length ? infoValues : [`${i18n.t('_COMMON_AREA_NO_SELECTED')}`],
      renderComponent: TitleDescriptionContent,
    },
  ];
};
