import {
  LinksPayloadModel,
  ListPaginationPayloadModel,
  SupportTicketCommentPayloadModel,
  SupportTicketPayloadModel,
  SupportTicketsResponsePayloadModel,
  TotalValuesPayloadModel,
} from '../models/supportTicketsPayloadModel';
import {
  LinksModel,
  ListPaginationModel,
  SupportTicketCommentModel,
  SupportTicketModel,
  SupportTicketsResponseModel,
  TotalValuesModel,
} from '../models/supportTicketsModel';

const payloadToSupportTicketDataComment = ({
  created_at,
  description,
  email,
  status,
  support_ticket_id,
}: SupportTicketCommentPayloadModel): SupportTicketCommentModel => {
  return {
    createdAt: created_at ?? 0,
    description: description ?? '',
    email: email ?? '',
    status: status ?? '',
    supportTicketId: support_ticket_id ?? '',
  };
};

const payloadToSupportTicketData = ({
  address,
  asset_id,
  category,
  comments,
  created_at,
  created_by,
  description,
  id,
  media,
  phone_number,
  priority,
  room,
  status,
  support_email,
  title,
}: SupportTicketPayloadModel): SupportTicketModel => {
  const transformComments = comments.map((comment) => payloadToSupportTicketDataComment(comment)) ?? [];

  return {
    address: address ?? '',
    assetId: asset_id ?? '',
    category: category ?? '',
    comments: transformComments,
    createdAt: created_at ?? 0,
    createdBy: {
      email: created_by.email ?? '',
      name: created_by.name ?? '',
      type: created_by.type ?? '',
      uuid: created_by.uuid ?? '',
    },
    description: description ?? '',
    id: id ?? '',
    media: {
      picture: media.picture ?? [],
      video: media.video ?? [],
    },
    phoneNumber: phone_number ?? '',
    priority: priority ?? '',
    room: room ?? '',
    status: status ?? '',
    supportEmail: support_email ?? '',
    title: title ?? '',
  };
};

const payloadToSupportTicketMeta = ({ all_results, page_size, results, total_pages }: ListPaginationPayloadModel): ListPaginationModel => {
  return {
    allResults: all_results ?? 0,
    pageSize: page_size ?? 0,
    results: results ?? 0,
    totalPages: total_pages ?? 0,
  };
};

const payloadToSupportTicketLinks = ({ first, last, next, prev, self }: LinksPayloadModel): LinksModel => {
  return {
    first: first ?? '',
    last: last ?? '',
    next: next ?? '',
    prev: prev ?? '',
    self: self ?? '',
  };
};

const payloadToSupportTicketTotalValues = ({
  tickets_created_during_last_month,
  tickets_created_during_last_week,
  total_accepted_support_tickets,
  total_in_progress_support_tickets,
  total_open_support_tickets,
}: TotalValuesPayloadModel): TotalValuesModel => {
  return {
    ticketsCreatedDuringLastMonth: tickets_created_during_last_month ?? 0,
    ticketsCreatedDuringLastWeek: tickets_created_during_last_week ?? 0,
    totalAcceptedSupportTickets: total_accepted_support_tickets ?? 0,
    totalInProgressSupportTickets: total_in_progress_support_tickets ?? 0,
    totalOpenSupportTickets: total_open_support_tickets ?? 0,
  };
};

export const payloadToSupportTicketResponse = ({
  data,
  meta,
  links,
  total_values,
}: SupportTicketsResponsePayloadModel): SupportTicketsResponseModel => {
  const transformedData = data.map((item) => payloadToSupportTicketData(item)) ?? [];
  const transformedMeta = payloadToSupportTicketMeta(meta);
  const transformedLinks = payloadToSupportTicketLinks(links);
  const transformedTotalValues = total_values && payloadToSupportTicketTotalValues(total_values);

  return {
    data: transformedData,
    meta: transformedMeta,
    links: transformedLinks,
    totalValues: transformedTotalValues,
  };
};
