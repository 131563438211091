import { FC } from 'react';
import { Skeleton } from 'antd';
import LayoutAtom from 'components/atoms/LayoutAtom';
import { MenuItemProps } from 'components/atoms/MenuItem';
import './styles.scss';

interface Props {
  sideMenuItems: MenuItemProps[],
  onReachLayoutBreakpoint: (broken: boolean) => void,
}

const AdminLoadingTemplate: FC<Props> = ({
  sideMenuItems,
  onReachLayoutBreakpoint,
}) => {

  return (
    <LayoutAtom className={'AdminLoadingTemplate'}>
      <LayoutAtom.Header className={'AdminLoadingTemplate__header'}>
        <Skeleton.Input active className={'AdminLoadingTemplate__logo'} />
        <Skeleton.Input active className={'AdminLoadingTemplate__topBarRight'} />
      </LayoutAtom.Header>
      <LayoutAtom>
        <LayoutAtom.Sider
          className={'AdminLoadingTemplate__sider'}
          breakpoint={'lg'}
          collapsedWidth={0}
          zeroWidthTriggerStyle={{ display: 'none' }}
          onBreakpoint={onReachLayoutBreakpoint}
        >
          {sideMenuItems.map((menuItem, index) => {
            return (
              <Skeleton.Input active key={index} className={'AdminLoadingTemplate__menuItem'} />
            );
          })}
        </LayoutAtom.Sider>
      </LayoutAtom>
    </LayoutAtom>
  );
};

export default AdminLoadingTemplate;