import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons'
import { ROUTE_PATH_LOGIN } from 'components/pages/App/routes';
import './styles.scss';

const RegisterUserConfirmation: FC = () => {
  const { t } = useTranslation('global');
  const history = useHistory();

  const handleOnGoToLogin = (): void => {
    history.push(ROUTE_PATH_LOGIN);
  }

  return (
    <div className='RegisterUserConfirmation'>
      <CheckCircleFilled className='RegisterUserConfirmation__icon'/>
      <p>{t('registered_correctly')}</p>
      <Button onClick={handleOnGoToLogin} type="primary" className='RegisterUserConfirmation__submit' >
        {t('go_to_login_page')}
      </Button>
    </div>
  );
};

export default RegisterUserConfirmation;
