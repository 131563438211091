import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AssignGatewayToAssetModalTranslations } from 'components/organisms/AssignGatewayToAssetModal';
import { AssignGatewayToAssetModalColumns } from 'components/organisms/AssignGatewayToAssetModal/AssignGatewayToAssetModalColumns';
import { ProjectAssetModel, ProjectListResponseDataModel } from 'core/domain/project/model/projectModel';
import { GetProjectsListByClientId } from 'core/domain/project/repositories/projectsCRUD';
import { GetAssetsListByProjectId } from 'core/domain/project/repositories/getAssetsListByProjectId';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { GetClientsList } from 'core/domain/client/repositories/getClientsList';
import { Asset } from 'models/assets.model';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { getAssets } from 'services/api/assets';
import { MessageErrorModel, useMessage } from 'hooks/useMessage';
import {
  AssetCustomData,
  AssignToAssetModalAssetsOptions,
  AssignToAssetModalClientsOptions,
  AssignToAssetModalOptions,
  AssignToAssetModalProjectsOptions,
  ClientCustomData,
  CustomGatewaysListDataModel,
  ProjectCustomData,
} from './utils';
import { AssignGatewayToAsset } from 'core/domain/assets/repositories/assignGatewayToAsset';

const MINIMUM_SEARCH_VALUE_CHARACTERS = 3;
const ERROR_MESSAGE_DURATION = 8;

export const useAssignToAsset = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [selectedGateway, setSelectedGateway] = useState<CustomGatewaysListDataModel>();
  const [loadingAssets, setLoadingAssets] = useState<boolean>(false);
  const [loadingManualAssets, setLoadingManualAssets] = useState<boolean>(false);
  const [assets, setAssets] = useState<AssetCustomData[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string | null>(null);
  const [errorSearching, setErrorSearching] = useState<boolean>(false);
  const [errorAssigning, setErrorAssigning] = useState<boolean>(false);
  const [isAssignAssetModalVisible, setIsAssignAssetModalVisible] = useState<boolean>(false);
  const [searchTableVisible, setSearchTableVisible] = useState<boolean>(false);
  const [searchingVisible, setSearchingVisible] = useState<boolean>(true);
  const [manualSearchingVisible, setManualSearchingVisible] = useState<boolean>(false);
  const [loadingClients, setLoadingClients] = useState<boolean>(false);
  const [clients, setClients] = useState<ClientCustomData[]>([]);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);
  const [projects, setProjects] = useState<ProjectCustomData[]>([]);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [successAssigningGateway, setSuccessAssigningGateway] = useState<boolean>(false);
  const [assigning, setAssigning] = useState<boolean>(false);

  const translations: AssignGatewayToAssetModalTranslations = {
    title: t('_GATEWAYS_LIST_ASSIGN_TO_ASSET'),
    mainButtonText: t('assign'),
    secondaryButtonText: t('to_cancel'),
    searchText: t('_GATEWAY_LIST_SEARCH_TEXT'),
    searchPlaceholderText: t('search_with_ellipsis'),
    errorSearchingMessage: t('_GATEWAYS_LIST_ERROR_SEARCHING_ASSET_LENGTH'),
    errorAssetNotSelectedMessage: t('_GATEWAYS_LIST_ERROR_ASSET_NOT_SELECTED'),
    manualSearchButtonText: t('_GATEWAYS_LIST_ASSIGN_ASSET_MODAL_MANUAL_SEARCH_TEXT'),
    clientPlaceholderText: t('_GATEWAYS_LIST_ASSIGN_ASSET_MODAL_CLIENT_PLACEHOLDER'),
    projectPlaceholderText: t('_GATEWAYS_LIST_ASSIGN_ASSET_MODAL_PROJECT_PLACEHOLDER'),
    assetPlaceholderText: t('_GATEWAYS_LIST_ASSIGN_ASSET_MODAL_ASSET_PLACEHOLDER'),
  };

  const assignGatewayToAssetModalColumns = AssignGatewayToAssetModalColumns();

  const onOpenAssignToAssetModal = (gateway: CustomGatewaysListDataModel) => {
    setIsAssignAssetModalVisible(true);
    setSelectedGateway(gateway);
  };

  const resetValues = () => {
    setErrorSearching(false);
    setSearchTableVisible(false);
    setManualSearchingVisible(false);
    setSelectedAsset(null);
    setSelectedClient(null);
    setSelectedProject(null);
    setSelectedGateway(undefined);
    setSearchingVisible(true);
    setAssets([]);
    setClients([]);
    setProjects([]);
    setIsAssignAssetModalVisible(false);
    setSuccessAssigningGateway(false);
    setErrorAssigning(false);
  };

  const onCloseAssignGatewayToAssetModal = () => {
    resetValues();
  };

  const transformAssetsListData = (assets: Asset[]): AssetCustomData[] => {
    return assets.map(({ id, alias }) => ({ value: id, label: alias }));
  };

  const setAssetList = (assets: Asset[]) => {
    const transformedAssets = transformAssetsListData(assets);
    setAssets(transformedAssets);
  };

  const getAssetsList = async (searchValue?: string) => {
    setLoadingAssets(true);
    try {
      const assetsResponse = await getAssets({ search: searchValue });
      !!assetsResponse ? setAssetList(assetsResponse.data) : setMessageError({ description: t('') });
    } catch (error) {
      console.warn(error);
    } finally {
      setLoadingAssets(false);
    }
  };

  const transformClientsListData = (clients: ClientModel[]): ClientCustomData[] => {
    return clients.map(({ id, name }) => ({ value: id, label: name }));
  };

  const getClientsList = async () => {
    setLoadingClients(true);
    try {
      const clients = await GetClientsList();
      if (!!clients?.length) {
        const transformedClients = transformClientsListData(clients);
        setClients(transformedClients);
        transformedClients.length === 1 && setSelectedClient(transformedClients[0].value);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setLoadingClients(false);
    }
  };

  const transformProjectsListData = (clients: ProjectListResponseDataModel[]): ProjectCustomData[] => {
    return clients.map(({ id, name }) => ({ value: id, label: name }));
  };

  const getProjectsListByClientId = async (clientId: string) => {
    setLoadingProjects(true);
    try {
      const response = await GetProjectsListByClientId(clientId);
      if (!!response?.data?.length) {
        const transformedProjects = transformProjectsListData(response.data);
        setProjects(transformedProjects);
        response.data.length === 1 && setSelectedProject(response.data[0].id);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setLoadingProjects(false);
    }
  };

  const transformManualAssetsListData = (assets: ProjectAssetModel[]): AssetCustomData[] => {
    return assets.map(({ id, alias }) => ({ value: id, label: alias }));
  };

  const getAssetsListByProjectId = async (projectId: string) => {
    setLoadingManualAssets(true);
    try {
      const response = await GetAssetsListByProjectId({ host, token, projectId });
      const transformedAssets = transformManualAssetsListData(response.data);
      setAssets(transformedAssets);
      response.data.length === 1 && setSelectedAsset(response.data[0].id);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoadingManualAssets(false);
    }
  };

  const onSearchAssets = (value: string) => {
    getAssetsList(value);
    setErrorSearching(false);
    setSearchTableVisible(true);
    setManualSearchingVisible(false);
    setSelectedClient(null);
    setSelectedProject(null);
    setSelectedAsset(null);
    setClients([]);
    setProjects([]);
    setAssets([]);
  };

  const onErrorSearchAssets = () => {
    setAssets([]);
    setErrorSearching(true);
    setSearchTableVisible(false);
  };

  const onValidateSearchAssets = (value: string) => {
    value.length >= MINIMUM_SEARCH_VALUE_CHARACTERS ? onSearchAssets(value) : onErrorSearchAssets();
  };

  const onSelectAssetToBeLinked = (assetId: string) => {
    setSelectedAsset(assetId);
    setErrorAssigning(false);
  };

  const onClickAssignGatewayToAssetModaSearch = () => {
    setManualSearchingVisible(false);
    setSearchingVisible(true);
    setSelectedClient(null);
    setClients([]);
    setSelectedProject(null);
    setProjects([]);
    setSelectedAsset(null);
    setAssets([]);
  };

  const onClickAssignGatewayToAssetModalManualSearch = () => {
    setManualSearchingVisible(true);
    setSearchingVisible(false);
    setSearchTableVisible(false);
    setErrorSearching(false);
    setSelectedAsset(null);
    setAssets([]);
    getClientsList();
  };

  const onSelectClient = (value: string) => {
    setSelectedClient(value);
    setSelectedAsset(null);
    setSelectedProject(null);
  };

  const onSelectSearchedProject = (value: string) => {
    setSelectedProject(value);
    setSelectedAsset(null);
  };

  const checkErrors = (error: MessageErrorModel) => {
    const description =
      error.code === 409 ? t('_GATEWAYS_LIST_ASSIGN_ASSET_MODAL_ERROR_409_MESSAGE') : t('_GATEWAYS_LIST_ASSIGN_ASSET_MODAL_ERROR_MESSAGE');
    setMessageError({ description }, ERROR_MESSAGE_DURATION);
  };

  const onAssignGatewayToAsset = async () => {
    setAssigning(true);
    try {
      if (!!selectedGateway && !!selectedAsset) {
        await AssignGatewayToAsset({ host, token, gatewayId: selectedGateway.id, assetId: selectedAsset });
        setSuccessAssigningGateway(true);
        setMessageSuccess({ description: t('_GATEWAYS_LIST_ASSIGN_ASSET_MODAL_SUCCESS_MESSAGE') });
        resetValues();
      }
      if (!selectedAsset) {
        setErrorAssigning(true);
      }
    } catch (error) {
      checkErrors(error as MessageErrorModel);
    } finally {
      setAssigning(false);
    }
  };

  useEffect(() => {
    !!selectedProject && getAssetsListByProjectId(selectedProject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  useEffect(() => {
    !!selectedClient && getProjectsListByClientId(selectedClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  const assetsOptions: AssignToAssetModalAssetsOptions = {
    assets,
    selectedAsset,
    assignGatewayToAssetModalColumns,
    onSelectAssetToBeLinked,
    onValidateSearchAssets,
    loadingAssets,
    loadingManualAssets,
  };

  const clientsOptions: AssignToAssetModalClientsOptions = {
    clients,
    selectedClient,
    onSelectClient,
    loadingClients,
  };

  const projectsOptions: AssignToAssetModalProjectsOptions = {
    projects,
    selectedProject,
    onSelectSearchedProject,
    loadingProjects,
  };

  const assignToAssetModalOptions: AssignToAssetModalOptions = {
    assignGatewayToAssetModalTranslations: translations,
    onCloseAssignGatewayToAssetModal,
    onClickAssignGatewayToAssetModaSearch,
    onClickAssignGatewayToAssetModalManualSearch,
    onAssignGatewayToAsset,
    isAssignAssetModalVisible,
    errorSearching,
    errorAssigning,
    searchTableVisible,
    searchingVisible,
    manualSearchingVisible,
  };

  return {
    clientsOptions,
    projectsOptions,
    assetsOptions,
    assignToAssetModalOptions,
    onOpenAssignToAssetModal,
    successAssigningGateway,
    assigningToAsset: assigning,
  };
};
