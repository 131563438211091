import { FC } from 'react';
import TagSuccess from 'components/atoms/tags/TagSuccess';
import TagWarning from 'components/atoms/tags/TagWarning';
import TagError from 'components/atoms/tags/TagError';
import { InvitationStatusType } from 'core/domain/anonymousAccess/mappers';
import { AccessStatusTypeText } from '../template/CheckInListTemplate/CheckInColumns/index';

export interface Props {
  status: string;
  text: AccessStatusTypeText;
}

const statusTagManager = {
  [InvitationStatusType.ACTIVE]: (text: AccessStatusTypeText) => <TagSuccess text={text.ACTIVE} />,
  [InvitationStatusType.EXPIRED]: (text: AccessStatusTypeText) => <TagWarning text={text.EXPIRED} />,
  [InvitationStatusType.REVOKED]: (text: AccessStatusTypeText) => <TagError text={text.REVOKED} />,
}

const AccessStatusTagManager: FC<Props> = ({ status, text }) => {
  return statusTagManager[status as InvitationStatusType](text);
}

export default AccessStatusTagManager
