import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { MaintenanceAction } from 'core/domain/gateways/model/reportMaintenance';
import { SensorWithPasswordsModel, SensorWithPasswordsPasswordModel } from 'core/domain/sensors/models/sensorEntityModels';
import { UpdatePasswordSensor } from 'core/domain/sensors/repositories/updatePasswordSensor';
import { GetSensorsListWithPasswordsByAssetId } from 'core/domain/sensors/repositories/getSensorsListWithPasswordsByAssetId';

const TIME_DELAY_TO_UPDATE_PASSWORD_SENSOR = 250;
const TIME_DELAY_TO_UPDATE_ALL_PASSWORDS_SENSORS = 5000;
const TIME_DELAY_TO_RESET_ERROR_UPDATE = 500;
const TIME_MESSAGE = 3;

export const useAccessUpdate = () => {
  const { t } = useTranslation();
  const { assetId } = useParams<{ assetId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deviceId, setDeviceId] = useState<string>('');
  const [isErrorSendingUpdate, setIsErrorSendingUpdate] = useState<boolean>(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean | null>(null);

  let timeoutRefreshingDataId: NodeJS.Timeout;
  const timeoutUpdatingIds: NodeJS.Timeout[] = [];

  const updateSuccessMessage = t('_ACCESS_PASSWORDS_UPDATE_SUCCESS_MESSAGE');
  const updateErrorMessage = t('_ACCESS_PASSWORDS_UPDATE_ERROR_MESSAGE');

  const getDeviceId = (deviceId: string) => {
    setDeviceId(deviceId);
  };

  const resetUpdateStatusRequest = () => {
    setIsUpdateSuccess(null);
  };

  const updatePasswordSensor = async (sensor: SensorWithPasswordsPasswordModel) => {
    const { gatewayId, deviceId, password, sensorId } = sensor;
    try {
      await UpdatePasswordSensor({ deviceId, gatewayId, action: MaintenanceAction.MANAGEMENT_PASSWORD, password, sensorId });
    } catch (error: any) {
      setIsErrorSendingUpdate(true);
      timeoutUpdatingIds.forEach((id) => clearTimeout(id));
      clearTimeout(timeoutRefreshingDataId);
      setIsLoading(false);
      setTimeout(() => setIsErrorSendingUpdate(false), TIME_DELAY_TO_RESET_ERROR_UPDATE);
    }
  };

  const updateMultiplePasswordSensors = async (sensors: SensorWithPasswordsPasswordModel[]) => {
    sensors.forEach((sensor, index) => {
      if (!isErrorSendingUpdate) {
        const delay = index * TIME_DELAY_TO_UPDATE_PASSWORD_SENSOR;
        const timeoutUpdatePasswordId = setTimeout(async () => {
          await updatePasswordSensor(sensor);
        }, delay);
        timeoutUpdatingIds.push(timeoutUpdatePasswordId);
      }
    });
  };

  const getDevicesWithPasswordSensorsList = async (): Promise<SensorWithPasswordsModel[] | undefined> => {
    try {
      const devicesWithPasswordSensors = await GetSensorsListWithPasswordsByAssetId(assetId);
      return devicesWithPasswordSensors;
    } catch (error) {
      console.warn(error);
    }
  };

  const checkIfUpdateSuccess = (selectedDevice: SensorWithPasswordsModel, oldSensors: SensorWithPasswordsPasswordModel[]) => {
    return oldSensors.every((oldSensor) => {
      const sensor = selectedDevice.sensorPasswords.filter((sensor) => sensor.sensorId === oldSensor.sensorId)[0];
      return sensor.password === oldSensor.password;
    });
  };

  const processUpdateResponse = async (changedPasswordSensors: SensorWithPasswordsPasswordModel[]) => {
    try {
      const devices = await getDevicesWithPasswordSensorsList();
      if (devices) {
        const selectedDevice = devices.filter((device) => device.sensorPasswords[0].deviceId === deviceId)[0];
        const isSuccessUpdating = selectedDevice && checkIfUpdateSuccess(selectedDevice, changedPasswordSensors);
        isSuccessUpdating && message.success(updateSuccessMessage, TIME_MESSAGE);
        !isSuccessUpdating && message.error(updateErrorMessage, TIME_MESSAGE);
        setIsUpdateSuccess(isSuccessUpdating);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = async (oldSensors: SensorWithPasswordsPasswordModel[]) => {
    setIsLoading(true);
    await updateMultiplePasswordSensors(oldSensors);
    timeoutRefreshingDataId = setTimeout(() => processUpdateResponse(oldSensors), TIME_DELAY_TO_UPDATE_ALL_PASSWORDS_SENSORS);
  };

  return {
    getDeviceId,
    onSave,
    resetUpdateStatusRequest,
    updateSuccess: isUpdateSuccess,
    loadingUpdate: isLoading,
  };
};
