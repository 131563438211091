import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ProjectAppCustomizationFormValues,
  ProjectAppCustomizationFormValuesNames,
  ProjectAppCustomizationInputValue,
  ProjectAppCustomizationTranslations,
  ProjectCustomDesignAppModel,
} from './models';
import { UpdateProjectModel } from 'core/domain/project/model/projectModel';
import { useProjectCRUD } from 'hooks/useProject/useProjectCRUD';

enum CustomizationAppFormValueTranslation {
  TITLE = '_CUSTOMIZATION_APP_FORM_TITLE',
  BACKGROUND_IMAGE = '_CUSTOMIZATION_APP_FORM_VALUE_BACKGROUND_IMAGE',
  BACKGROUND_IMAGE_REQUIREMENTS = '_CUSTOMIZATION_APP_FORM_VALUE_BACKGROUND_IMAGE_REQUIREMENTS',
  INFO = '_CUSTOMIZATION_APP_FORM_INFO',
  CANCEL = '_CUSTOMIZATION_APP_FORM_CANCEL',
  PUBLISH = '_CUSTOMIZATION_APP_FORM_PUBLISH',
}

export const useProjectAppCustomization = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();
  const [form] = Form.useForm<ProjectAppCustomizationFormValues>();
  const [initialFormValues, setInitialFormValues] = useState({} as ProjectCustomDesignAppModel);
  const [formValues, setFormValues] = useState({} as ProjectAppCustomizationFormValues);
  const [initialPreViewValues, setInitialPreviewValues] = useState({} as ProjectAppCustomizationFormValues);
  const [previewValues, setPreviewValues] = useState({} as ProjectAppCustomizationFormValues);
  const [areFormValuesCanceled, setAreFormValuesCanceled] = useState<boolean>(false);
  const [areThereValidChanges, setAreThereValidChanges] = useState<boolean>(false);
  const [isCancelEnable, setIsCancelEnable] = useState<boolean>(false);
  const { data: projectData, loading, updating, updateProject, getProjectById } = useProjectCRUD();

  const projectUpdateTranslations: ProjectAppCustomizationTranslations = {
    title: t(CustomizationAppFormValueTranslation.TITLE),
    backgroundImage: t(CustomizationAppFormValueTranslation.BACKGROUND_IMAGE),
    backgroundImageRequirements: t(CustomizationAppFormValueTranslation.BACKGROUND_IMAGE_REQUIREMENTS),
    info: t(CustomizationAppFormValueTranslation.INFO),
    cancel: t(CustomizationAppFormValueTranslation.CANCEL),
    publish: t(CustomizationAppFormValueTranslation.PUBLISH),
  };

  const getInitialFormValues = ({ backgroundImage, isDefault }: ProjectCustomDesignAppModel) => {
    setFormValues({ backgroundImage });
    setInitialFormValues({ backgroundImage, isDefault });
  };

  const getInitialPreviewValues = ({ backgroundImage }: ProjectAppCustomizationFormValues) => {
    setPreviewValues({ backgroundImage });
    setInitialPreviewValues({ backgroundImage });
  };

  const getInitialValues = ({ backgroundImage, isDefault }: ProjectCustomDesignAppModel) => {
    getInitialFormValues({ backgroundImage, isDefault });
    getInitialPreviewValues({ backgroundImage });
  };

  useEffect(() => {
    if (projectData) {
      const { backgroundImage, isBackgroundImageDefault } = projectData;
      getInitialValues({ backgroundImage, isDefault: isBackgroundImageDefault });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  const onChangeBackgroundImage = (backgroundImage: string) => {
    setPreviewValues({ ...formValues, backgroundImage });
    setFormValues({ ...formValues, backgroundImage });
  };

  const onCancelFormValues = async () => {
    const { backgroundImage } = initialFormValues;
    setFormValues({ backgroundImage });
  };

  const onCancelPreViewValues = async () => {
    const { backgroundImage } = initialPreViewValues;
    setPreviewValues({ backgroundImage });
  };

  const onCancel = async () => {
    setAreFormValuesCanceled(true);
    try {
      await onCancelFormValues();
      await onCancelPreViewValues();
    } finally {
      setAreFormValuesCanceled(false);
    }
  };

  const checkIfBackgroundImageIsNotEmptyAndDefault = (value: string): boolean => {
    const isEmptyDefaultValue = value === '' && initialFormValues.isDefault;
    return !isEmptyDefaultValue;
  };

  const changeCancelStatus = (value: string) => {
    const isCancelable = value !== initialFormValues.backgroundImage;
    setIsCancelEnable(isCancelable);
  };

  const updateFormValuesManager = {
    [ProjectAppCustomizationFormValuesNames.BACKGROUND_IMAGE]: (value: string) => onChangeBackgroundImage(value),
  };

  const checkForFormValuesChangesManager = {
    [ProjectAppCustomizationFormValuesNames.BACKGROUND_IMAGE]: (value: string): boolean =>
      value !== initialFormValues.backgroundImage && checkIfBackgroundImageIsNotEmptyAndDefault(value),
  };

  const updateCancelStatus = {
    [ProjectAppCustomizationFormValuesNames.BACKGROUND_IMAGE]: (value: string) => changeCancelStatus(value),
  };

  const onChangeMade = ({ label, value }: ProjectAppCustomizationInputValue) => {
    updateFormValuesManager[label](value);
    updateCancelStatus[label](value);
    const hasFormValueChanged = checkForFormValuesChangesManager[label](value);
    setAreThereValidChanges(hasFormValueChanged);
  };

  const transformDataToUpdateProject = (): UpdateProjectModel => {
    const backgroundImage = formValues.backgroundImage || null;
    const { id, name, usersRequireVerification, usersVerifiers, logo, type, supportEmail, status } = projectData;
    const transformedUsersVerifiers = !!usersVerifiers.length ? usersVerifiers.map(({ id }) => id) : [];
    return {
      id,
      name,
      usersRequireVerification,
      usersVerifiers: transformedUsersVerifiers,
      logo,
      backgroundImage,
      type,
      supportEmail,
      status,
    };
  };

  const onPublish = async () => {
    const transformedProject = transformDataToUpdateProject();
    try {
      await updateProject(transformedProject);
      await getProjectById(projectId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return {
    initialFormValues,
    customizationFormValues: formValues,
    customizationPreviewValues: previewValues,
    translations: projectUpdateTranslations,
    form,
    Form,
    onChangeMade,
    onCancel,
    onPublish,
    loading,
    updating,
    areThereValidChanges,
    isCancelEnable,
    formValuesCanceled: areFormValuesCanceled,
  };
};
