import { useState, useEffect } from 'react';

interface SizeType {
  width: number;
  height: number;
}
/**
 * Hook function to know the screen size
 * @example const { width, height } = useWindowSize();
 */
export const useWindowSize = (): SizeType => {
  const [windowSize, setWindowSize] = useState<SizeType>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = (): void => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
