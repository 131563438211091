import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TabsAtom, { TabsAtomProps } from 'components/atoms/TabsAtom';
import { ClientCustomizationSubTab } from '../../../resources/models';

const SubTabTitles: FC<TabsAtomProps> = (props) => {
  const { t } = useTranslation();

  return (
    <TabsAtom {...props}>
      <TabsAtom.TabPane tab={t('_ASSETS_MANAGER_AND_EMAILS')} key={ClientCustomizationSubTab.ASSETS_AND_EMAILS} />
    </TabsAtom>
  );
};

export default SubTabTitles;
