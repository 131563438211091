import { FC } from "react";
import { useProjectLayoutConfig } from "./resources/useProjectLayoutConfig";
import { ProjectLayoutConfigLoadingTemplate } from "components/template/ProjectLayoutConfigLoadingTemplate";
import { ProjectLayoutConfigTemplate } from "components/template/ProjectLayoutConfigTemplate";
import { ProjectLayoutConfigEmptyTemplate } from "components/template/ProjectLayoutConfigEmptyTemplate";
import { ProjectConfigAreaDrawer } from "components/organisms/ProjectConfigAreaDrawer";
import { ProjectConfigSecondLevelHorizontalModal } from "components/organisms/ProjectConfigSecondLevelHorizontalModal";
import { ProjectConfigAreaFormValues } from "components/molecules/ProjectConfigAreaForm";
import Paper from "components/atoms/Paper";

interface Props {
  isLayoutConfigEnabled: boolean;
}

export const TabLayoutConfig: FC<Props> = ({ isLayoutConfigEnabled }) => {
  const {
    tableMainConfig,
    firstLevelAreaConfig,
    secondLevelAreaConfig,
    emptyStateContent,
    isLoadingLayoutConfig,
  } = useProjectLayoutConfig();

  const {
    onClickAddFirstLevelArea,
    firstLevelAreaDrawerTitle,
    firstLevelAreaInitialValues,
    addFirstLevelArea,
    editFirstLevelArea,
    onCancelAddFirstLevelAreaDrawer,
    isAddFirstLevelAreaDrawerVisible,
    isSubmittingFirstLevelArea,
  } = firstLevelAreaConfig;

  const {
    secondLevelAreaModalTitle,
    secondLevelAreaInitialValues,
    addSecondLevelArea,
    editSecondLevelArea,
    onCancelSecondLevelAreaModal,
    isSecondLevelAreaModalVisible,
    isSubmittingSecondLevelArea,
  } = secondLevelAreaConfig;

  return (
    <>
      {isLayoutConfigEnabled &&
        <>
          <Paper>
            {isLoadingLayoutConfig &&
              <ProjectLayoutConfigLoadingTemplate />
            }
            {!isLoadingLayoutConfig && tableMainConfig &&
              <ProjectLayoutConfigTemplate
                tableConfig={tableMainConfig}
                onClickAdd={onClickAddFirstLevelArea}
              />
            }
            {!isLoadingLayoutConfig && !tableMainConfig &&
              <ProjectLayoutConfigEmptyTemplate
                title={emptyStateContent.title}
                description={emptyStateContent.description}
                buttonText={emptyStateContent.buttonText}
                onClickAdd={onClickAddFirstLevelArea}
              />
            }
          </Paper>
          <ProjectConfigAreaDrawer
            title={firstLevelAreaDrawerTitle}
            initialValues={firstLevelAreaInitialValues as ProjectConfigAreaFormValues}
            onAdd={addFirstLevelArea}
            onEdit={editFirstLevelArea}
            onCancel={onCancelAddFirstLevelAreaDrawer}
            open={isAddFirstLevelAreaDrawerVisible}
            loading={isSubmittingFirstLevelArea}
          />
          <ProjectConfigSecondLevelHorizontalModal
            title={secondLevelAreaModalTitle}
            initialValues={secondLevelAreaInitialValues}
            onAdd={addSecondLevelArea}
            onEdit={editSecondLevelArea}
            onCancel={onCancelSecondLevelAreaModal}
            open={isSecondLevelAreaModalVisible}
            loading={isSubmittingSecondLevelArea}
          />
        </>
      }
    </>
  )
};







