import { useState } from 'react';
import { RequiredFieldsError, RequiredFormValues } from '../models';

export const useFieldsEventsAndNewsFormErrors = () => {
  const [errorTitle, setErrorTitle] = useState<boolean>(false);
  const [errorImage, setErrorImage] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<boolean>(false);

  const setErrors = ({ title, image, description }: RequiredFormValues): void => {
    setErrorTitle(!title);
    setErrorImage(!image);
    setErrorDescription(!description);
  };

  const resetErrors = () => {
    setErrorTitle(false);
    setErrorImage(false);
    setErrorDescription(false);
  };

  const errors = {
    title: errorTitle,
    image: errorImage,
    description: errorDescription,
  } as RequiredFieldsError

  return {
    errors,
    setErrors,
    resetErrors,
  };
};
