import { UsersListUnstructuredStatisticsModel } from '../models/usersModel';
import { PayloadUsersListStatisticsModel } from '../models/usersPayloadModel';

export const payloadToUsersListUnstructuredStatistics = ({
  average,
  users,
}: PayloadUsersListStatisticsModel): UsersListUnstructuredStatisticsModel => {
  return {
    average: average ?? 0,
    labels: users.map((item) => item.label) ?? [],
    values: users.map((item) => item.value) ?? [],
  };
};
