import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { createAssetToPayload } from '../mappers/createAssetToPayload';
import { RegisterAssetModel } from '../models';

const getUrl = (host: string) => {
  return `${host}/assets`;
};

export const CreateAsset = async ({ token, host, asset }: { token: string; host: string; asset: RegisterAssetModel }): Promise<void> => {
  try {
    const url = getUrl(host);
    const data = createAssetToPayload(asset);

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data },
    };

    (await axios(options)) as AxiosResponse<void>;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
