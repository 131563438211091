import { DATE_FORMAT, DATE_FORMAT_1, DATE_FORMAT_SLASH, TIME_FORMAT } from 'constants/date';
import moment, { Moment } from 'moment';

export const formatDate = (date: Moment) => date.format(DATE_FORMAT_1);

export const formatSpanishDateWithSlash = (date: number) => moment.unix(date).format(DATE_FORMAT_SLASH);

export const formatSpanishDateAndTimeWithSlash = (date: number) => {
  return `${moment.unix(date).format(DATE_FORMAT_SLASH)} ${moment.unix(date).format(TIME_FORMAT)}h.`;
} 

export const formatTimeDate = (date: number) => {
  return `${moment.unix(date).format(TIME_FORMAT)}h. ${moment.unix(date).format(DATE_FORMAT)}`;
};

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const disabledDate = (current: Moment) => {
  return current < moment().subtract(1, 'days').endOf('day');
};

export const disabledDateTime = (date: Moment | null) => {
  const itIsAfterNow = date && date.diff(moment()) < 0;
  if (itIsAfterNow) {
    const currentDate = new Date();
    return {
      disabledHours: () => range(0, 24).splice(0, currentDate.getHours()),
      disabledMinutes: () => range(0, currentDate.getMinutes()),
    };
  }
  return {};
};

export const checkIfDateHasExpired = (date: number): boolean => {
  const fromMoment = moment.unix(date);
  const nowMoment = moment();
  return nowMoment.isAfter(fromMoment);
};
