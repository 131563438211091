import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { AccessDevicesListResponseModel, AccessDevicesListResponsePayload } from '../models';
import { payloadToAccessDevicesListByProjectIdResponse } from '../mappers';

const getUrl = ({ host, projectId, page, size }: { host: string; projectId: string; page: number; size: number }) => {
  const params = new URLSearchParams();
  page && params.append('page[number]', `${page}`);
  size && params.append('page[size]', `${size}`);

  return `${host}/devices/access/project/${projectId}/list?${params}`;
};

export const GetAccessDevicesListByProjectId = async ({
  host,
  token,
  projectId,
  page,
  size,
}: {
  host: string;
  token: string;
  projectId: string;
  page: number;
  size: number;
}): Promise<AccessDevicesListResponseModel> => {
  try {
    const url = getUrl({ host, projectId, page, size });
    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<AccessDevicesListResponsePayload>;
    return payloadToAccessDevicesListByProjectIdResponse(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
