import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DividerAtom from 'components/atoms/DividerAtom';
import TabSidebarMenuList from '../TabSidebarMenuList';
import './styles.scss';
import {
  CommonAreasAndBookingsVisorViews,
} from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabCommonAreasAndBookings/hook/useCommonAreasAndBookings';

export interface TabCommonAreasAndBookingsMenuItem {
  id: string;
  name: string;
  disabled: boolean;
}

interface TabCommonAreasAndBookingsMenuProps {
  menuListItems: TabCommonAreasAndBookingsMenuItem[];
  selectedItemId?: string;
  visibleType: CommonAreasAndBookingsVisorViews;
  onVisibleCreate: () => void;
  onClickMenuItem: (id: string) => void;
  onVisibleBookingList: () => void;
}

const TabCommonAreasAndBookingsMenu: FC<TabCommonAreasAndBookingsMenuProps> = ({
  menuListItems,
  selectedItemId,
  visibleType,
  onVisibleCreate,
  onClickMenuItem,
  onVisibleBookingList,
}) => {
  const { t } = useTranslation();
  const getClassName = visibleType === CommonAreasAndBookingsVisorViews.CREATE ? 'TabCommonAreasAndBookingsMenu__link--disabled' : 'TabCommonAreasAndBookingsMenu__link--active';
  const getClassNameBookings = visibleType === CommonAreasAndBookingsVisorViews.CONTENT_BOOKINGS ? 'TabCommonAreasAndBookingsMenu__link--disabled' : 'TabCommonAreasAndBookingsMenu__link';

  return (
    <div>
      <div className={`TabCommonAreasAndBookingsMenu__title  ${getClassNameBookings}`} onClick={() => onVisibleBookingList()}>
        {t('_COMMON_AREA_BOOKINGS')}
      </div>
      <DividerAtom />
      <div
        className={`TabCommonAreasAndBookingsMenu__title ${getClassName}`}
        onClick={visibleType === CommonAreasAndBookingsVisorViews.CREATE ? undefined : onVisibleCreate}
      >
        {t('_COMMON_AREA_ADD_SPACE')}
      </div>
      <TabSidebarMenuList
        listItems={menuListItems}
        selectedItemId={selectedItemId}
        onClick={onClickMenuItem}
      />
    </div>
  );
};

export default TabCommonAreasAndBookingsMenu;
