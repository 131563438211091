import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnAssignGatewayToProjectModalTranslations } from 'components/organisms/UnAssignGatewayToProject.ts';
import { useMessage } from 'hooks/useMessage';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { UnAssignToProjectModalOptions } from './utils';
import { UpdateGatewayDataModel } from 'core/domain/gateways/model';
import { UpdateGatewayDetail } from 'core/domain/gateways/repositories/updateGatewayDetail';

export const useUnAssignToProject = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [unAssigning, setUnAssigning] = useState<boolean>(false);
  const [successUnAssigningGateway, setSuccessUnAssigningGateway] = useState<boolean>(false);
  const [selectedGateway, setSelectedGateway] = useState<string | null>(null);

  const translations: UnAssignGatewayToProjectModalTranslations = {
    title: t('_GATEWAYS_LIST_UNASSIGN_PROJECT_MODAL_TITLE'),
    description: t('_GATEWAYS_LIST_UNASSIGN_PROJECT_MODAL_DESCRIPTION'),
    mainButtonText: t('_GATEWAYS_LIST_UNASSIGN_PROJECT_MODAL_MAIN_BUTTON_TEXT'),
    secondaryButtonText: t('to_cancel'),
  };

  const onOpenUnAssignToProjectModal = (gatewayId: string) => {
    setModalVisible(true);
    setSelectedGateway(gatewayId);
  };

  const onCloseUnAssignToProjectModal = () => {
    setModalVisible(false);
    setSuccessUnAssigningGateway(false);
    setSelectedGateway(null);
  };

  const onUnAssignGatewayToProject = async () => {
    setUnAssigning(true);
    try {
      if (selectedGateway) {
        const gateway: UpdateGatewayDataModel = { projectId: null };
        await UpdateGatewayDetail({ host, token, gatewayId: selectedGateway, gateway });
        setSuccessUnAssigningGateway(true);
        setMessageSuccess({ description: t('_GATEWAYS_LIST_UNASSIGN_PROJECT_MODAL_SUCCESS') });
      }
      onCloseUnAssignToProjectModal();
    } catch (error) {
      setMessageError({ description: t('_GATEWAYS_LIST_UNASSIGN_PROJECT_MODAL_ERROR') });
    } finally {
      setUnAssigning(false);
    }
  };

  const unAssigningToProjectModalOptions: UnAssignToProjectModalOptions = {
    unAssignGatewayToProjectModalTranslations: translations,
    onCloseUnAssignToProjectModal,
    onUnAssignGatewayToProject,
    unAssignToProjectModalVisible: modalVisible,
    unAssigning,
  };

  return {
    unAssigningToProjectModalOptions,
    onOpenUnAssignToProjectModal,
    successUnAssigningGateway,
  };
};
