import { getActiveGatewaysStatisticsPath } from 'core/endspoints/gatewaysPath';
import { ActiveGatewaysStatisticsPayloadModel } from '../model/gateway/gatewayUnparsed';
import api from 'services/api';
import { payloadToActiveGatewaysStatistics } from '../mappers/payloadToActiveGatewaysStatistics';
import { ActiveGatewaysStatisticsEntity } from '../entities/activeGatewaysStatistics';
import { ActiveGatewaysStatisticsModel } from '../model/gateway/gatewayParsed';

export const GetActiveGatewaysStatistics = async (): Promise<Readonly<ActiveGatewaysStatisticsModel> | undefined> => {
  const url = getActiveGatewaysStatisticsPath();
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    const response = await api.get<ActiveGatewaysStatisticsPayloadModel | undefined>(url);
    const mappedData = response?.data && payloadToActiveGatewaysStatistics(response.data);
    const activeGatewaysStatistics = mappedData && ActiveGatewaysStatisticsEntity(mappedData);
    return activeGatewaysStatistics;
  } catch (error: any) {
    throw new Error(error);
  }
};
