import { FC } from 'react';
import { Image } from 'antd';
import { Sku } from 'models/gateways.model';
import { IMAGES } from 'constants/images';
import { GATEWAY_IMAGES } from './config';
import './styles.scss';

interface GatewayImageProps {
  className: string;
  sku: Sku | null | undefined;
}

const GatewayImage: FC<GatewayImageProps> = ({ className, sku }) => {
  return (
    <Image className={`${className} GatewayImage`} key={sku ?? 'no_image'} src={(sku && GATEWAY_IMAGES[sku].image) ?? IMAGES.noImage} />
  );
};

export default GatewayImage;
