import { FC } from 'react';
import { ModalProps } from 'antd/lib/modal';
import { Button, Modal } from 'antd';
import { ProjectEditFormData, ProjectEditModalTranslations } from 'components/pages/ProjectPage/resources/models';
import ProjectEditForm from 'components/molecules/ProjectEditForm';
import { ProjectUsersVerifiersModel } from 'core/domain/project/model/projectModel';

interface Props extends ModalProps {
  modalTranslations: ProjectEditModalTranslations;
  formData: ProjectEditFormData;
  allowedUsersToVerifyAccounts: ProjectUsersVerifiersModel[];
}

const ProjectEditModal: FC<Props> = ({ modalTranslations, formData, onCancel, allowedUsersToVerifyAccounts, ...props }) => {
  const { title, editButton, cancelButton } = modalTranslations;
  const { form, editFormTranslations, typeOptions, onSubmitEditForm, onSubmitEditFormFailed, onSelectType, validateEmailFormat, editing } = formData;

  return (
    <Modal
      {...props}
      title={title}
      onCancel={onCancel}
      footer={[
        <Button key={'back'} onClick={onCancel}>
          {cancelButton}
        </Button>,
        <Button key={'submit'} type={'primary'} loading={editing} onClick={() => form.submit()}>
          {editButton}
        </Button>,
      ]}
      destroyOnClose
    >
      <ProjectEditForm
        form={form}
        allowedUsersToVerifyAccounts={allowedUsersToVerifyAccounts}
        formTranslations={editFormTranslations}
        typeOptions={typeOptions}
        onFinish={onSubmitEditForm}
        onFinishFailed={onSubmitEditFormFailed}
        onSelectType={onSelectType}
        validateEmailFormat={validateEmailFormat}
        editing={editing}
      />
    </Modal>
  );
};

export default ProjectEditModal;
