import { FC } from 'react';
import { Button, TimePicker } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { CommonAreaTimeAvailabilityModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { TIME_FORMAT } from 'constants/date';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import './styles.scss';

interface Props {
  timeAvailability: (CommonAreaTimeAvailabilityModel | null)[];
  onAddTimeAvailabilityInterval: () => void;
  onChangeTimeAvailability: (values: [Moment, Moment] | null, index: number) => void;
  onDeleteTimeAvailabilityInterval: (index: number) => void;
  disabled: boolean;
}

const CommonAreaTimeAvailabilitySelector: FC<Props> = ({
  timeAvailability,
  onAddTimeAvailabilityInterval,
  onChangeTimeAvailability,
  onDeleteTimeAvailabilityInterval,
  disabled,
}) => {
  return (
    <>
      {timeAvailability && timeAvailability.map((time, index) => {
        return (
          <div className={'CommonAreaTimeAvailabilitySelector__timeRange-wrapper'} key={index}>
            <TimePicker.RangePicker
              className={'CommonAreaTimeAvailabilitySelector__timeRange'}
              value={time && [moment(time.start, TIME_FORMAT), moment(time.end, TIME_FORMAT)]}
              onChange={(values) => onChangeTimeAvailability(values as unknown as [Moment, Moment], index)}
              disabled={disabled}
              format={TIME_FORMAT}
              showSecond={false}
            />
            {timeAvailability.length > 1 && (
              <Button className={'CommonAreaTimeAvailabilitySelector__timeRange-wrapper-delete-button'} onClick={() => onDeleteTimeAvailabilityInterval(index)}>
                <DeleteOutlined />
              </Button>
            )}
          </div>
        );
      })}
      <DefaultButton
        title={'Añadir intervalo'}
        onClick={onAddTimeAvailabilityInterval}
        className={'CommonAreaTimeAvailabilitySelector__timeRange-add-button'}
        disabled={disabled}
      />
    </>
  )
}

export default CommonAreaTimeAvailabilitySelector;