import { FC } from 'react';
import { Menu } from 'antd';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { ClientMenuExtraPlacement } from 'hooks/useClient/models';
import { ClientMenuExtraTranslations } from 'hooks/useClient/useClientTranslations';

interface Props {
  onClickContextualEditButton: () => void;
  itemsTranslations: ClientMenuExtraTranslations;
}

const ClientMenuExtra: FC<Props> = ({ onClickContextualEditButton, itemsTranslations }) => {
  return (
    <ContextualMenu placement={ClientMenuExtraPlacement.BOTTOM_RIGHT} menu={
      <Menu>
        <Menu.Item key={'edit'} onClick={onClickContextualEditButton}>
          {itemsTranslations.editButton}
        </Menu.Item>
      </Menu>
    } />
  );
}

export default ClientMenuExtra;