import { FC } from 'react';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import ModalAtom, { ModalAtomProps } from 'components/atoms/ModalAtom';

interface Props extends ModalAtomProps {
  title: string;
  text: string;
  mainButtonText: string;
  secondaryButtonText: string;
  onConfirm: () => void;
  visible: boolean;
  loading: boolean;
}

const BookingDeleteConfirmModal: FC<Props> = ({
  title,
  text,
  mainButtonText,
  secondaryButtonText,
  onConfirm,
  onCancel,
  visible,
  loading,
}) => {
  return (
    <ModalAtom
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={onConfirm}
      footer={[
        <DefaultButton
          key={'back'}
          title={secondaryButtonText}
          onClick={onCancel}
        />,
        <PrimaryButton
          key={'confirm'}
          loading={loading}
          title={mainButtonText}
          onClick={onConfirm}
        />,
      ]}
      destroyOnClose
      getContainer={false}
    >
      <p>{text}</p>
    </ModalAtom>
  );
};

export default BookingDeleteConfirmModal;
