import { FC } from 'react';
import { ModalProps, Space, Table } from 'antd';
import ModalAtom from 'components/atoms/ModalAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { CustomAssetData, ShareSensorOptionsModel } from 'components/pages/ProjectSharedGatewayDetailPage/Tabs/TabDevices/resources/useShareSensor';

export interface ShareSensorTranslationsModel {
  title: string;
  mainButtonText: string;
  secondaryButtonText: string;
  selectedAssetsText: string;
}

interface Props extends ModalProps {
  modalOptions: ShareSensorOptionsModel;
}

const TABLE_HEIGHT = 400;

const ShareSensorModal: FC<Props> = ({ onCancel, modalOptions, ...props }) => {
  const {
    translations,
    columns,
    assets,
    selectedRowKeys,
    onChangeAssetShareSensor,
    onUpdateGatewayWithNewSensors,
    loading,
    updating,
  } = modalOptions;

  const { title, mainButtonText, secondaryButtonText, selectedAssetsText } = translations;

  return (
    <ModalAtom
      {...props}
      title={<Space>{title}</Space>}
      onCancel={onCancel}
      destroyOnClose
      width={'75%'}
      footer={[
        <DefaultButton key={'back'} onClick={onCancel} title={secondaryButtonText} />,
        <PrimaryButton key={'submit'} loading={updating} onClick={onUpdateGatewayWithNewSensors} title={mainButtonText} />,
      ]}
    >
      <div>
        <p>{selectedAssetsText}</p>
        <Table<CustomAssetData>
          loading={loading}
          columns={columns}
          rowKey={'assetId'}
          dataSource={assets}
          pagination={false}
          bordered
          scroll={{ y: TABLE_HEIGHT }}
          rowSelection={{ selectedRowKeys, onChange: onChangeAssetShareSensor }}
        />
      </div>
    </ModalAtom>
  );
}

export default ShareSensorModal;