import { FC } from 'react';
import { Button, ButtonProps } from 'antd';

const LinkButton: FC<ButtonProps> = ({ title, ...props }) => {
  return (
    <Button type='link' {...props}>
      {title}
    </Button>
  );
};

export default LinkButton;
