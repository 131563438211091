export interface ProjectAppCustomizationFormValues {
  backgroundImage: string;
}

export enum ProjectAppCustomizationFormValuesNames {
  BACKGROUND_IMAGE = 'backgroundImage',
}

export interface ProjectAppCustomizationInputValue {
  label: ProjectAppCustomizationFormValuesNames;
  value: string;
}

export interface ProjectAppCustomizationTranslations {
  title: string;
  backgroundImage: string;
  backgroundImageRequirements: string;
  info: string;
  cancel: string;
  publish: string;
}

export interface ProjectCustomDesignAppModel {
  backgroundImage: string;
  isDefault: boolean;
}
