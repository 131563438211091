import { createContainer } from 'unstated-next'
import { useState } from 'react'

const UIContainer = createContainer(() => {
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false)

  return { menuCollapsed, setMenuCollapsed }
})

export default UIContainer
