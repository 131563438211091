import { FC } from 'react';
import { Alert, FormInstance, Tooltip } from 'antd'
import { Icon } from 'components/atoms/Icons';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import ImagePickerDisplay from '../ImagePickerDisplay';
import { ProjectAppCustomizationFormValues, ProjectAppCustomizationFormValuesNames, ProjectAppCustomizationInputValue, ProjectAppCustomizationTranslations } from 'components/pages/ProjectPage/Tabs/TabCustomization/SubTabs/SubTabAppAndWebApp/resources/models';
import './styles.scss';

interface Props {
  translations: ProjectAppCustomizationTranslations;
  initialValues: ProjectAppCustomizationFormValues;
  customizationFormValues: ProjectAppCustomizationFormValues;
  Form: any;
  form: FormInstance<ProjectAppCustomizationFormValues>;
  onChangeMade: ({ label, value }: ProjectAppCustomizationInputValue) => void;
  onCancel: () => void;
  onPublish: () => void;
  disabled: boolean;
  updating: boolean;
  canceled: boolean;
  cancelButtonDisabled: boolean;
}

const ProjectAppCustomizationForm: FC<Props> = ({
  translations,
  Form,
  form,
  onChangeMade,
  onCancel,
  onPublish,
  initialValues,
  customizationFormValues,
  disabled,
  updating,
  canceled,
  cancelButtonDisabled,
}) => {
  const { backgroundImage } = customizationFormValues;
  const { backgroundImage: backgroundImageTranslation, backgroundImageRequirements, info, cancel, publish } = translations;

  return (
    <Form form={form} layout={'vertical'} requiredMark={false}>
      <Form.Item
        name={ProjectAppCustomizationFormValuesNames.BACKGROUND_IMAGE}
        label={(
          <>
            <span className={'AppCustomizationForm__image-info'}>{backgroundImageTranslation}</span>
            <Tooltip title={backgroundImageRequirements} overlayInnerStyle={{ width: 290, textAlign: 'center', marginLeft: -20 }}>
              <Icon.InfoCircleFilled />
            </Tooltip>
          </>
        )}
      >
        <ImagePickerDisplay
          onChange={(value: string) => onChangeMade({ label: ProjectAppCustomizationFormValuesNames.BACKGROUND_IMAGE, value })}
          initialValues={initialValues.backgroundImage}
          temporaryImageUrl={backgroundImage}
          canceled={canceled}
          sizeLimit={5}
          acceptedTypes={['jpg', 'jpeg', 'png']}
          disabled={updating}
        />
      </Form.Item>
      <div className={'AppCustomizationForm__alert'}>
        <Alert message={info} type={'info'} showIcon />
      </div>
      <div className={'AppCustomizationForm__actions'}>
        <DefaultButton
          title={cancel}
          onClick={onCancel}
          className={'AppCustomizationForm__cancel-button'}
          disabled={cancelButtonDisabled || updating}
        />
        <PrimaryButton
          htmlType={'submit'}
          title={publish}
          loading={updating}
          disabled={disabled}
          onClick={onPublish}
        />
      </div>
    </Form>
  )
}

export default ProjectAppCustomizationForm;