import { FC } from 'react';
import './styles.scss';

interface Props {
  text: string;
}

const TagError: FC<Props> = ({ text }) => {
  return (
    <span className={'TagError'}>
      {text}
    </span>
  );
}

export default TagError;