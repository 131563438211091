import { FC } from 'react';
import { Button, Checkbox } from 'antd';
import DrawerAtom from 'components/atoms/DrawerAtom';
import TextAtom from 'components/atoms/TextAtom';
import { COLORS } from 'constants/colors';
import AccessHotelKeyReaderDrawerInfoAlert from 'components/molecules/AccessHotelKeyReaderDrawerInfoAlert';
import { Icon } from 'components/atoms/Icons';
import { CheckInBasicDataModel } from 'core/domain/hotels/models';
import DividerAtom from 'components/atoms/DividerAtom';
import SkeletonAtom from 'components/atoms/SkeletonAtom';
import InputAtom from 'components/atoms/InputAtom';
import { formatSpanishDateAndTimeWithSlash } from 'utils/date';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import Attention from 'assets/svg/icon/Attention';
import './styles.scss';

const DRAWER_ATOM_WIDTH = '520px';

interface Props {
  title: string;
  infoTitle: string;
  infoText: string;
  emptyStateText: string;
  nfcCode: string;
  nfcCodeLabel: string;
  roomsLabel: string;
  validLabel: string;
  keysLabel: string;
  digitalKeyLabel: string;
  emptyStateTitle: string;
  keyReaderData: CheckInBasicDataModel[];
  closeButtonText: string;
  checkoutButtonText: string;
  selectedRoomText: string;
  checkoutSelectedItems: number;
  onClose: () => void;
  onCheckout: () => void;
  onShowQr: () => void;
  onChangeNfcCode: (nfcCode: string) => void;
  onSelected: (checkoutId: string) => void;
  isNotLengthKeyReader: boolean;
  isVisible: boolean;
  isLoading: boolean;
  isDisabledCheckoutButton: boolean;
  isLoadingCheckout: boolean;
}

const AccessHotelKeyReaderDrawer: FC<Props> = ({
  title,
  infoTitle,
  infoText,
  nfcCode,
  nfcCodeLabel,
  keysLabel,
  roomsLabel,
  validLabel,
  digitalKeyLabel,
  keyReaderData,
  emptyStateText,
  closeButtonText,
  emptyStateTitle,
  selectedRoomText,
  checkoutButtonText,
  checkoutSelectedItems,
  onClose,
  onCheckout,
  onShowQr,
  onChangeNfcCode,
  onSelected,
  isNotLengthKeyReader,
  isVisible,
  isLoading,
  isDisabledCheckoutButton,
  isLoadingCheckout,
}) => (
  <DrawerAtom
    width={DRAWER_ATOM_WIDTH}
    className={'AccessHotelKeyReaderDrawer__wrapper'}
    title={
      <TextAtom style={{ color: COLORS.text }}>{title}</TextAtom>}
    destroyOnClose={true}
    closable={!isLoading}
    maskClosable={!isLoading}
    onClose={onClose}
    placement={'right'}
    size={'large'}
    open={isVisible}
  >
    <main className={'AccessHotelKeyReaderDrawer__main'}>
      <div className={'AccessHotelKeyReaderDrawer__info'}>
        <AccessHotelKeyReaderDrawerInfoAlert
          infoTitle={infoTitle}
          infoText={infoText}
        />
      </div>
      <div className={'AccessHotelKeyReaderDrawer__main-wrapper'}>
        <p className={'AccessHotelKeyReaderDrawer__main-label'}>{nfcCodeLabel}</p>
        <div className={'AccessHotelKeyReaderDrawer__main-wrapper-input'}>
          <InputAtom
            value={nfcCode}
            onChange={(event) => onChangeNfcCode(event.target.value)}
            autoFocus
            allowClear
          />
          <Button type={'default'} icon={<Icon.QR />} onClick={onShowQr} />
        </div>
      </div>
      <DividerAtom />
      <div className={'AccessHotelKeyReaderDrawer__wrapper-info-room'}>
        <div>
          <SkeletonAtom loading={isLoading} />
          <SkeletonAtom loading={isLoading} />
        </div>
        {!isLoading && !isNotLengthKeyReader && !keyReaderData.length && (
          <div>
            <div className={'AccessHotelKeyReaderDrawer__body-empty-container'}>
              <div className={'AccessHotelKeyReaderDrawer__body-empty-icon'}>
                <Attention />
              </div>
              <h2 className='EmptyCommonAreas__title'>{emptyStateTitle}</h2>
              <p className={'AccessHotelKeyReaderDrawer__body-empty-text'}>{emptyStateText}</p>
            </div>
          </div>
        )}
        {!isLoading && !isNotLengthKeyReader && keyReaderData.map((item, index) => {
          const validFromText = formatSpanishDateAndTimeWithSlash(item.validFrom);
          const validUntilText = formatSpanishDateAndTimeWithSlash(item.validUntil);
          const hasCheckbox = keyReaderData.length > 1;

          return (
            <div className='AccessHotelKeyReaderDrawer__item_wrapper'>
              {hasCheckbox && (
                <div className={'AccessHotelKeyReaderDrawer__item'}>
                  <Checkbox
                    defaultChecked={false}
                    onChange={() => {
                      onSelected(item.id)
                    }}
                  >{selectedRoomText}</Checkbox>
                </div>
              )}
              {item.rooms.map(({ alias }) => (
                <div className={'AccessHotelKeyReaderDrawer__item'}>
                  <div className={'AccessHotelKeyReaderDrawer__info-room-label'}>{`${roomsLabel}: `}</div>
                  <InputAtom value={alias} />
                </div>
              ))}
              <div className={'AccessHotelKeyReaderDrawer__item'}>
                <div className={'AccessHotelKeyReaderDrawer__info-room-label'}>{`${validLabel}: `}</div>
                <InputAtom value={`${validFromText} -> ${validUntilText}`} suffix={<Icon.Calendar />} />
              </div>
              {!!item.nfcCodes.length && item.nfcCodes.map((code) => (
                <div className={'AccessHotelKeyReaderDrawer__item'}>
                  <div className={'AccessHotelKeyReaderDrawer__info-room-label'}>{`${keysLabel}: `}</div>
                  <div className={'AccessHotelKeyReaderDrawer__main-wrapper-input'}>
                    <InputAtom value={code} />
                  </div>
                </div>
              ))}
              {item.url && (
                <div className={'AccessHotelKeyReaderDrawer__item'}>
                  <div className={'AccessHotelKeyReaderDrawer__info-room-label'}>{`${digitalKeyLabel}: `}</div>
                  <InputAtom value={`https://${item.url}`} />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </main>
    <footer>
      <DefaultButton
        disabled={isLoadingCheckout}
        onClick={onClose}
        title={closeButtonText}
      />
      <DefaultButton
        loading={isLoadingCheckout}
        onClick={onCheckout}
        title={`${checkoutButtonText} ${checkoutSelectedItems ? `(${checkoutSelectedItems})` : ''}`}
        disabled={isDisabledCheckoutButton}
      />
    </footer>
  </DrawerAtom>
);

export default AccessHotelKeyReaderDrawer;
