import { Camera } from '../models/camera';
import { CameraParsed } from '../models/cameraParsed';

export const CamerasEntity = ({ name, remote, local, vendor, model, comments, deviceSerial, enabled, snapshot }: CameraParsed): Camera => {
  return Object.freeze({
    name,
    remote,
    local,
    vendor,
    model,
    comments,
    deviceSerial,
    enabled,
    snapshot,
  });
};
