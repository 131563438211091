import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Paper from 'components/atoms/Paper';
import SupportTicketsSummary from 'components/organisms/SupportTicketsSummary';
import AssetSupportTicketsStatisticsLoadingTemplate from 'components/template/AssetSupportTicketsStatisticsLoadingTemplate';
import AssetSupportTicketsStatisticsErrorTemplate from 'components/template/AssetSupportTicketsStatisticsErrorTemplate';
import { useAssetSupportTicketsStatistics } from './resources/useAssetSupportTicketsStatistics';
import AssetSupportTicketsStatisticsTemplate from 'components/template/AssetSupportTicketsStatisticsTemplate';

const TabStart: FC = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { assetSupportTicketsStatisticsTranslations, supportTicketsError, supportTicketsLoading, supportTicketsStatistics } = useAssetSupportTicketsStatistics();

  return (
    <>
      {supportTicketsLoading && <AssetSupportTicketsStatisticsLoadingTemplate />}
      {!supportTicketsLoading && supportTicketsError && <AssetSupportTicketsStatisticsErrorTemplate translations={assetSupportTicketsStatisticsTranslations} />}
      {!supportTicketsLoading && !supportTicketsError && <AssetSupportTicketsStatisticsTemplate translations={assetSupportTicketsStatisticsTranslations} tickets={supportTicketsStatistics} />}
      <Paper>
        <SupportTicketsSummary assetId={assetId} />
      </Paper>
    </>
  );
};

export default TabStart;
