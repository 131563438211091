import { FC } from 'react';
import { Descriptions, Tooltip, Image, Checkbox } from 'antd';
import { AssetInAssetDashboard, ClientInAssetDashboard, ProjectInAssetDashboard } from 'models/assets.model';
import { useTranslation } from 'react-i18next';
import { IMAGES } from 'constants/images';
import SpaceAtom from 'components/atoms/SpaceAtom';
import TooltipAtom from 'components/atoms/TooltipAtom';
import { Icon } from 'components/atoms/Icons';
// import ProjectAssetGatewayConnectionStatusManager from '../ProjectPage/Tabs/TabAssets/gatewayConnectionStatusManager';
import { AssetGatewayConnectionStatusTranslation } from 'hooks/useAsset';
import LinkButton from 'components/atoms/Button/LinkButton';

interface HeaderDescriptionProps {
  assetId: string;
  asset: AssetInAssetDashboard;
  assetGatewayConnectionStatusTranslations: AssetGatewayConnectionStatusTranslation;
  client?: ClientInAssetDashboard;
  project?: ProjectInAssetDashboard;
  onClickClientLink: (clientId: string) => void;
  onClickProjectLink: (projectId: string) => void;
}

const HeaderDescription: FC<HeaderDescriptionProps> = ({
  asset,
  assetGatewayConnectionStatusTranslations,
  client,
  assetId,
  project,
  onClickClientLink,
  onClickProjectLink,
}) => {
  const { t } = useTranslation();
  // const { connected, disconnected, noLinkedGateways, defaultText } = assetGatewayConnectionStatusTranslations;

  return (
    <Descriptions column={3} size='small'>
      {/* <Descriptions column={4} size='small'> */}
      <Descriptions.Item key='community' label={<b>{t('community')}</b>}>
        {!!project && !!Object.keys(project).length ? (
          <LinkButton className={'HeaderDescription__link'} title={project.name} onClick={() => onClickProjectLink(project.uuid)} />
        ) : (
          <span>{t('not_reported')}</span>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        key='did'
        label={
          <SpaceAtom>
            <Tooltip className='AssetPage__did' title={<Image src={String(IMAGES.did)} />}>
              <Icon.InfoCircle />
            </Tooltip>
            <b> DID</b>
          </SpaceAtom>
        }
      >
        {assetId}
      </Descriptions.Item>
      <Descriptions.Item
        key='type'
        label={
          <SpaceAtom>
            <TooltipAtom className='AssetPage__did' title={t('asset_type_info')}>
              <Icon.InfoCircle />
            </TooltipAtom>
            <b>{t('type')}</b>
          </SpaceAtom>
        }
      >
        {asset.type ? t(asset.type) : t('without_type')}
      </Descriptions.Item>
      {/* <Descriptions.Item key='gateway_connection_status' label={<b>{t('_ASSET_GATEWAY_CONNECTION_STATUS_TITLE')}</b>}>
        {!!asset.gateway_connection_status ? (
          <span>
            <ProjectAssetGatewayConnectionStatusManager
              text={asset.gateway_connection_status}
              connectedText={connected}
              disconnectedText={disconnected}
              noLinkedGatewayText={noLinkedGateways}
              defaultText={defaultText}
            />
          </span>
        ) : '---'}
      </Descriptions.Item> */}
      <Descriptions.Item key='company' label={<b>{t('company')}</b>}>
        {!!client && !!Object.keys(client).length ? (
          <LinkButton className={'HeaderDescription__link'} title={client.name} onClick={() => onClickClientLink(client.id)} />
        ) : (
          <span>{t('not_reported')}</span>
        )}
      </Descriptions.Item>
      <Descriptions.Item key='address' label={<b>{t('address')}</b>}>
        {`${asset.street_type} ${asset.street_name} ${asset.block_number}, ${asset.apartment}, ${asset.zip_code}, ${asset.city}, ${asset.country}`}
      </Descriptions.Item>
      <Descriptions.Item key='is_blocked' label={<b>{t('_BLOCKED')}</b>}>
        <Checkbox checked={asset.is_blocked || false} disabled />
      </Descriptions.Item>
      {/* <Descriptions.Item key='isOccupied' label={<b>{t('_ASSET_OCCUPATION_TITLE')}</b>}>
        {asset.is_occupied ? t('_ASSET_OCCUPIED') : t('_ASSET_NOT_OCCUPIED')}
      </Descriptions.Item> */}
    </Descriptions>
  );
};

export default HeaderDescription;
