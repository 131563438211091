import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined, EyeOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { SetLogin } from 'core/domain/login/repositories/Login';
import LogoAlfred from 'assets/svg/LogoAlfred';
import { ROUTE_PATH_DASHBOARD } from '../App/routes/dashboard/config';
import { CheckLegalDocumentation } from 'core/domain/client/repositories/checkLegalDocumentation';
import { ROUTE_PATH_LEGAL_DOCUMENTATION_CHECK } from '../App/routes';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';
import { MessageErrorModel, useMessage } from 'hooks/useMessage';
import './styles.scss';

const SIZE_WIDTH_LOGO = '300';

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginPage: FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation('global');
  const { host } = useEnvironment()
  const { setToken, checkIfUserIsLoggedIn } = useUserSession();
  const { setMessageError } = useMessage();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const isLoggedIn = checkIfUserIsLoggedIn();

  if (isLoggedIn) {
    history.push(ROUTE_PATH_DASHBOARD);
    return <></>;
  }

  const checkErrors = (error: MessageErrorModel) => {
    error.code === 403 && setMessageError({ description: t('_LOGIN_USER_MAIL_ERROR_MESSAGE') });
    error.code !== 403 && setMessageError({ description: t('_LOGIN_ERROR_MESSAGE') });
  }

  const handleSubmit = async ({ email, password }: LoginFormValues) => {
    setLoading(true);
    try {
      const response = await SetLogin({ host, email, password });
      setToken(`Bearer ${response.token}`);
      const legalDocumentation = await CheckLegalDocumentation();
      !legalDocumentation.isAccepted && legalDocumentation.isEnabled ? history.push(ROUTE_PATH_LEGAL_DOCUMENTATION_CHECK) : history.push(ROUTE_PATH_DASHBOARD);;
    } catch (error) {
      checkErrors(error as MessageErrorModel);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='LoginPage'>
      <div className='LoginPage__logo'>
        <LogoAlfred width={SIZE_WIDTH_LOGO} />
      </div>
      <Form form={form} onFinish={handleSubmit} initialValues={{ remember: true }} className='LoginPage__form'>
        <Form.Item
          className='LoginPage__input-email'
          name='email'
          hasFeedback
          rules={[{ required: true, type: 'email', message: t('required_field') }]}
        >
          <Input autoComplete='on' name='email' prefix={<UserOutlined className='Icon__gray-extra-light' />} placeholder={t('email')} />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: t('required_field') }]}>
          <Input.Password
            autoComplete='on'
            name='password'
            prefix={<LockOutlined className='Icon__gray-extra-light' />}
            suffix={<EyeOutlined className='Icon__gray-extra-light' />}
            placeholder={t('password')}
          />
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          <Button loading={loading} disabled={loading} type='primary' htmlType='submit' className={'LoginPage__submit'}>
            {t('enter')}
          </Button>
        </Form.Item>
      </Form>
      <a className='LoginPage__forget' href='https://auth.alfredsmartdata.com/password/recover' target='_blank' rel='noreferrer'>
        {t('forgotten_the_password')}
      </a>
    </div>
  );
};

export default LoginPage;
