import { SensorByProjectModel } from '../models/sensorEntityModels';

export const SensorProjectEntity = ({ id, name, room, usage }: SensorByProjectModel): SensorByProjectModel => {
  return Object.freeze({
    id,
    name,
    room,
    usage,
  });
};
