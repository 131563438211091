import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { Divider, Dropdown, Menu, Modal, message } from 'antd';
import { FlagFilled, LogoutOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SetLogout } from 'core/domain/login/repositories/Logout';
import { useProfileUser } from 'hooks/useProfileUser';
import { useEnvironment } from 'hooks/useEnvironment';
import { ROUTE_PATH_LOGIN } from 'components/pages/App/routes';
import AvatarUser from 'components/atoms/AvatarUser';
import SpaceAtom from 'components/atoms/SpaceAtom';
import { ROUTE_PATH_TICKETS } from 'components/pages/App/routes/tickets/config';
import AlfredLogo from 'assets/svg/icon/AlfredLogo';
import { COLORS } from 'constants/colors';
import WaterMark from '../WaterMark';
import UserProfileContext, { UserProfileContextState } from 'context/UserProfileContext';
import './styles.scss';

interface Props {
  color?: string;
}

const AvatarMenu: FC<Props> = ({ color = COLORS.white }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data } = useProfileUser();
  const { host } = useEnvironment();
  const [name, setName] = useState<string>('');
  const { setUserProfile } = useContext<UserProfileContextState>(UserProfileContext);

  useEffect(() => {
    data && setName(data.username);
  }, [data]);

  const handleLogout = (): void => {
    Modal.confirm({
      title: t('are_you_sure_you_want_to_leave'),
      okText: t('ok'),
      cancelText: t('cancel'),
      onOk: async () => {
        const hide = message.loading(t('sign_out_session'));
        await SetLogout(host);
        setUserProfile(null)
        hide();
        history.push(ROUTE_PATH_LOGIN);
      },
    });
  };

  const handleCreateTicket = () => {
    history.push(ROUTE_PATH_TICKETS);
  };

  const menuAvatar: ReactElement = (
    <Menu>
      <Menu.Item key={'novedades'} className={'beamerItem beamerItem__newsUpdates'}>
        <SpaceAtom>
          <div className={'logoContainer'}>
            <AlfredLogo />
          </div>
          <span>{t('news_updates')}</span>
        </SpaceAtom>
      </Menu.Item>
      <Menu.Item key={'createTicket'} onClick={handleCreateTicket}>
        <SpaceAtom>
          <FlagFilled />
          <span>{t('create_ticket')}</span>
        </SpaceAtom>
      </Menu.Item>
      <Menu.Item key='signOut' onClick={handleLogout}>
        <SpaceAtom>
          <LogoutOutlined />
          <span>{t('sign_out')}</span>
        </SpaceAtom>
      </Menu.Item>
      <div className={'divider__wrapper'}>
        <Divider className={'divider'} />
      </div>
      <div className={'waterMark__wrapper'}>
        <WaterMark logoColor={'#BBBBBB'} logoWidth={'128'} />
      </div>
    </Menu>
  );

  return (
    <Dropdown overlay={menuAvatar} placement={'bottomRight'}>
      <div className={'AvatarMenu beamerItem beamerItem__avatar'} data-beamer-click='false'>
        <AvatarUser letter={name.charAt(0)} color={color} />
      </div>
    </Dropdown>
  );
};

export default AvatarMenu;
