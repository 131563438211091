import { ProjectClientModel } from 'core/domain/project/model/projectModel';
import { createContext, FC, useState, Dispatch, SetStateAction } from 'react';

export interface ClientProjectContextState {
  clientProject: ProjectClientModel;
  setClientProject: Dispatch<SetStateAction<ProjectClientModel>>;
}

interface ClientProjectContextProviderProps {
  children: React.ReactNode;
}

const ClientProjectContext = createContext<any>({});

export const ClientProjectContextProvider: FC<ClientProjectContextProviderProps> = ({ children }) => {
  const [clientProject, setClientProject] = useState<ProjectClientModel>();

  return <ClientProjectContext.Provider value={{ clientProject, setClientProject }}>{children}</ClientProjectContext.Provider>;
};

export default ClientProjectContext;
