import { LegalDocumentationCheckedModel } from '../models/clientEntityModel';
import { LegalDocumentationCheckedPayloadModel } from '../models/clientPayloadModel';

export const payloadToLegalDocumentationChecked = ({
  is_accepted,
  is_enabled,
  link_privacy_policy,
  link_terms_of_service,
}: LegalDocumentationCheckedPayloadModel): LegalDocumentationCheckedModel => {
  return {
    isAccepted: is_accepted ?? false,
    isEnabled: is_enabled ?? false,
    linkPrivacyPolicy: link_privacy_policy ?? '',
    linkTermsOfService: link_terms_of_service ?? '',
  };
};
