import { EventsAndNewsModel } from '../models/eventsAndNewsEntityModels';
import { PayloadEventsAndNewsModel } from '../models/payloadEventsAndNews';

export const payloadToEventsAndNews = ({
  id,
  title,
  description,
  image,
  link,
  link_title,
  send_notification,
}: PayloadEventsAndNewsModel): EventsAndNewsModel => {
  return {
    id,
    title,
    description,
    image,
    link: link ?? undefined,
    linkTitle: link_title ?? undefined,
    sendNotification: send_notification ?? false,
  };
};
