import { FC } from 'react';

const DigitalSignage: FC = () => {
  return (
    <iframe
      title='DigitalSignage'
      id='DigitalSignage'
      data-type='text/html'
      // src='https://alfredsmart.optisigns.net/signIn'
      src='https://digitalsignage.alfredsmart.com/signIn'
      width='100%'
      height='850vh'
      frameBorder='0'
    ></iframe>
  );
};

export default DigitalSignage;
