import { FC } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import ProjectIntercomsEmptyTemplate from 'components/template/ProjectIntercomsEmptyTemplate';
import ProjectIntercomsTemplate from 'components/template/ProjectIntercomsTemplate';
import { IntercomModel } from 'core/domain/intercoms/model/intercomModel';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import ProjectIntercomsCreateTemplate from 'components/template/ProjectIntercomsCreateTemplate';
import { IntercomStateModel, ProjectIntercomFormValues } from './config';
import ProjectIntercomsEditTemplate from 'components/template/ProjectIntercomEditTemplate';
import ProjectIntercomsErrorTemplate from 'components/template/ProjectIntercomsErrorTemplate';
import { IntercomEmptyTemplateTranslations, IntercomErrorTemplateTranslations, IntercomFormTranslations, IntercomsListTemplateTranslations } from './translations';

interface Props {
  stateModel: IntercomStateModel;
  intercomEmptyTemplateTranslations: IntercomEmptyTemplateTranslations;
  intercomsListTemplateTranslations: IntercomsListTemplateTranslations;
  intercomsErrorTemplateTranslations: IntercomErrorTemplateTranslations;
  intercomFormTranslations: IntercomFormTranslations;
  intercoms: IntercomModel[];
  columns: AlfredTableColumn<IntercomModel>[];
  form: FormInstance<ProjectIntercomFormValues>
  providers: DefaultOptionType[];
  onAddIntercom: () => void;
  onEditIntercom: (intercom: IntercomModel) => void;
  onDeleteIntercom: (intercomId: string) => void;
  onSubmitCreating: (values: ProjectIntercomFormValues) => void;
  onSubmitEditing: (values: ProjectIntercomFormValues) => void;
  onSubmitCreatingFailed: () => void;
  onSubmitEditingFailed: () => void;
  onClose: () => void;
  processing: boolean;
  loading: boolean;
}

const ProjectIntercomsTemplateFromStateModel = {
  [IntercomStateModel.EMPTY]: ProjectIntercomsEmptyTemplate,
  [IntercomStateModel.CONTENT]: ProjectIntercomsTemplate,
  [IntercomStateModel.EDITING]: ProjectIntercomsEditTemplate,
  [IntercomStateModel.CREATING]: ProjectIntercomsCreateTemplate,
  [IntercomStateModel.ERROR]: ProjectIntercomsErrorTemplate,
}

const ProjectIntercomsTemplatesManager: FC<Props> = ({ stateModel, ...props }) => {
  const Component = ProjectIntercomsTemplateFromStateModel[stateModel];

  return <Component {...props} />
}

export default ProjectIntercomsTemplatesManager;