import { FC } from 'react';
import { Icon } from 'components/atoms/Icons';
import './styles.scss';

interface TabEmptyProps {
  title: string;
  text: string;
  linkText: string;
  onClick: () => void;
}

const TabEmpty: FC<TabEmptyProps> = ({ title, text, linkText, onClick }) => {
  return (
    <div className='TabEmpty__container'>
      <div className='TabEmpty__icon'>
        <Icon.Empty />
      </div>
      <h2 className='TabEmpty__title'>{title}</h2>
      <p className='TabEmpty__text'>{text}</p>
      <div className='TabEmpty__wrapper-link TabEmpty__link' onClick={onClick}>
        {linkText}
      </div>
    </div>
  );
};

export default TabEmpty;
