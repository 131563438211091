import { FirstLevelAreaUpdateModel } from "../model/projectLayoutConfigModels";
import { PayloadUpdateFirstLevelArea } from "../model/projectLayoutConfigPayloadModels";

export const layoutConfigFirstLevelAreaToUpdatePayload = ({
  id,
  type,
  nameEn,
  nameEs,
  imageURL,
  imageBASE64,
  projectId,
  horizontal,
 }: FirstLevelAreaUpdateModel): PayloadUpdateFirstLevelArea[] => {
  return [{
    id,
    type,
    name_en: nameEn,
    name_es: nameEs,
    image_base_64: imageBASE64,
    image_url: imageURL,
    project_id: projectId,
    horizontal: horizontal,
  }]
}
