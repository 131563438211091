import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import AlfredTableTemplate from '../AlfredTableTemplate';
import { LogListColumns, LogListColumnsLiteralsModel } from './LogColumns';
import SpaceAtom from 'components/atoms/SpaceAtom';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { Menu } from 'antd';
import { LogListCustomDataModel } from 'components/pages/AccessesVerticals/office/AccessesLogListTab/resources/utils';
import './styles.scss';

interface Props {
  logList: LogListCustomDataModel[];
  totalPages: number;
  currentPage: number;
  pageSize: number;
  translations: LogListColumnsLiteralsModel;
  onChangePage: (page: number, pageSize?: number | undefined) => void;
  onShowDownloadLog: () => void;
  isLoading: boolean;
}

const AccessesLogListTemplate: FC<Props> = ({
  logList,
  totalPages,
  currentPage,
  pageSize,
  translations,
  onChangePage,
  onShowDownloadLog,
  isLoading,
}) => {
  const columns = LogListColumns({ ...translations });
  const { downloadLogButtonText } = translations;

  return (
    <Paper className={'LogListTemplate__wrapper'}>
      <AlfredTableTemplate<LogListCustomDataModel>
        columns={columns}
        data={logList}
        totalPages={totalPages}
        pageCurrent={currentPage}
        pageSize={pageSize}
        onPagination={onChangePage}
        isCharging={isLoading}
        actionSection={
          <SpaceAtom>
            <ContextualMenu menu={
              <Menu>
                <Menu.Item
                  key={'download-log'}
                  onClick={onShowDownloadLog}
                  disabled={!logList.length}
                >
                  {downloadLogButtonText}
                </Menu.Item>
              </Menu>
            } />
          </SpaceAtom>
        }
      />
    </Paper>
  )
}

export default AccessesLogListTemplate;