import { useState } from 'react';
import { DeleteDocument } from 'core/domain/documents/repositories/deleteDocument';
import { formatBytes } from 'utils/documents';

export const useDocuments = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDelete = async (documentId: string) => {
    setIsLoading(true);
    try {
      await DeleteDocument(documentId);
    } finally {
      setIsLoading(false);
    }
  };

  const onFormatToBytes = (document: { bytes: number; decimals?: number }): string => {
    return formatBytes(document);
  };

  const onDownload = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  }


  return {
    onDelete,
    onFormatToBytes,
    onDownload,
    loading: isLoading,
  };
};
