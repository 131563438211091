import { IMAGES } from 'constants/images';

export const GATEWAY_IMAGES = {
  /** ALFRED RENT **/
  ALFPAS0008: {
    sku: 'ALFPAS0008',
    image: IMAGES.noImage,
  },
  ALFPAS0007: {
    sku: 'ALFPAS0007',
    image: IMAGES.noImage,
  },
  /** ALFRED COMMUNITY **/
  ALFPAS0066: {
    sku: 'ALFPAS0066',
    image: IMAGES.noImage,
  },
  ALFPAS0065: {
    sku: 'ALFPAS0065',
    image: IMAGES.devices.alfred_community_lite,
  },
  /** ALFRED BUILDING **/
  ALFPAS0017: {
    sku: 'ALFPAS0017',
    image: IMAGES.noImage,
  },
  ALFPAS0016: {
    sku: 'ALFPAS0016',
    image: IMAGES.noImage,
  },
  /** ALFRED LOFT **/
  ALFPAS0001: {
    sku: 'ALFPAS0001',
    image: IMAGES.devices.alfred_loft,
  },
  ALFPAS0011: {
    sku: 'ALFPAS0011',
    image: IMAGES.noImage,
  },
  /** ALFRED HOME **/
  ALFPAS0010: {
    sku: 'ALFPAS0010',
    image: IMAGES.devices.alfred_home_desktop,
  },
  ALFPAS0002: {
    sku: 'ALFPAS0002',
    image: IMAGES.devices.alfred_home,
  },
};
