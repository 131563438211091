import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Empty } from 'antd';
import CameraSnapshot from 'components/pages/ProjectPage/Tabs/TabCamera/CameraSnapshot';
import { Camera } from 'models/camera.model';
import { getProjectCameras } from 'services/api/projects';
import Paper from 'components/atoms/Paper';
import './styles.scss';

const TabCamera = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [cameras, setCameras] = useState<Camera[]>([]);

  useEffect(() => {
    getProjectCameras(projectId).then((response) => {
      setCameras(response.data);
    });
  }, [projectId]);

  return (
    <Paper>
      {cameras.length ? (
        <Row className='TabCamera' gutter={[16, 16]}>
          {cameras?.map((camera, index) => (
            <Col span={8} key={index}>
              <CameraSnapshot snapshotUrl={camera.snapshot} />
            </Col>
          ))}
        </Row>
      ) : (
        <Empty />
      )}
    </Paper>
  );
};

export default TabCamera;
