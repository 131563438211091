import { FC } from 'react';
import { Table, Pagination, Row } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { User } from 'models/users.model';
import './styles.scss';
import AlfredMainTemplate from '../AlfredMainTemplate';

export interface Props {
  title: string;
  data: User[];
  buttons: JSX.Element;
  columns: ColumnType<User>[];
  onRowClick: (data: User) => void;
  loading: boolean;
  pageSize: number;
  onChangePagination: (page: number, pageSize?: number) => void;
  pageCurrent: number;
  totalPages: number;
}

export const DEFAULT_PAGE_SIZE = 50;
const PAGE_SIZE_OPTIONS = ['50', '75', '100'];
const MAX_SIZE_RESPONSIVE_SCREEN = 600;

const UsersListTemplate: FC<Props> = ({
  title,
  data,
  columns,
  onRowClick,
  buttons,
  loading,
  pageSize,
  onChangePagination,
  pageCurrent,
  totalPages,
}) => {
  return (
    <AlfredMainTemplate title={title}>
      <div className='UsersListTemplate'>
        <Row className='UsersListTemplate__toolbar'>
          {buttons}
        </Row>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          rowKey={'id'}
          pagination={false}
          scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
          onRow={(item) => {
            return {
              onClick: () => onRowClick(item),
            };
          }}
        />
        <Pagination
          className={'UsersListTemplate__pagination'}
          size={'small'}
          showSizeChanger={true}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          current={pageCurrent}
          total={pageSize * totalPages}
          pageSize={pageSize}
          onChange={onChangePagination}
        />
      </div>
    </AlfredMainTemplate>
  );
};

export default UsersListTemplate;
