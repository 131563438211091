import { FC } from 'react';
import { Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import CheckStatus from './CheckStatus';
import PercentStatus, { PercentStatusProps } from './PercentStatus';
import { GatewayStatusCheckValueModel } from '../../../TabGateway/resources/utils';
import './styles.scss';

interface GatewayStatusBlockProps {
  checkValues: GatewayStatusCheckValueModel[];
  percentValues: PercentStatusProps[];
}

const GatewayStatusBlock: FC<GatewayStatusBlockProps> = ({ checkValues, percentValues }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography.Title level={4}>{t('status')}:</Typography.Title>
      <div className='GatewayStatus'>
        {!!checkValues.length && (
          <div className='GatewayStatus__container GatewayStatus__check'>
            {checkValues.map((values, key) => (
              <CheckStatus key={key} {...values} />
            ))}
          </div>
        )}
        {!checkValues.length && (
          <div className='GatewayStatus__container GatewayStatus__percent'>
            <p>{t('_GATEWAY_STATUS_VALUES_NOT_REPORTED_MESSAGE')}</p>
          </div>
        )}
        <Divider className='GatewayStatus__divider' type='vertical' />
        <div className='GatewayStatus__container GatewayStatus__percent'>
          {!!percentValues.length ? percentValues.map((values, key) => (
            <PercentStatus key={key} {...values} />
          )) : <p>{t('_GATEWAY_STATUS_METRICS_NOT_REPORTED_MESSAGE')}</p>}
        </div>
      </div>
    </div>
  );
};

export default GatewayStatusBlock;
