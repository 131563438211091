import { FC } from 'react';
import DocumentsTableActionsNotCorrectSource from './DocumentsTableActionsNotCorrectSource';
import TableActionsContent from '../TableActionsContent';
import { TableActionsMenuProps } from 'components/atoms/TableAtom';

export enum SourceType {
  ASSET = 'asset',
  PROJECT = 'project',
}

interface DocumentsTableActionsProps {
  id: string;
  documentSource: SourceType;
  source: SourceType;
  wrongSourceText: string;
  menu: TableActionsMenuProps[];
}

const documentTableActionsAssetContentManager = {
  [SourceType.ASSET]: TableActionsContent,
  [SourceType.PROJECT]: DocumentsTableActionsNotCorrectSource,
};

const documentTableActionsProjectContentManager = {
  [SourceType.ASSET]: DocumentsTableActionsNotCorrectSource,
  [SourceType.PROJECT]: TableActionsContent,
};

const documentTableActionsContentManager = {
  [SourceType.ASSET]: documentTableActionsAssetContentManager,
  [SourceType.PROJECT]: documentTableActionsProjectContentManager,
};

const DocumentsTableActionsManager: FC<DocumentsTableActionsProps> = ({ source, documentSource, ...props }) => {
  const Component = documentTableActionsContentManager[source as SourceType][documentSource as SourceType];

  return <Component {...props} />;
};

export default DocumentsTableActionsManager;
