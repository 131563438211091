import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsProps } from 'antd';

export enum TabsType {
  info = 'info',
  devices = 'devices',
}

const TabsTitles: FC<TabsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Tabs defaultActiveKey={TabsType.info} {...props} items={[
      { label: <span>{t(TabsType.info)}</span>, key: TabsType.info },
      { label: <span>{t(TabsType.devices)}</span>, key: TabsType.devices }
    ]} />
  );
};

export default TabsTitles;
