import { FC, useState } from 'react';
import { PageHeader, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import TabProducts from './TabProducts';
import TabVideos from './TabVideos';
import './styles.scss';

type TabsOptions = 'TabProducts' | 'TabVideos';

const AcademyPage: FC = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<TabsOptions>('TabProducts');

  return (
    <>
      <PageHeader
        ghost={false}
        title={t('alfred_academy')}
        className='AcademyPage__header'
        footer={
          <Tabs defaultActiveKey='TabProducts' onChange={(activeTab: string) => setActiveTab(activeTab as TabsOptions)}>
            <Tabs.TabPane tab={t('products')} key='TabProducts' />
            <Tabs.TabPane tab={t('videos')} key='TabVideos' />
          </Tabs>
        }
      />
      <div className='AcademyPage__wrapper'>
        {activeTab === 'TabProducts' && <TabProducts key='TabProducts' />}
        {activeTab === 'TabVideos' && <TabVideos key='TabVideos' />}
      </div>
    </>
  );
};

export default AcademyPage;
