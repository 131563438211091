import { FC, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useParams } from 'react-router-dom';
import { Typography, DatePicker, Space, Divider, Select, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { getAccessControl } from 'services/api/assets';
import { AccessControl } from 'models/assets.model';
import { DATE_FORMAT } from 'constants/date';
import EventItem from './EventItem';
import SideEvents from './SideEvents';
import './styles.scss';
import Paper from 'components/atoms/Paper';

const fromDate = () => moment().subtract(7, 'days');

const TabEvents: FC = () => {
  const { t } = useTranslation();
  const { assetId } = useParams<{ assetId: string }>();
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([fromDate(), moment()]);
  const [data, setData] = useState<AccessControl[]>([]);

  useEffect(() => {
    getAccessControl(assetId, dateRange[0], dateRange[1])
      .then(({ data }) => setData(data))
      .catch(error => console.warn(error));
  }, [assetId, dateRange]);

  const handleOnCalendarChange = (dates: any) => {
    if (dates[0] !== null && dates[1] !== null) {
      setDateRange(dates);
    }
  };

  return (
    <Paper>
      <Typography.Title level={4}>{t('events')}</Typography.Title>
      <div className='TabEvents__header'>
        <Space size='middle'>
          <Space>
            {`${t('category')}:`}
            <Select defaultValue='accesses' disabled>
              <Select.Option value='accesses'>{t('accesses')}</Select.Option>
            </Select>
          </Space>
          <Space>
            {`${t('track')}:`}
            <DatePicker.RangePicker
              value={dateRange}
              format={DATE_FORMAT}
              onCalendarChange={handleOnCalendarChange}
            />
          </Space>
        </Space>
      </div>
      <Divider />
      {data.length ? (
        <div className='TabEvents__container'>
          {data && <EventItem accessControl={data} />}
          {data && (
            <SideEvents
              users={data.map(({ user_email, user_id, username }) => ({
                userEmail: user_email,
                userId: user_id,
                username: username,
              }))}
            />
          )}
        </div>
      ) : (
        <Empty />
      )}
    </Paper>
  );
};

export default TabEvents;
