import { DocuemntPayloadModel } from '../model/documentPayloadModels';
import { DocumentModel } from '../model/documentsEntityModels';

export const payloadToDocuments = ({ id, title, comments, url, size, source, created_at }: DocuemntPayloadModel): DocumentModel => {
  return {
    id,
    title,
    comments,
    url,
    size,
    source,
    createdAt: created_at,
  };
};
