import { VFC } from 'react';
import Paper from 'components/atoms/Paper';
import ConciergeContentTable from 'components/molecules/ConciergeContentTable';
import { useThirdPartyServicesListByAsset } from 'hooks/useThirdPartyServicesListByAsset';
import { ThirdPartyServicesByAsset } from 'core/domain/thirdPartyServices/model/thirdPartyServicesByAsset';
import ConfirmModal from 'components/molecules/ConfirmModal';

interface TabUsefulContactsProps {
  assetId: string;
}

const TabUsefulContacts: VFC<TabUsefulContactsProps> = ({ assetId }) => {
  const {
    data,
    usefulContactsTableActionsMenu,
    removeServiceModalTitle,
    removeServiceModalText,
    removeServiceModalMainButtonText,
    removeServiceModalSecondaryButtonText,
    loading,
    onConfirmRemove,
    onCloseConfirmModal,
    removeServiceModalVisible,
  } = useThirdPartyServicesListByAsset(assetId);

  return (
    <>
      <Paper>
        <ConciergeContentTable<ThirdPartyServicesByAsset>
          loading={loading}
          usefulContactsTableActionsMenu={usefulContactsTableActionsMenu}
          data={data}
        />
      </Paper>
      <ConfirmModal
        onCancel={onCloseConfirmModal}
        onClickMainButton={onConfirmRemove}
        visible={removeServiceModalVisible}
        loading={loading}
        title={removeServiceModalTitle}
        text={removeServiceModalText}
        mainButtonText={removeServiceModalMainButtonText}
        secondaryButtonText={removeServiceModalSecondaryButtonText}
      />
    </>
  );
};

export default TabUsefulContacts;
