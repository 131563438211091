import { FC } from 'react';
import CommonAreasAndBookingsListNewLoadingTemplate from 'components/template/CommonAreasAndBookingsNewLoadingTemplate';
import CommonAreaUpdateTemplate from 'components/template/CommonAreaUpdateTemplate';
import { useCommonAreaUpdate } from './resources/useCommonAreaUpdate';
import ModalFormConfirm from 'components/molecules/CommonAreaForm/components/ModalFormConfirm';
import { FormValuesResponse } from 'components/molecules/CommonAreaForm/models';

const CommonAreaUpdatePage: FC = () => {
  const {
    form,
    Form,
    commonAreaId,
    selectedSensors,
    errors,
    sensorList,
    selectedDays,
    timeAvailability,
    backgroundImage,
    bookingDurationOptionList,
    requiredText,
    configModal,
    onSelectDay,
    onUnselectDay,
    getTimeAvailabilityErrorMessage,
    onAddTimeAvailabilityInterval,
    onChangeTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    onChangeBackgroundImage,
    onSelectSensors,
    onSelectBookingDuration,
    onCloseUpdateCommonArea,
    onFinishUpdateCommonArea,
    onChangeInputFieldsValues,
    onCancelModal,
    onConfirmModal,
    onRemoveCommonArea,
    loadingCommonArea,
    areThereChanges,
    savingCommonArea,
    modalVisible,
    loadingModal,
  } = useCommonAreaUpdate();

  return (
    <>
      {loadingCommonArea && <CommonAreasAndBookingsListNewLoadingTemplate />}
      {!loadingCommonArea && (
        <CommonAreaUpdateTemplate
          commonAreaRequiredFieldsText={requiredText}
          bookingDurationOptionList={bookingDurationOptionList}
          commonAreaId={commonAreaId}
          selectedSensors={selectedSensors}
          errors={errors}
          form={form}
          Form={Form}
          selectedDays={selectedDays}
          sensorOptionList={sensorList}
          timeAvailability={timeAvailability}
          backgroundImage={backgroundImage}
          onChangeTimeAvailability={onChangeTimeAvailabilityInterval}
          onAddTimeAvailabilityInterval={onAddTimeAvailabilityInterval}
          onDeleteTimeAvailabilityInterval={onDeleteTimeAvailabilityInterval}
          onCloseUpdateCommonArea={onCloseUpdateCommonArea}
          onRemoveCommonArea={onRemoveCommonArea}
          getTimeAvailabilityErrorMessage={getTimeAvailabilityErrorMessage}
          onFinishUpdateCommonArea={onFinishUpdateCommonArea}
          onSelectBookingDuration={onSelectBookingDuration}
          onSelectDay={onSelectDay}
          onUnSelectDay={onUnselectDay}
          onSelectSensors={onSelectSensors}
          onChangeBackgroundImage={onChangeBackgroundImage}
          onChangeInputFieldsValues={onChangeInputFieldsValues}
          saving={savingCommonArea}
          saveButtonDisabled={!areThereChanges}
        />
      )}
      <ModalFormConfirm<FormValuesResponse>
        onCancel={onCancelModal}
        onClickConfirm={onConfirmModal}
        isLoading={loadingModal}
        isVisible={modalVisible}
        {...configModal}
      />

    </>
  );
}

export default CommonAreaUpdatePage;