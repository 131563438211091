import { SupportTicketsStatisticsModel } from '../models/supportTicketsModel';

export const SupportTicketsStatisticsEntity = ({
  ticketsClosed,
  ticketsClosedTimeAverage,
  ticketsClosedTimeAverageUnits,
  ticketsInProgress,
  ticketsRevoked,
  ticketsReceived,
  firstResponseTimeAverage,
  firstResponseTimeAverageUnits,
}: SupportTicketsStatisticsModel): Readonly<SupportTicketsStatisticsModel> => {
  return Object.freeze({
    ticketsClosed,
    ticketsClosedTimeAverage,
    ticketsClosedTimeAverageUnits,
    ticketsReceived,
    ticketsInProgress,
    ticketsRevoked,
    firstResponseTimeAverage,
    firstResponseTimeAverageUnits,
  });
};
