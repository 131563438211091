import i18n from 'i18next';
import es_ES from 'locales/es_ES/global.json';

i18n.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  defaultNS: 'global',
  resources: {
    es: {
      global: es_ES,
    },
  },
});

export default i18n;
