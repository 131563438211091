import { FC } from 'react';
import { Skeleton } from 'antd';
import './styles.scss';

const DashboardUsersLoadingTemplate: FC = () => {
  return (
    <div className='UsersLoading__container'>
      <div className='UsersLoading__header'>
        <Skeleton.Input className='UsersLoading__header-skeleton' active />
      </div>
      <div className='UsersLoading__content'>
        <Skeleton.Input className='UsersLoading__content-skeleton' active />
      </div>
    </div>
  );
}

export default DashboardUsersLoadingTemplate;