import { FC } from 'react';

interface Props {
  wrongSourceText: string;
}

const DocumentsTableActionsNotCorrectSource: FC<Props> = ({ wrongSourceText }) => {
  return <span>{wrongSourceText}</span>;
}

export default DocumentsTableActionsNotCorrectSource;