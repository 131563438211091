import { RouteConfig } from 'react-router-config';
import CheckInPage from 'components/pages/CheckIn';
import { ROUTE_PATH_CHECK_IN } from './config';

export const checkInRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_CHECK_IN,
    exact: true,
    component: CheckInPage,
  },
];
