import { FC } from 'react';
import { Divider, Form, Image, Input, Select } from 'antd';
import DrawerAtom from 'components/atoms/DrawerAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { CREATE_FORM_INITIAL_VALUES, CreateAssetDrawerProps, CreateAssetFormValuesNames } from './models';
import { Icon } from 'components/atoms/Icons';
import { IMAGES } from 'constants/images';
import './styles.scss';

const CreateAssetDrawer: FC<CreateAssetDrawerProps> = ({ options, qrScannerOptions, didScannerOptions, onOpenCreateClient }) => {
  const { onOpenQrScanner, maxQidLength, minQidLength } = qrScannerOptions;
  const { onOpenDidScanner, maxDidLength, minDidLength } = didScannerOptions;

  const {
    form,
    drawerLiterals,
    clients,
    projects,
    newProject,
    assetTypesList,
    streetTypes,
    onCloseCreateAssetDrawer,
    onCreateAsset,
    onGetNewProject,
    onCreateProject,
    translateAssetType,
    getProjectsByClientId,
    drawerVisible,
    creating,
    loadingClients,
    loadingProjects,
    projectsInputEnabled,
  } = options;

  const {
    title,
    closeButtonText,
    createAssetButtonText,
    requiredFieldText,
    qidPlaceholder,
    qidLabel,
    qidMaximumLengthText,
    qidMinimumLengthText,
    didLabel,
    didPlaceholder,
    didMaximumLengthText,
    didMinimumLengthText,
    assetDescription,
    clientPlaceholder,
    createClientButtonText,
    projectPlaceholder,
    createProjectButtonText,
    aliasPlaceholder,
    typePlaceholder,
    streetTypePlaceholder,
    streetNamePlaceholder,
    blockNumberPlaceholder,
    staircasePlaceholder,
    flatPlaceholder,
    doorPlaceholder,
    postalCodePlaceholder,
    cityPlaceholder,
    provincePlaceholder,
    countryPlaceholder,
    descriptionPlaceholder,
  } = drawerLiterals;

  return (
    <DrawerAtom
      className={'AccessPasswordDrawer__wrapper'}
      open={drawerVisible}
      onClose={onCloseCreateAssetDrawer}
      size={'large'}
      title={<span className={'CreateAssetDrawer__title'}>{title}</span>}
      style={{ zIndex: 2 }}
    >
      <main className={'CreateAssetDrawer__main'}>
        <Form
          form={form}
          layout={'vertical'}
          requiredMark={false}
          initialValues={CREATE_FORM_INITIAL_VALUES}
          onFinish={onCreateAsset}
        >
          <div className={'CreateAssetDrawer__main-wrapper-with-border'}>
            <Form.Item
              label={qidLabel}
              name={CreateAssetFormValuesNames.QID}
              tooltip={{ title: <Image src={String(IMAGES.qid)} />, icon: <Icon.InfoCircleFilled /> }}
              rules={[
                { max: maxQidLength, message: qidMaximumLengthText, type: 'string' },
                { min: minQidLength, message: qidMinimumLengthText, type: 'string' },
              ]}
            >
              <Input placeholder={qidPlaceholder} maxLength={maxQidLength} />
            </Form.Item>
            <DefaultButton
              className={'CreateAssetDrawer__extra-button'}
              onClick={onOpenQrScanner}
              icon={<Icon.QR />}
            />
          </div>
          <div className={'CreateAssetDrawer__main-wrapper-with-border'}>
            <Form.Item
              label={didLabel}
              name={CreateAssetFormValuesNames.DID}
              rules={[
                { required: true, message: requiredFieldText, type: 'string' },
                { max: maxQidLength, message: didMaximumLengthText, type: 'string' },
                { min: minDidLength, message: didMinimumLengthText, type: 'string' }
              ]}
              tooltip={{ title: <Image src={String(IMAGES.did)} />, icon: <Icon.InfoCircleFilled /> }}
            >
              <Input placeholder={didPlaceholder} maxLength={maxDidLength} />
            </Form.Item>
            <DefaultButton
              className={'CreateAssetDrawer__extra-button'}
              onClick={onOpenDidScanner}
              icon={<Icon.QR />}
            />
          </div>
          <p className={'CreateAssetDrawer__description'}>{assetDescription}</p>
          <div className={'CreateAssetDrawer__main-wrapper'}>
            <Form.Item name={CreateAssetFormValuesNames.CLIENT_ID} rules={[{ required: true, message: requiredFieldText, type: 'string' }]}>
              <Select
                loading={loadingClients}
                placeholder={`${clientPlaceholder}*`}
                onChange={getProjectsByClientId}
              >
                {clients.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <DefaultButton onClick={onOpenCreateClient} title={createClientButtonText} icon={<Icon.Plus />} />
          </div>
          <div className={'CreateAssetDrawer__main-wrapper'}>
            <Form.Item name={CreateAssetFormValuesNames.COMMUNITY} rules={[{ required: true, message: requiredFieldText, type: 'string' }]}>
              <Select
                loading={loadingProjects}
                disabled={!projectsInputEnabled}
                placeholder={`${projectPlaceholder}*`}
                allowClear
                dropdownRender={(menu) => {
                  return (
                    <>
                      {menu}
                      <Divider className={'CreateAssetDrawer__divider'} />
                      <div className={'CreateAssetDrawer__add-container'}>
                        <Input
                          className={'CreateAssetDrawer__add-input'}
                          value={newProject}
                          onChange={(event) => onGetNewProject(event.target.value)}
                        />
                        <span className={'CreateAssetDrawer__add-anchor'} onClick={onCreateProject}>
                          <Icon.Plus /> {createProjectButtonText}
                        </span>
                      </div>
                    </>
                  );
                }}
              >
                {projects.map((project, key) => (
                  <Select.Option key={key} value={project}>
                    {project}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={'CreateAssetDrawer__main-wrapper'}>
            <Form.Item name={CreateAssetFormValuesNames.ALIAS}>
              <Input placeholder={aliasPlaceholder} />
            </Form.Item>
            <Form.Item
              name={CreateAssetFormValuesNames.TYPE}
              rules={[{ required: true, message: requiredFieldText, type: 'string' }]}
            >
              <Select placeholder={typePlaceholder} allowClear>
                {assetTypesList.map((assetType, key) => (
                  <Select.Option key={key} value={assetType}>
                    {translateAssetType(assetType)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={'CreateAssetDrawer__main-wrapper'}>
            <Form.Item name={CreateAssetFormValuesNames.STREET_TYPE} rules={[{ required: true, message: requiredFieldText, type: 'string' }]}>
              <Select placeholder={streetTypePlaceholder} allowClear >
                {streetTypes.map((streetType, key) => (
                  <Select.Option key={key} value={streetType}>
                    {streetType}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={CreateAssetFormValuesNames.STREET_NAME} rules={[{ required: true, message: requiredFieldText }]}>
              <Input placeholder={streetNamePlaceholder} />
            </Form.Item>
            <Form.Item name={CreateAssetFormValuesNames.BLOCK_NUMBER} rules={[{ required: true, message: requiredFieldText }]}>
              <Input placeholder={blockNumberPlaceholder} />
            </Form.Item>
          </div>
          <div className={'CreateAssetDrawer__main-wrapper'}>
            <Form.Item name={CreateAssetFormValuesNames.STAIRCASE}>
              <Input placeholder={staircasePlaceholder} />
            </Form.Item>
            <Form.Item name={CreateAssetFormValuesNames.FLAT}>
              <Input placeholder={flatPlaceholder} />
            </Form.Item>
            <Form.Item name={CreateAssetFormValuesNames.DOOR}>
              <Input placeholder={doorPlaceholder} />
            </Form.Item>
          </div>
          <div className={'CreateAssetDrawer__main-wrapper'}>
            <Form.Item name={CreateAssetFormValuesNames.POSTAL_CODE} rules={[{ required: true, message: requiredFieldText }]}>
              <Input placeholder={postalCodePlaceholder} />
            </Form.Item>
            <Form.Item name={CreateAssetFormValuesNames.CITY} rules={[{ required: true, message: requiredFieldText }]}>
              <Input placeholder={cityPlaceholder} />
            </Form.Item>
          </div>
          <div className={'CreateAssetDrawer__main-wrapper'}>
            <Form.Item name={CreateAssetFormValuesNames.PROVINCE} rules={[{ required: true, message: requiredFieldText }]}>
              <Input placeholder={provincePlaceholder} />
            </Form.Item>
            <Form.Item name={CreateAssetFormValuesNames.COUNTRY} rules={[{ required: true, message: requiredFieldText }]}>
              <Input placeholder={countryPlaceholder} />
            </Form.Item>
          </div>
          <div className={'CreateAssetDrawer__main-wrapper'}>
            <Form.Item name={CreateAssetFormValuesNames.DESCRIPTION}>
              <Input placeholder={descriptionPlaceholder} maxLength={100} />
            </Form.Item>
          </div>
          <div className={'CreateAssetDrawer__main-wrapper'}>
            <p>(*) {requiredFieldText}</p>
          </div>
        </Form>
      </main>
      <footer>
        <DefaultButton onClick={onCloseCreateAssetDrawer} title={closeButtonText} />
        <PrimaryButton key={'submit'} loading={creating} title={createAssetButtonText} onClick={() => form.submit()} />
      </footer>
    </DrawerAtom>
  )
}

export default CreateAssetDrawer;