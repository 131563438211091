import { useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { useParams } from 'react-router-dom';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { useClientTranslations } from './useClientTranslations';
import { useClientCRUD } from './useClientCRUD';
import { ClientEditFormValues, ClientEditFormValuesNames } from './models';
import { getUsers } from 'services/api/users';
import { useTranslation } from 'react-i18next';

export const useClientEdit = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ClientEditFormValues>();
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [areThereChanges, setAreThereChanges] = useState<boolean>(false);
  const { clientId } = useParams<{ clientId: string }>();
  const { getClientTranslations } = useClientTranslations();
  const { data, loading, updating, updateClient, getClientById } = useClientCRUD();
  const [totalNumberUsers, setTotalNumberUsers] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clientEditTranslations = getClientTranslations();

  const clientDescriptionLabel = t('SETTINGS_CLIENTS_DETAIL_HEADER_DESCIPTION_LABEL');
  const clientDescription = data.description || '--';
  const clientEmailLabel = t('SETTINGS_CLIENTS_DETAIL_HEADER_EMAIL_LABEL');
  const clientEmail = data.email || '--';
  const totalNumberUsersClientLabel = t('SETTINGS_CLIENTS_DETAIL_HEADER_TOTAL_USERS_LABEL');
  const totalNumberUsersClient = totalNumberUsers ? String(totalNumberUsers) : '--';

  const getInitialEditFormValues = (client: ClientModel) => {
    const { name, email, description } = client;
    form.setFieldsValue({ name, email, description });
  };

  const onClickContextualEditButton = () => {
    setIsEditModalVisible(true);
  };

  const onCancelEditForm = () => {
    setIsEditModalVisible(false);
    data && getInitialEditFormValues(data);
  };

  const validateEditFormValuesManager = {
    [ClientEditFormValuesNames.NAME]: (value: string): boolean => value !== data.name,
    [ClientEditFormValuesNames.EMAIL]: (value: string): boolean => value !== data.email,
    [ClientEditFormValuesNames.DESCRIPTION]: (value: string): boolean => value !== data.description,
  };

  const onChangeEditFormValues = async (values: { [key in ClientEditFormValuesNames]: string }) => {
    const label = Object.keys(values) as ClientEditFormValuesNames[];
    const value = Object.values(values);
    const hasFormChanged = validateEditFormValuesManager[label[0]](value[0]);
    setAreThereChanges(hasFormChanged);
  };

  const onSubmitEditFormFailed = () => {
    message.error(clientEditTranslations.editFormTranslations.submitError, 3);
  };

  const onSubmitEditForm = async (values: ClientEditFormValues) => {
    const { name, email, description } = values;
    const client = { ...data, name, email, description };
    const textMessage = clientEditTranslations.editFormTranslations.submitSuccess;
    try {
      await updateClient({ client, textMessage });
      getClientById(clientId);
    } finally {
      setIsEditModalVisible(false);
    }
  };

  const getData = async () => {
    try {
      const response = await getUsers();
      setTotalNumberUsers(response.meta?.all_results)
    } catch (error) {
      message.error(t('SETTINGS_CLIENTS_DETAIL_HEADER_TOTAL_USERS_ERROR'), 5);
      setTotalNumberUsers(null)
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    data && getInitialEditFormValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    data,
    clientEditTranslations,
    form,
    clientDescription,
    clientDescriptionLabel,
    clientEmail,
    clientEmailLabel,
    totalNumberUsersClient,
    totalNumberUsersClientLabel,
    onClickContextualEditButton,
    onCancelEditForm,
    onChangeEditFormValues,
    onSubmitEditForm,
    onSubmitEditFormFailed,
    loading: isLoading || loading,
    updating,
    editModalVisible: isEditModalVisible,
    areThereChanges,
  };
};
