import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Input, Button, Image, PageHeader, message } from 'antd';
import { SendOutlined, QrcodeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { updateGatewayInformation } from 'services/api/gateways';
import { IMAGES } from 'constants/images';
import Paper from 'components/atoms/Paper';
import ModalQRScanner from './ModalQRScanner';
import './styles.scss';

interface FormValues {
  GID: string;
  QID: string;
}

const ONLY_NUMBERS_AND_CAPITAL_LETTERS_VALUES_CONTROLLER = /^[A-Z0-9]+$/;

const ProvisionedPage: FC = () => {
  const { t } = useTranslation();
  const path = useLocation();
  const [form] = Form.useForm<FormValues>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(path.search);
    const gid = params.get('gid');
    if (gid) {
      form.setFieldsValue({ GID: gid });
      setIsModalVisible(true);
    }
  }, [t, form, path.search]);

  const handleOnFinish = async (values: FormValues) => {
    try {
      await updateGatewayInformation(values.GID, values.QID);
      message.success(t('linked'));
      form.resetFields();
    } catch (error) {
      console.warn(error);
    }
  };

  const handleOnClick = () => {
    setIsModalVisible(true);
  };

  const trimID = (qr: string, indexQueryString: number) => {
    return qr.slice(indexQueryString + 1, qr.length);
  };

  const handleOnScanQr = (qr: string) => {
    const indexQueryString = qr.indexOf('=');

    if (indexQueryString === -1) form.setFieldsValue({ QID: qr });
    if (indexQueryString !== -1) {
      const _qr = trimID(qr, indexQueryString);
      form.setFieldsValue({ QID: _qr });
    }

    setIsModalVisible(false);
  };

  return (
    <>
      <PageHeader ghost={false} title={t('bind_GID_QID_Gateway')} />
      <div className='ProvisionedPage__wrapper'>
        <Paper>
          <div className='ProvisionedPage__container'>
            <Form form={form} onFinish={handleOnFinish} className='ProvisionedPage__form' layout='vertical'>
              <Form.Item name='GID' label={`${t('enter_the')} GID:`} rules={[{ required: true, message: t('field_required') }]}>
                <Input allowClear placeholder='00000000xx00xx00' />
              </Form.Item>
              <Form.Item
                name='QID'
                label={`${t('enter_the')} QID:`}
                rules={[
                  { required: true, message: t('field_required') },
                  { pattern: ONLY_NUMBERS_AND_CAPITAL_LETTERS_VALUES_CONTROLLER, message: t('_VALIDATE_QID_VALUE') }
                ]}
                tooltip={<Image src={IMAGES.qid} preview={false} />}
              >
                <Input allowClear placeholder='Q000000000000' addonAfter={<QrcodeOutlined onClick={handleOnClick} />} />
              </Form.Item>
              <Form.Item>
                <Button icon={<SendOutlined />} key='link' htmlType='submit' type='primary'>
                  {t('linked')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Paper>
      </div>
      <ModalQRScanner
        title={`${t('scan')} QID`}
        onCancel={() => setIsModalVisible(false)}
        onScan={(qr) => handleOnScanQr(qr)}
        visible={isModalVisible}
      />
    </>
  );
};

export default ProvisionedPage;
