import { ProjectAssetsResponseModel } from '../model/projectModel';

export const ProjectAssetsResponseEntity = ({
  data,
  included,
  links,
  meta,
}: ProjectAssetsResponseModel): Readonly<ProjectAssetsResponseModel> => {
  return Object.freeze({ data, included, links, meta });
};
