import { UserPermissions } from 'models/users.model';

const USER_PERMISSIONS = 'user:permissions';

export const savePermissions = (userPermissions: UserPermissions[]): void => {
  localStorage.setItem(USER_PERMISSIONS, JSON.stringify(userPermissions));
};

export const hasPermission = (userPermissions: UserPermissions | UserPermissions[]): boolean => {
  const data = localStorage.getItem(USER_PERMISSIONS);
  const permissions: UserPermissions[] = data ? JSON.parse(data) : [];

  if (typeof userPermissions === 'string') return permissions.includes(userPermissions);

  // TODO: este metodo puede construirse mejor, usando filter
  const havePermissions = userPermissions.map((userPermission) => permissions.includes(userPermission));

  return !havePermissions.includes(false);
};
