import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { AssetCustomData } from 'components/pages/GatewaysListPage/resources/utils';


export const AssignGatewayToAssetModalColumns = (): AlfredTableColumn<AssetCustomData>[] => {
  return [
    {
      title: 'Alias',
      key: 'label',
      width: '70%',
      dataIndex: 'label',
    },
    {
      title: 'Id',
      key: 'value',
      width: '30%',
      dataIndex: 'value',
    },
  ];
};
