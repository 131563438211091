import { FC } from 'react';
import { Icon } from '../Icons';
import './styles.scss';

interface Props {
  name?: string;
  onDelete: () => void;
  onShow: () => void;
  showActionVisible: boolean;
  deleteActionVisible: boolean;
  disabled?: boolean;
}

const UploadActionsVisor: FC<Props> = ({
  name = '',
  onShow,
  onDelete,
  disabled = false,
  showActionVisible,
  deleteActionVisible,
}) => {
  return (
    <div className={`UploadActionsVisor__container ${disabled && 'UploadActionsVisor__container--disabled'}`}>
      <span>{name}</span>
      <div className={'UploadActionsVisor__icons'}>
        {!!showActionVisible && <Icon.Show onClick={onShow} className={`${!showActionVisible && !disabled && 'UploadActionsVisor__icon--hide'} ${disabled && 'UploadActionsVisor__icon--disabled'}`} />}
        <Icon.Delete onClick={onDelete} className={`${!deleteActionVisible && !disabled && 'UploadActionsVisor__icon--hide'} ${disabled && 'UploadActionsVisor__icon--disabled'}`} />
      </div>
    </div>
  );
};

export default UploadActionsVisor;
