import api from 'services/api';
import { qrAssetsPath } from 'constants/endpointsPath/assets';
import { QrAssetsListValueObject } from '../entities/qrAssetsListValueObject';
import { QrAssets } from '../models/qrAssets';
import { QrAssetsUnparsed } from '../models/qrAssetsUnparsed';
import { payloadToQrAssets } from '../mappers/payloadToQrAssets';

export const GetQrAssetsList = async (quantity: string): Promise<Readonly<QrAssets>[]> => {
  const path = qrAssetsPath(quantity);
  const { data } = await api.get<QrAssetsUnparsed[]>(path);
  const qrAssetParser = data.map((item) => payloadToQrAssets(item));
  const { list } = QrAssetsListValueObject(qrAssetParser);
  return list;
};
