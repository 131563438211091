import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';

interface ModalFormConfirmProps<T> {
  title: string;
  text: string;
  buttonSuccess: string;
  onConfirm: any;
  onClickConfirm: (callback: (values: T) => void) => Promise<void>;
  onCancel: () => void;
  isVisible: boolean;
  isLoading: boolean;
}

const ModalFormConfirm = <T extends unknown>({
  title,
  text,
  buttonSuccess,
  onConfirm,
  onClickConfirm,
  onCancel,
  isVisible,
  isLoading,
}: ModalFormConfirmProps<T>) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={isVisible}
      title={title}
      onCancel={onCancel}
      footer={[
        <DefaultButton key={'back'} title={t('_COMMON_AREA_CANCEL')} onClick={onCancel} />,
        <PrimaryButton key={'confirm'} title={buttonSuccess} loading={isLoading} onClick={() => onClickConfirm(onConfirm)} />,
      ]}
      destroyOnClose
    >
      <p>{text}</p>
    </Modal>
  );
};

export default ModalFormConfirm;
