import { RouteConfig } from 'react-router-config';
import ProjectsPage from 'components/pages/ProjectsPage';
import ProjectPage from 'components/pages/ProjectPage';
import ProjectSharedGatewayDetailPage from 'components/pages/ProjectSharedGatewayDetailPage';
import {
  ROUTE_PATH_GATEWAY_DETAIL,
  ROUTE_PATH_PROJECT,
  ROUTE_PATH_PROJECTS,
  ROUTE_PATH_PROJECT_SUB_TAB,
  ROUTE_PATH_PROJECT_SUB_TAB_ID,
  ROUTE_PATH_PROJECT_SUB_TAB_ID_ACTION,
} from './config';

export const projectsRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_GATEWAY_DETAIL,
    exact: true,
    component: ProjectSharedGatewayDetailPage,
  },
  {
    path: ROUTE_PATH_PROJECTS,
    exact: true,
    component: ProjectsPage,
  },
  {
    path: ROUTE_PATH_PROJECT,
    exact: true,
    component: ProjectPage,
  },
  {
    path: ROUTE_PATH_PROJECT_SUB_TAB,
    exact: true,
    component: ProjectPage,
  },
  {
    path: ROUTE_PATH_PROJECT_SUB_TAB_ID,
    exact: true,
    component: ProjectPage,
  },
  {
    path: ROUTE_PATH_PROJECT_SUB_TAB_ID_ACTION,
    exact: true,
    component: ProjectPage,
  },
];
