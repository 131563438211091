import { RouteConfig } from 'react-router-config';
import DashboardPage from 'components/pages/DashboardPage';
import { ROUTE_PATH_DASHBOARD } from './config';

export const dashboardRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_DASHBOARD,
    exact: true,
    component: DashboardPage,
  },
];
