import { EventsAndNewsFormValues } from './form';

export const TRANSLATION = {
  [EventsAndNewsFormValues.TITLE]: '_EVENTS_AND_NEWS_TITLE',
  [EventsAndNewsFormValues.IMAGE]: '_EVENTS_AND_NEWS_IMAGE',
  [EventsAndNewsFormValues.DESCRIPTION]: '_EVENTS_AND_NEWS_DESCRIPTION',
  [EventsAndNewsFormValues.LINK]: '_EVENTS_AND_NEWS_LINK',
  [EventsAndNewsFormValues.LINK_TITLE]: '_EVENTS_AND_NEWS_LINK_TITLE',
  [EventsAndNewsFormValues.SEND_NOTIFICATION]: '_EVENTS_AND_NEWS_PUSH_NOTIFICATION',
  delete: '_EVENTS_AND_NEWS_DELETE',
  close: '_EVENTS_AND_NEWS_CLOSE',
  create: '_EVENTS_AND_NEWS_CREATE',
  save: '_EVENTS_AND_NEWS_SAVE',
  fieldRequired: 'field_required',
  tooltip: '_EVENTS_AND_NEWS_TOOLTIP',
};
