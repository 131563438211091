import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TabSidebarMenuList from '../TabSidebarMenuList';
import { EventsAndNewsVisorViews } from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabEventsAndNews/enums/visorViews';
import './styles.scss';

export interface TabEventsAndNewsMenuItem {
  id: string;
  name: string;
  disabled: boolean;
}

interface TabEventsAndNewsMenuProps {
  menuListItems: TabEventsAndNewsMenuItem[];
  selectedItemId?: string;
  visibleType: EventsAndNewsVisorViews;
  onVisibleCreate: () => void;
  onClickMenuItem: (id: string) => void;
}

enum TRANSLATION {
  create = '_EVENTS_AND_NEWS_CREATE'
}

const TabEventsAndNewsMenu: FC<TabEventsAndNewsMenuProps> = ({
  menuListItems,
  selectedItemId,
  visibleType,
  onVisibleCreate,
  onClickMenuItem,
}) => {
  const { t } = useTranslation();
  const getClassName = visibleType === EventsAndNewsVisorViews.CREATE ? 'TabEventsAndNewsMenu__link--disabled' : 'TabEventsAndNewsMenu__link--active';
  const click = visibleType === EventsAndNewsVisorViews.CREATE ? undefined : onVisibleCreate;

  return (
    <div>
      <div
        className={`TabEventsAndNewsMenu__title ${getClassName}`}
        onClick={click}
      >
        {t(TRANSLATION.create)}
      </div>
      <TabSidebarMenuList
        listItems={menuListItems}
        selectedItemId={selectedItemId}
        onClick={onClickMenuItem}
      />
    </div>
  );
};

export default TabEventsAndNewsMenu;
