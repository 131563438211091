import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';

interface Props {
  title: string;
  text: string;
  buttonSuccess: string;
  onConfirm: (bookingId: string) => void;
  onClickConfirm: (callback: (values: any) => void) => Promise<void>;
  onCancel: () => void;
  isVisible: boolean;
  isLoading: boolean;
}

const CommonAreaDeleteConfirmModal = ({
  title,
  text,
  buttonSuccess,
  onConfirm,
  onClickConfirm,
  onCancel,
  isVisible,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={isVisible}
      title={title}
      onCancel={onCancel}
      footer={[
        <DefaultButton key={'back'} title={t('_BOOKINGS_CANCEL')} onClick={onCancel} />,
        <PrimaryButton key={'confirm'} title={buttonSuccess} loading={isLoading} onClick={() => onClickConfirm(onConfirm)} danger />,
      ]}
      destroyOnClose
    >
      <p>{text}</p>
    </Modal>
  );
};

export default CommonAreaDeleteConfirmModal;
