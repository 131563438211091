import { AssetGatewayConnectionStatusType } from 'core/domain/assets/models/assetsModel';
import {
  ProjectAssetGatewayModel,
  ProjectAssetModel,
  ProjectAssetsIncludedModel,
  ProjectAssetsIncludedProjectModel,
  ProjectAssetsResponseModel,
  ProjectsAssetsLinksModel,
  ProjectsAssetsMetaModel,
} from '../model/projectModel';
import {
  PayloadProjectAssetGatewayModel,
  PayloadProjectAssetModel,
  PayloadProjectAssetsIncludedModel,
  PayloadProjectAssetsIncludedProjectModel,
  PayloadProjectAssetsResponseModel,
  PayloadProjectsAssetsLinksModel,
  PayloadProjectsAssetsMetaModel,
} from '../model/projectPayloadModel';

export const payloadToProjectAssetsGateway = ({
  id,
  assistant_cloud,
  gateway_password,
  gateway_user,
}: PayloadProjectAssetGatewayModel): ProjectAssetGatewayModel => {
  return {
    id: id ?? '',
    assistantCloud: assistant_cloud ?? '',
    gatewayPassword: gateway_password ?? '',
    gatewayUser: gateway_user ?? '',
  };
};

export const payloadToProjectAsset = ({
  id,
  alias,
  apartment,
  background_image,
  block_number,
  city,
  country,
  created_at,
  gateways,
  logo,
  staircase,
  state,
  street_name,
  street_type,
  zip_code,
  is_occupied,
  gateway_connection_status,
}: PayloadProjectAssetModel): ProjectAssetModel => {
  const transformedProjectAssetGateways = gateways?.map((item) => payloadToProjectAssetsGateway(item)) || [];
  return {
    id: id ?? '',
    alias: alias ?? '',
    apartment: apartment ?? '',
    backgroundImage: background_image ?? '',
    blockNumber: block_number ?? '',
    city: city ?? '',
    country: country ?? '',
    createdAt: created_at ?? 0,
    gateways: transformedProjectAssetGateways,
    logo: logo ?? '',
    staircase: staircase ?? '',
    state: state ?? '',
    streetName: street_name ?? '',
    streetType: street_type ?? '',
    zipCode: zip_code ?? '',
    isOccupied: is_occupied ?? false,
    gatewayConnectionStatus: gateway_connection_status ?? AssetGatewayConnectionStatusType.DEFAULT,
  };
};

export const payloadToProjectAssetsIncludedProjects = ({
  id,
  background_image,
  latitude,
  links,
  logo,
  longitude,
  name,
  status,
  support_email,
  uuid,
}: PayloadProjectAssetsIncludedProjectModel): ProjectAssetsIncludedProjectModel => {
  return {
    id: id ?? '',
    backgroundImage: background_image ?? '',
    latitude: latitude ?? '',
    links: links ?? { self: '' },
    logo: logo ?? '',
    longitude: longitude ?? '',
    name: name ?? '',
    status: status ?? '',
    supportEmail: support_email ?? '',
    uuid: uuid ?? '',
  };
};

export const payloadToProjectAssetsIncluded = ({ projects }: PayloadProjectAssetsIncludedModel): ProjectAssetsIncludedModel => {
  const transformedProjectsAssetsIncludedProjects = projects.map((item) => payloadToProjectAssetsIncludedProjects(item));
  return {
    projects: transformedProjectsAssetsIncludedProjects,
  };
};

export const payloadToProjectAssetsLinks = ({
  first,
  last,
  next,
  prev,
  self,
}: PayloadProjectsAssetsLinksModel): ProjectsAssetsLinksModel => {
  return {
    first: first ?? '',
    last: last ?? '',
    next: next ?? '',
    prev: prev ?? '',
    self: self ?? '',
  };
};

export const payloadToProjectAssetsMeta = ({
  all_results,
  page_size,
  results,
  total_pages,
}: PayloadProjectsAssetsMetaModel): ProjectsAssetsMetaModel => {
  return {
    allResults: all_results ?? 1,
    pageSize: page_size ?? 1,
    results: results ?? 1,
    totalPages: total_pages ?? 1,
  };
};

export const payloadToProjectAssetsResponse = ({
  data,
  included,
  links,
  meta,
}: PayloadProjectAssetsResponseModel): ProjectAssetsResponseModel => {
  const transformedData = data.map((item) => payloadToProjectAsset(item));
  const transformedIncluded = payloadToProjectAssetsIncluded(included);
  const transformedLinks = payloadToProjectAssetsLinks(links);
  const transformedMeta = payloadToProjectAssetsMeta(meta);
  return {
    data: transformedData,
    included: transformedIncluded,
    links: transformedLinks,
    meta: transformedMeta,
  };
};
