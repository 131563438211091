import { ReportMaintenance } from '../model/reportMaintenance';
import { ReportMaintenanceUnparsed } from '../model/reportMaintenance/reportMaintenanceUnparsed';

export const reportMaintenanceToPayload = ({
  deviceId,
  action,
  deviceType,
  comments,
  batteryVendor,
  batteryVoltage,
}: ReportMaintenance): ReportMaintenanceUnparsed => {
  return {
    device_id: deviceId,
    action,
    device_type: deviceType,
    comments,
    battery_voltage: batteryVoltage,
    battery_vendor: batteryVendor,
  };
};
