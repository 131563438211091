import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import PeripheralDevices from './PeripheralDevices';
import { useTabDevices } from './resources/useTabDevices';
import ShareSensorModal from 'components/organisms/ShareSensorModal';

const TabDevices: FC = () => {
  const {
    devices,
    gatewayId,
    peripheralsTitle,
    peripheralExpandedColumnsTranslations,
    searchInput,
    columnSearchTranslations,
    shareSensorOptions,
    onSearch,
    onReset,
    loading,
  } = useTabDevices();

  return (
    <>
      <Paper>
        <PeripheralDevices
          loading={loading}
          gatewayId={gatewayId}
          devices={devices}
          title={peripheralsTitle}
          expandedColumnsTranslations={peripheralExpandedColumnsTranslations}
          searchInput={searchInput}
          columnSearchTranslations={columnSearchTranslations}
          onSearch={onSearch}
          onReset={onReset}
          onOpenShareSensorModal={shareSensorOptions.onOpenShareSensorModal}
        />
      </Paper>
      <ShareSensorModal
        modalOptions={shareSensorOptions}
        open={shareSensorOptions.shareSensorModalVisible}
        onCancel={shareSensorOptions.onCloseShareSensorModal}
      />
    </>
  );
}

export default TabDevices;