export interface PayloadSupportTicketsStatisticsModel {
  tickets_received: number;
  tickets_closed: number;
  tickets_revoked: number;
  tickets_in_progress: number;
  tickets_closed_time_average: string;
  tickets_closed_time_average_units: string;
  first_response_time_average: string;
  first_response_time_average_units: string;
}

export enum SupportTicketCategoryType {
  AUTOMATED = 'automated',
  CONNECTIVITY = 'connectivity',
  ELECTRIC = 'electric',
  FURNITURE = 'furniture',
  GAS = 'gas',
  HOME_APPLIANCES = 'home_appliances',
  HOME_AUTOMATION = 'home_automation',
  INTERNET = 'internet',
  PLUMBING = 'plumbing',
  OTHERS = 'others',
}

export enum SupportTicketPriorityType {
  HIGH = 'high',
  LOW = 'low',
  NORMAL = 'normal',
  URGENT = 'urgent',
}

export enum SupportTicketStatusType {
  ALL_STATUSES = 'all_statuses',
  ACCEPTED = 'accepted',
  ASSIGNED = 'assigned',
  CLOSED = 'closed',
  OPENED = 'opened',
  REVOKED = 'revoked',
}

export enum SupportTicketTypeType {
  AUTOMATED = 'automated',
  MANUAL = 'manual',
  ALL_SOURCES = 'all_sources',
}

export interface SupportTicketCommentPayloadModel {
  created_at: number;
  description: string;
  email: string;
  status: SupportTicketStatusType;
  support_ticket_id: string;
}

export interface SupportTicketMediaPayloadModel {
  picture?: string[];
  video?: string[];
}

export interface SupportTicketCreatedByPayloadModel {
  uuid: string;
  type: SupportTicketTypeType;
  name: string;
  email: string;
}

export interface SupportTicketPayloadModel {
  address: string;
  asset_id: string;
  category: SupportTicketCategoryType;
  comments: SupportTicketCommentPayloadModel[];
  created_at: number;
  created_by: SupportTicketCreatedByPayloadModel;
  description: string;
  id: string;
  media: SupportTicketMediaPayloadModel;
  phone_number: string;
  priority: SupportTicketPriorityType;
  room: string;
  status: SupportTicketStatusType;
  support_email: string;
  title: string;
}

export interface ListPaginationPayloadModel {
  total_pages: number;
  page_size: number;
  results: number;
  all_results: number;
}

export interface LinksPayloadModel {
  first: string;
  last: string;
  next: string;
  prev: string;
  self: string;
}

export interface TotalValuesPayloadModel {
  tickets_created_during_last_month: number;
  tickets_created_during_last_week: number;
  total_accepted_support_tickets: number;
  total_in_progress_support_tickets: number;
  total_open_support_tickets: number;
}

export interface SupportTicketsResponsePayloadModel {
  data: SupportTicketPayloadModel[];
  meta: ListPaginationPayloadModel;
  links: LinksPayloadModel;
  total_values?: TotalValuesPayloadModel;
}
