import { useTranslation } from 'react-i18next';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import './styles.scss';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';

export const ClientsColumns = (): AlfredTableColumn<ClientModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('SETTINGS_CLIENTS_LIST_CLIENT_NAME_COLUMN'),
      key: 'username',
      dataIndex: 'name',
    },
    {
      title: t('SETTINGS_CLIENTS_LIST_CLIENT_EMAIL_COLUMN'),
      key: 'email',
      render: (text, record: ClientModel) => record.email! || t('not_reported'),
    },
    {
      title: t('SETTINGS_CLIENTS_LIST_CLIENT_DESCRIPTION_COLUMN'),
      key: 'description',
      render: (text, record: ClientModel) => record.description! || t('not_reported'),
    },
  ];
};
