import { GetCommonAreaListByProjectId } from 'core/domain/bookingsAndCommonAreas/repositories/getCommonAreaListByProjectId';
import { GetCommonAreaById } from 'core/domain/bookingsAndCommonAreas/repositories/getCommonAreaById';
import { UpdateCommonArea } from 'core/domain/bookingsAndCommonAreas/repositories/updateCommonArea';
import {
  CommonAreaModel,
  CreateCommonAreaModel,
  UpdateCommonAreaModel,
} from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { EnableCommonArea } from 'core/domain/bookingsAndCommonAreas/repositories/enableCommonArea';
import { RemoveCommonArea } from 'core/domain/bookingsAndCommonAreas/repositories/removeCommonArea';
import { CreateCommonArea } from 'core/domain/bookingsAndCommonAreas/repositories/createCommonArea';

export const useCommonAreasCRUD = () => {
  const getCommonAreasList = async ({ projectId }: { projectId: string }): Promise<CommonAreaModel[] | undefined> => {
    try {
      return await GetCommonAreaListByProjectId(projectId);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getCommonArea = async ({ commonAreaId }: { commonAreaId: string }): Promise<CommonAreaModel | undefined> => {
    try {
      return await GetCommonAreaById(commonAreaId);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const createCommonArea = async ({ projectId, newCommonArea }: { projectId: string; newCommonArea: CreateCommonAreaModel }) => {
    try {
      return await CreateCommonArea(projectId, newCommonArea);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const updateCommonArea = async (values: Omit<UpdateCommonAreaModel, 'enabled' | 'hasBooking'>): Promise<void> => {
    try {
      await UpdateCommonArea(values);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const enableCommonArea = async ({
    commonArea,
    checked,
  }: {
    checked: boolean;
    commonArea: Omit<UpdateCommonAreaModel, 'enabled'>;
  }): Promise<void> => {
    try {
      await EnableCommonArea({ commonArea, checked });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const removeCommonArea = async ({ id }: { id: string }): Promise<void> => {
    try {
      await RemoveCommonArea({ id });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return { getCommonAreasList, getCommonArea, createCommonArea, updateCommonArea, enableCommonArea, removeCommonArea };
};
