import api from '../../../../services/api';
import { clientPath } from 'core/endspoints/clients';
import ClientEntity from '../entities/clientEntity';
import { payloadToClient } from '../mappers/payloadToClient';
import { ClientModel } from '../models/clientEntityModel';
import { PayloadClientModel } from '../models/clientPayloadModel';

export const GetClientById = async (id: string): Promise<ClientModel | undefined> => {
  const url = clientPath(id);
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    const response = await api.get<PayloadClientModel>(url);
    const mappedClient = response && payloadToClient(response.data);
    const client = mappedClient && ClientEntity(mappedClient);
    return client;
  } catch (error: any) {
    throw new Error(error);
  }
};
