import { Tooltip } from 'antd';
import { useState, FC, ReactNode } from 'react';
import './styles.scss';

interface SegmentedControlProps {
  tabs: { tab: ReactNode; tooltipText: string }[];
  defaultIndex?: number;
  onSelection: (index: number) => void;
}

const SegmentedControl: FC<SegmentedControlProps> = ({ tabs, defaultIndex = 0, onSelection }) => {
  const [value, setValue] = useState<number>(defaultIndex);

  const handleOnChange = (index: number) => {
    setValue(index);
    onSelection(index);
  };

  const renderSelectedClass = (index: number) => {
    const lastChild = tabs.length - 1;
    if (index === lastChild) {
      return `SegmentedControl__tabContainer${index === value ? '--selected-last' : ''}`;
    }
    return `SegmentedControl__tabContainer${index === value ? '--selected' : ''}`;
  };

  return (
    <div className={'SegmentedControl__container'}>
      <div className={'SegmentedControl__fillContainer'}>
        {tabs.map(({ tab, tooltipText }, index) => (
          <Tooltip placement={'topRight'} title={tooltipText}>
            <div className={'SegmentedControl__trackContainer'} key={index}>
              <button className={renderSelectedClass(index)} onClick={() => handleOnChange(index)}>
                <p className={'SegmentedControl__tabText'}>{tab}</p>
              </button>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default SegmentedControl;
