import { FC } from 'react';
import ModalAtom from 'components/atoms/ModalAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';

interface Props {
  title: string;
  description: string;
  cancelButtonText: string;
  acceptButtonText: string;
  onCancel: () => void;
  onAccept: () => void;
  isVisible: boolean;
  isLoading: boolean;
}

const CheckInRoomForceSaveModal: FC<Props> = ({
  title,
  description,
  cancelButtonText,
  acceptButtonText,
  onCancel,
  onAccept,
  isVisible,
  isLoading,
}) => {
  return (
    <ModalAtom
      className={'CheckInRoomForceSaveModal'}
      title={title}
      onCancel={onCancel}
      open={isVisible}
      destroyOnClose
      footer={[
        <DefaultButton
          onClick={onCancel}
          title={cancelButtonText}
        />,
        <PrimaryButton
          loading={isLoading}
          onClick={onAccept}
          title={acceptButtonText}
        />,
      ]}
    >
      {description}
    </ModalAtom>
  );
};

export default CheckInRoomForceSaveModal;
