import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import { LogListCustomDataModel, transformLogListToCustomData } from './utils';
import { getOfficeLogListWithPaginationPath } from 'components/pages/App/routes/access/config';
import { GetAccessesLogListByProjectId } from 'core/domain/offices/repository/getAccessesLogListByProjectId';
import { GetDownloadLogCsvFileByProjectId } from 'core/domain/access/repository/getDownloadLogCsvFileByProjectId';
import { Form } from 'antd';
import moment, { Moment } from 'moment';
import { DownloadLogFormValues, DownloadLogFormValuesModel, DownloadLogModalLiteralsModel } from 'components/organisms/AccessDownloadLogModal';
import { LogListColumnsLiteralsModel } from 'components/template/AccessesLogListTemplate/LogColumns';
import { downloadCsvFile } from 'utils/csv';

interface GetAccessesLogListWithPaginationByProjectIdProps {
  page: number,
  size: number,
}

interface GetDownloadLogCsvFileByProjectIdProps {
  fromDate: number,
  untilDate: number,
}

export const useAccessesLogList = () => {
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [logList, setLogList] = useState<LogListCustomDataModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [totalPages, setTotalPages] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [isVisibleDownloadLogModal, setIsVisibleDownloadLogModal] = useState<boolean>(false);
  const [isLoadingDownloadLog, setIsLoadingDownloadLog] = useState<boolean>(false);
  const [form] = Form.useForm<DownloadLogFormValuesModel>();

  const logListColumnsLiterals: LogListColumnsLiteralsModel = {
    dateText: t('_ACCESSES_LOG_LIST_COLUMNS_DATE_TEXT'),
    actionText: t('_ACCESSES_LOG_LIST_COLUMNS_ACTION_TEXT'),
    userText: t('_ACCESSES_LOG_LIST_COLUMNS_USER_TEXT'),
    lockTypeText: t('_ACCESSES_LOG_LIST_COLUMNS_LOCK_TYPE_TEXT'),
    nameText: t('_ACCESSES_LOG_LIST_COLUMNS_NAME_TEXT'),
    roomText: t('_ACCESSES_LOG_LIST_COLUMNS_ROOM_TEXT'),
    downloadLogButtonText: t('_ACCESSES_LOG_LIST_DOWNLOAD_LOG_BUTTON_TEXT'),
  };

  const downloadLogModalLiterals: DownloadLogModalLiteralsModel = {
    titleText: t('_ACCESSES_DOWNLOAD_LOG_MODAL_TITLE_TEXT'),
    descriptionText: t('_ACCESSES_DOWNLOAD_LOG_MODAL_DESCRIPTION_TEXT'),
    cancelButtonText: t('_ACCESSES_DOWNLOAD_LOG_MODAL_CANCEL_BUTTON_TEXT'),
    confirmButtonText: t('_ACCESSES_DOWNLOAD_LOG_MODAL_CONFIRM_BUTTON_TEXT'),
    fromDateLabelText: t('_ACCESSES_DOWNLOAD_LOG_MODAL_FROM_DATE_LABEL_TEXT'),
    untilDateLabelText: t('_ACCESSES_DOWNLOAD_LOG_MODAL_UNTIL_DATE_LABEL_TEXT'),
    datePickerPlaceholderText: t('_ACCESSES_DOWNLOAD_LOG_MODAL_DATE_PICKER_PLACEHOLDER_TEXT'),
    requireFieldText: t('field_required')
  };

  const getIsDisabledFromDate = (current: Moment) => {
    return current > moment().endOf('day');
  };

  const getIsDisabledUntilDate = (current: Moment) => {
    const fromDate = form.getFieldValue(DownloadLogFormValues.FROM_DATE);
    const isCurrentDateBeforeFromDate = fromDate && current.isBefore(fromDate.startOf('day'));
    const isCurrentDateAfterToday = current > moment().endOf('day');

    return isCurrentDateBeforeFromDate || isCurrentDateAfterToday;
  };

  const onShowDownloadLog = () => {
    const untilDate = moment();
    form.setFieldsValue({ [DownloadLogFormValues.UNTIL_DATE]: untilDate });
    setIsVisibleDownloadLogModal(true);
  };

  const onCancelDownloadLog = () => {
    setIsVisibleDownloadLogModal(false);
    setTimeout(() => {
      form.resetFields();
    }, 500);
  };

  const getDownloadLogCsvFileByProjectId = async ({fromDate, untilDate}: GetDownloadLogCsvFileByProjectIdProps) => {
    setIsLoadingDownloadLog(true);
    try {
      const csvData = await GetDownloadLogCsvFileByProjectId({
        projectId,
        fromDate,
        untilDate
      });
      const csvFileName = t('_ACCESSES_DOWNLOAD_LOG_FILE_NAME');

      !!csvData && downloadCsvFile(csvData, csvFileName);
      onCancelDownloadLog();
    } catch (error) {
      setMessageError({ description: t('_ACCESSES_DOWNLOAD_LOG_ERROR_MESSAGE') });
    } finally {
      setIsLoadingDownloadLog(false);
      onCancelDownloadLog();
    }
  };

  const onSubmitDownloadLogForm = (values: DownloadLogFormValuesModel) => {
    const { fromDate, untilDate } = values;
    const timestampFromDate = fromDate.unix();
    const timestampUntilDate = untilDate.unix();
    getDownloadLogCsvFileByProjectId({fromDate: timestampFromDate, untilDate: timestampUntilDate})
  };

  const onChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize!);
    history.push(getOfficeLogListWithPaginationPath({ clientId, projectId, page: String(page), size: String(pageSize!) }));
  };

  const getAccessesLogListWithPaginationByProjectId = async ({ page, size }: GetAccessesLogListWithPaginationByProjectIdProps) => {
    setIsLoading(true);
    try {
      const { data, meta } = await GetAccessesLogListByProjectId({
        host,
        token,
        projectId,
        pageNumber: page,
        pageSize: size,
      });
      const transformedLogList = transformLogListToCustomData(data)
      setLogList(transformedLogList);
      setTotalPages(meta.totalPages);
    } catch (error) {
      setMessageError({ description: t('_ACCESSES_LOG_LIST_ERROR_MESSAGE') });
      setLogList([]);
      setTotalPages(Number(TABLE_PAGINATION_PAGE_DEFAULT));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchPage = searchParams.get('page');
    const searchSize = searchParams.get('size');

    const parsedPage = searchPage ? Number(searchPage) : Number(TABLE_PAGINATION_PAGE_DEFAULT);
    const parsedSize = searchSize ? Number(searchSize) : Number(TABLE_PAGINATION_SIZE_DEFAULT);

    setCurrentPage(parsedPage);
    setPageSize(parsedSize);
    getAccessesLogListWithPaginationByProjectId({ page: parsedPage, size: parsedSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return {
    logList,
    logListColumnsLiterals,
    downloadLogModalLiterals,
    totalPages,
    currentPage,
    pageSize,
    form,
    onChangePage,
    onSubmitDownloadLogForm,
    onCancelDownloadLog,
    onShowDownloadLog,
    getIsDisabledFromDate,
    getIsDisabledUntilDate,
    isLoading,
    isLoadingDownloadLog,
    isVisibleDownloadLogModal,
  };
};
