import { Menu, Space } from 'antd';
import moment from 'moment';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { CustomGatewaysListDataModel } from '../utils';
import LinkButton from 'components/atoms/Button/LinkButton';
import { DATE_FORMAT } from 'constants/date';
import './styles.scss';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export interface GatewaysListColumnsTranslations {
  projectAliasText: string;
  assetAliasText: string;
  typeTitle: string,
  privateTypeText: string,
  publicTypeText: string,
  actionsTitle: string;
  notReportedText: string;
  notAssignedText: string;
  assignGatewayToAssetText: string;
  unassignGatewayToAssetText: string;
  assignGatewayToProjectText: string;
  unassignGatewayToProjectText: string;
  deleteGatewayButtonText: string;
}

interface Props {
  translations: GatewaysListColumnsTranslations;
  onSelectProject: (projectId: string) => void;
  onSelectAsset: (assetId: string) => void;
  onOpenAssignToAssetModal: (gateway: CustomGatewaysListDataModel) => void;
  onOpenUnassignToAssetModal: ({ gatewayId, assetId }: { gatewayId: string; assetId: string }) => void;
  onOpenAssignToProjectModal: (gateway: CustomGatewaysListDataModel) => void;
  onOpenUnAssignToProjectModal: (gatewayId: string) => void;
  onOpenDeleteGatewayModal: (gateway: CustomGatewaysListDataModel) => void;
}

export const GatewaysListColumns = ({
  translations,
  onSelectProject,
  onSelectAsset,
  onOpenAssignToAssetModal,
  onOpenUnassignToAssetModal,
  onOpenAssignToProjectModal,
  onOpenUnAssignToProjectModal,
  onOpenDeleteGatewayModal,
}: Props): AlfredTableColumn<CustomGatewaysListDataModel>[] => {
  const {
    projectAliasText,
    assetAliasText,
    typeTitle,
    privateTypeText,
    publicTypeText,
    actionsTitle,
    notReportedText,
    notAssignedText,
    assignGatewayToAssetText,
    assignGatewayToProjectText,
    unassignGatewayToAssetText,
    unassignGatewayToProjectText,
    deleteGatewayButtonText,
  } = translations;

  return [
    {
      title: 'GID',
      key: 'id',
      width: '15%',
      render: (text, { id, productionDate }) => (
        <Space>
          <div className={'GatewaysListColumns__cell'}>
            <b>{id}</b>
            {!!productionDate && <span className={'Text__date'}>{moment.unix(productionDate).format(DATE_FORMAT)}</span>}
          </div>
        </Space>
      ),

    },
    {
      title: 'QID',
      key: 'qrCode',
      width: '15%',
      dataIndex: 'qrCode',
      render: (text, { qrCode }) => !!qrCode ? <span>{qrCode}</span> : <span className={'GatewaysListColumns__notReported'}>{notReportedText}</span>
    },
    {
      title: assetAliasText,
      key: 'assets',
      width: '15%',
      render: (text, { assets }) => (
        !!assets.length
          ? assets.map(({ alias, id }) => (
            <div className={'GatewaysListColumns__cell'}>
              <LinkButton className={'GatewaysListColumns__alias'} title={alias || notReportedText} onClick={() => onSelectAsset(id)} />
              <span className={'Text__date'}>{id}</span>
            </div>
          ))
          : <span className={'GatewaysListColumns__notReported'}>{notAssignedText}</span>
      ),
    },
    {
      title: projectAliasText,
      key: 'projectId',
      width: '35%',
      render: (text, { projectId, projectAlias }) => {
        const projectIdText: string = projectId || '';
        return (
          <>
            {(!!projectAlias || !!projectId) && (
              <div className={'GatewaysListColumns__cell'}>
                <LinkButton
                  className={'GatewaysListColumns__alias'}
                  title={!!projectAlias ? projectAlias : projectIdText}
                  onClick={() => projectId && onSelectProject(projectId)}
                />
                {!!projectAlias && <span className={'Text__date'}>{projectId || notReportedText}</span>}
              </div>
            )}
            {!projectAlias && !projectId && <span className={'GatewaysListColumns__notReported'}>{notAssignedText}</span>}
          </>
        )
      },
    },
    {
      title: typeTitle,
      key: 'type',
      width: '10%',
      render: (text, { type }) => <span>{type === 'private' ? privateTypeText : publicTypeText}</span>,
    },
    {
      title: actionsTitle,
      key: 'actions',
      width: '10%',
      render: (text, record) => {
        const menuItems: ItemType[] = [
          !record.actions.isAssignedToAsset ? {
            key: 'assign-asset',
            label: assignGatewayToAssetText,
            onClick: () => onOpenAssignToAssetModal(record),
            disabled: record.actions.isAssetActionsDisabled,
          } : null,
          record.actions.isAssignedToAsset ? {
            key: 'unassign-asset',
            label: unassignGatewayToAssetText,
            onClick: () => onOpenUnassignToAssetModal({ gatewayId: record.id, assetId: record.assets[0].id }),
            disabled: record.actions.isAssetActionsDisabled,
          } : null,
          !record.actions.isAssignedToProject ? {
            key: 'assign-project',
            label: assignGatewayToProjectText,
            onClick: () => onOpenAssignToProjectModal(record),
            disabled: record.actions.isProjectActionsDisabled,
          } : null,
          record.actions.isAssignedToProject ? {
            key: 'unassign-project',
            label: unassignGatewayToProjectText,
            onClick: () => onOpenUnAssignToProjectModal(record.id),
            disabled: record.actions.isProjectActionsDisabled,
          } : null,
          {
            key: 'delete-gateway',
            label: deleteGatewayButtonText,
            onClick: () => onOpenDeleteGatewayModal(record),
            danger: true,
            disabled: record.actions.isProjectActionsDisabled || record.actions.isAssetActionsDisabled,
          }
        ].filter((item) => !!item);
        return (<ContextualMenu menu={<Menu items={menuItems} />} />)
      },
    },
  ];
};
