import { FC } from 'react';
import ModalAtom, { ModalAtomProps } from 'components/atoms/ModalAtom';
import QRScanner from 'components/atoms/QRScanner';

interface Props extends ModalAtomProps {
  onQrScan: (value: string) => void;
}

const QrScannerModal: FC<Props> = ({ title, onCancel, onQrScan, open }) => {
  return (
    <ModalAtom
      title={title}
      onCancel={onCancel}
      open={open}
      getContainer={false}
      destroyOnClose
      footer={null}
    >
      <QRScanner onScan={onQrScan} />
    </ModalAtom>
  );
};

export default QrScannerModal;
