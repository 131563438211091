import { FC } from 'react';
import { Svg } from './model/svg';

interface Props extends Svg {
  color?: string;
}

const LogoAlfred: FC<Props> = ({
  width = '160',
  color = '#003D59',
  ...props
}) => {
  return (
    <svg
      width={width}
      viewBox="0 0 415 66"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_816_6901)">
        <path d="M30.9216 64.9701V59.3517C29.3703 61.1952 27.3916 62.7518 24.9789 64.018C22.5661 65.2841 19.6385 65.9189 16.1927 65.9189C14.0088 65.9189 11.9426 65.615 9.99088 65.0106C8.03914 64.4063 6.3297 63.5115 4.86589 62.3297C3.40209 61.148 2.22431 59.6792 1.33593 57.92C0.444189 56.1643 0 54.1316 0 51.8255V51.6533C0 49.1176 0.457658 46.9127 1.37632 45.0422C2.29498 43.1716 3.5737 41.615 5.20913 40.3759C6.84455 39.1367 8.79629 38.2149 11.0677 37.6105C13.3358 37.0061 15.7923 36.7023 18.4338 36.7023C21.1898 36.7023 23.5285 36.8744 25.4534 37.2222C27.3782 37.5666 29.229 38.0292 31.0091 38.6066V37.7422C31.0091 34.5751 30.0467 32.1541 28.1219 30.4828C26.197 28.8114 23.4276 27.9774 19.8102 27.9774C17.2258 27.9774 14.9274 28.2374 12.9185 28.754C10.9062 29.274 8.87031 29.9628 6.80416 30.8272L3.96069 22.4434C6.48785 21.292 9.07223 20.3702 11.7138 19.6781C14.3554 18.9859 17.5421 18.6415 21.2739 18.6415C28.0512 18.6415 33.0752 20.3263 36.3494 23.6994C39.6237 27.0692 41.2591 31.8097 41.2591 37.9178V64.9701H30.9216ZM31.1807 45.8694C29.8582 45.3494 28.2935 44.9172 26.4864 44.5728C24.676 44.2284 22.768 44.0528 20.7591 44.0528C17.4849 44.0528 14.9308 44.6876 13.0935 45.9538C11.2561 47.2234 10.3375 48.9521 10.3375 51.1401V51.3123C10.3375 53.5036 11.1855 55.175 12.8781 56.3263C14.5707 57.4811 16.6537 58.0551 19.1237 58.0551C20.8466 58.0551 22.4416 57.8255 23.9054 57.3629C25.3692 56.9037 26.648 56.2554 27.7382 55.4181C28.8285 54.5841 29.6765 53.5745 30.2789 52.3927C30.8812 51.211 31.1841 49.9009 31.1841 48.4591V45.8694H31.1807Z" fill={color} />
        <path d="M48.043 2.05286H58.5521V64.9701H48.043V2.05286Z" fill={color} />
        <path d="M68.2334 27.5419H62.3379V18.6246H68.2334V15.2718C68.2334 10.0957 69.5054 6.23972 72.0494 3.70737C74.5328 1.23578 78.0426 0 82.5753 0C84.453 0 86.0649 0.118173 87.4143 0.354526C88.7637 0.590879 90.0222 0.884628 91.1967 1.23578V10.2409C90.14 9.88632 89.1305 9.60945 88.1614 9.40348C87.1956 9.19752 86.1221 9.09285 84.9511 9.09285C80.8457 9.09285 78.793 11.4462 78.793 16.1564V18.7158H91.1092V27.5419H78.968V64.9702H68.2334V27.5419Z" fill={color} />
        <path d="M183.238 66.0001C180.573 66.0001 177.992 65.4868 175.498 64.4638C173.001 63.4407 170.791 61.9314 168.862 59.9393C166.934 57.9472 165.373 55.4992 164.185 52.5988C162.994 49.695 162.398 46.3692 162.398 42.6112V42.439C162.398 38.6843 162.977 35.3552 164.138 32.4514C165.295 29.5476 166.84 27.103 168.765 25.1109C170.69 23.1188 172.911 21.6129 175.428 20.5864C177.945 19.5634 180.532 19.0501 183.194 19.0501C186.872 19.0501 189.928 19.847 192.361 21.4407C194.794 23.0344 196.829 24.8847 198.472 26.9882V2.06982H208.849V65.0546H198.472L198.556 57.542C196.91 59.8751 194.854 61.8672 192.388 63.5183C189.918 65.1762 186.869 66.0001 183.238 66.0001ZM185.664 57.0389C187.36 57.0389 188.999 56.6979 190.581 56.0158C192.162 55.3338 193.549 54.3512 194.733 53.0715C195.921 51.7918 196.866 50.269 197.573 48.5031C198.28 46.7406 198.633 44.7755 198.633 42.6146V42.4424C198.633 40.2814 198.28 38.3028 197.573 36.5099C196.866 34.717 195.921 33.1942 194.733 31.9415C193.545 30.6889 192.162 29.7232 190.581 29.0377C188.999 28.3557 187.36 28.0147 185.664 28.0147C183.911 28.0147 182.259 28.3422 180.704 28.9972C179.149 29.6523 177.78 30.6044 176.592 31.8571C175.404 33.1098 174.472 34.6326 173.796 36.4221C173.119 38.215 172.779 40.2206 172.779 42.439V42.6112C172.779 44.7755 173.119 46.7508 173.796 48.5437C174.472 50.3366 175.407 51.8594 176.592 53.112C177.78 54.3647 179.149 55.3304 180.704 56.0124C182.259 56.6945 183.911 57.0389 185.664 57.0389Z" fill={color} />
        <path d="M105.273 20.8903C102.278 22.5616 100.703 25.3371 99.2358 28.8689V18.7158H88.5012V64.9735H99.2358V47.2302C99.2358 44.1711 99.616 41.48 100.38 39.1536C101.14 36.8306 102.227 34.9027 103.634 33.3731C105.041 31.8436 106.713 30.6956 108.648 29.9291C110.583 29.166 112.723 28.7811 115.072 28.7811H115.015V18.834C110.909 18.6517 108.301 19.202 105.273 20.8903Z" fill={color} />
        <path d="M135.097 36.4457C132.21 37.6443 130.841 40.9634 132.035 43.8604C133.23 46.7574 136.538 48.1317 139.425 46.933C140.956 46.2982 142.056 45.0591 142.578 43.6106L157.623 37.6815C157.334 36.7158 157.075 35.8278 156.738 34.9702C152.091 23.1221 141.454 15.4373 128.404 20.5898C116.674 25.2223 112.151 38.1677 116.768 49.9381L116.828 50.0934C121.812 62.7991 134.37 67.8334 146.256 63.1401C150.223 61.5734 153.255 59.4023 155.54 56.7923C156.647 55.5262 157.529 54.1215 157.589 54.0236C158.848 52.018 159.578 50.1609 160.012 48.7968C157.532 47.8852 155.052 46.9735 152.572 46.0619C150.563 50.5459 147.972 53.5509 143.389 55.3573C138.247 57.3866 133.008 56.3365 129.313 52.1227C129.02 51.8357 128.71 51.4643 128.387 50.9747C128.36 50.9308 128.337 50.8903 128.307 50.8497C128.021 50.4446 127.509 49.5667 127.509 49.5667C124.608 44.6336 124.561 41.0985 124.561 41.0985C123.892 35.3146 126.267 30.3444 131.359 28.3354C137.574 25.8807 143.012 29.2268 146.017 35.0613L140.683 37.1682C139.125 35.9966 137.019 35.6488 135.097 36.4457Z" fill={color} />
        <path d="M205.679 57.9774L208.779 54.8676C213.739 59.6656 219.406 61.7995 225.338 61.7995C231.096 61.7995 237.028 58.2441 237.028 52.8249C237.028 49.3606 235.08 46.781 227.199 43.8502C218.608 40.6527 214.536 37.6307 214.536 31.678C214.536 24.4793 221.088 18.5266 231.274 18.5266C237.651 18.5266 243.762 21.3696 247.568 24.5705L244.734 27.8592C241.282 25.0162 236.143 22.5277 230.83 22.5277C223.215 22.5277 219.14 26.7044 219.14 30.9689C219.14 34.4332 220.91 36.5671 229.322 39.8558C238.354 43.4113 241.632 46.6966 241.632 52.1192C241.632 60.0269 233.926 65.804 224.806 65.804C217.811 65.7973 210.815 63.1332 205.679 57.9774Z" fill={color} />
        <path d="M255.018 18.4894H259.356L257.141 27.9436C261.57 22.5243 266.086 18.4354 273.348 18.4354C281.494 18.4354 285.569 24.1214 286.542 29.544C290.617 22.7911 296.99 18.4354 305.494 18.4354C313.641 18.4354 319.573 24.3882 319.573 32.8294C319.573 34.7843 318.954 37.0938 318.423 39.2278L311.692 64.8181H307.352L314.26 38.8732C314.704 37.185 314.97 34.9633 314.97 33.275C314.97 26.6099 310.807 22.3454 304.256 22.3454C296.994 22.3454 288.847 29.544 286.279 39.2278L279.549 64.8181H275.212L282.12 38.8732C282.564 37.185 282.83 34.9633 282.83 33.275C282.83 26.6099 278.668 22.3454 272.116 22.3454C264.854 22.3454 256.707 29.544 254.14 39.2278L247.322 64.8181H242.985L255.018 18.4894Z" fill={color} />
        <path d="M318.352 52.8418C318.352 42.8001 326.852 36.4051 338.721 36.4051C345.185 36.4051 351.562 37.7388 356.078 38.9813L356.431 37.7388C356.784 36.4051 357.05 34.4501 357.05 33.2076C357.05 26.3668 352.268 22.5446 343.412 22.5446C338.451 22.5446 334.646 23.4327 330.305 24.9453L329.507 21.0354C333.936 19.4349 338.186 18.4591 343.765 18.4591C354.836 18.4591 361.3 23.8784 361.3 32.5863C361.3 34.2745 360.947 36.4962 360.415 38.539L353.507 64.4839H349.257L351.293 56.6639C346.952 61.8165 341.019 65.5508 333.138 65.5508C325.614 65.5475 318.352 61.283 318.352 52.8418ZM354.129 46.4433L355.193 42.4456C350.589 41.2908 344.744 40.3117 338.101 40.3117C329.07 40.3117 322.959 45.2886 322.959 52.3083C322.959 57.9065 327.209 61.5497 333.939 61.5497C342.439 61.5497 351.649 55.597 354.129 46.4433Z" fill={color} />
        <path d="M369.959 18.314H374.347L370.945 31.6781C376.945 23.2335 385.182 18.1114 394.853 18.6516L393.689 22.4265L393.238 22.6088C382.672 22.6088 371.039 31.1412 367.186 45.6972L362.172 64.4738H357.784L369.959 18.314Z" fill={color} />
        <path d="M385.448 56.2081C385.448 54.7697 385.717 52.9734 385.986 51.8052L393.777 22.6932H387.151L388.073 19.2255L394.853 18.6482L398.794 4.00439H403.182L399.241 18.6482H415L413.927 22.6898H398.168L390.378 51.8896C390.109 53.0578 389.93 54.6752 389.93 55.7523C389.93 59.5272 392.797 61.2323 397.364 61.2323C399.332 61.2323 401.392 60.7832 403.99 59.7939L402.825 64.1057C400.409 65.0038 398.706 65.2739 396.378 65.2739C390.284 65.2807 385.448 62.4985 385.448 56.2081Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_816_6901">
          <rect width="415" height="66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoAlfred;