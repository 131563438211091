import { PayloadIntercomModel } from '../model/intercomPayloadModel';
import { payloadToIntercom } from '../mappers/payloadToIntercom';
import { IntercomsEntityListValueObject } from '../entities/intercomsEntityListValueObject';
import { IntercomCreateModel, IntercomUpdateModel } from '../model/intercomModel';
import { intercomToCreatePayload } from '../mappers/intercomToCreatePayload';
import { getIntercomsListByProjectIdPath, createIntercomPath, updateIntercomPath, deleteIntercomPath } from 'core/endspoints/intercoms';
import { intercomToUpdatePayload } from '../mappers/intercomToUpdatePayload';
import api from 'services/api';

export const GetIntercomsListByProjectId = async (projectId: string) => {
  const url = getIntercomsListByProjectIdPath(projectId);
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    const { data } = await api.get<PayloadIntercomModel[]>(url);
    const mappedData = data.map((item) => payloadToIntercom(item));
    const { list } = IntercomsEntityListValueObject(mappedData);
    return list;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const CreateIntercom = async (intercom: IntercomCreateModel): Promise<void> => {
  const url = createIntercomPath();
  api.defaults.headers.common['Accept-version'] = '1';
  const data = intercomToCreatePayload(intercom);

  try {
    await api.post(url, { data });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const UpdateIntercom = async ({ id, ...intercom }: IntercomUpdateModel): Promise<void> => {
  const url = updateIntercomPath(id);
  api.defaults.headers.common['Accept-version'] = '1';
  const data = intercomToUpdatePayload({ id, ...intercom });

  try {
    await api.patch(url, { data });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const DeleteIntercom = async (intercomId: string) => {
  const url = deleteIntercomPath(intercomId);
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    await api.delete(url);
  } catch (error: any) {
    throw new Error(error);
  }
};
