import { RouteConfig } from 'react-router-config';
import AdminPage from 'components/pages/AdminPage';
import { projectsRoutes } from '../projects';
import { supportTicketsRoutes } from '../supportTickets';
import { usersRoutes } from '../users';
import { clientsRoutes } from '../settings';
import { assetsRoutes } from '../assets';
import { provisionedRoutes } from '../provisioned';
import { checkInRoutes } from '../checkIn';
import { academyRoutes } from '../academy';
import { digitalSignageRoutes } from '../digitalSignage';
import { dashboardRoutes } from '../dashboard';
import { superSearchRoutes } from '../superSearch';
import { ticketsRoutes } from '../tickets';
import { commonAreasRoutes } from '../commonAreas';
import { ROUTE_PATH_ADMIN } from '../../configRoutes';
import { accessesRoutes } from '../access';
import { gatewaysRoutes } from '../gateways';

export const adminRoutes: RouteConfig = {
  path: ROUTE_PATH_ADMIN,
  component: AdminPage,
  routes: [
    ...academyRoutes,
    ...assetsRoutes,
    ...checkInRoutes,
    ...clientsRoutes,
    ...dashboardRoutes,
    ...digitalSignageRoutes,
    ...projectsRoutes,
    ...provisionedRoutes,
    ...superSearchRoutes,
    ...supportTicketsRoutes,
    ...ticketsRoutes,
    ...usersRoutes,
    ...gatewaysRoutes,
    accessesRoutes,
    commonAreasRoutes,
  ],
};
