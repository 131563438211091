import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Input, ModalProps } from 'antd';
import AsSupportTicketStatusSelect from 'components/molecules/AsSupportTicketStatusSelect';
import { SupportTicketStatusType } from 'core/domain/supportTickets/models/supportTicketsPayloadModel';

interface Values {
  status: SupportTicketStatusType;
  description: string;
}

interface ModalStatusProps extends ModalProps {
  status: SupportTicketStatusType;
  loading?: boolean;
  onFinish: (values: Values) => Promise<void>;
}

const FORM_ID = 'form_status';

const ModalStatus: FC<ModalStatusProps> = ({ status, loading, onFinish, visible, onCancel, ...props }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleOnFinish = async (values: Values) => {
    await onFinish(values);
    values.status !== undefined && form.resetFields();
  };

  return (
    <Modal
      {...props}
      title={t('change_and_comment_status')}
      visible={visible}
      onCancel={(events) => {
        form.resetFields();
        onCancel && onCancel(events);
      }}
      footer={[
        <Button
          key='cancel'
          onClick={(events) => {
            form.resetFields();
            onCancel && onCancel(events);
          }}
        >
          {t('cancel')}
        </Button>,
        <Button key='submit' type='primary' htmlType='submit' loading={loading} form={FORM_ID}>
          {t('update')}
        </Button>,
      ]}
      destroyOnClose
    >
      <Form form={form} id={FORM_ID} layout='vertical' onFinish={handleOnFinish}>
        <Form.Item name='status' label={`${t('status')}:`}>
          <AsSupportTicketStatusSelect disabled={!status} loading={loading} status={status} />
        </Form.Item>
        <Form.Item name='description' label={`${t('additional_info')}:`}>
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalStatus;
