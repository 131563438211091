import { ThirdPartyServicesByProject } from '../model/thirdPartyServicesByProject';
import { ThirdPartyServicesByProjectEntity } from './thirdPartyServicesByProjectEntity';

export const ThirdPartyServicesByProjectListValueObject = (list: ThirdPartyServicesByProject[]): Readonly<{
  list: Readonly<ThirdPartyServicesByProject>[];
}> => {
  return Object.freeze({
    list: list.map(item => ThirdPartyServicesByProjectEntity(item)),
  });
};
