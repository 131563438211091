import { useEffect, useState } from 'react';
import { UsersListUnstructuredStatisticsModel } from 'core/domain/users/models/usersModel';
import { MonthName, getMonthAbbreviationTranslations } from './utils';
import { GetUsersListUnstructuredStatistics } from 'core/domain/users/repositories/getUsersListUnstructuredStatistics';

const initialStatistics: UsersListUnstructuredStatisticsModel = {
  average: 0,
  labels: [],
  values: [],
};

export const useUsersStatistics = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<UsersListUnstructuredStatisticsModel>(initialStatistics);

  const getLabelsTranslations = (labels: string[]) => {
    return labels.map((label) => {
      const labelToLowerCase = label.toLowerCase() as MonthName;
      const labelTranslation = getMonthAbbreviationTranslations(labelToLowerCase);
      return labelTranslation;
    });
  };

  const getUsersListStatistics = async () => {
    setIsLoading(true);
    try {
      const usersListStatistics = await GetUsersListUnstructuredStatistics();
      const labels = usersListStatistics ? getLabelsTranslations(usersListStatistics.labels) : initialStatistics.labels;
      usersListStatistics && setStatistics({ ...usersListStatistics, labels });
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsersListStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    usersStatistics: statistics,
    usersError: isError,
    usersLoading: isLoading,
  };
};
