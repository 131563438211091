export enum EventsAndNewsFormValues {
  TITLE = 'title',
  DESCRIPTION = 'description',
  IMAGE = 'image',
  LINK = 'link',
  LINK_TITLE = 'linkTitle',
  SEND_NOTIFICATION = 'sendNotification',
}

export enum CharactersMaxLength {
  TITLE = 50,
  DESCRIPTION = 600,
  LINK_TITLE = 20,
}
