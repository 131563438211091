import { FC } from 'react';
import { Svg } from '../model/svg';

const EmptyAccess: FC<Svg> = ({ width = '96', height = '96', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      opacity={'0.15'}
      d={'M46.8903 0.499998C56.1166 0.499998 64.9298 2.30392 72.5 7.1C78.6626 11.0043 84.0014 17.2345 88.0686 23.3C94.7071 33.2 96.5 40.5273 96.5 50.3C96.5 63.7175 93.5 75.5 84.5 84.5C76.4049 92.5546 63.9085 96.5 48.5 96.5C33.7614 96.5 19.822 90.002 11.3 80.3C5.50269 73.7 0.499998 59.9545 0.499998 47.3C0.499997 34.8385 1.74987 21.9513 8.9 13.7C20.3383 0.499992 31.7874 0.499999 46.8903 0.499998Z'}
      fill={'#96C0C4'}
    />
    <path
      d={'M0.999989 50.1097C0.99999 40.9462 2.79175 32.2344 7.52237 24.7676C11.3739 18.6882 17.5404 13.3955 23.5785 9.34667C33.3927 2.76566 40.6224 0.999995 50.3 0.999996C63.6456 0.999997 75.2754 3.98292 84.1458 12.8529C92.0729 20.8199 96 33.1658 96 48.5C96 63.1001 89.5599 76.9007 79.97 85.3243C76.7357 88.1653 71.7151 90.838 65.9103 92.8003C60.111 94.7608 53.5652 96 47.3 96C34.8287 96 22.123 94.7372 14.0274 87.7221C7.4989 82.0649 4.25588 76.4282 2.63307 70.3501C1.00315 64.2454 0.999988 57.6766 0.999989 50.1097Z'}
      stroke={'#96C0C4'}
    />
    <g clip-path={'url(#clip0_11012_13970)'}>
      <path
        d={'M60.3312 66.1633L58.3994 65.6457L65.6463 38.5998C65.931 37.5373 65.2946 36.435 64.2321 36.1503L56.5047 34.0797C56.7894 33.0172 56.153 31.9149 55.0905 31.6302L41.5675 28.0068C40.505 27.7221 39.4027 28.3585 39.118 29.421L31.3535 58.3988L29.4216 57.8811C28.3591 57.5964 27.2568 58.2328 26.9721 59.2953C26.6874 60.3579 27.3238 61.4601 28.3863 61.7448L45.773 66.4036C46.8355 66.6883 47.9378 66.0519 48.2225 64.9894L55.4694 37.9434L61.265 39.4963L54.018 66.5423C53.7333 67.6048 54.3697 68.7071 55.4323 68.9918L59.296 70.027C60.3585 70.3117 61.4608 69.6753 61.7455 68.6128C62.0302 67.5503 61.3938 66.448 60.3312 66.1633ZM46.0504 49.9135C44.9879 49.6288 44.3515 48.5265 44.6362 47.464C44.9209 46.4015 46.0232 45.7651 47.0857 46.0498C48.1482 46.3345 48.7846 47.4367 48.4999 48.4993C48.2152 49.5618 47.1129 50.1982 46.0504 49.9135Z'}
        fill={'#96C0C4'}
      />
    </g>
    <defs>
      <clipPath id={'clip0_11012_13970'}>
        <rect
          x={'31.5293'}
          y={'19.1055'}
          width={'48'}
          height={'48'}
          rx={'4'}
          transform={'rotate(15 31.5293 19.1055)'}
          fill={'white'}
        />
      </clipPath>
    </defs>
  </svg>
);



export default EmptyAccess;