import { updateClientPath } from 'core/endspoints/clients';
import api from 'services/api';
import { updateClientToPayload } from '../mappers/updateClientToPayload';
import { ClientUpdateModel } from '../models/updateClientEntityModel';

export const UpdateClient = async ({ id, ...client }: ClientUpdateModel): Promise<void> => {
  const url = updateClientPath(id);
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    const data = updateClientToPayload({ id, ...client });
    await api.patch(url, { data });
  } catch (error: any) {
    throw new Error(error);
  }
};
