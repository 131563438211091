import { FC } from 'react';
import './styles.scss';

export interface ModalProps {
  visible: boolean;
  onClose?: () => void;
  className?: string;
  loading?: boolean;
  children?: React.ReactNode;
}

const Modal: FC<ModalProps> = ({
  visible,
  onClose,
  className,
  children,
}) => {
  if (!visible) {
    return null;
  }

  const handleOnClick = (event: any) => {
    event.stopPropagation();
  }

  return (
    <div
      className={'Modal__container'}
      onClick={onClose}
    >
      <div className={'Modal__content'}>
        <div
          className={`Modal__body ${className}`}
          onClick={handleOnClick}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;