export const supportTicketsListPath = () => {
  return '/support-tickets';
};

export const supportTicketsStatisticsPath = () => {
  return `/tickets-support/statistics`;
};

export const assetSupportTicketsStatisticsPath = (assetId: string) => {
  return `/tickets-support/assets/${assetId}/statistics`;
};

export const downloadSupportTicketsListCsvFilePath = () => {
    return '/support-tickets/download';
}
