export const commonAreasListPath = (projectId: string) => {
  return `/projects/${projectId}/common-areas`;
};

export const commonAreaPath = (id: string) => {
  return `/projects/common-areas/${id}`;
};

export const commonAreasCreatePath = (projectId: string) => {
  return `/projects/${projectId}/common-areas`;
};

export const commonAreasUpdatedPath = (commonAreasId: string) => {
  return `/common-areas/${commonAreasId}`;
};

export const commonAreasRemovePath = (commonAreasId: string) => {
  return `/common-areas/${commonAreasId}`;
};
