import { FC } from 'react';
import { Modal, Button } from 'antd';
import { ModalProps } from 'antd/es/modal';
import ClientNewForm from 'components/organisms/ClientNewForm';
import { CreateClientModel } from 'core/domain/client/models/createClientEntityModel';

const FORM_ID = 'form_client';

interface Props extends ModalProps {
  title: string;
  mainButtonText: string;
  secondaryButtonText: string;
  onCreateClient: (values: CreateClientModel) => Promise<void>;
  loading: boolean;
}

const ClientNewModal: FC<Props> = ({
  title,
  mainButtonText,
  secondaryButtonText,
  onCreateClient,
  onCancel,
  loading,
  ...props
}) => {
  return (
    <Modal
      {...props}
      title={title}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button key={'back'} onClick={onCancel}>{secondaryButtonText}</Button>,
        <Button key={'submit'} type={'primary'} loading={loading} htmlType={'submit'} form={FORM_ID}>{mainButtonText}</Button>,
      ]}
    >
      <ClientNewForm id={FORM_ID} onFinish={onCreateClient} />
    </Modal>
  );
};

export default ClientNewModal;
