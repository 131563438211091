import { useContext } from 'react';
import UserProfileContext from 'context/UserProfileContext';
import { LocalStorageKeys } from 'constants/localStorage';

export const useUserSession = () => {
  const { token, setToken } = useContext(UserProfileContext);

  const checkIfUserIsLoggedIn = () => localStorage.getItem(LocalStorageKeys.TOKEN) != null;

  return {
    token,
    setToken,
    checkIfUserIsLoggedIn,
  };
};
