import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { GetClientById } from 'core/domain/client/repositories/getClientById';
import { UpdateClient } from 'core/domain/client/repositories/updateClient';
import { ClientUpdateModel } from 'core/domain/client/models/updateClientEntityModel';

interface UpdateClientModel {
  client: ClientUpdateModel;
  textMessage: string;
}

export const useClientCRUD = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [data, setData] = useState<ClientModel>({} as ClientModel);

  const getClientById = async (id: string): Promise<void> => {
    setIsLoading(true);
    try {
      const client = await GetClientById(id);
      client && setData(client);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateClient = async ({ client, textMessage }: UpdateClientModel) => {
    setIsUpdating(true);
    try {
      await UpdateClient(client);
      message.success(textMessage);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    getClientById(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return {
    data,
    getClientById,
    updateClient,
    loading: isLoading,
    updating: isUpdating,
  };
};
