import { useTranslation } from 'react-i18next';

export enum ClientEditTranslation {
  EDIT_BUTTON = 'edit',
}

export interface ClientMenuExtraTranslations {
  editButton: string;
}

export enum ClientEditModalTranslation {
  TITLE = '_CLIENT_EDIT_MODAL_TITLE',
  EDIT_BUTTON = 'edit',
  CANCEL_BUTTON = 'cancel',
}

export interface ClientEditModalTranslations {
  title: string;
  cancelButton: string;
  editButton: string;
}

export interface ClientEditFormTranslations {
  name: string;
  namePlaceholder: string;
  nameError: string;
  email: string;
  emailPlaceholder: string;
  emailError: string;
  emailErrorFormat: string;
  description: string;
  descriptionPlaceholder: string;
  fieldRequired: string;
  submitError: string;
  submitSuccess: string;
}

export enum ClientEditFormTranslation {
  NAME = '_CLIENT_EDIT_FORM_NAME',
  NAME_PLACEHOLDER = '_CLIENT_EDIT_FORM_NAME_PLACEHOLDER',
  NAME_ERROR = '_CLIENT_EDIT_FORM_NAME_ERROR',
  EMAIL = '_CLIENT_EDIT_FORM_EMAIL',
  EMAIL_PLACEHOLDER = '_CLIENT_EDIT_FORM_EMAIL_PLACEHOLDER',
  EMAIL_ERROR = '_CLIENT_EDIT_FORM_EMAIL_ERROR',
  EMAIL_ERROR_FORMAT = '_CLIENT_EDIT_FORM_EMAIL_ERROR_FORMAT',
  DESCRIPTION = '_CLIENT_EDIT_FORM_DESCRIPTION',
  DESCRIPTION_PLACEHOLDER = '_CLIENT_EDIT_FORM_DESCRIPTION_PLACEHOLDER',
  FIELD_REQUIRED = 'field_required',
  SUBMIT_ERROR = '_CLIENT_EDIT_FORM_SUBMIT_ERROR',
  SUBMIT_SUCCESS = '_CLIENT_EDIT_FORM_SUBMIT_SUCCESS',
}

enum CustomizationTranslation {
  PUBLISHED = '_CUSTOMIZATION_PUBLISHED',
}

interface CustomizationTranslations {
  published: string;
}

export interface ClientTranslations {
  menuExtraTranslations: ClientMenuExtraTranslations;
  editModalTranslations: ClientEditModalTranslations;
  editFormTranslations: ClientEditFormTranslations;
  customizationTranslations: CustomizationTranslations;
}

export const useClientTranslations = () => {
  const { t } = useTranslation();

  const getClientTranslations = (): ClientTranslations => {
    return {
      menuExtraTranslations: {
        editButton: t(ClientEditTranslation.EDIT_BUTTON),
      },
      editModalTranslations: {
        title: t(ClientEditModalTranslation.TITLE),
        cancelButton: t(ClientEditModalTranslation.CANCEL_BUTTON),
        editButton: t(ClientEditModalTranslation.EDIT_BUTTON),
      },
      editFormTranslations: {
        name: t(ClientEditFormTranslation.NAME),
        namePlaceholder: t(ClientEditFormTranslation.NAME_PLACEHOLDER),
        nameError: t(ClientEditFormTranslation.NAME_ERROR),
        email: t(ClientEditFormTranslation.EMAIL),
        emailPlaceholder: t(ClientEditFormTranslation.EMAIL_PLACEHOLDER),
        emailError: t(ClientEditFormTranslation.EMAIL_ERROR),
        emailErrorFormat: t(ClientEditFormTranslation.EMAIL_ERROR_FORMAT),
        description: t(ClientEditFormTranslation.DESCRIPTION),
        descriptionPlaceholder: t(ClientEditFormTranslation.DESCRIPTION_PLACEHOLDER),
        fieldRequired: t(ClientEditFormTranslation.FIELD_REQUIRED),
        submitError: t(ClientEditFormTranslation.SUBMIT_ERROR),
        submitSuccess: t(ClientEditFormTranslation.SUBMIT_SUCCESS),
      },
      customizationTranslations: {
        published: t(CustomizationTranslation.PUBLISHED),
      },
    };
  };

  return { getClientTranslations };
};
