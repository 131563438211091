import { FC } from 'react';
import { MenuItemProps } from 'components/atoms/MenuItem';
import Menu from '../Menu';

interface SideMenuProps {
  items: MenuItemProps[];
  activeItem: string;
  onClick: (key: string) => void;
}

const SideMenu: FC<SideMenuProps> = ({ items, activeItem, onClick }) => {
  return (
    <Menu
      activeKey={activeItem}
      onClick={onClick}
      mode={'vertical'}
    >
      {items.map(({ key, icon, children }) => (
        <Menu.Item
          key={key}
          icon={icon}
          className={'Menu__Item'}
        >
          {children}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default SideMenu;
