import { FC } from 'react';
import { Divider } from 'antd';
import { CustomGatewayDataModel, GatewayStatusInformationTranslations } from '../../TabGateway/resources/utils';
import GatewayInformationBlock from './GatewayInformationBlock';
import GatewayStatusBlock from './GatewayStatusBlock';
import { GatewayUpdateModel } from 'core/domain/gateways/model';

interface Props {
  gateway: CustomGatewayDataModel;
  informationTranslations: GatewayStatusInformationTranslations;
  onUnassignGateway: () => void;
  onUpdateGatewayModule: ({ gateway, module }: GatewayUpdateModel) => void;
  hasPermissionToEditGatewayLink: boolean;
  isGatewayLinked: boolean;
}

const GatewayStatus: FC<Props> = ({
  gateway,
  informationTranslations,
  onUnassignGateway,
  onUpdateGatewayModule,
  hasPermissionToEditGatewayLink,
  isGatewayLinked,
}) => {
  return (
    <>
      <GatewayInformationBlock
        gateway={gateway}
        translations={informationTranslations}
        onUnassignGateway={onUnassignGateway}
        onUpdateGatewayModule={onUpdateGatewayModule}
        hasPermissionToEditGatewayLink={hasPermissionToEditGatewayLink}
        isGatewayLinked={isGatewayLinked}
      />
      <Divider />
      <GatewayStatusBlock
        checkValues={gateway.checkedValues}
        percentValues={gateway.percentValues}
      />
    </>
  );
};

export default GatewayStatus;
