import { ProjectIntercomRoomModel } from '../model/projectModel';
import { PayloadProjectIntercomRoomModel } from '../model/projectPayloadModel';

export const payloadToRoom = ({
  id,
  identifier,
  password,
  comments,
  asset_id,
  intercom_id,
}: PayloadProjectIntercomRoomModel): ProjectIntercomRoomModel => {
  return {
    id: id ?? '',
    identifier: identifier ?? '',
    password: password ?? '',
    comments: comments ?? '',
    assetId: asset_id ?? '',
    intercomId: intercom_id ?? '',
  };
};
