import { RegisterAssetModel, RegisterAssetPayload } from '../models';

export const createAssetToPayload = ({
  id,
  clientId,
  community,
  alias,
  type,
  streetType,
  streetName,
  blockNumber,
  staircase,
  flat,
  door,
  city,
  postalCode,
  province,
  country,
  description,
  gatewayId,
  qrGatewayId,
}: RegisterAssetModel): RegisterAssetPayload => {
  const payload: RegisterAssetPayload = {
    id,
    attributes: {
      community,
      alias: alias ?? undefined,
      client_id: clientId,
      type,
      description,
      location: {
        country,
        province,
        city,
        postal_code: postalCode,
        street_type: streetType,
        street_name: streetName,
        block_number: blockNumber,
        staircase: staircase ?? null,
        flat: flat ?? null,
        door: door ?? null,
      },
    },
  };

  const relationship = {
    gateways: {
      data: {
        gateways_id: gatewayId ?? undefined,
        qr_gateway_id: qrGatewayId ?? undefined,
      },
    },
  };

  const filteredPayload: RegisterAssetPayload = !!qrGatewayId ? { ...payload, relationship } : { ...payload };

  return { ...filteredPayload };
};
