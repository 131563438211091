import { ROUTE_PATH_ADMIN } from '../../configRoutes';

export const ROUTE_PATH_ASSETS = ROUTE_PATH_ADMIN + '/assets';
export const ROUTE_PATH_ASSET = ROUTE_PATH_ADMIN + '/assets/:assetId/:tab';
export const ROUTE_PATH_ASSET_QR_TOOL = ROUTE_PATH_ADMIN + '/assets/qr-generator-tool';

export const getAssetPathById = (assetId: string) => `${ROUTE_PATH_ADMIN}/assets/${assetId}/start`;

export const getAccessesAssetPathById = (assetId: string) => `${ROUTE_PATH_ADMIN}/assets/${assetId}/accesses`;

export const getAssetsWithPaginationPath = ({ page, size }: { page: string; size: string }) =>
  `${ROUTE_PATH_ADMIN}/assets?page=${page}&size=${size}`;
