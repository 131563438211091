import { FC } from 'react';
import { useGatewaysList } from './resources/useGatewaysList';
import GatewaysListTemplate from 'components/template/GatewaysListTemplate';
import AssignGatewayToAssetModal from 'components/organisms/AssignGatewayToAssetModal';
import UnAssignGatewayToAssetModal from 'components/organisms/UnassignGatewayToAssetModal';
import AssignGatewayToProjectModal from 'components/organisms/AssignGatewayToProjectModal';
import UnAssignGatewayToProjectModal from 'components/organisms/UnAssignGatewayToProject.ts';
import DeleteGatewayModal from 'components/organisms/DeleteGatewayModal';

const GatewaysListPage: FC = () => {
  const {
    title,
    gatewaysOptions,
    clientsAssetsOptions,
    clientsProjectOptions,
    projectsAssetsOptions,
    projectsProjectsOptions,
    assetsOptions,
    assignToAssetModalOptions,
    assignToProjectModalOptions,
    unAssigningToAssetModalOptions,
    unAssigningToProjectModalOptions,
    deleteGatewayOptions,
    assigningToAsset,
    assigningToProject,
  } = useGatewaysList();

  return (
    <>
      <GatewaysListTemplate title={title} gatewaysOptions={gatewaysOptions} />
      <AssignGatewayToAssetModal
        clientsOptions={clientsAssetsOptions}
        projectsOptions={projectsAssetsOptions}
        assetsOptions={assetsOptions}
        modalOptions={assignToAssetModalOptions}
        assigning={assigningToAsset}
        open={assignToAssetModalOptions.isAssignAssetModalVisible}
        onCancel={assignToAssetModalOptions.onCloseAssignGatewayToAssetModal}
      />
      <UnAssignGatewayToAssetModal
        modalOptions={unAssigningToAssetModalOptions}
        open={unAssigningToAssetModalOptions.unAssignToAssetModalVisible}
        onCancel={unAssigningToAssetModalOptions.onCloseUnAssignToAssetModal}
      />
      <AssignGatewayToProjectModal
        clientsOptions={clientsProjectOptions}
        modalOptions={assignToProjectModalOptions}
        assigning={assigningToProject}
        projectsOptions={projectsProjectsOptions}
        open={assignToProjectModalOptions.isAssignProjectModalVisible}
        onCancel={assignToProjectModalOptions.onCloseAssignGatewayToProjectModal}
      />
      <UnAssignGatewayToProjectModal
        modalOptions={unAssigningToProjectModalOptions}
        open={unAssigningToProjectModalOptions.unAssignToProjectModalVisible}
        onCancel={unAssigningToProjectModalOptions.onCloseUnAssignToProjectModal}
      />
      <DeleteGatewayModal
        options={deleteGatewayOptions}
        onCancel={deleteGatewayOptions.onCloseDeleteGatewayModal}
        open={deleteGatewayOptions.visible}
      />
    </>
  );
}

export default GatewaysListPage;