import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getGatewayStatusGID } from 'services/api/gateways';
import { ColumnSearchTranslationsType, DeviceDataIndex } from 'components/organisms/PeripheralDevices/SearchPeripheralColumns';
import { DeviceModel } from 'core/domain/gateways/model';
import { GetAssetDevices } from 'core/domain/assets/repositories/getAssetDevices';
import { useEnvironment } from './useEnvironment';
import { useUserSession } from './useUserSession';
import { useMessage } from './useMessage';

export const useAssetDevices = (assetId: string) => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [searchedColumn, setSearchedColumn] = useState<string>('');

  const isMounted = useRef(false);
  const searchInput = useRef<any>(null);

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
    notReportedText: t('not_reported'),
  };

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const getDevices = useCallback(async () => {
    setIsLoading(true);
    try {
      const { gatewayId } = await getGatewayStatusGID(assetId);
      if (gatewayId) {
        const response = await GetAssetDevices({ host, token, assetId });
        const devices = response.gateways.flatMap(({ devices }) => devices);
        isMounted.current && setDevices(devices);
      }
    } catch (error) {
      setMessageError({ description: t('_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    isMounted.current = true;
    getDevices().finally(() => {
      if (isMounted.current) {
        setIsLoading(false);
      }
    });

    return () => {
      isMounted.current = false;
    };
  }, [assetId, getDevices]);

  return {
    devices,
    loading: isLoading,
    searchedColumn,
    searchInput,
    columnSearchTranslations,
    getDevices,
    onSearch,
    onReset,
  };
};
