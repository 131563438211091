import { FC } from 'react';
import EventsAndNewsContentTemplate from 'components/template/EventsAndNewsContentTemplate';
import EventsAndNewsFormCreateTemplate from 'components/template/EventsAndNewsFormCreateTemplate';
import EventsAndNewsContentEmptyTemplate from 'components/template/EventsAndNewsContentEmptyTemplate';
import EventsAndNewsFormUpdateTemplate from 'components/template/EventsAndNewsFormUpdateTemplate';
import { CreateFormValues, UpdateFormValues } from 'components/molecules/EventsAndNewsForm/models';
import { EventsAndNewsVisorViews } from '../../pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabEventsAndNews/enums/visorViews';
import { EventsAndNewsModel } from 'core/domain/eventsAndNews/models/eventsAndNewsEntityModels';

interface EventsAndNewsVisorManagerProps {
  eventsAndNewsData: EventsAndNewsModel;
  visibleType: EventsAndNewsVisorViews;
  onCreateEventsAndNews: (values: CreateFormValues) => void;
  onInvisibleCreate: () => void;
  onInvisibleUpdate: () => void;
  onRemoveEventsAndNews: (id: string) => void;
  onUpdateEventsAndNews: (id: string, values: UpdateFormValues) => void;
  onVisibleCreate: () => void;
  onVisibleUpdate: () => void;
  isSaving: boolean;
}

const visorContentManager = {
  [EventsAndNewsVisorViews.EMPTY]: EventsAndNewsContentEmptyTemplate,
  [EventsAndNewsVisorViews.UPDATE]: EventsAndNewsFormUpdateTemplate,
  [EventsAndNewsVisorViews.CREATE]: EventsAndNewsFormCreateTemplate,
  [EventsAndNewsVisorViews.CONTENT]: EventsAndNewsContentTemplate,
};

const EventsAndNewsVisorManager: FC<EventsAndNewsVisorManagerProps> = ({
  visibleType, ...props
}) => {
  const Component = visorContentManager[visibleType];

  return <Component {...props} />;
};

export default EventsAndNewsVisorManager;
