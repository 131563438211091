import { ProjectListResponseDataModel, ProjectUsersVerifiersModel, ProjectsListResponseModel } from '../model/projectModel';
import {
  PayloadUsersVerifiersModel,
  ProjectListResponseDataPayloadModel,
  ProjectsListResponsePayloadModel,
} from '../model/projectPayloadModel';

const transformAllowedUsersToVerifyAccounts = (
  allowed_users_to_verify_accounts: PayloadUsersVerifiersModel[]
): ProjectUsersVerifiersModel[] => {
  return (
    allowed_users_to_verify_accounts.map(({ id, email }) => {
      return { id: id ?? '', value: email ?? '' };
    }) ?? []
  );
};

const transformUsersVerifiers = (users_verifiers: PayloadUsersVerifiersModel[]): ProjectUsersVerifiersModel[] => {
  return (
    users_verifiers.map(({ id, email }) => {
      return { id: id ?? '', value: email ?? '' };
    }) ?? []
  );
};

const payloadToProjectsListResponseData = ({
  id,
  name,
  logo,
  background_image,
  is_background_image_default,
  support_email,
  client,
  users_require_verification,
  users_verifiers,
  allowed_users_to_verify_accounts,
  type,
  status,
  available_intercom_vendors,
  latitude,
  longitude,
}: ProjectListResponseDataPayloadModel): ProjectListResponseDataModel => {
  return {
    id: id ?? '',
    name: name ?? '',
    logo: logo ?? '',
    backgroundImage: background_image ?? '',
    isBackgroundImageDefault: is_background_image_default ?? false,
    supportEmail: support_email ?? [],
    latitude: latitude ?? '',
    longitude: longitude ?? '',
    client: {
      id: client ? client.id : '',
      name: client ? client.name : '',
    },
    usersRequireVerification: users_require_verification,
    usersVerifiers: !!users_verifiers ? transformUsersVerifiers(users_verifiers) : [],
    allowedUsersToVerifyAccounts: !!allowed_users_to_verify_accounts
      ? transformAllowedUsersToVerifyAccounts(allowed_users_to_verify_accounts)
      : [],
    type: type ?? 'HOME',
    status: status ?? 'ACCEPTED',
    availableIntercomVendors: available_intercom_vendors ?? [],
  };
};

export const payloadToProjectsListResponse = ({ data, links, meta }: ProjectsListResponsePayloadModel): ProjectsListResponseModel => {
  const transformedProjects = data.map((item) => payloadToProjectsListResponseData(item)) ?? [];

  return {
    data: transformedProjects,
    meta: {
      totalPages: meta.total_pages ?? 1,
      allResults: meta.all_results ?? 1,
      pageSize: meta.page_size ?? 1,
      results: meta.results ?? 1,
    },
    links: {
      first: links.first ?? '',
      last: links.last ?? '',
      next: links.next ?? '',
      prev: links.prev ?? '',
      self: links.self ?? '',
    },
  };
};
