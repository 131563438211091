import { FC } from 'react';
import { Button, Modal } from 'antd';
import { ModalProps } from 'antd/es/modal';

interface Props extends ModalProps {
  title: string;
  text: string;
  mainButtonText: string;
  secondaryButtonText: string;
  onClick: () => void;
  loading: boolean;
}

const LegalDocumentationDisableModal: FC<Props> = ({ title, text, mainButtonText, secondaryButtonText, onCancel, onClick, loading, ...props }) => {
  return (
    <Modal
      {...props}
      title={title}
      onCancel={onCancel}
      footer={[
        <Button key='back' onClick={onCancel}>{secondaryButtonText}</Button>,
        <Button key='continue' type='primary' onClick={onClick} loading={loading}>{mainButtonText}</Button>,
      ]}
    >
      <p>{text}</p>
    </Modal>
  );
}

export default LegalDocumentationDisableModal;