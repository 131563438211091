import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { LogHistoryResponseModel, LogHistoryResponsePayload } from '../models';
import { payloadToLogList } from '../mappers';

const getUrl = ({ host, projectId, pageNumber, pageSize }: { host: string; projectId: string; pageNumber: number; pageSize: number }) => {
  const params = new URLSearchParams();
  pageSize && params.append('page[size]', String(pageSize));
  pageNumber && params.append('page[number]', String(pageNumber));

  return `${host}/data/projects/time-series/${projectId}/access-control?${params}`;
};

export const GetAccessesLogListByProjectId = async ({
  projectId,
  token,
  host,
  pageNumber,
  pageSize,
}: {
  projectId: string;
  token: string;
  host: string;
  pageNumber: number;
  pageSize: number;
}): Promise<LogHistoryResponseModel> => {
  try {
    const url = getUrl({ host, projectId, pageNumber, pageSize });
    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '2',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<LogHistoryResponsePayload>;
    return payloadToLogList(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
