import { useState } from 'react';
import { CreateClientModel } from 'core/domain/client/models/createClientEntityModel';
import { CreateClient } from 'core/domain/client/repositories/createClient';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import { useTranslation } from 'react-i18next';

export interface CreateClientLiteralsModel {
  title: string;
  mainButtonText: string;
  secondaryButtonText: string;
}

export interface CreateClientOptionsModel {
  createClientLiterals: CreateClientLiteralsModel;
  onOpenCreateClientModal: () => void;
  onCloseCreateClientModal: () => void;
  onCreateClient: (values: CreateClientModel) => Promise<void>;
  createClientModalVisible: boolean;
  creatingClient: boolean;
}

export const useCreateClient = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageSuccess, setMessageError } = useMessage();
  const [createClientModalVisible, setCreateClientModalVisible] = useState<boolean>(false);
  const [creatingClient, setCreatingClient] = useState<boolean>(false);
  const [successCreatingClient, setSuccessCreatingClient] = useState<boolean>(false);

  const createClientLiterals = {
    title: t('company_new'),
    mainButtonText: t('send'),
    secondaryButtonText: t('cancel'),
  };

  const onOpenCreateClientModal = () => {
    setCreateClientModalVisible(true);
  };

  const onCloseCreateClientModal = () => {
    setCreateClientModalVisible(false);
  };

  const onCreateClient = async (client: CreateClientModel) => {
    setCreatingClient(true);
    try {
      await CreateClient({ host, token, client });
      setMessageSuccess({ description: t('created_correctly') });
      setSuccessCreatingClient(true);
      setCreateClientModalVisible(false);
      setSuccessCreatingClient(false);
    } catch (error) {
      setMessageError({ description: t('_CREATE_CLIENT_ERROR_MESSAGE') });
    } finally {
      setCreatingClient(false);
    }
  };

  const clientOptions: CreateClientOptionsModel = {
    createClientLiterals,
    onOpenCreateClientModal,
    onCloseCreateClientModal,
    onCreateClient,
    createClientModalVisible,
    creatingClient,
  };

  return { clientOptions, successCreatingClient };
};
