import { EventsAndNewsModel } from '../models/eventsAndNewsEntityModels';

export const EventsAndNewsEntity = ({
  id,
  title,
  description,
  image,
  link,
  linkTitle,
  sendNotification,
}: EventsAndNewsModel): EventsAndNewsModel => {
  return Object.freeze({
    id,
    title,
    description,
    image,
    link,
    linkTitle,
    sendNotification,
  });
};
