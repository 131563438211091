import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormValues, FormValuesResponse, RequiredFormValues } from 'components/molecules/CommonAreaForm/models';
import { getCommonAreaDetailPath, getCommonAreasBookingsListPath } from 'components/pages/App/routes/commonAreas/config';
import { useTimeAvailability } from 'components/molecules/CommonAreaForm/hooks/useTimeAvailability';
import { useFieldsCommonAreasFormErrors } from 'components/molecules/CommonAreaForm/hooks/useFieldsCommonAreasFormErrors';
import { useWeekDays } from 'components/molecules/CommonAreaForm/hooks/useWeekDays';
import { useSensorsProject } from 'hooks/useSensorsProject';
import { messageAtom } from 'components/atoms/MessageAtom';
import { useCommonAreasCRUD } from 'hooks/useCommonAreasCRUD';
import {
  BookingDuration,
  CommonAreaTimeAvailabilityModel,
  CreateCommonAreaModel,
} from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import {
  BOOKING_DURATION_OPTIONS,
  DEFAULT_MAXIMUM_BOOKINGS_BY_USER,
  DEFAULT_MAXIMUM_TIME_AVAILABILITY_PERIOD,
  DEFAULT_MAXIMUM_TIME_AVAILABILITY_VALUE,
  DEFAULT_TIME_AVAILABILITY,
} from './utils';

const useCommonAreaCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>();
  const [form] = Form.useForm<FormValues>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { sensorList, loading: loadingSensors } = useSensorsProject({ projectId });
  const { createCommonArea } = useCommonAreasCRUD();
  const { selectedDays, onSelectDay, onUnselectDay } = useWeekDays();
  const { errors, resetErrors, setErrors, getTimeAvailabilityErrorMessage } = useFieldsCommonAreasFormErrors();
  const {
    timeAvailability,
    onAddTimeAvailabilityInterval,
    onChangeTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    onFilterValidTimeAvailabilityIntervals,
    onParsedTimeAvailabilityToBeSent,
    haveTimeAvailabilityInputsErrors,
  } = useTimeAvailability();

  const translations = {
    createCommonAreaRequiredFieldsText: t('_COMMON_AREA_REQUIRED_FIELDS'),
  };

  const onSelectSensors = (sensorIds: string[]) => {
    form.setFieldsValue({ access: sensorIds });
  };

  const onSelectBookingDuration = (bookingDurationId: BookingDuration) => {
    form.setFieldsValue({ bookingDuration: bookingDurationId });
  };

  const onChangeBackgroundImage = (backgroundImage: string) => {
    form.setFieldsValue({ backgroundImage });
  };

  const onCloseCreateCommonArea = () => {
    const bookingsListRoute = getCommonAreasBookingsListPath({ clientId, projectId });
    history.push(bookingsListRoute);
  };

  const checkAllRequiredFields = ({
    name,
    capacity,
    daysAvailability,
    timeAvailability,
    bookingDuration,
    maximumBookingsByUser,
    maximumTimeAvailabilityPeriod,
    maximumTimeAvailabilityValue,
  }: RequiredFormValues) => {
    const haveTimeAvailabilityErrors = haveTimeAvailabilityInputsErrors(timeAvailability);

    if (
      !name ||
      !capacity ||
      !daysAvailability.length ||
      haveTimeAvailabilityErrors ||
      !bookingDuration ||
      !maximumBookingsByUser ||
      !maximumTimeAvailabilityPeriod ||
      !maximumTimeAvailabilityValue
    ) {
      throw new Error('There are some fields not completed');
    }
  };

  const resetFormNullTimeAvailabilityIntervals = (time: (CommonAreaTimeAvailabilityModel | null)[]) => {
    const filteredValidIntervals = onFilterValidTimeAvailabilityIntervals(time);
    form.setFieldsValue({ timeAvailability: filteredValidIntervals });
  };

  const onParseValues = ({ capacity, access, timeAvailability, ...values }: FormValues): FormValuesResponse | undefined => {
    resetFormNullTimeAvailabilityIntervals(timeAvailability);
    const transformedTimeAvailability = onParsedTimeAvailabilityToBeSent(timeAvailability);

    try {
      checkAllRequiredFields({ capacity, timeAvailability, ...values });
      resetErrors();
      return {
        capacity: Number(capacity),
        sensors: access,
        timeAvailability: transformedTimeAvailability,
        ...values,
      };
    } catch (error: any) {
      setErrors({ capacity, timeAvailability: transformedTimeAvailability, ...values });
    }
  };

  const navigateToCommonAreaDetailPage = (commonAreaId: string) => {
    const commonAreaDetailRoute = getCommonAreaDetailPath({ clientId, projectId, commonAreaId });
    history.push(commonAreaDetailRoute);
  };

  const onCreateCommonArea = async (values: FormValuesResponse): Promise<void> => {
    setIsSaving(true);
    const newCommonArea: CreateCommonAreaModel = {
      ...values,
      timeAvailability: values.timeAvailability,
      maximumTimeAvailability: { timePeriod: values.maximumTimeAvailabilityPeriod, value: Number(values.maximumTimeAvailabilityValue) },
      maximumBookingsByUser: Number(values.maximumBookingsByUser),
      backgroundImage: values.backgroundImage || null,
    };

    try {
      const response = await createCommonArea({ projectId, newCommonArea });
      if (!!response) {
        const messageSuccess = t('_COMMON_AREA_MESSAGE_CREATED');
        messageAtom.success(messageSuccess);
        navigateToCommonAreaDetailPage(response.id);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsSaving(false);
    }
  };

  const onFinishCreateCommonArea = (values: FormValues) => {
    const parsedValues = onParseValues(values);
    parsedValues && onCreateCommonArea(parsedValues);
  };

  useEffect(() => {
    form.setFieldsValue({
      maximumBookingsByUser: DEFAULT_MAXIMUM_BOOKINGS_BY_USER,
      maximumTimeAvailabilityPeriod: DEFAULT_MAXIMUM_TIME_AVAILABILITY_PERIOD,
      maximumTimeAvailabilityValue: DEFAULT_MAXIMUM_TIME_AVAILABILITY_VALUE,
      timeAvailability: DEFAULT_TIME_AVAILABILITY,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({ timeAvailability });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeAvailability]);

  useEffect(() => {
    form.setFieldsValue({ daysAvailability: selectedDays });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays]);

  return {
    Form,
    form,
    translations,
    timeAvailability,
    bookingDurationOptionList: BOOKING_DURATION_OPTIONS,
    errors,
    selectedDays,
    sensorList,
    onAddTimeAvailabilityInterval,
    onChangeTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    getTimeAvailabilityErrorMessage,
    onChangeBackgroundImage,
    onSelectDay,
    onUnselectDay,
    onSelectSensors,
    onSelectBookingDuration,
    onFinishCreateCommonArea,
    onCloseCreateCommonArea,
    saving: isSaving,
    loadingSensors,
  };
};

export default useCommonAreaCreate;
