import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RequiredParsedFormValues } from '../models';
import { CommonAreaTimeAvailabilityModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/date';

interface RequiredFieldsError {
  name: boolean;
  capacity: boolean;
  daysAvailability: boolean;
  timeAvailability: boolean;
  bookingDuration: boolean;
}

export const useFieldsCommonAreasFormErrors = () => {
  const { t } = useTranslation();
  const [errorName, setErrorName] = useState<boolean>(false);
  const [errorCapacity, setErrorCapacity] = useState<boolean>(false);
  const [errorDaysAvailability, setErrorDaysAvailability] = useState<boolean>(false);
  const [errorTimeAvailability, setErrorTimeAvailability] = useState<boolean>(false);
  const [errorDuplicatedTimeAvailability, setErrorDuplicatedTimeAvailability] = useState<boolean>(false);
  const [errorBookingDuration, setErrorBookingDuration] = useState<boolean>(false);
  const [errorMaximumBookingsByUser, setErrorMaximumBookingsByUser] = useState<boolean>(false);
  const [errorMaximumTimeAvailabilityPeriod, setErrorMaximumTimeAvailabilityPeriod] = useState<boolean>(false);
  const [errorMaximumTimeAvailabilityValue, setErrorMaximumTimeAvailabilityValue] = useState<boolean>(false);

  const setErrors = ({
    name,
    capacity,
    daysAvailability,
    timeAvailability,
    bookingDuration,
    maximumBookingsByUser,
    maximumTimeAvailabilityPeriod,
    maximumTimeAvailabilityValue,
  }: RequiredParsedFormValues): void => {
    setErrorName(!name);
    setErrorCapacity(!capacity);
    setErrorDaysAvailability(!daysAvailability.length);
    setErrorBookingDuration(!bookingDuration);
    setErrorMaximumBookingsByUser(!maximumBookingsByUser);
    setErrorMaximumTimeAvailabilityPeriod(!maximumTimeAvailabilityPeriod);
    setErrorMaximumTimeAvailabilityValue(!maximumTimeAvailabilityValue);

    const isTimeAvailabilityNotEmptyError = checkTimeAvailabilityNotEmptyError(timeAvailability);
    setErrorTimeAvailability(!isTimeAvailabilityNotEmptyError);

    const isDuplicatedTimeAvailability = checkTimeAvailabilityOverlapIntervalsError(timeAvailability);
    setErrorDuplicatedTimeAvailability(isDuplicatedTimeAvailability);
  };

  const transformTimeAvailabilityIntoTimestamps = (timeIntervals: CommonAreaTimeAvailabilityModel[]): { start: number; end: number }[] => {
    return timeIntervals.map((value) => {
      const dateString = moment().format(DATE_FORMAT);
      const startDateAndTimeString = `${value.start} ${dateString}`;
      const startTimestamp = moment(startDateAndTimeString, DATE_TIME_FORMAT).valueOf();
      const endDateAndTimeString = `${value.end} ${dateString}`;
      const endTimestamp = moment(endDateAndTimeString, DATE_TIME_FORMAT).valueOf();
      return { start: startTimestamp, end: endTimestamp };
    });
  };

  const hasTimeAvailabilityOverlapIntervals = (timeAvailabilityIntervals: { start: number; end: number }[]): boolean => {
    return timeAvailabilityIntervals.some((interval, index) => {
      const { start: checkingStartValue, end: checkingEndValue } = interval;
      const otherIntervals = timeAvailabilityIntervals.slice(index + 1);

      return otherIntervals.some((otherInterval) => {
        const { start: otherStartValue, end: otherEndValue } = otherInterval;

        const isStartInsideInterval = checkingStartValue >= otherStartValue && checkingStartValue < otherEndValue;
        const isEndInsideInterval = checkingEndValue > otherStartValue && checkingEndValue <= otherEndValue;
        const isStartAndEndInsideInterval = checkingStartValue < otherStartValue && checkingEndValue > otherEndValue;

        return isStartInsideInterval || isEndInsideInterval || isStartAndEndInsideInterval;
      });
    });
  };

  const checkTimeAvailabilityOverlapIntervalsError = (intervals: CommonAreaTimeAvailabilityModel[]): boolean => {
    if (!!intervals.length) {
      const formattedValues = transformTimeAvailabilityIntoTimestamps(intervals);
      const areThereSomeOverlapIntervals = hasTimeAvailabilityOverlapIntervals(formattedValues);
      return areThereSomeOverlapIntervals;
    }

    return false;
  };

  const checkTimeAvailabilityNotEmptyError = (values: (CommonAreaTimeAvailabilityModel | null)[]): boolean => {
    return values.some((value) => {
      return !!value && !!value.start && !!value.end;
    });
  };

  const getTimeAvailabilityErrorMessage = (): string => {
    return errorTimeAvailability ? t('field_required') : t('_COMMON_AREA_FORM_ERROR_TIME_AVAILABILITY_DUPLICATED');
  };

  const resetErrors = () => {
    setErrorName((state) => state && false);
    setErrorCapacity((state) => state && false);
    setErrorDaysAvailability((state) => state && false);
    setErrorTimeAvailability((state) => state && false);
    setErrorDuplicatedTimeAvailability((state) => state && false);
    setErrorBookingDuration((state) => state && false);
    setErrorMaximumBookingsByUser((state) => state && false);
    setErrorMaximumTimeAvailabilityPeriod((state) => state && false);
    setErrorMaximumTimeAvailabilityValue((state) => state && false);
  };

  return {
    errors: {
      name: errorName,
      capacity: errorCapacity,
      daysAvailability: errorDaysAvailability,
      timeAvailability: errorTimeAvailability || errorDuplicatedTimeAvailability,
      bookingDuration: errorBookingDuration,
      maximumBookingsByUser: errorMaximumBookingsByUser,
      maximumTimeAvailability: errorMaximumTimeAvailabilityPeriod || errorMaximumTimeAvailabilityValue,
    } as RequiredFieldsError,
    setErrors,
    checkTimeAvailabilityOverlapIntervalsError,
    checkTimeAvailabilityNotEmptyError,
    getTimeAvailabilityErrorMessage,
    resetErrors,
  };
};
