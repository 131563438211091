import { FC } from 'react';
import { Svg } from '../model/svg';

const Empty: FC<Svg> = ({ width = '96', height = '96', ...props }) => (
  <svg width={width} height={height} viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      opacity='0.15'
      d='M46.3903 -2.02779e-06C55.6166 -2.43108e-06 64.4298 1.80392 72 6.6C78.1626 10.5043 83.5014 16.7345 87.5686 22.8C94.2071 32.7 96 40.0273 96 49.8C96 63.2175 93 75 84 84C75.9049 92.0546 63.4085 96 48 96C33.2614 96 19.322 89.502 10.8 79.8C5.00269 73.2 -1.59745e-06 59.4545 -2.1506e-06 46.8C-2.69531e-06 34.3385 1.24987 21.4513 8.4 13.2C19.8383 -8.49656e-06 31.2874 -1.36762e-06 46.3903 -2.02779e-06Z'
      fill='#96C0C4'
    />
    <path
      d='M0.499989 49.6097C0.49999 40.4462 2.29175 31.7344 7.02237 24.2676C10.8739 18.1882 17.0404 12.8955 23.0785 8.84667C32.8927 2.26566 40.1224 0.499995 49.8 0.499996C63.1456 0.499997 74.7754 3.48292 83.6458 12.3529C91.5729 20.3199 95.5 32.6658 95.5 48C95.5 62.6001 89.0599 76.4007 79.47 84.8243C76.2357 87.6653 71.2151 90.338 65.4103 92.3003C59.611 94.2608 53.0652 95.5 46.8 95.5C34.3287 95.5 21.623 94.2372 13.5274 87.2221C6.9989 81.5649 3.75588 75.9282 2.13307 69.8501C0.503146 63.7454 0.499988 57.1766 0.499989 49.6097Z'
      stroke='#96C0C4'
    />
    <g>
      <path
        d='M47.7597 33.4411L45.4303 42.1344C45.2873 42.6679 44.739 42.9845 44.2055 42.8415L42.2737 42.3239C41.7402 42.1809 41.4236 41.6326 41.5666 41.0991L43.896 32.4058L38.1004 30.8529L33.9593 46.3077L39.7548 47.8606L40.0137 46.8947C40.1566 46.3612 40.7049 46.0447 41.2384 46.1876L43.1703 46.7052C43.7037 46.8482 44.0203 47.3965 43.8774 47.93L41.8068 55.6574C41.6639 56.1908 41.1155 56.5074 40.5821 56.3645L38.6502 55.8469C38.1168 55.7039 37.8002 55.1556 37.9431 54.6221L38.7196 51.7243L32.924 50.1714L30.8535 57.8988L44.3764 61.5223L45.1529 58.6245C45.2958 58.091 45.8442 57.7745 46.3776 57.9174L48.3095 58.435C48.8429 58.578 49.1595 59.1263 49.0166 59.6598L48.2401 62.5576L57.8994 65.1458L58.6758 62.248C58.8188 61.7145 59.3671 61.3979 59.9006 61.5409L61.8324 62.0585C62.3659 62.2014 62.6825 62.7498 62.5395 63.2833L60.9866 69.0788C60.8437 69.6123 60.2954 69.9289 59.7619 69.7859L26.9204 60.9861C26.3869 60.8431 26.0704 60.2948 26.2133 59.7613L35.0132 26.9198C35.1561 26.3864 35.7044 26.0698 36.2379 26.2127L69.0794 35.0126C69.6128 35.1555 69.9294 35.7039 69.7865 36.2373L64.0925 57.4877C63.9495 58.0212 63.4012 58.3377 62.8677 58.1948L60.9359 57.6772C60.4024 57.5342 60.0858 56.9859 60.2288 56.4524L62.0405 49.6909L52.3812 47.1028L50.5695 53.8642C50.4266 54.3977 49.8782 54.7143 49.3448 54.5713L47.4129 54.0537C46.8794 53.9108 46.5629 53.3624 46.7058 52.829L49.5528 42.2038L63.0758 45.8272L65.1463 38.0998L47.7597 33.4411Z'
        fill='#96C0C4'
      />
    </g>
    <defs>
      <clipPath id='clip0_2435_68823'>
        <rect width='48' height='48' fill='white' transform='translate(31.0293 18.6055) rotate(15)' />
      </clipPath>
    </defs>
  </svg>
);

export default Empty;
