import { FC, ReactNode } from "react";
import Paper from "components/atoms/Paper";
import SubTabsTitles from "./SubTabs/SubTabsTitles";
import { ProjectCustomizationSubTabs } from "./SubTabs/constants";

interface Props {
  subTab: string;
  onSubTabClick: (subTab: ProjectCustomizationSubTabs) => void;
  children: { key: string; render: ReactNode }[];
}

const TabCustomization: FC<Props> = ({ subTab, onSubTabClick, children }) => {
  const handleOnChange = (activeSubTab: ProjectCustomizationSubTabs): void => {
    onSubTabClick(activeSubTab);
  };

  return (
    <Paper>
      <SubTabsTitles defaultActiveKey={subTab} onChange={(activeSubTab: any) => handleOnChange(activeSubTab)} />
      {children.map((element) => {
        return subTab === element.key && element.render;
      })}
    </Paper>
  );
}

export default TabCustomization;