import { FC } from 'react';
import { AssetSupportTicketsStatisticsTranslationsTypes } from 'components/pages/AssetPage/Tabs/TabStart/resources/translations';
import './styles.scss';

interface Props {
  translations: AssetSupportTicketsStatisticsTranslationsTypes;
}

const AssetSupportTicketsStatisticsErrorTemplate: FC<Props> = ({ translations }) => {
  return (
    <div className='AssetSupportTicketsStatisticsErrorTemplate__container'>
      <div className='AssetSupportTicketsStatisticsErrorTemplate__header'>
        <div>
          <p className='AssetSupportTicketsStatisticsErrorTemplate__title'>
            {translations.TITLE}
          </p>
          <p className='AssetSupportTicketsStatisticsErrorTemplate__subtitle'>
            {translations.TITLE_TIME_PERIOD}
          </p>
        </div>
      </div>
      <div className='AssetSupportTicketsStatisticsErrorTemplate__chart-container'>
        <div className='AssetSupportTicketsStatisticsErrorTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-title'>
            {translations.TICKETS_CLOSED_TIME_AVERAGE}
          </p>
          <span className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='AssetSupportTicketsStatisticsErrorTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-title'>
            {translations.FIRST_RESPONSE_TIME_AVERAGE}
          </p>
          <span className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='AssetSupportTicketsStatisticsErrorTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-title'>
            {translations.TICKETS_RECEIVED}
          </p>
          <span className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='AssetSupportTicketsStatisticsErrorTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-title'>
            {translations.TICKETS_CLOSED}
          </p>
          <span className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='AssetSupportTicketsStatisticsErrorTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-title'>
            {translations.TICKETS_IN_PROGRESS}
          </p>
          <span className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='AssetSupportTicketsStatisticsErrorTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-title'>
            {translations.TICKETS_REVOKED}
          </p>
          <span className='AssetSupportTicketsStatisticsErrorTemplate__chart-element-data'>-</span>
        </div>
      </div>
    </div>
  );
}

export default AssetSupportTicketsStatisticsErrorTemplate;