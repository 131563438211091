import { FC } from 'react';
import { Divider, Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { ProjectIntercomFormValues, ProjectIntercomFormValuesNames } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/config';
import { IntercomFormTranslations } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/translations';
import './styles.scss';

interface Props {
  translations: IntercomFormTranslations;
  form: FormInstance<ProjectIntercomFormValues>;
  providers: DefaultOptionType[];
  onFinish: (values: ProjectIntercomFormValues) => void;
  onFinishFailed: () => void;
  onClose: () => void;
  processing: boolean;
}

const ProjectIntercomEditForm: FC<Props> = ({
  translations,
  form,
  providers,
  onFinish,
  onFinishFailed,
  onClose,
  processing,
}) => {
  const {
    name,
    nameError,
    namePlaceholder,
    provider,
    providerError,
    providerPlaceholder,
    save,
    close,
  } = translations;

  return (
    <div className={'IntercomEditForm__content'}>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout={'vertical'}
        requiredMark={false}
      >
        <div>
          <Form.Item
            label={name}
            name={ProjectIntercomFormValuesNames.NAME}
            rules={[{ required: true, message: nameError }]}
          >
            <Input disabled={processing} placeholder={namePlaceholder} />
          </Form.Item>
          <Form.Item
            label={provider}
            name={ProjectIntercomFormValuesNames.PROVIDER}
            rules={[{ required: true, message: providerError }]}
          >
            <Select
              allowClear
              options={providers}
              placeholder={providerPlaceholder}
              disabled={processing}
            />
          </Form.Item>
        </div>
        <Divider />
        <div className={'IntercomEditForm__buttons'}>
          <div className={'IntercomEditForm__right-buttons'}>
            <DefaultButton
              title={close}
              onClick={onClose}
              disabled={processing}
            />
            <PrimaryButton
              htmlType={'submit'}
              title={save}
              loading={processing}
              disabled={processing}
            />
          </div>
        </div>
      </Form>
    </div>

  );
}

export default ProjectIntercomEditForm;