import { FC } from 'react';
import { getOccupationTranslations, getSupportTicketsTranslations, getUsersTranslations } from './resources/translations';
import { useSupportTicketsStatistics } from './resources/useSupportTicketsStatistics';
import { useUsersStatistics } from './resources/useUsersStatistics';
import { useOccupation } from './resources/useOccupation';
import DashboardOccupationLoadingTemplate from 'components/template/DashboardOccupationLoadingTemplate';
import DashboardOccupationTemplate from 'components/template/DashboardOccupationTemplate';
import DashboardOccupationErrorTemplate from 'components/template/DashboardOccupationErrorTemplate.tsx';
import DashboardUsersLoadingTemplate from 'components/template/DashboardUsersLoadingTemplate';
import DashboardUsersTemplate from 'components/template/DashboardUsersTemplate';
import DashboardSupportTicketsLoadingTemplate from 'components/template/DashboardSupportTicketsLoadingTemplate';
import DashboardSupportTicketsTemplate from 'components/template/DashboardSupportTicketsTemplate';
import DashboardSupportTicketsErrorTemplate from 'components/template/DashboardSupportTicketsErrorTemplate';
import DashboardUsersErrorTemplate from 'components/template/DashboardUsersErrorTemplate';
import { useTechSupportList } from 'hooks/useTechSupportList';
import DashboardActiveGatewaysLoadingTemplate from 'components/template/DashboardActiveGatewaysLoadingTemplate';
import DashboardActiveGatewaysErrorTemplate from 'components/template/DashboardActiveGatewaysErrorTemplate';
import { useActiveGatewaysStatistics } from './resources/useActiveGatewaysStatistics';
import DashboardActiveGatewaysTemplate from 'components/template/DashboardActiveGatewaysTemplate';
import './styles.scss';

const DashboardPage: FC = () => {
  const { occupationLoading, occupationError, assetsLinked, assetsNotLinked } = useOccupation();
  const { supportTicketsLoading, supportTicketsError, supportTicketsStatistics, onClickOpenedSupportTickets } = useSupportTicketsStatistics();
  const { usersLoading, usersError, usersStatistics } = useUsersStatistics();
  const { totalValues, loadingSupportTickets: isOpenedSupportTicketsLoading } = useTechSupportList();
  const { activeGatewaysStatistics, activeGatewaysError, activeGatewaysLoading, activeGatewaysTranslations, activeGatewaysErrorTranslations } = useActiveGatewaysStatistics();
  const occupationTranslations = getOccupationTranslations();
  const supportTicketsTranslations = getSupportTicketsTranslations();
  const usersTranslations = getUsersTranslations();
  const { errorChartTitle, errorChartDescription } = activeGatewaysErrorTranslations;
  const { title, dataSubtitle, totalGatewaysText, chartSubtitle } = activeGatewaysTranslations;


  return (
    <div className='Dashboard__wrapper'>
      <div className='Dashboard__charts'>
        {occupationLoading || isOpenedSupportTicketsLoading && <DashboardOccupationLoadingTemplate />}
        {!occupationLoading && !isOpenedSupportTicketsLoading && !occupationError && (
          <DashboardOccupationTemplate
            assetsLinked={assetsLinked}
            assetsNotLinked={assetsNotLinked}
            translations={occupationTranslations}
          />
        )}
        {!occupationLoading && !isOpenedSupportTicketsLoading && occupationError && (
          <DashboardOccupationErrorTemplate
            translations={occupationTranslations}
          />
        )}
        {usersLoading || isOpenedSupportTicketsLoading && <DashboardUsersLoadingTemplate />}
        {!usersLoading && !isOpenedSupportTicketsLoading && !usersError && (
          <DashboardUsersTemplate
            statistics={usersStatistics}
            translations={usersTranslations}
          />
        )}
        {!usersLoading && !isOpenedSupportTicketsLoading && usersError && (
          <DashboardUsersErrorTemplate
            translations={usersTranslations}
          />
        )}
      </div>
      {supportTicketsLoading || isOpenedSupportTicketsLoading && <DashboardSupportTicketsLoadingTemplate />}
      {!supportTicketsLoading && !isOpenedSupportTicketsLoading && !supportTicketsError && (
        <DashboardSupportTicketsTemplate
          tickets={supportTicketsStatistics}
          openTickets={totalValues?.totalOpenSupportTickets || 0}
          onClick={onClickOpenedSupportTickets}
          translations={supportTicketsTranslations}
        />
      )}
      {!supportTicketsLoading && !isOpenedSupportTicketsLoading && supportTicketsError && (
        <DashboardSupportTicketsErrorTemplate
          translations={supportTicketsTranslations}
        />
      )}
      {activeGatewaysLoading || isOpenedSupportTicketsLoading && <DashboardActiveGatewaysLoadingTemplate />}
      {!activeGatewaysLoading && !isOpenedSupportTicketsLoading && !activeGatewaysError && (
        <DashboardActiveGatewaysTemplate
          title={title}
          dataSubtitle={dataSubtitle}
          totalGatewaysText={totalGatewaysText}
          chartSubtitle={chartSubtitle}
          gateways={activeGatewaysStatistics}
        />
      )}
      {!activeGatewaysLoading && !isOpenedSupportTicketsLoading && activeGatewaysError &&
        <DashboardActiveGatewaysErrorTemplate
          title={title}
          dataSubtitle={dataSubtitle}
          totalGatewaysText={totalGatewaysText}
          chartSubtitle={chartSubtitle}
          chartErrorTitle={errorChartTitle}
          chartErrorDescription={errorChartDescription}
        />}
    </div>

  );
};

export default DashboardPage;
