import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContextualMenuLinks from '../GatewayStatus/GatewayInformationBlock/ContextualMenuLinks';
import Paper from 'components/atoms/Paper';
import LinkButton from 'components/atoms/Button/LinkButton';
import AssignGatewayAction, { AssignGatewayFormValues } from 'components/organisms/AssignGatewayAction';
import './styles.scss';

interface Props {
  assetId: string;
  onAssignGateway: (gatewayQid: string) => void;
  hasPermissionToEditGatewayLink: boolean;
}

export const NoGatewayAssigned: FC<Props> = ({
  assetId,
  onAssignGateway,
  hasPermissionToEditGatewayLink,
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const onFinish = (values: AssignGatewayFormValues) => {
    setIsModalVisible(false);

    setTimeout(() => {
      onAssignGateway(values.gatewayQid);
    }, 200);
  }

  return (
    <>
      <Paper>
        <AssignGatewayAction
          assetId={assetId}
          onFinish={onFinish}
          isShown={isModalVisible}
          onCancel={() => setIsModalVisible(false)} />
        <div className='NoGatewayAssigned__contextualMenu'>
          <ContextualMenuLinks
            onAssignGateway={() => setIsModalVisible(true)}
            disabledContextualMenuLinks={true}
            isGatewayLinked={false}
            hasPermissionToEditGatewayLink={hasPermissionToEditGatewayLink}
            isSharedGateway={false}
          />
        </div>
        <div className={'NoGatewayAssigned__textContainer'}>
          <div className={'NoGatewayAssigned__textContent'}>
            <span className={'NoGatewayAssigned__title'}>{t('gateway_unlinked')}</span>
            <span className={'NoGatewayAssigned__subtitle'}>{t('link_gateway_to_start')}</span>
            <div className={'NoGatewayAssigned__button'}>
              <LinkButton
                title={t('link_gateway')}
                disabled={!hasPermissionToEditGatewayLink}
                onClick={() => setIsModalVisible(true)} />
            </div>
          </div>
        </div>
      </Paper >
    </>
  )
};