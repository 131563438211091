import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_SIZE } from 'components/organisms/AlfredTable';
import { updateSupportTicketStatus } from 'services/api/supportTickets';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { getSupportTicketPathById, getSupportTicketsListWithPaginationPath } from 'components/pages/App/routes/supportTickets/config';
import { TechSupportsColumns } from 'components/pages/TechSupportsPage/TechSupportsColumns';
import { GetSupportTicketsList } from 'core/domain/supportTickets/repositories/getSupportTicketsList';
import { SupportTicketCreatedByModel, SupportTicketModel, TotalValuesModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import {
  SupportTicketCategoryType,
  SupportTicketStatusType,
  SupportTicketTypeType,
} from 'core/domain/supportTickets/models/supportTicketsPayloadModel';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { ColumnSearchTranslationsType } from 'components/pages/TechSupportsPage/TechSupportsColumns/ColumnsSearchProps';
import { Form } from 'antd';
import moment, { Moment } from 'moment';
import { downloadCsvFile } from 'utils/csv';
import { SupportTicketsListDownloadModalFormValues, SupportTicketsListDownloadModalFormValuesModel, SupportTicketsListDownloadModalLiteralModel } from 'components/organisms/SupportTicketDownloadModal';
import { getDownloadSupportTicketsListCsvFileByClient } from 'core/domain/supportTickets/repositories/getDownloadSupportTicketsListCsvFileByClient';
import { useMessage } from './useMessage';

export type DataIndex = keyof SupportTicketModel;

const SUCCESS_MESSAGE_DURATION = 3;

export const useTechSupportList = (assetId: string = '') => {
  const { t } = useTranslation();
  const history = useHistory();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalValues, setTotalValues] = useState<TotalValuesModel>();
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [supportTicketList, setSupportTicketList] = useState<SupportTicketModel[]>([]);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<any>(null);
  const [isVisibleDownloadCsvModal, setIsVisibleDownloadCsvModal] = useState<boolean>(false);
  const [isLoadingDownloadCsv, setIsLoadingDownloadCsv] = useState<boolean>(false);
  const [form] = Form.useForm<SupportTicketsListDownloadModalFormValuesModel>();
  const { setMessageError } = useMessage();
  
  const openedSupportTicketsTranslation: string = t('KPIS.total_open_support_tickets');
  const acceptedSupportTicketsTranslation: string = t('KPIS.total_accepted_support_tickets');
  const inProgressSupportTicketsTranslation: string = t('KPIS.total_in_progress_support_tickets');
  const lastWeekSupportTicketTranslation: string = t('KPIS.tickets_created_during_last_week');
  const lastMonthSupportTicketsTranslation: string = t('KPIS.tickets_created_during_last_month');
  const title: string = t('support_tickets');
  const subtitle: string = t('_SUPPORT_TICKETS_MONTH_PERIOD_IN_DAYS');
  const successUpdateMessage: string = t('status_changed');
  const downloadCsvButtonText: string = t('_SUPPORT_TICKETS_DOWNLOAD_BUTTON_TEXT');
  
  const downloadModal: SupportTicketsListDownloadModalLiteralModel = {
    titleText: t('_SUPPORT_TICKETS_DOWNLOAD_MODAL_TITLE_TEXT'),
    descriptionText: t('_SUPPORT_TICKETS_DOWNLOAD_MODAL_DESCRIPTION_TEXT'),
    cancelButtonText: t('_SUPPORT_TICKETS_DOWNLOAD_MODAL_CANCEL_BUTTON_TEXT'),
    confirmButtonText: t('_SUPPORT_TICKETS_DOWNLOAD_MODAL_CONFIRM_BUTTON_TEXT'),
    fromDateLabelText: t('_SUPPORT_TICKETS_DOWNLOAD_MODAL_FROM_DATE_LABEL_TEXT'),
    untilDateLabelText: t('_SUPPORT_TICKETS_DOWNLOAD_MODAL_UNTIL_DATE_LABEL_TEXT'),
    datePickerPlaceholderText: t('_SUPPORT_TICKETS_DOWNLOAD_MODAL_DATE_PICKER_PLACEHOLDER_TEXT'),
    requireFieldText: t('field_required'),
  }

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
  };

  const filteredSupportTicketsTypes: string[] = Object.values(SupportTicketTypeType).filter(
    (type) => type !== SupportTicketTypeType.ALL_SOURCES
  );
  const sourcesFilters: { text: string; value: string }[] = filteredSupportTicketsTypes.map((sourceType) => ({
    text: t(sourceType.toLowerCase()),
    value: sourceType.toLowerCase(),
  }));

  const filteredSupportTicketsCategoryTypes: string[] = Object.values(SupportTicketCategoryType);
  const categoriesFilters: { text: string; value: string }[] = filteredSupportTicketsCategoryTypes.map((categoryType) => {
    return { text: t(categoryType), value: categoryType };
  });

  const filteredSupportTicketsStatusType: string[] = Object.values(SupportTicketStatusType).filter(
    (type) => type !== SupportTicketStatusType.ALL_STATUSES
  );
  const statusFilters: { text: string; value: string }[] = filteredSupportTicketsStatusType.map((status) => {
    return { text: t(status), value: status };
  });

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const formatData = (data: SupportTicketModel[]): SupportTicketModel[] => {
    return data.map((ticket) => {
      const { createdBy } = ticket;
      const formattedTicketCreatedBy: SupportTicketCreatedByModel = { ...createdBy, name: 'Gateway' };
      return ticket.createdBy.type === SupportTicketTypeType.AUTOMATED ? { ...ticket, createdBy: formattedTicketCreatedBy } : ticket;
    });
  };

  const getSupportTicketsListWithPagination = async ({ page, size }: { page: number; size: number }) => {
    setIsLoading(true);
    try {
      const response = await GetSupportTicketsList({ assetId, pageNumber: page, pageSize: size });
      if (response) {
        const { data, meta, totalValues } = response;
        const formattedData = formatData(data);
        setSupportTicketList(formattedData);
        setTotalPages(meta.totalPages);
        setTotalValues(totalValues);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateStatus = async ({
    id,
    status,
    description,
  }: {
    id: string;
    status: SupportTicketStatusType;
    description: string;
  }): Promise<void> => {
    setIsLoading(true);
    try {
      await updateSupportTicketStatus(id, { status, description });
      message.success(successUpdateMessage, SUCCESS_MESSAGE_DURATION);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize!);
    history.push(getSupportTicketsListWithPaginationPath({ page: String(page), size: String(pageSize!) }));
  };

  const onSelectSupportTicket = (techSupportData: SupportTicketModel) => {
    history.push(getSupportTicketPathById(techSupportData.id));
  };

  const columns = TechSupportsColumns({
    onUpdateStatus,
    loading: isLoading,
    sourcesFilters,
    categoriesFilters,
    statusFilters,
    searchedColumn,
    searchInput,
    onSearch,
    onReset,
    columnSearchTranslations,
  });

  const getIsDisabledFromDate = (current: Moment) => {
    return current > moment().endOf('day');
  };

  const getIsDisabledUntilDate = (current: Moment) => {
    const fromDate = form.getFieldValue(SupportTicketsListDownloadModalFormValues.FROM_DATE);
    const isCurrentDateBeforeFromDate = fromDate && current.isBefore(fromDate.startOf('day'));
      const isCurrentDateAfterToday = current > moment().endOf('day');
      
    return isCurrentDateBeforeFromDate || isCurrentDateAfterToday;
  };

  const onShowDownloadSupportTicketsList = () => {
    const untilDate = moment();
    form.setFieldsValue({ [SupportTicketsListDownloadModalFormValues.UNTIL_DATE]: untilDate });
    setIsVisibleDownloadCsvModal(true);
  };

  const onCancelDownloadSupportTicketsList = () => {
    setIsVisibleDownloadCsvModal(false);
    setTimeout(() => {
      form.resetFields();
    }, 500);
  };

  const onSubmitDownloadSupportTicketsListForm = async (values: SupportTicketsListDownloadModalFormValuesModel) => {
    setIsLoadingDownloadCsv(true);
    const { fromDate, untilDate } = values;
    const timestampFrom = fromDate.unix();
    const timestampUntil = untilDate.unix();
    try {
      const csvData = await getDownloadSupportTicketsListCsvFileByClient({
        timestampFrom,
        timestampUntil,
      })
      const csvFileName = t('_SUPPORT_TICKETS_DOWNLOAD_FILE_NAME');
      
      !!csvData && downloadCsvFile(csvData, csvFileName);
    } catch (error) {
      setMessageError({ description: t('_SUPPORT_TICKETS_DOWNLOAD_ERROR_MESSAGE') });
    } finally {
      onCancelDownloadSupportTicketsList();
      setIsLoadingDownloadCsv(false);
    }
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    setCurrentPage(Number(newPage));
    setPageSize(Number(newSize));
  }, [page, size]);

  useEffect(() => {
    getSupportTicketsListWithPagination({
      page: Number(page) || Number(TABLE_PAGINATION_PAGE_DEFAULT),
      size: Number(size) || Number(TABLE_PAGINATION_SIZE_DEFAULT),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  return {
    form,
    supportTicketList,
    columns,
    totalPages,
    totalValues,
    pageSize,
    currentPage,
    openedSupportTicketsTranslation,
    acceptedSupportTicketsTranslation,
    inProgressSupportTicketsTranslation,
    lastWeekSupportTicketTranslation,
    lastMonthSupportTicketsTranslation,
    title,
    subtitle,
    downloadCsvButtonText,
    downloadModal,
    onChangePage,
    onUpdateStatus,
    onSelectSupportTicket,
    onShowDownloadSupportTicketsList,
    onCancelDownloadSupportTicketsList ,
    getIsDisabledFromDate,
    getIsDisabledUntilDate,
    onSubmitDownloadSupportTicketsListForm,
    loadingSupportTickets: isLoading,
    errorSupportTickets: isError,
    isLoadingDownloadCsv,
    isVisibleDownloadCsvModal,
  };
};
