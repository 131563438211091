import { assetsTemplatePath } from 'core/endspoints/users';
import api from 'services/api';

export const getDownloadAssetsTemplate = async (clientId: string) => {
  api.defaults.headers.common['Accept-version'] = '1';
  const url = assetsTemplatePath();

  try {
    const { data } = await api.post<string | undefined>(url, { data: { client_id: clientId } });
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
