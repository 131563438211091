import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/lib/upload';
import {
  CreateLegalDocumentation,
  DeleteLegalDocumentation,
  GetLegalDocumentationListByClientId,
} from 'core/domain/client/repositories/legalDocumentationCRUD';
import { checkFileFormat, checkFileSize, openFile } from './utils';
import {
  CustomLegalDocumentationModel,
  LegalDocumentationActionsNames,
  LegalDocumentationChangeValuesModel,
  LegalDocumentationContentTranslationsModel,
  LegalDocumentationEmptyTranslationsType,
  LegalDocumentationFormChangesModel,
  LegalDocumentationFormValues,
  LegalDocumentationFormValuesNames,
  LegalDocumentationStateType,
} from './config';
import { LegalDocumentationCreateModel, LegalDocumentationModel } from 'core/domain/client/models/clientEntityModel';
import { messageAtom } from 'components/atoms/MessageAtom';
import { UploadFile } from 'antd/lib/upload/interface';
import { fileToBase64 } from 'utils/documents';
import { LocalStorageKeys } from 'constants/localStorage';
import { ROUTE_PATH_LOGIN } from 'components/pages/App/routes';

const ACCEPTED_FORMAT_TYPES = ['pdf', 'doc', 'docx'];
const ACCEPTED_BASE64_FORMAT_TYPES = ['pdf', 'msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document'];
const MAXIMUM_FILE_SIZE = 10;

const INITIAL_LEGAL_DOCUMENTATION: CustomLegalDocumentationModel = {
  id: '',
  clientId: '',
  enabled: true,
  privacyPolicy: '',
  privacyPolicyName: '',
  termsOfService: '',
  termsOfServiceName: '',
  termsOfServiceShowButtonVisible: false,
  privacyPolicyShowButtonVisible: false,
  isDefault: true,
};

export const forceLogout = () => {
  LocalStorageKeys.TOKEN && localStorage.removeItem(LocalStorageKeys.TOKEN);
  setTimeout(() => window.location.pathname !== ROUTE_PATH_LOGIN && (window.location.pathname = ROUTE_PATH_LOGIN), 1000);
};

export const useLegalDocumentation = () => {
  const { t } = useTranslation();
  const { clientId } = useParams<{ clientId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [legalDocumentation, setLegalDocumentation] = useState<CustomLegalDocumentationModel>(INITIAL_LEGAL_DOCUMENTATION);
  const [initialLegalDocumentation, setInitialLegalDocumentation] = useState<CustomLegalDocumentationModel>(INITIAL_LEGAL_DOCUMENTATION);
  const [templateVisor, setTemplateVisor] = useState<LegalDocumentationStateType>(LegalDocumentationStateType.EMPTY);
  const [enabledSwitch, setEnabledSwitch] = useState<boolean>(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm<LegalDocumentationFormValues>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState<boolean>(false);
  const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState<boolean>(false);
  const [disableConfirmationModalVisible, setDisableConfirmationModalVisible] = useState<boolean>(false);

  const isTermsOfServiceUploadButtonDisabled = !!legalDocumentation.termsOfService;
  const isTermsOfServiceUploadInfoVisible = !!legalDocumentation.termsOfService;
  const isPrivacyPolicyUploadButtonDisabled = !!legalDocumentation.privacyPolicy;
  const isPrivacyPolicyUploadInfoVisible = !!legalDocumentation.privacyPolicy;
  const hasLegalDocumentationContent = !!legalDocumentation.id;
  const isLegalDocumentationDefaultValue = !!legalDocumentation.isDefault;

  const transformedAcceptedTypes = ACCEPTED_FORMAT_TYPES.map((type) => type.toUpperCase())
    .join(', ')
    .trim();
  const acceptedFormatFiles = ACCEPTED_FORMAT_TYPES.map((type) => `.${type}`)
    .join(', ')
    .trim();

  const emptyTemplateTranslations: LegalDocumentationEmptyTranslationsType = {
    title: t('_LEGAL_DOCUMENTATION_EMPTY_TEMPLATE_TITLE'),
    infoTitle: t('_LEGAL_DOCUMENTATION_EMPTY_TEMPLATE_INFO_TITLE'),
    infoDescription: t('_LEGAL_DOCUMENTATION_EMPTY_TEMPLATE_INFO_DESCRIPTION_TEXT'),
    infoButtonText: t('_LEGAL_DOCUMENTATION_EMPTY_TEMPLATE_INFO_BUTTON_TEXT'),
  };

  const emptyModalTranslations = {
    title: t('_LEGAL_DOCUMENTATION_EMPTY_MODAL_TITLE'),
    text: t('_LEGAL_DOCUMENTATION_EMPTY_MODAL_TEXT'),
    mainButtonText: t('_LEGAL_DOCUMENTATION_EMPTY_MODAL_MAIN_BUTTON_TEXT'),
    secondaryButtonText: t('_LEGAL_DOCUMENTATION_EMPTY_MODAL_SECONDARY_BUTTON_TEXT'),
  };

  const contentTranslations: LegalDocumentationContentTranslationsModel = {
    title: t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_TITLE'),
    termsOfServiceLabel: t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_TERMS_OF_SERVICE_LABEL'),
    privacyPolicyLabel: t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_PRIVACY_POLICY_LABEL'),
    mainButtonText: t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_MAIN_BUTTON_TEXT'),
    deleteButtonText: t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_DELETE_BUTTON_TEXT'),
    secondaryButtonText: t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_SECONDARY_BUTTON_TEXT'),
    uploadButtonText: t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_UPLOAD_BUTTON_TEXT'),
    termsOfServiceRequirements: `${t(
      '_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_TERMS_OF_SERVICE_REQUIREMENT_SIZE_TEXT'
    )}: ${MAXIMUM_FILE_SIZE}MB \n${t(
      '_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_TERMS_OF_SERVICE_REQUIREMENT_FORMATS_TEXT'
    )}: ${transformedAcceptedTypes}`,
  };

  const deleteConfirmationTranslations = {
    title: t('_LEGAL_DOCUMENTATION_DELETE_CONFIRM_MODAL_TITLE'),
    text: t('_LEGAL_DOCUMENTATION_DELETE_CONFIRM_MODAL_TEXT'),
    mainButtonText: t('_LEGAL_DOCUMENTATION_DELETE_CONFIRM_MODAL_MAIN_BUTTON_TEXT'),
    secondaryButtonText: t('_LEGAL_DOCUMENTATION_DELETE_CONFIRM_MODAL_SECONDARY_BUTTON_TEXT'),
  };

  const disableConfirmationTranslations = {
    title: t('_LEGAL_DOCUMENTATION_DISABLE_MODAL_TITLE'),
    text: t('_LEGAL_DOCUMENTATION_DISABLE_MODAL_TEXT'),
    mainButtonText: t('_LEGAL_DOCUMENTATION_DISABLE_MODAL_MAIN_BUTTON_TEXT'),
    secondaryButtonText: t('_LEGAL_DOCUMENTATION_DISABLE_MODAL_SECONDARY_BUTTON_TEXT'),
  };

  const getInitialData = async (data: LegalDocumentationModel) => {
    const transformedLegalDocumentation = { ...data, termsOfServiceShowButtonVisible: true, privacyPolicyShowButtonVisible: true };
    setLegalDocumentation(transformedLegalDocumentation);
    setInitialLegalDocumentation(transformedLegalDocumentation);
    setTemplateVisor(LegalDocumentationStateType.CONTENT);
    setIsDeleteButtonVisible(true);
    setIsSaveButtonDisabled(true);
    setEnabledSwitch(data.enabled);
  };

  const getInitialEmptyData = async () => {
    setLegalDocumentation(INITIAL_LEGAL_DOCUMENTATION);
    setInitialLegalDocumentation(INITIAL_LEGAL_DOCUMENTATION);
    setTemplateVisor(LegalDocumentationStateType.EMPTY);
    setIsDeleteButtonVisible(false);
    setIsSaveButtonDisabled(true);
    setEnabledSwitch(false);
    form.resetFields();
  };

  const getLegalDocumentation = async () => {
    setIsLoading(true);
    try {
      const legalDocumentationList = await GetLegalDocumentationListByClientId(clientId);
      !!legalDocumentationList && !!legalDocumentationList.length && getInitialData(legalDocumentationList[0]);
      (!legalDocumentationList || !legalDocumentationList.length) && getInitialEmptyData();
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCreate = () => {
    setIsCreateModalVisible(true);
    setEnabledSwitch(true);
  };

  const onCreateConfirmation = () => {
    setTemplateVisor(LegalDocumentationStateType.CONTENT);
  };

  const onCloseCreateModal = () => {
    setIsCreateModalVisible(false);
    setEnabledSwitch(false);
  };

  const onSaveForm = async () => {
    const formattedLegalDocumentation: LegalDocumentationCreateModel = { ...legalDocumentation };
    setIsProcessing(true);
    try {
      await CreateLegalDocumentation({ clientId, legalDocumentation: formattedLegalDocumentation });
      messageAtom.success(t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_SAVE_SUCCESS_MESSAGE'), 3);
      forceLogout();
    } catch (error) {
      console.warn(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const onDeleteForm = () => {
    setDeleteConfirmationModalVisible(true);
  };

  const onCloseDeleteConfirmationModal = () => {
    setDeleteConfirmationModalVisible(false);
  };

  const onConfirmDeleteForm = async () => {
    setIsProcessing(true);
    const legalDocumentation: LegalDocumentationCreateModel = {
      enabled: true,
      privacyPolicy: null,
      privacyPolicyName: null,
      termsOfService: null,
      termsOfServiceName: null,
    };
    try {
      await DeleteLegalDocumentation({ clientId, legalDocumentation });
      setDeleteConfirmationModalVisible(false);
      messageAtom.success(t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_DELETE_SUCCESS_MESSAGE'), 3);
      forceLogout();
    } catch (error) {
      console.warn(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const onEnable = async () => {
    setEnabledSwitch(true);
    setIsCreateModalVisible(true);
  };

  const onConfirmUpdate = async () => {
    const formattedLegalDocumentation: LegalDocumentationModel = { ...legalDocumentation, enabled: true };
    try {
      const response = await CreateLegalDocumentation({ clientId, legalDocumentation: formattedLegalDocumentation });
      if (response !== undefined) {
        messageAtom.success(t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_ENABLE_SUCCESS_MESSAGE'), 3);
        forceLogout();
      }
      response === undefined && setEnabledSwitch(false);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const onConfirmEnable = async () => {
    setIsCreateModalVisible(false);
    hasLegalDocumentationContent ? onConfirmUpdate() : onCreateConfirmation();
  };

  const onDisable = async () => {
    setDisableConfirmationModalVisible(true);
  };

  const onCloseConfirmDisable = () => {
    setDisableConfirmationModalVisible(false);
  };

  const onDisableEmptyContent = () => {
    setTemplateVisor(LegalDocumentationStateType.EMPTY);
  };

  const onDisableContent = async () => {
    setIsProcessing(true);
    const formattedLegalDocumentation: LegalDocumentationModel = { ...legalDocumentation, enabled: false };
    try {
      const response = await CreateLegalDocumentation({ clientId, legalDocumentation: formattedLegalDocumentation });
      if (response !== undefined) {
        messageAtom.success(t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_DISABLE_SUCCESS_MESSAGE'), 3);
        setDisableConfirmationModalVisible(false);
        forceLogout();
      }
      response === undefined && setEnabledSwitch(true);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const onConfirmDisable = async () => {
    setEnabledSwitch(false);
    setDisableConfirmationModalVisible(false);
    hasLegalDocumentationContent ? onDisableContent() : onDisableEmptyContent();
  };

  const onCloseForm = () => {
    setLegalDocumentation(initialLegalDocumentation);
    setIsSaveButtonDisabled(true);
  };

  const changeTermsOfServiceData = ({ base64File, name }: LegalDocumentationChangeValuesModel) => {
    const newLegalDocumentation: CustomLegalDocumentationModel = {
      ...legalDocumentation,
      termsOfService: base64File,
      termsOfServiceName: name,
      termsOfServiceShowButtonVisible: false,
    };
    setLegalDocumentation(newLegalDocumentation);
    !!legalDocumentation.privacyPolicy && setIsSaveButtonDisabled(false);
  };

  const changePrivacyPolicyData = ({ base64File, name }: LegalDocumentationChangeValuesModel) => {
    const newLegalDocumentation: CustomLegalDocumentationModel = {
      ...legalDocumentation,
      privacyPolicy: base64File,
      privacyPolicyName: name,
      privacyPolicyShowButtonVisible: false,
    };
    setLegalDocumentation(newLegalDocumentation);
    !!legalDocumentation.termsOfService && setIsSaveButtonDisabled(false);
  };

  const legalDocumentationChangesManager = {
    [LegalDocumentationFormValuesNames.TERMS_OF_SERVICE]: ({ base64File, name }: LegalDocumentationChangeValuesModel) =>
      changeTermsOfServiceData({ base64File, name }),
    [LegalDocumentationFormValuesNames.PRIVACY_POLICY]: ({ base64File, name }: LegalDocumentationChangeValuesModel) =>
      changePrivacyPolicyData({ base64File, name }),
  };

  const onChangeValueForm = async ({ label, file }: LegalDocumentationFormChangesModel) => {
    const formattedFile = file as unknown as UploadFile;
    const base64File = (await fileToBase64(formattedFile)) as string;
    legalDocumentationChangesManager[label]({ base64File, name: file.name });
  };

  const deleteTermsOfServiceData = () => {
    const newLegalDocumentation = { ...legalDocumentation, termsOfService: '', termsOfServiceName: '' };
    setLegalDocumentation(newLegalDocumentation);
  };

  const deletePrivacyPolicyData = () => {
    const newLegalDocumentation = { ...legalDocumentation, privacyPolicy: '', privacyPolicyName: '' };
    setLegalDocumentation(newLegalDocumentation);
  };

  const legalDocumentationActionsManager = {
    [LegalDocumentationActionsNames.DELETE_TERMS_OF_SERVICE]: () => deleteTermsOfServiceData(),
    [LegalDocumentationActionsNames.DELETE_PRIVACY_POLICY]: () => deletePrivacyPolicyData(),
    [LegalDocumentationActionsNames.SHOW_TERMS_OF_SERVICE]: () => openFile(legalDocumentation.termsOfService),
    [LegalDocumentationActionsNames.SHOW_PRIVACY_POLICY]: () => openFile(legalDocumentation.privacyPolicy),
  };

  const onChangeActionForm = (label: LegalDocumentationActionsNames) => {
    legalDocumentationActionsManager[label]();
  };

  const onCheckBeforeUploadValueForm = (file: RcFile) => {
    const { size: fileSize, type: fileType } = file;
    const formatMessageErrorText = `${t(
      '_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_UPLOAD_FORMAT_ERROR_MESSAGE'
    )} ${ACCEPTED_FORMAT_TYPES.join(', ')}!`;
    const sizeMessageErrorText = `${t('_LEGAL_DOCUMENTATION_CONTENT_TEMPLATE_FORM_UPLOAD_SIZE_ERROR_MESSAGE')} ${MAXIMUM_FILE_SIZE}MB!`;
    const isFileFormatCorrect = checkFileFormat({
      fileType,
      acceptedTypes: ACCEPTED_BASE64_FORMAT_TYPES,
      messageText: formatMessageErrorText,
    });
    const isFileSizeUnderLimit = checkFileSize({ fileSize, limitSize: MAXIMUM_FILE_SIZE, messageText: sizeMessageErrorText });
    return isFileFormatCorrect && isFileSizeUnderLimit;
  };

  useEffect(() => {
    getLegalDocumentation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    form,
    acceptedFormatFiles,
    templateVisor,
    legalDocumentation,
    emptyTemplateTranslations,
    contentTranslations,
    emptyModalTranslations,
    deleteConfirmationTranslations,
    disableConfirmationTranslations,
    onCreate,
    onCloseCreateModal,
    onEnable,
    onDisable,
    onSaveForm,
    onCloseForm,
    onCloseDeleteConfirmationModal,
    onDeleteForm,
    onConfirmDeleteForm,
    onChangeValueForm,
    onChangeActionForm,
    onCheckBeforeUploadValueForm,
    onCloseConfirmDisable,
    onConfirmDisable,
    onConfirmEnable,
    loading: isLoading,
    enabledSwitch,
    createModalVisible: isCreateModalVisible,
    processing: isProcessing,
    termsOfServiceUploadButtonDisabled: isTermsOfServiceUploadButtonDisabled,
    termsOfServiceUploadInfoVisible: isTermsOfServiceUploadInfoVisible,
    privacyPolicyUploadButtonDisabled: isPrivacyPolicyUploadButtonDisabled,
    privacyPolicyUploadInfoVisible: isPrivacyPolicyUploadInfoVisible,
    saveButtonDisabled: isSaveButtonDisabled,
    deleteButtonVisible: isDeleteButtonVisible,
    deleteConfirmationModalVisible,
    disableConfirmationModalVisible,
    isDefault: isLegalDocumentationDefaultValue,
  };
};
