import { FC } from 'react'
import { Button, Modal } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { FormInstance } from 'antd/es/form/Form';
import ClientEditForm from 'components/molecules/ClientEditForm';
import { ClientEditFormTranslations, ClientEditModalTranslations } from 'hooks/useClient/useClientTranslations';
import { ClientEditFormValues, ClientEditFormValuesNames } from 'hooks/useClient/models';

interface Props extends ModalProps {
  modalTranslations: ClientEditModalTranslations;
  formTranslations: ClientEditFormTranslations;
  form: FormInstance<ClientEditFormValues>;
  onCancelEditForm: () => void;
  onChangeEditFormValues: (values: { [key in ClientEditFormValuesNames]: string }) => void;
  onSubmitEditForm: (values: ClientEditFormValues) => void;
  onSubmitEditFormFailed: () => void;
  editing: boolean;
  disabled: boolean;
}

const ClientEditModal: FC<Props> = ({
  modalTranslations,
  formTranslations,
  form,
  onCancelEditForm,
  onChangeEditFormValues,
  onSubmitEditForm,
  onSubmitEditFormFailed,
  editing,
  disabled,
  ...props
}) => {
  const { title, editButton, cancelButton } = modalTranslations;

  return (
    <Modal
      {...props}
      forceRender
      title={title}
      onCancel={onCancelEditForm}
      footer={[
        <Button key={'back'} onClick={onCancelEditForm}>
          {cancelButton}
        </Button>,
        <Button key={'submit'} type={'primary'} loading={editing} onClick={() => form.submit()} disabled={disabled}>
          {editButton}
        </Button>,
      ]}
      destroyOnClose
      getContainer={false}
    >
      <ClientEditForm
        form={form}
        formTranslations={formTranslations}
        onFinish={onSubmitEditForm}
        onFinishFailed={onSubmitEditFormFailed}
        onChange={onChangeEditFormValues}
        editing={editing}
      />
    </Modal>
  );
}

export default ClientEditModal