import { FC } from 'react';
import DrawerAtom from 'components/atoms/DrawerAtom';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import TextAtom from 'components/atoms/TextAtom';
import { Device } from 'models/devices.model';
import { SPACING } from 'constants/spacing';
import { Form, message } from 'antd';
import { COLORS } from 'constants/colors';
import { useWindowSize } from 'hooks/useWindowSize';
import { useGateway } from 'hooks/useGateway';
import { ReportMaintenance, MaintenanceAction } from 'core/domain/gateways/model/reportMaintenance';
import MaintenanceActionsForm, { MaintenanceActionsFormValues } from 'components/molecules/MaintenanceActionsForm';
import './styles.scss';

interface AddMaintenanceActionDrawerProps {
  onClose: () => void;
  visible: boolean;
  deviceData?: Device;
  gatewayId: string;
}

const LARGE_SCREEN = 768;

const GATEWAY_MAINTENANCE_ACTIONS: MaintenanceAction[] = [
  MaintenanceAction.BATTERY_REPLACEMENT,
  MaintenanceAction.REPLACEMENT,
  MaintenanceAction.ADDED_DEVICE,
  MaintenanceAction.REMOVED_DEVICE,
  MaintenanceAction.SOFTWARE_UPDATE,
  MaintenanceAction.CLEANING,
  MaintenanceAction.ON_SITE_CHECK,
  MaintenanceAction.REMOTE_CHECK,
  MaintenanceAction.CALL_ATTENDED,
  MaintenanceAction.MANAGEMENT_PASSWORD,
];

const AddMaintenanceActionDrawer: FC<AddMaintenanceActionDrawerProps> = ({ onClose, visible, deviceData, gatewayId }) => {
  const [form] = Form.useForm<MaintenanceActionsFormValues>();
  const { t } = useTranslation();
  const { width: windowsWidth } = useWindowSize();
  const { onSaveAction, loading } = useGateway();

  const isLargeScreen = windowsWidth >= LARGE_SCREEN;

  const handleOnFinish = async ({ action, comments, batteryVoltage, batteryVendor }: MaintenanceActionsFormValues) => {
    const newAction: ReportMaintenance = {
      deviceId: deviceData?.device_id || null,
      action: action,
      deviceType: deviceData?.device_type || null,
      createdAt: null,
      batteryVoltage,
      batteryVendor,
      comments,
    };
    try {
      await onSaveAction(gatewayId, newAction);
      message.success(t('successfully_added'), 3);
      handleOnClose();
    } catch {
      message.error(t('add_error'), 3);
    }
  };

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <DrawerAtom
      headerStyle={{ padding: `${SPACING.l} ${SPACING.xl}` }}
      title={<TextAtom style={{ color: COLORS.text }}>{t('maintenance_actions')}</TextAtom>}
      destroyOnClose={true}
      closable={false}
      size={isLargeScreen ? 'large' : 'default'}
      placement={'right'}
      onClose={handleOnClose}
      visible={visible}
    >
      <div className={'icon__wrapper'}>
        <CloseOutlined className={'icon__close'} onClick={handleOnClose} />
      </div>
      <MaintenanceActionsForm
        form={form}
        onFinish={handleOnFinish}
        onCancel={handleOnClose}
        availableActions={deviceData?.available_actions || GATEWAY_MAINTENANCE_ACTIONS}
        loading={loading}
      />
    </DrawerAtom>
  );
};

export default AddMaintenanceActionDrawer;
