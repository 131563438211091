import { FC } from 'react';
import { Pagination } from 'antd';
import TableAtom from 'components/atoms/TableAtom';
import { AccessDeviceModel } from 'core/domain/access/models';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import Paper from 'components/atoms/Paper';
import { TABLE_PAGE_SIZE_DEFAULT_OPTIONS, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import './styles.scss';

interface Props {
  accessDevicesList: AccessDeviceModel[];
  columns: AlfredTableColumn<AccessDeviceModel>[];
  loading: boolean;
  page: number;
  size: number;
  totalPages: number;
  onChangePage: (page: number, pageSize: number) => void;
}

const MAX_SIZE_RESPONSIVE_SCREEN = 600;

const AccessDevicesListTemplate: FC<Props> = ({
  accessDevicesList,
  columns,
  page,
  size,
  totalPages,
  onChangePage,
  loading,
}) => {
  return (
    <Paper className={'AccessDevicesListTemplate__wrapper'}>
      <TableAtom<AccessDeviceModel>
        loading={loading}
        columns={columns}
        dataSource={accessDevicesList}
        rowKey='id'
        pagination={false}
        scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        onRow={(item) => {
          return {
            onClick: () => { },
          };
        }}
      />
      <Pagination
        className={'AccessDevicesListTemplate__pagination'}
        size={'small'}
        showSizeChanger={true}
        defaultPageSize={Number(TABLE_PAGINATION_SIZE_DEFAULT)}
        pageSizeOptions={TABLE_PAGE_SIZE_DEFAULT_OPTIONS}
        current={page}
        total={size * totalPages}
        pageSize={size}
        onChange={onChangePage}
      />
    </Paper>
  );
};

export default AccessDevicesListTemplate;
