import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import AlfredTableTemplate from '../AlfredTableTemplate';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';
import './styles.scss';

interface Props {
  list: ProjectAssetModel[];
  columns: AlfredTableColumn<ProjectAssetModel>[]
  totalPages: number;
  pageSize: number;
  currentPage: number;
  onChangePage: (page: number, pageSize?: number | undefined) => void;
  onSearch: (value: string) => void;
  isLoading: boolean;
}

const AccessRoomListTemplate: FC<Props> = ({
  list,
  columns,
  totalPages,
  pageSize,
  currentPage,
  onChangePage,
  onSearch,
  isLoading,
}) => {
  return (
    <Paper className={'AccessRoomListTemplate__wrapper'}>
      <AlfredTableTemplate<ProjectAssetModel>
        columns={columns}
        data={list}
        totalPages={totalPages}
        pageSize={pageSize}
        pageCurrent={currentPage}
        onPagination={onChangePage}
        onSearch={onSearch}
        isCharging={isLoading}
      />
    </Paper>
  )
}

export default AccessRoomListTemplate;