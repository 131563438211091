import { FC, ReactNode } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { SupportTicketTypeType } from 'core/domain/supportTickets/models/supportTicketsPayloadModel';
import './styles.scss';

interface AvatarUserProps {
  icon?: ReactNode;
  color?: string;
  letter?: string;
  size?: number | 'small' | 'large' | 'default';
  type?: SupportTicketTypeType;
}

const AvatarUser: FC<AvatarUserProps> = ({ icon, color, letter, size, type }) => (
  <Avatar size={size} className={'AvatarUser__avatar'} icon={letter ? undefined : icon ? icon : <UserOutlined />} style={{ color }}>
    {letter}
  </Avatar>
);

export default AvatarUser;
