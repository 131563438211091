import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { AssetInAssetDashboard, RegisterAssetAttributes } from 'models/assets.model';
import AssetEditForm from 'components/molecules/AssetEditForm';
import { useAssetType } from 'hooks/useAssetType';

const FORM_ID = 'form_assetEdit';

interface AssetEditModalProps extends ModalProps {
  asset: AssetInAssetDashboard;
  onSubmit: (values: RegisterAssetAttributes) => Promise<void>;
  loading: boolean;
}

const AssetEditModal: FC<AssetEditModalProps> = ({ asset, onCancel, onSubmit, loading, ...props }) => {
  const { t } = useTranslation();
  const [isBlocked, setIsBlocked] = useState<boolean>(asset?.is_blocked || false);
  const [form] = Form.useForm<RegisterAssetAttributes>();
  const { data } = useAssetType();

  useEffect(() => {
    form.setFieldsValue({
      is_blocked: isBlocked
    })
  }, [isBlocked, form])

  const setInitialFormValues = useCallback(() => {
    form.setFieldsValue({
      alias: asset?.alias || '',
      street_type: asset?.street_type || '',
      street_name: asset?.street_name || '',
      block_number: asset?.block_number || '',
      staircase: asset?.staircase || '',
      flat: asset?.flat || '',
      door: asset?.door || '',
      postal_code: asset?.zip_code || '',
      city: asset?.city || '',
      province: asset?.state || '',
      country: asset?.country || '',
      asset_type: asset?.type,
      description: asset?.description || '',
    });
  }, [asset, form]);

  useEffect(() => {
    setInitialFormValues();
  }, [setInitialFormValues]);

  const handleOnFinish = async (values: RegisterAssetAttributes) => {
    await onSubmit(values);
  };

  const handleOnCancel = (event: any) => {
    setInitialFormValues();
    onCancel && onCancel(event);
  };

  const handleOnIsBlocked = (isBlocked: boolean) => setIsBlocked(isBlocked)

  return (
    <Modal
      {...props}
      title={t('edit_asset')}
      onCancel={handleOnCancel}
      footer={[
        <Button key={'back'} onClick={handleOnCancel}>
          {t('to_cancel')}
        </Button>,
        <Button key={'submit'} type={'primary'} loading={loading} htmlType={'submit'} form={FORM_ID}>
          {t('edit')}
        </Button>,
      ]}
      destroyOnClose
      getContainer={false}
    >
      <AssetEditForm
        form={form}
        id={FORM_ID}
        onFinish={handleOnFinish}
        assetTypesList={data}
        isBlocked={isBlocked}
        onBlocked={handleOnIsBlocked}
      />
    </Modal>
  );
};

export default AssetEditModal;
