import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageErrorModel, useMessage } from 'hooks/useMessage';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useAssetType } from 'hooks/useAssetType';
import { Form, FormInstance } from 'antd';
import { GetClientsList } from 'core/domain/client/repositories/getClientsList';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { GetProjectsListByClientId } from 'core/domain/project/repositories/projectsCRUD';
import { useQrScanner } from './useQrScanner';
import { useDidScanner } from './useDidScanner';
import { useCreateClient } from './useCreateClient';
import { useQrError } from './useQrError';
import { CreateAsset } from 'core/domain/assets/repositories/createAsset';
import { RegisterAssetModel } from 'core/domain/assets/models';
import { useHistory } from 'react-router-dom';
import { getAssetPathById } from 'components/pages/App/routes/assets/config';
import { CreateAssetConfirmationLiteralsModel } from 'components/organisms/CreateAssetConfirmationModal';
import {
  CreateAssetDrawerLiteralsType,
  CreateAssetFormValues,
  CreateAssetFormValuesNames,
} from 'components/organisms/CreateAssetDrawer/models';

export interface CreateAssetDrawerOptionsModel {
  form: FormInstance<CreateAssetFormValues>;
  drawerLiterals: CreateAssetDrawerLiteralsType;
  clients: ClientModel[];
  projects: string[];
  newProject: string | undefined;
  assetTypesList: string[];
  streetTypes: string[];
  confirmationLiterals: CreateAssetConfirmationLiteralsModel;
  onOpenCreateAssetDrawer: () => void;
  onCloseCreateAssetDrawer: () => void;
  onCreateAsset: (asset: CreateAssetFormValues) => Promise<void>;
  onGetNewProject: (value: string) => void;
  translateAssetType: (value: string) => string;
  getProjectsByClientId: (clientId: string) => Promise<void>;
  onCreateProject: () => void;
  onCloseQrErrorsModal: () => void;
  onCloseCreateAssetConfirmationModal: () => void;
  onAcceptConfirmationModal: () => void;
  drawerVisible: boolean;
  creating: boolean;
  loadingClients: boolean;
  loadingProjects: boolean;
  projectsInputEnabled: boolean;
  confirmationModalVisible: boolean;
}

enum CreateAssetResponseErrorType {
  DUPLICATED_DID = 409,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
}

enum CreateAssetBadRequestType {
  QID_ALREADY_USED = 'QR gateway (already used)',
  QID_NOT_VALID = 'QR Gateway Id (invalid checksum) is not valid.',
}

export const useCreateAsset = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const [form] = Form.useForm<CreateAssetFormValues>();
  const { setMessageError, setMessageWarning } = useMessage();
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [loadingClients, setLoadingClients] = useState<boolean>(false);
  const [projects, setProjects] = useState<string[]>([]);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);
  const [projectsInputEnabled, setProjectsInputEnabled] = useState<boolean>(false);
  const [newProject, setNewProject] = useState<string>();
  const [successCreatingAsset, setSuccessCreatingAsset] = useState<boolean>(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false);
  const { data: assetTypesList } = useAssetType();
  const { qrScannerOptions, qid } = useQrScanner();
  const { didScannerOptions, did } = useDidScanner();
  const { clientOptions, successCreatingClient } = useCreateClient();
  const { qrErrorModalOptions, checkIfQrErrors, resetQrErrorsValues, onCloseQrErrorModal, errorsChecked } = useQrError();

  const { qidError, didError } = qrErrorModalOptions;

  const streetTypes = [t('street'), t('alleyway'), t('passageway'), t('road'), t('round'), t('ride'), t('square'), t('avenue')];

  const literals: CreateAssetDrawerLiteralsType = {
    title: t('new_asset'),
    closeButtonText: t('close'),
    createAssetButtonText: t('save'),
    qidPlaceholder: 'QID',
    qidLabel: t('_REGISTER_ASSET_FORM_QID_LABEL'),
    qidMinimumLengthText: t('_REGISTER_ASSET_FORM_QID_MINIMUM_LENGTH_TEXT'),
    qidMaximumLengthText: t('_REGISTER_ASSET_FORM_QID_MAXIMUM_LENGTH_TEXT'),
    didLabel: t('_REGISTER_ASSET_FORM_DID_LABEL'),
    didMinimumLengthText: t('_REGISTER_ASSET_FORM_DID_MINIMUM_LENGTH_TEXT'),
    didMaximumLengthText: t('_REGISTER_ASSET_FORM_DID_MAXIMUM_LENGTH_TEXT'),
    didPlaceholder: 'DID*',
    assetDescription: t('_REGISTER_ASSET_FORM_ASSET_DESCRIPTION'),
    clientPlaceholder: t('select_company'),
    createClientButtonText: t('company_new'),
    projectPlaceholder: t('select_community'),
    createProjectButtonText: t('add_community'),
    aliasPlaceholder: t('deployment_alias'),
    typePlaceholder: `${t('asset_type')}*`,
    streetTypePlaceholder: `${t('street_type')}*`,
    streetNamePlaceholder: `${t('street_name')}*`,
    blockNumberPlaceholder: `${t('block_number')}*`,
    staircasePlaceholder: t('stairway'),
    flatPlaceholder: t('storey'),
    doorPlaceholder: t('door'),
    postalCodePlaceholder: `${t('zip_code')}*`,
    cityPlaceholder: `${t('city')}*`,
    provincePlaceholder: `${t('province')}*`,
    countryPlaceholder: `${t('country')}*`,
    descriptionPlaceholder: t('description'),
    requiredFieldText: t('field_required'),
  };

  const confirmationLiterals = {
    text: t('registered_correctly'),
    primaryButtonText: t('view_assets'),
    secondaryButtonText: t('close'),
  };

  const resetValues = () => {
    setProjectsInputEnabled(false);
    setNewProject(undefined);
    setSuccessCreatingAsset(false);
    form.resetFields();
  };

  const onOpenCreateAssetDrawer = () => {
    setIsDrawerVisible(true);
  };

  const onCloseCreateAssetDrawer = () => {
    resetValues();
    setIsDrawerVisible(false);
  };

  const translateAssetType = (value: string): string => {
    return t(value);
  };

  const onGetNewProject = (value: string) => {
    setNewProject(value);
  };

  const onCreateProject = () => {
    if (!!newProject) {
      const transformedProject = newProject.trim();
      const projectAlreadyExists = projects.some((name) => name.toUpperCase() === transformedProject.toUpperCase());

      if (projectAlreadyExists) {
        setMessageWarning({ description: t('add_community_error') });
        return;
      }

      const newProjects = [...projects];
      newProjects.unshift(transformedProject);
      setProjects(newProjects);
      setNewProject(undefined);
    }
  };

  const checkForQrsErrors = () => {
    const qidFormValue = form.getFieldValue(CreateAssetFormValuesNames.QID);
    const didFormValue = form.getFieldValue(CreateAssetFormValuesNames.DID);
    checkIfQrErrors({ qid: qidFormValue, did: didFormValue });
  };

  const onCloseQrErrorsModal = () => {
    onCloseQrErrorModal();
    setCreating(false);
  };

  const transformValuesToBeSend = (): RegisterAssetModel => {
    const {
      did,
      clientId,
      community,
      alias,
      type,
      qid,
      streetType,
      streetName,
      blockNumber,
      staircase,
      flat,
      door,
      city,
      postalCode,
      province,
      country,
      description,
    } = form.getFieldsValue();
    return {
      id: did ?? '',
      clientId: clientId ?? '',
      community: community ?? '',
      alias,
      type: type ?? '',
      streetType: streetType ?? '',
      streetName: streetName ?? '',
      blockNumber: blockNumber ?? '',
      staircase,
      flat,
      door,
      city: city ?? '',
      postalCode: postalCode ?? '',
      province: province ?? '',
      country: country ?? '',
      description,
      qrGatewayId: qid,
    };
  };

  const checkQidDidError = (isQidError: boolean): string => {
    return isQidError ? t('_REGISTER_ASSET_ERROR_QID_NOT_VALID') : t('_REGISTER_ASSET_ERROR_DID_NOT_VALID');
  };

  const checkForBadRequestOtherErrors = (error: string): string => {
    return error.includes(CreateAssetBadRequestType.QID_ALREADY_USED)
      ? t('_REGISTER_ASSET_ERROR_QID_ALREADY_IN_USED')
      : t('_REGISTER_ASSET_ERROR_MESSAGE');
  };

  const checkBadRequestError = (error: string): string => {
    const didFormValue = form.getFieldValue(CreateAssetFormValuesNames.DID);
    const isQidError = error.includes(CreateAssetBadRequestType.QID_NOT_VALID);
    const isDidError = error.includes(didFormValue);
    return isQidError || isDidError ? checkQidDidError(isQidError) : checkForBadRequestOtherErrors(error);
  };

  const responseErrorManager = {
    [CreateAssetResponseErrorType.BAD_REQUEST]: checkBadRequestError,
    [CreateAssetResponseErrorType.NOT_FOUND]: () => t('_REGISTER_ASSET_ERROR_DID_NOT_FOUND'),
    [CreateAssetResponseErrorType.DUPLICATED_DID]: () => t('_REGISTER_ASSET_ERROR_DUPLICATED_DID'),
  };

  const checkError = (error: MessageErrorModel) => {
    const { code, message } = error;
    const errorMessage = (message as any).errors.detail;
    const genericErrorMessage = t('_REGISTER_ASSET_ERROR_MESSAGE');
    const isControlledError =
      code === CreateAssetResponseErrorType.BAD_REQUEST ||
      code === CreateAssetResponseErrorType.NOT_FOUND ||
      code === CreateAssetResponseErrorType.DUPLICATED_DID;
    const response = isControlledError ? responseErrorManager[code as CreateAssetResponseErrorType](errorMessage) : genericErrorMessage;
    setMessageError({ description: response });
  };

  const onCloseCreateAssetConfirmationModal = () => {
    setConfirmationModalVisible(false);
    resetValues();
  };

  const onAcceptConfirmationModal = () => {
    const formDid = form.getFieldValue(CreateAssetFormValuesNames.DID);
    history.push(getAssetPathById(formDid));
    resetValues();
  };

  const onCreateAsset = async () => {
    setCreating(true);
    checkForQrsErrors();
  };

  const onConfirmCreateAsset = async () => {
    const asset = transformValuesToBeSend();
    try {
      await CreateAsset({ host, token, asset });
      setSuccessCreatingAsset(true);
      resetQrErrorsValues();
      setIsDrawerVisible(false);
      setConfirmationModalVisible(true);
    } catch (error) {
      checkError(error as MessageErrorModel);
    } finally {
      setCreating(false);
    }
  };

  const getProjectsByClientId = async (clientId: string) => {
    setLoadingProjects(true);
    form.setFieldsValue({ community: null });

    try {
      const { data } = await GetProjectsListByClientId(clientId);
      const transformedProjects = data.map(({ name }) => name);
      setProjects(transformedProjects);
    } catch (error) {
      console.error(error);
    } finally {
      setProjectsInputEnabled(true);
      setLoadingProjects(false);
    }
  };

  const getClientsList = async () => {
    setLoadingClients(true);
    try {
      const response = await GetClientsList();
      if (!!response) {
        setClients(response);
        response.length === 1 && form.setFieldsValue({ clientId: response[0].id });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClients(false);
    }
  };

  const setProjectsFormValue = (projects: string[]) => {
    const community = projects.length >= 1 ? projects[0] : null;
    form.setFieldsValue({ community });
  };

  useEffect(() => {
    const didFormValue = form.getFieldValue(CreateAssetFormValuesNames.DID);
    errorsChecked && !qidError && !didError && !!didFormValue && onConfirmCreateAsset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qidError, didError, errorsChecked]);

  useEffect(() => {
    setProjectsFormValue(projects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    !!qid && form.setFieldsValue({ qid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qid]);

  useEffect(() => {
    !!did && form.setFieldsValue({ did });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [did]);

  useEffect(() => {
    !!successCreatingClient && getClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCreatingClient]);

  useEffect(() => {
    getClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createAssetDrawerOptions: CreateAssetDrawerOptionsModel = {
    form,
    drawerLiterals: literals,
    clients,
    projects,
    newProject,
    assetTypesList,
    streetTypes,
    confirmationLiterals,
    onOpenCreateAssetDrawer,
    onCloseCreateAssetDrawer,
    onCreateAsset,
    translateAssetType,
    getProjectsByClientId,
    onGetNewProject,
    onCreateProject,
    onCloseQrErrorsModal,
    onCloseCreateAssetConfirmationModal,
    onAcceptConfirmationModal,
    drawerVisible: isDrawerVisible,
    creating,
    loadingClients,
    loadingProjects,
    projectsInputEnabled,
    confirmationModalVisible,
  };

  return {
    createAssetDrawerOptions,
    qrScannerOptions,
    didScannerOptions,
    clientOptions,
    qrErrorModalOptions,
    successCreatingAsset,
  };
};
