import { FC } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import Paper from 'components/atoms/Paper';
import { ProjectIntercomFormValues } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/config';
import ProjectIntercomCreateForm from 'components/organisms/ProjectIntercomCreateForm';
import { IntercomFormTranslations } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/translations';
import './styles.scss';

interface Props {
  intercomFormTranslations: IntercomFormTranslations;
  form: FormInstance<ProjectIntercomFormValues>;
  providers: DefaultOptionType[];
  onSubmitCreating: (values: ProjectIntercomFormValues) => void;
  onSubmitCreatingFailed: () => void;
  onClose: () => void;
  processing: boolean;
}

const ProjectIntercomsCreateTemplate: FC<Props> = ({
  intercomFormTranslations,
  form,
  providers,
  onSubmitCreating,
  onSubmitCreatingFailed,
  onClose,
  processing,
}) => {

  return (
    <Paper>
      <div className={'ProjectIntercomCreateTemplate__container'}>
        <ProjectIntercomCreateForm
          translations={intercomFormTranslations}
          form={form}
          providers={providers}
          onFinish={onSubmitCreating}
          onFinishFailed={onSubmitCreatingFailed}
          onClose={onClose}
          processing={processing}
        />
      </div>
    </Paper>
  );
}

export default ProjectIntercomsCreateTemplate;