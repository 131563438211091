export const getIntercomsListByProjectIdPath = (projectId: string) => {
  return `/projects/${projectId}/intercoms`;
};

export const createIntercomPath = () => {
  return `/intercoms`;
};

export const updateIntercomPath = (intercomId: string) => {
  return `/intercoms/${intercomId}`;
};

export const deleteIntercomPath = (intercomId: string) => {
  return `/intercoms/${intercomId}`;
};
