import { RcFile } from 'antd/lib/upload';
import { LegalDocumentationModel } from 'core/domain/client/models/clientEntityModel';

export enum LegalDocumentationStateType {
  EMPTY = 'empty',
  CONTENT = 'content',
  DISABLED_CONTENT = 'disableContent',
}

export interface LegalDocumentationEmptyTranslationsType {
  title: string;
  infoTitle: string;
  infoDescription: string;
  infoButtonText: string;
}

export interface LegalDocumentationContentTranslationsModel {
  title: string;
  termsOfServiceLabel: string;
  termsOfServiceRequirements: string;
  privacyPolicyLabel: string;
  mainButtonText: string;
  secondaryButtonText: string;
  deleteButtonText: string;
  uploadButtonText: string;
}

export enum LegalDocumentationActionsNames {
  SHOW_TERMS_OF_SERVICE = 'showTermsOfService',
  DELETE_TERMS_OF_SERVICE = 'deleteTermsOfService',
  SHOW_PRIVACY_POLICY = 'showPrivacyPolicy',
  DELETE_PRIVACY_POLICY = 'deletePrivacyPolicy',
}

export enum LegalDocumentationFormValuesNames {
  TERMS_OF_SERVICE = 'termsOfService',
  PRIVACY_POLICY = 'privacyPolicy',
}

export interface LegalDocumentationFormValues {
  [LegalDocumentationFormValuesNames.TERMS_OF_SERVICE]: string;
  [LegalDocumentationFormValuesNames.PRIVACY_POLICY]: string;
}

export interface LegalDocumentationFormChangesModel {
  label: LegalDocumentationFormValuesNames;
  file: RcFile;
}

export interface LegalDocumentationChangeValuesModel {
  base64File: string;
  name: string;
}

export interface CustomLegalDocumentationModel extends LegalDocumentationModel {
  termsOfServiceShowButtonVisible: boolean;
  privacyPolicyShowButtonVisible: boolean;
}
