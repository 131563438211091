import moment from 'moment';
import { Space } from 'antd';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import AsSupportTicketStatusSelect from 'components/molecules/AsSupportTicketStatusSelect';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT } from 'constants/date';
import AsSupportTicketSources from 'components/atoms/AsSupportTicketSources';
import AsSupportTicketsCategories from 'components/atoms/AsSupportTicketsCategories';
import TooltipAtom from 'components/atoms/TooltipAtom';
import { SupportTicketModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import { SupportTicketStatusType, SupportTicketTypeType } from 'core/domain/supportTickets/models/supportTicketsPayloadModel';
import { sortSupportTicketsByCategoryType, sortSupportTicketsByCreatedBy, sortSupportTicketsBySourceType, sortSupportTicketsByStatusType } from '../utils';
import { SearchOutlined } from '@ant-design/icons';
import { COLORS } from 'constants/colors';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { DataIndex } from 'hooks/useTechSupportList';
import { ColumnSearchTranslationsType, getColumnSearchProps } from './ColumnsSearchProps';
import './styles.scss';

interface Props {
  onUpdateStatus: ({ id, status, description }: { id: string; status: SupportTicketStatusType; description: string }) => void;
  loading: boolean;
  sourcesFilters?: { text: string, value: string }[];
  categoriesFilters?: { text: string, value: string }[];
  statusFilters?: { text: string, value: string }[];
  searchedColumn?: string;
  searchInput?: any;
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => void;
  columnSearchTranslations?: ColumnSearchTranslationsType;
}

export const TechSupportsColumns = ({
  onUpdateStatus,
  loading,
  sourcesFilters = [],
  categoriesFilters = [],
  statusFilters = [],
  onSearch,
  onReset,
  searchedColumn = '',
  searchInput,
  columnSearchTranslations,
}: Props): AlfredTableColumn<SupportTicketModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: `${t('source')}`,
      key: 'source',
      width: '10%',
      filters: sourcesFilters,
      onFilter: (value, { createdBy }) => createdBy.type.includes(value as string),
      sorter: (a, b) => sortSupportTicketsBySourceType(a, b),
      render: (text, { createdBy }) => <AsSupportTicketSources type={createdBy.type} showIcon={false} />,
    },
    {
      title: t('name'),
      key: 'name',
      width: '20%',
      dataIndex: 'title',
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      ...getColumnSearchProps({ dataIndex: 'title', onSearch, onReset, searchInput, searchedColumn, translations: columnSearchTranslations }),
    },
    {
      title: `${t('address')}`,
      key: 'address',
      dataIndex: 'address',
      width: '20%',
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      ...getColumnSearchProps({ dataIndex: 'address', onSearch, onReset, searchInput, searchedColumn, translations: columnSearchTranslations }),
    },
    {
      title: `${t('category')}`,
      key: 'category',
      width: '10%',
      filters: categoriesFilters,
      onFilter: (value, { category }) => category.includes(value as string),
      sorter: (a, b) => sortSupportTicketsByCategoryType(a, b),
      render: (text, { category }) => <AsSupportTicketsCategories category={category} showIcon={false} />,
    },
    {
      title: `${t('created_by')}`,
      key: 'createdBy',
      width: '10%',
      dataIndex: 'createdBy',
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      sorter: (a, b) => sortSupportTicketsByCreatedBy(a, b),
      ...getColumnSearchProps({ dataIndex: 'createdBy', onSearch, onReset, searchInput, searchedColumn, translations: columnSearchTranslations }),
      render: (text, { createdBy }) => (
        <div>
          {createdBy.type === SupportTicketTypeType.AUTOMATED && (
            <Space>
              <div className='TechSupportsColumns__cell'>
                <span>{'Gateway'}</span>
              </div>
            </Space>
          )}
          {createdBy.type === SupportTicketTypeType.MANUAL && (
            <TooltipAtom
              title={
                <>
                  <p>{t('name')}: {createdBy.name}</p>
                  <p>{t('email')}: {createdBy.email}</p>
                </>
              }
            >
              <Space>
                <div className='TechSupportsColumns__cell'>
                  <span>{createdBy.name}</span>
                </div>
              </Space>
            </TooltipAtom>
          )}
        </div>
      )
    },
    {
      title: `${t('created_at')}`,
      key: 'created_at',
      width: '10%',
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortDirections: ['descend', 'ascend'],
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      ...getColumnSearchProps({ dataIndex: 'createdAt', onSearch, onReset, searchInput, searchedColumn, translations: columnSearchTranslations }),
      render: (text, { createdAt }) => (
        <div>
          <Space>
            <div className='TechSupportsColumns__cell'>
              <span className='Text__date Text__secondary'>{moment.unix(Number(createdAt)).format(DATE_TIME_FORMAT)}</span>
            </div>
          </Space>
        </div>
      )
    },
    {
      title: t('status'),
      key: 'status',
      width: '10%',
      filters: statusFilters,
      onFilter: (value, { status }) => status.includes(value as string),
      sorter: (a, b) => sortSupportTicketsByStatusType(a, b),
      render: (text, { status, createdBy, id }) => (
        <div key={id} onClick={(event) => event.stopPropagation()}>
          <AsSupportTicketStatusSelect
            disabled={createdBy.type === SupportTicketTypeType.AUTOMATED}
            status={status}
            loading={loading}
            showIcon={false}
            onChange={(newStatus) => onUpdateStatus({ id, status: newStatus, description: '' })}
          />
        </div>
      ),
    },
  ];
};
