import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Divider, List, Spin, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { getSupportTicket } from 'services/api/supportTickets';
import { SupportTicketComment } from 'models/supportTickets.model';
import { DATE_TIME_COMPLETE_FORMAT } from 'constants/date';
import AsSupportTicketStatus from 'components/atoms/AsSupportTicketStatus';
import './styles.scss';

interface StatusHistoryProps {
  ticketId: string;
}

const NUMBER_ITEMS = 5;

const StatusListHistory: FC<StatusHistoryProps> = ({ ticketId }) => {
  const { t } = useTranslation();
  const [allComments, setAllComments] = useState<SupportTicketComment[]>([]);
  const [comments, setComments] = useState<SupportTicketComment[]>([]);
  const [loading, setLoading] = useState(true);
  const [visibleLoadMore, setVisibleLoadMore] = useState(true);
  const [numberItems, setNumberItems] = useState(NUMBER_ITEMS);

  useEffect(() => {
    if (allComments.length <= numberItems) setVisibleLoadMore(false);
    if (allComments.length > numberItems) setVisibleLoadMore(true);
  }, [allComments, numberItems]);

  useEffect(() => {
    setLoading(true);
    getSupportTicket(ticketId)
      .then(({ data: { comments } }) => {
        setAllComments(comments);
        setComments(comments.slice(0, NUMBER_ITEMS));
      })
      .finally(() => setLoading(false));
  }, [ticketId]);

  const handleOnLoadMore = async (numberItems: number) => {
    setComments(allComments.slice(0, numberItems));
    setNumberItems(numberItems);
  };

  return (
    <>
      <Divider orientation='left'>
        {`${t('status_history')} `}
        {loading ? <Spin /> : allComments.length <= NUMBER_ITEMS ? `(${allComments.length})` : `(${comments.length}/${allComments.length})`}
      </Divider>
      <List
        className='StatusListHistory__wrapper'
        loading={loading}
        itemLayout='horizontal'
        dataSource={comments}
        loadMore={
          visibleLoadMore ? (
            <div className='StatusListHistory__loadMore'>
              <Button loading={loading} onClick={() => handleOnLoadMore(numberItems + NUMBER_ITEMS)}>
                {t('load_more')}
              </Button>
            </div>
          ) : null
        }
        renderItem={(item, key) => (
          <List.Item key={key}>
            <List.Item.Meta
              title={
                <span>
                  {<UserOutlined />} {item.email}: {!!item.description.length ? item.description : t('no_comment')}
                </span>
              }
              description={
                <div className='StatusListHistory__footer'>
                  <span>
                    {t('status')}: <AsSupportTicketStatus status={item.status} />
                  </span>
                  <span>
                    {t('hour_date')}: {moment.unix(item.created_at).format(DATE_TIME_COMPLETE_FORMAT)}
                  </span>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default StatusListHistory;
