import { FC, ReactElement, ReactNode, useEffect } from 'react';
import { RouteConfig } from 'react-router-config';
import { ROUTE_PATH_ADMIN } from 'components/pages/App/configRoutes';
import { hasPermission } from 'services/permissions';
import { UserPermissions } from 'models/users.model';
import { useHistory } from 'react-router-dom';
import { CheckLegalDocumentation } from 'core/domain/client/repositories/checkLegalDocumentation';
import { useUserSession } from 'hooks/useUserSession';
import { ROUTE_PATH_LEGAL_DOCUMENTATION_CHECK } from 'components/pages/App/routes';

interface CheckRoutesPermissionProps {
  route: string;
  permission: UserPermissions;
}

enum PermissionRoute {
  ACADEMY = 'list_academy_docs',
  CHECK_IN = 'list_check_in',
  DIGITAL_SIGNAGE = 'list_digital_signage',
  PROVISIONED = 'register_gateways',
}

enum CheckingAllowedRoute {
  ACADEMY = 'academy',
  CHECK_IN = 'checkin',
  DIGITAL_SIGNAGE = 'digital-signage',
  PROVISIONED = 'provisioned',
}

interface NavigationGuardProps {
  routes: RouteConfig[];
  children: ReactNode;
}

const NavigationGuard: FC<NavigationGuardProps> = ({ routes, children }) => {
  const history = useHistory();
  const { checkIfUserIsLoggedIn } = useUserSession();

  const checkLegalDocumentationRoutes = async (currentPath: string): Promise<string> => {
    const isLoggedIn = checkIfUserIsLoggedIn();
    if (isLoggedIn) {
      const legalDocumentation = await CheckLegalDocumentation();
      const { isAccepted, isEnabled } = legalDocumentation;
      const completeUrl = window.location.href
      const routeName = completeUrl.split('/');
      return (!isAccepted && isEnabled && !!routeName[3] && routeName[3] === 'admin') || (!isAccepted && isEnabled && !routeName[3]) ? ROUTE_PATH_LEGAL_DOCUMENTATION_CHECK : currentPath
    }
    return currentPath;
  }

  const checkRoutePermissions = ({ route, permission }: CheckRoutesPermissionProps) => {
    return hasPermission(permission) ? route : ROUTE_PATH_ADMIN;
  }

  const allowedRoutesManager = {
    [CheckingAllowedRoute.ACADEMY]: (route: string): string => checkRoutePermissions({ route, permission: PermissionRoute.ACADEMY }),
    [CheckingAllowedRoute.CHECK_IN]: (route: string): string => checkRoutePermissions({ route, permission: PermissionRoute.CHECK_IN }),
    [CheckingAllowedRoute.DIGITAL_SIGNAGE]: (route: string): string => checkRoutePermissions({ route, permission: PermissionRoute.DIGITAL_SIGNAGE }),
    [CheckingAllowedRoute.PROVISIONED]: (route: string): string => checkRoutePermissions({ route, permission: PermissionRoute.PROVISIONED }),
  }

  const checkAllowedRoutes = ({ pathname, currentPath }: { pathname: string, currentPath: string }): string => {
    const routeName = pathname.split('/');
    const hasRouteToBeChecked = routeName.length >= 2 && !!allowedRoutesManager[routeName[2] as CheckingAllowedRoute];
    const allowedRoute = hasRouteToBeChecked ? allowedRoutesManager[routeName[2] as CheckingAllowedRoute](currentPath) : currentPath;
    return allowedRoute;
  }

  const checkRoutes = async () => {
    const queryParams = window.location.search;
    const pathname = window.location.pathname;
    const currentPath = !!queryParams ? `${pathname}${queryParams}` : pathname;

    const checkedRoute = await checkLegalDocumentationRoutes(currentPath);
    const allowedRoute = checkAllowedRoutes({ pathname, currentPath: checkedRoute });
    history.push(allowedRoute);
  }

  useEffect(() => {
    checkRoutes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  return children as ReactElement;
};

export default NavigationGuard;
