import { ClientModel } from '../models/clientEntityModel';
import ClientEntity from './clientEntity';

const ClientsListValueObject = (list: ClientModel[]): Readonly<{ list: Readonly<ClientModel>[] }> => {
  return Object.freeze({
    list: list.map((item: ClientModel) => ClientEntity(item)),
  });
};

export default ClientsListValueObject;
