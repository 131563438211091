import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { AssetDevicesModel, AssetDevicesResponse } from '../models';
import { payloadToAssetDevices } from '../mappers/payloadToAssetDevices';

const getUrl = ({ host, assetId, type }: { host: string; assetId: string; type?: string }): string => {
  const params = new URLSearchParams();
  type && params.append('type', type);
  const stringParams = type ? `?${params}` : '';

  return `${host}/assets/${assetId}/devices${stringParams}`;
};

export const GetAssetDevices = async ({
  host,
  token,
  assetId,
  type,
}: {
  host: string;
  token: string;
  assetId: string;
  type?: string;
}): Promise<AssetDevicesModel> => {
  try {
    const url = getUrl({ host, assetId, type });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<AssetDevicesResponse>;
    return payloadToAssetDevices(data.data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
