import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import ContextualMenu from 'components/atoms/ContextualMenu';
import GatewayLink from '../GatewayLink';
import { Links, LinksTypes, ManagementLinks } from 'models/gateways.model';

interface Props {
  managementLinks?: Links;
  onUnassignGateway?: () => void;
  onAssignGateway?: () => void;
  disabledContextualMenuLinks?: boolean;
  isGatewayLinked: boolean;
  hasPermissionToEditGatewayLink: boolean;
  isSharedGateway: boolean;
}

const getLinkManager = (disabled: boolean, managementLinks?: Links): ManagementLinks => {
  const openhab = managementLinks ? managementLinks[LinksTypes.openhab] : null;
  const ssh_teleport = managementLinks ? managementLinks[LinksTypes.ssh_teleport] : null;

  return {
    [LinksTypes.openhab]: {
      url: openhab,
      disabled,
      title: 'Openhab',
    },
    [LinksTypes.ssh_teleport]: {
      url: ssh_teleport,
      disabled,
      title: 'SSH Teleport',
    },
  }
}

enum Translations {
  LINK_GATEWAY = 'link_gateway',
  UNLINK_GATEWAY = 'unlink_gateway',
  ACCESS_TO = 'access_to',
}

export const ContextualMenuLinks: FC<Props> = ({
  managementLinks,
  onUnassignGateway,
  onAssignGateway,
  disabledContextualMenuLinks = true,
  isGatewayLinked,
  hasPermissionToEditGatewayLink,
  isSharedGateway,
}) => {
  const { t } = useTranslation();
  const links = getLinkManager(disabledContextualMenuLinks, managementLinks);

  const GatewayLinkMenuItem: FC = () => {
    return (
      <Menu.Item
        key={isGatewayLinked ? Translations.UNLINK_GATEWAY : Translations.LINK_GATEWAY}
        onClick={isGatewayLinked ? onUnassignGateway : onAssignGateway}
        disabled={isSharedGateway || !hasPermissionToEditGatewayLink}
      >
        {isGatewayLinked ? t(Translations.UNLINK_GATEWAY) : t(Translations.LINK_GATEWAY)}
      </Menu.Item>
    )
  }

  const menu: ReactElement = (
    <Menu>
      <GatewayLinkMenuItem />
      <Menu.SubMenu title={t(Translations.ACCESS_TO)} disabled={!hasPermissionToEditGatewayLink}
      >
        <Menu.Item
          key={LinksTypes.openhab}
          disabled={links[LinksTypes.openhab].disabled}
        >
          <GatewayLink
            url={links[LinksTypes.openhab].url}
            styleLink={false}
          >
            {links[LinksTypes.openhab].title}
          </GatewayLink>
        </Menu.Item>
        <Menu.Item
          key={LinksTypes.ssh_teleport}
          disabled={links[LinksTypes.ssh_teleport].disabled}
        >
          <GatewayLink
            url={links[LinksTypes.ssh_teleport].url}
            styleLink={false}
          >
            {links[LinksTypes.ssh_teleport].title}
          </GatewayLink>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <ContextualMenu menu={menu} />
  )
}

export default ContextualMenuLinks;