import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalProps } from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';
import { Button, message, Modal, Space } from 'antd';
import { sendProjectNotification } from 'services/api/projects';
import ProjectNotifyForm from 'components/organisms/ProjectNotifyForm';
import { getProjectPathById } from 'components/pages/App/routes/projects/config';
import './styles.scss';

interface Props extends ModalProps {
  projectId?: string;
}

const FORM_ID = 'form_project_notification';

interface Values {
  projectId: string;
  content: string;
}

const ProjectNotificationModal: FC<Props> = ({ projectId, onCancel, ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);

  const handleOnSubmit = async (values: Values) => {
    try {
      setSending(true);
      await sendProjectNotification(values.projectId, values.content);
      message.success(t('sent_successfully'));
      history.push(getProjectPathById({ projectId: values.projectId }));
      if (props.onOk) props.onOk({} as any);
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal
      {...props}
      title={<Space>{t('send_notification')}</Space>}
      onCancel={onCancel}
      footer={[
        <Button key='back' onClick={onCancel}>
          {t('to_cancel')}
        </Button>,
        <Button key='submit' type='primary' loading={sending} htmlType='submit' form={FORM_ID}>
          {t('send')}
        </Button>,
      ]}
      destroyOnClose
    >
      <ProjectNotifyForm id={FORM_ID} onFinish={handleOnSubmit} initialValues={{ projectId: projectId }} />
    </Modal>
  );
};

export default ProjectNotificationModal;
