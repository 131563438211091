import { FC } from 'react';
import Access from './Access';
import PermissionsAccess from './PermissionsAccess';
import ListInvitationsModal from './Access/ListInvitationsModal';
import CreateInvitationModal from './Access/CreateInvitationModal';
import { useAccess } from './resources/useAccess';
import { useAccessPermissions } from './PermissionsAccess/useAccessPermissions';
import AccessPasswordsDrawer from 'components/organisms/AccessPasswordsDrawer';
import ConfirmModal from 'components/molecules/ConfirmModal';
import ConfirmCloseAccessPasswordModal from 'components/molecules/ConfirmDeleteAccessPasswordModal';
import CreateBetaInvitationModal from './Access/CreateBetaInvitationModal';
import CheckInRoomDrawer from 'components/organisms/CheckInCreateRoomDrawer';
import CheckInRoomForceSaveModal from 'components/organisms/CheckInRoomForceSaveModal';
import CheckInScannerQRModal from 'components/organisms/CheckInScannerQRModal';

const TabAccesses: FC = () => {
  const {
    assetId,
    devices,
    currentDevice,
    accessColumn,
    invitations,
    pageNumber,
    form,
    createModalTranslations,
    createBetaInvitationButtonText,
    drawerTranslations,
    adminPasswords,
    adminAvailablePasswords,
    nfcPasswords,
    userPasswords,
    userAvailablePasswords,
    deleteModalTranslations,
    closeModalTranslations,
    listInvitationsModalTranslations,
    listInvitationItemTranslations,
    assignRoomButtonText,
    checkInStates,
    onOpenAssignRoomDrawer,
    onOpenCreateInvitationModal,
    onCloseCreateInvitationModal,
    onOpenCreateBetaInvitationModal,
    onCloseCreateBetaInvitationModal,
    onCloseInvitationsListModal,
    onOpenInvitationsListModal,
    onLoadMore,
    onRevoke,
    onRowClick,
    onClosePasswordsDrawer,
    onClosePasswordsDrawerModal,
    onConfirmClosePasswordDrawer,
    onTogglePassword,
    onChangePassword,
    onCloseDeleteModal,
    onDeletePassword,
    onClickConfirmDeletingPassword,
    onClickAddPassword,
    onSavePasswordsChanges,
    onConfirmCloseDrawerModalWithoutSaving,
    onGetSensorsList,
    isAssignRoomButtonVisible,
    loading,
    loadingAccesses,
    createInvitationModalVisible,
    createBetaInvitationModalVisible,
    invitationListModalVisible,
    drawerPasswordVisible,
    adminPasswordsAddButtonVisible,
    userPasswordsAddButtonVisible,
    nfcPasswordsAddButtonVisible,
    confirmDeletingModalVisible,
    closeDrawerModalVisible,
    adminSectionVisible,
    userSectionVisible,
    nfcSectionVisible,
    loadingUpdate,
    saveButtonDisabled,
    showLoadMoreInvitationsButton,
    isCreateAndListInvitationsForbidden,
  } = useAccess();
  const {
    accessPermissionsTitle,
    devicesWithPermissions,
    width,
    getSensorsNames,
    onChangeAccessPermission,
    loadingAccessWithPermissions,
  } = useAccessPermissions();
  const {
    titleText,
    createInvitationButtonText,
    createInvitationInfoButtonText,
    listInvitationButtonText,
    listInvitationInfoButtonText,
    updateAccessListText,
  } = createModalTranslations;
  const {
    deleteModalTitle,
    deleteModalText,
    deleteModalMainButtonText,
    deleteModalSecondaryButtonText,
  } = deleteModalTranslations;
  const {
    closeModalTitle,
    closeModalText,
    closeModalMainButtonText,
    closeModalSecondaryButtonText,
  } = closeModalTranslations;
  const { title: listInvitationsModalTitle, closeButtonText, loadMoreButtonText } = listInvitationsModalTranslations

  return (
    <>
      <Access
        accessColumn={accessColumn}
        devices={devices}
        form={form}
        title={titleText}
        createInvitationButtonText={createInvitationButtonText}
        createInvitationInfoText={createInvitationInfoButtonText}
        listInvitationsButtonText={listInvitationButtonText}
        listInvitationsInfoText={listInvitationInfoButtonText}
        createBetaInvitationButtonText={createBetaInvitationButtonText}
        updateButtonText={updateAccessListText}
        assignRoomButtonText={assignRoomButtonText}
        loading={loading}
        onOpenCreateInvitation={onOpenCreateInvitationModal}
        onOpenInvitationsListModal={onOpenInvitationsListModal}
        onOpenCreateBetaInvitationModal={onOpenCreateBetaInvitationModal}
        onRefresh={onGetSensorsList}
        onRowClick={onRowClick}
        isCreateAndListInvitationsForbidden={isCreateAndListInvitationsForbidden}
        onClickAssignRoom={onOpenAssignRoomDrawer}
        isAssignRoomButtonVisible={isAssignRoomButtonVisible}
      />
      <PermissionsAccess
        devices={devicesWithPermissions}
        title={accessPermissionsTitle}
        width={width}
        getSensorsNames={getSensorsNames}
        onChange={onChangeAccessPermission}
        loading={loadingAccessWithPermissions}
      />
      <CheckInRoomDrawer {...checkInStates} />
      <CheckInRoomForceSaveModal {...checkInStates.modalForce} />
      <CheckInScannerQRModal {...checkInStates.qrModal} />
      <CreateInvitationModal
        key={'createInvitation'}
        assetId={assetId}
        visible={createInvitationModalVisible}
        onClose={onCloseCreateInvitationModal}
      />
      <ListInvitationsModal
        key={'listInvitation'}
        invitations={invitations}
        pageNumber={pageNumber}
        onClose={onCloseInvitationsListModal}
        onLoadMore={onLoadMore}
        onRevoke={onRevoke}
        loading={loadingAccesses}
        visible={invitationListModalVisible}
        showLoadMoreInvitationsButton={showLoadMoreInvitationsButton}
        title={listInvitationsModalTitle}
        closeButtonText={closeButtonText}
        loadMoreButtonText={loadMoreButtonText}
        itemTranslations={listInvitationItemTranslations}
      />
      <CreateBetaInvitationModal
        key={'createBetaInvitation'}
        assetId={assetId}
        visible={createBetaInvitationModalVisible}
        onClose={onCloseCreateBetaInvitationModal}
      />
      <AccessPasswordsDrawer
        device={currentDevice}
        adminPasswords={adminPasswords}
        adminAvailablePasswords={adminAvailablePasswords}
        nfcPasswords={nfcPasswords}
        userPasswords={userPasswords}
        userAvailablePasswords={userAvailablePasswords}
        onClose={onClosePasswordsDrawer}
        onTogglePassword={onTogglePassword}
        onChangePassword={onChangePassword}
        onDeletePassword={onDeletePassword}
        onClickAddPassword={onClickAddPassword}
        onSave={onSavePasswordsChanges}
        visible={drawerPasswordVisible}
        adminSectionVisible={adminSectionVisible}
        userSectionVisible={userSectionVisible}
        nfcSectionVisible={nfcSectionVisible}
        adminPasswordsAddButtonVisible={adminPasswordsAddButtonVisible}
        userPasswordsAddButtonVisible={userPasswordsAddButtonVisible}
        nfcPasswordsAddButtonVisible={nfcPasswordsAddButtonVisible}
        translations={drawerTranslations}
        loading={loadingUpdate}
        disabled={saveButtonDisabled}
      />
      <ConfirmModal
        loading={false}
        onCancel={onCloseDeleteModal}
        onClickMainButton={onClickConfirmDeletingPassword}
        mainButtonText={deleteModalMainButtonText}
        secondaryButtonText={deleteModalSecondaryButtonText}
        text={deleteModalText}
        title={deleteModalTitle}
        visible={confirmDeletingModalVisible}
      />
      <ConfirmCloseAccessPasswordModal
        loading={false}
        onCancel={onConfirmCloseDrawerModalWithoutSaving}
        onClickMainButton={onConfirmClosePasswordDrawer}
        onClickSecondaryButton={onClosePasswordsDrawerModal}
        mainButtonText={closeModalMainButtonText}
        secondaryButtonText={closeModalSecondaryButtonText}
        text={closeModalText}
        title={closeModalTitle}
        visible={closeDrawerModalVisible}
      />
    </>
  );
};

export default TabAccesses;
