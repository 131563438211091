import { BookingModel } from '../models/bookingEntityModels';

const BookingEntity = ({ id, commonArea, hourBooking, dateBooking, sensors, user }: BookingModel): Readonly<BookingModel> => {
  return Object.freeze({
    id,
    commonArea,
    hourBooking,
    dateBooking,
    sensors,
    user,
  });
};

export default BookingEntity;
