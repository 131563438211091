import { FC } from 'react';
import Error from 'assets/svg/icon/Error';
import { IntercomErrorTemplateTranslations } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/translations';
import './styles.scss';

interface Props {
  intercomsErrorTemplateTranslations: IntercomErrorTemplateTranslations;
}

const ProjectIntercomsErrorTemplate: FC<Props> = ({ intercomsErrorTemplateTranslations }) => {
  const { title, text } = intercomsErrorTemplateTranslations;

  return (
    <div className={'ProjectIntercomsErrorTemplate__container'}>
      <div className={'ProjectIntercomsErrorTemplate__message-content'}>
        <Error />
        <p className={'ProjectIntercomsErrorTemplate__message-title'}>{title}</p>
        <p className={'ProjectIntercomsErrorTemplate__message-description'}>{text}</p>
      </div>
    </div>
  );
}

export default ProjectIntercomsErrorTemplate;