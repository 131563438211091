import { FC, ReactNode } from 'react';
import { DocumentsColumns } from './DocumentsColumns';
import TableAtom, { TableActionsMenuProps } from 'components/atoms/TableAtom';
import { DocumentModel } from 'core/domain/documents/model/documentsEntityModels';
import { SourceType } from './DocumentsTableActionsManager';
import './styles.scss';

export interface DocumentsTableProps {
  button: ReactNode;
  documents: DocumentModel[];
  source: SourceType;
  wrongSourceText: string;
  onFormat: (document: { bytes: number; decimals?: number }) => string;
  onRowClick: (document: DocumentModel) => void;
  isLoading: boolean;
  documentTableActionsMenu: TableActionsMenuProps[];
}

const DocumentsTable: FC<DocumentsTableProps> = ({
  button,
  documents,
  source,
  wrongSourceText,
  onRowClick,
  onFormat,
  isLoading,
  documentTableActionsMenu,
}) => {
  const documentsColumns = DocumentsColumns({ source, wrongSourceText, onFormat, documentTableActionsMenu });

  return (
    <>
      <p>{button}</p>
      <TableAtom<DocumentModel>
        key={documents.length}
        loading={isLoading}
        dataSource={documents}
        columns={documentsColumns}
        rowKey={'id'}
        rowClassName={'editable-row DocumentsTable__row'}
        pagination={false}
        sticky
        onRow={(record) => {
          return {
            onClick: () => onRowClick(record)
          };
        }}
      />
    </>
  );
};

export default DocumentsTable;
