import { FC } from 'react';
import { Form, FormProps, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { ClientSelectorFormValues } from 'components/pages/UsersPage/resources/useUsersAssets';

interface Props extends FormProps {
  data: ClientModel[];
  form: FormInstance<ClientSelectorFormValues>
  requiredMessage: string;
  placeholder: string;
  onFinish: (values: ClientSelectorFormValues) => void;
}

const UsersClientsSelectorModalForm: FC<Props> = ({ form, data, requiredMessage, placeholder, onFinish, ...props }) => {
  return (
    <Form form={form} onFinish={onFinish} requiredMark={false} {...props}>
      <Form.Item name={'clientId'} rules={[{ required: true, message: requiredMessage, type: 'string' }]}>
        <Select placeholder={placeholder} allowClear>
          {data.map(({ id, name }) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default UsersClientsSelectorModalForm;