import { useTranslation } from 'react-i18next';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { Camera } from 'core/domain/assets/models/camera';

export const CamerasAssetColumns = (): AlfredTableColumn<Camera>[] => {
  const { t } = useTranslation();
  return [
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('vendor'),
      key: 'vendor',
      dataIndex: 'vendor',
    },
    {
      title: t('model'),
      key: 'model',
      dataIndex: 'model',
    },
    {
      title: t('status'),
      key: 'enabled',
      dataIndex: 'enabled',
      render: (record, { enabled }) => (enabled ? t('active') : t('inactive')),
    },
  ];
};
