import { FC, useState } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

interface RevokeActionProps {
  id?: string;
  onSuccess?: () => void;
  onRevoke: (revokeId: string, id?: string) => Promise<void>;
  popConfirmText: string;
  revokeId: string;
  messageInfo: string;
  buttonText: string;
}

const RevokeAction: FC<RevokeActionProps> = ({ id, onSuccess, onRevoke, popConfirmText, revokeId, messageInfo, buttonText }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleRevokeAsset = async (itemId: string) => {
    setLoading(true);
    try {
      await onRevoke(itemId, id);
      message.info(messageInfo, 3);
    } finally {
      setLoading(false);
      onSuccess && onSuccess();
    }
  };

  return (
    <div key={revokeId} onClick={(event) => event.stopPropagation()}>
      <Popconfirm
        placement='topRight'
        title={popConfirmText}
        onConfirm={() => handleRevokeAsset(revokeId)}
        okText={t('ok')}
        cancelText={t('cancel')}
      >
        <Button className='Text__link' loading={loading} type='link' size='small' shape='round'>
          {buttonText}
        </Button>
      </Popconfirm>
    </div>
  );
};

export default RevokeAction;
