import { FC } from 'react';
import { Svg } from '../model/svg';

const Maintenance: FC<Svg> = ({ width = '96', height = '96', ...props }) => (
  <svg width={width} height={height} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path opacity="0.15" d="M46.3903 -2.02779e-06C55.6166 -2.43108e-06 64.4298 1.80392 72 6.6C78.1626 10.5043 83.5014 16.7345 87.5686 22.8C94.2071 32.7 96 40.0273 96 49.8C96 63.2175 93 75 84 84C75.9049 92.0546 63.4085 96 48 96C33.2614 96 19.322 89.502 10.8 79.8C5.00269 73.2 -1.59745e-06 59.4545 -2.1506e-06 46.8C-2.69531e-06 34.3385 1.24987 21.4513 8.4 13.2C19.8383 -8.49656e-06 31.2874 -1.36762e-06 46.3903 -2.02779e-06Z" fill="#96C0C4" />
    <path d="M0.499989 49.6097C0.49999 40.4462 2.29175 31.7344 7.02237 24.2676C10.8739 18.1882 17.0404 12.8955 23.0785 8.84667C32.8927 2.26566 40.1224 0.499995 49.8 0.499996C63.1456 0.499997 74.7754 3.48292 83.6458 12.3529C91.5729 20.3199 95.5 32.6658 95.5 48C95.5 62.6001 89.0599 76.4007 79.47 84.8243C76.2357 87.6653 71.2151 90.338 65.4103 92.3003C59.611 94.2608 53.0652 95.5 46.8 95.5C34.3287 95.5 21.623 94.2372 13.5274 87.2221C6.9989 81.5649 3.75588 75.9282 2.13307 69.8501C0.503146 63.7454 0.499988 57.1766 0.499989 49.6097Z" stroke="#96C0C4" />
    <g clipPath="url(#clip0_7090_624)">
      <path d="M56.2448 48.1381L54.313 47.6205L57.9364 34.0975C58.7957 30.8907 56.9007 27.6084 53.6938 26.7491C50.4869 25.8898 47.2046 27.7848 46.3453 30.9917L42.7219 44.5147L40.79 43.997C35.4581 42.5684 29.9712 45.7362 28.5426 51.0681L25.9544 60.7274C25.385 62.8524 26.6578 65.0569 28.7828 65.6263L55.8287 72.8733C57.9538 73.4427 60.1583 72.1699 60.7277 70.0449L63.3159 60.3856C64.7446 55.0537 61.5767 49.5668 56.2448 48.1381ZM56.864 69.0096L53.0003 67.9743L54.5532 62.1787C54.8379 61.1162 54.2015 60.014 53.139 59.7293C52.0765 59.4446 50.9742 60.0809 50.6895 61.1435L49.1366 66.939L45.2729 65.9037L46.8258 60.1082C47.1105 59.0457 46.4741 57.9434 45.4116 57.6587C44.3491 57.374 43.2468 58.0104 42.9621 59.0729L41.4092 64.8685L37.5455 63.8332L39.0984 58.0376C39.3831 56.9751 38.7467 55.8728 37.6842 55.5881C36.6217 55.3034 35.5194 55.9398 35.2347 57.0024L33.6818 62.7979L29.8181 61.7626L32.4063 52.1034C33.2604 48.9158 36.5672 47.0066 39.7547 47.8607L55.2095 52.0018C58.3971 52.8559 60.3063 56.1628 59.4522 59.3503L56.864 69.0096Z" fill="#96C0C4" />
    </g>
    <defs>
      <clipPath id="clip0_7090_624">
        <rect x="31.0293" y="18.6055" width="48" height="48" rx="4" transform="rotate(15 31.0293 18.6055)" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Maintenance;
