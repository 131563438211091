import axios, { AxiosRequestConfig } from 'axios';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string, checkInId: string) => {
  return `${host}/access/${checkInId}/revoke`;
};

export const CheckoutRoom = async ({
  checkInIdList,
  token,
  host,
}: {
  checkInIdList: string[];
  token: string;
  host: string;
}): Promise<void> => {
  try {
    const response = checkInIdList.map(async id => {
      const url = getUrl(host, id);
  
      const options: AxiosRequestConfig = {
        url,
        method: 'PUT',
        timeout: 10000,
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: token,
          'Accept-version': '1',
        },
      };
  
      (await axios(options));
    })

    Promise.all(response);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
