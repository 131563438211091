import { ProjectIntercomDeepLinkModel, ProjectIntercomModel } from '../model/projectModel';
import { PayloadProjectIntercomDeepLinkModel, PayloadProjectIntercomModel } from '../model/projectPayloadModel';
import { payloadToRoom } from './payloadToRoom';

const payloadToIntercomDeepLink = ({ vendor, ios, android }: PayloadProjectIntercomDeepLinkModel): ProjectIntercomDeepLinkModel => {
  return {
    vendor: vendor ?? '',
    ios: {
      deepLink: ios.deep_link ?? '',
      storeId: ios.store_id ?? '',
    },
    android: {
      deepLink: android.deep_link ?? '',
      storeId: android.store_id ?? '',
    },
  };
};

export const payloadToIntercom = ({
  id,
  vendor,
  name,
  project_id,
  local,
  remote,
  model,
  comments,
  serial_number,
  handler_id,
  rooms,
  available_intercom_vendors,
  deep_links,
}: PayloadProjectIntercomModel): ProjectIntercomModel => {
  const transformedRooms = rooms.map((item) => payloadToRoom(item)) ?? [];
  const transformedDeepLinks = payloadToIntercomDeepLink(deep_links);

  return {
    id: id ?? '',
    vendor: vendor ?? '',
    projectId: project_id ?? '',
    name: name ?? '',
    local: local ?? '',
    remote: remote ?? '',
    model: model ?? '',
    comments: comments ?? '',
    serialNumber: serial_number ?? '',
    handlerId: handler_id ?? '',
    availableIntercomVendors: available_intercom_vendors ?? [],
    rooms: transformedRooms,
    deepLinks: transformedDeepLinks,
  };
};
