import { FC } from 'react';
import Paper from '../../../atoms/Paper';
import { PremiumUser } from 'core/domain/premiumUsers/model/premiumUser';
import AssignUserModal from 'components/pages/SettingsPage/AssignUserModal';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { UserAddOutlined } from '@ant-design/icons';
import AlfredTableTemplate from 'components/template/AlfredTableTemplate';
import { useTabBillableUserList } from './resources/useTabUserList';


const TabBillableUserList: FC = () => {
  const {
    clientId,
    addBillableUsersButton,
    columns,
    dataList,
    totalPages,
    pageSize,
    pageCurrent,
    onClick,
    onVisible,
    onPagination,
    onRowClick,
    paginationShowed,
    isAddExpertButtonVisible,
    isModalVisible,
    isLoading,
  } = useTabBillableUserList();

  const actionSection = isAddExpertButtonVisible ? (
    <DefaultButton
      onClick={onClick}
      icon={<UserAddOutlined />}
      title={addBillableUsersButton}
    />
  ) : undefined;

  return (
    <Paper>
      <AlfredTableTemplate<PremiumUser>
        actionSectionRight={actionSection}
        columns={columns}
        data={dataList}
        totalPages={totalPages}
        pageSize={pageSize}
        pageCurrent={pageCurrent}
        onPagination={onPagination}
        onRowClick={onRowClick}
        isCharging={isLoading}
        paginationShowed={paginationShowed}
      />
      <AssignUserModal
        visible={isModalVisible}
        clientId={clientId}
        onCancel={onVisible}
      />
    </Paper>
  );
};

export default TabBillableUserList;
