import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ConciergeServiceModal from '../../molecules/ConciergeServiceModal';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { Icon } from 'components/atoms/Icons';
import { ThirdPartyServicesCreateByProject } from 'core/domain/thirdPartyServices/model/thirdPartyServicesCreateByProject';
import ConciergeServiceForm from 'components/molecules/ConciergeServiceForm';
import './styles.scss';

const FORM_ID = 'form_concierge_service';

interface ConciergeServiceActionProps {
  onSave: (values: ThirdPartyServicesCreateByProject) => Promise<void>;
  onOpen: () => void;
  onCancel: () => void;
  visibleModal: boolean;
  loading: boolean;
}

const ConciergeServiceAction: FC<ConciergeServiceActionProps> = ({
  onSave, onOpen, onCancel, visibleModal, loading 
}) => {
  const { t } = useTranslation();

  return (
    <>
      <DefaultButton
        onClick={onOpen}
        icon={<Icon.Plus />}
        title={t('new_service')}
        className={'ConciergeServiceAction'}
      />
      <ConciergeServiceModal
        formId={FORM_ID}
        visible={visibleModal}
        onCancel={onCancel}
        loading={loading}
      >
        <ConciergeServiceForm formId={FORM_ID} onSave={onSave} />
      </ConciergeServiceModal>
    </>
  );
};

export default ConciergeServiceAction;
