import { FC } from "react";
import { Icon } from "components/atoms/Icons";
import './styles.scss';

interface Props {
  title: string;
  description: string;
  buttonText: string;
  onClickAdd: () => void;
}

export const ProjectLayoutConfigEmptyTemplate: FC<Props> = ({
  title,
  description,
  buttonText,
  onClickAdd,
}) => {
  return (
    <>
      <div className={'ProjectLayoutConfigEmptyTemplate__container'}>
        <div className={'ProjectLayoutConfigEmptyTemplate__empty'}>
          <div className='ProjectLayoutConfigEmptyTemplate__container-info'>
            <div className='ProjectLayoutConfigEmptyTemplate__icon'>
              <Icon.Empty />
            </div>
            <h2 className='ProjectLayoutConfigEmptyTemplate__title'>{title}</h2>
            <p className='ProjectLayoutConfigEmptyTemplate__text'>{description}</p>
            <div className='ProjectLayoutConfigEmptyTemplate__wrapper-link ProjectLayoutConfigEmptyTemplate__link' onClick={onClickAdd}>
              {buttonText}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}