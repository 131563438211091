import { SensorWithPasswordsModel } from '../models/sensorEntityModels';

const SensorWithPasswordsEntity = ({
  deviceName,
  deviceType,
  deviceParameters,
  sensorPasswords,
}: SensorWithPasswordsModel): Readonly<SensorWithPasswordsModel> => {
  return Object.freeze({
    deviceName,
    deviceType,
    deviceParameters,
    sensorPasswords,
  });
};

export const SensorsListWithPasswordsListObjectValue = (
  sensorsList: SensorWithPasswordsModel[]
): Readonly<{ list: SensorWithPasswordsModel[] }> => {
  return Object.freeze({
    list: sensorsList.map((item) => SensorWithPasswordsEntity(item)),
  });
};
