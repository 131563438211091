import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH_SUPPORT_TICKETS } from 'components/pages/App/routes/supportTickets/config';
import { SupportTicketsStatisticsModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import { SupportTicketsStatisticsUnit, supportTicketsUnitsTranslationsManager } from './translations';
import { GetSupportTicketsStatistics } from 'core/domain/supportTickets/repositories/getSupportTicketsStatistics';

const initialStatistics: SupportTicketsStatisticsModel = {
  firstResponseTimeAverage: '00:00',
  firstResponseTimeAverageUnits: '',
  ticketsClosed: 0,
  ticketsRevoked: 0,
  ticketsInProgress: 0,
  ticketsClosedTimeAverage: '00:00',
  ticketsClosedTimeAverageUnits: '',
  ticketsReceived: 0,
};

export const useSupportTicketsStatistics = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<SupportTicketsStatisticsModel>(initialStatistics);
  const history = useHistory();

  const formatUnits = (units?: string): string => {
    const value = !!units ? (units as SupportTicketsStatisticsUnit) : SupportTicketsStatisticsUnit.DEFAULT;
    return supportTicketsUnitsTranslationsManager[value]().toLowerCase();
  };

  const formatSupportTicketsStatisticsUnits = (tickets?: SupportTicketsStatisticsModel): SupportTicketsStatisticsModel | undefined => {
    const ticketsClosedUnits = formatUnits(tickets?.ticketsClosedTimeAverageUnits);
    const firstResponseUnits = formatUnits(tickets?.firstResponseTimeAverageUnits);
    return (
      tickets && {
        ...tickets,
        ticketsClosedTimeAverageUnits: ticketsClosedUnits,
        firstResponseTimeAverageUnits: firstResponseUnits,
      }
    );
  };

  const getSupportTicketsStatistics = async () => {
    setIsLoading(true);
    try {
      const ticketsStatistics = await GetSupportTicketsStatistics();
      const ticketsStatisticsWithFormattedUnits = formatSupportTicketsStatisticsUnits(ticketsStatistics);
      ticketsStatisticsWithFormattedUnits && setStatistics(ticketsStatisticsWithFormattedUnits);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickOpenedSupportTickets = () => {
    history.push(ROUTE_PATH_SUPPORT_TICKETS);
  };

  useEffect(() => {
    getSupportTicketsStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    supportTicketsStatistics: statistics,
    onClickOpenedSupportTickets,
    supportTicketsLoading: isLoading,
    supportTicketsError: isError,
  };
};
