import { FC } from 'react';
import { Upload, UploadProps } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './styles.scss';

const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

interface Props extends UploadProps {
  loading: boolean;
  buttonText?: string;
}

const UploadButton: FC<Props> = ({ loading, disabled, buttonText = '', ...props }) => {
  return (
    <Upload
      name={'xxx'}
      listType='text'
      customRequest={dummyRequest}
      showUploadList={false}
      disabled={disabled}
      {...props}
      className={`UploadButton__wrapper ${disabled && 'UploadButton__wrapper--disabled'}`}
    >
      <div className={'UploadButton__info'}>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className={'UploadButton__text'}>{buttonText}</div>
      </div>
    </Upload>
  );
};

export default UploadButton;
