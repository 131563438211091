import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Skeleton, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getSupportTicket } from 'services/api/supportTickets';
import './styles.scss';

interface CarrouselMediaProps {
  ticketId: string;
}

const CarrouselMedia: FC<CarrouselMediaProps> = ({ ticketId }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSupportTicket(ticketId)
      .then(({ data: { media } }) => {
        const items = Object.values(media).flatMap<string>((item) => item);
        setItems(items);
      })
      .finally(() => setLoading(false));
  }, [ticketId]);

  return (
    <div>
      <b>{t('images')}:</b>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {!!items.length ? (
            <ul>
              {items.map((path, key) => (
                <li key={key}>
                  <Button className='Text__link' type='link' shape='round' icon={<DownloadOutlined />} download href={path} target='_blank'>
                    {`${t('download_image')} ${key + 1}`}
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <Empty
              description={t('no_images')}
              imageStyle={{
                height: 60,
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CarrouselMedia;
