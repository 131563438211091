import { FC } from 'react';
import AlfredMainTemplate from 'components/template/AlfredMainTemplate';
import SpaceAtom from 'components/atoms/SpaceAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { Icon } from 'components/atoms/Icons';
import { useAssets } from './resources/useAssets';
import CreateAssetDrawer from 'components/organisms/CreateAssetDrawer';
import QrScannerModal from 'components/molecules/QrScannerModal';
import ClientNewModal from 'components/organisms/ClientNewModal';
import QrErrorModal from 'components/organisms/QidErrorModal';
import AlfredTableTemplate from 'components/template/AlfredTableTemplate';
import { Asset } from 'models/assets.model';
import CreateAssetConfirmationModal from 'components/organisms/CreateAssetConfirmationModal';

const AssetsPage: FC = () => {
  const {
    assets,
    columns,
    totalPages,
    pageCurrent,
    pageSize,
    literals,
    createAssetDrawerOptions,
    qrScannerOptions,
    didScannerOptions,
    clientOptions,
    qrErrorModalOptions,
    onNavigateToQrToolPage,
    onSelectAsset,
    onChangePage,
    onSearch,
    isCharging,
    hasPermissionToGenerateQr,
  } = useAssets();
  const { title, createAssetButtonText, qrGeneratorButtonText } = literals;
  const { onOpenCreateAssetDrawer, onCloseQrErrorsModal, onAcceptConfirmationModal, confirmationLiterals, onCloseCreateAssetConfirmationModal, confirmationModalVisible } = createAssetDrawerOptions;
  const { onCloseQrScanner, onScanQrCode, qrScannerVisible, modalQrTitle } = qrScannerOptions;
  const { onCloseDidScanner, onScanDid, didScannerVisible, modalDidTitle } = didScannerOptions;

  const {
    qidErrorValue,
    didErrorValue,
    qrErrorModalLiterals,
    didError,
    qidError,
    qrErrorModalVisible,
    onAcceptErrors
  } = qrErrorModalOptions;

  const {
    createClientLiterals,
    onCreateClient,
    onCloseCreateClientModal,
    onOpenCreateClientModal,
    createClientModalVisible,
    creatingClient,
  } = clientOptions;

  const { title: createClientModalTitle, mainButtonText, secondaryButtonText } = createClientLiterals

  return (
    <>
      <AlfredMainTemplate title={title}>
        <AlfredTableTemplate<Asset>
          columns={columns}
          onRowClick={onSelectAsset}
          data={assets}
          totalPages={totalPages}
          isCharging={isCharging}
          pageSize={pageSize}
          pageCurrent={pageCurrent}
          onPagination={onChangePage}
          onSearch={onSearch}
          actionSection={
            <SpaceAtom>
              <DefaultButton
                key={'newAsset'}
                icon={<Icon.Plus />}
                onClick={onOpenCreateAssetDrawer}
                title={createAssetButtonText}
              />
              {hasPermissionToGenerateQr && (
                <DefaultButton
                  key={'qrGenerator'}
                  icon={<Icon.QR />}
                  onClick={onNavigateToQrToolPage}
                  title={qrGeneratorButtonText}
                />
              )}
            </SpaceAtom>
          }
        />
      </AlfredMainTemplate>
      <CreateAssetDrawer
        options={createAssetDrawerOptions}
        qrScannerOptions={qrScannerOptions}
        didScannerOptions={didScannerOptions}
        onOpenCreateClient={onOpenCreateClientModal}
      />
      <QrScannerModal
        title={modalQrTitle}
        open={qrScannerVisible}
        onCancel={onCloseQrScanner}
        onQrScan={onScanQrCode}
      />
      <QrScannerModal
        title={modalDidTitle}
        open={didScannerVisible}
        onCancel={onCloseDidScanner}
        onQrScan={onScanDid}
      />
      <QrErrorModal
        literals={qrErrorModalLiterals}
        qidValue={qidErrorValue}
        didValue={didErrorValue}
        isDidError={didError}
        isQidError={qidError}
        open={qrErrorModalVisible}
        onCancel={onCloseQrErrorsModal}
        onOk={onAcceptErrors}
      />
      <ClientNewModal
        open={createClientModalVisible}
        onCreateClient={onCreateClient}
        onCancel={onCloseCreateClientModal}
        onOk={onCloseCreateClientModal}
        loading={creatingClient}
        title={createClientModalTitle}
        mainButtonText={mainButtonText}
        secondaryButtonText={secondaryButtonText}
      />
      <CreateAssetConfirmationModal
        literals={confirmationLiterals}
        onCancel={onCloseCreateAssetConfirmationModal}
        onOk={onAcceptConfirmationModal}
        open={confirmationModalVisible}
      />
    </>
  );
};

export default AssetsPage;
