import { FC } from 'react';
import { Button, Modal, List } from 'antd';
import { CustomInvitationModel, InvitationsListItemTranslations, RevokeInvitationModel } from './resources/utils';
import InvitationItem from './InvitationItem';
import './styles.scss';

interface ListInvitationsModalProps {
  invitations: CustomInvitationModel[];
  pageNumber: number;
  title: string;
  closeButtonText: string;
  loadMoreButtonText: string;
  itemTranslations: InvitationsListItemTranslations;
  onClose: () => void;
  onLoadMore: (page: number) => Promise<void>;
  onRevoke: ({ invitationId, isNewInvitation }: RevokeInvitationModel) => Promise<void>;
  loading: boolean;
  visible: boolean;
  showLoadMoreInvitationsButton: boolean;
}

const ListInvitationsModal: FC<ListInvitationsModalProps> = ({
  invitations,
  pageNumber,
  title,
  closeButtonText,
  loadMoreButtonText,
  itemTranslations,
  onClose,
  onLoadMore,
  onRevoke,
  loading,
  showLoadMoreInvitationsButton,
  visible,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      destroyOnClose
      keyboard
      footer={[<Button key='back' onClick={onClose}>{closeButtonText}</Button>]}
    >
      <List
        className='ListInvitationsModal'
        itemLayout='vertical'
        loading={loading}
        loadMore={
          showLoadMoreInvitationsButton ? (
            <div className='ListInvitationsModal__loadMore'>
              <Button loading={loading} onClick={() => onLoadMore(pageNumber + 1)}>
                {loadMoreButtonText}
              </Button>
            </div>
          ) : null
        }
        dataSource={invitations}
        renderItem={(invitation, key) => <InvitationItem key={key} invitation={invitation} onRevoke={onRevoke} translations={itemTranslations} />}
      />
    </Modal>
  );
};

export default ListInvitationsModal;
