import { RouteConfig } from 'react-router-config';
import {
  ROUTE_PATH_COMMON_AREAS_BOOKINGS_LIST,
  ROUTE_PATH_COMMON_AREAS_BOOKINGS_MENU,
  ROUTE_PATH_COMMON_AREAS_CLIENT_AND_PROJECT_SELECTOR,
  ROUTE_PATH_COMMON_AREAS_COMMON_AREA_DETAIL,
  ROUTE_PATH_COMMON_AREAS_CREATE_COMMON_AREA,
  ROUTE_PATH_COMMON_AREAS_UPDATE_COMMON_AREA,
  ROUTE_PATH_COMMON_AREAS_V2,
} from './config';
import CommonAreasBookingsListPage from 'components/pages/CommonAreasBookingsListPage';
import CommonAreaCreatePage from 'components/pages/CommonAreaCreatePage';
import CommonAreaDetailPage from 'components/pages/CommonAreaDetailPage';
import CommonAreasClientAndProjectSelectorPage from 'components/pages/CommonAreasClientAndProjectSelectorPage';
import CommonAreasAndBookingsMenuPage from 'components/pages/CommonAreasAndBookingsMenuPage';
import CommonAreaUpdatePage from 'components/pages/CommonAreaUpdatePage';
import CommonAreasV2Page from 'components/pages/CommonAreasV2Page';

const commonAreasAndBookingsMenuRoutes: RouteConfig = {
  path: ROUTE_PATH_COMMON_AREAS_BOOKINGS_MENU,
  component: CommonAreasAndBookingsMenuPage,
  routes: [
    {
      path: ROUTE_PATH_COMMON_AREAS_BOOKINGS_LIST,
      exact: true,
      component: CommonAreasBookingsListPage,
    },
    {
      path: ROUTE_PATH_COMMON_AREAS_CREATE_COMMON_AREA,
      exact: true,
      component: CommonAreaCreatePage,
    },
    {
      path: ROUTE_PATH_COMMON_AREAS_COMMON_AREA_DETAIL,
      exact: true,
      component: CommonAreaDetailPage,
    },
    {
      path: ROUTE_PATH_COMMON_AREAS_UPDATE_COMMON_AREA,
      exact: true,
      component: CommonAreaUpdatePage,
    },
  ]
}

export const commonAreasRoutes: RouteConfig = {
  path: ROUTE_PATH_COMMON_AREAS_CLIENT_AND_PROJECT_SELECTOR,
  component: CommonAreasClientAndProjectSelectorPage,
  routes: [
    {
      path: ROUTE_PATH_COMMON_AREAS_V2,
      exact: true,
      component: CommonAreasV2Page,
    },
    commonAreasAndBookingsMenuRoutes,
  ]
};
