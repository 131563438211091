import { FC } from 'react'
import { Form, Input, Select, Switch, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { Icon } from 'components/atoms/Icons';
import { ProjectEditFormValuesNames, ProjectEditFormValues } from 'components/pages/ProjectPage/resources/models';
import { ProjectEditFormTranslations } from 'components/pages/ProjectPage/resources/models';
import { ProjectType, ProjectUsersVerifiersModel, TypeOptionsModel } from 'core/domain/project/model/projectModel';

interface Props {
  formTranslations: ProjectEditFormTranslations;
  form: FormInstance<ProjectEditFormValues>;
  allowedUsersToVerifyAccounts: ProjectUsersVerifiersModel[];
  typeOptions: TypeOptionsModel[];
  onFinish: (values: ProjectEditFormValues) => void;
  onFinishFailed: () => void;
  onSelectType: (value: ProjectType) => void;
  validateEmailFormat: (value: string) => void;
  editing: boolean;
}

const ProjectEditForm: FC<Props> = ({
  formTranslations,
  form,
  allowedUsersToVerifyAccounts,
  typeOptions,
  onFinish,
  onFinishFailed,
  onSelectType,
  validateEmailFormat,
  editing,
}) => {
  const {
    name,
    namePlaceholder,
    nameError,
    supportEmails,
    supportEmailsPlaceholder,
    usersVerifiers,
    usersVerifiersPlaceholder,
    usersRequireVerification,
    emailFormat,
    type,
  } = formTranslations;
  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout={'vertical'}
      requiredMark={false}
    >
      <div>
        <Form.Item
          label={`${name} *`}
          name={ProjectEditFormValuesNames.NAME}
          rules={[{ required: true, message: nameError }]}
        >
          <Input disabled={editing} placeholder={namePlaceholder} />
        </Form.Item>
        <Form.Item
          label={type}
          name={ProjectEditFormValuesNames.TYPE}
        >
          <Select options={typeOptions} onSelect={(id: any, value: { id: string }) => {
            onSelectType(value.id as unknown as ProjectType)
          }} />
        </Form.Item>
        <Form.Item
          label={
            <span>{`${supportEmails} `}
              <Tooltip title={emailFormat}>
                <Icon.InfoCircleFilled />
              </Tooltip>
            </span>
          }
          name={ProjectEditFormValuesNames.SUPPORT_EMAILS}
          rules={
            [{ validator: async (rule, value) => validateEmailFormat(value) }]
          }>
          <Input disabled={editing} placeholder={supportEmailsPlaceholder} multiple />
        </Form.Item>
        <Form.Item label={usersVerifiers} name={ProjectEditFormValuesNames.USERS_VERIFIERS}>
          <Select
            allowClear
            mode='multiple'
            options={allowedUsersToVerifyAccounts}
            placeholder={usersVerifiersPlaceholder}
          />
        </Form.Item>
        <Form.Item
          label={usersRequireVerification}
          name={ProjectEditFormValuesNames.USERS_REQUIRE_VERIFICATION}
          valuePropName={'checked'}
        >
          <Switch disabled={editing} />
        </Form.Item>
      </div>
    </Form>
  );
}

export default ProjectEditForm