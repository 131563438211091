import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { CommonAreaTimeAvailabilityModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { TIME_FORMAT } from 'constants/date';
import { useFieldsCommonAreasFormErrors } from './useFieldsCommonAreasFormErrors';

export const useTimeAvailability = (time: (CommonAreaTimeAvailabilityModel | null)[] = [null]) => {
  const [timeAvailability, setTimeAvailability] = useState<(CommonAreaTimeAvailabilityModel | null)[]>(time);
  const { checkTimeAvailabilityOverlapIntervalsError, checkTimeAvailabilityNotEmptyError } = useFieldsCommonAreasFormErrors();

  const onAddTimeAvailabilityInterval = () => {
    setTimeAvailability((currentState) => [...currentState, null]);
  };

  const onDeleteTimeAvailabilityInterval = (index: number) => {
    const newTimeAvailability = [...timeAvailability];
    newTimeAvailability.splice(index, 1);
    setTimeAvailability(newTimeAvailability);
  };

  const onChangeTimeAvailabilityInterval = (values: [Moment, Moment] | null, index: number) => {
    const newTimeAvailability = [...timeAvailability];
    const newInterval = !values ? null : { start: moment(values[0]).format(TIME_FORMAT), end: moment(values[1]).format(TIME_FORMAT) };
    newTimeAvailability.splice(index, 1, newInterval);
    setTimeAvailability(newTimeAvailability);
  };

  const onFilterValidTimeAvailabilityIntervals = (
    intervals: (CommonAreaTimeAvailabilityModel | null)[]
  ): CommonAreaTimeAvailabilityModel[] => {
    return intervals.filter((interval) => interval !== null) as CommonAreaTimeAvailabilityModel[];
  };

  const onSortTimeAvailabilityIntervals = (intervals: (CommonAreaTimeAvailabilityModel | null)[]): CommonAreaTimeAvailabilityModel[] => {
    const filteredTimeAvailability = onFilterValidTimeAvailabilityIntervals(intervals);
    return filteredTimeAvailability.sort((a, b) => {
      if (a.start < b.start) {
        return -1;
      }
      if (a.start > b.start) {
        return 1;
      }
      return 0;
    });
  };

  const areTimeAvailabilityArraysEquals = (
    firstTimeAvailability: (CommonAreaTimeAvailabilityModel | null)[],
    secondTimeAvailability: (CommonAreaTimeAvailabilityModel | null)[]
  ): boolean => {
    const sortedFirst = onSortTimeAvailabilityIntervals(firstTimeAvailability);
    const sortedSecond = onSortTimeAvailabilityIntervals(secondTimeAvailability);
    const areEveryIntervalEqual = sortedFirst.every(
      (value, index) => value.start === sortedSecond[index]?.start && value.end === sortedSecond[index]?.end
    );

    return sortedFirst.length === sortedSecond.length && areEveryIntervalEqual;
  };

  const haveTimeAvailabilityInputsErrors = (time: (CommonAreaTimeAvailabilityModel | null)[]): boolean => {
    const filteredTimeAvailabilityIntervals = onFilterValidTimeAvailabilityIntervals(time);
    const hasDuplicatedTimeAvailabilityIntervals = checkTimeAvailabilityOverlapIntervalsError(filteredTimeAvailabilityIntervals);
    const isTimeAvailabilityNotEmpty = checkTimeAvailabilityNotEmptyError(timeAvailability);
    return filteredTimeAvailabilityIntervals.length === 0 || hasDuplicatedTimeAvailabilityIntervals || !isTimeAvailabilityNotEmpty;
  };

  const onParsedTimeAvailabilityToBeSent = (time: (CommonAreaTimeAvailabilityModel | null)[]) => {
    const transformedTimeAvailability = onFilterValidTimeAvailabilityIntervals(time);
    const sortedTimeAvailability = onSortTimeAvailabilityIntervals(transformedTimeAvailability);
    return sortedTimeAvailability;
  };

  useEffect(() => {
    time[0] !== null && setTimeAvailability(time);
  }, [time]);

  return {
    timeAvailability,
    onAddTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    onChangeTimeAvailabilityInterval,
    onFilterValidTimeAvailabilityIntervals,
    onSortTimeAvailabilityIntervals,
    onParsedTimeAvailabilityToBeSent,
    areTimeAvailabilityArraysEquals,
    haveTimeAvailabilityInputsErrors,
  };
};
