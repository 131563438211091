import { FC, ReactElement, useState, useEffect } from 'react';
import { Modal, Form, Select, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { getExpertUsers, assignExpertUserToClient } from 'services/api/users';
import { User } from 'models/users.model';

interface Values { userExpertId: string }

interface AssignUserModalProps {
  visible: boolean;
  clientId: string;
  onCancel: () => void;
}

const FORM_ID = 'form_assign_user_expert_to_client';

const AssignUserModal: FC<AssignUserModalProps> = (props): ReactElement => {
  const { t } = useTranslation('global');
  const [form] = Form.useForm();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.visible) {
      setLoading(true);
      form.resetFields();

      getExpertUsers()
      .then((response) => setUsers(response.data))
      .finally(() => setLoading(false));
    }
  }, [form, props.visible]);

  const handleOnSubmit = async (values: Values) => {
    try{
      setLoading(true);
      await assignExpertUserToClient(props.clientId, values.userExpertId);
      message.success(t('user_expert_assigned'));
      props.onCancel();
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      {...props}
      title={t('assign_user')}
      footer={[
        <Button key='back' onClick={() => props.onCancel()}>{t('to_cancel')}</Button>,
        <Button key='submit' type='primary' loading={loading} htmlType='submit' form={FORM_ID}>
          {t('assign')}
        </Button>,
      ]}
    >
      <Form form={form} id={FORM_ID} layout='vertical' name='assign_expert_user_form' onFinish={(values: Values) => handleOnSubmit(values)}>
        <Form.Item
          name='userExpertId'
          label={`${t('user')}:`}
          rules={[ { required: true, type: 'string', message: t('required_field') } ]}
        >
          <Select placeholder={t('select_an_expert_user')} allowClear>
            {users.map((user, key) => {
              return (
                <Select.Option key={key} value={user.id}>{user.username}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignUserModal;
