import { useState } from 'react';
import { IntercomCreateModel, IntercomModel, IntercomUpdateModel } from 'core/domain/intercoms/model/intercomModel';
import {
  CreateIntercom,
  DeleteIntercom,
  GetIntercomsListByProjectId,
  UpdateIntercom,
} from 'core/domain/intercoms/repositories/intercomsCRUD';

export const useIntercomsCRUD = (projectId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const getIntercomsListByProjectId = async (): Promise<IntercomModel[] | undefined> => {
    setIsLoading(true);
    try {
      return await GetIntercomsListByProjectId(projectId);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const createIntercom = async (values: IntercomCreateModel) => {
    setIsProcessing(true);
    try {
      await CreateIntercom({ ...values, projectId });
    } catch (error) {
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  const updateIntercom = async (values: IntercomUpdateModel) => {
    setIsProcessing(true);
    try {
      await UpdateIntercom(values);
    } catch (error) {
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  const deleteIntercom = async (intercomId: string) => {
    setIsLoading(true);
    try {
      await DeleteIntercom(intercomId);
    } catch (error) {
      throw error;
    }
  };

  return {
    getIntercomsListByProjectId,
    createIntercom,
    updateIntercom,
    deleteIntercom,
    loadingIntercoms: isLoading,
    processingIntercom: isProcessing,
  };
};
