import { getDownloadTenantsTemplate } from 'core/domain/users/repositories/getDownloadTenantsTemplate';
import { downloadCsvFile } from 'utils/csv';

export const useTenantsCsv = () => {
  const onDownloadTenantsTemplateCsv = async () => {
    try {
      const csvData = await getDownloadTenantsTemplate();
      const csvFileName = 'plantilla_invitaciones_masivas_tenants.csv';
      !!csvData && downloadCsvFile(csvData, csvFileName);
    } catch (error) {
      console.warn(error);
    }
  };

  return {
    onDownloadTenantsTemplateCsv,
  };
};
