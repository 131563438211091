import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAsset, getAssetDashboard, updateAssetInformation } from 'services/api/assets';
import {
  AssetInAssetDashboard,
  ClientInAssetDashboard,
  ProjectInAssetDashboard,
  RegisterAssetAttributes,
  UpdateAssetInformation,
} from 'models/assets.model';
import { useHistory, useParams } from 'react-router-dom';
import { UserPermissionType } from 'models/users.model';
import { messageAtom } from 'components/atoms/MessageAtom';
import { hasPermission } from 'services/permissions';
import { DeleteAsset } from 'core/domain/assets/repositories/deleteAsset';
import { ROUTE_PATH_ASSETS } from 'components/pages/App/routes/assets/config';
import { AssetGatewayConnectionStatusType } from 'core/domain/assets/models/assetsModel';
import { getClientPathById } from 'components/pages/App/routes/settings/config';
import { getProjectPathById } from 'components/pages/App/routes/projects/config';

export interface AssetGatewayConnectionStatusTranslation {
  connected: string;
  disconnected: string;
  noLinkedGateways: string;
  defaultText: string;
}

export const useAsset = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { assetId } = useParams<{ assetId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [asset, setAsset] = useState<AssetInAssetDashboard>({} as AssetInAssetDashboard);
  const [project, setProject] = useState({} as ProjectInAssetDashboard);
  const [client, setClient] = useState<ClientInAssetDashboard>({} as ClientInAssetDashboard);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [showLinkedGatewaysInfoModal, setShowLinkedGatewaysInfoModal] = useState<boolean>(false);
  const [showActiveUsersInfoModal, setShowActiveUsersInfoModal] = useState<boolean>(false);

  const hasPermissionToDeleteAsset = hasPermission(UserPermissionType.DELETE_ASSETS);

  const deleteModalTitle = `${t('_ASSET_DELETE_MODAL_TITLE')} ${asset.alias}`;

  const assetGatewayConnectionStatusTranslations: AssetGatewayConnectionStatusTranslation = {
    connected: t('_ASSET_GATEWAY_CONNECTED'),
    disconnected: t('_ASSET_GATEWAY_DISCONNECTED'),
    noLinkedGateways: t('_ASSET_GATEWAY_NO_LINKED'),
    defaultText: t('not_reported'),
  };

  const getAssetDashboardData = useCallback(async (assetId: string) => {
    setIsLoading(true);
    try {
      const response = await getAssetDashboard(assetId);
      if (response) {
        const transformedAsset: AssetInAssetDashboard = {
          ...response.assets,
          gateway_connection_status: response.assets.gateway_connection_status ?? AssetGatewayConnectionStatusType.DEFAULT,
        };
        setAsset(transformedAsset);
        setProject(response.included.projects);
        setClient(response.included.clients);
      }
    } catch {
      messageAtom.error(t('unsuccessfully_edit'), 3);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAssetDashboardData(assetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const editAsset = async (values: RegisterAssetAttributes): Promise<void> => {
    const data: UpdateAssetInformation = {
      location: {
        country: values.country,
        province: values.province,
        city: values.city,
        postal_code: values.postal_code,
        street_type: values.street_type,
        street_name: values.street_name,
        block_number: values.block_number,
        staircase: values.staircase,
        flat: values.flat,
        door: values.door,
      },
      description: values.description,
      alias: values.alias,
      type: values.asset_type,
      is_blocked: values.is_blocked !== undefined ? values.is_blocked : null,
    };

    try {
      setIsLoadingUpdate(true);
      await updateAssetInformation(assetId, data);
    } finally {
      setIsLoadingUpdate(false);
    }

    await getAssetDashboardData(assetId);
  };

  const onDeleteAsset = async () => {
    setIsLoadingDelete(true);
    try {
      await DeleteAsset(assetId);
      messageAtom.success(t('_ASSET_DELETE_MODAL_SUBMIT_SUCCESS'), 3);
      setShowConfirmDeleteModal(false);
      history.push(ROUTE_PATH_ASSETS);
    } catch (error) {
      messageAtom.error(t('_ASSET_DELETE_MODAL_SUBMIT_ERROR'), 3);
    } finally {
      setIsLoadingDelete(false);
      setShowConfirmDeleteModal(false);
    }
  };

  const onClickDelete = async () => {
    try {
      const { data, included } = await getAsset(assetId, 'users');

      const hasAssetActiveUsers = !!included?.users?.length;
      const hasAssetLinkedGateways = !!data?.gateways_qrs?.length;
      const isAssetRemovable = !hasAssetActiveUsers && !hasAssetLinkedGateways;

      hasAssetLinkedGateways && setShowLinkedGatewaysInfoModal(true);
      hasAssetActiveUsers && setShowActiveUsersInfoModal(true);
      isAssetRemovable && setShowConfirmDeleteModal(true);
    } catch {
      messageAtom.error(t('_ASSET_FETCH_DATA_ERROR'), 3);
    }
  };

  const onCancelDelete = async () => {
    setShowConfirmDeleteModal(false);
    setShowActiveUsersInfoModal(false);
    setShowLinkedGatewaysInfoModal(false);
  };

  const onClickAgree = async () => {
    setShowActiveUsersInfoModal(false);
    setShowLinkedGatewaysInfoModal(false);
  };

  const onClickClientLink = (clientId: string) => {
    const route = getClientPathById({ clientId });
    history.push(route);
  };

  const onClickProjectLink = (projectId: string) => {
    const route = getProjectPathById({ projectId });
    history.push(route);
  };

  return {
    asset,
    project,
    client,
    deleteModalTitle,
    assetGatewayConnectionStatusTranslations,
    editAsset,
    onDeleteAsset,
    onClickDelete,
    onCancelDelete,
    onClickAgree,
    onClickClientLink,
    onClickProjectLink,
    showConfirmDeleteModal,
    showActiveUsersInfoModal,
    showLinkedGatewaysInfoModal,
    isLoading,
    isLoadingUpdate,
    isLoadingDelete,
    hasPermissionToDeleteAsset,
  };
};
