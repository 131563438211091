import { useTranslation } from 'react-i18next';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import TableActionsContent from 'components/organisms/TableActionsContent';
import { TableActionsMenuProps } from 'components/atoms/TableAtom';
import './styles.scss';

interface ConciergeTabColumnsOptions {
  usefulContactsTableActionsMenu: TableActionsMenuProps[];
}

export const ConciergeTabColumns = <T extends {}>({ usefulContactsTableActionsMenu }: ConciergeTabColumnsOptions): AlfredTableColumn<T>[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('title'),
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      key: 'description',
      width: '40%',
      className: 'ConciergeTabColumns__description'
    },
    {
      title: t('link'),
      dataIndex: 'link',
      key: 'link',
      render: (text, { link }: any) => {
        return (
          <a
            className={'ConciergeTabColumns__link'}
            href={link}
            target={'_blank'}
            rel={'noreferrer'}
            onClick={(event) => event.stopPropagation()}
          >
            {link}
          </a>
        );
      },
      width: '25%',
    },
    {
      title: t('actions'),
      key: 'action',
      width: '10%',
      render: (text, { id }: any) => (
        <div key={id} onClick={(event) => event.stopPropagation()}>
          <TableActionsContent menu={usefulContactsTableActionsMenu} id={id} />
        </div>
      ),
    },
  ];
};
