import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GatewayDetailStatusModel, GatewayParsed } from 'core/domain/gateways/model/gateway/gatewayParsed';
import { GetGatewayDetail } from 'core/domain/gateways/repositories/getGatewayDetail';
import { GetLastGatewayModulesVersions } from 'core/domain/gateways/repositories/getLastGatewayModulesVersions';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';
import {
  GatewayModuleType,
  GatewayUpdateLatestModulesVersionsModel,
  GatewayUpdateModel,
  GatewayUpdateProcessType,
} from 'core/domain/gateways/model';
import {
  CheckValuesManager,
  CheckValuesType,
  CustomGatewayDataModel,
  GatewayModuleCheckerModel,
  GatewayStatusCheckValueModel,
  GatewayStatusInformationTranslations,
  GatewayStatusKeys,
  GatewayStatusModuleConfig,
  GatewayStatusPercentValueInfoModel,
  GatewayStatusPercentValueModel,
  INITIAL_CUSTOM_GATEWAY,
  PercentValuesType,
} from 'components/pages/AssetPage/Tabs/TabGateway/resources/utils';
import { UserPermissionType, UserRoleType } from 'models/users.model';
import { useProfileUser } from 'hooks/useProfileUser';
import { CheckGatewayModuleVersionUpdateState } from 'core/domain/gateways/repositories/checkGatewayModuleVersionUpdateState';
import { GatewayStatusModel } from 'core/domain/gateways/model/gatewayStatus';
import { useTranslation } from 'react-i18next';
import { Thresholds } from 'core/domain/gateways/model/thresholds';
import { checkIfVersionIsOutdated } from 'utils/checkVersions';
import {
  GatewayNetworkInterfaceModel,
  GatewayNetworkStateStatusModel,
} from 'core/domain/gateways/model/gatewayStatus/gatewayStatusPayload';
import { ActiveIssue } from 'core/domain/gateways/model/activeIssue';
import { UpdateGatewayModuleVersion } from 'core/domain/gateways/repositories/updateGatewayModuleVersion';
import { hasPermission } from 'services/permissions';
import { CHECK_UPDATE_GATEWAY_MODULE_TIME_INTERVAL } from 'constants/time';

export const useSharedGateway = () => {
  const { t } = useTranslation();
  const { gatewayId } = useParams<{ gatewayId: string }>();
  const { token } = useUserSession();
  const { host } = useEnvironment();
  const { data: userData } = useProfileUser();
  const [gateway, setGateway] = useState<CustomGatewayDataModel>(INITIAL_CUSTOM_GATEWAY);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const hasPermissionToEditGatewayLink =
    hasPermission(UserPermissionType.ASSIGN_GATEWAY) && hasPermission(UserPermissionType.UNASSIGN_GATEWAY);
  const isUserRoleSuperAdmin = userData?.roles.includes(UserRoleType.ROLE_ASSETS_MANAGER_SUPER_ADMIN);

  const gatewayStatusInformationTranslations: GatewayStatusInformationTranslations = {
    title: t('information'),
    notReportedText: t('not_reported'),
    productionDateText: t('date_of_production'),
    networkStateText: t('_GATEWAY_STATUS_DETAIL_NETWORK_STATE'),
    networkStateStatusTranslations: {
      [GatewayNetworkStateStatusModel.GOOD]: t('_GATEWAY_STATUS_NETWORK_STATE_STATUS_GOOD'),
      [GatewayNetworkStateStatusModel.MEDIUM]: t('_GATEWAY_STATUS_NETWORK_STATE_STATUS_REGULAR'),
      [GatewayNetworkStateStatusModel.BAD]: t('_GATEWAY_STATUS_NETWORK_STATE_STATUS_BAD'),
      [GatewayNetworkStateStatusModel.UNKNOWN]: t('not_reported'),
    },
    networkStateLatencyText: t('_GATEWAY_STATUS_NETWORK_STATE_LATENCY'),
    networkStateBandWidthText: t('_GATEWAY_STATUS_NETWORK_STATE_BAND_WIDTH'),
    networkStateLostPackagesText: t('_GATEWAY_STATUS_NETWORK_STATE_LOST_PACKAGES'),
    networkInterfaceText: t('_GATEWAY_STATUS_DETAIL_CONNECTION_TYPE'),
    networkInterfaceDetailTranslations: {
      [GatewayNetworkInterfaceModel.ETHERNET]: t('_GATEWAY_STATUS_CONNECTION_TYPE_ETHERNET'),
      [GatewayNetworkInterfaceModel.WIFI]: t('_GATEWAY_STATUS_CONNECTION_TYPE_WIFI'),
      [GatewayNetworkInterfaceModel.LTE]: t('_GATEWAY_STATUS_CONNECTION_TYPE_LTE'),
    },
    lastConnectionText: t('last_connection'),
    statusText: t('status'),
    statusOfflineText: t('offline'),
    statusOnlineText: t('online'),
    healthText: t('health'),
    activeIssuesText: t('active_issues'),
    activeIssuesDetailTranslations: {
      [ActiveIssue.ASSISTANT_CLOUD_SERVICE]: t('ACTIVE_ISSUES.assistant_cloud_service'),
      [ActiveIssue.CPU_HDD_USED]: t('ACTIVE_ISSUES.cpu_hdd_used'),
      [ActiveIssue.CPU_HD_AVAILABLE]: t('ACTIVE_ISSUES.cpu_hd_available'),
      [ActiveIssue.CPU_HD_USED]: t('ACTIVE_ISSUES.cpu_hd_used'),
      [ActiveIssue.CPU_LOAD]: t('ACTIVE_ISSUES.cpu_load'),
      [ActiveIssue.CPU_RAM]: t('ACTIVE_ISSUES.cpu_ram'),
      [ActiveIssue.CPU_RAM_AVAILABLE]: t('ACTIVE_ISSUES.cpu_ram_available'),
      [ActiveIssue.CPU_RAM_USED]: t('ACTIVE_ISSUES.cpu_ram_used'),
      [ActiveIssue.CPU_TEMPERATURE]: t('ACTIVE_ISSUES.cpu_temperature'),
      [ActiveIssue.OHS_API]: t('ACTIVE_ISSUES.ohs_api'),
      [ActiveIssue.OHS_SERVICE]: t('ACTIVE_ISSUES.ohs_service'),
      [ActiveIssue.ONLINE]: t('ACTIVE_ISSUES.online'),
      [ActiveIssue.OPENHAB_API]: t('ACTIVE_ISSUES.openhab_api'),
      [ActiveIssue.OPENHAB_SERVICED]: t('ACTIVE_ISSUES.openhab_serviced'),
      [ActiveIssue.SYSTEM_TIME]: t('ACTIVE_ISSUES.system_time'),
      [ActiveIssue.TOTAL_INODES]: t('ACTIVE_ISSUES.total_inodes'),
      [ActiveIssue.TOTAL_SWAP]: t('ACTIVE_ISSUES.total_swap'),
      [ActiveIssue.UPTIME]: t('ACTIVE_ISSUES.uptime'),
      [ActiveIssue.USED_INODES]: t('ACTIVE_ISSUES.used_inodes'),
      [ActiveIssue.USED_SWAP]: t('ACTIVE_ISSUES.used_swap'),
      [ActiveIssue.VPN_CONNECTION]: t('ACTIVE_ISSUES.vpn_connection'),
      [ActiveIssue.VPN_IP]: t('ACTIVE_ISSUES.vpn_ip'),
      [ActiveIssue.ZWAVE_CONTROLLER]: t('ACTIVE_ISSUES.zwave_controller'),
    },
    gatewayVersionText: t('gw_version'),
    gatewayVersionUpdateButtonText: t('_GATEWAY_UPDATE_BUTTON_TEXT', { version: gateway?.systemConfig.lastVersion }),
    updateModuleErrorMessage: t('_GATEWAY_UPDATE_MODULE_VERSION_ERROR'),
    aassUpdateButtonText: t('_GATEWAY_UPDATE_BUTTON_TEXT', { version: gateway?.aassConfig.lastVersion }),
    updateButtonInProgressText: t('updating'),
  };

  const checkValuesManager: CheckValuesManager = {
    [CheckValuesType.ZWAVE_CONTROLLER]: (element: boolean): GatewayStatusCheckValueModel => ({
      check: element,
      text: t(CheckValuesType.ZWAVE_CONTROLLER),
    }),
    [CheckValuesType.HELPER_API]: (element: boolean): GatewayStatusCheckValueModel => ({
      check: element,
      text: t(CheckValuesType.HELPER_API),
    }),
    [CheckValuesType.VPN_CONNECTION]: (element: boolean): GatewayStatusCheckValueModel => ({
      check: element,
      text: t(CheckValuesType.VPN_CONNECTION),
    }),
    [CheckValuesType.ASSISTANT_CLOUD_SERVICE]: (element: boolean): GatewayStatusCheckValueModel => ({
      check: element,
      text: t(CheckValuesType.ASSISTANT_CLOUD_SERVICE),
    }),
  };

  const getGatewaysStatusCheckedValues = (gateway: GatewayStatusModel) => {
    const gatewayKeys: GatewayStatusKeys[] = Object.keys(gateway) as GatewayStatusKeys[];
    const filteredGatewayKeys = gatewayKeys.filter((key) => Object.values(CheckValuesType).includes(key as CheckValuesType));
    const checkedValues: GatewayStatusCheckValueModel[] = [];
    filteredGatewayKeys.forEach((gatewayKey) => {
      if (typeof gateway[gatewayKey] === 'boolean') {
        const checkedFunction = checkValuesManager[gatewayKey as CheckValuesType];
        checkedValues.push(checkedFunction(gateway[gatewayKey] as boolean));
      }
    });
    return checkedValues;
  };

  const percentValuesManager = {
    [PercentValuesType.CPU_TEMPERATURE]: ({
      value,
      threshold,
      units,
    }: GatewayStatusPercentValueInfoModel): GatewayStatusPercentValueModel => ({
      value,
      threshold,
      unit: 'ºC',
      text: t(PercentValuesType.CPU_TEMPERATURE),
    }),
    [PercentValuesType.CPU_RAM_USED]: ({
      value,
      threshold,
      units,
    }: GatewayStatusPercentValueInfoModel): GatewayStatusPercentValueModel => ({
      value,
      threshold,
      unit: '%',
      text: t(PercentValuesType.CPU_RAM_USED),
    }),
    [PercentValuesType.CPU_HDD_USED]: ({
      value,
      threshold,
      units,
    }: GatewayStatusPercentValueInfoModel): GatewayStatusPercentValueModel => ({
      value,
      threshold,
      unit: '%',
      text: t(PercentValuesType.CPU_HDD_USED),
    }),
    [PercentValuesType.CPU_LOAD]: ({ value, threshold, units }: GatewayStatusPercentValueInfoModel): GatewayStatusPercentValueModel => ({
      value,
      threshold,
      unit: '',
      text: t(PercentValuesType.CPU_LOAD),
    }),
  };

  const getGatewaysStatusPercentValues = (gateway: GatewayDetailStatusModel) => {
    const gatewayKeys: GatewayStatusKeys[] = Object.keys(gateway) as GatewayStatusKeys[];
    const filteredGatewayKeys = gatewayKeys.filter((key) => Object.values(PercentValuesType).includes(key as PercentValuesType));
    const checkedValues: GatewayStatusPercentValueModel[] = [];
    filteredGatewayKeys.forEach((gatewayKey) => {
      if (typeof gateway[gatewayKey] === 'number') {
        const checkedFunction = percentValuesManager[gatewayKey as PercentValuesType];
        checkedValues.push(
          checkedFunction({
            value: gateway[gatewayKey] as number,
            threshold: gateway.thresholds[gatewayKey as keyof Thresholds] as number,
            units: gateway.units,
          })
        );
      }
    });
    return checkedValues;
  };

  const payloadToCustomGatewayStatusData = ({
    gatewayDetail,
    versions,
    updatingModule,
  }: {
    gatewayDetail: GatewayParsed;
    versions: GatewayUpdateLatestModulesVersionsModel;
    updatingModule?: GatewayModuleType;
  }): CustomGatewayDataModel => {
    const { aass, system } = versions;
    const { daemonVersion, gatewayVersion } = gatewayDetail;
    const checkedValues = !!gatewayDetail.status ? getGatewaysStatusCheckedValues(gatewayDetail.status) : [];
    const percentValues = !!gatewayDetail.status ? getGatewaysStatusPercentValues(gatewayDetail.status) : [];
    const isAssButtonEnabled = checkIfVersionIsOutdated({ minimumVersion: aass, version: daemonVersion });
    const isSystemButtonEnabled = checkIfVersionIsOutdated({ minimumVersion: system, version: gatewayVersion });
    const isAassVersionUpdating = !!updatingModule && updatingModule === GatewayModuleType.AASS;
    const isSystemVersionUpdating = !!updatingModule && updatingModule === GatewayModuleType.SYSTEM;

    return {
      ...gatewayDetail,
      checkedValues,
      percentValues,
      aassConfig: {
        isVersionUpdateError: false,
        isVersionUpdating: isAassVersionUpdating,
        isUpdateButtonDisabled: isSystemVersionUpdating,
        isUpdateButtonVisible: !!isUserRoleSuperAdmin && isAssButtonEnabled,
        lastVersion: aass,
      },
      systemConfig: {
        isVersionUpdateError: false,
        isVersionUpdating: isSystemVersionUpdating,
        isUpdateButtonDisabled: isAassVersionUpdating,
        isUpdateButtonVisible: !!isUserRoleSuperAdmin && isSystemButtonEnabled,
        lastVersion: system,
      },
    };
  };

  const getGatewayCompletedStatus = async (versions: GatewayUpdateLatestModulesVersionsModel) => {
    try {
      const gatewayDetail = await GetGatewayDetail(gatewayId);
      return payloadToCustomGatewayStatusData({ gatewayDetail, versions });
    } catch (error) {
      console.warn(error);
      return INITIAL_CUSTOM_GATEWAY;
    }
  };

  const blockUpdateButtonIfOtherModuleIsUpdating = ({
    aassConfig,
    systemConfig,
  }: {
    aassConfig: GatewayStatusModuleConfig;
    systemConfig: GatewayStatusModuleConfig;
  }): { newAassConfig: GatewayStatusModuleConfig; newSystemConfig: GatewayStatusModuleConfig } => {
    const newAassConfig: GatewayStatusModuleConfig = { ...aassConfig, isUpdateButtonDisabled: systemConfig.isVersionUpdating };
    const newSystemConfig: GatewayStatusModuleConfig = { ...systemConfig, isUpdateButtonDisabled: aassConfig.isVersionUpdating };
    return { newAassConfig, newSystemConfig };
  };

  const updateFailedModuleConfig = (module: GatewayStatusModuleConfig): GatewayStatusModuleConfig => {
    const isGatewayModuleUpdatedToLastVersion = !module.isUpdateButtonVisible;
    const isError = !!isUserRoleSuperAdmin && !isGatewayModuleUpdatedToLastVersion;
    return { ...module, isVersionUpdateError: isError };
  };

  const moduleVersionUpdateInitialStateManager = {
    [GatewayUpdateProcessType.COMPLETED]: (module: GatewayStatusModuleConfig) => ({ ...module }),
    [GatewayUpdateProcessType.IN_PROGRESS]: (module: GatewayStatusModuleConfig) => ({ ...module, isVersionUpdating: true }),
    [GatewayUpdateProcessType.FAILED]: updateFailedModuleConfig,
  };

  const checkGatewayModulesUpdateState = async (gateway: CustomGatewayDataModel) => {
    try {
      const modules = Object.values(GatewayModuleType) as GatewayModuleType[];
      const filteredModules: GatewayModuleCheckerModel[] = [];
      await Promise.all(
        modules.map(async (module) => {
          const response = await CheckGatewayModuleVersionUpdateState({ host, token, gatewayId, module });
          !!response && response.state !== GatewayUpdateProcessType.COMPLETED && filteredModules.push({ module, state: response.state });
        })
      );
      if (!!filteredModules.length) {
        let aassConfig: GatewayStatusModuleConfig = { ...gateway.aassConfig };
        let systemConfig: GatewayStatusModuleConfig = { ...gateway.systemConfig };
        filteredModules.forEach(({ module, state }) => {
          state === GatewayUpdateProcessType.IN_PROGRESS && setIsUpdating(true);

          if (module === GatewayModuleType.AASS) {
            const aassModule: GatewayStatusModuleConfig = moduleVersionUpdateInitialStateManager[state](aassConfig);
            aassConfig = { ...aassModule };
          }
          if (module === GatewayModuleType.SYSTEM) {
            const systemModule: GatewayStatusModuleConfig = moduleVersionUpdateInitialStateManager[state](gateway.systemConfig);
            systemConfig = { ...systemModule };
          }
        });
        const { newAassConfig, newSystemConfig } = blockUpdateButtonIfOtherModuleIsUpdating({ aassConfig, systemConfig });
        setGateway({ ...gateway, aassConfig: newAassConfig, systemConfig: newSystemConfig });
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const getModulesVersions = async (): Promise<GatewayUpdateLatestModulesVersionsModel> => {
    try {
      return await GetLastGatewayModulesVersions({ host, token });
    } catch (error) {
      return { aass: '0.0.0', system: '0.0.0' };
    }
  };

  const getGatewaysListData = async () => {
    setIsLoading(true);

    try {
      const gatewayModulesLastVersions = await getModulesVersions();
      const gatewayCompletedStatus = await getGatewayCompletedStatus(gatewayModulesLastVersions);
      setGateway(gatewayCompletedStatus);
      gatewayCompletedStatus && checkGatewayModulesUpdateState(gatewayCompletedStatus);
    } catch (error) {
      console.warn(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateAassModuleInProgress = (currentGateway: CustomGatewayDataModel): CustomGatewayDataModel => {
    const aassConfig: GatewayStatusModuleConfig = { ...currentGateway.aassConfig, isVersionUpdating: true, isVersionUpdateError: false };
    const systemConfig: GatewayStatusModuleConfig = { ...currentGateway.systemConfig, isUpdateButtonDisabled: true };
    return { ...currentGateway, aassConfig, systemConfig };
  };

  const updateSystemModuleInProgress = (currentGateway: CustomGatewayDataModel): CustomGatewayDataModel => {
    const aassConfig: GatewayStatusModuleConfig = { ...currentGateway.aassConfig, isUpdateButtonDisabled: true };
    const systemConfig: GatewayStatusModuleConfig = {
      ...currentGateway.systemConfig,
      isVersionUpdating: true,
      isVersionUpdateError: false,
    };
    return { ...currentGateway, aassConfig, systemConfig };
  };

  const updateGatewayModuleManager = {
    [GatewayModuleType.AASS]: (gateway: CustomGatewayDataModel) => updateAassModuleInProgress(gateway),
    [GatewayModuleType.SYSTEM]: (gateway: CustomGatewayDataModel) => updateSystemModuleInProgress(gateway),
  };

  const transformGatewayToShowUpdateInProgress = (module: GatewayModuleType) => {
    const newGatewayStatus = updateGatewayModuleManager[module](gateway);
    setGateway(newGatewayStatus);
    setIsUpdating(true);
  };

  const onUpdateGatewayModule = async ({ gateway, module }: GatewayUpdateModel) => {
    try {
      transformGatewayToShowUpdateInProgress(module);
      await UpdateGatewayModuleVersion({ host, token, gatewayId: gateway.id, module });
    } catch (error) {
      moduleVersionUpdateStateManager[GatewayUpdateProcessType.FAILED]({ module, gateway });
    }
  };

  const updateGatewayAassModuleVersionStateError = (currentGateway: CustomGatewayDataModel): CustomGatewayDataModel => {
    const aassConfig: GatewayStatusModuleConfig = { ...currentGateway.aassConfig, isVersionUpdating: false, isVersionUpdateError: true };
    const systemConfig: GatewayStatusModuleConfig = { ...currentGateway.systemConfig, isUpdateButtonDisabled: false };
    return { ...currentGateway, aassConfig, systemConfig };
  };

  const updateGatewaySystemModuleVersionStateError = (currentGateway: CustomGatewayDataModel): CustomGatewayDataModel => {
    const aassConfig: GatewayStatusModuleConfig = { ...currentGateway.aassConfig, isUpdateButtonDisabled: false };
    const systemConfig: GatewayStatusModuleConfig = {
      ...currentGateway.systemConfig,
      isVersionUpdating: false,
      isVersionUpdateError: true,
    };
    return { ...currentGateway, aassConfig, systemConfig };
  };

  const updateModuleVersionStateErrorManager = {
    [GatewayModuleType.AASS]: updateGatewayAassModuleVersionStateError,
    [GatewayModuleType.SYSTEM]: updateGatewaySystemModuleVersionStateError,
  };

  const transformGatewaysToShowUpdateError = ({ module, gateway }: GatewayUpdateModel) => {
    const newGateway = updateModuleVersionStateErrorManager[module](gateway);
    setGateway(newGateway);
  };

  const moduleVersionUpdateStateManager = {
    [GatewayUpdateProcessType.COMPLETED]: getGatewaysListData,
    [GatewayUpdateProcessType.IN_PROGRESS]: () => {},
    [GatewayUpdateProcessType.FAILED]: transformGatewaysToShowUpdateError,
  };

  const checkUpdateStatus = async (module: GatewayModuleType) => {
    try {
      const response = await CheckGatewayModuleVersionUpdateState({ host, token, gatewayId, module });
      moduleVersionUpdateStateManager[response.state]({ module, gateway });
      response.state !== GatewayUpdateProcessType.IN_PROGRESS && setIsUpdating(false);
    } catch (error) {
      moduleVersionUpdateStateManager[GatewayUpdateProcessType.FAILED]({ module, gateway });
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    getGatewaysListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isUpdating) {
      intervalId = setInterval(() => {
        const { aassConfig, systemConfig } = gateway;
        aassConfig.isVersionUpdating && checkUpdateStatus(GatewayModuleType.AASS);
        systemConfig.isVersionUpdating && checkUpdateStatus(GatewayModuleType.SYSTEM);
      }, CHECK_UPDATE_GATEWAY_MODULE_TIME_INTERVAL);
    }
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdating]);

  return {
    gateway,
    translations: gatewayStatusInformationTranslations,
    onUpdateGatewayModule,
    loading: isLoading,
    error: isError,
    hasPermissionToEditGatewayLink,
  };
};
