export const checkIfHexColorIsLight = (backgroundColor: string) => {
  const hex = String(backgroundColor).replace(/[^0-9a-f]/gi, '');
  const longHex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  const hexColor = hex.length < 6 ? longHex : hex;

  const rgb = parseInt(hexColor, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  return hsp > 127.5;
};
