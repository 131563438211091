import TabEmpty from 'components/molecules/TabEmpty';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const translationsManager = {
  TITLE: '_COMMON_AREA_BOOKINGS_EMPTY_TITLE',
  TEXT: '_COMMON_AREA_BOOKINGS_EMPTY_TEXT',
  LINK_TEXT: '_COMMON_AREA_BOOKINGS_ADD_SPACE',
};

interface CommonAreaBookingsEmptyTemplateProps {
  onVisibleCreate: () => void;
}

const CommonAreaBookingsEmptyTemplate: FC<CommonAreaBookingsEmptyTemplateProps> = ({ onVisibleCreate }) => {
  const { t } = useTranslation();

  return (
    <div className={'CommonAreaBookingsEmptyTemplate__container'}>
      <div className={'CommonAreaBookingsEmptyTemplate__empty'}>
        <TabEmpty
          title={t(translationsManager.TITLE)}
          text={t(translationsManager.TEXT)}
          linkText={t(translationsManager.LINK_TEXT)}
          onClick={onVisibleCreate}
        />
      </div>
    </div>
  );
};

export default CommonAreaBookingsEmptyTemplate;
