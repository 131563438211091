import { FC } from 'react';
import './styles.scss';

interface Props {
  openedText: string;
  acceptedText: string;
  inProgressText: string;
  lastWeekCreatedText: string;
  lastMonthCreatedText: string;
  title: string;
  subtitle: string;
}

const KpisErrorTemplate: FC<Props> = ({
  openedText,
  acceptedText,
  inProgressText,
  lastWeekCreatedText,
  lastMonthCreatedText,
  title,
  subtitle,
}) => {
  return (
    <div className='KpisErrorTemplate__container'>
      <div className='KpisErrorTemplate__header'>
        <p className='KpisErrorTemplate__title'>{title}</p>
        <p className='KpisErrorTemplate__subtitle'>{subtitle}</p>
      </div>
      <div className='KpisErrorTemplate__chart-container'>
        <div className='KpisErrorTemplate__chart-element'>
          <p className='KpisErrorTemplate__chart-element-title'>
            {openedText}
          </p>
          <span className='KpisErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='KpisErrorTemplate__chart-element'>
          <p className='KpisErrorTemplate__chart-element-title'>
            {acceptedText}
          </p>
          <span className='KpisErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='KpisErrorTemplate__chart-element'>
          <p className='KpisErrorTemplate__chart-element-title'>
            {inProgressText}
          </p>
          <span className='KpisErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='KpisErrorTemplate__chart-element'>
          <p className='KpisErrorTemplate__chart-element-title'>
            {lastWeekCreatedText}
          </p>
          <span className='KpisErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='KpisErrorTemplate__chart-element'>
          <p className='KpisErrorTemplate__chart-element-title'>
            {lastMonthCreatedText}
          </p>
          <span className='KpisErrorTemplate__chart-element-data'>-</span>
        </div>
      </div>
    </div>
  );
}

export default KpisErrorTemplate;