import { FC } from 'react';
import { Switch } from 'antd';
import Paper from 'components/atoms/Paper';
import { FormInstance } from 'antd/es/form/Form';
import { RcFile } from 'antd/lib/upload';
import LegalDocumentationForm from 'components/molecules/LegalDocumentationForm';
import {
  CustomLegalDocumentationModel,
  LegalDocumentationActionsNames,
  LegalDocumentationContentTranslationsModel,
  LegalDocumentationFormChangesModel,
  LegalDocumentationFormValues,
} from 'components/pages/ClientDetailPage/TabLegalDocumentation/resources/config';
import './styles.scss';

interface Props {
  contentTranslations: LegalDocumentationContentTranslationsModel;
  form: FormInstance<LegalDocumentationFormValues>;
  acceptedFormatFiles: string;
  legalDocumentation: CustomLegalDocumentationModel
  onEnable: () => void;
  onDisable: () => void;
  onSaveForm: () => void;
  onDeleteForm: () => void;
  onCloseForm: () => void;
  onChangeValueForm: ({ label, file }: LegalDocumentationFormChangesModel) => void;
  onChangeActionForm: (label: LegalDocumentationActionsNames) => void;
  onCheckBeforeUploadValueForm: (file: RcFile) => void;
  enabledSwitch: boolean;
  processing: boolean;
  termsOfServiceUploadButtonDisabled: boolean;
  termsOfServiceUploadInfoVisible: boolean;
  privacyPolicyUploadButtonDisabled: boolean;
  privacyPolicyUploadInfoVisible: boolean;
  saveButtonDisabled: boolean;
  deleteButtonVisible: boolean;
  isDefault: boolean;
}

const LegalDocumentationContentTemplate: FC<Props> = ({
  form,
  contentTranslations,
  acceptedFormatFiles,
  legalDocumentation,
  onEnable,
  onDisable,
  onSaveForm,
  onCloseForm,
  onDeleteForm,
  onChangeValueForm,
  onChangeActionForm,
  onCheckBeforeUploadValueForm,
  enabledSwitch,
  processing,
  termsOfServiceUploadButtonDisabled,
  termsOfServiceUploadInfoVisible,
  privacyPolicyUploadButtonDisabled,
  privacyPolicyUploadInfoVisible,
  saveButtonDisabled,
  deleteButtonVisible,
  isDefault,
}) => {
  const { title } = contentTranslations;
  const { termsOfServiceName, privacyPolicyName, termsOfServiceShowButtonVisible, privacyPolicyShowButtonVisible } = legalDocumentation;

  return (
    <Paper>
      <div className={'LegalDocumentationContentTemplate__header'}>
        <h2>{title}</h2>
        <Switch checked={enabledSwitch} onChange={!!enabledSwitch ? onDisable : onEnable} loading={false} disabled={isDefault} />
      </div>
      <div className={'LegalDocumentationContentTemplate__form'}>
        <LegalDocumentationForm
          contentTranslations={contentTranslations}
          form={form}
          acceptedTypes={acceptedFormatFiles}
          termsOfServiceName={termsOfServiceName}
          privacyPolicyName={privacyPolicyName}
          onSave={onSaveForm}
          onClose={onCloseForm}
          onDelete={onDeleteForm}
          onChange={onChangeValueForm}
          onChangeAction={onChangeActionForm}
          onCheckBeforeUpload={onCheckBeforeUploadValueForm}
          processing={processing}
          termsOfServiceUploadButtonDisabled={termsOfServiceUploadButtonDisabled}
          termsOfServiceUploadInfoVisible={termsOfServiceUploadInfoVisible}
          termsOfServiceShowButtonVisible={termsOfServiceShowButtonVisible}
          privacyPolicyUploadButtonDisabled={privacyPolicyUploadButtonDisabled}
          privacyPolicyUploadInfoVisible={privacyPolicyUploadInfoVisible}
          privacyPolicyShowButtonVisible={privacyPolicyShowButtonVisible}
          saveButtonDisabled={saveButtonDisabled}
          deleteButtonVisible={deleteButtonVisible && !isDefault}
        />
      </div>
    </Paper>
  );
}

export default LegalDocumentationContentTemplate;