import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { LocalStorageKeys } from 'constants/localStorage';
import { getErrorFormat } from 'core/utils/errors';
import { LoginModel, LoginPayload } from '../models';
import { payloadToLogin } from '../mappers';
import { savePermissions } from 'services/permissions';

const getUrl = (host: string) => {
  return `${host}/users/login`;
};

export const SetLogin = async ({ host, email, password }: { host: string; email: string; password: string }): Promise<LoginModel> => {
  try {
    const url = getUrl(host);

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept-version': '1',
      },
      data: { email, password },
    };

    const { data } = (await axios(options)) as AxiosResponse<LoginPayload>;
    const mappedData = payloadToLogin(data);
    localStorage.setItem(LocalStorageKeys.TOKEN, mappedData.token);
    savePermissions(mappedData.permissions);
    return mappedData;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
