import api from 'services/api';
import { GatewayDetailResponse } from 'models/gateways.model';
import { ListResponse } from 'models/general.model';
import {
  AlertsResponse,
  Query,
  GatewayStatus,
  GatewayStatusUnits,
  GatewayStatusResponse,
  GatewayStatusDevices,
  GatewayStatusGIDResponse,
} from 'models/gateways.model';
import { getAsset } from './assets';
import { MaintenanceAction } from 'core/domain/gateways/model/reportMaintenance';

export const getGatewayDetail = async (gatewayId: string): Promise<GatewayDetailResponse> => await api.get(`/gateways/${gatewayId}`);

interface GetGatewayStatusOptions {
  activeIssues?: string;
  assetId?: string;
  projectId?: string;
}

export const getGatewayStatus = async ({ activeIssues, assetId, projectId }: GetGatewayStatusOptions): Promise<GatewayStatusResponse> => {
  const params = new URLSearchParams();
  if (activeIssues) params.append('active_issues', `${activeIssues}`);
  if (assetId) params.append('asset_id', `${assetId}`);
  if (projectId) params.append('project_id', `${projectId}`);
  return await api.get('/gateways/status', { params });
};

export const getGatewayStatusGID = async (assetId: string): Promise<GatewayStatusGIDResponse> => {
  const assetResponse = await getAsset(assetId);
  if (assetResponse) {
    const { data: { gateways_qrs } } = assetResponse;
    const getwayStatusResponse = await getGatewayStatus({ assetId });
    if (getwayStatusResponse) {
      const { data: gatewayStatusData, units } = getwayStatusResponse;
      const gatewayStatusComplete = await Promise.all(
        gatewayStatusData.map(async (gateway): Promise<GatewayStatusDevices> => {
          const currentGatewaysQrs = gateways_qrs.find((element) => gateway.gateway_id === element.gateway_id);
          const gatewayDetail = await getGatewayDetail(gateway.gateway_id);
  
          return {
            ...gateway,
            sku: gatewayDetail.data.sku,
            daemon_version: gatewayDetail.data.daemon_version,
            qr_code: currentGatewaysQrs?.qr_code,
            batch_id: gatewayDetail.data.batch_id,
            created_at: gatewayDetail.data.devices?.created_at,
            management_links: gatewayDetail.data.management_links,
          };
        })
      );
      return {
        data: gatewayStatusComplete,
        units: units,
        gatewayId: gateways_qrs.length ? gateways_qrs[0].gateway_id : '',
        gatewayQid: gateways_qrs.length ? gateways_qrs[0].qr_code : '',
      };
    }
  }
  return {
    data: [],
    units: {} as GatewayStatusUnits,
    gatewayId: '',
    gatewayQid: '',
  };
};

export const getGatewayStatusList = async (
  current: number,
  size: number,
  sort?: string,
  filter?: string
): Promise<ListResponse<GatewayStatus, {}, {}, GatewayStatusUnits>> => {
  const params = new URLSearchParams();
  params.append('page[number]', `${current}`);
  params.append('page[size]', `${size}`);
  if (sort) params.append('page[sort]', `${sort}`);
  if (filter) params.append('page[filter]', `${filter}`);
  return await api.get('/gateways/status', { params });
};

const queryString = (query: Query) =>
  Object.keys(query)
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
    })
    .join('&');

export const getGatewayAlerts = (query?: Query) => {
  const qs = query ? queryString(query) : '';
  return async (current: number, size: number, sort?: string): Promise<AlertsResponse> => {
    const params = new URLSearchParams();
    params.append('query', qs);
    params.append('page[number]', `${current}`);
    params.append('page[size]', `${size}`);
    if (sort) params.append('page[sort]', `${sort}`);
    return await api.get('/gateways/alerts', { params });
  };
};

export const updateGatewayInformation = async (gatewayId: string, qrGatewayId: string) => {
  const data = {
    attributes: {
      qr_gateway_id: qrGatewayId,
    },
  };
  return await api.patch(`/gateways/${gatewayId}`, {
    data,
  });
};

export const updateActionToDevice = async (gatewayId: string, deviceId: string, data: { action: MaintenanceAction; body: string }) => {
  api.post(`/gateways/${gatewayId}/devices/${deviceId}/actions`, {
    data,
  });
};

export const checkIsAssetLinkedToGateway = async (assetId: string) => {
  try {
    const {
      data: { gateways_qrs },
    } = await getAsset(assetId);
    return !!gateways_qrs.length;
  } catch (error) {
    console.error(error);
    return false;
  }
};
