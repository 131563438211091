import { FC, ReactNode } from 'react';
import { PageHeader, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTab } from 'hooks/useTab';
import './styles.scss';

interface AlfredDetailTemplateProps {
  currentTabKey: string;
  extra: ReactNode;
  description: ReactNode;
  tabsTitles: (currentTab: string) => ReactNode;
  loading: boolean;
  title: string;
  logo?: string;
  children: { key: string; render: ReactNode }[];
}

const AlfredDetailTemplate: FC<AlfredDetailTemplateProps> = ({
  currentTabKey,
  description,
  tabsTitles,
  loading,
  extra,
  title,
  logo,
  children,
}) => {
  const history = useHistory();
  const { activeTab } = useTab(currentTabKey);

  const goBack = () => history.goBack();

  return (
    <>
      <PageHeader
        className='AlfredDetailTemplate__header'
        ghost={false}
        onBack={goBack}
        title={
          loading ? (
            <Skeleton.Input className='AlfredDetailTemplate__title' active />
          ) : (
            <div>
              {title} {logo && <img className='ProjectPage__logo' src={logo} alt='' />}
            </div>
          )
        }
        extra={extra}
        footer={tabsTitles(activeTab)}
      >
        {loading ? <Skeleton active /> : description}
      </PageHeader>
      <div className='AlfredDetailTemplate__tabs'>
        {children.map((element) => {
          return activeTab === element.key && element.render;
        })}
      </div>
    </>
  );
};

export default AlfredDetailTemplate;
