import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface QrScannerOptionsModel {
  modalQrTitle: string;
  maxQidLength: number;
  minQidLength: number;
  onOpenQrScanner: () => void;
  onCloseQrScanner: () => void;
  onScanQrCode: (value: string) => void;
  qrScannerVisible: boolean;
}

const trimID = (qr: string, indexQueryString: number) => {
  return qr.slice(indexQueryString + 1, qr.length);
};

const MAX_LENGTH_QID = 13;
const MIN_LENGTH_QID = 12;

export const useQrScanner = () => {
  const { t } = useTranslation();
  const [qrScannerVisible, setQrScannerVisible] = useState<boolean>(false);
  const [qid, setQid] = useState<string | null>(null);

  const modalQrTitle: string = t('_REGISTER_ASSET_FORM_QID_MODAL_TITLE');

  const onOpenQrScanner = () => {
    setQrScannerVisible(true);
  };

  const onCloseQrScanner = () => {
    setQrScannerVisible(false);
  };

  const onScanQrCode = (value: string) => {
    const queryStringIndex = value.indexOf('=');
    const newQid = queryStringIndex === -1 ? value : trimID(value, queryStringIndex);
    setQid(newQid);
    setQrScannerVisible(false);
  };

  const qrScannerOptions: QrScannerOptionsModel = {
    modalQrTitle,
    maxQidLength: MAX_LENGTH_QID,
    minQidLength: MIN_LENGTH_QID,
    onOpenQrScanner,
    onCloseQrScanner,
    onScanQrCode,
    qrScannerVisible,
  };

  return { qrScannerOptions, qid };
};
