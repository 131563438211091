import { FC } from 'react';
import { Divider, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import UserAvatarIdentity from '../UserAvatarIdentity';
import './styles.scss';

interface UserEvents {
  userEmail?: string;
  userId?: string;
  username?: string;
}

interface SideEventsProps {
  users: UserEvents[];
}

const SideEvents: FC<SideEventsProps> = ({ users }) => {
  const { t } = useTranslation();

  const filteredRepeatUsers = (users: UserEvents[]) => {
    return users.filter((user, key) => users.findIndex((_user) => user.userId === _user.userId) === key);
  };

  const removeAnonymousUsers = (users: UserEvents[]) => {
    return users.filter((user) => user.userId);
  };

  const listUsers = filteredRepeatUsers(users);
  const filteredUsers = removeAnonymousUsers(listUsers);

  return (
    <div className='SideEvents'>
      <Divider className='SideEvents__divider-vertical' type='vertical' />
      <Divider className='SideEvents__divider-horizontal' type='horizontal' />
      <div className='SideEvents__content'>
        <Typography.Title level={5}>{`${t('users')} (${filteredUsers.length})`}</Typography.Title>
        <Space direction='vertical'>
          {filteredUsers.map(({ username, userId }: UserEvents) => (
            <UserAvatarIdentity username={username} userId={userId} />
          ))}
        </Space>
      </div>
    </div>
  );
};

export default SideEvents;
