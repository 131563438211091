import { FC } from 'react';
import AlfredDetailTemplate from 'components/template/AlfredDetailTemplate';
import TabsTitles, { TabsType } from './Tabs/TabsTitles';
import TabInfo from './Tabs/TabInfo';
import TabDevices from './Tabs/TabDevices';
import { useSharedGatewaysDetail } from 'hooks/useSharedGatewaysDetail';

const ProjectSharedGatewayDetailPage: FC = () => {
  const { gatewayId, tab, loading, onClickTab } = useSharedGatewaysDetail();

  return (
    <AlfredDetailTemplate
      currentTabKey={tab}
      title={`Gateway: ${(gatewayId && gatewayId) || ''}`}
      loading={loading}
      extra={[]}
      description={<></>}
      tabsTitles={(currentTab) => <TabsTitles onChange={onClickTab} activeKey={currentTab} />}
    >
      {[
        { key: TabsType.info, render: <TabInfo key={TabsType.info} /> },
        { key: TabsType.devices, render: <TabDevices key={TabsType.devices} /> },
      ]}
    </AlfredDetailTemplate>
  );
};

export default ProjectSharedGatewayDetailPage;
