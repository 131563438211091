import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { LogListCustomDataModel } from 'components/pages/AccessesVerticals/office/AccessesLogListTab/resources/utils';

export interface LogListColumnsLiteralsModel {
  dateText: string,
  actionText: string,
  userText: string,
  lockTypeText: string,
  nameText: string,
  roomText: string,
  downloadLogButtonText: string,
}

interface Props extends LogListColumnsLiteralsModel { };

export const LogListColumns = ({
  actionText,
  dateText,
  lockTypeText,
  userText,
  nameText,
  roomText
}: Props): AlfredTableColumn<LogListCustomDataModel>[] => {
  return [
    {
      title: dateText,
      key: 'date',
      width: '20%',
      render: (text, { createdAtText }) => <span>{createdAtText}</span>,
    },
    {
      title: actionText,
      key: 'action',
      width: '16%',
      render: (text, { action }) => <span>{action}</span>,
    },
    {
      title: userText,
      key: 'userName',
      width: '16%',
      render: (text, { userName }) => <span>{userName}</span>,
    },
    {
      title: lockTypeText,
      key: 'lockType',
      width: '16%',
      render: (text, { lockType }) => <span>{lockType}</span>,
    },
    {
      title: nameText,
      key: 'name',
      width: '16%',
      render: (text, { name }) => <span>{name}</span>,
    },
    {
      title: roomText,
      key: 'room',
      width: '16%',
      render: (text, { room }) => <span>{room}</span>,
    },
  ];
};
