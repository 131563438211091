import { Button, DatePicker, Input } from 'antd';
import moment, { Moment } from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { SupportTicketCreatedByModel, SupportTicketModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { DATE_TIME_FORMAT_REVERSE, TIME_FORMAT } from 'constants/date';
import { COLORS } from 'constants/colors';
import './styles.scss';

export type DataIndex = keyof SupportTicketModel;

export interface ColumnSearchTranslationsType {
  searchButtonText: string;
  resetButtonText: string;
  closeButtonText: string;
  placeholder: string;
}

enum DataIndexSearchingValues {
  CREATED_BY = 'createdBy',
  CREATED_AT = 'createdAt',
}

interface Props {
  dataIndex: DataIndex;
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => void;
  searchInput: any;
  searchedColumn: string;
  translations?: ColumnSearchTranslationsType;
}

export const getColumnSearchProps = ({ dataIndex, onSearch, onReset, searchInput, searchedColumn, translations }: Props): AlfredTableColumn<SupportTicketModel> => {
  return dataIndex !== DataIndexSearchingValues.CREATED_AT ? {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className={'ColumnSearch__dropdown'} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          className={'ColumnSearch__dropdown-input'}
          placeholder={translations?.placeholder || ''}
          value={selectedKeys[0] as string}
          onChange={(event) => setSelectedKeys(event.target.value ? [event.target.value] : [])}
          onPressEnter={() => onSearch && onSearch(selectedKeys as string[], confirm, dataIndex)}
        />
        <div className={'ColumnSearch__dropdown-buttons-wrapper'}>
          <Button type={'link'} size={'middle'} onClick={() => confirm()}>
            {translations?.closeButtonText || ''}
          </Button>
          <div>
            <Button
              className={'ColumnSearch__dropdown-button'}
              onClick={() => clearFilters && onReset && onReset(clearFilters, confirm, dataIndex)}
              size={'middle'}
            >
              {translations?.resetButtonText || ''}
            </Button>
            <Button
              type={'primary'}
              className={'ColumnSearch__dropdown-button'}
              onClick={() => onSearch && onSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size={'middle'}
            >
              {translations?.searchButtonText || ''}
            </Button>
          </div>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? COLORS.corporate : undefined }} />
    ),
    onFilter: (value, record) => {
      const filteredRecord = dataIndex === DataIndexSearchingValues.CREATED_BY ? (record[dataIndex] as SupportTicketCreatedByModel).name : record[dataIndex];
      return filteredRecord
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      visible && setTimeout(() => searchInput.current?.select(), 100);
    },
    render: text => text || null,
  } : {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const formatSelectedKeys = (key: string) => {
        const keyValues = key.split(',').flat();
        return keyValues.map((key) => moment(Number(key)));
      };
      const formattedSelectedKeys = !!selectedKeys.length ? formatSelectedKeys(selectedKeys[0] as string) : selectedKeys;
      return (
        <div className={'ColumnSearch__dropdown'}>.
          <div className={'ColumnSearch__dropdown-date-wrapper'}>
            <DatePicker.RangePicker
              className={'ColumnSearch__dropdown-date-input'}
              showTime={{ format: TIME_FORMAT }}
              value={formattedSelectedKeys as unknown as [Moment, Moment]}
              format={DATE_TIME_FORMAT_REVERSE}
              allowClear={true}
              onChange={(event) => {
                if (event) {
                  const [startingDate, endingDate] = event;
                  const formattedStartingDate = !!startingDate ? String(startingDate.valueOf()) : null;
                  const formattedEndingDate = !!endingDate ? String(endingDate.valueOf()) : null;
                  const formattedEvent = [`${formattedStartingDate},${formattedEndingDate}`]
                  formattedEvent && setSelectedKeys(formattedEvent);
                }
              }}
            />
          </div>
          <div className={'ColumnSearch__dropdown-buttons-wrapper'}>
            <Button type={'link'} size={'small'} onClick={() => confirm()}>
              {translations?.closeButtonText || ''}
            </Button>
            <div>
              <Button
                onClick={() => {
                  setSelectedKeys([''])
                  clearFilters && onReset && onReset(clearFilters, confirm, dataIndex);
                }}
                size={'middle'}
                className={'ColumnSearch__dropdown-button'}
              >
                {translations?.resetButtonText || ''}
              </Button>
              <Button
                type={'primary'}
                onClick={() => onSearch && onSearch(selectedKeys as string[], confirm, dataIndex)}
                icon={<SearchOutlined />}
                size={'middle'}
                className={'ColumnSearch__dropdown-button'}
              >
                {translations?.searchButtonText || ''}
              </Button>
            </div>
          </div>
        </div>
      )
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? COLORS.corporate : undefined }} />
    ),
    onFilter: (value, record) => {
      const supportTicketDate = (record[dataIndex] as number) * 1000;
      const searchDates = (value as string).split(',').map((value) => Number(value));
      const [startingSearchDate, endingSearchDate] = searchDates;
      return startingSearchDate <= supportTicketDate && endingSearchDate >= supportTicketDate;
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      visible && setTimeout(() => searchInput.current?.select(), 100);
    },
    render: text => text || null,
  }
};