import { FC } from 'react';
import { Button, Modal } from 'antd';
import { ModalProps } from 'antd/es/modal';
import './styles.scss';

interface Props extends ModalProps {
  title: string;
  text: string;
  mainButtonText: string;
  secondaryButtonText: string;
  termsOfServiceLinkText: string;
  privacyPolicyLinkText: string;
  onClick: () => void;
  onRevoke: () => void;
  onOpenTermsOfServiceLink: () => void;
  onOpenPrivacyPolicyLink: () => void;
  loading: boolean;
}

const LegalDocumentationModal: FC<Props> = ({
  title,
  text,
  mainButtonText,
  secondaryButtonText,
  termsOfServiceLinkText,
  privacyPolicyLinkText,
  onClick,
  onRevoke,
  onOpenTermsOfServiceLink,
  onOpenPrivacyPolicyLink,
  loading,
  ...props
}) => {
  return (
    <Modal
      {...props}
      title={title}
      closable={false}
      maskClosable={false}
      onCancel={onRevoke}
      footer={[
        <Button key='revoke' onClick={onRevoke}>{secondaryButtonText}</Button>,
        <Button key='continue' type='primary' onClick={onClick} loading={loading}>{mainButtonText}</Button>,
      ]}
    >
      <p>{text}</p>
      <div>
        <span onClick={onOpenTermsOfServiceLink} className={'LegalDocumentationModal__hyperlink'}>{termsOfServiceLinkText}</span>
      </div>
      <div>
        <span onClick={onOpenPrivacyPolicyLink} className={'LegalDocumentationModal__hyperlink'}>{privacyPolicyLinkText}</span>
      </div>
    </Modal >
  );
}

export default LegalDocumentationModal;