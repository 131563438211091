import { DeviceModel, DevicePayload } from 'core/domain/gateways/model';

export enum AssetVerticalsEnum {
  BUILDING = 'BUILDING',
  HOME = 'HOME',
  RENT = 'RENT',
  OFFICE = 'OFFICE',
  PARKING_SLOT = 'PARKING_SLOT',
  NN_DISCOUNT_ALL_PARKINGS = 'NN_DISCOUNT_ALL_PARKINGS',
  BLOCKED = 'BLOCKED',
  HOTEL_ROOM = 'HOTEL_ROOM',
  HOTEL = 'HOTEL',
  VACATIONAL_RENTAL = 'VACATIONAL_RENTAL',
}

export interface AssetDeviceGatewayPayload {
  uuid: string;
  devices: DevicePayload[];
}

export interface AssetDevicesPayload {
  gateways: AssetDeviceGatewayPayload[];
}

export interface AssetDevicesResponse {
  data: AssetDevicesPayload;
}

export interface AssetDeviceGatewayModel {
  uuid: string;
  devices: DeviceModel[];
}

export interface AssetDevicesModel {
  gateways: AssetDeviceGatewayModel[];
}

export interface RegisterAssetModel {
  id: string;
  clientId: string;
  community: string;
  alias?: string | null;
  type: string;
  streetType: string;
  streetName: string;
  blockNumber: string;
  staircase?: string | null;
  flat?: string | null;
  door?: string | null;
  city: string;
  postalCode: string;
  province: string;
  country: string;
  description: string | null;
  gatewayId?: string | null;
  qrGatewayId?: string | null;
}

export interface RegisterAssetPayload {
  id: string;
  attributes: {
    community: string;
    alias?: string;
    client_id: string;
    type: string;
    description?: string | null;
    location: {
      country: string;
      province: string;
      city: string;
      postal_code: string;
      street_type: string;
      street_name: string;
      block_number: string;
      staircase?: string | null;
      flat?: string | null;
      door?: string | null;
    };
  };
  relationship?: {
    gateways: {
      data: {
        gateways_id?: string | null;
        qr_gateway_id?: string | null;
      };
    };
  };
}

export interface AssetDeviceGatewayPayload {
  uuid: string;
  devices: DevicePayload[];
}

export interface AssetDevicesPayload {
  gateways: AssetDeviceGatewayPayload[];
}

export interface AssetDevicesResponse {
  data: AssetDevicesPayload;
}

export interface AssetDeviceGatewayModel {
  uuid: string;
  devices: DeviceModel[];
}

export interface AssetDevicesModel {
  gateways: AssetDeviceGatewayModel[];
}

export interface RegisterAssetModel {
  id: string;
  clientId: string;
  community: string;
  alias?: string | null;
  type: string;
  streetType: string;
  streetName: string;
  blockNumber: string;
  staircase?: string | null;
  flat?: string | null;
  door?: string | null;
  city: string;
  postalCode: string;
  province: string;
  country: string;
  description: string | null;
  gatewayId?: string | null;
  qrGatewayId?: string | null;
}

export interface RegisterAssetPayload {
  id: string;
  attributes: {
    community: string;
    alias?: string;
    client_id: string;
    type: string;
    description?: string | null;
    location: {
      country: string;
      province: string;
      city: string;
      postal_code: string;
      street_type: string;
      street_name: string;
      block_number: string;
      staircase?: string | null;
      flat?: string | null;
      door?: string | null;
    };
  };
  relationship?: {
    gateways: {
      data: {
        gateways_id?: string | null;
        qr_gateway_id?: string | null;
      };
    };
  };
}
