import i18next from 'i18next';

export enum SupportTicketsStatisticsUnit {
  MINUTES = 'minutes',
  MINUTE = 'minute',
  SECONDS = 'seconds',
  HOURS = 'hours',
  HOUR = 'hour',
  DAY = 'day',
  DAYS = 'days',
  DEFAULT = '',
}

export const supportTicketsUnitsTranslationsManager = {
  [SupportTicketsStatisticsUnit.DAY]: () => ` ${i18next.t('_SUPPORT_TICKETS_UNITS_DAY')}`,
  [SupportTicketsStatisticsUnit.DAYS]: () => ` ${i18next.t('_SUPPORT_TICKETS_UNITS_DAYS')}`,
  [SupportTicketsStatisticsUnit.MINUTES]: () => i18next.t('_SUPPORT_TICKETS_UNITS_MINUTES'),
  [SupportTicketsStatisticsUnit.HOURS]: () => i18next.t('_SUPPORT_TICKETS_UNITS_HOURS'),
  [SupportTicketsStatisticsUnit.MINUTE]: () => i18next.t('_SUPPORT_TICKETS_UNITS_MINUTE'),
  [SupportTicketsStatisticsUnit.HOUR]: () => i18next.t('_SUPPORT_TICKETS_UNITS_HOUR'),
  [SupportTicketsStatisticsUnit.SECONDS]: () => i18next.t('_SUPPORT_TICKETS_UNITS_SECONDS'),
  [SupportTicketsStatisticsUnit.DEFAULT]: () => '',
};

export interface AssetSupportTicketsStatisticsTranslationsTypes {
  TITLE: string;
  TITLE_TIME_PERIOD: string;
  OPEN_TICKETS: string;
  TICKETS_CLOSED: string;
  TICKETS_CLOSED_TIME_AVERAGE: string;
  FIRST_RESPONSE_TIME_AVERAGE: string;
  TICKETS_RECEIVED: string;
  TICKETS_REVOKED: string;
  TICKETS_IN_PROGRESS: string;
}
