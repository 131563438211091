import { GatewayParsed } from '../model/gateway/gatewayParsed';

export const GatewayEntity = ({
  id,
  masterUser,
  qrCode,
  gatewayVersion,
  projectId,
  hwVersion,
  osVersion,
  assistantCloud,
  assistantCloudUrl,
  voiceAssistantUser,
  daemonVersion,
  helperVersion,
  productionDate,
  deploymentDate,
  knxVersion,
  status,
  batchId,
  batchName,
  firmwareVersion,
  sku,
  zwaveVersion,
  devices,
  deployedAt,
  managementLinks,
  type,
  sensors,
  sharedSensors,
}: GatewayParsed): Readonly<GatewayParsed> => {
  return Object.freeze({
    id,
    masterUser,
    qrCode,
    gatewayVersion,
    projectId,
    hwVersion,
    osVersion,
    assistantCloud,
    assistantCloudUrl,
    voiceAssistantUser,
    daemonVersion,
    helperVersion,
    productionDate,
    deploymentDate,
    knxVersion,
    status,
    batchId,
    batchName,
    firmwareVersion,
    sku,
    zwaveVersion,
    devices,
    deployedAt,
    managementLinks,
    type,
    sensors,
    sharedSensors,
  });
};
