import api from 'services/api';
import { Camera } from '../models/camera';
import { camerasListPath } from 'core/endspoints/assets';
import { CameraUnparsed } from '../models/cameraUnparsed';
import { payloadToCameras } from '../mappers/payloadToCameras';
import { CamerasListValueObject } from '../entities/camerasListValueObject';

export const GetCamerasList = async (assetId: string): Promise<Camera[]> => {
  const path = camerasListPath(assetId);
  const { data } = await api.get<CameraUnparsed[]>(path);
  const camerasListParser = data.map((item) => payloadToCameras(item));
  const { list } = CamerasListValueObject(camerasListParser);
  return list;
};
