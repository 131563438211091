import { message } from 'antd';

export const checkFileFormat = ({
  fileType,
  acceptedTypes,
  messageText,
}: {
  fileType: string;
  acceptedTypes: string[];
  messageText: string;
}): boolean => {
  const transformedFileType = fileType.split('/');
  const isFormatCorrect = acceptedTypes.some((type) => type === transformedFileType[1]);
  !isFormatCorrect && message.error(messageText);
  return isFormatCorrect;
};

export const checkFileSize = ({
  fileSize,
  limitSize,
  messageText,
}: {
  fileSize: number;
  limitSize: number;
  messageText: string;
}): boolean => {
  const isValidFileSize = fileSize / 1024 / 1024 < limitSize;
  !isValidFileSize && message.error(messageText);
  return isValidFileSize;
};

export const openFile = (data: string) => {
  window.open(data, '_blank', 'noreferrer');
};
