export const sortByParam = (list: any[] = [], param: string) => {
  return list.sort((a, b) => a[param].localeCompare(b[param]));
};

export const getAreaNamesByBrowserLanguage = ({
  nameEn,
  nameEs
}: {
  nameEn: string;
  nameEs: string;
}) => {
  const browserLanguage = navigator.language;
  const mainNameText = browserLanguage.startsWith('es') ? nameEs : nameEn;
  const secondaryNameText = mainNameText === nameEn ? nameEs : nameEn;

  return { mainNameText, secondaryNameText };
}