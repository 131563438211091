interface Params {
  uuidNfcEncoded: string;
  idConcat: string;
  numberOfItems: number;
  itemIndex: number;
} 

export const decryptFromBase64 = ({
  idConcat,
  numberOfItems,
  uuidNfcEncoded,
  itemIndex,
}: Params) => {
  const decodedString = atob(uuidNfcEncoded);

  const uidNfcPassCodeExplode = decodedString.split(idConcat);
  if (uidNfcPassCodeExplode.length !== numberOfItems) {
    throw new Error('Invalid NFC pass code');
  }
  
  return uidNfcPassCodeExplode[itemIndex];
}
