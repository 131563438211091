import { PayloadProjectLayoutConfigArea } from '../model/projectLayoutConfigPayloadModels';
import { AreaType, FirstLevelAreaUpdateModel, ProjectLayoutConfigAreaCreateModel, ProjectLayoutConfigAreaModel, SecondLevelAreaUpdateModel } from '../model/projectLayoutConfigModels';
import { payloadToFistLevelHorizontalArea } from '../mappers/payloadToFistLevelHorizontalArea';
import { payloadToVerticalArea } from '../mappers/payloadToVerticalArea';
import { layoutConfigToCreatePayload } from '../mappers/layoutConfigToCreatePayload';
import { createProjectLayoutConfigPath, deleteProjectLayoutConfigAreaPath, getProjectLayoutConfigPath, updateProjectLayoutConfigPath } from 'core/endspoints/projectLayouts';
import api from 'services/api';
import { layoutConfigFirstLevelAreaToUpdatePayload } from '../mappers/layoutConfigFirstLevelAreaToUpdatePayload';
import { layoutConfigSecondLevelAreaToUpdatePayload } from '../mappers/layoutConfigSecondLevelAreaToUpdatePayload';

export const GetProjectLayoutConfig = async (projectId: string): Promise<ProjectLayoutConfigAreaModel[]> => {
  const url = getProjectLayoutConfigPath(projectId);

  try {
    const { data } = await api.get<PayloadProjectLayoutConfigArea[]>(url);
    const mappedData = data.map((item) => { 
      return item.type === AreaType.HORIZONTAL
        ? payloadToFistLevelHorizontalArea(item)
        : payloadToVerticalArea(item);
    });
    return mappedData;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const CreateProjectLayoutConfig = async (projectId: string, layoutConfig: ProjectLayoutConfigAreaCreateModel[]): Promise<void> => {
  const url = createProjectLayoutConfigPath(projectId);
  const data = layoutConfigToCreatePayload(layoutConfig);

  try {
    await api.post(url, { data });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const DeleteProjectLayoutConfigArea = async (areaId: string): Promise<void> => {
  const url = deleteProjectLayoutConfigAreaPath(areaId);

  try {
    await api.delete(url);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const UpdateProjectLayoutConfigArea = async (
  projectId: string,
  updatedArea: FirstLevelAreaUpdateModel | SecondLevelAreaUpdateModel  
): Promise<void> => { 

  const isSecondLevelArea = 'verticalId' in updatedArea;

  const url = updateProjectLayoutConfigPath(projectId);

  if (isSecondLevelArea) {
    const data = layoutConfigSecondLevelAreaToUpdatePayload(updatedArea as SecondLevelAreaUpdateModel);
    try {
      await api.patch(url, { data });
    } catch (error: any) {
      throw new Error(error);
    };
  }

  if (!isSecondLevelArea) {
    const data = layoutConfigFirstLevelAreaToUpdatePayload(updatedArea as FirstLevelAreaUpdateModel);
    try {
      await api.patch(url, { data });
    } catch (error: any) {
      throw new Error(error);
    };
  }
}