import { FC } from 'react';
import { Svg } from '../model/svg';

const Intercom: FC<Svg> = ({ width = '96', height = '96', ...props }) => (
  <svg width={width} height={height} viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      opacity="0.15"
      d="M46.3903 -2.02779e-06C55.6166 -2.43108e-06 64.4298 1.80392 72 6.6C78.1626 10.5043 83.5014 16.7345 87.5686 22.8C94.2071 32.7 96 40.0273 96 49.8C96 63.2175 93 75 84 84C75.9049 92.0546 63.4085 96 48 96C33.2614 96 19.322 89.502 10.8 79.8C5.00269 73.2 -1.59745e-06 59.4545 -2.1506e-06 46.8C-2.69531e-06 34.3385 1.24987 21.4513 8.4 13.2C19.8383 -8.49656e-06 31.2874 -1.36762e-06 46.3903 -2.02779e-06Z"
      fill="#96C0C4"
    />
    <path
      d="M0.499989 49.6097C0.49999 40.4462 2.29175 31.7344 7.02237 24.2676C10.8739 18.1882 17.0404 12.8955 23.0785 8.84667C32.8927 2.26566 40.1224 0.499995 49.8 0.499996C63.1456 0.499997 74.7754 3.48292 83.6458 12.3529C91.5729 20.3199 95.5 32.6658 95.5 48C95.5 62.6001 89.0599 76.4007 79.47 84.8243C76.2357 87.6653 71.2151 90.338 65.4103 92.3003C59.611 94.2608 53.0652 95.5 46.8 95.5C34.3287 95.5 21.623 94.2372 13.5274 87.2221C6.9989 81.5649 3.75588 75.9282 2.13307 69.8501C0.503146 63.7454 0.499988 57.1766 0.499989 49.6097Z"
      stroke="#96C0C4"
    />
    <g clipPath="url(#clip0_6539_6259)">
      <path d="M58.4355 47.6898L60.2473 40.9283C60.532 39.8658 59.8956 38.7635 58.8331 38.4788L35.6508 32.2671C34.5883 31.9824 33.4861 32.6188 33.2014 33.6814L28.025 52.9999C27.7403 54.0624 28.3767 55.1647 29.4392 55.4494L52.6214 61.661C53.6839 61.9457 54.7862 61.3093 55.0709 60.2468L56.8826 53.4853L60.1212 59.0947C61.0121 60.6378 63.3315 60.3276 63.7922 58.6082L66.986 46.6887C67.4467 44.9693 65.5932 43.541 64.0501 44.4319L58.4355 47.6898Z" fill="#96C0C4" />
    </g>
    <defs>
      <clipPath id="clip0_6539_6259">
        <rect x="31.0293" y="18.6055" width="48" height="48" rx="4" transform="rotate(15 31.0293 18.6055)" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Intercom;
