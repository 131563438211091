import { FC } from 'react';
import { TabCommonAreasAndBookingsMenuItem } from 'components/molecules/TabCommonAreasAndBookingsMenu';
import TabEventsAndNewsMenu from 'components/molecules/TabEventsAndNewsMenu';
import EventsAndNewsVisorManager from 'components/molecules/EventsAndNewsVisorManager';
import { CreateFormValues, UpdateFormValues } from 'components/molecules/EventsAndNewsForm/models';
import { EventsAndNewsVisorViews } from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabEventsAndNews/enums/visorViews';
import { EventsAndNewsModel } from 'core/domain/eventsAndNews/models/eventsAndNewsEntityModels';
import './styles.scss';

interface EventsAndNewsTemplateProps {
  eventsAndNewsData: EventsAndNewsModel;
  menuListItems: TabCommonAreasAndBookingsMenuItem[];
  selectedItemId?: string;
  visibleType: EventsAndNewsVisorViews;
  onClickMenuItem: (id: string) => void;
  onCreateEventsAndNews: (values: CreateFormValues) => void;
  onInvisibleCreate: () => void;
  onVisibleCreate: () => void;
  onRemoveEventsAndNews: (id: string) => void;
  onUpdateEventsAndNews: (id: string, values: UpdateFormValues) => void;
  onInvisibleUpdate: () => void;
  onVisibleUpdate: () => void;
  isSaving: boolean;
}

const EventsAndNewsTemplate: FC<EventsAndNewsTemplateProps> = (props) => (
  <div className={'EventsAndNewsTemplate__container'}>
    <div className={'EventsAndNewsTemplate__menu'}>
      <TabEventsAndNewsMenu {...props} />
    </div>
    <div className={'EventsAndNewsTemplate__content'}>
      <EventsAndNewsVisorManager {...props} />
    </div>
  </div>
);

export default EventsAndNewsTemplate;

