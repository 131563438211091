import { FC } from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import DescriptionsAtom from 'components/atoms/DescriptionsAtom';
import DescriptionsAtomItem from 'components/atoms/DescriptionsAtom/DescriptionsAtomItem';
import { ProjectHeaderDescription } from '../resources/models';
import LinkButton from 'components/atoms/Button/LinkButton';
import './styles.scss';

interface HeaderDescriptionProps {
  data: ProjectHeaderDescription;
  translations: ProjectHeaderDescription;
  onClickClientLink: (clientId: string | null) => void;
}

const HeaderDescription: FC<HeaderDescriptionProps> = ({ data, translations, onClickClientLink }) => {
  const { supportEmail, verifiers, client, clientId, requireVerification, type } = data;

  return (
    <DescriptionsAtom column={3}>
      <DescriptionsAtomItem label={translations.client} labelStyle={{ fontWeight: 'bold' }}>
        <LinkButton className={'HeaderDescription__link'} title={client} onClick={() => onClickClientLink(clientId)} />
      </DescriptionsAtomItem>
      <DescriptionsAtomItem label={translations.requireVerification} labelStyle={{ fontWeight: 'bold' }}>
        {requireVerification}
        <div className={'HeaderDescription__requireInfo'}>
          <Tooltip title={translations.requireVerificationInfo}>
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      </DescriptionsAtomItem>
      <DescriptionsAtomItem label={translations.type} labelStyle={{ fontWeight: 'bold' }}>
        {type}
      </DescriptionsAtomItem>
      <DescriptionsAtomItem label={translations.verifiers} labelStyle={{ fontWeight: 'bold' }}>
        {verifiers}
      </DescriptionsAtomItem>
      <DescriptionsAtomItem label={translations.supportEmail} labelStyle={{ fontWeight: 'bold' }}>
        {supportEmail}
      </DescriptionsAtomItem>
    </DescriptionsAtom>
  );
};

export default HeaderDescription;
