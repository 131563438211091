import { ROUTE_PATH_ADMIN } from 'components/pages/App/configRoutes';
import { useTranslation } from 'react-i18next';

enum MaintenanceTranslation {
  TITLE = '_MAINTENANCE_TITLE',
  FIRST_PARAGRAPH = '_MAINTENANCE_FIRST_PARAGRAPH',
  SECOND_PARAGRAPH = '_MAINTENANCE_SECOND_PARAGRAPH',
  BUTTON_TEXT = '_MAINTENANCE_BUTTON_TEXT',
}

export const useMaintenance = () => {
  const { t } = useTranslation();

  const title = t(MaintenanceTranslation.TITLE);
  const firstParagraph = t(MaintenanceTranslation.FIRST_PARAGRAPH);
  const secondParagraph = t(MaintenanceTranslation.SECOND_PARAGRAPH);
  const buttonText = t(MaintenanceTranslation.BUTTON_TEXT);

  const onRefresh = () => {
    window.location.pathname = ROUTE_PATH_ADMIN;
  };

  return { title, firstParagraph, secondParagraph, buttonText, onRefresh };
};
