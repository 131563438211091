import { ReactNode, VFC } from 'react';
import ModalAtom, { ModalAtomProps } from 'components/atoms/ModalAtom';
import SpaceAtom from 'components/atoms/SpaceAtom';
import { useTranslation } from 'react-i18next';
import TextButton from 'components/atoms/Button/TextButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';

interface AssignGatewayModalProps extends ModalAtomProps {
  formId: string;
  children: ReactNode;
  disabled: boolean;
}

const AssignGatewayModal: VFC<AssignGatewayModalProps> = ({ formId, children, disabled, ...props }) => {
  const { t } = useTranslation();

  return (
    <ModalAtom
      {...props}
      title={<SpaceAtom>{t('assign_gateway')}</SpaceAtom>}
      footer={[
        <TextButton key='back' onClick={props.onCancel} title={t('to_cancel')} />,
        <PrimaryButton key='submit' htmlType='submit' form={formId} title={t('bound')} disabled={disabled} />,
      ]}
      destroyOnClose
    >
      {children}
    </ModalAtom>
  );
};

export default AssignGatewayModal;
