import { FC } from 'react';
import { useHotelAccessDevicesList } from './resources/useAccessDevicesList';
import AccessDevicesListTemplate from 'components/template/AccessDevicesListTemplate';

export const AccessDevicesListTab: FC = () => {
  const {
    accessDevicesList,
    columns,
    pageCurrent,
    pageSize,
    totalPages,
    onChangePage,
    loadingAccessDevices,
  } = useHotelAccessDevicesList();

  return (
    <AccessDevicesListTemplate
      accessDevicesList={accessDevicesList}
      columns={columns}
      page={pageCurrent}
      size={pageSize}
      totalPages={totalPages}
      onChangePage={onChangePage}
      loading={loadingAccessDevices}
    />
  )
}

export default AccessDevicesListTab;