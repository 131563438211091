import { FC } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import Paper from 'components/atoms/Paper';
import { ProjectIntercomFormValues } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/config';
import ProjectIntercomEditForm from 'components/organisms/ProjectIntercomEditForm';
import { IntercomFormTranslations } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/translations';
import './styles.scss';

interface Props {
  intercomFormTranslations: IntercomFormTranslations;
  form: FormInstance<ProjectIntercomFormValues>;
  providers: DefaultOptionType[];
  onSubmitEditing: (values: ProjectIntercomFormValues) => void;
  onSubmitEditingFailed: () => void;
  onClose: () => void;
  processing: boolean;
}

const ProjectIntercomsEditTemplate: FC<Props> = ({
  intercomFormTranslations,
  form,
  providers,
  onSubmitEditing,
  onSubmitEditingFailed,
  onClose,
  processing,
}) => {

  return (
    <Paper>
      <div className={'ProjectIntercomEditTemplate__container'}>
        <ProjectIntercomEditForm
          translations={intercomFormTranslations}
          form={form}
          providers={providers}
          onFinish={onSubmitEditing}
          onFinishFailed={onSubmitEditingFailed}
          onClose={onClose}
          processing={processing}
        />
      </div>
    </Paper>
  );
}

export default ProjectIntercomsEditTemplate;