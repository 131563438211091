import api from 'services/api';
import { removeEventsAndNewsPath } from 'core/endspoints/eventsAndNews';

type RemoveModel = (eventsAndNewsId: string) => Promise<void>;

export const RemoveEventsAndNews: RemoveModel = async (eventsAndNewsId) => {
  try {
    const path = removeEventsAndNewsPath(eventsAndNewsId);
    await api.delete(path);
  } catch (error) {
    console.error(error);
  }
};
