import { CurrentUserModel } from '../models/userModel';

export const CurrentUserEntity = ({
  id,
  username,
  email,
  type,
  roles,
  lang,
  status,
  createdAt,
  confirmedAt,
  assets,
  client,
}: CurrentUserModel): Readonly<CurrentUserModel> => {
  return Object.freeze({
    id,
    username,
    email,
    type,
    roles,
    lang,
    status,
    createdAt,
    confirmedAt,
    assets,
    client,
  });
};
