import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  AreaType,
  FirstLevelAreaUpdateModel,
  FirstLevelHorizontalArea,
  ProjectLayoutConfigAreaCreateModel,
  ProjectLayoutConfigAreaModel,
  SecondLevelAreaUpdateModel,
  SecondLevelHorizontalArea,
  VerticalArea,
} from "core/domain/projectLayoutConfig/model/projectLayoutConfigModels";
import { Body2SemiBoldText, TableModel } from "@alfred_developes/alfred-ui-web-components";
import {
  CreateProjectLayoutConfig,
  DeleteProjectLayoutConfigArea,
  GetProjectLayoutConfig,
  UpdateProjectLayoutConfigArea
} from "core/domain/projectLayoutConfig/repositories/projectLayoutConfigCRUD";
import { message } from "antd";
import { ProjectConfigAreaFormValues } from "components/molecules/ProjectConfigAreaForm";
import { FirstLevelRow } from "../components/FirstLevelRow";
import { ProjectConfigSecondLevelAreaFormValues } from "components/molecules/ProjectConfigSecondLevelAreaForm";
import { sortByParam } from "components/pages/ProjectPage/utils";

enum NameLanguage {
  Es = 'nameEs',
  En = 'nameEn',
}

const browserLanguage = navigator.language;
const defaultLanguageName = browserLanguage.startsWith('es') ? NameLanguage.Es : NameLanguage.En;

export const useProjectLayoutConfig = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const [layoutConfigData, setLayoutConfigData] = useState<(ProjectLayoutConfigAreaModel)[]>([]);
  const [deletingRowId, setDeletingRowId] = useState<string>('');
  const [secondLevelAreaInitialValues, setSecondLevelAreaInitialValues] = useState<ProjectConfigSecondLevelAreaFormValues>();
  const [firstLevelAreaInitialValues, setFirstLevelAreaInitialValues] = useState<unknown>();
  const [selectedVerticalId, setSelectedVerticalId] = useState<string>('');
  const [selectedSecondLevelAreaId, setSelectedSecondLevelAreaId] = useState<string>('');
  const [secondLevelAreaModalTitle, setSecondLevelAreaModalTitle] = useState<string>('');
  const [firstLevelAreaDrawerTitle, setFirstLevelAreaDrawerTitle] = useState<string>('');
  const [isLoadingLayoutConfig, setIsLoadingLayoutConfig] = useState<boolean>(false);
  const [isSubmittingFirstLevelArea, setIsSubmittingFirstLevelArea] = useState<boolean>(false);
  const [isSubmittingSecondLevelArea, setIsSubmittingSecondLevelArea] = useState<boolean>(false);
  const [isAddFirstLevelAreaDrawerVisible, setIsAddFirstLevelDrawerVisible] = useState<boolean>(false);
  const [isSecondLevelAreaModalVisible, setIsSecondLevelAreaModalVisible] = useState<boolean>(false);

  const emptyStateContent = {
    title: t('_PROJECT_LAYOUT_CONFIG_EMPTY_STATE_TITLE'),
    description: t('_PROJECT_LAYOUT_CONFIG_EMPTY_STATE_DESCRIPTION'),
    buttonText: t('_PROJECT_LAYOUT_CONFIG_EMPTY_STATE_BUTTON_TEXT'),
  }

  const getTableMainConfig = (layoutConfigData: ProjectLayoutConfigAreaModel[]): TableModel => {
    const sortedLayoutConfigData = sortByParam(layoutConfigData, defaultLanguageName);
    const firstLevelTableRows = sortedLayoutConfigData.map((area: ProjectLayoutConfigAreaModel) => {
      const { id, imageURL, nameEn, nameEs, type, projectId, horizontal: secondLevelAreas } = area as VerticalArea;
      const sortedSecondLevelAreas = sortByParam(secondLevelAreas, defaultLanguageName);

      const horizontalByType = area.type === AreaType.VERTICAL ? { horizontal: secondLevelAreas } : {};
      return {
        id,
        data: {
          id,
          imageURL,
          nameEs,
          nameEn,
          type,
          projectId,
          ...horizontalByType
        },
        cells: [
          {
            key: 'column-1',
            isWrappedContent: true,
            component:
              <FirstLevelRow
                area={area}
                secondLevelAreas={sortedSecondLevelAreas}
                layoutConfigData={layoutConfigData}
                deletingRowId={deletingRowId}
                setDeletingRowId={setDeletingRowId}
                deleteArea={deleteArea}
                onClickEditFirstLevelArea={onClickEditFirstLevelArea}
                onClickEditSecondLevelArea={onClickEditSecondLevelArea}
                onClickAddSecondLevelArea={onClickAddSecondLevelArea}
              />
          },
        ],
      }
    });

    return {
      isTableLayoutFixed: true,
      headerCells: [
        {
          key: 'column-1',
          width: '100%',
          Component: <Body2SemiBoldText>{t('_PROJECT_LAYOUT_CONFIG_TABLE_HEADER_AREAS')}</Body2SemiBoldText>,
        },
      ],
      tableRows: firstLevelTableRows,
    };
  }

  const getLayoutConfigData = async () => {
    setIsLoadingLayoutConfig(true);
    try {
      const response = await GetProjectLayoutConfig(projectId);
      setLayoutConfigData(response);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingLayoutConfig(false);
    }
  }

  const onClickAddFirstLevelArea = () => {
    setFirstLevelAreaDrawerTitle(t('_PROJECT_LAYOUT_CONFIG_ADD_NEW_AREA'));
    setIsAddFirstLevelDrawerVisible(true);
  }

  const onClickEditFirstLevelArea = (areaData: VerticalArea | FirstLevelHorizontalArea) => {
    const { id, nameEn, nameEs } = areaData;
    setSelectedVerticalId(id);
    setFirstLevelAreaDrawerTitle(`${t('_PROJECT_LAYOUT_CONFIG_ACTION_BUTTON_EDIT')} ${nameEs} | ${nameEn}`);
    setFirstLevelAreaInitialValues(areaData);
    setIsAddFirstLevelDrawerVisible(true);
  }

  const onClickAddSecondLevelArea = (parentId: string) => {
    setSelectedVerticalId(parentId)
    setSecondLevelAreaModalTitle(t('_PROJECT_LAYOUT_CONFIG_ADD_NEW_HORIZONTAL_AREA'));
    setIsSecondLevelAreaModalVisible(true);
  }

  const onClickEditSecondLevelArea = (areaData: SecondLevelHorizontalArea) => {
    const { verticalId, id, nameEn, nameEs } = areaData;
    setSelectedVerticalId(verticalId);
    setSelectedSecondLevelAreaId(id);
    setSecondLevelAreaModalTitle(`${t('_PROJECT_LAYOUT_CONFIG_ACTION_BUTTON_EDIT')} ${nameEs} | ${nameEn}`);
    setSecondLevelAreaInitialValues(areaData);
    setIsSecondLevelAreaModalVisible(true);
  }

  const refreshLayoutConfigData = async () => {
    try {
      const layoutConfig = await GetProjectLayoutConfig(projectId);
      layoutConfig && setLayoutConfigData(layoutConfig);
    } catch (error) {
      console.warn(error);
    }
  }

  const resetSecondLevelAreaInitialValues = () => {
    setSecondLevelAreaInitialValues(undefined);
  }

  const resetFirstLevelAreaInitialValues = () => {
    setFirstLevelAreaInitialValues(undefined);
  }

  const deleteArea = async (areaId: string) => {
    // loading
    try {
      await DeleteProjectLayoutConfigArea(areaId);
      message.success(t('_PROJECT_LAYOUT_CONFIG_DELETE_AREA_SUCCESS_MESSAGE'));
      refreshLayoutConfigData();
    } catch (error) {
      message.error(t('_PROJECT_LAYOUT_CONFIG_DELETE_AREA_ERROR_MESSAGE'));
    }
  }

  const onCancelSecondLevelAreaModal = () => {
    setIsSecondLevelAreaModalVisible(false);
    resetSecondLevelAreaInitialValues();
  }

  const onCancelAddFirstLevelAreaDrawer = () => {
    setIsAddFirstLevelDrawerVisible(false);
    resetFirstLevelAreaInitialValues();
  }

  const addFirstLevelArea = async ({ type, nameEs, nameEn, image, horizontal }: ProjectConfigAreaFormValues) => {
    setIsSubmittingFirstLevelArea(true);

    const payload = [{
      type,
      nameEs,
      nameEn,
      imageBASE64: image,
      horizontal,
    }]

    try {
      await CreateProjectLayoutConfig(projectId, payload as ProjectLayoutConfigAreaCreateModel[]);
      message.success(t('_PROJECT_LAYOUT_CONFIG_CREATE_AREA_SUCCESS_MESSAGE'));
      refreshLayoutConfigData();
    } catch (error) {
      message.error(t('_PROJECT_LAYOUT_CONFIG_CREATE_AREA_ERROR_MESSAGE'));
    } finally {
      setIsSubmittingFirstLevelArea(false);
      setIsAddFirstLevelDrawerVisible(false);
      resetFirstLevelAreaInitialValues();
    }
  }

  const editFirstLevelArea = async ({ type, image, nameEs, nameEn }: ProjectConfigAreaFormValues) => {
    setIsSubmittingFirstLevelArea(true);

    const hasUrlImage = image.startsWith('https://');
    const imageURL = hasUrlImage ? image : '';
    const imageBASE64 = hasUrlImage ? '' : image;

    const payload: FirstLevelAreaUpdateModel = {
      id: selectedVerticalId,
      type,
      nameEs,
      nameEn,
      projectId,
      imageURL,
      imageBASE64,
      horizontal: [],
    }

    try {
      await UpdateProjectLayoutConfigArea(projectId, payload);
      message.success(t('_PROJECT_LAYOUT_CONFIG_EDIT_AREA_SUCCESS_MESSAGE'));
      refreshLayoutConfigData();
    } catch (error) {
      message.error(t('_PROJECT_LAYOUT_CONFIG_EDIT_AREA_ERROR_MESSAGE'));
    } finally {
      setIsSubmittingFirstLevelArea(false);
      setIsAddFirstLevelDrawerVisible(false);
      resetFirstLevelAreaInitialValues();
    }
  }

  const editSecondLevelArea = async ({ image, nameEs, nameEn }: ProjectConfigAreaFormValues) => {
    setIsSubmittingSecondLevelArea(true);

    const hasUrlImage = image.startsWith('https://');
    const imageURL = hasUrlImage ? image : '';
    const imageBASE64 = hasUrlImage ? '' : image;

    const payload: SecondLevelAreaUpdateModel = {
      type: AreaType.HORIZONTAL,
      id: selectedSecondLevelAreaId,
      nameEs,
      nameEn,
      projectId,
      verticalId: selectedVerticalId,
      imageURL,
      imageBASE64,
    }

    try {
      await UpdateProjectLayoutConfigArea(projectId, payload);
      message.success(t('_PROJECT_LAYOUT_CONFIG_EDIT_AREA_SUCCESS_MESSAGE'));
      refreshLayoutConfigData();
    } catch (error) {
      message.error(t('_PROJECT_LAYOUT_CONFIG_EDIT_AREA_ERROR_MESSAGE'));
    } finally {
      setIsSubmittingSecondLevelArea(false);
      setIsSecondLevelAreaModalVisible(false);
      resetSecondLevelAreaInitialValues();
    }
  }

  const addSecondLevelArea = async ({ image, nameEs, nameEn }: ProjectConfigAreaFormValues) => {
    setIsSubmittingSecondLevelArea(true);

    const hasUrlImage = image.startsWith('https://');
    const imageURL = hasUrlImage ? image : '';
    const imageBASE64 = hasUrlImage ? '' : image;

    const payload: SecondLevelAreaUpdateModel = {
      type: AreaType.HORIZONTAL,
      verticalId: selectedVerticalId,
      nameEs,
      nameEn,
      projectId,
      imageURL,
      imageBASE64,
    }

    try {
      await UpdateProjectLayoutConfigArea(projectId, payload);
      message.success(t('_PROJECT_LAYOUT_CONFIG_EDIT_AREA_SUCCESS_MESSAGE'));
      refreshLayoutConfigData();
    } catch (error) {
      message.error(t('_PROJECT_LAYOUT_CONFIG_EDIT_AREA_ERROR_MESSAGE'));
    } finally {
      setIsSubmittingSecondLevelArea(false);
      setIsSecondLevelAreaModalVisible(false);
      resetSecondLevelAreaInitialValues();
    }
  }

  useEffect(() => {
    getLayoutConfigData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableMainConfig = useMemo(() => {
    if (layoutConfigData.length) {
      return getTableMainConfig(layoutConfigData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutConfigData, isSecondLevelAreaModalVisible, deletingRowId]);

  const firstLevelAreaConfig = {
    firstLevelAreaDrawerTitle,
    firstLevelAreaInitialValues,
    addFirstLevelArea,
    editFirstLevelArea,
    onCancelAddFirstLevelAreaDrawer,
    onClickAddFirstLevelArea,
    isAddFirstLevelAreaDrawerVisible,
    isSubmittingFirstLevelArea,
  };

  const secondLevelAreaConfig = {
    secondLevelAreaModalTitle,
    secondLevelAreaInitialValues,
    addSecondLevelArea,
    editSecondLevelArea,
    onCancelSecondLevelAreaModal,
    isSubmittingSecondLevelArea,
    isSecondLevelAreaModalVisible,
  };

  return {
    tableMainConfig,
    firstLevelAreaConfig,
    secondLevelAreaConfig,
    emptyStateContent,
    isLoadingLayoutConfig,
  }
}