import { GatewayModuleVersionUpdateStateModel, GatewayUpdateLatestModulesVersionsModel } from '../model';
import { GatewayStatusResponseModel } from '../model/gatewayStatus';

export const GatewayStatusResponseEntity = ({
  data,
  units,
  meta,
  links,
}: GatewayStatusResponseModel): Readonly<GatewayStatusResponseModel> => {
  return Object.freeze({
    data,
    units,
    meta,
    links,
  });
};

export const GatewayModuleVersionUpdateStateEntity = ({
  state,
  uuid,
}: GatewayModuleVersionUpdateStateModel): Readonly<GatewayModuleVersionUpdateStateModel> => {
  return Object.freeze({
    state,
    uuid,
  });
};

export const LastGatewayModulesVersionsEntity = ({
  aass,
  system,
}: GatewayUpdateLatestModulesVersionsModel): Readonly<GatewayUpdateLatestModulesVersionsModel> => {
  return Object.freeze({ aass, system });
};
