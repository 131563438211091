import { BookingModel } from 'core/domain/bookingsAndCommonAreas/models/bookingEntityModels';
import { GetBookingListByProjectId } from 'core/domain/bookingsAndCommonAreas/repositories/getBookingListByProjectId';

export const useBookings = () => {
  const getBookingList = async ({ projectId }: { projectId: string }): Promise<BookingModel[] | undefined> => {
    try {
      return await GetBookingListByProjectId(projectId);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return { getBookingList };
};
