import { FC } from 'react';
import { Input, ModalProps, Select, Space } from 'antd';
import ModalAtom from 'components/atoms/ModalAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import TableAtom from 'components/atoms/TableAtom';
import LinkButton from 'components/atoms/Button/LinkButton';
import {
  AssetCustomData,
  AssignToAssetModalClientsOptions,
  AssignToProjectModalOptions,
  AssignToProjectModalProjectsOptions,
  ProjectCustomData,
} from 'components/pages/GatewaysListPage/resources/utils';
import './styles.scss';

export interface AssignGatewayToProjectModalTranslations {
  title: string;
  mainButtonText: string;
  secondaryButtonText: string;
  searchText: string;
  searchPlaceholderText: string;
  errorSearchingMessage: string;
  errorProjectNotSelectedMessage: string;
  manualSearchButtonText: string;
  clientPlaceholderText: string;
  projectPlaceholderText: string;
}

interface Props extends ModalProps {
  clientsOptions: AssignToAssetModalClientsOptions;
  projectsOptions: AssignToProjectModalProjectsOptions;
  modalOptions: AssignToProjectModalOptions;
  assigning: boolean;
}

const TABLE_HEIGHT = 300;

const AssignGatewayToProjectModal: FC<Props> = ({
  clientsOptions,
  projectsOptions,
  modalOptions,
  onCancel,
  assigning,
  ...props
}) => {
  const { clients, selectedClient, onSelectClient, loadingClients } = clientsOptions;

  const {
    projects,
    selectedProject,
    loadingProjects,
    assignGatewayToProjectModalColumns,
    onSelectProjectToBeLinked,
    loadingManualProjects,
    onValidateSearchProjects,
  } = projectsOptions;

  const {
    assignGatewayToProjectModalTranslations,
    onAssignGatewayToProject,
    onClickAssignGatewayToProjectModaSearch,
    onClickAssignGatewayToProjectModalManualSearch,
    errorSearching,
    errorAssigning,
    searchingVisible,
    manualSearchingVisible,
    searchTableVisible,
  } = modalOptions;

  const {
    title,
    mainButtonText,
    secondaryButtonText,
    errorSearchingMessage,
    errorProjectNotSelectedMessage,
    searchPlaceholderText,
    searchText,
    manualSearchButtonText,
    clientPlaceholderText,
    projectPlaceholderText,
  } = assignGatewayToProjectModalTranslations;

  return (
    <ModalAtom
      {...props}
      title={<Space>{title}</Space>}
      onCancel={onCancel}
      destroyOnClose
      width={'50%'}
      footer={[
        <DefaultButton key={'back'} onClick={onCancel} title={secondaryButtonText} />,
        <PrimaryButton key={'submit'} loading={assigning} onClick={onAssignGatewayToProject} title={mainButtonText} />,
      ]}
    >
      <div className={'AssignGatewayToProjectModal__wrapper'}>
        <div className={manualSearchingVisible ? 'AssignGatewayToProjectModal__buttons-gap' : ''}>
          <LinkButton
            className={'AssignGatewayToProjectModal__manual-searching-button'}
            title={searchText}
            onClick={onClickAssignGatewayToProjectModaSearch}
          />
          {searchingVisible && (
            <>
              <Input.Search
                className={'AssignGatewayToProjectModal__search-button'}
                allowClear
                placeholder={searchPlaceholderText}
                onSearch={onValidateSearchProjects}
                loading={loadingProjects}
                status={errorSearching ? 'error' : undefined}

              />
              <div className={'AssignGatewayToProjectModal__error-message-wrapper'}>
                {errorSearching && <p className={'AssignGatewayToProjectModal__error-message'}>{errorSearchingMessage}</p>}
              </div>
              {searchTableVisible && (
                <TableAtom<ProjectCustomData>
                  className={'AssignGatewayToProjectModal__table'}
                  loading={loadingProjects}
                  bordered={true}
                  columns={assignGatewayToProjectModalColumns}
                  rowKey={'value'}
                  dataSource={projects}
                  pagination={false}
                  size={'small'}
                  scroll={{ y: TABLE_HEIGHT }}
                  rowSelection={{
                    type: 'radio',
                    hideSelectAll: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: AssetCustomData[]) => onSelectProjectToBeLinked(selectedRows[0].value),
                  }}
                />
              )}
            </>
          )}
        </div>
        <LinkButton
          className={'AssignGatewayToProjectModal__manual-searching-button'}
          title={manualSearchButtonText}
          onClick={onClickAssignGatewayToProjectModalManualSearch}
        />
        {manualSearchingVisible && (
          <>
            <div className={'AssignGatewayToProjectModal__manual-selectors'}>
              <Select
                className={'AssignGatewayToProjectModal__selector'}
                value={selectedClient}
                showSearch
                placeholder={clientPlaceholderText}
                onChange={onSelectClient}
                options={clients}
                loading={loadingClients}
                filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
              <Select
                className={'AssignGatewayToProjectModal__selector'}
                value={selectedProject}
                showSearch
                placeholder={projectPlaceholderText}
                onChange={onSelectProjectToBeLinked}
                options={projects}
                loading={loadingManualProjects}
                filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </div>
          </>
        )}
      </div>
      <div className={'AssignGatewayToAssetModal__error-message-wrapper'}>
        {errorAssigning && <p className={'AssignGatewayToAssetModal__error-message'}>{errorProjectNotSelectedMessage}</p>}
      </div>
    </ModalAtom>
  );
}

export default AssignGatewayToProjectModal;