import { FormInstance } from 'antd/es/form/Form';
import { ProjectType, TypeOptionsModel } from 'core/domain/project/model/projectModel';

export interface ProjectContextualMenuTranslations {
  editButton: string;
  notifyButton: string;
}

export interface ProjectEditModalTranslations {
  title: string;
  cancelButton: string;
  editButton: string;
}

export interface ProjectEditFormTranslations {
  name: string;
  namePlaceholder: string;
  nameError: string;
  supportEmails: string;
  supportEmailsPlaceholder: string;
  emailErrorFormat: string;
  emailFormat: string;
  usersVerifiers: string;
  usersVerifiersPlaceholder: string;
  usersRequireVerification: string;
  fieldRequired: string;
  submitError: string;
  submitSuccess: string;
  type: string;
}

interface ProjectEditRequiredFormValues {
  name: string;
}

export interface ProjectEditFormValues extends ProjectEditRequiredFormValues {
  supportEmails: string;
  usersVerifiers: string[];
  usersRequireVerification: boolean;
  type: string;
}

export enum ProjectEditFormValuesNames {
  NAME = 'name',
  SUPPORT_EMAILS = 'supportEmails',
  USERS_VERIFIERS = 'usersVerifiers',
  USERS_REQUIRE_VERIFICATION = 'usersRequireVerification',
  TYPE = 'type',
}

export interface ProjectEditFormData {
  form: FormInstance<ProjectEditFormValues>;
  editFormTranslations: ProjectEditFormTranslations;
  typeOptions: TypeOptionsModel[];
  onSubmitEditForm: (values: ProjectEditFormValues) => void;
  onSubmitEditFormFailed: () => void;
  onSelectType: (value: ProjectType) => void;
  validateEmailFormat: (value: string) => Promise<void>;
  editing: boolean;
}

export interface ProjectHeaderDescription {
  name: string;
  supportEmail: string;
  verifiers: string;
  client: string;
  clientId: string | null;
  requireVerification: string;
  requireVerificationInfo: string;
  type: string;
}
