import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ModalAtom from 'components/atoms/ModalAtom';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';

interface ConciergeServiceModalProps {
  formId: string;
  onCancel: () => void;
  visible: boolean;
  loading: boolean;
  children?: React.ReactNode;
}

const ConciergeServiceModal: FC<ConciergeServiceModalProps> = ({
  formId, onCancel, visible, loading, children
}) => {
  const { t } = useTranslation();

  return (
    <ModalAtom
      title={t('new_service')}
      onCancel={onCancel}
      footer={[
        <DefaultButton
          key={'back'}
          onClick={onCancel}
          title={t('to_cancel')}
        />,
        <PrimaryButton
          key={'submit'}
          type={'primary'}
          loading={loading}
          htmlType={'submit'}
          form={formId}
          title={t('send')}
        />,
      ]}
      visible={visible}
      destroyOnClose
    >
      {children}
    </ModalAtom>
  );
};

export default ConciergeServiceModal;
