import { useEffect, useState } from 'react';
import { ActiveGatewaysMonthStatisticsModel } from 'core/domain/gateways/model/gateway/gatewayParsed';
import { GetActiveGatewaysStatistics } from 'core/domain/gateways/repositories/getActiveGatewaysStatistics';
import { MonthName, getMonthAbbreviationTranslations } from './utils';
import { useTranslation } from 'react-i18next';

export interface CustomActiveGatewaysStatisticsModel {
  totalGatewaysOnline: number;
  labels: string[];
  values: number[];
}

const initialStatistics: CustomActiveGatewaysStatisticsModel = {
  totalGatewaysOnline: 0,
  labels: [],
  values: [],
};

export const useActiveGatewaysStatistics = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<CustomActiveGatewaysStatisticsModel>(initialStatistics);

  const activeGatewaysTranslations = {
    title: t('_ACTIVE_GATEWAYS_TITLE'),
    dataSubtitle: t('_ACTIVE_GATEWAYS_DATA_SUBTITLE'),
    totalGatewaysText: t('_ACTIVE_GATEWAYS_TOTAL_GATEWAYS_TEXT'),
    chartSubtitle: t('_ACTIVE_GATEWAYS_CHART_SUBTITLE'),
  };

  const activeGatewaysErrorTranslations = {
    errorChartTitle: t('_ACTIVE_GATEWAYS_CHART_ERROR_TITLE'),
    errorChartDescription: t('_ACTIVE_GATEWAYS_CHART_ERROR_DESCRIPTION'),
  };

  const getLabelsTranslations = (gateways: ActiveGatewaysMonthStatisticsModel[]): string[] => {
    return gateways.map(({ label }) => {
      const labelToLowerCase = label.toLowerCase() as MonthName;
      const labelTranslation = getMonthAbbreviationTranslations(labelToLowerCase);
      return labelTranslation;
    });
  };

  const getActiveGatewaysStatistics = async () => {
    setIsLoading(true);
    try {
      const activeGatewaysStatistics = await GetActiveGatewaysStatistics();
      if (!!activeGatewaysStatistics) {
        const labels: string[] = getLabelsTranslations(activeGatewaysStatistics.gateways);
        const values: number[] = activeGatewaysStatistics.gateways.map(({ value }) => value);
        setStatistics({ ...activeGatewaysStatistics, labels, values });
      }
      !activeGatewaysStatistics && setIsError(true);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActiveGatewaysStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    activeGatewaysStatistics: statistics,
    activeGatewaysTranslations,
    activeGatewaysErrorTranslations,
    activeGatewaysError: isError,
    activeGatewaysLoading: isLoading,
  };
};
