import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { GatewaysListResponseModel, GatewaysListResponsePayload } from '../model';
import { payloadToGatewayFromList } from '../mappers';

const getUrl = ({ host, pageNumber, pageSize, search }: { host: string; pageNumber: number; pageSize: number; search?: string }) => {
  const params = new URLSearchParams();
  pageNumber && params.append('page[number]', String(pageNumber));
  pageSize && params.append('page[size]', String(pageSize));
  !!search && params.append('search', search);

  return `${host}/gateways?${params}`;
};

export const GetGatewaysList = async ({
  token,
  host,
  pageNumber,
  pageSize,
  search,
}: {
  token: string;
  host: string;
  pageNumber: number;
  pageSize: number;
  search?: string;
}): Promise<GatewaysListResponseModel> => {
  try {
    const url = getUrl({ host, pageNumber, pageSize, search });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<GatewaysListResponsePayload>;
    return payloadToGatewayFromList(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
