import { compare } from 'compare-versions';

interface CheckVersion {
  minimumVersion: string;
  version?: string;
}

const checkIfVersionCharactersAreValid = (version: string): boolean => {
  const numbersAndPointValues = /^[0-9.]+$/;
  const isOnlyNumbersAndPointValues = numbersAndPointValues.test(version);
  !isOnlyNumbersAndPointValues && console.warn('Not valid Gateway version: ', version);
  return isOnlyNumbersAndPointValues;
};

export const checkIfVersionIsOutdated = ({ minimumVersion, version }: CheckVersion): boolean => {
  if (!version) return true;
  const isValidVersion = checkIfVersionCharactersAreValid(version);
  return isValidVersion ? compare(minimumVersion, version, '>') : true;
};
