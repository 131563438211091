import { CommonAreaModel } from '../models/commonAreaEntityModels';

const CommonAreaEntity = ({
  id,
  name,
  capacity,
  bookingDuration,
  backgroundImage,
  isBackgroundImageDefault,
  sensors,
  daysAvailability,
  timeAvailability,
  infoUserTitle,
  infoUserDescription,
  hasBooking,
  enabled,
  maximumBookingsByUser,
  maximumTimeAvailability,
}: CommonAreaModel): Readonly<CommonAreaModel> => {
  return Object.freeze({
    id,
    name,
    capacity,
    bookingDuration,
    backgroundImage,
    isBackgroundImageDefault,
    sensors,
    daysAvailability,
    timeAvailability,
    infoUserTitle,
    infoUserDescription,
    hasBooking,
    enabled,
    maximumBookingsByUser,
    maximumTimeAvailability,
  });
};

export default CommonAreaEntity;
