export enum AreaType { 
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
} 

export type ProjectLayoutConfigAreaModel = FirstLevelHorizontalArea | VerticalArea;

export type ProjectLayoutConfigAreaCreateModel = FirstLevelHorizontalAreaCreateModel | VerticalAreaCreateModel;

export interface FirstLevelHorizontalArea { 
  id: string,
  type: AreaType.HORIZONTAL,
  nameEn: string,
  nameEs: string,
  imageURL: string,
  projectId: string,
}

export interface FirstLevelHorizontalAreaCreateModel { 
  type: AreaType.HORIZONTAL,
  nameEn: string,
  nameEs: string,
  imageBASE64: string,
}

export interface VerticalArea {
  id: string,
  type: AreaType.VERTICAL,
  imageURL: string,
  nameEn: string,
  nameEs: string,
  projectId: string
  horizontal: SecondLevelHorizontalArea[]
}
export interface FirstLevelAreaUpdateModel {
  id: string,
  type: AreaType.HORIZONTAL | AreaType.VERTICAL,
  nameEn: string,
  nameEs: string,
  imageBASE64: string,
  imageURL: string,
  projectId: string,
  horizontal: SecondLevelHorizontalArea[]
}

export interface SecondLevelAreaUpdateModel {
  id?: string,
  type: AreaType.HORIZONTAL,
  nameEn: string,
  nameEs: string,
  imageBASE64: string,
  imageURL: string,
  verticalId: string,
  projectId: string,
}

export interface VerticalAreaCreateModel {
  type: AreaType.VERTICAL,
  nameEn: string,
  nameEs: string,
  imageBASE64: string,
  horizontal: SecondLevelHorizontalAreaCreateModel[]
}

export interface SecondLevelHorizontalArea {
  id: string,
  type: AreaType.HORIZONTAL,
  nameEn: string,
  nameEs: string,
  imageURL: string,
  projectId: string,
  verticalId: string
}

export interface SecondLevelHorizontalAreaCreateModel {
  nameEn: string,
  nameEs: string,
  imageBASE64: string,
}

