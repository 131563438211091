import api from 'services/api';
import { thirdPartyServicesCreateByProjectPath } from 'constants/endpointsPath/thirdPartyServices';
import { responseToThirdPartyServicesByProject } from '../mappers/responseToThirdPartyServicesByProject';
import { ResponseThirdPartySevicesUnparser } from '../model/responseThirdPartySevices';
import { RequestResponse } from 'core/models/requestResponse';
import { ThirdPartyServicesCreateByProject } from '../model/thirdPartyServicesCreateByProject';

const CreateThirdPartyServicesByProject = async (
  projectId: string, values: ThirdPartyServicesCreateByProject
): Promise<Readonly<RequestResponse>> => {
  const path = thirdPartyServicesCreateByProjectPath(projectId);
  const { data } = await api.post<ResponseThirdPartySevicesUnparser>(path, { data: values });
  const dataParser = responseToThirdPartyServicesByProject(data);
  return dataParser;
};

export default CreateThirdPartyServicesByProject;
