import { RouteConfig } from 'react-router-config';
import AcademyPage from 'components/pages/AcademyPage';
import { ROUTE_PATH_ALFRED_ACADEMY } from './config';

export const academyRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_ALFRED_ACADEMY,
    exact: true,
    component: AcademyPage,
  },
];
