import { FC, ReactNode } from 'react';
import { PageHeader, Select } from 'antd';
import Paper from 'components/atoms/Paper';
import { Icon } from 'components/atoms/Icons';
import { AccessClientAndProjectCustomData } from 'components/pages/AccessesPage/resources/useAccessesClientAndProjectSelector';
import './styles.scss';

interface Props {
  title: string;
  clientId: string | null;
  projectId: string | null;
  clientListOptions: AccessClientAndProjectCustomData[];
  clientPlaceholderText: string;
  projectPlaceholderText: string;
  projectListOptions: AccessClientAndProjectCustomData[];
  emptyText: string;
  tabTitles: ReactNode;
  onSelectClient: (value: string) => void;
  onSelectProject: (value: string) => void;
  loadingClientsList: boolean;
  loadingProjectsList: boolean;
  showEmptyMessage: boolean;
  children: ReactNode;
}

const AccessesClientAndProjectSelectorTemplate: FC<Props> = ({
  title,
  clientId,
  projectId,
  clientListOptions,
  clientPlaceholderText,
  projectPlaceholderText,
  projectListOptions,
  emptyText,
  children,
  tabTitles,
  onSelectClient,
  onSelectProject,
  loadingClientsList,
  loadingProjectsList,
  showEmptyMessage,
}) => {
  return (
    <>
      <PageHeader
        ghost={false}
        title={title}
        footer={tabTitles}
        extra={
          <>
            <Select
              className={'AccessesClientAndProjectSelectorTemplate__header--selector'}
              value={clientId}
              showSearch
              placeholder={clientPlaceholderText}
              onChange={onSelectClient}
              options={clientListOptions}
              loading={loadingClientsList}
              filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
            <Select
              className={'AccessesClientAndProjectSelectorTemplate__header--selector'}
              value={projectId}
              showSearch
              placeholder={projectPlaceholderText}
              onChange={onSelectProject}
              options={projectListOptions}
              loading={loadingProjectsList}
              filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </>
        }
      >
      </PageHeader>
      {showEmptyMessage && (
        <Paper className={'AccessesClientAndProjectSelectorTemplate__body'}>
          <div className={'AccessesClientAndProjectSelectorTemplate__body-empty-container'}>
            <div className={'AccessesClientAndProjectSelectorTemplate__body-empty-icon'}>
              <Icon.EmptyAccess />
            </div>
            <p className={'AccessesClientAndProjectSelectorTemplate__body-empty-text'}>{emptyText}</p>
          </div>
        </Paper>
      )}
      {!showEmptyMessage && children}
    </>
  );
}

export default AccessesClientAndProjectSelectorTemplate;