import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentModel } from 'core/domain/documents/model/documentsEntityModels';
import { GetDocumentListByProjectId } from 'core/domain/documents/repositories/getDocumentListByProjectId';
import { useDocuments } from './useDocuments';
import { CreateDocumentsByProjectId } from 'core/domain/documents/repositories/createDocumentByProjectId';
import { TableActionsMenuProps } from 'components/atoms/TableAtom';
import { DocumentManagementFormValues } from 'components/organisms/DocumentManagementForm';
import { message } from 'antd';

export const useDocumentsProject = (projectId: string) => {
  const { t } = useTranslation();
  const [data, setData] = useState<DocumentModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteDocumentModalVisible, setIsDeleteDocumentModalVisible] = useState<boolean>(false);
  const [currentDocumentId, setCurrentDocumentId] = useState<string>('');
  const { onDelete, onFormatToBytes, onDownload, loading: loadingDelete } = useDocuments();

  const wrongSourceText = t('view_in_projects');
  const deleteModalTitle = t('_ASSET_DOCUMENTS_DELETE_MODAL_TITLE');
  const deleteModalText = t('DOCUMENT_MANAGEMENT.delete_document_confirm');
  const deleteModalMainButtonText = t('delete');
  const deleteModalSecondaryButtonText = t('cancel');

  const documentTableActionsMenu: TableActionsMenuProps[] = [
    {
      key: 'deleteDocument',
      onClick: (documentId: string) => onDeleteDocument(documentId),
      text: t('delete'),
      disabled: false,
    },
  ];

  const onGetDocumentsList = async () => {
    setIsLoading(true);
    try {
      const data = await GetDocumentListByProjectId(projectId);
      data && setData(data);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRefresh = async (): Promise<void> => {
    onGetDocumentsList();
  };

  useEffect(() => {
    onGetDocumentsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onSave = async (values: DocumentManagementFormValues) => {
    const { files } = values;
    setIsLoading(true);
    try {
      const response = await CreateDocumentsByProjectId({ projectId, files });
      await onRefresh();
      return response;
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteDocument = (documentId: string) => {
    setCurrentDocumentId(documentId);
    setIsDeleteDocumentModalVisible(true);
  };

  const onConfirmDeleteDocument = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await onDelete(currentDocumentId);
      message.success(t('deleted_document'), 3);
      await onRefresh();
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
      setIsDeleteDocumentModalVisible(false);
    }
  };

  const onCloseConfirmModal = () => {
    setIsDeleteDocumentModalVisible(false);
  };

  const onRowClick = ({ url }: DocumentModel) => {
    onDownload(url);
  };

  return {
    data,
    documentTableActionsMenu,
    wrongSourceText,
    deleteModalTitle,
    deleteModalText,
    deleteModalMainButtonText,
    deleteModalSecondaryButtonText,
    onSave,
    onRefresh,
    onFormatToBytes,
    onRowClick,
    onConfirmDeleteDocument,
    onCloseConfirmModal,
    loading: isLoading || loadingDelete,
    deleteModalVisible: isDeleteDocumentModalVisible,
  };
};
