import api from 'services/api';
import { commonAreasRemovePath } from 'core/endspoints/commonAreas';

export const RemoveCommonArea = async ({ id }: { id: string }) => {
  try {
    const path = commonAreasRemovePath(id);
    api.defaults.headers.common['Accept-version'] = '1';
    await api.delete(path);
  } catch (error: any) {
    throw new Error(error);
  }
};
