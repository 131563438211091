import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { GatewayUpdateLatestModulesVersionsModel, GatewayUpdateLatestModulesVersionsPayload } from '../model';
import { payloadToLastGatewayModulesVersions } from '../mappers';
import { LastGatewayModulesVersionsEntity } from '../entities';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string) => {
  return `${host}/gateways/versions/latest`;
};

export const GetLastGatewayModulesVersions = async ({
  token,
  host,
}: {
  token: string;
  host: string;
}): Promise<GatewayUpdateLatestModulesVersionsModel> => {
  try {
    const url = getUrl(host);

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<GatewayUpdateLatestModulesVersionsPayload>;
    const mappedData = payloadToLastGatewayModulesVersions(data);
    return LastGatewayModulesVersionsEntity(mappedData);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
