import { useCallback, useContext, useEffect, useState } from 'react';
import UserProfileContext, { UserProfileContextState } from 'context/UserProfileContext';
import { CurrentUserModel } from 'core/domain/users/models/userModel';
import { GetCurrentUser } from 'core/domain/users/repositories/getCurrentUser';
import { SetLogout } from 'core/domain/login/repositories/Logout';
import { useEnvironment } from './useEnvironment';

interface useProfileUserResponse {
  data: CurrentUserModel | null;
  loading: boolean;
  userProfileLoading: boolean;
  error: boolean;
  onFallbackProfile: () => void;
}

export const useProfileUser = (): useProfileUserResponse => {
  const { userProfile, userProfileLoading, setIsUserProfileLoading, setUserProfile } =
    useContext<UserProfileContextState>(UserProfileContext);
  const { host } = useEnvironment();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const onFallbackProfile = useCallback(async () => {
    setIsLoading(true);
    setIsUserProfileLoading(true);
    try {
      const data = await GetCurrentUser();
      setUserProfile(data);
    } catch (error) {
      await SetLogout(host);
      setIsError(true);
    } finally {
      setIsLoading(false);
      setIsError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserProfile]);

  useEffect(() => {
    !userProfile && onFallbackProfile();
  }, [userProfile, onFallbackProfile]);

  return {
    data: userProfile,
    loading: isLoading,
    userProfileLoading,
    error: isError,
    onFallbackProfile,
  };
};
