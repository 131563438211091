import { FC } from 'react';
import BookingListTable from 'components/organisms/BookingListTable';
import { BookingModel } from 'core/domain/bookingsAndCommonAreas/models/bookingEntityModels';
import './styles.scss';

interface Props {
  bookingsList: BookingModel[];
  onDeleteBooking: (bookingId: string) => void;
}

const CommonAreasBookingsListTemplate: FC<Props> = ({
  bookingsList,
  onDeleteBooking,
}) => {
  return (
    <div className={'BookingListTable__wrapper'}>
      <BookingListTable
        bookingListData={bookingsList}
        onDeleteBooking={onDeleteBooking}
      />
    </div>
  )
}

export default CommonAreasBookingsListTemplate;