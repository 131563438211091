import { useTranslation } from 'react-i18next';
import { ColumnType, FilterConfirmProps } from 'antd/lib/table/interface';
import { DeviceModel } from 'core/domain/gateways/model';
import DeviceMenuExtra from 'components/organisms/DeviceMenuExtra';
import { Icon } from 'components/atoms/Icons';
import { COLORS } from 'constants/colors';
import { ColumnSearchTranslationsType, DeviceDataIndex, getColumnSearchProps } from '../SearchPeripheralColumns';

interface Props {
  gatewayId?: string;
  columnSearchTranslations?: ColumnSearchTranslationsType;
  searchInput?: any;
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
}

export const sortPeripherals = (a: string, b: string) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const ColumnsPeripheralDevices = ({
  gatewayId,
  columnSearchTranslations,
  searchInput,
  onSearch,
  onReset,
}: Props): ColumnType<DeviceModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: 'Id',
      key: 'deviceId',
      width: '10%',
      sorter: (a, b) => Number(a.deviceId) - Number(b.deviceId),
      sortDirections: ['ascend', 'descend'],
      dataIndex: 'deviceId',
    },
    {
      title: `${t('type')}`,
      key: 'deviceType',
      width: '40%',
      filterIcon: (filtered: boolean) => (
        <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      sorter: (a, b) => sortPeripherals(a.deviceType, b.deviceType),
      ...getColumnSearchProps({ dataIndex: 'deviceType', onSearch, onReset, searchInput, translations: columnSearchTranslations }),
    },
    {
      title: `${t('name')}`,
      key: 'deviceName',
      width: '40%',
      filterIcon: (filtered: boolean) => (
        <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      ...getColumnSearchProps({ dataIndex: 'deviceName', onSearch, onReset, searchInput, translations: columnSearchTranslations }),
    },
    {
      title: t('actions'),
      key: 'menuExtra',
      width: '10%',
      render: (values) => {
        return gatewayId ? <DeviceMenuExtra data={values} gatewayId={gatewayId} /> : null;
      },
    },
  ];
};
