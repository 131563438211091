import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormInstance } from 'antd';
import { CustomGatewaysListDataModel } from './utils';
import { DeleteGatewayModalTranslations } from 'components/organisms/DeleteGatewayModal';
import { DeleteGatewayFormTranslations, DeleteGatewayFormValues } from 'components/molecules/DeleteGatewayForm';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { DeleteGatewaysFromSystemPermanently } from 'core/domain/gateways/repositories/deleteGatewayFromSystemPermanently';
import { useMessage } from 'hooks/useMessage';

export interface DeleteGatewayOptionsModel {
  translations: DeleteGatewayModalTranslations;
  form: FormInstance<DeleteGatewayFormValues>;
  errorGidMessage: string;
  errorQidMessage: string;
  onCloseDeleteGatewayModal: () => void;
  onFinish: (values: DeleteGatewayFormValues) => void;
  visible: boolean;
  qidValidationVisible: boolean;
  deleting: boolean;
  errorGid: boolean;
  errorQid: boolean;
}

export const useDeleteGateway = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [form] = Form.useForm<DeleteGatewayFormValues>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [gateway, setGateway] = useState<CustomGatewaysListDataModel | null>(null);
  const [errorGid, setErrorGid] = useState<boolean>(false);
  const [errorQid, setErrorQid] = useState<boolean>(false);
  const [errorGidMessage, setErrorGidMessage] = useState<string>('');
  const [errorQidMessage, setErrorQidMessage] = useState<string>('');
  const [isQidValidationsVisible, setIsQidValidationVisible] = useState<boolean>(true);
  const [successDeletingGateway, setSuccessDeletingGateway] = useState<boolean>(false);

  const formTranslations: DeleteGatewayFormTranslations = {
    gidInputText: t('_GATEWAYS_LIST_DELETE_MODAL_GID_INPUT_TEXT'),
    gidPlaceholder: t('_GATEWAYS_LIST_DELETE_MODAL_GID_PLACEHOLDER'),
    qidInputText: t('_GATEWAYS_LIST_DELETE_MODAL_QID_INPUT_TEXT'),
    qidPlaceholder: t('_GATEWAYS_LIST_DELETE_MODAL_QID_PLACEHOLDER'),
  };

  const translations: DeleteGatewayModalTranslations = {
    title: t('_GATEWAYS_LIST_DELETE_MODAL_TITLE'),
    mainButtonText: t('_GATEWAYS_LIST_DELETE_MODAL_MAIN_BUTTON_TEXT'),
    secondaryButtonText: t('_GATEWAYS_LIST_DELETE_MODAL_SECONDARY_BUTTON_TEXT'),
    description: t('_GATEWAYS_LIST_DELETE_MODAL_DESCRIPTION'),
    formTranslations,
  };

  const checkIfQidValidationIsVisible = (currentGateway: CustomGatewaysListDataModel) => {
    const hasQrCode = !!currentGateway.qrCode;
    setIsQidValidationVisible(hasQrCode);
  };

  const onOpenDeleteGatewayModal = (currentGateway: CustomGatewaysListDataModel) => {
    setIsModalVisible(true);
    setGateway(currentGateway);
    checkIfQidValidationIsVisible(currentGateway);
  };

  const onCloseDeleteGatewayModal = () => {
    setIsModalVisible(false);
    setGateway(null);
    form.resetFields();
    setErrorGid(false);
    setErrorQid(false);
    setSuccessDeletingGateway(false);
  };

  const setGidEmptyState = (): boolean => {
    setErrorGidMessage(t('field_required'));
    setErrorGid(true);
    return false;
  };

  const checkIfGidValuesMatched = (value: string, id: string): boolean => {
    const matchedValues = value === id;
    setErrorGid(!matchedValues);
    !matchedValues && setErrorGidMessage(t('_GATEWAYS_LIST_DELETE_MODAL_GID_ERROR_MESSAGE'));
    return matchedValues;
  };

  const checkGidValue = (value: string, id: string): boolean => {
    return !!value ? checkIfGidValuesMatched(value, id) : setGidEmptyState();
  };

  const checkIfQidValuesMatched = (value: string, id: string): boolean => {
    const matchedValues = value === id;
    setErrorQid(!matchedValues);
    !matchedValues && setErrorQidMessage(t('_GATEWAYS_LIST_DELETE_MODAL_QID_ERROR_MESSAGE'));
    return matchedValues;
  };

  const setQidEmptyState = (): boolean => {
    setErrorQidMessage(t('field_required'));
    setErrorQid(true);
    return false;
  };

  const checkQidValue = (value: string, id: string): boolean => {
    return !!value ? checkIfQidValuesMatched(value, id) : setQidEmptyState();
  };

  const onFinish = async (values: DeleteGatewayFormValues) => {
    setDeleting(true);
    try {
      if (!!gateway) {
        setErrorGid(false);
        const validQrCode: string = gateway.qrCode || '';
        const isGidValueCorrect = checkGidValue(values.gid, gateway.id);
        const isQidValueCorrect = isQidValidationsVisible ? checkQidValue(values.qid, validQrCode) : true;
        if (isGidValueCorrect && isQidValueCorrect) {
          await DeleteGatewaysFromSystemPermanently({ host, token, gid: gateway.id });
          setSuccessDeletingGateway(true);
          setMessageSuccess({ description: t('_GATEWAYS_LIST_DELETE_MODAL_SUCCESS') });
          onCloseDeleteGatewayModal();
        }
      }
    } catch (error) {
      setMessageError({ description: t('_GATEWAYS_LIST_DELETE_MODAL_ERROR') });
    } finally {
      setDeleting(false);
    }
  };

  const options: DeleteGatewayOptionsModel = {
    translations,
    form,
    errorGidMessage,
    errorQidMessage,
    onCloseDeleteGatewayModal,
    onFinish,
    visible: isModalVisible,
    qidValidationVisible: isQidValidationsVisible,
    deleting,
    errorGid,
    errorQid,
  };

  return {
    deleteGatewayOptions: options,
    onOpenDeleteGatewayModal,
    successDeletingGateway,
  };
};
