import { FC } from "react"
import { AddFilledIcon, SecondaryLargeIconFlatButton, Table, TableModel } from "@alfred_developes/alfred-ui-web-components";
import { ProjectLayoutConfigAreaModel } from "core/domain/projectLayoutConfig/model/projectLayoutConfigModels";

interface Props {
  area: ProjectLayoutConfigAreaModel;
  onClickAdd: (areaId: string) => void;
  tableConfig: TableModel;
}

export const FirstLevelRowContent: FC<Props> = ({
  area,
  onClickAdd,
  tableConfig,
}) => {

  return (
    <>
      <div style={{ padding: '0 24px' }}>
        <Table tableConfig={tableConfig} />
        <div style={{
          marginTop: '1rem'
        }}>
          <SecondaryLargeIconFlatButton
            Icon={AddFilledIcon}
            onClick={() => onClickAdd(area.id)}
          />
        </div>
      </div>
    </>
  )
}