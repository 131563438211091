import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TabsAtom, { TabsAtomProps } from 'components/atoms/TabsAtom';
import { ProjectCustomizationSubTabs } from '../constants';

const SubTabsTitles: FC<TabsAtomProps> = (props) => {
  const { t } = useTranslation();

  return (
    <TabsAtom {...props}>
      <TabsAtom.TabPane tab={t('_APP_AND_WEBAPP')} key={ProjectCustomizationSubTabs.APP_AND_WEBAPP} />
    </TabsAtom>
  );
};

export default SubTabsTitles;
