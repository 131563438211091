import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RouteConfig, renderRoutes } from 'react-router-config';
import { useCommonAreasAndBookingsMenu } from './resources/useCommonAreasAndBookingsMenu';
import CommonAreasAndBookingsMenuTemplate from 'components/template/CommonAreasAndBookingsMenuTemplate';
import CommonAreasAndBookingsMenuLoadingTemplate from 'components/template/CommonAreasAndBookingsMenuLoadingTemplate';

interface PrivatePageProps extends RouteComponentProps {
  route?: RouteConfig;
}

const CommonAreasAndBookingsMenuPage: FC<PrivatePageProps> = ({ route }) => {
  const {
    commonAreasList,
    translations,
    selectedCommonArea,
    onCreateCommonArea,
    onSelectCommonArea,
    onShowBookingsList,
    pageAlreadyLoaded,
    loadingCommonAreasList,
    createCommonAreaButtonDisabled,
    showBookingListButtonDisabled,
  } = useCommonAreasAndBookingsMenu();
  const { bookingsListButtonText, createCommonAreaButtonText } = translations;

  return (
    <>
      {loadingCommonAreasList && !pageAlreadyLoaded && (
        <CommonAreasAndBookingsMenuLoadingTemplate />
      )}
      {pageAlreadyLoaded && (
        <CommonAreasAndBookingsMenuTemplate
          menuListItems={commonAreasList}
          selectedItemId={selectedCommonArea}
          bookingsListButtonText={bookingsListButtonText}
          createCommonAreaButtonText={createCommonAreaButtonText}
          onCreateCommonArea={onCreateCommonArea}
          onSelectCommonArea={onSelectCommonArea}
          onShowBookingsList={onShowBookingsList}
          createCommonAreaButtonDisabled={createCommonAreaButtonDisabled}
          showBookingListButtonDisabled={showBookingListButtonDisabled}
        >
          {renderRoutes(route?.routes)}
        </CommonAreasAndBookingsMenuTemplate>
      )}
    </>
  );
}

export default CommonAreasAndBookingsMenuPage;