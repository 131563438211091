import { LinkOutlined } from '@ant-design/icons';
import { AutoComplete, Input, message } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const MIN_CHARACTERES = 2;

export interface Options {
  value: string;
  label: JSX.Element;
}

interface LinkSearchSelectProps {
  dropdownMatchSelectWidth?: number;
  onOk: () => void;
  onSearch: (query: string) => Promise<Options[]>;
  onSelect: (id: string) => Promise<void>;
  placeholder: string;
}

const LinkSearchSelect: FC<LinkSearchSelectProps> = ({ dropdownMatchSelectWidth = 400, onOk, onSearch, onSelect, placeholder }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<Options[] | undefined>(undefined);

  const handleSearch = async (query: string) => {
    if (query.length < MIN_CHARACTERES) {
      setOptions(undefined);
      return;
    }

    setLoading(true);
    const getData = await onSearch(query);
    setOptions(getData);
    setLoading(false);
  };

  const handleOnSelect = async (id: string) => {
    setLoading(true);
    try {
      await onSelect(id);
      message.info(t('link_correctly'), 3);
      setValue('');
    } finally {
      setLoading(false);
      onOk();
    }
  };

  return (
    <AutoComplete
      open={options ? true : false}
      notFoundContent={options ? t('no_results') : undefined}
      className='AddUserIntoAsset__input'
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      options={options}
      onSelect={handleOnSelect}
      onSearch={handleSearch}
      value={value}
    >
      <Input.Search
        loading={loading}
        placeholder={placeholder}
        enterButton={<LinkOutlined />}
        onChange={(event) => setValue(event.target.value)}
        value={value}
        allowClear
      />
    </AutoComplete>
  );
};

export default LinkSearchSelect;
