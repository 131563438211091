import { FC } from 'react';
import { Modal, Button, Space } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import UsersClientsSelectorModalForm from 'components/molecules/UsersClientsSelectorModalForm';
import { ClientSelectorFormValues } from 'components/pages/UsersPage/resources/useUsersAssets';
import { FormInstance } from 'antd/es/form/Form';

interface Props extends ModalProps {
  onSelectClient: (values: ClientSelectorFormValues) => void;
  loading: boolean;
  form: FormInstance<ClientSelectorFormValues>;
  title: string;
  mainButtonText: string,
  secondaryButtonText: string,
  requiredMessage: string,
  placeholderText: string,
  data: ClientModel[],
}

const UserClientsSelectorModal: FC<Props> = ({
  onCancel,
  onSelectClient,
  loading,
  title,
  form,
  mainButtonText,
  secondaryButtonText,
  requiredMessage,
  placeholderText,
  data,
  ...props
}) => {
  return (
    <Modal
      {...props}
      title={<Space>{title}</Space>}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button key={'back'} onClick={onCancel}>
          {secondaryButtonText}
        </Button>,
        <Button key={'submit'} type={'primary'} loading={loading} htmlType={'submit'} onClick={() => form.submit()}>
          {mainButtonText}
        </Button>,
      ]}
    >
      <UsersClientsSelectorModalForm
        form={form}
        data={data}
        onFinish={onSelectClient}
        requiredMessage={requiredMessage}
        placeholder={placeholderText}
      />
    </Modal>
  );
};

export default UserClientsSelectorModal;
