import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { PremiumUser } from 'core/domain/premiumUsers/model/premiumUser';
import { useTranslation } from 'react-i18next';

export const UserListColumns = (): AlfredTableColumn<PremiumUser>[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('name'),
      key: 'clientName',
      dataIndex: 'clientName',
    },
    {
      title: t('email'),
      key: 'clientEmail',
      dataIndex: 'clientEmail',
    },
    {
      title: t('billable_users'),
      key: 'billableUsers',
      dataIndex: 'billableUsers',
      render: (text, document) => {
        const billableUsers = document.billableUsers.join(', ');
        return <div>{billableUsers}</div>;
      },
    },
    {
      title: t('users'),
      key: 'billableUsers',
      dataIndex: 'billableUsers',
      render: (text, document) => {
        return <div>{document.billableUsers.length}</div>;
      },
    },
  ];
};
