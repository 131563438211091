import { ChangeEvent, useEffect, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadTenantsTemplatePreview } from 'core/domain/users/repositories/uploadTenantsTemplatePreview';
import { TenantsPreviewDataResponseModel } from 'core/domain/users/models/userModel';
import { csvStringFileToBase64, isUtf8ValidFormat, validateFileSize } from 'utils/documents';
import { TenantsPreviewColumns } from './tenantsPreviewColumns';
import { UploadTenantsTemplate } from 'core/domain/users/repositories/uploadTenantsTemplate';

export interface ColumnsTranslations {
  nameText: string;
  emailText: string;
  addressText: string;
  assetIdText: string;
}

const MAXIMUM_UPLOADING_CSV_FILE_SIZE = 5;
const FILE_UNITS = 'MB';
const UPLOAD_MESSAGE_SUCCESS_DURATION = 8;

export const useUploadCsv = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [previewData, setPreviewData] = useState<TenantsPreviewDataResponseModel[]>([]);
  const [previewDataInfo, setPreviewDataInfo] = useState<string>('');
  const [isDataError, setIsDataError] = useState<boolean>(false);
  const [isErrorSendingPreview, setIsErrorSendingPreview] = useState<boolean>(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const translations = {
    title: t('_USERS_PREVIEW_MODAL_TITLE'),
    columnsText: t('_USERS_PREVIEW_MODAL_COLUMNS_TEXT'),
    rowsText: t('_USERS_PREVIEW_MODAL_ROWS_TEXT'),
    tableTitle: t('_USERS_PREVIEW_MODAL_TABLE_TITLE'),
    mainButtonText: t('_USERS_PREVIEW_MODAL_MAIN_BUTTON_TEXT'),
    secondaryButtonText: t('_USERS_PREVIEW_MODAL_SECONDARY_BUTTON_TEXT'),
    errorTitle: t('_USERS_PREVIEW_MODAL_ERROR_TITLE'),
    errorDescription: t('_USERS_PREVIEW_MODAL_ERROR_DESCRIPTION'),
    errorSendingTitle: t('_USERS_PREVIEW_MODAL_ERROR_SENDING_TITLE'),
    errorSendingDescription: t('_USERS_PREVIEW_MODAL_ERROR_SENDING_DESCRIPTION'),
  };

  const columnsTranslations: ColumnsTranslations = {
    nameText: t('_USERS_MODAL_UPLOAD_COLUMN_NAME'),
    emailText: t('_USERS_MODAL_UPLOAD_COLUMN_EMAIL'),
    addressText: t('_USERS_MODAL_UPLOAD_COLUMN_ADDRESS'),
    assetIdText: t('_USERS_MODAL_UPLOAD_COLUMN_ASSET_ID'),
  };

  const columns = TenantsPreviewColumns(columnsTranslations);

  const getPreviewDataInfo = (data: TenantsPreviewDataResponseModel[]) => {
    const tableColumns = Object.keys(data[0]) || [];
    const numberOfTableRows = data.length;
    const dataInfo = `${tableColumns.length} ${translations.columnsText} · ${numberOfTableRows} ${translations.rowsText}`;
    setPreviewDataInfo(dataInfo);
  };

  const getErrorMessage = (isErrorSize: boolean, isErrorFormat: boolean) => {
    isErrorFormat &&
      !isErrorSize &&
      message.error(`${t('_USERS_UPLOAD_SIZE_ERROR_MESSAGE')} ${MAXIMUM_UPLOADING_CSV_FILE_SIZE}${FILE_UNITS}`);
    !isErrorFormat && message.error(t('_USERS_UPLOAD_FORMAT_ERROR_MESSAGE'));
  };

  const validateFile = (event: ProgressEvent<FileReader>) => {
    const isValidSize = validateFileSize(event.total, MAXIMUM_UPLOADING_CSV_FILE_SIZE);
    const fileContent = event.target?.result as string;
    const formattedFile = fileContent.replaceAll(';', ',');
    const isValidFormat = isUtf8ValidFormat(fileContent);
    const isValidFile = isValidSize && isValidFormat;
    isValidFile && setFile(formattedFile);
    !isValidFile && getErrorMessage(isValidSize, isValidFormat);
  };

  const transformFileToValidate = async (file: File) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => validateFile(event);
    fileReader.readAsText(file);
  };

  const onUploadSelectedFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    file && setFileName(file.name);
    file && transformFileToValidate(file);
    event.target.value = '';
  };

  const onClosePreviewModal = () => {
    setIsPreviewModalVisible(false);
    setFile('');
    setFileName('');
    setPreviewData([]);
    setIsDataError(false);
    setIsErrorSendingPreview(false);
  };

  const onUploadTenantsTemplate = async () => {
    setIsUpdating(true);
    try {
      const response = await UploadTenantsTemplate(previewData);
      if (response !== undefined) {
        message.success(t('_USERS_UPLOAD_SUCCESS_MESSAGE'), UPLOAD_MESSAGE_SUCCESS_DURATION);
        onClosePreviewModal();
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const getTenantsTemplatePreview = async () => {
    setIsLoadingPreview(true);
    setIsPreviewModalVisible(true);
    try {
      const transformedFile = csvStringFileToBase64(file);
      const tenantsPreview = await UploadTenantsTemplatePreview(transformedFile);
      if (tenantsPreview) {
        setPreviewData(tenantsPreview.previewData);
        setIsDataError(tenantsPreview.isError);
        getPreviewDataInfo(tenantsPreview.previewData);
      }
    } catch (error) {
      console.warn(error);
      setFile('');
      setFileName('');
      setIsErrorSendingPreview(true);
    } finally {
      setIsLoadingPreview(false);
    }
  };

  useEffect(() => {
    !!file && getTenantsTemplatePreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return {
    previewData,
    previewDataTitle: fileName,
    previewDataInfo,
    previewTranslations: translations,
    previewColumns: columns,
    onUploadSelectedFile,
    onClosePreviewModal,
    onUploadTenantsTemplate,
    loadingPreview: isLoadingPreview,
    previewModalVisible: isPreviewModalVisible,
    previewDataError: isDataError,
    errorSendingPreview: isErrorSendingPreview,
    updating: isUpdating,
  };
};
