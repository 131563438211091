import { CreateAssetAnonymousAccess, Locks } from 'models/assets.model';
import { Device } from 'models/devices.model';
import { AccessesForm } from './index';
import i18next from 'i18next';

interface AccessSensor {
  deviceId: string;
  sensorId: string;
}

export const createAccess = (
  assetId: string,
  { description, accesses, datePicker }: AccessesForm,
  locks?: Device[]
): CreateAssetAnonymousAccess => {
  const privateLocks: Locks[] = [];
  const publicLocks: Locks[] = [];

  const ids: AccessSensor[] = accesses.map((access) => {
    const separateAccess = access.split('::::');
    return { deviceId: separateAccess[0], sensorId: separateAccess[1] };
  });

  ids.forEach((id) => {
    const selectedLock = locks?.find((lock) => lock.device_id === id.deviceId);
    if (!!selectedLock) {
      const selectedSensor = selectedLock.sensors.find((sensor) => sensor.sensor_id === id.sensorId);
      if (!!selectedSensor && !!selectedSensor.item_name) {
        const itemLock = {
          item: selectedSensor.item_name,
          label: `${selectedSensor.room} ${selectedSensor.name}` || i18next.t('_NAME_NOT_REPORTED'),
        };

        selectedLock.lock_type === 'private' && privateLocks.push(itemLock);
        selectedLock.lock_type === 'public' && publicLocks.push(itemLock);
      }
    }
  });

  return {
    attributes: {
      alias: description,
      private_locks: privateLocks,
      public_locks: publicLocks,
      valid_from: datePicker[0].unix(),
      valid_until: datePicker[1].unix(),
      asset_id: assetId,
    },
  };
};
