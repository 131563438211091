// no copiar este compo
// lo he creado xk este historia de grafica hay cosas que no tenemos claro

import { FC } from 'react';
import moment, { Moment } from 'moment';
import { AssetTimeSeriesData, SensorSelectedType } from 'models/dataAcquisition.model';
import AccumulatedChart from 'components/pages/AssetPage/Tabs/TabConsumption/OptionChart/AccumulatedChart';
import ConsumptionChart from 'components/pages/AssetPage/Tabs/TabConsumption/OptionChart/ConsumptionChart';
import InstantChart from 'components/pages/AssetPage/Tabs/TabConsumption/OptionChart/InstantChart';
import Color from 'color';
import { useTranslation } from 'react-i18next';
import { SensorEntityModel } from 'core/domain/sensors/models/sensorEntityModels';

const BAR_CHAR_SENSOR: SensorSelectedType[] = ['LITERS_HOUR', 'CUBICMETERS_HOUR', 'KILOWATTSHOUR_HOUR_CH1', 'KILOWATTSHOUR_HOUR_CH2'];
const INSTANT_CHAR_SENSOR: SensorSelectedType[] = ['WATTS_INSTANTANEOUS_CH1', 'WATTS_INSTANTANEOUS_CH2'];

// TODO: esto tenemos que refactorlizar, no puede estar harcoded
const getBarChartColorBySensorType = (sensorType: SensorSelectedType): string => {
  if (sensorType === 'LITERS_ACCUMULATED') return '#38CDFF';
  if (sensorType === 'LITERS_HOUR') return '#38CDFF';
  if (sensorType === 'CUBICMETERS_ACCUMULATED') return '#FF911F';
  if (sensorType === 'CUBICMETERS_HOUR') return '#FF911F';
  return '#3919ED';
};

interface ChartSwitchProps {
  allData: AssetTimeSeriesData;
  dataPresence?: AssetTimeSeriesData;
  dateRange: [Moment, Moment] | null;
  onGetSensorById: (id: string) => SensorEntityModel;
}

export const ChartSwitch: FC<ChartSwitchProps> = ({ allData, dataPresence, dateRange, onGetSensorById }) => {
  const { t } = useTranslation();
  if (!allData) {
    return null;
  }

  const sensorId = allData?.sensor?.type;
  const selectedSensorName = onGetSensorById(sensorId).name;

  const chartColor = getBarChartColorBySensorType(allData.sensor.type);

  const formatDateRange: [Moment, Moment] = dateRange || [moment(), moment()]

  if (BAR_CHAR_SENSOR.includes(allData.sensor.type)) {
    return (
      <ConsumptionChart
        data={allData?.data}
        dataPresence={dataPresence?.data}
        unit={allData?.meta?.unit}
        color={chartColor}
        title={selectedSensorName || t('not_reported')}
        dateRange={formatDateRange}
      />
    );
  }

  if (INSTANT_CHAR_SENSOR.includes(allData.sensor.type)) {
    return (
      <InstantChart
        data={allData?.data}
        dataPresence={dataPresence?.data}
        unit={allData?.meta?.unit}
        color={Color(chartColor).alpha(0.5).rgb().toString()}
        title={selectedSensorName || t('not_reported')}
        dateRange={formatDateRange}
      />
    );
  }

  return (
    <AccumulatedChart
      data={allData?.data}
      dataPresence={dataPresence?.data}
      unit={allData?.meta?.unit}
      color={Color(chartColor).alpha(0.5).rgb().toString()}
      title={selectedSensorName || t('not_reported')}
      dateRange={formatDateRange}
    />
  );
};
