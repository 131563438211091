import { ProjectSharedGatewayModel } from '../model/projectModel';
import { PayloadProjectSharedGatewayModel } from '../model/projectPayloadModel';
import { payloadToManagementLinks } from './payloadToManagementLinks';

export const payloadToSharedGateways = ({
  id,
  master_user,
  qr_code,
  hw_version,
  os_version,
  assistant_cloud,
  voice_assistant_user,
  daemon_version,
  helper_version,
  knx_version,
  sku,
  batch_id,
  batch_name,
  project_id,
  type,
  management_links,
  production_date,
  deployed_at,
}: PayloadProjectSharedGatewayModel): ProjectSharedGatewayModel => {
  const managementLinks = payloadToManagementLinks(management_links);
  return {
    id: id ?? '',
    masterUser: master_user ?? '',
    qrCode: qr_code ?? '',
    hwVersion: hw_version ?? '',
    osVersion: os_version ?? '',
    productionDate: production_date ?? 0,
    deployedAt: deployed_at ?? '',
    assistantCloud: assistant_cloud ?? '',
    voiceAssistantUser: voice_assistant_user ?? '',
    daemonVersion: daemon_version ?? '',
    helperVersion: helper_version ?? '',
    knxVersion: knx_version ?? '',
    sku: sku ?? '',
    batchId: batch_id ?? '',
    batchName: batch_name ?? '',
    projectId: project_id ?? '',
    type: type ?? 'shared',
    managementLinks,
  };
};
