export const thirdPartyServicesListByAssetPath = (
  assetId: string
): string => (
  `/assets/${assetId}/third-party-services`
);

export const thirdPartyServicesListByProjectPath = (
  projectId: string
): string => (
  `/projects/${projectId}/third-party-services`
);

export const thirdPartyServicesCreateByProjectPath = (
  projectId: string
): string => (
  `/projects/${projectId}/third-party-services`
);

export const thirdPartyServicesRemovePath = (
  serviceId: string
): string => (
  `/third-party-services/${serviceId}`
);
