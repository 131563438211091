import { FC, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale-provider';
import UIContainer from 'containers/UI';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { Helmet } from 'react-helmet';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import routes from './routes';
import { I18nextProvider } from 'react-i18next';
import i18n from 'config/i18n';
import { ContextProvider } from 'context';
import NavigationGuard from 'navigation/NavigationGuard';
import { version } from '../../../../package.json'
import { ThemeProvider } from 'styled-components';
import { defaultColors } from '@alfred_developes/alfred-ui-web-components';

interface Props {
  children?: React.ReactNode;
}
const AntConfigProvider: React.FC<Props> = ({ children }) => {
  const [antLocale] = useState<Locale>(require(`antd/lib/locale/es_ES.js`).default);
  return <ConfigProvider locale={antLocale}>{children}</ConfigProvider>;
};


// TagManager.initialize({
//   gtmId: 'GTM-TH3QVTV'
// })

const UpdateCache: FC = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  useEffect(() => {
    !isLatestVersion && emptyCacheStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const App: FC = () => {
  const theme = { colors: defaultColors };

  return (
    <>
      <Helmet>
        <meta name='app version' content={version} />
      </Helmet>
      <ClearCacheProvider duration={5000}>
        <UpdateCache />
        <AntConfigProvider>
          <ThemeProvider theme={theme}>
            <UIContainer.Provider>
              <I18nextProvider i18n={i18n}>
                <ContextProvider>
                  <BrowserRouter>
                    <NavigationGuard routes={routes}>
                      {renderRoutes(routes)}
                    </NavigationGuard>
                  </BrowserRouter>
                </ContextProvider>
              </I18nextProvider>
            </UIContainer.Provider>
          </ThemeProvider>
        </AntConfigProvider>
      </ClearCacheProvider>
    </>
  );
};

export default App;
