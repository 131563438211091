import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetInvitationsList } from 'core/domain/anonymousAccess/repositories/getInvitationsList';
import {
  CustomInvitationModel,
  InvitationsListItemTranslations,
  InvitationData,
  RevokeInvitationModel,
  transformInvitationsListToCustomData,
} from 'components/pages/AssetPage/Tabs/TabAccesses/Access/ListInvitationsModal/resources/utils';
import { RevokeInvitation } from 'core/domain/anonymousAccess/repositories/revokeInvitation';
import { RevokeAnonymousAccess } from 'core/domain/anonymousAccess/repositories/revokeAnonymousAccess';
import { useEnvironment } from './useEnvironment';
import { useUserSession } from './useUserSession';
import { useMessage } from './useMessage';

const PAGE_SIZE = 8;

export const useAnonymousAccess = (assetId: string) => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<CustomInvitationModel[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [allResults, setAllResults] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoadMoreInvitationsButtonVisible, setIsLoadMoreInvitationsButtonVisible] = useState<boolean>(true);
  const [isCreateInvitationModalVisible, setIsCreateInvitationModalVisible] = useState(false);
  const [isCreateBetaInvitationModalVisible, setIsCreateBetaInvitationModalVisible] = useState(false);

  const listInvitationsModalTranslations = {
    title: t('ANONYMOUS_ACCESS.list_invitations'),
    closeButtonText: t('close'),
    loadMoreButtonText: t('load_more'),
  };

  const listInvitationItemTranslations: InvitationsListItemTranslations = {
    revokedStateText: t('revoked'),
    revokeText: t('revoke'),
    expiredStateText: t('expired'),
    invitationStateText: t('invitation'),
    createdByTitle: t('created_by'),
    createdAtTitle: t('created_at'),
    validityTitle: t('validity'),
    accessesTitle: t('accesses'),
    notReportedText: t('not_reported'),
  };

  const createModalTranslations = {
    titleText: t('access_management'),
    createInvitationButtonText: t('ANONYMOUS_ACCESS.create_a_invitation'),
    createInvitationInfoButtonText: t('ANONYMOUS_ACCESS.create_a_invitation_info'),
    listInvitationButtonText: t('ANONYMOUS_ACCESS.list_invitations_info'),
    listInvitationInfoButtonText: t('ANONYMOUS_ACCESS.list_invitations_info'),
    updateAccessListText: t('_ACCESS_UPDATE_LIST'),
  };

  const createBetaInvitationButtonText = t('_ACCESS_CREATE_BETA_INVITATIONS_MODAL_BUTTON_TEXT');

  const getInvitationsListData = async (page: number): Promise<InvitationData> => {
    const initialData: InvitationData = { accesses: [], pageNumber: 1, allResults: 0 };
    try {
      const { data, meta } = await GetInvitationsList({ host, token, assetId, pageSize: PAGE_SIZE, pageNumber: page });
      return { accesses: data, pageNumber: page, allResults: meta.allResults };
    } catch (error) {
      setMessageError({ description: t('_ANONYMOUS_ACCESS_ERROR_MESSAGE') });
      return initialData;
    }
  };

  const onLoadMore = async (page: number): Promise<void> => {
    getData(page);
  };

  const getData = async (page: number) => {
    setIsLoading(true);
    try {
      const newInvitationListData = await getInvitationsListData(page);
      const { accesses, allResults: allNewResults } = newInvitationListData;
      const newInvitations = !!accesses.length ? transformInvitationsListToCustomData(accesses) : [];
      const formattedInvitations = page === 1 ? [...newInvitations] : [...invitations, ...newInvitations];
      setInvitations(formattedInvitations);
      setPageNumber(page);
      setAllResults(allNewResults);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRevoke = async ({ invitationId, isNewInvitation }: RevokeInvitationModel): Promise<void> => {
    try {
      isNewInvitation && (await RevokeInvitation({ host, token, invitationId }));
      !isNewInvitation && (await RevokeAnonymousAccess(invitationId));
      await getData(1);
    } catch (error) {
      setMessageError({ description: t('_ANONYMOUS_ACCESS_REVOKE_ACCESS_ERROR_MESSAGE') });
    }
  };

  const onCloseInvitationsListModal = async () => {
    setIsModalVisible(false);
    setInvitations([]);
    setAllResults(0);
    setPageNumber(1);
    setIsLoadMoreInvitationsButtonVisible(true);
  };

  const onOpenInvitationsListModal = async () => {
    setIsModalVisible(true);
    getData(1);
  };

  const onOpenCreateInvitationModal = () => {
    setIsCreateInvitationModalVisible(true);
  };

  const onCloseCreateInvitationModal = () => {
    setIsCreateInvitationModalVisible(false);
  };

  const onOpenCreateBetaInvitationModal = () => {
    setIsCreateBetaInvitationModalVisible(true);
  };

  const onCloseCreateBetaInvitationModal = () => {
    setIsCreateBetaInvitationModalVisible(false);
  };

  useEffect(() => {
    setIsLoadMoreInvitationsButtonVisible(invitations.length < allResults);
  }, [allResults, invitations]);

  return {
    invitations,
    pageNumber,
    createModalTranslations,
    createBetaInvitationButtonText,
    listInvitationsModalTranslations,
    listInvitationItemTranslations,
    getData,
    onRevoke,
    onLoadMore,
    onCloseInvitationsListModal,
    onOpenInvitationsListModal,
    onOpenCreateInvitationModal,
    onCloseCreateInvitationModal,
    onOpenCreateBetaInvitationModal,
    onCloseCreateBetaInvitationModal,
    invitationListModalVisible: isModalVisible,
    loading: isLoading,
    showLoadMoreInvitationsButton: isLoadMoreInvitationsButtonVisible,
    createInvitationModalVisible: isCreateInvitationModalVisible,
    createBetaInvitationModalVisible: isCreateBetaInvitationModalVisible,
  };
};
