import {
  DEFAULT_MAXIMUM_BOOKINGS_BY_USER,
  DEFAULT_MAXIMUM_TIME_AVAILABILITY_VALUE,
} from 'components/pages/CommonAreaCreatePage/resources/utils';
import { BookingDuration, CommonAreaModel } from '../models/commonAreaEntityModels';
import { PayloadCommonAreaModel, PayloadCommonAreaTimeAvailabilityModel } from '../models/payloadCommonAreaModels';
import { payloadToCommonAreaSensor } from './payloadToCommonAreaSensor';
import { payloadToCommonAreaTimeAvailability } from './payloadToCommonAreaTimeAvailiability';

export const payloadToCommonArea = ({
  id,
  name,
  capacity,
  booking_duration,
  background_image,
  is_background_image_default,
  sensors,
  days_availability,
  time_availability,
  info_user_title,
  info_user_description,
  has_booking,
  enabled,
  maximum_bookings_by_user,
  maximum_time_availability,
}: PayloadCommonAreaModel): CommonAreaModel => {
  const transformDaysAvailability = days_availability ? days_availability.split(',').map((item) => Number(item)) : [];
  const transformSensors = sensors.map((sensor) => payloadToCommonAreaSensor(sensor));

  const transformTimeAvailabilityArray = (timeAvailabilityArray: PayloadCommonAreaTimeAvailabilityModel[]) =>
    timeAvailabilityArray.map((time) => payloadToCommonAreaTimeAvailability(time)) ?? [];
  const transformTimeAvailability = Array.isArray(time_availability)
    ? transformTimeAvailabilityArray(time_availability)
    : [payloadToCommonAreaTimeAvailability(time_availability)] ?? [{ start: '', end: '' }];

  return {
    id: id ?? '',
    name: name ?? '',
    capacity: capacity,
    bookingDuration: booking_duration as BookingDuration,
    backgroundImage: background_image ?? '',
    isBackgroundImageDefault: is_background_image_default ?? false,
    sensors: transformSensors,
    daysAvailability: transformDaysAvailability ?? [],
    timeAvailability: transformTimeAvailability,
    infoUserTitle: info_user_title ?? undefined,
    infoUserDescription: info_user_description ?? undefined,
    hasBooking: has_booking ?? false,
    enabled: enabled ?? false,
    maximumBookingsByUser: maximum_bookings_by_user ?? Number(DEFAULT_MAXIMUM_BOOKINGS_BY_USER),
    maximumTimeAvailability: {
      timePeriod: maximum_time_availability?.time_period ?? '',
      value: maximum_time_availability?.value ?? Number(DEFAULT_MAXIMUM_TIME_AVAILABILITY_VALUE),
    },
  };
};
