import { ThirdPartyServicesByAsset } from '../model/thirdPartyServicesByAsset';

export const ThirdPartyServicesByAssetEntity = ({
  id,
  title,
  description,
  link,
}: ThirdPartyServicesByAsset): Readonly<ThirdPartyServicesByAsset> => {
  return Object.freeze({
    id,
    title,
    description,
    link,
  });
};
