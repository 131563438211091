import { ProjectSharedGatewayModel } from 'core/domain/project/model/projectModel';
import { createContext, FC, useState, Dispatch, SetStateAction } from 'react';

export interface SharedGatewaysContextState {
  sharedGateways: ProjectSharedGatewayModel[];
  setSharedGateways: Dispatch<SetStateAction<ProjectSharedGatewayModel[]>>;
}

interface SharedGatewaysContextProviderProps {
  children: React.ReactNode;
}

const SharedGatewaysContext = createContext<any>({});

export const SharedGatewaysContextProvider: FC<SharedGatewaysContextProviderProps> = ({ children }) => {
  const [sharedGateways, setSharedGateways] = useState<ProjectSharedGatewayModel[]>([]);

  return <SharedGatewaysContext.Provider value={{ sharedGateways, setSharedGateways }}>{children}</SharedGatewaysContext.Provider>;
};

export default SharedGatewaysContext;
