import {
  CreateInvitationAttributesAssetDeviceModel,
  CreateInvitationAttributesAssetDevicePayloadModel,
  CreateInvitationAttributesAssetModel,
  CreateInvitationAttributesAssetPayloadModel,
  CreateInvitationAttributesModel,
  CreateInvitationAttributesPayloadModel,
  CreateInvitationModel,
  CreateInvitationPayloadModel,
  CreateInvitationResponseModel,
  CreateInvitationResponsePayloadModel,
  InvitationAssetDeviceModel,
  InvitationAssetDevicePayloadModel,
  InvitationAssetModel,
  InvitationAssetPayloadModel,
  InvitationCommunityModel,
  InvitationCommunityPayloadModel,
  InvitationListResponseModel,
  InvitationListResponsePayloadModel,
  InvitationModel,
  InvitationPayloadModel,
} from '../models';

export enum InvitationStatusType {
  ACTIVE = 'ACTIVE',
  REVOKED = 'REVOKED',
  EXPIRED = 'EXPIRED',
}

const createInvitationAttributesAssetDeviceToPayload = ({
  deviceId,
  gatewayId,
  label,
  sensorId,
  type,
}: CreateInvitationAttributesAssetDeviceModel): CreateInvitationAttributesAssetDevicePayloadModel => {
  return {
    device_id: deviceId,
    gateway_id: gatewayId,
    label,
    sensor_id: sensorId,
    type,
  };
};

const createInvitationAttributesAssetToPayload = ({
  assetId,
  devices,
}: CreateInvitationAttributesAssetModel): CreateInvitationAttributesAssetPayloadModel => {
  const transformedDevices = devices.map((item) => createInvitationAttributesAssetDeviceToPayload(item));
  return {
    asset_id: assetId,
    devices: transformedDevices,
  };
};

const createInvitationAttributesToPayload = ({
  alias,
  assets,
  type,
  validFrom,
  validUntil,
}: CreateInvitationAttributesModel): CreateInvitationAttributesPayloadModel => {
  const transformedAssets = assets.map((item) => createInvitationAttributesAssetToPayload(item));

  return {
    alias,
    assets: transformedAssets,
    type,
    valid_from: validFrom,
    valid_until: validUntil,
  };
};

export const createInvitationToPayload = ({ attributes }: CreateInvitationModel): CreateInvitationPayloadModel => {
  const transformedAttributes = createInvitationAttributesToPayload(attributes);

  return {
    attributes: transformedAttributes,
  };
};

export const payloadToCreateInvitationResponse = ({
  data: { alias, created_at, status, type, uid, url, valid_from, valid_until },
}: CreateInvitationResponsePayloadModel): CreateInvitationResponseModel => {
  return {
    alias: alias ?? '',
    createdAt: created_at ?? 0,
    status: status ?? '',
    type: type ?? '',
    uid: uid ?? '',
    url: url ?? '',
    validFrom: valid_from ?? 0,
    validUntil: valid_until ?? 0,
  };
};

const payloadToInvitationCommunity = ({
  background_image,
  block_number,
  city,
  country,
  google_address,
  id,
  latitude,
  logo,
  longitude,
  name,
  postal_code,
  province,
  street_name,
  street_type,
}: InvitationCommunityPayloadModel): InvitationCommunityModel => {
  return {
    backgroundImage: background_image ?? '',
    blockNumber: block_number ?? '',
    city: city ?? '',
    country: country ?? '',
    googleAddress: google_address ?? '',
    id,
    latitude: latitude ?? '',
    logo: logo ?? '',
    longitude: longitude ?? '',
    name: name ?? '',
    postalCode: postal_code ?? '',
    province: province ?? '',
    streetName: street_name ?? '',
    streetType: street_type ?? '',
  };
};

const payloadToInvitationAssetDevice = ({
  access_sensor_id,
  device_id,
  gateway_id,
  label,
  sensor_id,
  type,
}: InvitationAssetDevicePayloadModel): InvitationAssetDeviceModel => {
  return {
    accessSensorId: access_sensor_id ?? '',
    deviceId: device_id ?? '',
    gatewayId: gateway_id ?? '',
    label: label ?? '',
    sensorId: sensor_id ?? '',
    type: type ?? '',
  };
};

const payloadToInvitationAsset = ({ asset_id, devices }: InvitationAssetPayloadModel): InvitationAssetModel => {
  const transformedDevices = devices.map((item) => payloadToInvitationAssetDevice(item)) ?? [];

  return {
    assetId: asset_id ?? '',
    devices: transformedDevices,
  };
};

export const payloadToInvitation = ({
  alias,
  assets,
  community,
  created_at,
  created_by,
  revoked_at,
  status,
  type,
  uid,
  url,
  valid_from,
  valid_until,
  is_new_invitation,
}: InvitationPayloadModel): InvitationModel => {
  const transformedCommunity = !!community ? payloadToInvitationCommunity(community) : null;
  const transformedAssets = assets.map((item) => payloadToInvitationAsset(item)) ?? [];

  return {
    alias: alias ?? '',
    assets: transformedAssets,
    community: transformedCommunity,
    createdAt: created_at ?? 0,
    createdBy: created_by ?? '',
    revokedAt: revoked_at,
    status: status ?? '',
    type: type ?? '',
    uid: uid ?? '',
    url: url ?? '',
    validFrom: valid_from ?? 0,
    validUntil: valid_until ?? 0,
    isNewInvitation: is_new_invitation ?? true,
  };
};

export const payloadToInvitationListResponse = ({ data, meta, links }: InvitationListResponsePayloadModel): InvitationListResponseModel => {
  const transformedData = data.map((item) => payloadToInvitation(item)) ?? [];

  return {
    data: transformedData,
    meta: {
      allResults: meta.all_results ?? 1,
      pageSize: meta.page_size ?? 1,
      results: meta.results ?? 1,
      totalPages: meta.total_pages ?? 1,
    },
    links: {
      self: links.self ?? '',
      first: links.first ?? '',
      prev: links.prev ?? '',
      next: links.next ?? '',
      last: links.last ?? '',
    },
  };
};
