import api from 'services/api';
import { qrGatewayPath } from 'constants/endpointsPath/gateways';
import { QrGatewayListValueObject } from '../entities/qrGatewayListValueObject';
import { QrGateway } from '../model/qrGateway';
import { QrGatewayUnparsed } from '../model/qrGateway/qrGatewayUparsed';
import { payloadToQrGateway } from '../mappers/payloadToQrGateway';

export const GetQrGatewayList = async (quantity: string): Promise<Readonly<QrGateway>[]> => {
  const path = qrGatewayPath(quantity);
  const { data } = await api.get<QrGatewayUnparsed[]>(path);
  const qrAssetParser = data.map((item) => payloadToQrGateway(item));
  const { list } = QrGatewayListValueObject(qrAssetParser);
  return list;
};
