import { FC } from 'react'
import { Svg } from "../model/svg"

const Error: FC<Svg> = ({ width = '96', height = '96', ...props }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path opacity='0.15' d='M46.3903 -2.02779e-06C55.6166 -2.43108e-06 64.4298 1.80392 72 6.6C78.1626 10.5043 83.5014 16.7345 87.5686 22.8C94.2071 32.7 96 40.0273 96 49.8C96 63.2175 93 75 84 84C75.9049 92.0546 63.4085 96 48 96C33.2614 96 19.322 89.502 10.8 79.8C5.00269 73.2 -1.59745e-06 59.4545 -2.1506e-06 46.8C-2.69531e-06 34.3385 1.24987 21.4513 8.4 13.2C19.8383 -8.49656e-06 31.2874 -1.36762e-06 46.3903 -2.02779e-06Z' fill='#96C0C4' />
    <path d='M0.499989 49.6097C0.49999 40.4462 2.29175 31.7344 7.02237 24.2676C10.8739 18.1882 17.0404 12.8955 23.0785 8.84667C32.8927 2.26566 40.1224 0.499995 49.8 0.499996C63.1456 0.499997 74.7754 3.48292 83.6458 12.3529C91.5729 20.3199 95.5 32.6658 95.5 48C95.5 62.6001 89.0599 76.4007 79.47 84.8243C76.2357 87.6653 71.2151 90.338 65.4103 92.3003C59.611 94.2608 53.0652 95.5 46.8 95.5C34.3287 95.5 21.623 94.2372 13.5274 87.2221C6.9989 81.5649 3.75588 75.9282 2.13307 69.8501C0.503146 63.7454 0.499988 57.1766 0.499989 49.6097Z' stroke='#96C0C4' />
    <g clip-path='url(#clip0_6252_877)'>
      <path d='M53.1762 28.6808C42.5124 25.8234 31.5386 32.1591 28.6813 42.8229C25.8239 53.4867 32.1596 64.4604 42.8234 67.3178C53.4872 70.1752 64.461 63.8395 67.3183 53.1757C70.1757 42.5119 63.84 31.5381 53.1762 28.6808ZM47.4822 49.9311C46.4196 49.6464 45.7832 48.5442 46.0679 47.4817L48.1385 39.7542C48.4232 38.6917 49.5255 38.0553 50.588 38.34C51.6505 38.6247 52.2869 39.727 52.0022 40.7895L49.9317 48.5169C49.647 49.5794 48.5447 50.2158 47.4822 49.9311ZM47.3435 58.1762L43.4798 57.1409L44.515 53.2772L48.3787 54.3125L47.3435 58.1762Z' fill='#96C0C4' />
    </g>
    <defs>
      <clipPath id='clip0_6252_877'>
        <rect x='31.0293' y='18.6055' width='48' height='48' rx='4' transform='rotate(15 31.0293 18.6055)' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Error;


