import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportTicketCategory } from 'models/supportTickets.model';
import Icon from '@mdi/react';
import {
  mdiAutoFix,
  mdiCellphoneInformation,
  mdiConnection,
  mdiEvStation,
  mdiFire,
  mdiFridgeIndustrialAlertOutline,
  mdiHammerScrewdriver,
  mdiTableFurniture,
  mdiWifiStrength1Alert,
  mdiHomeVariantOutline,
} from '@mdi/js';
import { Space } from 'antd';
import { COLORS } from 'constants/colors';
import './styles.scss';

interface AsSupportTicketsCategoriesProps {
  category: SupportTicketCategory;
  showIcon?: boolean;
}

const AsSupportTicketsCategories: FC<AsSupportTicketsCategoriesProps> = ({ category, showIcon = true }) => {
  const { t } = useTranslation();

  const ICONS = {
    automated: mdiAutoFix,
    connectivity: mdiConnection,
    electric: mdiEvStation,
    furniture: mdiTableFurniture,
    gas: mdiFire,
    home_appliances: mdiFridgeIndustrialAlertOutline,
    home_automation: mdiCellphoneInformation,
    internet: mdiWifiStrength1Alert,
    plumbing: mdiHammerScrewdriver,
    others: mdiHomeVariantOutline,
  };

  return (
    <Space className='AsSupportTicketsCategories'>
      {showIcon && ICONS[category] && <Icon size={0.7} path={ICONS[category]} color={COLORS.gray_extra_light} />}
      {t(`SUPPORT_TICKET_CATEGORY.${category}`)}
    </Space>
  );
};

export default AsSupportTicketsCategories;
