import { useEffect } from 'react';
import { Form } from 'antd';
import { BookingDuration, CommonAreaTimeAvailabilityModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { FormValues, FormValuesResponse, RequiredFormValues } from 'components/molecules/CommonAreaForm/models';
import { useFieldsCommonAreasFormErrors } from 'components/molecules/CommonAreaForm/hooks/useFieldsCommonAreasFormErrors';
import { useWeekDays } from './useWeekDays';
import { useTimeAvailability } from './useTimeAvailability';
import {
  BOOKING_DURATION_OPTIONS,
  DEFAULT_MAXIMUM_BOOKINGS_BY_USER,
  DEFAULT_MAXIMUM_TIME_AVAILABILITY_PERIOD,
  DEFAULT_MAXIMUM_TIME_AVAILABILITY_VALUE,
  DEFAULT_TIME_AVAILABILITY,
} from 'components/pages/CommonAreaCreatePage/resources/utils';

export const useCreateCommonAreasForm = () => {
  const [form] = Form.useForm<FormValues>();
  const { selectedDays, onSelectDay, onUnselectDay } = useWeekDays();
  const { errors, resetErrors, setErrors, getTimeAvailabilityErrorMessage } = useFieldsCommonAreasFormErrors();
  const {
    timeAvailability,
    onAddTimeAvailabilityInterval,
    onChangeTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    onFilterValidTimeAvailabilityIntervals,
    onParsedTimeAvailabilityToBeSent,
    haveTimeAvailabilityInputsErrors,
  } = useTimeAvailability();

  useEffect(() => {
    form.setFieldsValue({ daysAvailability: selectedDays });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays]);

  useEffect(() => {
    form.setFieldsValue({ timeAvailability });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeAvailability]);

  useEffect(() => {
    form.setFieldsValue({
      maximumBookingsByUser: DEFAULT_MAXIMUM_BOOKINGS_BY_USER,
      maximumTimeAvailabilityPeriod: DEFAULT_MAXIMUM_TIME_AVAILABILITY_PERIOD,
      maximumTimeAvailabilityValue: DEFAULT_MAXIMUM_TIME_AVAILABILITY_VALUE,
      timeAvailability: DEFAULT_TIME_AVAILABILITY,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectSensors = (sensorIds: string[]) => {
    form.setFieldsValue({ access: sensorIds });
  };

  const onSelectBookingDuration = (bookingDurationId: BookingDuration) => {
    form.setFieldsValue({ bookingDuration: bookingDurationId });
  };

  const onChangeBackgroundImage = (backgroundImage: string) => {
    form.setFieldsValue({ backgroundImage });
  };

  const checkAllRequiredFields = ({
    name,
    capacity,
    daysAvailability,
    timeAvailability,
    bookingDuration,
    maximumBookingsByUser,
    maximumTimeAvailabilityPeriod,
    maximumTimeAvailabilityValue,
  }: RequiredFormValues) => {
    const haveTimeAvailabilityErrors = haveTimeAvailabilityInputsErrors(timeAvailability);

    if (
      !name ||
      !capacity ||
      !daysAvailability.length ||
      haveTimeAvailabilityErrors ||
      !bookingDuration ||
      !maximumBookingsByUser ||
      !maximumTimeAvailabilityPeriod ||
      !maximumTimeAvailabilityValue
    ) {
      throw new Error('There are some fields not completed');
    }
  };

  const resetFormNullTimeAvailabilityIntervals = (time: (CommonAreaTimeAvailabilityModel | null)[]) => {
    const filteredValidIntervals = onFilterValidTimeAvailabilityIntervals(time);
    form.setFieldsValue({ timeAvailability: filteredValidIntervals });
  };

  const onParseValues = ({ capacity, access, timeAvailability, ...values }: FormValues): FormValuesResponse | undefined => {
    resetFormNullTimeAvailabilityIntervals(timeAvailability);
    const transformedTimeAvailability = onParsedTimeAvailabilityToBeSent(timeAvailability);

    try {
      checkAllRequiredFields({ capacity, timeAvailability, ...values });
      resetErrors();
      return {
        capacity: Number(capacity),
        sensors: access,
        timeAvailability: transformedTimeAvailability,
        ...values,
      };
    } catch (error: any) {
      setErrors({ capacity, timeAvailability: transformedTimeAvailability, ...values });
    }
  };

  return {
    Form,
    form,
    errors,
    selectedDays,
    bookingDurationOptionList: BOOKING_DURATION_OPTIONS,
    timeAvailability,
    onSelectDay,
    onUnselectDay,
    onSelectSensors,
    onSelectBookingDuration,
    onChangeTimeAvailabilityInterval,
    onChangeBackgroundImage,
    onParseValues,
    onAddTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    getTimeAvailabilityErrorMessage,
  };
};
