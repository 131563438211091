import { FC } from 'react';
import ComingSoonTemplate from 'components/template/ComingSoonTemplate';
import { useParkingSlotComingSoon } from './resources/useParkingSlotComingSoon';

const ParkingSlotComingSoonPage: FC = () => {
  const { forbiddenText } = useParkingSlotComingSoon();

  return <ComingSoonTemplate text={forbiddenText} />
}

export default ParkingSlotComingSoonPage;