import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = ({ host, invitationId }: { host: string; invitationId: string }): string => {
  return `${host}/access/${invitationId}/revoke`;
};

export const RevokeInvitation = async ({
  host,
  token,
  invitationId,
}: {
  host: string;
  token: string;
  invitationId: string;
}): Promise<void> => {
  try {
    const url = getUrl({ host, invitationId });

    const options: AxiosRequestConfig = {
      url,
      method: 'PUT',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    (await axios(options)) as AxiosResponse<void>;
  } catch (error: any) {
    throw getErrorFormat(error);
  }
};
