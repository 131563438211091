import { SensorEditEntityModel } from '../models/sensorEntityModels';
import { SensorUpdatePayloadModel } from '../models/sensorPayloadModels';

export const sensorEditToPayload = ({ name, room, usage, canDataBeFetched }: SensorEditEntityModel): SensorUpdatePayloadModel => {
  return {
    name,
    room,
    usage,
    can_data_be_fetched: canDataBeFetched,
  };
};
