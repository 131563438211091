import { Upload, UploadProps } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FC } from 'react';
import './styles.scss';

const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

interface ImageUploadButtonProps extends UploadProps {
  loading: boolean;
}

const ImageUploadButton: FC<ImageUploadButtonProps> = ({ loading, ...props }) => {
  return (
    <Upload name='xxx' listType='picture-card' customRequest={dummyRequest} showUploadList={false} {...props}>
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </Upload>
  );
};

export default ImageUploadButton;
