import { Asset } from 'models/assets.model';
import { ListResponse } from 'models/general.model';

export type Included = {
  assets: Array<Asset>;
};

export type Query = { [k: string]: string | number | boolean };

export type ActiveIssue =
  | 'cpu_temperature'
  | 'cpu_load'
  | 'cpu_ram'
  | 'system_time'
  | 'uptime'
  | 'openhab_serviced'
  | 'openhab_api'
  | 'cpu_hdd_used'
  | 'zwave_controller'
  | 'assistant_cloud_service'
  | 'vpn_connection'
  | 'online'
  | 'cpu_ram_used'
  | 'cpu_hd_used'
  | 'ohs_api'
  | 'ohs_service'
  | 'cpu_hd_available'
  | 'cpu_ram_available'
  | 'total_swap'
  | 'used_swap'
  | 'total_inodes'
  | 'used_inodes'
  | 'vpn_ip';

export type Sku =
  | 'ALFPAS0008'
  | 'ALFPAS0007'
  | 'ALFPAS0066'
  | 'ALFPAS0065'
  | 'ALFPAS0017'
  | 'ALFPAS0016'
  | 'ALFPAS0001'
  | 'ALFPAS0011'
  | 'ALFPAS0010'
  | 'ALFPAS0002';

export type CPUStatusKey = 'cpu_hdd_used' | 'cpu_ram_used' | 'cpu_temperature';

export interface Thresholds {
  time_offline: number;
  cpu_temperature: number;
  cpu_ram_used: number;
  cpu_hdd_used: number;
  cpu_load: number;
  used_inodes: number;
  used_swap: number;
}

export interface GatewayStatusDevices extends GatewayStatus {
  batch_id?: string;
  created_at?: number;
  daemon_version?: string;
  qr_code?: string;
  sku?: Sku;
  management_links: Links;
}

export interface GatewayStatus {
  gateway_id: string;
  asset_id: string;
  project_id: string;
  location: string;
  vpn_ip?: string;
  gateway_version?: string;
  hw_version?: string;
  knx_version?: string;
  firmware_version?: string;
  helper_version?: string;
  zwave_version?: string;
  deployment_id?: string;
  uptime: number;
  last_connection: number;
  time_offline: number | null;
  production_date: number | null;
  cpu_temperature: number | null;
  cpu_ram_used: number | null;
  cpu_hdd_used: number | null;
  health: number | null;
  total_inodes: number | null;
  total_swap: number | null;
  used_swap: number | null;
  used_inodes: number | null;
  cpu_load: number | null;
  zwave_controller: boolean | null;
  vpn_connection: boolean | null;
  helper_service: boolean | null;
  helper_api: boolean | null;
  assistant_cloud_service: boolean | null;
  active_issues: ActiveIssue[];
  thresholds: Thresholds;
}

export interface GatewayStatusUnits {
  cpu_temperature: string;
  cpu_load: string;
  cpu_ram: string;
  system_time: string;
  uptime: string;
  openhab_api: string;
  openhab_serviced: string;
  cpu_hdd_used: string;
  zwave_controller: string;
  assistant_cloud_service: string;
  vpn_connection: string;
  cpu_ram_used: string;
  cpu_hd_used: string;
  ohs_api: string;
  ohs_service: string;
  cpu_hd_available: string;
  cpu_ram_available: string;
  total_swap: string;
  used_swap: string;
  total_inodes: string;
  used_inodes: string;
  vpn_ip: string;
  battery_level: string;
  connectivity: string;
  internal_temperature: string;
  latency: string;
}

export interface GatewayDevices {
  device_id: number;
  device_type: string;
  label: string | null;
  health: number;
  active_issues: ActiveIssue[];
  battery_level: number;
  connectivity: boolean | null;
  latency: number | null;
  internal_temperature: number;
  created_at: number;
  updated_at: number;
}

export enum LinksTypes {
  control_panel = 'control_panel',
  openhab = 'openhab',
  ssh_teleport = 'ssh_teleport',
}

export interface Links {
  [LinksTypes.openhab]: string | null;
  [LinksTypes.ssh_teleport]: string | null;
}

export interface ManagementLinks {
  [LinksTypes.openhab]: {
    url: string | null;
    disabled: boolean;
    title: string;
  };
  [LinksTypes.ssh_teleport]: {
    url: string | null;
    disabled: boolean;
    title: string;
  };
}

export interface Gateway {
  id: string;
  master_user: string;
  qr_code: string;
  gateway_version: string;
  project: string;
  hw_version: string;
  os_version: string;
  assistant_cloud: string;
  assistant_cloud_url: string;
  voice_assistant_user: string;
  daemon_version?: string;
  helper_version?: string;
  production_date: number;
  deployment_date: string | number;
  knx_version?: string;
  assets: Asset[];
  status: GatewayStatus;
  batch_id?: string;
  batch_name?: string;
  firmware_version?: string;
  sku?: Sku;
  zwave_version: null;
  devices?: GatewayDevices;
  management_links: Links;
}

export interface GatewayDetailResponse {
  data: Gateway;
  included: {
    assets: Array<Asset>;
  };
}

export interface GatewayStatusResponse {
  data: GatewayStatus[];
  units: GatewayStatusUnits;
  links?: {
    first: string;
    last: string;
    next: string;
    prev: string;
    self: string;
  };
  meta?: {
    all_results: number;
    page_size: number;
    results: number;
    total_pages: number;
  };
}

export interface GatewayStatusGIDResponse {
  data: GatewayStatusDevices[];
  units: GatewayStatusUnits;
  gatewayId: string;
  gatewayQid: string;
}

export interface Alert {
  gateway_id: string;
  metric: string;
  value: number;
  threshold: number;
  timestamp: number;
  message: string;
}

export type AlertsResponse = ListResponse<Alert>;

export type StatusesParams = {
  current: number;
  size: number;
  filter?: string;
  sort?: string;
};
