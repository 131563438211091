import { deleteBookingPath } from 'core/endspoints/bookings';
import api from 'services/api';

export const DeleteBooking = async (bookingId: string): Promise<void> => {
  const url = deleteBookingPath(bookingId);
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    await api.delete(url);
  } catch (error: any) {
    throw new Error(error);
  }
};
