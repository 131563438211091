import { useEffect, useState } from 'react';
import { Form } from 'antd';
import {
  BookingDuration,
  CommonAreaModel,
  CommonAreaTimeAvailabilityModel,
} from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { FormValues, FormValuesResponse, RequiredFormValues } from 'components/molecules/CommonAreaForm/models';
import { useFieldsCommonAreasFormErrors } from 'components/molecules/CommonAreaForm/hooks/useFieldsCommonAreasFormErrors';
import { useWeekDays } from './useWeekDays';
import { useTimeAvailability } from './useTimeAvailability';
import { BOOKING_DURATION_OPTIONS } from 'components/pages/CommonAreaCreatePage/resources/utils';

interface UseCommonAreasForm {
  commonAreaData: CommonAreaModel;
}

const formInput = {
  NAME: 'name',
  ACCESS: 'access',
  CAPACITY: 'capacity',
  DAYS_AVAILABILITY: 'daysAvailability',
  TIME_AVAILABILITY: 'timeAvailability',
  BOOKING_DURATION: 'bookingDuration',
  BACKGROUND_IMAGE: 'backgroundImage',
  INFO_USER_TITLE: 'infoUserTitle',
  INFO_USER_DESCRIPTION: 'infoUserDescription',
  MAXIMUM_BOOKINGS_BY_USER: 'maximumBookingsByUser',
  MAXIMUM_TIME_AVAILABILITY_PERIOD: 'maximumTimeAvailabilityPeriod',
  MAXIMUM_TIME_AVAILABILITY_VALUE: 'maximumTimeAvailabilityValue',
};

export const useUpdateCommonAreasForm = ({ commonAreaData }: UseCommonAreasForm) => {
  const [form] = Form.useForm<FormValues>();
  const { selectedDays, onSelectDay, onUnselectDay } = useWeekDays(commonAreaData.daysAvailability);
  const [temporaryBackgroundImage, setTemporaryBackgroundImage] = useState<string>('');
  const [areThereChanges, setAreThereChanges] = useState<boolean>(false);
  const { errors, resetErrors, setErrors, getTimeAvailabilityErrorMessage } = useFieldsCommonAreasFormErrors();
  const {
    timeAvailability,
    onAddTimeAvailabilityInterval,
    onChangeTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    onFilterValidTimeAvailabilityIntervals,
    onParsedTimeAvailabilityToBeSent,
    areTimeAvailabilityArraysEquals,
    haveTimeAvailabilityInputsErrors,
  } = useTimeAvailability(commonAreaData.timeAvailability);

  useEffect(() => {
    const haveDaysChanged = JSON.stringify(selectedDays) !== JSON.stringify(commonAreaData.daysAvailability);
    setAreThereChanges(haveDaysChanged);
    form.setFieldsValue({ daysAvailability: selectedDays });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDays]);

  useEffect(() => {
    const haveTimeAvailabilityChanged = !areTimeAvailabilityArraysEquals(timeAvailability, commonAreaData.timeAvailability);
    setAreThereChanges(haveTimeAvailabilityChanged);
    form.setFieldsValue({ timeAvailability });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeAvailability]);

  useEffect(() => {
    commonAreaData && getInitialFormValues(commonAreaData);
    commonAreaData && setTemporaryBackgroundImage(commonAreaData.backgroundImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonAreaData]);

  const getInitialFormValues = (commonAreaData: CommonAreaModel) => {
    form.setFieldsValue({
      name: commonAreaData.name,
      capacity: commonAreaData ? String(commonAreaData.capacity) : '1',
      daysAvailability: commonAreaData.daysAvailability,
      timeAvailability: commonAreaData ? onFilterValidTimeAvailabilityIntervals(commonAreaData.timeAvailability) : [null],
      bookingDuration: BOOKING_DURATION_OPTIONS.find((item) => item.id === commonAreaData.bookingDuration)?.id,
      infoUserTitle: commonAreaData.infoUserTitle,
      infoUserDescription: commonAreaData.infoUserDescription,
      maximumBookingsByUser: commonAreaData.maximumBookingsByUser || 1000,
      maximumTimeAvailabilityPeriod: commonAreaData.maximumTimeAvailability.timePeriod || 'month',
      maximumTimeAvailabilityValue: String(commonAreaData.maximumTimeAvailability.value) || '6',
      backgroundImage: commonAreaData.backgroundImage,
    });
  };

  const onSelectSensors = (sensorIds: string[]) => {
    form.setFieldsValue({ access: sensorIds });
  };

  const onSelectBookingDuration = (bookingDurationId: BookingDuration) => {
    form.setFieldsValue({ bookingDuration: bookingDurationId });
  };

  const onChangeBackgroundImage = (backgroundImage: string) => {
    form.setFieldsValue({ backgroundImage });
    setTemporaryBackgroundImage(backgroundImage);
  };

  const checkAllRequiredFields = ({
    name,
    capacity,
    daysAvailability,
    timeAvailability,
    bookingDuration,
    maximumBookingsByUser,
    maximumTimeAvailabilityPeriod,
    maximumTimeAvailabilityValue,
  }: RequiredFormValues) => {
    const haveTimeAvailabilityErrors = haveTimeAvailabilityInputsErrors(timeAvailability);

    if (
      !name ||
      !capacity ||
      !daysAvailability.length ||
      haveTimeAvailabilityErrors ||
      !bookingDuration ||
      !maximumBookingsByUser ||
      !maximumTimeAvailabilityPeriod ||
      !maximumTimeAvailabilityValue
    ) {
      throw new Error('There are some fields not completed');
    }
  };

  const resetFormNullTimeAvailabilityIntervals = (time: (CommonAreaTimeAvailabilityModel | null)[]) => {
    const filteredValidIntervals = onFilterValidTimeAvailabilityIntervals(time);
    form.setFieldsValue({ timeAvailability: filteredValidIntervals });
  };

  const onParseValues = ({ capacity, access, timeAvailability, ...values }: FormValues): FormValuesResponse | undefined => {
    resetFormNullTimeAvailabilityIntervals(timeAvailability);
    const transformedTimeAvailability = onParsedTimeAvailabilityToBeSent(timeAvailability);

    try {
      checkAllRequiredFields({ capacity, timeAvailability, ...values });
      resetErrors();
      return {
        capacity: Number(capacity),
        sensors: access,
        timeAvailability: transformedTimeAvailability,
        ...values,
      };
    } catch (error) {
      setErrors({ capacity, timeAvailability: transformedTimeAvailability, ...values });
    }
  };

  const areThereMandatoryChanges = ({
    name,
    capacity,
    daysAvailability,
    timeAvailability,
    bookingDuration,
    maximumBookingsByUser,
    maximumTimeAvailabilityPeriod,
    maximumTimeAvailabilityValue,
  }: FormValues): boolean => {
    const hasNameChanged = name !== commonAreaData.name;
    const hasCapacityChanged = Number(capacity) !== commonAreaData.capacity;
    const hasDaysAvailabilityChanged = JSON.stringify(daysAvailability) !== JSON.stringify(commonAreaData.daysAvailability);
    const haveTimeAvailabilityIntervalsChanged = haveTimeAvailabilityInputsErrors(timeAvailability);
    const hasBookingDurationChanged = bookingDuration !== commonAreaData.bookingDuration;
    const hasMaximumBookingsByUserChanged = maximumBookingsByUser !== commonAreaData.maximumBookingsByUser;
    const hasMaximumTimeAvailabilityPeriodChanged = maximumTimeAvailabilityPeriod !== commonAreaData.maximumTimeAvailability.timePeriod;
    const hasMaximumTimeAvailabilityValueChanged = Number(maximumTimeAvailabilityValue) !== commonAreaData.maximumTimeAvailability.value;

    return [
      hasNameChanged,
      hasCapacityChanged,
      hasDaysAvailabilityChanged,
      haveTimeAvailabilityIntervalsChanged,
      hasBookingDurationChanged,
      hasMaximumBookingsByUserChanged,
      hasMaximumTimeAvailabilityPeriodChanged,
      hasMaximumTimeAvailabilityValueChanged,
    ].some((item) => item);
  };

  const areArraysEquals = (a: any, b: any): boolean => {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((value, index) => value === b[index]);
  };

  const changesManager = {
    [formInput.NAME]: (values: string[]): boolean => values[0] !== commonAreaData.name,
    [formInput.ACCESS]: (values: string[]): boolean => !areArraysEquals(values, commonAreaData.sensors),
    [formInput.CAPACITY]: (values: string[]): boolean => values[0] !== JSON.stringify(commonAreaData.capacity),
    [formInput.DAYS_AVAILABILITY]: (values: string[]): boolean => values[0] !== JSON.stringify(commonAreaData.daysAvailability),
    [formInput.TIME_AVAILABILITY]: (values: string[]): boolean => {
      const parsedTimeAvailability = values[0].split(' ');
      const newTimeAvailability: (CommonAreaTimeAvailabilityModel | null)[] = [
        { start: parsedTimeAvailability[0], end: parsedTimeAvailability[1] },
      ];
      return !areTimeAvailabilityArraysEquals(newTimeAvailability, commonAreaData.timeAvailability);
    },
    [formInput.BOOKING_DURATION]: (values: string[]): boolean => values[0] !== JSON.stringify(commonAreaData.bookingDuration),
    [formInput.INFO_USER_TITLE]: (values: string[]): boolean => {
      const infoUserTitle = commonAreaData.infoUserTitle || '';
      return values[0] !== infoUserTitle;
    },
    [formInput.INFO_USER_DESCRIPTION]: (values: string[]): boolean => {
      const infoUserDescription = commonAreaData.infoUserDescription || '';
      return values[0] !== infoUserDescription;
    },
    [formInput.MAXIMUM_BOOKINGS_BY_USER]: (values: string[]) => Number(values[0]) !== commonAreaData.maximumBookingsByUser,
    [formInput.MAXIMUM_TIME_AVAILABILITY_PERIOD]: (values: string[]) => values[0] !== commonAreaData.maximumTimeAvailability.timePeriod,
    [formInput.MAXIMUM_TIME_AVAILABILITY_VALUE]: (values: string[]) => Number(values[0]) !== commonAreaData.maximumTimeAvailability.value,
    [formInput.BACKGROUND_IMAGE]: (values: string[]) => values[0] !== commonAreaData.backgroundImage,
  };

  const onChangeInputFieldsValues = ({ label, values }: { label: string; values: string[] }): void => {
    const item = changesManager[label];
    const isChange = item(values);
    setAreThereChanges(isChange);
  };

  return {
    Form,
    form,
    errors,
    selectedDays,
    bookingDurationOptionList: BOOKING_DURATION_OPTIONS,
    timeAvailability,
    backgroundImage: temporaryBackgroundImage,
    onSelectDay,
    onUnselectDay,
    onSelectSensors,
    onSelectBookingDuration,
    onChangeTimeAvailabilityInterval,
    onParseValues,
    onChangeInputFieldsValues,
    onAddTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    getTimeAvailabilityErrorMessage,
    onChangeBackgroundImage,
    areThereChanges,
    areThereMandatoryChanges,
  };
};
