import api from 'services/api';
import { BookingModel } from '../models/bookingEntityModels';
import { bookingListPath } from 'core/endspoints/bookings';
import { PayloadBookingModel } from '../models/payloadBookingModels';
import { payloadToBooking } from '../mappers/payloadToBooking';
import BookingListValueObject from '../entities/bookingListValueObject';

export const GetBookingListByProjectId = async (projectId: string): Promise<BookingModel[] | undefined> => {
  try {
    const path = bookingListPath(projectId);
    api.defaults.headers.common['Accept-version'] = '1';
    const { data } = await api.get<PayloadBookingModel[]>(path);
    const bookingParsed = data.map((item) => payloadToBooking(item));
    const { list } = BookingListValueObject(bookingParsed);
    return list.reverse();
  } catch (error: any) {
    throw new Error(error);
  }
};
