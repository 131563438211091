import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemsConfig } from 'components/pages/AdminPage/resources/config';
import { openFile } from 'components/pages/ClientDetailPage/TabLegalDocumentation/resources/utils';
import { getUnauthorizedError } from 'services/api/errors';
import { useProfileUser } from 'hooks/useProfileUser';
import { UpdateLegalDocumentationStatus } from 'core/domain/users/repositories/updateLegalDocumentation';
import { GetClientById } from 'core/domain/client/repositories/getClientById';
import { CheckLegalDocumentation } from 'core/domain/client/repositories/checkLegalDocumentation';
import { LegalDocumentationCheckedModel } from 'core/domain/client/models/clientEntityModel';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH_ADMIN } from 'components/pages/App/configRoutes';

const DEFAULT_CLIENT_NAME = 'Alfred Smart Systems';

export const useLegalDocumentation = () => {
  const { t } = useTranslation();
  const { data } = useProfileUser();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLegalDocumentationModalVisible, setIsLegalDocumentationModalVisible] = useState<boolean>(true);
  const [legalDocumentation, setLegalDocumentation] = useState<LegalDocumentationCheckedModel>();
  const [clientName, setClientName] = useState<string>(DEFAULT_CLIENT_NAME);

  const legalDocumentationModalTranslations = {
    title: t('_CURRENT_USER_LEGAL_DOCUMENTATION_MODAL_TITLE'),
    text: `${clientName} ${t('_CURRENT_USER_LEGAL_DOCUMENTATION_MODAL_TEXT')}`,
    termsOfServiceLinkText: t('_CURRENT_USER_LEGAL_DOCUMENTATION_MODAL_TERMS_OF_SERVICE_LINK_TEXT'),
    privacyPolicyLinkText: t('_CURRENT_USER_LEGAL_DOCUMENTATION_MODAL_PRIVACY_POLICY_LINK_TEXT'),
    mainButtonText: t('_CURRENT_USER_LEGAL_DOCUMENTATION_MODAL_MAIN_BUTTON_TEXT'),
    secondaryButtonText: t('_CURRENT_USER_LEGAL_DOCUMENTATION_MODAL_SECONDARY_BUTTON_TEXT'),
  };

  const MenuItems = MenuItemsConfig().filter((item) => !item.hidden);

  const onAcceptLegalDocumentation = async () => {
    setIsLoading(true);
    try {
      const response = data && (await UpdateLegalDocumentationStatus());
      response !== undefined && setIsLegalDocumentationModalVisible(false);
      response && history.push(ROUTE_PATH_ADMIN);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRevokeLegalDocumentation = () => {
    setIsLegalDocumentationModalVisible(false);
    getUnauthorizedError();
  };

  const onOpenTermsOfServiceLink = () => {
    legalDocumentation && openFile(legalDocumentation.linkTermsOfService);
  };

  const onOpenPrivacyPolicyLink = () => {
    legalDocumentation && openFile(legalDocumentation.linkPrivacyPolicy);
  };

  const getClientName = async (clientId: string): Promise<string | undefined> => {
    try {
      const response = await GetClientById(clientId);
      return response?.name;
    } catch (error) {
      console.warn(error);
    }
  };

  const checkLegalDocumentation = async () => {
    setIsLoading(true);

    try {
      const response = await CheckLegalDocumentation();
      response && setLegalDocumentation(response);
      response && response.isAccepted && setIsLegalDocumentationModalVisible(true);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    data &&
      data.client &&
      getClientName(data.client.id).then((name) => {
        name && setClientName(name);
      });
  }, [data]);

  useEffect(() => {
    checkLegalDocumentation();
  }, []);

  return {
    MenuItems,
    legalDocumentationModalTranslations,
    onAcceptLegalDocumentation,
    onRevokeLegalDocumentation,
    onOpenTermsOfServiceLink,
    onOpenPrivacyPolicyLink,
    loading: isLoading,
    legalDocumentationModalVisible: isLegalDocumentationModalVisible,
  };
};
