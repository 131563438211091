import { FC } from 'react';
import { Skeleton } from 'antd';
import './styles.scss';

const AssetSupportTicketsStatisticsLoadingTemplate: FC = () => {
  return (
    <div className='AssetSupportTicketsStatisticsLoading__container'>
      <div className='AssetSupportTicketsStatisticsLoading__header'>
        <Skeleton.Input className='AssetSupportTicketsStatisticsLoading__header-skeleton' active />
      </div>
      <div className='AssetSupportTicketsStatisticsLoading__content'>
        <div className='AssetSupportTicketsStatisticsLoading__skeleton-wrapper'>
          <Skeleton.Input className='AssetSupportTicketsStatisticsLoading__skeleton' active />
        </div>
        <div className='AssetSupportTicketsStatisticsLoading__skeleton-wrapper'>
          <Skeleton.Input className='AssetSupportTicketsStatisticsLoading__skeleton' active />
        </div>
        <div className='AssetSupportTicketsStatisticsLoading__skeleton-wrapper'>
          <Skeleton.Input className='AssetSupportTicketsStatisticsLoading__skeleton' active />
        </div>
        <div className='AssetSupportTicketsStatisticsLoading__skeleton-wrapper'>
          <Skeleton.Input className='AssetSupportTicketsStatisticsLoading__skeleton' active />
        </div>
        <div className='AssetSupportTicketsStatisticsLoading__skeleton-wrapper'>
          <Skeleton.Input className='AssetSupportTicketsStatisticsLoading__skeleton' active />
        </div>
        <div className='AssetSupportTicketsStatisticsLoading__skeleton-wrapper'>
          <Skeleton.Input className='AssetSupportTicketsStatisticsLoading__skeleton' active />
        </div>
      </div>
    </div>

  );
}

export default AssetSupportTicketsStatisticsLoadingTemplate;