import { SensorEntityModel } from '../models/sensorEntityModels';
import { SensorPayloadModel } from '../models/sensorPayloadModels';

export const payloadToSensorsAssets = ({
  id,
  uid,
  name,
  room,
  usage,
  sensor_type,
  are_values_accumulated,
  can_data_be_fetched,
}: SensorPayloadModel): SensorEntityModel => {
  return {
    id,
    uid,
    name: name ?? '',
    room: room ?? '',
    usage: usage ?? '',
    sensorType: sensor_type ?? '',
    areValuesAccumulated: are_values_accumulated,
    canDataBeFetched: can_data_be_fetched,
  };
};
