import { FC } from 'react';
import ClientCustomizationLoadingTemplate from 'components/template/ClientCustomizationLoadingTemplate';
import ClientCustomizationTemplate from 'components/template/ClientCustomizationTemplate';
import { useClientCustomization } from './resources/useClientCustomization';

const SubTabAssetsManagerAndEmail: FC = () => {
  const {
    initialFormValues,
    customizationFormValues,
    customizationPreviewValues,
    translations,
    form,
    Form,
    onChangeMade,
    onCancel,
    onPublish,
    loading,
    updating,
    areThereValidChanges,
    formValuesCanceled,
  } = useClientCustomization();

  return (
    <>
      {loading && <ClientCustomizationLoadingTemplate />}
      {!loading && (
        <ClientCustomizationTemplate
          initialFormValues={initialFormValues}
          customizationFormValues={customizationFormValues}
          customizationPreviewValues={customizationPreviewValues}
          translations={translations}
          form={form}
          Form={Form}
          onChangeMade={onChangeMade}
          onCancel={onCancel}
          onPublish={onPublish}
          disabled={!areThereValidChanges}
          updating={updating}
          canceled={formValuesCanceled}

        />)
      }
    </>
  )
}

export default SubTabAssetsManagerAndEmail;