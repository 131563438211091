import { RouteConfig } from 'react-router-config';
import TechSupportPage from 'components/pages/TechSupportPage';
import TechSupportsPage from 'components/pages/TechSupportsPage';
import { ROUTE_PATH_SUPPORT_TICKET, ROUTE_PATH_SUPPORT_TICKETS } from './config';
export const supportTicketsRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_SUPPORT_TICKETS,
    exact: true,
    component: TechSupportsPage,
  },
  {
    path: ROUTE_PATH_SUPPORT_TICKET,
    exact: true,
    component: TechSupportPage,
  },
];
