import { FC } from 'react'
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { ClientEditFormValues, ClientEditFormValuesNames } from 'hooks/useClient/models';
import { ClientEditFormTranslations } from 'hooks/useClient/useClientTranslations';

interface Props {
  formTranslations: ClientEditFormTranslations;
  form: FormInstance<ClientEditFormValues>;
  onChange: (values: { [key in ClientEditFormValuesNames]: string }) => void;
  onFinish: (values: ClientEditFormValues) => void;
  onFinishFailed: () => void;
  editing: boolean;
}

const CHARACTERS_MAX_LENGTH = { description: 255 };

const ClientEditForm: FC<Props> = ({
  formTranslations,
  form,
  onChange,
  onFinish,
  onFinishFailed,
  editing,
}) => {
  const {
    name,
    namePlaceholder,
    nameError,
    email,
    emailPlaceholder,
    emailError,
    emailErrorFormat,
    description,
    descriptionPlaceholder,
  } = formTranslations;
  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={(values: { [key in ClientEditFormValuesNames]: string }) => onChange(values)}
      layout={'vertical'}
      requiredMark={false}
    >
      <div>
        <Form.Item
          label={`${name} *`}
          name={ClientEditFormValuesNames.NAME}
          rules={[{ required: true, message: nameError }]}
        >
          <Input disabled={editing} placeholder={namePlaceholder} />
        </Form.Item>
        <Form.Item
          label={`${email} *`}
          name={ClientEditFormValuesNames.EMAIL}
          rules={[
            { required: true, message: emailError },
            { type: ClientEditFormValuesNames.EMAIL, message: emailErrorFormat },
          ]}
        >
          <Input disabled={editing} placeholder={emailPlaceholder} />
        </Form.Item>
        <Form.Item label={description} name={ClientEditFormValuesNames.DESCRIPTION}>
          <Input
            disabled={editing}
            maxLength={CHARACTERS_MAX_LENGTH.description}
            showCount={true}
            placeholder={descriptionPlaceholder}
          />
        </Form.Item>
      </div>
    </Form>
  );
}

export default ClientEditForm