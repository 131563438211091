import { FC } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import CommonAreaFormGrid from 'components/molecules/CommonAreaForm/components/CommonAreaFormGrid';
import { BookingDuration, CommonAreaTimeAvailabilityModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { FormValues } from 'components/molecules/CommonAreaForm/models';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import './styles.scss';

interface Props {
  createCommonAreaRequiredFieldsText: string;
  bookingDurationOptionList: { id: BookingDuration, name: string }[];
  errors: any;
  form: FormInstance<FormValues>;
  Form: any;
  selectedDays: number[];
  sensorOptionList: SensorByProjectModel[];
  timeAvailability: (CommonAreaTimeAvailabilityModel | null)[];
  onChangeTimeAvailability: (values: [moment.Moment, moment.Moment] | null, index: number) => void;
  onAddTimeAvailabilityInterval: () => void;
  onDeleteTimeAvailabilityInterval: (index: number) => void;
  getTimeAvailabilityErrorMessage: () => void
  onCloseCreateCommonArea: () => void;
  onFinishCreateCommonArea: (values: FormValues) => void;
  onSelectBookingDuration: (bookingDurationId: BookingDuration) => void;
  onSelectDay: (day: number) => void;
  onUnSelectDay: (day: number) => void;
  onSelectSensors: (sensorIds: string[]) => void;
  onChangeBackgroundImage: (image: string) => void;
  saving: boolean;
}

const CommonAreaCreateTemplate: FC<Props> = ({
  createCommonAreaRequiredFieldsText,
  bookingDurationOptionList,
  errors,
  form,
  Form,
  selectedDays,
  sensorOptionList,
  timeAvailability,
  onChangeTimeAvailability,
  onAddTimeAvailabilityInterval,
  onDeleteTimeAvailabilityInterval,
  getTimeAvailabilityErrorMessage,
  onCloseCreateCommonArea,
  onFinishCreateCommonArea,
  onSelectBookingDuration,
  onSelectDay,
  onUnSelectDay,
  onSelectSensors,
  onChangeBackgroundImage,
  saving,
}) => {
  return (
    <div className={'CommonAreaCreateTemplate__body'}>
      <div className={'CommonAreaCreateTemplate__body-required'}>
        <p>{createCommonAreaRequiredFieldsText}</p>
      </div>
      <CommonAreaFormGrid
        bookingDurationOptionList={bookingDurationOptionList}
        errors={errors}
        form={form}
        Form={Form}
        selectedDays={selectedDays}
        sensorOptionList={sensorOptionList}
        timeAvailability={timeAvailability}
        onChangeTimeAvailability={onChangeTimeAvailability}
        onClose={onCloseCreateCommonArea}
        onFinish={({ values }) => onFinishCreateCommonArea(values)}
        onSelectBookingDuration={onSelectBookingDuration}
        onSelectDay={onSelectDay}
        onSelectSensors={onSelectSensors}
        onUnselectDay={onUnSelectDay}
        onAddTimeAvailabilityInterval={onAddTimeAvailabilityInterval}
        onDeleteTimeAvailabilityInterval={onDeleteTimeAvailabilityInterval}
        getTimeAvailabilityErrorMessage={getTimeAvailabilityErrorMessage}
        onChangeBackgroundImage={onChangeBackgroundImage}
        isSaving={saving}
      />
    </div>
  )
}

export default CommonAreaCreateTemplate;