import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Checkbox } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import './styles.scss';

export interface RegisterUserFormValuesType {
  name: string;
  email: string;
  password: string;
  confirm: string;
  cellphone: string;
  terms_of_service: boolean;
}

interface RegisterUserFormProps { 
  onSubmit: (values: RegisterUserFormValuesType) => void;
  loading: boolean;
  linkLopd: string; 
}

const RegisterUserForm: FC<RegisterUserFormProps> = ({onSubmit, loading, linkLopd}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('global');

  return (
    <Form form={form} layout="vertical" onFinish={(values) => onSubmit(values)} className='RegisterExpertForm' >
      <Form.Item name="name" label={t('name_surname')} rules={[{ required: true, message: t('field_required') }]} >
        <Input name='name'/>
      </Form.Item>
      <Form.Item
        name={'email'}
        label={t('email')}
        hasFeedback
        rules={[
          { message: t('email_no_valid'), type: 'email' },
          { required: true, message: t('field_required') },
        ]}
      >
        <Input autoComplete='on' name='email' />
      </Form.Item>
      <Form.Item
        name="password"
        label={t('password')}
        hasFeedback
        rules={[{ required: true, message: t('field_required') }]}
      >
        <Input.Password name='password' suffix={<EyeOutlined className='Icon__gray-extra-light' />} />
      </Form.Item>
      <Form.Item
        name="confirm"
        label={t('password_confirm')}
        dependencies={['password']}
        hasFeedback
        rules={[
          { required: true, message: t('password_confirm_message')},
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(t('password_confirm_reject'));
            },
          })
        ]}
      >
        <Input.Password name='confirm' />
      </Form.Item>
      <Form.Item
        name="cellphone"
        label={t('phone_number')}
        hasFeedback
        rules={[{ required: true, message: t('field_required') }]}
      >
        <Input name="cellphone"/>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="terms_of_service"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(t('field_required')),
          },
        ]}
      >
        <Checkbox>
          <a href={linkLopd} target="_blank" rel="noopener noreferrer">{t('form_agreement')}</a>
        </Checkbox>
      </Form.Item>
      <Form.Item shouldUpdate={true}>
        <Button loading={loading} disabled={loading} type='primary' htmlType='submit' className={'RegisterUserForm__submit'} >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegisterUserForm;
