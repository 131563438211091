import { FC, useState } from 'react';
import Paper from 'components/atoms/Paper';
import OptionChart from './OptionChart';
import OptionList from './OptionList';
import SegmentedControl from 'components/atoms/SegmentedControl';
import { Icon } from 'components/atoms/Icons';
import { COLORS } from 'constants/colors';
import './styles.scss';
import { useTranslation } from 'react-i18next';

interface TabConsumptionProps {
  assetId: string;
}

const DEFAULT_SEGMENT = 1;

enum Segment {
  ONE,
  TWO,
}

const TabConsumption: FC<TabConsumptionProps> = ({ assetId }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<number>(DEFAULT_SEGMENT);

  const handleOnSelect = (key: number) => {
    setSelectedValue(key);
  };

  return (
    <Paper>
      <div className={'TabConsumption__containerSelector'}>
        <SegmentedControl
          defaultIndex={DEFAULT_SEGMENT}
          tabs={[
            {
              tab: <Icon.ListColor twoToneColor={selectedValue === 0 ? COLORS.corporate : COLORS.textSubtitle} />,
              tooltipText: `${t('SENSOR_LIST')}`,
            },
            {
              tab: <Icon.BarChartColor twoToneColor={selectedValue === 1 ? COLORS.corporate : COLORS.textSubtitle} />,
              tooltipText: `${t('CONSUMPTION_CHART')}`,
            },
          ]}
          onSelection={handleOnSelect}
        />
      </div>
      {selectedValue === Segment.ONE && <OptionList assetId={assetId} />}
      {selectedValue === Segment.TWO && <OptionChart />}
    </Paper>
  );
};

export default TabConsumption;
