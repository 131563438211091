import { SecondLevelAreaUpdateModel } from "../model/projectLayoutConfigModels"
import { PayloadUpdateSecondLevelArea } from "../model/projectLayoutConfigPayloadModels"

export const layoutConfigSecondLevelAreaToUpdatePayload =({
  id,
  type,
  nameEn,
  nameEs,
  imageURL,
  imageBASE64,
  projectId,
  verticalId,
 }: SecondLevelAreaUpdateModel): PayloadUpdateSecondLevelArea[] => {
  return [{
    id,
    type,
    name_en: nameEn,
    name_es: nameEs,
    image_url: imageURL,
    image_base_64: imageBASE64,
    project_id: projectId,
    vertical_id: verticalId,
  }]
}