import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAnonymousAccess } from 'hooks/useAnonymousAccess';
import { AccessColumn } from '../Access/AccessColumn';
import { SensorWithPasswordsModel } from 'core/domain/sensors/models/sensorEntityModels';
import { useAccessPasswordsDrawer } from './useAccessPasswords';
import { GetSensorsListWithPasswordsByAssetId } from 'core/domain/sensors/repositories/getSensorsListWithPasswordsByAssetId';
import { GetAssetDetail } from 'core/domain/assets/repositories/getAssetDetail';
import { useProfileUser } from 'hooks/useProfileUser';
import { UserRole, UserRoleType } from 'models/users.model';
import { AssetVerticalsEnum } from 'core/domain/assets/models';
import { getAccessPublicDevices } from 'services/api/assets';
import { DeviceWithGatewayId } from 'models/devices.model';
import { useCheckInRoom } from 'hooks/useCheckInRoom/useCheckInRoom';

export interface AccessListModel extends SensorWithPasswordsModel {
  parameterName: string;
  parameterRoom: string;
}

const sensorListToAccessList = (sensorList: SensorWithPasswordsModel[], deviceList: DeviceWithGatewayId[]): AccessListModel[] => {
  return sensorList.map((sensor) => {
    const currentDevice = deviceList.find((device) => sensor.deviceName === device.device_name && sensor.deviceType === device.device_type)

    return {
    parameterName: currentDevice?.parameters.name || '',
    parameterRoom: currentDevice?.parameters.room || '',
    ...sensor
  }
})
}

export const useAccess = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { data: userData } = useProfileUser();
  const [devices, setDevices] = useState<AccessListModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentDevice, setCurrentDevice] = useState<SensorWithPasswordsModel>({} as SensorWithPasswordsModel);
  const [assetType, setAssetType] = useState<AssetVerticalsEnum>(AssetVerticalsEnum.HOME);
  const [assetName, setAssetName] = useState<string>('');
  const [isAssignRoomButtonVisible, setIsAssignRoomButtonVisible] = useState<boolean>(false);
  const { checkInStates } = useCheckInRoom();
  const {
    invitations,
    pageNumber,
    listInvitationsModalTranslations,
    listInvitationItemTranslations,
    createModalTranslations,
    createBetaInvitationButtonText,
    onRevoke,
    onLoadMore,
    onCloseInvitationsListModal,
    onOpenInvitationsListModal,
    onOpenCreateInvitationModal,
    onCloseCreateInvitationModal,
    onOpenCreateBetaInvitationModal,
    onCloseCreateBetaInvitationModal,
    loading: loadingAccesses,
    showLoadMoreInvitationsButton,
    invitationListModalVisible,
    createInvitationModalVisible,
    createBetaInvitationModalVisible,
  } = useAnonymousAccess(assetId);
  const {
    adminPasswords,
    adminAvailablePasswords,
    nfcPasswords,
    userPasswords,
    userAvailablePasswords,
    drawerTranslations,
    deleteModalTranslations,
    closeModalTranslations,
    onCloseDrawer,
    onCloseDrawerModal,
    onConfirmCloseDrawer,
    onClickDevice,
    onTogglePassword,
    onChangePassword,
    onCloseDeleteModal,
    onDeletePassword,
    onClickConfirmDeletingPassword,
    onClickAddPassword,
    onSavePasswordsChanges,
    onConfirmCloseDrawerModalWithoutSaving,
    drawerPasswordVisible,
    adminPasswordsAddButtonVisible,
    userPasswordsAddButtonVisible,
    nfcPasswordsAddButtonVisible,
    confirmDeletingModalVisible,
    closeDrawerModalVisible,
    adminSectionVisible,
    userSectionVisible,
    nfcSectionVisible,
    updateSuccess,
    loadingUpdate,
    saveButtonDisabled,
  } = useAccessPasswordsDrawer();

  const assignRoomButtonText = t('_ACCESS_ASSIGN_ROOM')

  const isUserPropertyManager = !!userData?.roles.includes(UserRoleType.ROLE_ASSETS_MANAGER_ADMIN as UserRole);
  const isAssetTypeHome = assetType === AssetVerticalsEnum.HOME;
  const isCreateAndListInvitationsForbidden = isUserPropertyManager && isAssetTypeHome;

  const getSensorsList = async (): Promise<void> => {
    setLoading(true);
    try {
      const devicesWithPasswordSensors = await GetSensorsListWithPasswordsByAssetId(assetId);
      const accessPublicDevicesList = await getAccessPublicDevices(assetId)
      const devices = sensorListToAccessList(devicesWithPasswordSensors, accessPublicDevicesList)
      devicesWithPasswordSensors && setDevices(devices);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  const onRowClick = (device: SensorWithPasswordsModel): void => {
    const deviceName = !!device.deviceName ? device.deviceName : t('_NAME_NOT_REPORTED');
    const deviceType = !!device.deviceType ? device.deviceType : t('not_reported');
    const newDevice = { ...device, deviceName, deviceType };
    setCurrentDevice(newDevice);
    onClickDevice(newDevice);
  };

  const accessColumn = AccessColumn();

  useEffect(() => {
    getSensorsList();
    GetAssetDetail(assetId).then((response) => {
      response && setAssetType(response.data.type as AssetVerticalsEnum);
      response && setAssetName(response.data.alias);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    setIsAssignRoomButtonVisible(assetType === AssetVerticalsEnum.HOME || assetType === AssetVerticalsEnum.HOTEL_ROOM);
  }, [assetType])

  useEffect(() => {
    !!updateSuccess && getSensorsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  const onOpenAssignRoomDrawer = () => {
    checkInStates.drawer.onCheckInRoom(assetName, assetId)
  }

  return {
    assetId,
    devices,
    currentDevice,
    accessColumn,
    invitations,
    pageNumber,
    form,
    createModalTranslations,
    createBetaInvitationButtonText,
    drawerTranslations,
    adminPasswords,
    adminAvailablePasswords,
    nfcPasswords,
    userPasswords,
    userAvailablePasswords,
    deleteModalTranslations,
    closeModalTranslations,
    listInvitationsModalTranslations,
    listInvitationItemTranslations,
    assignRoomButtonText,
    checkInStates,
    onOpenCreateInvitationModal,
    onCloseCreateInvitationModal,
    onOpenCreateBetaInvitationModal,
    onCloseCreateBetaInvitationModal,
    onCloseInvitationsListModal,
    onOpenInvitationsListModal,
    onOpenAssignRoomDrawer,
    onLoadMore,
    onRevoke,
    onRowClick,
    onClosePasswordsDrawer: onCloseDrawer,
    onClosePasswordsDrawerModal: onCloseDrawerModal,
    onConfirmClosePasswordDrawer: onConfirmCloseDrawer,
    onTogglePassword,
    onChangePassword,
    onCloseDeleteModal,
    onDeletePassword,
    onClickConfirmDeletingPassword,
    onClickAddPassword,
    onSavePasswordsChanges,
    onConfirmCloseDrawerModalWithoutSaving,
    onGetSensorsList: getSensorsList,
    isAssignRoomButtonVisible,
    loading,
    loadingAccesses,
    createInvitationModalVisible,
    createBetaInvitationModalVisible,
    invitationListModalVisible,
    drawerPasswordVisible,
    adminPasswordsAddButtonVisible,
    userPasswordsAddButtonVisible,
    nfcPasswordsAddButtonVisible,
    confirmDeletingModalVisible,
    closeDrawerModalVisible,
    adminSectionVisible,
    userSectionVisible,
    nfcSectionVisible,
    loadingUpdate,
    saveButtonDisabled,
    showLoadMoreInvitationsButton,
    isCreateAndListInvitationsForbidden,
  };
};
