import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAsset } from 'services/api/assets';
import { ExpandedPeripheralDevicesTranslations } from 'components/organisms/PeripheralDevices/ExpandedColumnsPeripheralsDevices';
import { useAssetDevices } from 'hooks/useAssetDevices';

export const useTabDevices = () => {
  const { t } = useTranslation();
  const { assetId } = useParams<{ assetId: string }>();
  const [loading, setLoading] = useState(true);
  const [gateway, setGateway] = useState<string>();
  const { devices, searchInput, columnSearchTranslations, onSearch, onReset, loading: loadingDevices } = useAssetDevices(assetId);

  const peripheralsTitle: string = t('peripheral');
  const peripheralExpandedColumnsTranslations: ExpandedPeripheralDevicesTranslations = {
    nameText: t('name'),
    roomText: t('room'),
    typeText: t('type'),
    usageText: t('usage'),
    notReportedText: t('not_reported'),
  };

  const getAssetLinkedGateways = async (): Promise<string[]> => {
    try {
      const { data } = await getAsset(assetId);
      return !!data ? data.gateways_qrs.map((gateway) => gateway.gateway_id) : [];
    } catch (error) {
      console.warn(error);
      return [];
    }
  };

  const getGatewaysListData = async () => {
    setLoading(true);
    try {
      const linkedGateways = await getAssetLinkedGateways();
      !!linkedGateways.length && setGateway(linkedGateways[0]);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGatewaysListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return {
    devices,
    gateway,
    peripheralsTitle,
    peripheralExpandedColumnsTranslations,
    searchInput,
    columnSearchTranslations,
    onSearch,
    onReset,
    loading: loading || loadingDevices,
  };
};
