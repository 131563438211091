import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import AlfredDetailTemplate from '../../template/AlfredDetailTemplate';
import { useClient } from '../../../hooks/useClient/useClient';
import HeaderDescription from './HeaderDescription';
import TabBillableUserList from './TabBillableUserList';
import TabCustomization from './TabCustomization';
import { ClientCustomizationSubTab, ClientTab } from './resources/models';
import SubTabAssetsManagerAndEmail from './TabCustomization/SubTabs/SubTabAssetsManagerAndEmail';
import ClientMenuExtra from 'components/organisms/ClientMenuExtra';
import ClientEditModal from 'components/organisms/ClientEditModal';
import { useClientEdit } from 'hooks/useClient/useClientEdit';
import TabLegalDocumentation from './TabLegalDocumentation';
import { hasPermission } from 'services/permissions';
import { UserPermissionType } from 'models/users.model';
import TabProjectsList from './TabProjects';

const ClientDetailPage: FC = () => {
  const { tab, subTab } = useParams<{ clientId: string; tab: ClientTab; subTab: ClientCustomizationSubTab }>();
  const { t } = useTranslation();
  const { onSelectClient } = useClient();
  const {
    data,
    clientEditTranslations,
    form,
    clientDescription,
    clientDescriptionLabel,
    clientEmail,
    clientEmailLabel,
    totalNumberUsersClient,
    totalNumberUsersClientLabel,
    onClickContextualEditButton,
    onCancelEditForm,
    onSubmitEditForm,
    onSubmitEditFormFailed,
    onChangeEditFormValues,
    loading,
    updating,
    editModalVisible,
    areThereChanges,
  } = useClientEdit();

  const { menuExtraTranslations, editModalTranslations, editFormTranslations } = clientEditTranslations;

  return (
    <>
      <AlfredDetailTemplate
        currentTabKey={tab}
        title={(data && data.name) || ''}
        loading={loading}
        extra={[
          <ClientMenuExtra
            key={'ClientMenuExtra'}
            onClickContextualEditButton={onClickContextualEditButton}
            itemsTranslations={menuExtraTranslations}
          />
        ]}
        description={
          <HeaderDescription
            clientDescription={clientDescription}
            clientDescriptionLabel={clientDescriptionLabel}
            clientEmail={clientEmail}
            clientEmailLabel={clientEmailLabel}
            totalNumberUsersClient={totalNumberUsersClient}
            totalNumberUsersClientLabel={totalNumberUsersClientLabel}
          />
        }
        tabsTitles={(currentTab) => (
          <Tabs defaultActiveKey={currentTab} onChange={(activeTab: any) => onSelectClient({ activeTab })}>
            <Tabs.TabPane
              tab={t('_SETTINGS_CLIENT_BILLABLE_USERS_TAB_TITLE')}
              key={ClientTab.BILLABLE_USERS}
            />
            <Tabs.TabPane
              tab={t('_SETTINGS_CLIENT_CUSTOMIZATION_TAB_TITLE')}
              key={ClientTab.CUSTOMIZATION}
            />
            {hasPermission(UserPermissionType.READ_LEGAL_DOCUMENTATION) && (
              <Tabs.TabPane
                tab={t('_SETTINGS_CLIENT_DOCUMENTATION_TAB_TITLE')}
                key={ClientTab.LEGAL_DOCUMENTATION}
              />
            )}
            <Tabs.TabPane
              tab={t('_SETTINGS_CLIENT_PROJECTS_TAB_TITLE')}
              key={ClientTab.PROJECTS}
            />
          </Tabs>
        )}
      >
        {[
          { key: ClientTab.BILLABLE_USERS, render: <TabBillableUserList key={ClientTab.BILLABLE_USERS} /> },
          {
            key: ClientTab.CUSTOMIZATION,
            render: (
              <TabCustomization key={ClientTab.CUSTOMIZATION} subTab={subTab} onSubTab={onSelectClient}>
                {[
                  {
                    key: ClientCustomizationSubTab.ASSETS_AND_EMAILS,
                    render: <SubTabAssetsManagerAndEmail key={ClientCustomizationSubTab.ASSETS_AND_EMAILS} />,
                  },
                ]}
              </TabCustomization>
            ),
          },
          { key: ClientTab.LEGAL_DOCUMENTATION, render: <TabLegalDocumentation key={ClientTab.LEGAL_DOCUMENTATION} /> },
          { key: ClientTab.PROJECTS, render: <TabProjectsList key={ClientTab.PROJECTS} /> },
        ]}
      </AlfredDetailTemplate>
      <ClientEditModal
        modalTranslations={editModalTranslations}
        formTranslations={editFormTranslations}
        form={form}
        onCancelEditForm={onCancelEditForm}
        onChangeEditFormValues={onChangeEditFormValues}
        onSubmitEditForm={onSubmitEditForm}
        onSubmitEditFormFailed={onSubmitEditFormFailed}
        editing={updating}
        visible={editModalVisible}
        disabled={!areThereChanges}
      />
    </>
  );
};

export default ClientDetailPage;
