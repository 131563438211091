import api from 'services/api';
import { eventsAndNewsPath } from 'core/endspoints/eventsAndNews';
import { payloadToEventsAndNews } from '../mappers/payloadToEventsAndNews';
import { EventsAndNewsListValueObject } from '../entities/eventsAndNewsListValueObject';
import { PayloadEventsAndNewsModel } from '../models/payloadEventsAndNews';
import { EventsAndNewsModel } from '../models/eventsAndNewsEntityModels';

type GetModel = (projectId: string, itemId: string) => Promise<EventsAndNewsModel | undefined>;

export const GetEventsAndNewsById: GetModel = async (projectId, itemId) => {
  try {
    const path = eventsAndNewsPath(projectId);
    const { data } = await api.get<PayloadEventsAndNewsModel[]>(path);
    const eventsAndNewsParser = data.map((item) => payloadToEventsAndNews(item));
    const { list } = EventsAndNewsListValueObject(eventsAndNewsParser);
    const selectedItem = list.find((item) => itemId === item.id);
    return selectedItem;
  } catch (error) {
    console.error(error);
  }
};
