import { ReactNode, VFC } from 'react';
import Paper from 'components/atoms/Paper';
import { Skeleton } from 'antd';

interface TabTemplateProps {
  loading?: boolean;
  children: ReactNode;
}

const TabTemplate: VFC<TabTemplateProps> = ({ children, loading = false }) => {
  return <Paper>{loading ? <Skeleton active /> : children}</Paper>;
};

export default TabTemplate;
