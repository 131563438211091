import { FC } from 'react';
import { ClientCustomizationFormValues } from 'components/pages/ClientDetailPage/TabCustomization/SubTabs/SubTabAssetsManagerAndEmail/resources/models';
import './styles.scss';

interface Props {
  previewValues: ClientCustomizationFormValues;
}

const ClientCustomizationPreView: FC<Props> = ({ previewValues }) => {
  const { logo, backgroundColor } = previewValues;

  return (
    <div className={'PreView__container'}>
      <div className={'PreView__header'} style={{ backgroundColor }}>
        <div className={'PreView__logo'}>
          <img src={logo} alt={''} />
        </div>
      </div>
    </div>
  );
}

export default ClientCustomizationPreView;