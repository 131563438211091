import { ROUTE_PATH_LOGIN, ROUTE_PATH_MAINTENANCE } from 'components/pages/App/routes';
import { LocalStorageKeys } from 'constants/localStorage';

export enum InstanceError {
  UNAUTHORIZED = '401',
  MAINTENANCE = '503',
}

export const getUnauthorizedError = () => {
  LocalStorageKeys.TOKEN && localStorage.removeItem(LocalStorageKeys.TOKEN);
  setTimeout(() => window.location.pathname !== ROUTE_PATH_LOGIN && (window.location.pathname = ROUTE_PATH_LOGIN), 2000);
};

export const getServiceUnavailableError = () => {
  window.location.pathname !== ROUTE_PATH_MAINTENANCE && (window.location.pathname = ROUTE_PATH_MAINTENANCE);
};

const serviceResponseErrorToControlledError = {
  [InstanceError.UNAUTHORIZED]: getUnauthorizedError,
  [InstanceError.MAINTENANCE]: getServiceUnavailableError,
};

export const serviceResponseErrorManager = (status?: number) => {
  return status && serviceResponseErrorToControlledError[String(status) as InstanceError]();
};
