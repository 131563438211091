import { useEffect, useState } from 'react';
import { Color } from 'react-color';

interface ColorComplete {
  color: typeof Color;
  onChangeColor: (color: string) => void;
}

const ARTICLE_CLASS_NAME = 'ColorPicker__popover';

export const useColorPicker = (color: string) => {
  const [colorValue, setColorValue] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    color && setColorValue(color);
  }, [color]);

  const onChangeColorComplete = ({ color, onChangeColor }: ColorComplete) => {
    const { hex } = color;
    setColorValue(hex);
    onChangeColor(hex);
  };

  const onClickColorButton = () => {
    setIsVisible(!isVisible);
  };

  const onClickElement = (event: MouseEvent) => {
    const target = event.target as Element;
    const targetWrapperClassName = target.closest('article')?.getAttribute('class');
    isVisible && targetWrapperClassName !== ARTICLE_CLASS_NAME && setIsVisible(false);
  };

  useEffect(() => {
    window.addEventListener('mousedown', onClickElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return {
    colorValue,
    onChangeColorComplete,
    onClickColorButton,
    isVisible,
  };
};
