import { FC } from 'react';
import TabContentLoading from 'components/molecules/TabContentLoading';
import TabEventsAndNewsMenu, { TabEventsAndNewsMenuItem } from 'components/molecules/TabEventsAndNewsMenu';
import { EventsAndNewsVisorViews } from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabEventsAndNews/enums/visorViews';
import './styles.scss';

interface EventsAndNewsLoadingTemplateProps {
  menuListItems: TabEventsAndNewsMenuItem[];
  selectedItemId?: string;
  visibleType: EventsAndNewsVisorViews;
  onVisibleCreate: () => void;
  onClickMenuItem: (id: string) => void;
}

const EventsAndNewsLoadingTemplate: FC<EventsAndNewsLoadingTemplateProps> = ({ ...props }) => {
  return (
    <div className={'EventsAndNewsLoadingTemplate__container'}>
      <div className={'EventsAndNewsLoadingTemplate__menu'}>
        <TabEventsAndNewsMenu {...props} />
      </div>
      <div className={'EventsAndNewsLoadingTemplate__content'}>
        <TabContentLoading />
      </div>
    </div>
  );
};

export default EventsAndNewsLoadingTemplate;
