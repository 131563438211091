import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateFormValues } from 'components/molecules/EventsAndNewsForm/models';
import { useCreateEventsAndNewsForm } from 'components/molecules/EventsAndNewsForm/hooks/useCreateEventsAndNewsForm';
import EventsAndNewsFormGrid from 'components/molecules/EventsAndNewsForm/components/EventsAndNewsFormGrid';
import { EventsAndNewsVisorViews } from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabEventsAndNews/enums/visorViews';
import './styles.scss';

interface EventsAndNewsFormCreateTemplateProps {
  onCreateEventsAndNews: (values: CreateFormValues) => void;
  onInvisibleCreate: () => void;
  isSaving: boolean;
}

enum TRANSLATION {
  requiredFields = '_EVENTS_AND_NEWS_REQUIRED_FIELDS',
}

const EventsAndNewsFormCreateTemplate: FC<EventsAndNewsFormCreateTemplateProps> = ({
  onCreateEventsAndNews,
  onInvisibleCreate,
  isSaving,
}) => {
  const { t } = useTranslation();
  const {
    errors,
    form,
    Form,
    onParseValues,
  } = useCreateEventsAndNewsForm();

  const handleOnFinish = (values: CreateFormValues) => {
    const parsedValues = onParseValues(values);
    parsedValues && onCreateEventsAndNews(parsedValues);
  };

  return (
    <div className={'EventsAndNewsFormCreateTemplate__container'}>
      <div className={'EventsAndNewsFormCreateTemplate__required-info'}>
        <p>{t(TRANSLATION.requiredFields)}</p>
      </div>
      <EventsAndNewsFormGrid<CreateFormValues>
        typeForm={EventsAndNewsVisorViews.CREATE}
        errors={errors}
        form={form}
        Form={Form}
        onClose={onInvisibleCreate}
        onFinish={({ values }) => handleOnFinish(values)}
        isSaving={isSaving}
      />
    </div>
  );
};

export default EventsAndNewsFormCreateTemplate;
