import { FC } from 'react';
import { RcFile } from 'antd/lib/upload';
import UploadButton from 'components/atoms/UploadButton';
import UploadActionsVisor from 'components/atoms/UploadActionsVisor';
import './styles.scss';

interface Props {
  buttonText: string;
  acceptedTypes: string;
  fileName: string
  onChange: (data: any) => void;
  onCheckBeforeUpload: (file: RcFile) => void;
  onDelete: () => void;
  onShow: () => void;
  loading: boolean;
  disabledUploadButton?: boolean;
  disabledInfo?: boolean;
  showInfo?: boolean;
  showActionVisible?: boolean;
  deleteActionVisible?: boolean;
}

const FileUploader: FC<Props> = ({
  buttonText,
  acceptedTypes,
  fileName,
  onChange,
  onCheckBeforeUpload,
  onDelete,
  onShow,
  loading,
  disabledUploadButton = false,
  disabledInfo = false,
  showInfo = false,
  showActionVisible = true,
  deleteActionVisible = true,
}) => {
  return (
    <div className={'FileUploader__wrapper'}>
      <UploadButton
        loading={loading}
        buttonText={buttonText}
        onChange={({ file }) => onChange(file)}
        beforeUpload={onCheckBeforeUpload}
        disabled={disabledUploadButton}
        accept={acceptedTypes}
      />
      {showInfo && (
        <UploadActionsVisor
          name={fileName}
          onDelete={onDelete}
          onShow={onShow}
          showActionVisible={showActionVisible}
          deleteActionVisible={deleteActionVisible}
          disabled={disabledInfo}
        />
      )}
    </div>

  );
}

export default FileUploader;