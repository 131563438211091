import { LegalDocumentationModel } from '../models/clientEntityModel';
import LegalDocumentationEntity from './legalDocumentationEntity';

const LegalDocumentationListValueObject = (list: LegalDocumentationModel[]): Readonly<{ list: Readonly<LegalDocumentationModel>[] }> => {
  return Object.freeze({
    list: list.map((item: LegalDocumentationModel) => LegalDocumentationEntity(item)),
  });
};

export default LegalDocumentationListValueObject;
