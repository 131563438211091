import { FC } from 'react';
import { Progress } from 'antd';
import { COLORS } from 'constants/colors';
import './styles.scss';

interface HealthProgressProps {
  percent: number;
};

const HealthProgress: FC<HealthProgressProps> = ({ percent }) => {
  const handleStatusColor = (percent: number) => {
    if (percent >= 70) return COLORS.success;
    if (percent <= 69 && percent >= 30) return COLORS.orange;
    return COLORS.alert;
  };

  return (
    <div className='HealthProgress__health'>
      <Progress status='active' percent={percent} size='small' strokeColor={handleStatusColor(percent)} />
    </div>
  );
};

export default HealthProgress;
