import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = ({ host, gatewayQid, assetId }: { host: string; gatewayQid: string; assetId: string }): string => {
  return `${host}/gateways/${assetId}/deployments/by-qr-code/${gatewayQid}`;
};

export const AssignGateway = async ({
  host,
  token,
  gatewayQid,
  assetId,
}: {
  host: string;
  token: string;
  gatewayQid: string;
  assetId: string;
}): Promise<void> => {
  try {
    const url = getUrl({ host, gatewayQid, assetId });

    const options: AxiosRequestConfig = {
      url,
      method: 'PATCH',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<void>;
    return data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
