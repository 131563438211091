import { FC } from 'react';
import { Svg } from '../model/svg';

const Invitation: FC<Svg> = ({ width = '18', height = '20', ...props }) => (
  <svg width={width} height={height} viewBox='0 0 18 20' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13 11H10C9.45 11 9 11.45 9 12V15C9 15.55 9.45 16 10 16H13C13.55 16 14 15.55 14 15V12C14 11.45 13.55 11 13 11ZM13 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2H15V1C15 0.45 14.55 0 14 0C13.45 0 13 0.45 13 1ZM15 18H3C2.45 18 2 17.55 2 17V7H16V17C16 17.55 15.55 18 15 18Z'
    />
  </svg>
);

export default Invitation;
