import { SensorEntityModel } from '../models/sensorEntityModels';

export const SensorAssetEntity = ({
  id,
  uid,
  name,
  room,
  usage,
  sensorType,
  areValuesAccumulated,
  canDataBeFetched,
}: SensorEntityModel): SensorEntityModel => {
  return Object.freeze({
    id,
    uid,
    name,
    room,
    usage,
    sensorType,
    areValuesAccumulated,
    canDataBeFetched,
  });
};
