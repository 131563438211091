import { FC } from 'react';
import ComingSoonTemplate from 'components/template/ComingSoonTemplate';
import { useHomeComingSoon } from './resources/useHomeComingSoon';

const HomeComingSoonPage: FC = () => {
  const { forbiddenText } = useHomeComingSoon();

  return <ComingSoonTemplate text={forbiddenText} />
}

export default HomeComingSoonPage;