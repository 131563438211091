import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import GetThirdPartyServicesListByProject from 'core/domain/thirdPartyServices/repositories/getThirdPartyServicesListByProject';
import { ThirdPartyServicesByProject } from 'core/domain/thirdPartyServices/model/thirdPartyServicesByProject';
import CreateThirdPartyServicesByProject from 'core/domain/thirdPartyServices/repositories/createThirdPartyServicesByProject';
import { ThirdPartyServicesCreateByProject } from 'core/domain/thirdPartyServices/model/thirdPartyServicesCreateByProject';
import RemoveThirdPartyServices from 'core/domain/thirdPartyServices/repositories/removeThirdPartyServices';
import { TableActionsMenuProps } from 'components/atoms/TableAtom';

const sortReverse = (data: ThirdPartyServicesByProject[]): ThirdPartyServicesByProject[] => {
  return data.reverse();
};

interface UseThirdPartyServicesListByProjectResponse {
  data: ThirdPartyServicesByProject[];
  usefulContactsTableActionsMenu: TableActionsMenuProps[];
  removeServiceModalTitle: string;
  removeServiceModalText: string;
  removeServiceModalMainButtonText: string;
  removeServiceModalSecondaryButtonText: string;
  onSave: (data: ThirdPartyServicesCreateByProject) => Promise<void>;
  onRemove: (serviceId: string) => void;
  onConfirmRemove: () => Promise<void>;
  onCloseConfirmModal: () => void;
  onOpenCreateUsefulContactModal: () => void;
  onCloseCreateUsefulContactModal: () => void;
  onCreateUsefulContact: (values: ThirdPartyServicesCreateByProject) => Promise<void>;
  error: boolean;
  loading: boolean;
  removeServiceModalVisible: boolean;
  createServiceModalVisible: boolean;
}

export const useThirdPartyServicesListByProject = (projectId: string): UseThirdPartyServicesListByProjectResponse => {
  const { t } = useTranslation();
  const [data, setData] = useState<ThirdPartyServicesByProject[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [currentServiceId, setCurrentServiceId] = useState<string>('');
  const [removeServiceModalVisible, setRemoveServiceModalVisible] = useState<boolean>(false);
  const [createServiceModalVisible, setCreateServiceModalVisible] = useState<boolean>(false);

  const removeServiceModalTitle = t('_ASSET_DETAIL_USEFUL_CONTACTS_MODAL_TITLE');
  const removeServiceModalText = t('revoke_service_confirm');
  const removeServiceModalMainButtonText = t('delete');
  const removeServiceModalSecondaryButtonText = t('cancel');

  const usefulContactsTableActionsMenu: TableActionsMenuProps[] = [
    {
      key: 'unlinkUser',
      onClick: (serviceId: string) => onRemove(serviceId),
      text: t('delete'),
      disabled: false,
    },
  ];

  const onFallbackThirdPartyServicesListByProject = useCallback(async (projectId: string) => {
    try {
      const data = await GetThirdPartyServicesListByProject(projectId);
      const services = !data.length ? sortReverse(data) : data;
      setData(services);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsError(false);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    onFallbackThirdPartyServicesListByProject(projectId).finally(() => setIsLoading(false));
  }, [projectId, onFallbackThirdPartyServicesListByProject]);

  const onSave = async (data: ThirdPartyServicesCreateByProject) => {
    setIsLoading(true);
    try {
      await CreateThirdPartyServicesByProject(projectId, data);
      await onFallbackThirdPartyServicesListByProject(projectId);
    } finally {
      setIsLoading(false);
    }
  };

  const onRemove = (serviceId: string) => {
    setCurrentServiceId(serviceId);
    setRemoveServiceModalVisible(true);
  };

  const onConfirmRemove = async () => {
    setIsLoading(true);
    try {
      await RemoveThirdPartyServices(currentServiceId);
      await onFallbackThirdPartyServicesListByProject(projectId);
      message.info(t('deleted_correctly'), 3);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
      setRemoveServiceModalVisible(false);
    }
  };

  const onCloseConfirmModal = () => {
    setRemoveServiceModalVisible(false);
  };

  const onOpenCreateUsefulContactModal = (): void => {
    setCreateServiceModalVisible(true);
  };
  const onCloseCreateUsefulContactModal = (): void => {
    setCreateServiceModalVisible(false);
  };

  const onCreateUsefulContact = async (values: ThirdPartyServicesCreateByProject): Promise<void> => {
    await onSave(values);
    message.success(t('created_correctly'), 3);
    setCreateServiceModalVisible(false);
  };

  return {
    data,
    usefulContactsTableActionsMenu,
    removeServiceModalTitle,
    removeServiceModalText,
    removeServiceModalMainButtonText,
    removeServiceModalSecondaryButtonText,
    loading: isLoading,
    error: isError,
    onSave,
    onRemove,
    onConfirmRemove,
    onCloseConfirmModal,
    onOpenCreateUsefulContactModal,
    onCloseCreateUsefulContactModal,
    onCreateUsefulContact,
    removeServiceModalVisible,
    createServiceModalVisible,
  };
};
