import { supportTicketsStatisticsPath } from 'core/endspoints/supportTickets';
import { PayloadSupportTicketsStatisticsModel } from '../models/supportTicketsPayloadModel';
import { payloadToSupportTicketsStatistics } from '../mappers/payloadToSupportTicketsStatistics';
import { SupportTicketsStatisticsModel } from '../models/supportTicketsModel';
import { SupportTicketsStatisticsEntity } from '../entities/supportTicketsStatisticsEntity';
import api from 'services/api';

export const GetSupportTicketsStatistics = async (): Promise<SupportTicketsStatisticsModel | undefined> => {
  const url = supportTicketsStatisticsPath();
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    const { data } = await api.get<PayloadSupportTicketsStatisticsModel>(url);
    const mappedData = payloadToSupportTicketsStatistics(data);
    const statistics = mappedData && SupportTicketsStatisticsEntity(mappedData);
    return statistics;
  } catch (error: any) {
    throw new Error(error);
  }
};
