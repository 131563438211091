import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Body2RegularText,
  Body2SemiBoldText,
  CaptionRegularText,
  Collapsible,
  DeleteIcon,
  EditIcon,
  LabelRegularText,
  Picture,
  SecondarySmallIconFlatButton,
  TableModel
} from "@alfred_developes/alfred-ui-web-components";
import { TableHeading } from "../TableHeading";
import { FirstLevelRowContent } from "../FirstLevelRowContent";
import {
  AreaType,
  FirstLevelHorizontalArea,
  ProjectLayoutConfigAreaModel,
  SecondLevelHorizontalArea,
  VerticalArea
} from "core/domain/projectLayoutConfig/model/projectLayoutConfigModels";
import { getAreaNamesByBrowserLanguage } from "components/pages/ProjectPage/utils";

interface Props {
  area: ProjectLayoutConfigAreaModel;
  secondLevelAreas: SecondLevelHorizontalArea[];
  layoutConfigData: ProjectLayoutConfigAreaModel[];
  deletingRowId: string;
  setDeletingRowId: (rowId: string) => void;
  onClickEditFirstLevelArea: (areaData: VerticalArea | FirstLevelHorizontalArea) => void;
  onClickEditSecondLevelArea: (areaData: SecondLevelHorizontalArea) => void;
  deleteArea: (areaId: string) => void;
  onClickAddSecondLevelArea: (parentId: string) => void;
}

export const FirstLevelRow: FC<Props> = ({
  area,
  secondLevelAreas,
  layoutConfigData,
  deletingRowId,
  setDeletingRowId,
  onClickEditFirstLevelArea,
  onClickEditSecondLevelArea,
  deleteArea,
  onClickAddSecondLevelArea
}) => {
  const { t } = useTranslation();

  const getNestedTableConfig = (list: SecondLevelHorizontalArea[]): TableModel => {
    const secondLevelTableRows = list.map(({ id, type, imageURL, nameEn, nameEs, verticalId, projectId }) => {
      const { mainNameText, secondaryNameText } = getAreaNamesByBrowserLanguage({ nameEn, nameEs });

      return {
        id,
        data: {
          id,
          type,
          imageURL,
          nameEs,
          nameEn,
          verticalId,
          projectId,
        },
        cells: [
          {
            key: 'column-1',
            isWrappedContent: true,
            component:
              <Picture
                height={50}
                imageURL={imageURL}
                style={{ objectFit: 'cover', borderRadius: 6, width: 70 }}
              />
          },
          {
            key: 'column-2',
            isWrappedContent: true,
            component:
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 30,
                }}
              >
                <div>
                  <LabelRegularText>{mainNameText}</LabelRegularText>
                  <CaptionRegularText>{secondaryNameText}</CaptionRegularText>
                </div>
              </div>
          },
          {
            key: 'column-3',
            isWrappedContent: true,
            component: (rowId: string) => {
              return (
                <div style={{
                  display: 'flex',
                  gap: '9px',
                }}>
                  <SecondarySmallIconFlatButton
                    Icon={EditIcon}
                    onClick={() => {
                      const selectedRow = secondLevelTableRows.find(row => row.id === rowId);
                      selectedRow && onClickEditSecondLevelArea(selectedRow.data);
                    }}
                  />
                  <SecondarySmallIconFlatButton
                    Icon={DeleteIcon}
                    onClick={() => {
                      const selectedRow = secondLevelTableRows.find(row => row.id === rowId);
                      selectedRow && deleteArea(selectedRow?.data.id);
                    }}
                  />
                </div>
              )
            }
          },
        ],
      }
    });

    return {
      isTableLayoutFixed: false,
      headerCells: [
        { key: 'column-1', width: '150px', Component: <Body2SemiBoldText>{t('_PROJECT_LAYOUT_CONFIG_TABLE_HEADER_HORIZONTALS')}</Body2SemiBoldText> },
        { key: 'column-2', width: '100%', Component: <Body2SemiBoldText>{''}</Body2SemiBoldText> },
        { key: 'column-3', width: '100%', Component: <Body2SemiBoldText>{''}</Body2SemiBoldText> },
      ],
      tableRows: secondLevelTableRows,
    };
  }

  return (
    <Collapsible
      style={{ border: 'none' }
      }
      CustomHeadingComponent={
        <TableHeading
          area={area}
          tableData={layoutConfigData}
          deletingRowId={deletingRowId}
          onClickDelete={setDeletingRowId}
          onClickEdit={onClickEditFirstLevelArea}
          onConfirm={deleteArea}
        />
      }
    >
      <>
        {area.type === AreaType.VERTICAL &&
          <FirstLevelRowContent
            area={area}
            onClickAdd={onClickAddSecondLevelArea}
            tableConfig={getNestedTableConfig(secondLevelAreas || [])}
          />
        }
        {area.type === AreaType.HORIZONTAL &&
          <Body2RegularText>{t('_PROJECT_LAYOUT_CONFIG_NO_HORIZONTALS_ALLOWED_TEXT')}</Body2RegularText>
        }
      </>
    </Collapsible >
  )
}