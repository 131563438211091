import { FC } from 'react';
import { Button, ButtonProps } from 'antd';

const PrimaryButton: FC<ButtonProps> = ({ title, ...props }) => {
  return (
    <Button type='primary' {...props}>
      {title}
    </Button>
  );
};

export default PrimaryButton;
