export interface ProjectAssetIdModel {
  id: string;
}

export interface ProjectClientModel {
  id: string;
  name: string;
}

export interface ProjectUsersVerifiersModel {
  value: string;
  id: string;
}

export interface TypeOptionsModel {
  value: string;
  id: string;
}

export interface ProjectSharedGatewayManagementLinksModel {
  openhab: string;
  sshTeleport: string;
  controlPanel: string;
}

export interface ProjectSharedGatewayModel {
  id: string;
  masterUser: string;
  qrCode: string;
  hwVersion: string;
  osVersion: string;
  assistantCloud: string;
  voiceAssistantUser: string;
  daemonVersion: string;
  helperVersion: string;
  knxVersion: string;
  sku: string;
  batchId: string;
  batchName: string;
  projectId: string;
  type: 'private' | 'shared';
  managementLinks: ProjectSharedGatewayManagementLinksModel;
  productionDate: number;
  deployedAt: string;
}

export interface ProjectIntercomRoomModel {
  id: string;
  identifier: string;
  password: string;
  comments: string;
  assetId: string;
  intercomId: string;
}

export enum ProjectType {
  HOME = 'HOME',
  RENT = 'RENT',
  OFFICE = 'OFFICE',
  PARKING_SLOT = 'PARKING_SLOT',
  HOTEL = 'HOTEL',
  VACATIONAL_RENTAL = 'VACATIONAL_RENTAL',
  BUILDING = 'BUILDING',
}

export interface UpdateProjectModel {
  id: string;
  name: string;
  usersRequireVerification: boolean;
  usersVerifiers: string[] | null;
  logo: string | null;
  backgroundImage: string | null;
  type: string;
  supportEmail: string[] | null;
  status: string;
}

export interface ProjectAssetGatewayModel {
  id: string;
  assistantCloud: string;
  gatewayUser: string;
  gatewayPassword: string;
}

export interface ProjectAssetsIncludedProjectModel {
  id: string;
  uuid: string;
  name: string;
  logo: string;
  backgroundImage: string;
  supportEmail: string;
  status: string;
  latitude: string;
  longitude: string;
  links: {
    self: string;
  };
}

export interface ProjectAssetModel {
  id: string;
  alias: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  streetType: string;
  streetName: string;
  blockNumber: string;
  staircase: string;
  apartment: string;
  createdAt: Number;
  logo: string;
  backgroundImage: string;
  gateways: ProjectAssetGatewayModel[];
  isOccupied: boolean;
  gatewayConnectionStatus: string;
}
export interface ProjectAssetsIncludedModel {
  projects: ProjectAssetsIncludedProjectModel[];
}

export interface ProjectsAssetsLinksModel {
  first: string;
  last: string;
  next: string;
  prev: string;
  self: string;
}

export interface ProjectsAssetsMetaModel {
  allResults: number;
  pageSize: number;
  results: number;
  totalPages: number;
}

export interface ProjectAssetsResponseModel {
  data: ProjectAssetModel[];
  included: ProjectAssetsIncludedModel;
  links: ProjectsAssetsLinksModel;
  meta: ProjectsAssetsMetaModel;
}

export interface ProjectIntercomDeepLinkPlatformModel {
  deepLink: string;
  storeId: string;
}

export interface ProjectIntercomDeepLinkModel {
  vendor: string;
  ios: ProjectIntercomDeepLinkPlatformModel;
  android: ProjectIntercomDeepLinkPlatformModel;
}

export interface ProjectIntercomModel {
  id: string;
  vendor: string;
  projectId: string;
  name: string;
  availableIntercomVendors: string[];
  deepLinks: ProjectIntercomDeepLinkModel;
  remote: string | null;
  local: string | null;
  model: string | null;
  comments: string | null;
  serialNumber: string | null;
  handlerId: string | null;
  rooms: ProjectIntercomRoomModel[];
}

export interface ProjectModel {
  id: string;
  name: string;
  logo: string;
  backgroundImage: string;
  isBackgroundImageDefault: boolean;
  supportEmail: string[];
  client: ProjectClientModel;
  usersRequireVerification: boolean;
  usersVerifiers: ProjectUsersVerifiersModel[];
  allowedUsersToVerifyAccounts: ProjectUsersVerifiersModel[];
  type: string;
  status: string;
  sharedGateways: ProjectSharedGatewayModel[];
  assets: ProjectAssetIdModel[];
  intercoms: ProjectIntercomModel[];
  label: string;
  availableIntercomVendors: string[];
}

export interface ProjectListResponseDataModel {
  id: string;
  name: string;
  logo: string;
  backgroundImage: string;
  isBackgroundImageDefault: boolean;
  availableIntercomVendors: string[];
  supportEmail: string[];
  client: ProjectClientModel;
  usersRequireVerification: boolean;
  usersVerifiers: ProjectUsersVerifiersModel[];
  allowedUsersToVerifyAccounts: ProjectUsersVerifiersModel[];
  type: string;
  status: string;
  latitude: string;
  longitude: string;
}

export interface ProjectsListResponseLinksModel {
  first: string;
  last: string;
  next: string;
  prev: string;
  self: string;
}

export interface ProjectsListResponseMetaModel {
  totalPages: number;
  pageSize: number;
  results: number;
  allResults: number;
}

export interface ProjectsListResponseModel {
  data: ProjectListResponseDataModel[];
  meta: ProjectsListResponseMetaModel;
  links: ProjectsListResponseLinksModel;
}
