import { ProjectAssetIdModel, ProjectModel } from '../model/projectModel';
import { PayloadProjectAssetModel, PayloadProjectModel } from '../model/projectPayloadModel';
import { payloadToIntercom } from './payloadToIntercom';
import { payloadToSharedGateways } from './payloadToSharedGateways';

export const payloadToProject = ({
  id,
  name,
  logo,
  background_image,
  is_background_image_default,
  support_email,
  client,
  users_require_verification,
  users_verifiers,
  allowed_users_to_verify_accounts,
  type,
  status,
  shared_gateways,
  assets,
  intercoms,
  label,
  available_intercom_vendors,
}: PayloadProjectModel): ProjectModel => {
  const transformedSharedGateways = shared_gateways.map((item) => payloadToSharedGateways(item));
  const transformedIntercoms = intercoms.map((item) => payloadToIntercom(item));
  const transformedAllowedUsersToVerifyAccounts = allowed_users_to_verify_accounts.map(({ id, email }) => {
    return { id: id ?? '', value: email ?? '' };
  });
  const transformedAssets = assets.map(({ id }: PayloadProjectAssetModel): ProjectAssetIdModel => {
    return { id: id ?? '' };
  });
  const transformedUsersVerifiers = users_verifiers.map(({ id, email }) => {
    return { id: id ?? '', value: email ?? '' };
  });

  return {
    id: id ?? '',
    name: name ?? '',
    logo: logo ?? '',
    backgroundImage: background_image ?? '',
    isBackgroundImageDefault: is_background_image_default ?? false,
    supportEmail: support_email ?? [],
    client: {
      id: client ? client.id : '',
      name: client ? client.name : '',
    },
    usersRequireVerification: users_require_verification,
    usersVerifiers: transformedUsersVerifiers ?? [],
    allowedUsersToVerifyAccounts: transformedAllowedUsersToVerifyAccounts ?? [],
    type: type ?? 'HOME',
    status: status ?? 'ACCEPTED',
    assets: transformedAssets,
    intercoms: transformedIntercoms,
    sharedGateways: transformedSharedGateways,
    label: label ?? '',
    availableIntercomVendors: available_intercom_vendors ?? [],
  };
};
