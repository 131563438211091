import { ROUTE_PATH_ADMIN } from '../../configRoutes';
import { ClientTab } from 'components/pages/ClientDetailPage/resources/models';

interface GetClientPath {
  clientId: string;
  tab?: ClientTab;
  subTab?: string;
}

export const ROUTE_PATH_SETTINGS = ROUTE_PATH_ADMIN + '/settings';
export const ROUTE_PATH_SETTINGS_CLIENTS = ROUTE_PATH_ADMIN + '/settings/clients';
export const ROUTE_PATH_SETTINGS_CLIENTS_NEW = ROUTE_PATH_ADMIN + '/settings/clients/new';
export const ROUTE_PATH_SETTINGS_CLIENT = ROUTE_PATH_ADMIN + '/settings/clients/:clientId';
export const ROUTE_PATH_SETTINGS_CLIENT_TAB = ROUTE_PATH_SETTINGS_CLIENT + '/:tab';
export const ROUTE_PATH_SETTINGS_CLIENT_SUB_TAB = ROUTE_PATH_SETTINGS_CLIENT_TAB + '/:subTab';

export const getClientPathById = ({ clientId, tab = ClientTab.BILLABLE_USERS, subTab }: GetClientPath) => {
  const ClientDetailTabPath = `${ROUTE_PATH_SETTINGS_CLIENTS}/${clientId}/${tab}`;
  const clientDetailSubTabPath = ClientDetailTabPath + `/${subTab}`;
  return subTab ? clientDetailSubTabPath : ClientDetailTabPath;
};

export const getClientTabListWithPaginationPath = ({
  clientId,
  tab,
  page,
  size,
}: {
  clientId: string;
  page: string;
  size: string;
  tab: string;
}) => `${ROUTE_PATH_ADMIN}/settings/clients/${clientId}/${tab}?&page=${page}&size=${size}`;
