import { FC } from 'react';
import { Svg } from '../model/svg';

const Business: FC<Svg> = ({
  width = '24',
  height = '24',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 24 24'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <path
      d='M12 7V5C12 3.9 11.1 3 10 3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V9C22 7.9 21.1 7 20 7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM19 19H12V17H14V15H12V13H14V11H12V9H19C19.55 9 20 9.45 20 10V18C20 18.55 19.55 19 19 19ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z'
    />
  </svg>
);

export default Business;
