import { useEffect, useState } from 'react';
import { message } from 'antd';
import i18n from 'i18next';
import { useEventsAndNewsCRUD } from 'hooks/useEventsAndNewsCRUD';
import { CreateEventsAndNewsModel, EventsAndNewsModel } from 'core/domain/eventsAndNews/models/eventsAndNewsEntityModels';
import { UpdateFormValues } from 'components/molecules/EventsAndNewsForm/models';
import { EventsAndNewsVisorViews } from '../enums/visorViews';
import { TRANSLATION } from '../enums/translations';
import { useMenuListItems } from './useMenuListItems';

type FallbackModel = (
  selectedProjectId: string,
  selectedEventsAndNewsId?: string
) => Promise<{
  eventsAndNewsList: EventsAndNewsModel[] | undefined;
  eventsAndNewsItem: EventsAndNewsModel | undefined;
}>;
type OnUpdateModel = (id: string, values: UpdateFormValues) => Promise<void>;
type OnCreateModel = (eventsAndNewsValues: CreateEventsAndNewsModel) => Promise<void>;
type OnRemoveModel = (eventsAndNewsId: string) => Promise<void>;
type OnSelectModel = (selectedId: string) => Promise<void>;
type OnVisibleUpdate = () => void;
type OnInvisibleUpdate = () => void;
type OnVisibleCreate = () => void;
type OnInvisibleCreate = () => void;

export const useEventsAndNews = (projectId: string) => {
  const [eventsAndNewsList, setEventsAndNewsList] = useState<EventsAndNewsModel[]>([]);
  const [selectedEventsAndNews, setSelectedEventsAndNews] = useState<EventsAndNewsModel>({} as EventsAndNewsModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [visibleType, setVisibleType] = useState<EventsAndNewsVisorViews>(EventsAndNewsVisorViews.CONTENT);
  const { menuListItems, onSetMenuListItems, disabledItemsMenu, enabledItemsMenu, disabledItemMenu } = useMenuListItems();
  const { getEventsAndNewsList, getEventsAndNews, createEventsAndNews, updateEventsAndNews, removeEventsAndNews } = useEventsAndNewsCRUD();

  const fallback: FallbackModel = async (selectedProjectId: string, selectedEventsAndNewsId?: string) => {
    const eventsAndNewsList = await getEventsAndNewsList(selectedProjectId);

    const eventsAndNewsId: string | undefined =
      selectedEventsAndNewsId || (!!eventsAndNewsList?.length ? eventsAndNewsList[0].id : undefined);
    const eventsAndNewsItem: EventsAndNewsModel | undefined = eventsAndNewsList?.find((item) => eventsAndNewsId === item.id);

    if (eventsAndNewsId && !eventsAndNewsItem) {
      const refreshArea = await getEventsAndNews(selectedProjectId, eventsAndNewsId);
      return { eventsAndNewsList, eventsAndNewsItem: refreshArea };
    }

    return { eventsAndNewsList, eventsAndNewsItem };
  };

  useEffect(() => {
    setIsLoading(true);
    fallback(projectId)
      .then(({ eventsAndNewsList, eventsAndNewsItem }) => {
        eventsAndNewsList && setEventsAndNewsList(eventsAndNewsList);
        eventsAndNewsList && onSetMenuListItems(eventsAndNewsList);
        eventsAndNewsItem && setSelectedEventsAndNews(eventsAndNewsItem);
        eventsAndNewsItem ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.EMPTY);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onUpdateItemEventsAndNews: OnUpdateModel = async (id, values) => {
    setIsSaving(true);
    try {
      await updateEventsAndNews({ id, ...values });
      const { eventsAndNewsList, eventsAndNewsItem } = await fallback(projectId, id);
      eventsAndNewsList && onSetMenuListItems(eventsAndNewsList);
      eventsAndNewsList && setEventsAndNewsList(eventsAndNewsList);
      eventsAndNewsItem && setSelectedEventsAndNews(eventsAndNewsItem);
      setVisibleType(EventsAndNewsVisorViews.CONTENT);
      const messageSuccess = i18n.t(TRANSLATION.saved);
      message.success(messageSuccess);
    } finally {
      setIsSaving(false);
    }
  };

  const onCreateEventsAndNews: OnCreateModel = async (eventsAndNewsValues) => {
    setIsSaving(true);
    try {
      await createEventsAndNews(projectId, eventsAndNewsValues);
      const { eventsAndNewsList, eventsAndNewsItem } = await fallback(projectId);
      eventsAndNewsList && onSetMenuListItems(eventsAndNewsList);
      eventsAndNewsList && setEventsAndNewsList(eventsAndNewsList);
      eventsAndNewsItem && setSelectedEventsAndNews(eventsAndNewsItem);
      eventsAndNewsItem ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.EMPTY);
      const messageSuccess = i18n.t(TRANSLATION.created);
      message.success(messageSuccess);
    } finally {
      setIsSaving(false);
    }
  };

  const onRemoveEventsAndNews: OnRemoveModel = async (id) => {
    setIsLoading(true);
    try {
      await removeEventsAndNews(id);
      const { eventsAndNewsList, eventsAndNewsItem } = await fallback(projectId);
      eventsAndNewsList && onSetMenuListItems(eventsAndNewsList);
      eventsAndNewsList && setEventsAndNewsList(eventsAndNewsList);
      eventsAndNewsItem && setSelectedEventsAndNews(eventsAndNewsItem);
      eventsAndNewsItem ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.EMPTY);
      const messageSuccess = i18n.t(TRANSLATION.removed);
      message.success(messageSuccess);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectItemEventsAndNews: OnSelectModel = async (selectedId) => {
    const eventsAndNewsValues = eventsAndNewsList.find((item) => selectedId === item.id);
    eventsAndNewsValues && setSelectedEventsAndNews(eventsAndNewsValues);
    eventsAndNewsValues ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.CONTENT);

    if (!eventsAndNewsValues) {
      setIsLoading(true);
      try {
        const eventsAndNews = await getEventsAndNews(projectId, selectedId);
        eventsAndNews && setSelectedEventsAndNews(eventsAndNews);
        eventsAndNews && setVisibleType(EventsAndNewsVisorViews.CONTENT);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onVisibleUpdate: OnVisibleUpdate = () => {
    !!selectedEventsAndNews.id && disabledItemMenu(selectedEventsAndNews.id);
    setVisibleType(EventsAndNewsVisorViews.UPDATE);
  };

  const onInvisibleUpdate: OnInvisibleUpdate = () => {
    enabledItemsMenu();
    !!selectedEventsAndNews.id ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.CONTENT);
  };

  const onVisibleCreate: OnVisibleCreate = () => {
    setSelectedEventsAndNews({} as EventsAndNewsModel);
    disabledItemsMenu();
    setVisibleType(EventsAndNewsVisorViews.CREATE);
  };

  const onInvisibleCreate: OnInvisibleCreate = () => {
    enabledItemsMenu();
    !!eventsAndNewsList.length && setSelectedEventsAndNews(eventsAndNewsList[0]);
    !!eventsAndNewsList.length ? setVisibleType(EventsAndNewsVisorViews.CONTENT) : setVisibleType(EventsAndNewsVisorViews.EMPTY);
  };

  return {
    menuListItems,
    selectedEventsAndNews,
    visibleType,
    onCreateEventsAndNews,
    onInvisibleCreate,
    onInvisibleUpdate,
    onRemoveEventsAndNews,
    onSelectItemEventsAndNews,
    onUpdateItemEventsAndNews,
    onVisibleCreate,
    onVisibleUpdate,
    isLoading,
    isSaving,
  };
};
