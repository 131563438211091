export enum ClientTab {
  BILLABLE_USERS = 'billable_users',
  CUSTOMIZATION = 'customization',
  LEGAL_DOCUMENTATION = 'legal_documentation',
  PROJECTS = 'projects',
}

export enum ClientCustomizationSubTab {
  ASSETS_AND_EMAILS = 'assets_and_emails',
  APP_AND_WEBAPP = 'app_and_webapp',
}

export interface ClientNavigation {
  activeTab: ClientTab;
  activeSubTab?: ClientCustomizationSubTab;
}
