import { ROUTE_PATH_ADMIN } from '../../configRoutes';

export const ROUTE_PATH_COMMON_AREAS_CLIENT_AND_PROJECT_SELECTOR = ROUTE_PATH_ADMIN + '/common-areas';
export const ROUTE_PATH_COMMON_AREAS_BOOKINGS_MENU =
  ROUTE_PATH_COMMON_AREAS_CLIENT_AND_PROJECT_SELECTOR + '/clients/:clientId/projects/:projectId';
export const ROUTE_PATH_COMMON_AREAS_BOOKINGS_LIST = ROUTE_PATH_COMMON_AREAS_BOOKINGS_MENU + '/bookings';
export const ROUTE_PATH_COMMON_AREAS_V2 = ROUTE_PATH_COMMON_AREAS_BOOKINGS_MENU + '/v2';
export const ROUTE_PATH_COMMON_AREAS_CREATE_COMMON_AREA = ROUTE_PATH_COMMON_AREAS_BOOKINGS_MENU + '/common-area';
export const ROUTE_PATH_COMMON_AREAS_COMMON_AREA_DETAIL = ROUTE_PATH_COMMON_AREAS_CREATE_COMMON_AREA + '/:commonAreaId';
export const ROUTE_PATH_COMMON_AREAS_UPDATE_COMMON_AREA = ROUTE_PATH_COMMON_AREAS_COMMON_AREA_DETAIL + '/update';

export const getCommonAreasBookingsMenuPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/common-areas/clients/${clientId}/projects/${projectId}`;
};

export const getCommonAreasBookingsListPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/common-areas/clients/${clientId}/projects/${projectId}/bookings`;
};

export const getCommonAreaCreatePath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/common-areas/clients/${clientId}/projects/${projectId}/common-area`;
};

export const getCommonAreasV2Path = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/common-areas/clients/${clientId}/projects/${projectId}/v2`;
};

export const getCommonAreaDetailPath = ({
  clientId,
  projectId,
  commonAreaId,
}: {
  clientId: string;
  projectId: string;
  commonAreaId: string;
}) => {
  return `${ROUTE_PATH_ADMIN}/common-areas/clients/${clientId}/projects/${projectId}/common-area/${commonAreaId}`;
};

export const getCommonAreaUpdatePath = ({
  clientId,
  projectId,
  commonAreaId,
}: {
  clientId: string;
  projectId: string;
  commonAreaId: string;
}) => {
  return `${ROUTE_PATH_ADMIN}/common-areas/clients/${clientId}/projects/${projectId}/common-area/${commonAreaId}/update`;
};
