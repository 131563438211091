import { ClientModel } from '../models/clientEntityModel';
import { PayloadClientModel } from '../models/clientPayloadModel';

export const payloadToClient = ({
  id,
  name,
  description,
  email,
  tax_id,
  client_code,
  created_at,
  updated_at,
  look_and_feel,
}: PayloadClientModel): ClientModel => {
  return {
    id: id ?? '',
    name: name ?? '',
    description: description ?? '',
    email: email ?? '',
    taxId: tax_id ?? '',
    clientCode: client_code ?? '',
    createdAt: created_at ?? 0,
    updatedAt: updated_at ?? 0,
    lookAndFeel: {
      assetsManager: {
        logo: look_and_feel.assets_manager.logo ?? '',
        backgroundColor: look_and_feel.assets_manager.header_background_color ?? '',
        isDefault: look_and_feel.assets_manager.is_default ?? false,
      },
    },
  };
};
