import { FC } from 'react';
import ConciergeContentTable from 'components/molecules/ConciergeContentTable';
import ConciergeServiceAction from 'components/organisms/ConciergeServiceAction';
import { ThirdPartyServicesByProject } from 'core/domain/thirdPartyServices/model/thirdPartyServicesByProject';
import { useThirdPartyServicesListByProject } from 'hooks/useThirdPartyServicesListByProject';
import ConfirmModal from 'components/molecules/ConfirmModal';

interface SubTabUsefulContactsProps {
  projectId: string;
}

const SubTabUsefulContacts: FC<SubTabUsefulContactsProps> = ({ projectId }) => {
  const {
    data,
    usefulContactsTableActionsMenu,
    loading,
    onCloseConfirmModal,
    onConfirmRemove,
    onOpenCreateUsefulContactModal,
    onCloseCreateUsefulContactModal,
    onCreateUsefulContact,
    removeServiceModalVisible,
    removeServiceModalTitle,
    removeServiceModalText,
    removeServiceModalMainButtonText,
    removeServiceModalSecondaryButtonText,
    createServiceModalVisible,
  } = useThirdPartyServicesListByProject(projectId);

  return (
    <>
      <ConciergeServiceAction
        onSave={onCreateUsefulContact}
        onOpen={onOpenCreateUsefulContactModal}
        onCancel={onCloseCreateUsefulContactModal}
        visibleModal={createServiceModalVisible}
        loading={loading}
      />
      <ConciergeContentTable<ThirdPartyServicesByProject>
        data={data}
        loading={loading}
        usefulContactsTableActionsMenu={usefulContactsTableActionsMenu}
      />
      <ConfirmModal
        onCancel={onCloseConfirmModal}
        onClickMainButton={onConfirmRemove}
        visible={removeServiceModalVisible}
        loading={loading}
        title={removeServiceModalTitle}
        text={removeServiceModalText}
        mainButtonText={removeServiceModalMainButtonText}
        secondaryButtonText={removeServiceModalSecondaryButtonText}
      />
    </>
  );

}

export default SubTabUsefulContacts;
