import { useState, VFC } from 'react';
import CamerasAssetTable from 'components/organisms/CamerasAssetTable';
import TabTemplate from 'components/template/TabTemplate';
import AddCameraAction, { CameraFormValues } from 'components/organisms/AddCameraAction';
import { useCameras } from 'hooks/useCameras';
import { useParams } from 'react-router-dom';
import { Camera } from 'core/domain/assets/models/camera';
import CameraDrawer from 'components/molecules/CameraDrawer';

const TabCameras: VFC = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { data, onSave, loading } = useCameras(assetId);
  const [selectedCamera, setSelectedCamera] = useState<Camera>({} as Camera);
  const [visible, setVisible] = useState(false);

  const handleOnFinish = async (values: CameraFormValues) => {
    onSave(values);
  };

  const handleOnRow = (item: Camera) => {
    setSelectedCamera(item);
    setVisible(true);
  };

  const handleOnClose = () => {
    setVisible(false);
  };

  return (
    <TabTemplate>
      <CamerasAssetTable
        button={<AddCameraAction onFinish={handleOnFinish} loading={loading} />}
        data={data}
        loading={loading}
        onRowClick={handleOnRow}
      />
      <CameraDrawer item={selectedCamera} onClose={handleOnClose} visible={visible} />
    </TabTemplate>
  );
};

export default TabCameras;
