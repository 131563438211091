import api from 'services/api';
import { payloadToEventsAndNews } from '../mappers/payloadToEventsAndNews';
import { EventsAndNewsListValueObject } from '../entities/eventsAndNewsListValueObject';
import { eventsAndNewsPath } from 'core/endspoints/eventsAndNews';
import { PayloadEventsAndNewsModel } from '../models/payloadEventsAndNews';
import { EventsAndNewsModel } from '../models/eventsAndNewsEntityModels';

type GetListModel = (projectId: string) => Promise<EventsAndNewsModel[] | undefined>

export const GetEventsAndNewsList: GetListModel = async (projectId) => {
  try {
    const path = eventsAndNewsPath(projectId);
    const { data } = await api.get<PayloadEventsAndNewsModel[]>(path);
    const eventsAndNewsParser = data.map((item) => payloadToEventsAndNews(item));
    const { list } = EventsAndNewsListValueObject(eventsAndNewsParser);
    return list;
  } catch (error) {
    console.error(error);
  }
};
