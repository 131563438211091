import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Spotlight from 'assets/svg/icon/Spotlight';
import Close from 'assets/svg/icon/Close';
import TooltipAtom from 'components/atoms/TooltipAtom';
import { COLORS } from 'constants/colors';
import { ROUTE_PATH_SUPER_SEARCH } from 'components/pages/App/routes/superSearch/config';
import Modal from './Modal';
import './styles.scss';

interface Props {
  iconColor?: string;
}

const getSuperSearchPathToQueryString = (query: string) => {
  const queryParam = new URLSearchParams();
  if (query) queryParam.set('q', query);
  return `${ROUTE_PATH_SUPER_SEARCH}?${queryParam.toString()}`;
};

const SuperSearchManager: FC<Props> = ({ iconColor = COLORS.gray }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [query, setQuery] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleOnClick = () => {
    setIsVisible(true);
  }

  const handleOnClose = () => {
    setIsVisible(false);
    setQuery('');
  }

  const handleOnChange = (event: any) => {
    setQuery(event.target.value);
  }

  const handleOnErase = () => {
    setQuery('');
  }

  const handleOnEnter = (event: any) => {
    if (event.key === 'Enter') {
      const path = getSuperSearchPathToQueryString(query.trim());
      history.push(path);
      setIsVisible(false);
      setQuery('');
    }
  }

  return (
    <>
      <TooltipAtom title={t('SPOTLIGHT.title')}>
        <div className={'SuperSearch'} onClick={handleOnClick}>
          <Spotlight style={{ fill: iconColor }} />
        </div>
      </TooltipAtom>
      <Modal
        visible={isVisible}
        onClose={handleOnClose}
        className={'SuperSearch__modal'}
      >
        <div className={'SuperSearch__icon'}>
          <Spotlight />
        </div>
        <input
          value={query}
          className={'SuperSearch__input'}
          placeholder={t('SPOTLIGHT.info')}
          onChange={handleOnChange}
          onKeyDown={handleOnEnter}
          autoFocus
        />
        {!!query.length && (
          <div
            className={'SuperSearch__icon SuperSearch__icon-close'}
            onClick={handleOnErase}
          >
            <Close />
          </div>
        )}
      </Modal>
    </>
  );
};

export default SuperSearchManager;
