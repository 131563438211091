import { FC } from 'react';
import { PrimaryMediumTextSolidButton, Table, TableModel } from '@alfred_developes/alfred-ui-web-components';
import './styles.scss';

interface Props {
  tableConfig: TableModel;
  onClickAdd: () => void;
}

export const ProjectLayoutConfigTemplate: FC<Props> = ({
  tableConfig,
  onClickAdd,
}) => {
  return (
    <>
      <Table tableConfig={tableConfig} />
      <div className={'ProjectLayoutConfigTemplate__button-wrapper'}>
        <PrimaryMediumTextSolidButton
          text={'Añadir área'}
          onClick={onClickAdd}
        />
      </div>
    </>
  )
};