import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AlfredMainTemplate from 'components/template/AlfredMainTemplate';
import CardQrTool, { Title } from './CardQrTool';
import { useQrGeneratorGateways } from 'hooks/useQrGeneratorGateways';
import { useQrGeneratorAssets } from 'hooks/useQrGeneratorAssets';
import { ROUTE_PATH_ASSETS } from '../App/routes/assets/config';
import './styles.scss';

const cards: Title[] = ['assets', 'gateways'];

const QRToolPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { download: downloadGateways, isLoading: isLoadingGateways } = useQrGeneratorGateways();
  const { download: downloadAssets, isLoading: isLoadingAssets } = useQrGeneratorAssets()

  const handleOnGoToBack = () => {
    history.push(ROUTE_PATH_ASSETS);
  }

  const handleOnChange = async (type: Title, value: string) => {
    type === 'assets' && downloadAssets(value);
    type === 'gateways' && downloadGateways(value);
  }

  return (
    <AlfredMainTemplate
      title={t('qr_generator')}
      onBack={handleOnGoToBack}
    >
      <div className={'QRToolPage__container'}>
        {cards.map((card, key) => {
          const isLoading = card === 'assets' ? isLoadingAssets : isLoadingGateways;

          return (
            <CardQrTool
              key={key}
              title={card}
              onClick={handleOnChange}
              loading={isLoading}
              disabled={isLoading}
            />
          );
        })}
      </div>
    </AlfredMainTemplate>
  );
};

export default QRToolPage;
