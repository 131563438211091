export enum ActiveIssue {
  CPU_TEMPERATURE = 'cpu_temperature',
  CPU_LOAD = 'cpu_load',
  CPU_RAM = 'cpu_ram',
  SYSTEM_TIME = 'system_time',
  UPTIME = 'uptime',
  OPENHAB_SERVICED = 'openhab_serviced',
  OPENHAB_API = 'openhab_api',
  CPU_HDD_USED = 'cpu_hdd_used',
  ZWAVE_CONTROLLER = 'zwave_controller',
  ASSISTANT_CLOUD_SERVICE = 'assistant_cloud_service',
  VPN_CONNECTION = 'vpn_connection',
  ONLINE = 'online',
  CPU_RAM_USED = 'cpu_ram_used',
  CPU_HD_USED = 'cpu_hd_used',
  OHS_API = 'ohs_api',
  OHS_SERVICE = 'ohs_service',
  CPU_HD_AVAILABLE = 'cpu_hd_available',
  CPU_RAM_AVAILABLE = 'cpu_ram_available',
  TOTAL_SWAP = 'total_swap',
  USED_SWAP = 'used_swap',
  TOTAL_INODES = 'total_inodes',
  USED_INODES = 'used_inodes',
  VPN_IP = 'vpn_ip',
}

export type ActiveIssueKey = keyof typeof ActiveIssue;
