import { BookingDuration } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';

export const DEFAULT_MAXIMUM_TIME_AVAILABILITY_PERIOD = 'month';
export const DEFAULT_MAXIMUM_TIME_AVAILABILITY_VALUE = '6';
export const DEFAULT_MAXIMUM_BOOKINGS_BY_USER = 1000;
export const DEFAULT_TIME_AVAILABILITY = [null];

export const BOOKING_DURATION_OPTIONS: { id: BookingDuration; name: string }[] = [
  { id: 30, name: '30 min' },
  { id: 60, name: '60 min' },
  { id: 90, name: '90 min' },
  { id: 2, name: '2 h' },
  { id: 3, name: '3 h' },
  { id: 4, name: '4 h' },
  { id: 5, name: '5 h' },
  { id: 6, name: '6 h' },
  { id: 7, name: '7 h' },
  { id: 8, name: '8 h' },
  { id: 9, name: '9 h' },
  { id: 10, name: '10 h' },
  { id: 11, name: '11 h' },
  { id: 12, name: '12 h' },
];
