import { useState } from 'react';
import { GetQrAssetsList } from 'core/domain/assets/repositories/getQrAssetsList'
import { useQrCsv } from './useQrCsv'
import { GetQrGatewayList } from 'core/domain/gateways/repositories/getQrGatewayList';

export const useQrGenerator = () => {
  const { processed } = useQrCsv();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const downloadAssets = async (quantity: string): Promise<void> => {
    setIsLoading(true);
    try {
      const qrList =  await GetQrAssetsList(quantity);
      return await processed(qrList, 'qr_asset_list');
    } finally {
      setIsLoading(false);
    }
  }

  const downloadGateways = async (quantity: string): Promise<void> => {
    setIsLoading(true);
    try {
      const qrList =  await GetQrGatewayList(quantity);
      return await processed(qrList, 'qr_gateway_list');
    } finally {
      setIsLoading(false);
    }
  }

  return { downloadAssets, downloadGateways, isLoading }
}