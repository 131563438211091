import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { Asset } from 'models/assets.model';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constants/date';

export const AssetsColumns = (
  onClick: (asset: Asset, tab?: 'start' | 'users' | 'devices' | 'consumptions' | 'events' | 'permissions') => void
): AlfredTableColumn<Asset>[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('address'),
      key: 'address',
      width: '60%',
      render: (text, record) => {
        return (
          <>
            <div>
              {`${record.street_type} ${record.street_name} ${record.block_number}, ${record.apartment}, ${record.city}, ${record.state}, ${record.country}`}
            </div>
            {record.created_at && <span className='Text__date'>{moment.unix(Number(record.created_at)).format(DATE_TIME_FORMAT)}</span>}
          </>
        );
      },
    },
    {
      title: t('type'),
      key: 'type',
      width: '20%',
      render: (text, record) =>
        record.type ? <div>{t(record.type)}</div> : <Typography.Text disabled>{t('without_type')}</Typography.Text>,
    },
    {
      title: t('alias'),
      key: 'alias',
      width: '20%',
      render: (text, record) =>
        record.alias ? <div>{record.alias}</div> : <Typography.Text disabled>{t('without_alias')}</Typography.Text>,
    },
  ];
};
