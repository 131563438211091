import { downloadSupportTicketsListCsvFilePath } from 'core/endspoints/supportTickets';
import { getErrorFormat } from 'core/utils/errors';
import api from 'services/api';

export const getDownloadSupportTicketsListCsvFileByClient = async ({
  timestampFrom,
  timestampUntil,
}: {
  timestampFrom: number;
  timestampUntil: number;
  }): Promise<string> => {

  try {
    const url = downloadSupportTicketsListCsvFilePath();

    const params = new URLSearchParams();
    params.append('format', 'csv');
    timestampFrom && params.append('from', String(timestampFrom));
    timestampUntil && params.append('until', String(timestampUntil));
    
    const { data } = await api.get(url, { params }) as { data: string };
    return data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};