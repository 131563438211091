import { FC } from 'react';
import './styles.scss';

interface Props {
  text: string;
}

const TagWarning: FC<Props> = ({ text }) => {
  return (
    <span className={'TagWarning'}>
      {text}
    </span>
  );
}

export default TagWarning;