import { FC } from 'react';
import CommonAreaDetailTemplate from 'components/template/CommonAreaDetailTemplate';
import { useCommonAreaDetail } from './resources/useCommonAreaDetail';
import CommonAreasAndBookingsListNewLoadingTemplate from 'components/template/CommonAreasAndBookingsNewLoadingTemplate';

const CommonAreaDetailPage: FC = () => {
  const {
    sensorList,
    commonArea,
    translations,
    onUpdateCommonArea,
    onEnableCommonArea,
    saving,
    loadingCommonArea,
  } = useCommonAreaDetail();
  const { updateButtonText } = translations;

  return (
    <>
      {loadingCommonArea && <CommonAreasAndBookingsListNewLoadingTemplate />}
      {!loadingCommonArea && !!commonArea && (
        <CommonAreaDetailTemplate
          commonArea={commonArea}
          sensorsOptionList={sensorList}
          updateButtonText={updateButtonText}
          onEnableCommonArea={onEnableCommonArea}
          onUpdateCommonArea={onUpdateCommonArea}
          loading={saving}
        />
      )}
    </>
  );
}

export default CommonAreaDetailPage;