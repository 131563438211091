import api from 'services/api';
import { assetsOccupationStatisticsPath } from 'core/endspoints/assets';
import { AssetsOccupationModel } from '../models/assetsModel';
import { PayloadAssetsOccupationModel } from '../models/assetsPayloadModel';
import { payloadToAssetsOccupationStatistics } from '../mappers/payloadToAssetsOccupationStatistics';
import { AssetsOccupationStatisticsEntity } from '../entities/assetsOccupationStatisticsEntity';

export const GetActiveUsersOccupationStatistics = async (): Promise<AssetsOccupationModel> => {
  api.defaults.headers.common['Accept-version'] = '1';
  const url = assetsOccupationStatisticsPath();

  try {
    const { data } = await api.get<PayloadAssetsOccupationModel>(url);
    const mappedData = payloadToAssetsOccupationStatistics(data);
    const assetsOccupation = mappedData && AssetsOccupationStatisticsEntity(mappedData);
    return assetsOccupation;
  } catch (error: any) {
    throw new Error(error);
  }
};
