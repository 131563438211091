import { Table, TableProps } from 'antd';

interface TableAtomProps<T> extends TableProps<T> { }

export interface TableActionsMenuProps {
  key: string;
  disabled: boolean;
  text: string;
  onClick: (id: string) => void;
}

const MAX_SIZE_RESPONSIVE_SCREEN = 600;

const TableAtom = <T extends {}>({ rowKey = 'id', ...props }: TableAtomProps<T>) => {
  return <Table<T> {...props} rowKey={rowKey} scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }} />;
};

export default TableAtom;
