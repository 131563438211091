import { LegalDocumentationModel } from '../models/clientEntityModel';
import { LegalDocumentationPayloadModel } from '../models/clientPayloadModel';

export const payloadToLegalDocumentation = ({
  id,
  client_id,
  privacy_policy,
  privacy_policy_name,
  terms_of_service,
  terms_of_service_name,
  enabled,
  is_default,
}: LegalDocumentationPayloadModel): LegalDocumentationModel => {
  return {
    id: id ?? '',
    clientId: client_id ?? '',
    privacyPolicy: privacy_policy ?? '',
    privacyPolicyName: privacy_policy_name ?? '',
    termsOfService: terms_of_service ?? '',
    termsOfServiceName: terms_of_service_name ?? '',
    enabled: enabled ?? false,
    isDefault: is_default ?? true,
  };
};
