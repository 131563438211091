export enum MaintenanceAction {
  BATTERY_REPLACEMENT = 'battery_replacement',
  REPLACEMENT = 'replacement',
  ADDED_DEVICE = 'added_device',
  REMOVED_DEVICE = 'removed_device',
  SOFTWARE_UPDATE = 'software_update',
  CLEANING = 'cleaning',
  ON_SITE_CHECK = 'on_site_check',
  REMOTE_CHECK = 'remote_check',
  CALL_ATTENDED = 'call_attended',
  MANAGEMENT_PASSWORD = 'management_password',
}

export interface ReportMaintenance {
  deviceId: string | null;
  action: MaintenanceAction;
  deviceType: string | null;
  comments: string | null;
  createdAt: number | null;
  batteryVoltage?: string | null;
  batteryVendor?: string | null;
}
