import { supportTicketsListPath } from 'core/endspoints/supportTickets';
import api from 'services/api';
import {
  SupportTicketPriorityType,
  SupportTicketStatusType,
  SupportTicketTypeType,
  SupportTicketsResponsePayloadModel,
} from '../models/supportTicketsPayloadModel';
import { payloadToSupportTicketResponse } from '../mappers/payloadToSupportTicket';
import { SupportTicketsListResponseEntity } from '../entities/supportTicketsListValueObject';
import { SupportTicketsResponseModel } from '../models/supportTicketsModel';

interface SupportTicketsListProps {
  assetId?: string;
  pageNumber?: number;
  pageSize?: number;
  priority?: SupportTicketPriorityType;
  sort?: string;
  status?: SupportTicketStatusType;
  type?: SupportTicketTypeType;
}

export const GetSupportTicketsList = async ({
  assetId,
  pageNumber,
  pageSize,
  priority,
  sort,
  status,
  type,
}: SupportTicketsListProps): Promise<Readonly<SupportTicketsResponseModel>> => {
  const url = supportTicketsListPath();
  api.defaults.headers.common['Accept-version'] = '2';

  const params = new URLSearchParams();
  assetId && params.append('asset_id', assetId);
  pageNumber && params.append('page[number]', String(pageNumber));
  pageSize && params.append('page[size]', String(pageSize));
  priority && params.append('priority', priority);
  sort && params.append('page[sort]', sort);
  status && params.append('status', status);
  type && params.append('type', type);

  try {
    const response = (await api.get(url, { params })) as SupportTicketsResponsePayloadModel;
    api.defaults.headers.common['Accept-version'] = '1';
    const mappedResponse = payloadToSupportTicketResponse(response);
    return SupportTicketsListResponseEntity(mappedResponse);
  } catch (error: any) {
    throw new Error(error);
  }
};
