import { RouteConfig } from 'react-router-config';
import TicketsPage from 'components/pages/TicketsPage';
import { ROUTE_PATH_TICKETS } from './config';

export const ticketsRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_TICKETS,
    exact: true,
    component: TicketsPage,
  },
];
