import { FC, useState } from 'react';
import { ModalProps } from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import DocumentManagement, { DocumentManagementFormValues } from 'components/organisms/DocumentManagementForm';
import ModalAtom from 'components/atoms/ModalAtom';
import TextButton from 'components/atoms/Button/TextButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import SpaceAtom from 'components/atoms/SpaceAtom';
import './styles.scss';

interface DocumentManagementModalProps extends ModalProps {
  onFinish: (values: DocumentManagementFormValues) => Promise<void>;
}

const FORM_ID = 'form-upload';

const DocumentManagementModal: FC<DocumentManagementModalProps> = ({ onCancel, onFinish, ...props }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleOnFinish = async (values: DocumentManagementFormValues) => {
    try {
      setLoading(true);
      await onFinish(values);
      message.success(t('sent_successfully'));
      if (props.onOk) props.onOk({} as any);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalAtom
      {...props}
      title={<SpaceAtom>{t('publish_document')}</SpaceAtom>}
      onCancel={onCancel}
      footer={[
        <TextButton key='back' onClick={onCancel} title={t('to_cancel')} />,
        <PrimaryButton key='submit' loading={loading} htmlType='submit' form={FORM_ID} title={t('send')} />,
      ]}
      destroyOnClose
    >
      <DocumentManagement id={FORM_ID} onFinish={handleOnFinish} />
    </ModalAtom>
  );
};

export default DocumentManagementModal;
