import { Moment } from 'moment';

export interface AssetConsumptionsFormValues {
  sensor: string;
  timeRange: [start: Moment, end: Moment];
  month: string;
}

export enum AssetConsumptionsFormValuesNames {
  SENSOR = 'sensor',
  TIME_RANGE = 'timeRange',
  MONTH = 'month',
}

export interface AssetConsumptionsFormTranslationsType {
  initialDateText: string;
  textButton: string;
  secondaryTextButton: string;
}
