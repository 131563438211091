import { ROUTE_PATH_DASHBOARD } from 'components/pages/App/routes/dashboard/config';

const USER_HISTORY = 'super_search:history';

export const saveSuperSearchHistory = (url: string) => {
  localStorage.setItem(USER_HISTORY, url);
};

export const hasSuperSearchHistory = () => {
  const history = localStorage.getItem(USER_HISTORY);
  return history === null ? ROUTE_PATH_DASHBOARD : history;
};
