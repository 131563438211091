import { sensorEditPath } from 'core/endspoints/sensors';
import api from 'services/api';
import { sensorEditToPayload } from '../mappers/sensorUpdateToPayload';
import { SensorEditEntityModel } from '../models/sensorEntityModels';

export const EditSensorById = async (sensorId: string, data: SensorEditEntityModel): Promise<void> => {
  try {
    const path = sensorEditPath(sensorId);
    api.defaults.headers.common['Accept-version'] = '1';
    const sensorUnpparser = sensorEditToPayload(data);
    return api.patch(path, { data: sensorUnpparser });
  } catch (error) {
    console.error(error);
  }
};
