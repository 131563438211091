import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { DeviceModel, GatewayDevicesResponsePayload } from '../model';
import { getErrorFormat } from 'core/utils/errors';
import { payloadToGatewayDevicesList } from '../mappers';

const getUrl = ({ host, gatewayId }: { host: string; gatewayId: string }): string => {
  return `${host}/gateways/${gatewayId}/devices`;
};

export const GetGatewaysDevices = async ({
  host,
  token,
  gatewayId,
}: {
  host: string;
  token: string;
  gatewayId: string;
}): Promise<DeviceModel[]> => {
  try {
    const url = getUrl({ host, gatewayId });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<GatewayDevicesResponsePayload>;
    return payloadToGatewayDevicesList(data.data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
