import { FC } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import QRScanner from 'components/atoms/QRScanner';
import { ModalProps } from 'antd/es/modal';

interface ModalQRScannerProps extends ModalProps {
  onError?: (error: string) => void;
  onScan: (qr: string) => void;
}

const ModalQRScanner: FC<ModalQRScannerProps> = ({ onCancel, onError, onScan, ...props }) => {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      destroyOnClose
      footer={[
        <Button key='back' onClick={(event) => onCancel && onCancel(event)}>
          {t('to_cancel')}
        </Button>,
      ]}
      onCancel={onCancel}
    >
      <QRScanner onScan={onScan} onError={onError && onError} />
    </Modal>
  );
};

export default ModalQRScanner;
