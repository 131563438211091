import { BookingModel } from '../models/bookingEntityModels';
import { PayloadBookingModel } from '../models/payloadBookingModels';
import { payloadToCommonAreaSensor } from './payloadToCommonAreaSensor';

export const payloadToBooking = ({ id, common_area, hour_booking, date_booking, sensors, user }: PayloadBookingModel): BookingModel => {
  const transformSensors = sensors.map((sensor) => payloadToCommonAreaSensor(sensor));
  return {
    id: id ?? '',
    commonArea: common_area ?? { id: '', name: '' },
    hourBooking: hour_booking ?? { start: '', end: '' },
    dateBooking: date_booking ?? 0,
    sensors: transformSensors,
    user: user ?? { name: '', surname: '', id: '' },
  };
};
