import { useTranslation } from 'react-i18next';
import { messageAtom } from 'components/atoms/MessageAtom';
import { RequestErrorModel } from 'core/utils/errors';
import { getServiceUnavailableError, getUnauthorizedError } from 'services/api/errors';

const DEFAULT_MESSAGE_TIME_DURATION = 3;

export interface GlobalErrorModel {
  id: string;
  title?: string;
  description?: string;
  type: string;
}

export interface BasicMessageModel {
  title?: string;
  description?: string;
}

export interface MessageErrorModel extends RequestErrorModel {
  title?: string;
  description?: string;
}

interface ResponseMessageModel {
  [key: number]: string;
}

export enum AlertTypes {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

export const temporaryResponsesMessages: ResponseMessageModel = {
  100: '_100_TEMPORARY',
  101: '_101_TEMPORARY',
  102: '_102_TEMPORARY',
};

export const successResponsesMessages: ResponseMessageModel = {
  200: '_200_SUCCESS',
  201: '_201_SUCCESS',
  202: '_202_SUCCESS',
  203: '_203_SUCCESS',
  204: '_204_SUCCESS',
  205: '_205_SUCCESS',
  206: '_206_SUCCESS',
  207: '_207_SUCCESS',
  208: '_208_SUCCESS',
  226: '_226_SUCCESS',
};

export const redirectionResponsesMessages: ResponseMessageModel = {
  300: '_300_REDIRECTION',
  301: '_301_REDIRECTION',
  302: '_302_REDIRECTION',
  303: '_303_REDIRECTION',
  304: '_304_REDIRECTION',
  305: '_305_REDIRECTION',
  306: '_306_REDIRECTION',
  307: '_307_REDIRECTION',
  308: '_308_REDIRECTION',
};

export const clientSideErrorResponseMessages: ResponseMessageModel = {
  400: '_400_CLIENT_SIDE_ERROR',
  401: '_401_CLIENT_SIDE_ERROR',
  402: '_402_CLIENT_SIDE_ERROR',
  403: '_403_CLIENT_SIDE_ERROR',
  404: '_404_CLIENT_SIDE_ERROR',
  405: '_405_CLIENT_SIDE_ERROR',
  406: '_406_CLIENT_SIDE_ERROR',
  407: '_407_CLIENT_SIDE_ERROR',
  408: '_408_CLIENT_SIDE_ERROR',
  409: '_409_CLIENT_SIDE_ERROR',
  410: '_410_CLIENT_SIDE_ERROR',
  411: '_411_CLIENT_SIDE_ERROR',
  412: '_412_CLIENT_SIDE_ERROR',
  413: '_413_CLIENT_SIDE_ERROR',
  414: '_414_CLIENT_SIDE_ERROR',
  415: '_415_CLIENT_SIDE_ERROR',
  416: '_416_CLIENT_SIDE_ERROR',
  417: '_417_CLIENT_SIDE_ERROR',
  418: '_418_CLIENT_SIDE_ERROR',
  421: '_421_CLIENT_SIDE_ERROR',
  422: '_422_CLIENT_SIDE_ERROR',
  423: '_423_CLIENT_SIDE_ERROR',
  424: '_424_CLIENT_SIDE_ERROR',
  426: '_426_CLIENT_SIDE_ERROR',
  428: '_428_CLIENT_SIDE_ERROR',
  429: '_429_CLIENT_SIDE_ERROR',
  431: '_431_CLIENT_SIDE_ERROR',
  451: '_451_CLIENT_SIDE_ERROR',
};

export const serverSideErrorResponseMessages: ResponseMessageModel = {
  500: '_500_SERVER_SIDE_ERROR',
  501: '_501_SERVER_SIDE_ERROR',
  502: '_502_SERVER_SIDE_ERROR',
  503: '_503_SERVER_SIDE_ERROR',
  504: '_504_SERVER_SIDE_ERROR',
  505: '_505_SERVER_SIDE_ERROR',
  506: '_506_SERVER_SIDE_ERROR',
  507: '_507_SERVER_SIDE_ERROR',
  508: '_508_SERVER_SIDE_ERROR',
  510: '_510_SERVER_SIDE_ERROR',
  511: '_511_SERVER_SIDE_ERROR',
};

export const javascriptError: ResponseMessageModel = {
  800: '_800_JAVASCRIPT_ERROR',
  801: '_801_JAVASCRIPT_ERROR',
};

export const useMessage = () => {
  const { t } = useTranslation();

  const setMessageError = (message: BasicMessageModel, duration?: number) => {
    const { description } = message;
    messageAtom.error({ content: description, duration: duration || DEFAULT_MESSAGE_TIME_DURATION });
  };

  const setMessageWarning = (message: BasicMessageModel, duration?: number) => {
    const { description } = message;
    messageAtom.warning({ content: description, duration: duration || DEFAULT_MESSAGE_TIME_DURATION });
  };

  const setMessageSuccess = (message: BasicMessageModel, duration?: number) => {
    const { description } = message;
    messageAtom.success({ content: description, duration: duration || DEFAULT_MESSAGE_TIME_DURATION });
  };

  const setMessageInfo = (message: BasicMessageModel, duration?: number) => {
    const { description } = message;
    messageAtom.info({ content: description, duration: duration || DEFAULT_MESSAGE_TIME_DURATION });
  };

  const setMessageManager = (messageData: MessageErrorModel) => {
    const { code, message, title, description } = messageData;

    try {
      code === 401 && getUnauthorizedError();
      code === 503 && getServiceUnavailableError();
    } catch (error) {
      console.warn('ERROR_ON_LOGOUT_AUTO_USER');
    }

    try {
      if (typeof code === 'string') {
        code === 'ECONNABORTED' && setMessageWarning({ title, description: t('_000_ECONNABORTED_ERROR') || message });
      }
      if (typeof code === 'number') {
        code >= 100 && code < 200 && setMessageInfo({ title, description: description || t(temporaryResponsesMessages[code]) || message });
        code >= 200 && code < 300 && setMessageSuccess({ title, description: description || t(successResponsesMessages[code]) || message });
        code >= 300 &&
          code < 400 &&
          setMessageInfo({ title, description: description || t(redirectionResponsesMessages[code]) || message });
        code >= 400 &&
          code < 500 &&
          setMessageError({ title, description: description || t(clientSideErrorResponseMessages[code]) || message });
        code >= 500 &&
          code < 600 &&
          setMessageError({ title, description: description || t(serverSideErrorResponseMessages[code]) || message });
        code >= 800 && code < 900 && setMessageError({ title, description: description || t(javascriptError[code]) || message });
      }
    } catch (e) {
      setMessageError({ title: `${code}`, description: message });
    }
  };

  return {
    setMessageManager,
    setMessageError,
    setMessageWarning,
    setMessageSuccess,
    setMessageInfo,
  };
};
