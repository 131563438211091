import { FC } from 'react';
import { Col, Row, Table } from 'antd';
import Paper from 'components/atoms/Paper';
import { IntercomModel } from 'core/domain/intercoms/model/intercomModel';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import SpaceAtom from 'components/atoms/SpaceAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { hasIntercomsCreatingPermission } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/config';
import { IntercomsListTemplateTranslations } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/translations';
import './styles.scss';

interface Props {
  intercoms: IntercomModel[];
  columns: AlfredTableColumn<IntercomModel>[];
  intercomsListTemplateTranslations: IntercomsListTemplateTranslations;
  onAddIntercom: () => void;
  loading: boolean;
}

const ProjectIntercomsTemplate: FC<Props> = ({ intercoms, columns, onAddIntercom, intercomsListTemplateTranslations, loading }) => {
  const { add } = intercomsListTemplateTranslations;

  return (
    <Paper>
      {hasIntercomsCreatingPermission && (
        <Row className={'IntercomsTable'}>
          <Col className={'IntercomsTable__button'}>
            <SpaceAtom>
              <DefaultButton onClick={onAddIntercom} title={add} />
            </SpaceAtom>
          </Col>
        </Row>
      )}
      <Table<IntercomModel>
        key={'intercoms'}
        dataSource={intercoms}
        columns={columns}
        pagination={false}
        rowKey={'id'}
        loading={loading}
        sticky
      />

    </Paper >
  );
}

export default ProjectIntercomsTemplate;