import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import { Icon } from 'components/atoms/Icons';
import { useCommonAreasV2 } from './resources/useCommonAreasV2';
import TextLinkAtom from 'components/atoms/TextLinkAtom';
import './styles.scss';

const CommonAreasV2Page: FC = () => {
  const { text, textButton, link } = useCommonAreasV2();
  return (
    <Paper className={'CommonAreasV2Page__body'}>
      <div className={'CommonAreasV2Page__body-container'}>
        <div className={'CommonAreasV2Page__body-icon'}>
          <Icon.EmptyBooking />
        </div>
        <p className={'CommonAreasV2Page__body-text'}>{text}</p>
        <TextLinkAtom className='CommonAreasV2Page__body-link' href={link} target='_blank'>
          {textButton}
        </TextLinkAtom>
      </div>
    </Paper>
  )
}

export default CommonAreasV2Page;