import { useTranslation } from 'react-i18next';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { ProjectModel, ProjectType } from 'core/domain/project/model/projectModel';


export const ProjectsColumns = (): AlfredTableColumn<ProjectModel>[] => {
  const { t } = useTranslation();

  const projectTypeManager = {
    [ProjectType.HOME]: t('_PROJECT_TYPE_HOME'),
    [ProjectType.RENT]: t('_PROJECT_TYPE_RENT'),
    [ProjectType.OFFICE]: t('_PROJECT_TYPE_OFFICE'),
    [ProjectType.PARKING_SLOT]: t('_PROJECT_TYPE_PARKING_SLOT'),
    [ProjectType.HOTEL]: t('_PROJECT_TYPE_HOTEL'),
    [ProjectType.VACATIONAL_RENTAL]: t('_PROJECT_TYPE_VACATIONAL_RENTAL'),
    [ProjectType.BUILDING]: t('_PROJECT_TYPE_VACATIONAL_BUILDING'),
  }

  return [
    {
      title: t('name'),
      key: 'name',
      width: '60%',
      render: (text, record) => (
        <span>
          <img className='ProjectsPage__columns' src={record.logo} alt={record.name} /> {record.name}
        </span>
      ),
    },
    {
      title: t('type'),
      key: 'type',
      width: '20%',
      render: (text, record) => projectTypeManager[record.type as unknown as ProjectType],
    },
    {
      title: t('support_email'),
      key: 'support_email',
      width: '20%',
      dataIndex: 'support_email',
    },
  ];
};
