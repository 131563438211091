import { FC } from 'react';

const CheckIn: FC = () => {
  return (
    <iframe
      title='Check in'
      id='CheckIn'
      data-type='text/html'
      // src='https://alfredsmart.optisigns.net/signIn'
      src='https://chekin.com/onboarding/login/'
      width='100%'
      height='850vh'
      frameBorder='0'
    ></iframe>
  );
};

export default CheckIn;
