import { useState, VFC } from 'react';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { Icon } from 'components/atoms/Icons';
import { useTranslation } from 'react-i18next';
import AddCameraModal from 'components/molecules/AddCameraModal';
import { messageAtom } from 'components/atoms/MessageAtom';
import SwitchAtom from 'components/atoms/SwitchAtom';
import InputForm from 'components/atoms/InputForm';
import FormAtom from 'components/atoms/FormAtom';

const FORM_ID = 'camera_form';

export interface CameraFormValues {
  name: string;
  vendor: string;
  model: string;
  comments: string;
  enabled?: boolean;
  localUrl: string;
  remoteUrl?: string;
  localApiPort?: string;
  remoteApiPort?: string;
  deviceSerial?: string;
}

interface AddCameraActionProps {
  onFinish: (values: CameraFormValues) => Promise<void>;
  loading: boolean;
}

const AddCameraAction: VFC<AddCameraActionProps> = ({ onFinish, loading }) => {
  const { t } = useTranslation();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const handleOnClick = () => {
    setIsEnabled(false);
    setIsShowModal(true);
  };

  const handleOnCancel = () => {
    setIsShowModal(false);
  };

  const handleOnFinish = async (values: Omit<CameraFormValues, 'enabled'>) => {
    try {
      await onFinish({ enabled: isEnabled, ...values });
      messageAtom.success(t('created_correctly'), 3);
      setIsShowModal(false);
    } catch (error) {
      messageAtom.error(t('created_error'), 3);
    }
  };

  const handleOnChange = (value: boolean) => {
    setIsEnabled(value);
  };

  return (
    <>
      <DefaultButton onClick={handleOnClick} icon={<Icon.Cloud />} title={t('add_camera')} />
      <AddCameraModal formId={FORM_ID} loading={loading} visible={isShowModal} onCancel={handleOnCancel}>
        <FormAtom onFinish={handleOnFinish} id={FORM_ID} layout={'vertical'}>
          <FormAtom.Item name={'name'} label={`${t('name')}*`} rules={[{ required: true, message: t('field_required'), type: 'string' }]}>
            <InputForm />
          </FormAtom.Item>
          <FormAtom.Item
            name={'vendor'}
            label={`${t('vendor')}*`}
            rules={[{ required: true, message: t('field_required'), type: 'string' }]}
          >
            <InputForm />
          </FormAtom.Item>
          <FormAtom.Item name={'model'} label={`${t('model')}*`} rules={[{ required: true, message: t('field_required'), type: 'string' }]}>
            <InputForm />
          </FormAtom.Item>
          <FormAtom.Item
            name={'localUrl'}
            label={`${t('localUrl')}*`}
            rules={[{ required: true, message: t('field_required'), type: 'string' }]}
          >
            <InputForm />
          </FormAtom.Item>
          <FormAtom.Item
            name={'remoteUrl'}
            label={`${t('remoteUrl')}*`}
            rules={[{ required: true, message: t('field_required'), type: 'string' }]}
          >
            <InputForm />
          </FormAtom.Item>
          <FormAtom.Item label={t('enabled')}>
            <SwitchAtom onChange={handleOnChange} checked={isEnabled} />
          </FormAtom.Item>
          <FormAtom.Item name={'localApiPort'} label={t('localApiPort')}>
            <InputForm />
          </FormAtom.Item>
          <FormAtom.Item name={'remoteApiPort'} label={t('remoteApiPort')}>
            <InputForm />
          </FormAtom.Item>
          <FormAtom.Item name={'deviceSerial'} label={t('serialNumber')}>
            <InputForm />
          </FormAtom.Item>
          <FormAtom.Item name={'comments'} label={t('comments')}>
            <InputForm.TextArea />
          </FormAtom.Item>
        </FormAtom>
      </AddCameraModal>
    </>
  );
};

export default AddCameraAction;
