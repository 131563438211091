import { User } from 'models/users.model';

export interface AssetsOccupationModel {
  assetsLinkedToUsers: number;
  assetsNotLinkedToUsers: number;
  totalAssets: number;
}

export interface AssetGatewaysQrsModel {
  gatewayId: string;
  qrCode: string;
}

export enum AssetGatewayConnectionStatusType {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  NO_LINKED_GATEWAY = 'no_linked_gateway',
  DEFAULT = 'not_reported',
}

export interface AssetModel {
  id: string;
  alias: string;
  country: string;
  state: string;
  city: string;
  type: string;
  zipCode: string;
  streetType: string;
  streetName: string;
  blockNumber: string;
  staircase: string;
  logo: string;
  flat: string;
  door: string;
  createdAt: number;
  updatedAt: number;
  location: string;
  apartment: string;
  assetQr: string;
  projects: { id: string }[];
  project?: string;
  description: string;
  gatewaysQrs: AssetGatewaysQrsModel[];
  isOccupied: boolean;
  gatewayConnectionStatus: string | null;
  links?: {
    self: string;
  };
}

export interface ProjectAssetIdModel {
  id: string;
}

export interface AssetIncludedModel {
  projects: ProjectAssetIdModel;
  users: User[];
}

export interface AssetResponseModel {
  data: AssetModel;
  included: AssetIncludedModel;
}
