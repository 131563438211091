export const camerasListPath = (assetId: string) => {
  return `/assets/${assetId}/cameras`;
};

export const camerasCreatePath = (assetId: string) => {
  return `/assets/${assetId}/cameras/create`;
};

export const assetTypeListPath = () => {
  return '/assets/types';
};

export const assetDeletePath = (assetId: string) => {
  return `/assets/${assetId}`;
};

export const assetsOccupationStatisticsPath = () => {
  return `/asset/statistics`;
};

export const assetDetailPath = (assetId: string) => {
  return `/assets/${assetId}`;
};
