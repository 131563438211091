import { FC } from 'react';
import { Table } from 'antd';
import { BookingModel } from 'core/domain/bookingsAndCommonAreas/models/bookingEntityModels';
import { BookingListColumns } from './BookingListColumns';

interface BookingListTableProps {
  bookingListData: BookingModel[];
  onDeleteBooking: (bookingId: string) => void;
}

const MAX_SIZE_RESPONSIVE_TABLE_Y = 500;

const BookingListTable: FC<BookingListTableProps> = ({ bookingListData, onDeleteBooking }) => {
  const columns = BookingListColumns({ onDeleteBooking });

  return (
    <Table<BookingModel>
      columns={columns}
      dataSource={bookingListData}
      rowKey={'id'}
      pagination={false}
      scroll={{ y: MAX_SIZE_RESPONSIVE_TABLE_Y }}
    />
  );
};

export default BookingListTable;
