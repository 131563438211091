import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CloudUploadOutlined, TeamOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { SupportTicketTypeType } from 'core/domain/supportTickets/models/supportTicketsPayloadModel';
import { Space } from 'antd';
import './styles.scss';

interface AsSupportTicketSourcesProps {
  type: SupportTicketTypeType;
  showIcon?: boolean;
}

const ICONS = {
  all_sources: <InfoCircleOutlined className='Icon__gray-extra-light' />,
  automated: <CloudUploadOutlined className='Icon__gray-extra-light' />,
  manual: <TeamOutlined className='Icon__gray-extra-light' />,
};

const AsSupportTicketSources: FC<AsSupportTicketSourcesProps> = ({ type, showIcon = true }) => {
  const { t } = useTranslation();
  const formattedType = type.toLowerCase() as SupportTicketTypeType;

  return (
    <Space className='AsSupportTicketSources'>
      {showIcon && ICONS[formattedType]}
      {t(formattedType)}
    </Space>
  );
};

export default AsSupportTicketSources;
