import { FC } from 'react';
import { ProjectModel } from 'core/domain/project/model/projectModel';
import AlfredMainTemplate from 'components/template/AlfredMainTemplate';
import SpaceAtom from 'components/atoms/SpaceAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { NotificationOutlined } from '@ant-design/icons';
import { useProjects } from 'hooks/useProjects';
import { ProjectsColumns } from './ProjectsColumns';
import AlfredTableTemplate from 'components/template/AlfredTableTemplate';
import ProjectNotificationModal from 'components/organisms/ProjectNotificationModal';
import './styles.scss';

const ProjectsPage: FC = () => {
  const columns = ProjectsColumns();
  const { projects,
    loadingProjects,
    totalPages,
    currentPage,
    pageSize,
    translations,
    onChangePage,
    onSearch,
    onSelectProject,
    onOpenNotification,
    onCloseNotification,
    hasNotificationPermission,
    notificationModalVisible,
  } = useProjects();

  return (
    <>
      <AlfredMainTemplate title={translations.projects}>
        <AlfredTableTemplate<ProjectModel>
          columns={columns}
          onRowClick={onSelectProject}
          data={projects}
          totalPages={totalPages}
          isCharging={loadingProjects}
          onPagination={onChangePage}
          onSearch={onSearch}
          pageCurrent={currentPage}
          pageSize={pageSize}
          actionSection={
            <SpaceAtom>
              {hasNotificationPermission && (
                <DefaultButton
                  onClick={onOpenNotification}
                  icon={<NotificationOutlined />}
                  title={translations.notify}
                />
              )}
            </SpaceAtom>
          }
        />
      </AlfredMainTemplate>
      <ProjectNotificationModal
        visible={notificationModalVisible}
        onCancel={onCloseNotification}
        onOk={onCloseNotification}
      />
    </>
  );
};

export default ProjectsPage;
