import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface DidScannerOptionsModel {
  modalDidTitle: string;
  maxDidLength: number;
  minDidLength: number;
  onOpenDidScanner: () => void;
  onCloseDidScanner: () => void;
  onScanDid: (value: string) => void;
  didScannerVisible: boolean;
}

const trimID = (did: string, indexQueryString: number) => {
  return did.slice(indexQueryString + 1, did.length);
};

const MAX_LENGTH_DID = 13;
const MIN_LENGTH_DID = 12;

export const useDidScanner = () => {
  const { t } = useTranslation();
  const [didScannerVisible, setDidScannerVisible] = useState<boolean>(false);
  const [did, setDid] = useState<string | null>(null);

  const modalDidTitle: string = t('_REGISTER_ASSET_FORM_DID_MODAL_TITLE');

  const onOpenDidScanner = () => {
    setDidScannerVisible(true);
  };

  const onCloseDidScanner = () => {
    setDidScannerVisible(false);
  };

  const onScanDid = (value: string) => {
    const queryStringIndex = value.indexOf('=');
    const newDid = queryStringIndex === -1 ? value : trimID(value, queryStringIndex);
    setDid(newDid);
    setDidScannerVisible(false);
  };

  const didScannerOptions: DidScannerOptionsModel = {
    modalDidTitle,
    maxDidLength: MAX_LENGTH_DID,
    minDidLength: MIN_LENGTH_DID,
    onOpenDidScanner,
    onCloseDidScanner,
    onScanDid,
    didScannerVisible,
  };

  return { didScannerOptions, did };
};
