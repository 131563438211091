import { FC } from 'react';
import CountUp from 'react-countup';
import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { TotalValuesModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import './styles.scss';

interface Props {
  openedText: string;
  acceptedText: string;
  inProgressText: string;
  lastWeekCreatedText: string;
  lastMonthCreatedText: string;
  title: string;
  subtitle: string;
  totalValues: TotalValuesModel;
}

const KpisTemplate: FC<Props> = ({
  openedText,
  acceptedText,
  inProgressText,
  lastWeekCreatedText,
  lastMonthCreatedText,
  title,
  subtitle,
  totalValues,
}) => {
  const {
    ticketsCreatedDuringLastMonth,
    ticketsCreatedDuringLastWeek,
    totalAcceptedSupportTickets,
    totalInProgressSupportTickets,
    totalOpenSupportTickets,
  } = totalValues;

  return (
    <div className={'KpisTemplate'}>
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
        expandIconPosition={'right'}
        className={'site-collapse-custom-collapse'}
      >
        <CollapsePanel header={title} extra={<span className={'ant-collapse-subtitle'}>{subtitle}</span>} key='1' className={'site-collapse-custom-panel'}>
          <div className='KpisTemplate__chart-container'>
            <div className='KpisTemplate__chart-element'>
              <p className='KpisTemplate__chart-element-title'>
                {openedText}
              </p>
              <span className='KpisTemplate__chart-element-data'>
                <CountUp start={0} end={totalOpenSupportTickets} duration={2.75} useEasing />
              </span>
            </div>
            <div className='KpisTemplate__chart-element'>
              <p className='KpisTemplate__chart-element-title'>
                {acceptedText}
              </p>
              <span className='KpisTemplate__chart-element-data'>
                <CountUp start={0} end={totalAcceptedSupportTickets} duration={2.75} useEasing />
              </span>
            </div>
            <div className='KpisTemplate__chart-element'>
              <p className='KpisTemplate__chart-element-title'>
                {inProgressText}
              </p>
              <span className='KpisTemplate__chart-element-data'>
                <CountUp start={0} end={totalInProgressSupportTickets} duration={2.75} useEasing />
              </span>
            </div>
            <div className='KpisTemplate__chart-element'>
              <p className='KpisTemplate__chart-element-title'>
                {lastWeekCreatedText}
              </p>
              <span className='KpisTemplate__chart-element-data'>
                <CountUp start={0} end={ticketsCreatedDuringLastWeek} duration={2.75} useEasing />
              </span>
            </div>
            <div className='KpisTemplate__chart-element'>
              <p className='KpisTemplate__chart-element-title'>
                {lastMonthCreatedText}
              </p>
              <span className='KpisTemplate__chart-element-data'>
                <CountUp start={0} end={ticketsCreatedDuringLastMonth} duration={2.75} useEasing />
              </span>
            </div>
          </div>
        </CollapsePanel>
      </Collapse>
    </div>
  );
}

export default KpisTemplate;