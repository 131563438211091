import { clientsListPath } from 'core/endspoints/clients';
import api from 'services/api';
import ClientsListValueObject from '../entities/clientsListValueObject';
import { ClientModel } from '../models/clientEntityModel';
import { PayloadClientModel } from '../models/clientPayloadModel';
import { payloadToClient } from '../mappers/payloadToClient';

export const GetClientsList = async (search?: string): Promise<ClientModel[] | undefined> => {
  const url = clientsListPath();
  api.defaults.headers.common['Accept-version'] = '1';
  const params = new URLSearchParams();
  search && params.append('search', `${search}`);
  try {
    const { data } = await api.get<PayloadClientModel[]>(url, { params });
    const mappedClientsList = data.map((item: PayloadClientModel) => payloadToClient(item));
    const { list } = ClientsListValueObject(mappedClientsList);
    return list;
  } catch (error: any) {
    throw new Error(error);
  }
};
