import { FC } from 'react';
import ModalAtom from 'components/atoms/ModalAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { Checkbox } from 'antd';

interface Props {
  title: string;
  description: string;
  cancelButtonText: string;
  acceptButtonText: string;
  actionText: string;
  onCancel: () => void;
  onAccept: () => void;
  onAction: (isChecked: boolean) => void;
  isVisible: boolean;
  isLoading: boolean;
  isCheckedAction: boolean;
}

const CheckoutRoomModal: FC<Props> = ({
  title,
  description,
  cancelButtonText,
  acceptButtonText,
  actionText,
  onCancel,
  onAccept,
  onAction,
  isVisible,
  isLoading,
  isCheckedAction,
}) => {
  return (
    <ModalAtom
      className={'CheckoutRoomModal'}
      title={title}
      onCancel={onCancel}
      open={isVisible}
      destroyOnClose
      footer={[
        <DefaultButton
          disabled={isLoading}
          onClick={onCancel}
          title={cancelButtonText}
        />,
        <PrimaryButton
          loading={isLoading}
          onClick={onAccept}
          title={acceptButtonText}
        />,
      ]}
    >
      <p>
        {description}
      </p>
      <Checkbox
        disabled={isLoading}
        checked={isCheckedAction}
        onChange={() => onAction(!isCheckedAction)}>
        {actionText}
      </Checkbox>
    </ModalAtom>
  );
};

export default CheckoutRoomModal;
