import { FC, ReactElement } from 'react';
import { Dropdown, DropDownProps } from 'antd';
import { Icon } from 'components/atoms/Icons';
import './styles.scss';

interface ContextualMenuProps {
  menu: ReactElement;
  placement?: DropDownProps['placement'];
}

const ContextualMenu: FC<ContextualMenuProps> = ({ menu, placement }) => {
  return (
    <Dropdown placement={placement} overlay={menu}>
      <Icon.Elipsis className={'ContextualMenu__icon'} />
    </Dropdown>
  );
};

export default ContextualMenu;
