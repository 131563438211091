import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';
import './styles.scss';


export const RoomListColumns = (
  onGoTo: (id: string) => void,
  onClickCheckIn: (alias: string, roomId: string) => void
): AlfredTableColumn<ProjectAssetModel>[] => {
  const { t } = useTranslation();
  return [
    {
      title: t('_ACCESSES_HOTEL_LIST_ROOM_LIST_ROOM_TEXT'),
      key: 'alias',
      width: '80%',
      render: (text, { alias, id }) =>
        <Typography.Text
          className={'RoomListColumns__link'}
          onClick={(event) => {
            event.stopPropagation();
            onGoTo(id)
          }}
        >
          {alias}
        </Typography.Text>,
    },
    {
      title: t('_ACCESSES_HOTEL_LIST_ROOM_LIST_ACTIONS_TEXT'),
      key: 'actions',
      width: '20%',
      render: (text, { alias, id }) =>
        <Typography.Text
          className={'RoomListColumns__link'}
          onClick={(event) => {
            event.stopPropagation();
            onClickCheckIn(alias, id)
          }}
        >
          {'Check-in'}
        </Typography.Text>,
    },
  ];
};
