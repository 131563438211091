import { FC } from 'react';
import CountUp from 'react-countup';
import { SupportTicketsTranslationsTypes } from 'components/pages/DashboardPage/resources/translations';
import { SupportTicketsStatisticsModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import './styles.scss';

interface Props {
  tickets: SupportTicketsStatisticsModel;
  onClick?: () => void;
  openTickets: number;
  translations: SupportTicketsTranslationsTypes;
}

const DashboardSupportTicketsTemplate: FC<Props> = ({ tickets, translations, onClick, openTickets }) => {
  const {
    ticketsClosed,
    ticketsClosedTimeAverage,
    ticketsClosedTimeAverageUnits,
    firstResponseTimeAverage,
    firstResponseTimeAverageUnits,
    ticketsReceived,
    ticketsRevoked,
    ticketsInProgress,
  } = tickets;

  return (
    <div className='SupportTicketsTemplate__container'>
      <div className='SupportTicketsTemplate__header'>
        <div>
          <p className='SupportTicketsTemplate__title'>{
            translations.TITLE}
          </p>
          <p className='SupportTicketsTemplate__subtitle'>
            {translations.TITLE_TIME_PERIOD}
          </p>
        </div>
        <div onClick={onClick || undefined}>
          <p className='SupportTicketsTemplate__average' style={onClick && { cursor: 'pointer', textDecoration: 'underline' }}>
            {`${translations.OPEN_TICKETS}: ${openTickets}`}
          </p>
        </div>
      </div>
      <div className='SupportTicketsTemplate__chart-container'>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {translations.TICKETS_CLOSED_TIME_AVERAGE}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            {`${ticketsClosedTimeAverage}${ticketsClosedTimeAverageUnits}`}
          </span>
        </div>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {translations.FIRST_RESPONSE_TIME_AVERAGE}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            {`${firstResponseTimeAverage}${firstResponseTimeAverageUnits}`}
          </span>
        </div>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {translations.TICKETS_RECEIVED}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsReceived} duration={2.75} useEasing />
          </span>
        </div>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {translations.TICKETS_CLOSED}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsClosed} duration={2.75} useEasing />
          </span>
        </div>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {translations.TICKETS_IN_PROGRESS}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsInProgress} duration={2.75} useEasing />
          </span>
        </div>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {translations.TICKETS_REVOKED}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsRevoked} duration={2.75} useEasing />
          </span>
        </div>
      </div>
    </div>
  );
}

export default DashboardSupportTicketsTemplate;