import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormAtom from 'components/atoms/FormAtom';
import InputForm from 'components/atoms/InputForm';
import SelectAtom from 'components/atoms/SelectAtom';
import { ThirdPartyServices } from 'core/domain/thirdPartyServices/model/thirdPartyServices';
import { Rule } from 'antd/lib/form';

enum Protocol {
  http = 'http://',
  https = 'https://',
  tel = 'tel://',
}

interface ThirdPartyServicesForm extends Omit<ThirdPartyServices, 'id'> { }

interface ConciergeServiceFormProps {
  formId: string;
  onSave: (values: ThirdPartyServicesForm) => Promise<void>
}

const VALID_TELEPHONE_VALUES = /^\+?[0-9#*\s]+$/;

const ConciergeServiceForm: FC<ConciergeServiceFormProps> = ({
  formId, onSave
}) => {
  const { t } = useTranslation();
  const [valueOption, setValueOption] = useState<string>(Protocol.http);

  const handleOnChange = (value: string): void => {
    setValueOption(value);
  };

  const handleOnSave = ({
    title, link, description
  }: ThirdPartyServicesForm): void => {
    onSave({
      title,
      link: `${valueOption}${link}`,
      description,
    });
  };

  const getValidationRules = (): Rule[] => {
    const phoneRules: Rule[] = [{ required: true, message: t('field_required') }, { pattern: VALID_TELEPHONE_VALUES, message: t('_ERROR_TELEPHONE_VALUES') }];
    const defaultRules: Rule[] = [{ required: true, message: t('field_required') }];
    return valueOption === Protocol.tel ? phoneRules : defaultRules;
  }

  const selectBefore: JSX.Element = (
    <SelectAtom defaultValue={valueOption} onChange={handleOnChange}>
      <SelectAtom.Option value={Protocol.http}>{Protocol.http}</SelectAtom.Option>
      <SelectAtom.Option value={Protocol.https}>{Protocol.https}</SelectAtom.Option>
      <SelectAtom.Option value={Protocol.tel}>{Protocol.tel}</SelectAtom.Option>
    </SelectAtom>
  );

  return (
    <FormAtom
      id={formId}
      onFinish={handleOnSave}
      layout={'vertical'}
    >
      <FormAtom.Item
        name={'title'}
        label={`${t('title')}:`}
        rules={[{ required: true, message: t('field_required') }]}
      >
        <InputForm name={'title'} />
      </FormAtom.Item>
      <FormAtom.Item
        name={'link'}
        label={`${t('link')}:`}
        rules={getValidationRules()}
      >
        <InputForm name={'link'} addonBefore={selectBefore} />
      </FormAtom.Item>
      <FormAtom.Item
        name={'description'}
        label={`${t('description')}:`}
        rules={[{ required: true, message: t('field_required') }]}
        hasFeedback
      >
        <InputForm.TextArea
          value={'description'}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </FormAtom.Item>
    </FormAtom>
  );
};

export default ConciergeServiceForm;
