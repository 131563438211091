import { RouteConfig } from 'react-router-config';
import AssetsPage from 'components/pages/AssetsPage';
import AssetPage from 'components/pages/AssetPage';
import QrToolPage from 'components/pages/QrToolPage';
import { ROUTE_PATH_ASSET, ROUTE_PATH_ASSETS, ROUTE_PATH_ASSET_QR_TOOL } from './config';

export const assetsRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_ASSETS,
    exact: true,
    component: AssetsPage,
  },
  {
    path: ROUTE_PATH_ASSET,
    exact: true,
    component: AssetPage,
  },
  {
    path: ROUTE_PATH_ASSET_QR_TOOL,
    exact: true,
    component: QrToolPage,
  },
];
