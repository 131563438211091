import api from 'services/api';
import ProjectEntity from '../entities/projectEntity';
import { payloadToProject } from '../mappers/payloadToProject';
import { ProjectModel, ProjectsListResponseModel, UpdateProjectModel } from '../model/projectModel';
import { getProjectByIdPath, getProjectsListByClientId, updateProjectPath } from 'core/endspoints/projects';
import { PayloadProjectModel, ProjectsListResponsePayloadModel } from '../model/projectPayloadModel';
import { updateProjectToPayload } from '../mappers/updateProjectToPayload';
import { payloadToProjectsListResponse } from '../mappers/payloadToProjectsListResponse';

export const GetProjectById = async (projectId: string): Promise<ProjectModel | undefined> => {
  const url = getProjectByIdPath(projectId);
  api.defaults.headers.common['Accept-version'] = '1';
  const params = new URLSearchParams();
  params.append('include', 'assets');

  try {
    const { data } = await api.get<PayloadProjectModel>(url, { params });
    const mappedProject = payloadToProject(data);
    const project = mappedProject && ProjectEntity(mappedProject);
    return project;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const UpdateProject = async ({ id, ...project }: UpdateProjectModel): Promise<void> => {
  const url = updateProjectPath(id);
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    const data = updateProjectToPayload({ id, ...project });
    await api.patch(url, { data });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const GetProjectsListByClientId = async (clientId: string): Promise<ProjectsListResponseModel> => {
  const url = getProjectsListByClientId(clientId);
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    const response: ProjectsListResponsePayloadModel = await api.get(url);
    return payloadToProjectsListResponse(response);
  } catch (error: any) {
    throw new Error(error);
  }
};
