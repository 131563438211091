import { FC, useMemo } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';

interface DebounceSelectProps extends SelectProps<any> {
  onSearch: (search: string) => any;
}

const MINIMUM_LENGTH_TO_SEARCH = 3;
const TIME_WAIT_TO_SET_URL = 500;

const DebounceSelect: FC<DebounceSelectProps> = ({ onSearch, ...props }) => {
  const debounceOnSearch = useMemo(() => {
    const loadOptions = (query: string) => {
      if (query.length >= MINIMUM_LENGTH_TO_SEARCH) {
        onSearch(query);
      }
    };

    return debounce(loadOptions, TIME_WAIT_TO_SET_URL);
  }, [onSearch]);

  return (
    <Select
      allowClear
      showSearch
      showArrow={false}
      onSearch={debounceOnSearch}
      defaultActiveFirstOption={false}
      filterOption={false}
      notFoundContent={null}
      {...props}
    />
  );
};

export default DebounceSelect;
