import { FC, useEffect, useState } from 'react';
import { Form, FormProps, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { getProjects } from 'services/api/projects';
import DebounceSelect from 'components/atoms/DebounceSelect';

interface Options {
  value: string;
  label: string;
}

export interface ProjectNotifyValues {
  projectId: string;
  content: string;
}

const ProjectNotifyForm: FC<FormProps> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [optionsProjects, setOptionsProjects] = useState<Options[]>([]);

  useEffect(() => {
    form.resetFields();
    setLoading(true);
    getProjects({})
      .then(({ data }) => {
        const projects = data.map((project) => ({
          label: project.name,
          value: project.id,
        }));
        setOptionsProjects(projects);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form]);

  const handleOnClientSearch = async (query: string) => {
    try {
      setLoading(true);
      const { data } = await getProjects({ search: query });
      const projects = data.map((project) => ({
        label: project.name,
        value: project.id,
      }));
      setOptionsProjects(projects);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form<ProjectNotifyValues> {...props} form={form} layout='vertical'>
      <Form.Item name='projectId' label={`${t('select_community')}:`} rules={[{ required: true, message: t('field_required') }]}>
        <DebounceSelect
          loading={loading}
          options={optionsProjects}
          onSearch={handleOnClientSearch}
          placeholder={t('DEBOUNCE_SELECT.placeholder_project')}
        />
      </Form.Item>
      <Form.Item name='content' label={`${t('message')}:`} rules={[{ required: true, message: t('field_required') }]}>
        <Input.TextArea placeholder={t('write_notification')} />
      </Form.Item>
    </Form>
  );
};

export default ProjectNotifyForm;
