import { FC } from 'react';
import { Row, Col, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import LinkSearchSelect from 'components/organisms/LinkSearchSelect';
import { useTranslation } from 'react-i18next';
import { User } from 'models/users.model';
import { UsersTabColumns } from './UsersTabColumns';
import Paper from 'components/atoms/Paper';
import ConfirmModal from 'components/molecules/ConfirmModal';
import { AddUserToSearchingTableProps, AddUserToSearchingTableRespondeProps, useAssetUsersList } from './resources/useAssetUsersList';
import './styles.scss';

const MAX_SIZE_RESPONSIVE_SCREEN = 800;

const TabUsers: FC = () => {
  const { t } = useTranslation();
  const {
    users,
    usersTableActionsMenu,
    unlinkModalTitle,
    unlinkModalText,
    unlinkModalMainButtonText,
    unlinkModalSecondaryButtonText,
    onUpdateUserList,
    onConfirmRevokeUser,
    onSelectUserFromTable,
    onCloseConfirmModal,
    onSearchUser,
    onSelectUserToBeLinkedToAsset,
    loading,
    unlinkUserModalVisible,
  } = useAssetUsersList();

  const userColumns = UsersTabColumns({ usersTableActionsMenu });

  const setItemAutocomplete = ({ id, username, email }: AddUserToSearchingTableProps): AddUserToSearchingTableRespondeProps => {
    return {
      value: id,
      label: (
        <Row justify={'space-between'}>
          <Col>{`${username} (${email})`}</Col>
          <Col>
            <PlusOutlined />
          </Col>
        </Row>
      ),
    };
  };

  return (
    <>
      <Paper>
        <h2>{t('users')}</h2>
        <Row justify='end' align='top' className='TabUsers__headers'>
          <Col>
            <LinkSearchSelect
              dropdownMatchSelectWidth={500}
              key={users.length}
              onOk={onUpdateUserList}
              onSearch={(query) => onSearchUser(query, setItemAutocomplete)}
              onSelect={onSelectUserToBeLinkedToAsset}
              placeholder={t('grant_new_user')}
            />
          </Col>
        </Row>
        <Table<User>
          key='SupportTicketsSummary'
          loading={loading}
          dataSource={users}
          columns={userColumns}
          pagination={false}
          scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
          rowKey='id'
          onRow={(item) => {
            return {
              onClick: () => onSelectUserFromTable(item),
            };
          }}
          sticky
        />
      </Paper>
      <ConfirmModal
        onCancel={onCloseConfirmModal}
        onClickMainButton={onConfirmRevokeUser}
        visible={unlinkUserModalVisible}
        loading={loading}
        title={unlinkModalTitle}
        text={unlinkModalText}
        mainButtonText={unlinkModalMainButtonText}
        secondaryButtonText={unlinkModalSecondaryButtonText}
      />
    </>
  );
};

export default TabUsers;
