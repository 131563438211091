import { PayloadClientUpdateModel } from '../models/updateClientPayloadModel';
import { ClientUpdateModel } from '../models/updateClientEntityModel';

export const updateClientToPayload = ({
  id,
  name,
  email,
  description,
  taxId,
  clientCode,
  lookAndFeel,
}: ClientUpdateModel): PayloadClientUpdateModel => {
  return {
    id,
    name,
    email: email || null,
    description: description || null,
    tax_id: taxId || null,
    client_code: clientCode || null,
    look_and_feel: {
      assets_manager: {
        logo: lookAndFeel.assetsManager.logo || null,
        header_background_color: lookAndFeel.assetsManager.backgroundColor,
      },
    },
  };
};
