import { FC } from 'react';
import { Button, DatePicker, Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from 'constants/date';
import ModalAtom from 'components/atoms/ModalAtom';
import 'moment/min/locales';
import './styles.scss';

export enum DownloadLogFormValues {
  FROM_DATE = 'fromDate',
  UNTIL_DATE = 'untilDate',
}

export interface DownloadLogFormValuesModel {
  [DownloadLogFormValues.FROM_DATE]: Moment;
  [DownloadLogFormValues.UNTIL_DATE]: Moment;
}

export interface DownloadLogModalLiteralsModel {
  titleText: string,
  descriptionText: string,
  cancelButtonText: string,
  confirmButtonText: string,
  fromDateLabelText: string,
  untilDateLabelText: string,
  datePickerPlaceholderText: string,
  requireFieldText: string,
}

interface Props extends DownloadLogModalLiteralsModel {
  form: FormInstance;
  onCancel: () => void;
  onFinish: (data: DownloadLogFormValuesModel) => void;
  getIsDisabledFromDate: (current: moment.Moment) => boolean;
  getIsDisabledUntilDate: (current: moment.Moment) => boolean;
  isLoading: boolean;
  isVisible: boolean;
}

const FORM_ID = 'downloadLogForm';

const AccessDownloadLogModal: FC<Props> = ({
  cancelButtonText,
  confirmButtonText,
  datePickerPlaceholderText,
  descriptionText,
  fromDateLabelText,
  untilDateLabelText,
  requireFieldText,
  titleText,
  form,
  onCancel,
  onFinish,
  getIsDisabledFromDate,
  getIsDisabledUntilDate,
  isLoading,
  isVisible,
}) => {
  return (
    <ModalAtom
      className={'AccessDownloadLogModal'}
      title={titleText}
      open={isVisible}
      destroyOnClose
      onCancel={onCancel}
      footer={[
        <Button
          key={'back'}
          onClick={onCancel}
        >
          {cancelButtonText}
        </Button>,
        <Button
          type={'primary'}
          key={'submit'}
          loading={isLoading}
          htmlType={'submit'}
          form={FORM_ID}
        >
          {confirmButtonText}
        </Button>,
      ]}
    >
      <p>{descriptionText}</p>
      <Form
        id={FORM_ID}
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
      >
        <div className='AccessDownloadLogModal__form-wrapper-row'>
          <div className={'AccessDownloadLogModal__form-wrapper-column'}>
            <Form.Item
              name={DownloadLogFormValues.FROM_DATE}
              label={fromDateLabelText}
              rules={[{ required: true, message: requireFieldText }]}
            >
              <DatePicker
                disabledDate={getIsDisabledFromDate}
                format={DATE_FORMAT}
                placeholder={datePickerPlaceholderText}
              />
            </Form.Item>
          </div>
          <div className={'AccessDownloadLogModal__form-wrapper-column'}>
            <Form.Item
              name={DownloadLogFormValues.UNTIL_DATE}
              label={untilDateLabelText}
              rules={[{ required: true, message: requireFieldText }]}
            >
              <DatePicker
                disabledDate={getIsDisabledUntilDate}
                format={DATE_FORMAT}
                placeholder={datePickerPlaceholderText}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </ModalAtom>
  );
};

export default AccessDownloadLogModal;
