import api from 'services/api';
import { PayloadUsersListStatisticsModel } from '../models/usersPayloadModel';
import UsersListStatisticsEntity from '../entities/usersListStatisticsEntity';
import { UsersListUnstructuredStatisticsModel } from '../models/usersModel';
import { payloadToUsersListUnstructuredStatistics } from '../mappers/payloadToUsersListUnstructuredStatistics';
import { usersStatisticsPath } from 'core/endspoints/statistics';

export const GetUsersListUnstructuredStatistics = async (): Promise<UsersListUnstructuredStatisticsModel | undefined> => {
  api.defaults.headers.common['Accept-version'] = '1';
  const url = usersStatisticsPath();

  try {
    const { data } = await api.get<PayloadUsersListStatisticsModel>(url);
    const mappedData = payloadToUsersListUnstructuredStatistics(data);
    const usersList = mappedData && UsersListStatisticsEntity(mappedData);
    return usersList;
  } catch (error: any) {
    throw new Error(error);
  }
};
