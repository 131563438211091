import { RouteConfig } from 'react-router-config';
import ProvisionedPage from 'components/pages/ProvisionedPage';
import { ROUTE_PATH_PROVISIONED, ROUTE_PATH_PROVISIONED_NEW } from './config';

export const provisionedRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_PROVISIONED,
    exact: true,
    component: ProvisionedPage,
  },
  {
    path: ROUTE_PATH_PROVISIONED_NEW,
    exact: true,
    component: ProvisionedPage,
  },
];
