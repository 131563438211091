import {
  AreaType, 
  FirstLevelHorizontalAreaCreateModel, 
  ProjectLayoutConfigAreaCreateModel, 
  SecondLevelHorizontalAreaCreateModel, 
  VerticalAreaCreateModel
} from "../model/projectLayoutConfigModels";
import {
  PayloadCreateFirstLevelHorizontalArea, 
  PayloadCreateProjectLayoutConfigArea, 
  PayloadCreateSecondLevelHorizontalArea
} from "../model/projectLayoutConfigPayloadModels";

const firstLevelHorizontalAreaToCreatePayload = ({
  type,
  imageBASE64,
  nameEn,
  nameEs,
}: FirstLevelHorizontalAreaCreateModel): PayloadCreateFirstLevelHorizontalArea => { 
  return {
    type,
    image_base_64: imageBASE64,
    name_en: nameEn,
    name_es: nameEs,
  }
}

const secondLevelHorizontalToCreatePayload = ({
  nameEn,
  nameEs,
  imageBASE64,
}: SecondLevelHorizontalAreaCreateModel): PayloadCreateSecondLevelHorizontalArea => {
  return {
    name_en: nameEn,
    name_es: nameEs,
    image_base_64: imageBASE64,
  }
}

const verticalAreaToCreatePayload = ({
  type,
  nameEn,
  nameEs,
  imageBASE64,
  horizontal,
}: VerticalAreaCreateModel) => {
  const parsedHorizontal = horizontal?.map((item) => secondLevelHorizontalToCreatePayload(item));
  
  return {
    type,
    name_en: nameEn,
    name_es: nameEs,
    image_base_64: imageBASE64,
    horizontal: parsedHorizontal,
  };
}

export const layoutConfigToCreatePayload = (layoutConfig: ProjectLayoutConfigAreaCreateModel[]): PayloadCreateProjectLayoutConfigArea[] => {
  return layoutConfig.map((item) => { 
    return item.type === AreaType.HORIZONTAL
      ? firstLevelHorizontalAreaToCreatePayload(item)
      : verticalAreaToCreatePayload(item);
  });
};