import { useEffect, useState } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeviceSensor, DeviceWithGatewayId } from 'models/devices.model';
import { enableAccess, getAccessPublicDevices } from 'services/api/assets';
import { getSensorLabel } from '../resources/utils';

export const useAccessPermissions = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState<DeviceWithGatewayId[]>([]);

  const accessPermissionsTitle = t('permissions');

  const devicesWithPermissions = devices.filter((device) => device.sensors.length);

  useEffect(() => {
    setLoading(true);
    getAccessPublicDevices(assetId)
      .then((devices) => setDevices(devices))
      .finally(() => setLoading(false));
  }, [assetId]);

  const delayAccessPublicDevices = () => {
    window.setTimeout(async () => {
      try {
        const devices = await getAccessPublicDevices(assetId);
        setDevices(devices);
      } finally {
        setLoading(false);
      }
    }, 2000);
  };

  const onChangeAccessPermission = async (checked: boolean, deviceId: number, gatewayId: string) => {
    try {
      setLoading(true);
      await enableAccess(assetId, { enabled: checked, device_id: deviceId, gateway_id: gatewayId });
    } finally {
      delayAccessPublicDevices();
    }
  };

  const getSensorsNames = (sensors: DeviceSensor[]): string => {
    const names: string[] = [];
    sensors.forEach((sensor) => {
      const label = getSensorLabel({ name: sensor.name, room: sensor.room });
      names.push(label);
    });
    return names.join(', ');
  };

  return {
    devicesWithPermissions,
    width,
    accessPermissionsTitle,
    getSensorsNames,
    onChangeAccessPermission,
    loadingAccessWithPermissions: loading,
  };
};
