import { FC } from 'react';
import SkeletonAtom from 'components/atoms/SkeletonAtom';
import './styles.scss';

const CommonAreasAndBookingsListNewLoadingTemplate: FC = () => {
  return (
    <div className={'CommonAreasBookingsListLoadingTemplate__body'}>
      <SkeletonAtom.Input className={'CommonAreasBookingsListLoadingTemplate__body-skeleton-header'} active />
      <SkeletonAtom.Input className={'CommonAreasBookingsListLoadingTemplate__body-skeleton'} active />
      <SkeletonAtom.Input className={'CommonAreasBookingsListLoadingTemplate__body-skeleton'} active />
      <SkeletonAtom.Input className={'CommonAreasBookingsListLoadingTemplate__body-skeleton-header'} active />
      <SkeletonAtom.Input className={'CommonAreasBookingsListLoadingTemplate__body-skeleton'} active />
      <SkeletonAtom.Input className={'CommonAreasBookingsListLoadingTemplate__body-skeleton'} active />
      <SkeletonAtom.Input className={'CommonAreasBookingsListLoadingTemplate__body-skeleton-header'} active />
      <SkeletonAtom.Input className={'CommonAreasBookingsListLoadingTemplate__body-skeleton'} active />
      <SkeletonAtom.Input className={'CommonAreasBookingsListLoadingTemplate__body-skeleton'} active />
    </div>
  )
}

export default CommonAreasAndBookingsListNewLoadingTemplate;