import { CreateInvitationResponseModel, InvitationListResponseModel } from '../models';

export const CreateInvitationResponseEntity = ({
  alias,
  createdAt,
  status,
  type,
  uid,
  url,
  validFrom,
  validUntil,
}: CreateInvitationResponseModel): Readonly<CreateInvitationResponseModel> => {
  return Object.freeze({
    alias,
    createdAt,
    status,
    type,
    uid,
    url,
    validFrom,
    validUntil,
  });
};

export const InvitationListResponseEntity = ({ data, meta, links }: InvitationListResponseModel): Readonly<InvitationListResponseModel> => {
  return Object.freeze({ data, meta, links });
};
