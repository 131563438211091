import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SupportTicketsStatisticsModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import { SupportTicketsStatisticsUnit, supportTicketsUnitsTranslationsManager } from './translations';
import { GetAssetSupportTicketsStatistics } from 'core/domain/supportTickets/repositories/getAssetSupportTicketsStatistics';

const initialStatistics: SupportTicketsStatisticsModel = {
  firstResponseTimeAverage: '00:00',
  firstResponseTimeAverageUnits: '',
  ticketsClosed: 0,
  ticketsRevoked: 0,
  ticketsInProgress: 0,
  ticketsClosedTimeAverage: '00:00',
  ticketsClosedTimeAverageUnits: '',
  ticketsReceived: 0,
};

export const useAssetSupportTicketsStatistics = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<SupportTicketsStatisticsModel>(initialStatistics);

  const assetSupportTicketsStatisticsTranslations = {
    TITLE: t('issues'),
    TITLE_TIME_PERIOD: t('_SUPPORT_TICKETS_MONTH_PERIOD_IN_DAYS'),
    OPEN_TICKETS: t('_SUPPORT_TICKETS_OPENED'),
    TICKETS_CLOSED: t('_SUPPORT_TICKETS_CLOSED'),
    TICKETS_CLOSED_TIME_AVERAGE: t('_SUPPORT_TICKETS_CLOSED_TIME_AVERAGE'),
    FIRST_RESPONSE_TIME_AVERAGE: t('_SUPPORT_TICKETS_FIRST_RESPONSE_TIME_AVERAGE'),
    TICKETS_RECEIVED: t('_SUPPORT_TICKETS_RECEIVED'),
    TICKETS_REVOKED: t('_SUPPORT_TICKETS_REVOKED'),
    TICKETS_IN_PROGRESS: t('_SUPPORT_TICKETS_IN_PROGRESS'),
  };

  const formatUnits = (units?: string): string => {
    const value = !!units ? (units as SupportTicketsStatisticsUnit) : SupportTicketsStatisticsUnit.DEFAULT;
    return supportTicketsUnitsTranslationsManager[value]().toLowerCase();
  };

  const formatSupportTicketsStatisticsUnits = (tickets?: SupportTicketsStatisticsModel): SupportTicketsStatisticsModel | undefined => {
    const ticketsClosedUnits = formatUnits(tickets?.ticketsClosedTimeAverageUnits);
    const firstResponseUnits = formatUnits(tickets?.firstResponseTimeAverageUnits);
    return (
      tickets && {
        ...tickets,
        ticketsClosedTimeAverageUnits: ticketsClosedUnits,
        firstResponseTimeAverageUnits: firstResponseUnits,
      }
    );
  };

  const getSupportTicketsStatistics = async () => {
    setIsLoading(true);
    try {
      const ticketsStatistics = await GetAssetSupportTicketsStatistics(assetId);
      const ticketsStatisticsWithFormattedUnits = formatSupportTicketsStatisticsUnits(ticketsStatistics);
      ticketsStatisticsWithFormattedUnits && setStatistics(ticketsStatisticsWithFormattedUnits);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSupportTicketsStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    supportTicketsStatistics: statistics,
    supportTicketsLoading: isLoading,
    supportTicketsError: isError,
    assetSupportTicketsStatisticsTranslations,
  };
};
