import { FC } from 'react';
import { Icon } from 'components/atoms/Icons';
import './styles.scss';

interface Props {
  infoTitle: string;
  infoText: string;
}

const ICON_SIZE = 24;

const AccessHotelKeyReaderDrawerInfoAlert: FC<Props> = ({
  infoTitle,
  infoText,
}) => {
  return (
    <div className={'AccessHotelKeyReaderDrawerInfoAlert__info'}>
      <div className={'AccessHotelKeyReaderDrawerInfoAlert__info-icon'}>
        <Icon.InfoCircleFilled style={{ fontSize: ICON_SIZE }} />
      </div>
      <div>
        <p className={'AccessHotelKeyReaderDrawerInfoAlert__info-title'}>{infoTitle}</p>
        <p className={'AccessHotelKeyReaderDrawerInfoAlert__info-text'}>{infoText}</p>
      </div>
    </div>
  );
}

export default AccessHotelKeyReaderDrawerInfoAlert;