import { ThirdPartyServicesByProject } from '../model/thirdPartyServicesByProject';

export const ThirdPartyServicesByProjectEntity = ({ 
  id, 
  title, 
  description, 
  link 
}: ThirdPartyServicesByProject): Readonly<ThirdPartyServicesByProject> => {
  return Object.freeze({
    id,
    title,
    description,
    link,
  });
};
