import { FC } from 'react';
import { Switch, List } from 'antd';
import Paper from 'components/atoms/Paper';
import { DeviceSensor, DeviceWithGatewayId } from 'models/devices.model';
import './styles.scss';

interface Props {
  devices: DeviceWithGatewayId[];
  width: number;
  title: string;
  getSensorsNames: (sensors: DeviceSensor[]) => string;
  onChange: (checked: boolean, deviceId: number, gatewayId: string) => Promise<void>;
  loading: boolean;
}

const MAX_SIZE_RESPONSIVE_SCREEN = 768;

const PermissionsAccess: FC<Props> = ({
  devices,
  width,
  title,
  getSensorsNames,
  onChange,
  loading,
}) => {
  return (
    <Paper>
      <h2>{title}</h2>
      <div className='PermissionsAccess'>
        <List
          grid={{ gutter: 100, column: width < MAX_SIZE_RESPONSIVE_SCREEN ? 1 : 2 }}
          loading={loading}
          dataSource={devices}
          renderItem={({ sensors, enabled, device_id, gatewayId }) => (
            <List.Item className='PermissionsAccess__item'>
              <span>{getSensorsNames(sensors)}</span>
              <Switch
                checked={enabled}
                onChange={(checked) => onChange(checked, Number(device_id), gatewayId)}
              />
            </List.Item>
          )}
        />
      </div>
    </Paper>
  );
};

export default PermissionsAccess;
