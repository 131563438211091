import api from 'services/api';
import { fileToBase64 } from 'utils/documents';
import { createDocumentsByProjectIdPath } from 'core/endspoints/documents';
import { DocumentUploadFile } from '../model/documentPayloadModels';

interface CreateDocumentByProjectIdParams {
  projectId: string;
  files: any[];
}

export const CreateDocumentsByProjectId = async ({ projectId, files }: CreateDocumentByProjectIdParams): Promise<void> => {
  const path = createDocumentsByProjectIdPath(projectId);
  const documents = await Promise.all(
    files.map(async (file) => {
      const fileContents = await fileToBase64(file);
      return { name: file.name, base64: fileContents } as DocumentUploadFile;
    }) as Promise<DocumentUploadFile>[]
  );
  await Promise.all(
    documents.map((document) => {
      const data = {
        title: document.name,
        comments: '',
        document: document.base64,
      };
      return api.post(path, { data });
    })
  );
};
