import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { ThirdPartyServicesByAsset } from 'core/domain/thirdPartyServices/model/thirdPartyServicesByAsset';
import GetThirdPartyServicesListByAsset from 'core/domain/thirdPartyServices/repositories/getThirdPartyServicesListByAsset';
import RemoveThirdPartyServices from 'core/domain/thirdPartyServices/repositories/removeThirdPartyServices';
import { TableActionsMenuProps } from 'components/atoms/TableAtom';

const sortReverse = (data: ThirdPartyServicesByAsset[]): ThirdPartyServicesByAsset[] => {
  return data.reverse();
};

interface UseThirdPartyServicesListByAssetResponse {
  data: ThirdPartyServicesByAsset[];
  usefulContactsTableActionsMenu: TableActionsMenuProps[];
  removeServiceModalTitle: string;
  removeServiceModalText: string;
  removeServiceModalMainButtonText: string;
  removeServiceModalSecondaryButtonText: string;
  onRemove: (serviceId: string) => void;
  onConfirmRemove: () => Promise<void>;
  onCloseConfirmModal: () => void;
  error: boolean;
  loading: boolean;
  removeServiceModalVisible: boolean;
}

export const useThirdPartyServicesListByAsset = (assetId: string): UseThirdPartyServicesListByAssetResponse => {
  const { t } = useTranslation();
  const [data, setData] = useState<ThirdPartyServicesByAsset[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [currentServiceId, setCurrentServiceId] = useState<string>('');
  const [removeServiceModalVisible, setRemoveServiceModalVisible] = useState<boolean>(false);

  const removeServiceModalTitle = t('_ASSET_DETAIL_USEFUL_CONTACTS_MODAL_TITLE');
  const removeServiceModalText = t('revoke_service_confirm');
  const removeServiceModalMainButtonText = t('delete');
  const removeServiceModalSecondaryButtonText = t('cancel');

  const usefulContactsTableActionsMenu: TableActionsMenuProps[] = [
    {
      key: 'unlinkUser',
      onClick: (serviceId: string) => onRemove(serviceId),
      text: t('delete'),
      disabled: false,
    },
  ];

  const onFallbackThirdPartyServicesListByAsset = useCallback(async (assetId: string) => {
    try {
      const data = await GetThirdPartyServicesListByAsset(assetId);
      setData(sortReverse(data));
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      setIsError(false);
    }
  }, []);

  useEffect(() => {
    onFallbackThirdPartyServicesListByAsset(assetId);
  }, [assetId, onFallbackThirdPartyServicesListByAsset]);

  const onRemove = (serviceId: string) => {
    setCurrentServiceId(serviceId);
    setRemoveServiceModalVisible(true);
  };

  const onConfirmRemove = async () => {
    setIsLoading(true);
    try {
      await RemoveThirdPartyServices(currentServiceId);
      await onFallbackThirdPartyServicesListByAsset(assetId);
      message.info(t('deleted_correctly'), 3);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
      setRemoveServiceModalVisible(false);
    }
  };

  const onCloseConfirmModal = () => {
    setRemoveServiceModalVisible(false);
  };

  return {
    data,
    usefulContactsTableActionsMenu,
    removeServiceModalTitle,
    removeServiceModalText,
    removeServiceModalMainButtonText,
    removeServiceModalSecondaryButtonText,
    loading: isLoading,
    error: isError,
    onRemove,
    onConfirmRemove,
    onCloseConfirmModal,
    removeServiceModalVisible,
  };
};
