import { PremiumUser } from '../model/premiumUser';

const PremiumUserEntity = ({
  billableUsers,
  clientEmail,
  clientId,
  clientName
}: PremiumUser): Readonly<PremiumUser> => {
  return Object.freeze({
    billableUsers,
    clientEmail,
    clientId,
    clientName,
  })
};

export default PremiumUserEntity;
