import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { createClientToPayload } from '../mappers/createClientToPayload';
import { CreateClientModel, CreateClientResponseData } from '../models/createClientEntityModel';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string) => {
  return `${host}/clients`;
};

export const CreateClient = async ({
  host,
  token,
  client,
}: {
  host: string;
  token: string;
  client: CreateClientModel;
}): Promise<CreateClientResponseData> => {
  try {
    const url = getUrl(host);
    const mappedData = createClientToPayload(client);

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: mappedData },
    };

    const { data: responseData } = (await axios(options)) as AxiosResponse<{ data: CreateClientResponseData }>;
    return responseData.data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
