import { LogHistoryDataModel } from 'core/domain/offices/models';
import { formatSpanishDateAndTimeWithSlash } from 'utils/date';

export interface LogListCustomDataModel extends LogHistoryDataModel {
  createdAtText: string;
}

const transformLogToCustomLog = (props: LogHistoryDataModel): LogListCustomDataModel => ({
  ...props,
  createdAtText: formatSpanishDateAndTimeWithSlash(props.createdAt),
});

export const transformLogListToCustomData = (logList: LogHistoryDataModel[]): LogListCustomDataModel[] => {
  return logList.map((item) => transformLogToCustomLog(item));
};
