import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserSession } from '../../../../hooks/useUserSession';

const getLink = (clientId: string, projectId: string, token: string): string => {
  const isLocalDevelopment = window.location.hostname === 'localhost';
  const host = isLocalDevelopment ? process.env.REACT_APP_SAAS_LOCALHOST : process.env.REACT_APP_SAAS_HOST;
  return `${host}/${clientId}/${projectId}/common-areas?token=${token}`;
};

export const useCommonAreasV2 = () => {
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>();
  const { token } = useUserSession();
  const { t } = useTranslation();

  const text: string = t('_COMMON_AREAS_NEW_ASSETS_TEXT');
  const textButton: string = t('_COMMON_AREAS_NEW_ASSETS_BUTTON');

  const link = getLink(clientId, projectId, token);

  return {
    text,
    textButton,
    link,
  }
};
