import { PayloadSupportTicketsStatisticsModel } from '../models/supportTicketsPayloadModel';
import { SupportTicketsStatisticsModel } from '../models/supportTicketsModel';

export const payloadToSupportTicketsStatistics = ({
  tickets_closed,
  tickets_closed_time_average,
  tickets_closed_time_average_units,
  tickets_received,
  tickets_revoked,
  tickets_in_progress,
  first_response_time_average,
  first_response_time_average_units,
}: PayloadSupportTicketsStatisticsModel): SupportTicketsStatisticsModel => {
  return {
    ticketsClosed: tickets_closed ?? 0,
    ticketsClosedTimeAverage: tickets_closed_time_average ?? '',
    ticketsClosedTimeAverageUnits: tickets_closed_time_average_units ?? '',
    ticketsReceived: tickets_received ?? 0,
    ticketsRevoked: tickets_revoked ?? 0,
    ticketsInProgress: tickets_in_progress ?? 0,
    firstResponseTimeAverage: first_response_time_average ?? '',
    firstResponseTimeAverageUnits: first_response_time_average_units ?? '',
  };
};
