import { useContext, useState } from 'react';
import SharedGatewaysContext, { SharedGatewaysContextState } from 'context/SharedGatewaysContext';
import { ProjectSharedGatewayModel } from 'core/domain/project/model/projectModel';
import { GetProjectById } from 'core/domain/project/repositories/projectsCRUD';

export const useSharedGateways = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const { sharedGateways, setSharedGateways } = useContext<SharedGatewaysContextState>(SharedGatewaysContext);

  const filterGatewayTypeShared = (gateways: ProjectSharedGatewayModel[]) => {
    return gateways.filter((gateway) => gateway.type === 'shared');
  };

  const onSetSharedGateways = (data: ProjectSharedGatewayModel[]): void => {
    setSharedGateways(data);
  };

  const onGetSharedGateways = async (projectId: string): Promise<void> => {
    try {
      setIsLoading(true);
      const project = await GetProjectById(projectId);
      const sharedGateways = project ? filterGatewayTypeShared(project.sharedGateways) : [];
      onSetSharedGateways(sharedGateways);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsError(false);
      setIsLoading(false);
    }
  };

  return {
    data: sharedGateways,
    loading: isLoading,
    error: isError,
    onSetData: onSetSharedGateways,
    onGetData: onGetSharedGateways,
    filterGatewayTypeShared,
  };
};
