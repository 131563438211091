import { TenantsPreviewDataResponseModel, TenantsPreviewResponseModel } from '../models/userModel';
import { TenantsPreviewDataResponsePayloadModel, TenantsPreviewResponsePayloadModel } from '../models/userPayloadModel';

const payloadToTenantsPreviewData = ({
  name,
  email,
  address,
  asset_id,
}: TenantsPreviewDataResponsePayloadModel): TenantsPreviewDataResponseModel => {
  return {
    name: {
      label: name.label ?? '',
      error: name.error ?? false,
    },
    email: {
      label: email.label ?? '',
      error: email.error ?? false,
    },
    address: {
      label: address.label ?? '',
      error: address.error ?? false,
    },
    assetId: {
      label: asset_id.label ?? '',
      error: asset_id.error ?? false,
    },
  };
};

export const payloadToTenantsPreview = ({ is_error, preview_data }: TenantsPreviewResponsePayloadModel): TenantsPreviewResponseModel => {
  const transformedPreviewData = preview_data.map((item) => payloadToTenantsPreviewData(item));
  return {
    isError: is_error ?? false,
    previewData: transformedPreviewData ?? [],
  };
};
