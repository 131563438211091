import { FC } from 'react';
import { ModalProps, Space } from 'antd';
import ModalAtom from 'components/atoms/ModalAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { UnAssignToProjectModalOptions } from 'components/pages/GatewaysListPage/resources/utils';

export interface UnAssignGatewayToProjectModalTranslations {
  title: string,
  description: string;
  mainButtonText: string,
  secondaryButtonText: string,
}

interface Props extends ModalProps {
  modalOptions: UnAssignToProjectModalOptions;
}

const UnAssignGatewayToProjectModal: FC<Props> = ({ modalOptions, onCancel, ...props }) => {
  const { unAssignGatewayToProjectModalTranslations, onUnAssignGatewayToProject, unAssigning } = modalOptions;

  const {
    title,
    description,
    mainButtonText,
    secondaryButtonText,
  } = unAssignGatewayToProjectModalTranslations;

  return (
    <ModalAtom
      {...props}
      title={<Space>{title}</Space>}
      onCancel={onCancel}
      destroyOnClose
      width={'50%'}
      footer={[
        <DefaultButton key={'back'} onClick={onCancel} title={secondaryButtonText} />,
        <PrimaryButton key={'submit'} loading={unAssigning} onClick={onUnAssignGatewayToProject} title={mainButtonText} />,
      ]}
    >
      {description}
    </ModalAtom>
  );
}

export default UnAssignGatewayToProjectModal;