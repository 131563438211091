import { ActiveIssue } from '../activeIssue';
import { ThresholdsUnparsed } from '../thresholds/thresholdsUnparsed';

export enum GatewayNetworkStateStatusModel {
  GOOD = 'good',
  MEDIUM = 'medium',
  BAD = 'bad',
  UNKNOWN = 'unknown',
}

export interface GatewayNetworkStatusPayload {
  status: GatewayNetworkStateStatusModel;
  percentage_package_lost: number | null;
  latency: number | null;
  bandwidth: number | null;
}

export enum GatewayNetworkInterfaceModel {
  ETHERNET = 'ETHERNET',
  WIFI = 'WIFI',
  LTE = 'LTE',
}

export interface GatewayStatusPayload {
  gateway_id: string;
  asset_id: string;
  project_id: string;
  location: string;
  vpn_ip: string | null;
  gateway_version: string | null;
  hw_version: string | null;
  knx_version: string | null;
  firmware_version: string | null;
  helper_version: string | null;
  zwave_version: string | null;
  deployment_id: string | null;
  uptime: number;
  last_connection: number;
  time_offline: number | null;
  production_date: number | null;
  cpu_temperature: number | null;
  cpu_ram_used: number | null;
  cpu_hdd_used: number | null;
  health: number | null;
  total_inodes: number | null;
  total_swap: number | null;
  used_swap: number | null;
  used_inodes: number | null;
  cpu_load: number | null;
  zwave_controller: boolean | null;
  vpn_connection: boolean | null;
  helper_service: boolean | null;
  helper_api: boolean | null;
  assistant_cloud_service: boolean | null;
  active_issues: ActiveIssue[] | null;
  thresholds: ThresholdsUnparsed;
  network_interface: GatewayNetworkInterfaceModel;
  network_state: GatewayNetworkStatusPayload;
}

export interface GatewayStatusUnitsPayload {
  cpu_temperature: string;
  cpu_load: string;
  cpu_ram: string;
  system_time: string;
  uptime: string;
  openhab_api: string;
  openhab_serviced: string;
  cpu_hdd_used: string;
  zwave_controller: string;
  assistant_cloud_service: string;
  vpn_connection: string;
  cpu_ram_used: string;
  cpu_hd_used: string;
  ohs_api: string;
  ohs_service: string;
  cpu_hd_available: string;
  cpu_ram_available: string;
  total_swap: string;
  used_swap: string;
  total_inodes: string;
  used_inodes: string;
  vpn_ip: string;
  battery_level: string;
  connectivity: string;
  internal_temperature: string;
  latency: string;
}
export interface GatewayStatusResponsePayload {
  data: GatewayStatusPayload[];
  units: GatewayStatusUnitsPayload;
  links?: {
    first: string;
    last: string;
    next: string;
    prev: string;
    self: string;
  };
  meta?: {
    all_results: number;
    page_size: number;
    results: number;
    total_pages: number;
  };
}
