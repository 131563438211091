import api from 'services/api';

const fileFormat = 'csv';

interface DownloadAssetDataCsvModel {
  assetId: string;
  sensor_uuid: string;
  from?: number|null;
  until?: number|null;
}

const DownloadAssetDataCsv = async ({
  assetId,
  sensor_uuid,
  from = null,
  until = null,
}:DownloadAssetDataCsvModel): Promise<string> => {
  const url = `/data/assets/download-time-series/${assetId}`;
  const paramsObj = {
    assetId,
    sensor_uuid,
    format: fileFormat,
    from: `${from}` || 'null',
    until: `${until}` || 'null',
  };
  const params = new URLSearchParams(paramsObj).toString();

  return await api.get(`${url}?${params}`);
}

export default DownloadAssetDataCsv
