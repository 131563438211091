import { AreaType, SecondLevelHorizontalArea } from "../model/projectLayoutConfigModels";
import { PayloadSecondLevelHorizontalArea } from "../model/projectLayoutConfigPayloadModels";

export const payloadToSecondLevelHorizontalArea = ({
  id,
  image_url,
  name_en,
  name_es,
  project_id,
  vertical_id,
}: PayloadSecondLevelHorizontalArea): SecondLevelHorizontalArea => {
  return {
    id: id ?? '',
    type: AreaType.HORIZONTAL,
    imageURL: image_url ?? '',
    nameEn: name_en ?? '',
    nameEs: name_es ?? '',
    projectId: project_id ?? '',
    verticalId: vertical_id ?? '',
  };
};