import { FC } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import './styles.scss';

interface ImageActionsMoleculeProps {
  imageName?: string;
  imageURL: string;
  disabledDelete?: boolean;
  onTrashClick: () => void;
}

const ImageActionsMolecule: FC<ImageActionsMoleculeProps> = ({ onTrashClick, imageName, imageURL, disabledDelete = false }) => {
  return (
    <div className={`ImageActionsMolecule__container ${disabledDelete && 'ImageActionsMolecule__container--disabled'}`}>
      {!!imageURL.length && <img className={'ImageActionsMolecule__image'} src={imageURL} alt={imageName} />}
      <span>{imageName}</span>
      {!disabledDelete ? (
        <div className={'ImageActionsMolecule__icon'} onClick={onTrashClick}>
          <DeleteOutlined />
        </div>
      ) : (
        <DeleteOutlined />
      )}
    </div>
  );
};

export default ImageActionsMolecule;
