import { CreateClientModel } from '../models/createClientEntityModel';
import { PayloadCreateClient } from '../models/createClientPayloadModel';

export const createClientToPayload = ({ name, email, description }: CreateClientModel): PayloadCreateClient => {
  return {
    name,
    email: email || null,
    description: description || null,
  };
};
