import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import ClientCustomizationForm from "components/molecules/ClientCustomizationForm";
import { ClientCustomizationTranslations, ClientCustomizationFormValues, ClientCustomizationInputValue } from 'components/pages/ClientDetailPage/TabCustomization/SubTabs/SubTabAssetsManagerAndEmail/resources/models';
import './styles.scss';
import ClientCustomizationPreView from 'components/molecules/ClientCustomizationPreView';

interface Props {
  initialFormValues: ClientCustomizationFormValues;
  customizationFormValues: ClientCustomizationFormValues;
  customizationPreviewValues: ClientCustomizationFormValues;
  translations: ClientCustomizationTranslations;
  Form: any;
  form: FormInstance<ClientCustomizationFormValues>
  onChangeMade: ({ label, value }: ClientCustomizationInputValue) => void;
  onCancel: () => void;
  onPublish: () => void;
  disabled: boolean;
  updating: boolean;
  canceled: boolean;
}

const ClientCustomizationTemplate: FC<Props> = ({
  initialFormValues,
  customizationFormValues,
  customizationPreviewValues,
  translations,
  Form,
  form,
  onChangeMade,
  onCancel,
  onPublish,
  updating,
  disabled,
  canceled,
}) => {
  const { t } = useTranslation();

  return (
    <div className={'ClientCustomizationTemplate__container'}>
      <div className={'ClientCustomizationTemplate__form'}>
        <span className={'ClientCustomizationTemplate__title'}>{t('_CUSTOMIZATION_FORM_TITLE')}</span>
        <ClientCustomizationForm
          translations={translations}
          customizationFormValues={customizationFormValues}
          initialValues={initialFormValues}
          Form={Form} form={form}
          onChangeMade={onChangeMade}
          onCancel={onCancel}
          onPublish={onPublish}
          disabled={disabled}
          updating={updating}
          canceled={canceled}
        />
      </div>
      <div className={'ClientCustomizationTemplate__preview'}>
        <ClientCustomizationPreView previewValues={customizationPreviewValues} />
      </div>
    </div>
  )
}

export default ClientCustomizationTemplate;