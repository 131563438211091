import { FC } from 'react';
import { GatewayNetworkStateStatusModel } from 'core/domain/gateways/model/gatewayStatus/gatewayStatusPayload';
import TagError from 'components/atoms/tags/TagError';
import TagWarning from 'components/atoms/tags/TagWarning';
import TagSuccess from 'components/atoms/tags/TagSuccess';
import TagDefault from 'components/atoms/tags/TagDefault';
import { GatewayStatusNetworkStateStatusTranslationsModel } from 'components/pages/AssetPage/Tabs/TabGateway/resources/utils';

interface Props {
  status: GatewayNetworkStateStatusModel;
  translations: GatewayStatusNetworkStateStatusTranslationsModel;
}

const networkStateTagManager = {
  [GatewayNetworkStateStatusModel.BAD]: (translations: GatewayStatusNetworkStateStatusTranslationsModel) => <TagError text={translations[GatewayNetworkStateStatusModel.BAD]} />,
  [GatewayNetworkStateStatusModel.MEDIUM]: (translations: GatewayStatusNetworkStateStatusTranslationsModel) => <TagWarning text={translations[GatewayNetworkStateStatusModel.MEDIUM]} />,
  [GatewayNetworkStateStatusModel.GOOD]: (translations: GatewayStatusNetworkStateStatusTranslationsModel) => <TagSuccess text={translations[GatewayNetworkStateStatusModel.GOOD]} />,
  [GatewayNetworkStateStatusModel.UNKNOWN]: (translations: GatewayStatusNetworkStateStatusTranslationsModel) => <TagDefault text={translations[GatewayNetworkStateStatusModel.UNKNOWN]} />,
}

const GatewayStatusNetworkStateManager: FC<Props> = ({ status, translations }) => {
  return networkStateTagManager[status](translations);
}

export default GatewayStatusNetworkStateManager;