import { TenantsPreviewDataResponseModel } from '../models/userModel';
import { TenantsDataPayloadModel } from '../models/userPayloadModel';

const tenantToBeUploaded = ({ name, email, address, assetId }: TenantsPreviewDataResponseModel): TenantsDataPayloadModel => {
  return {
    name: name.label ?? '',
    email: email.label ?? '',
    address: address.label ?? '',
    asset_id: assetId.label ?? '',
  };
};

export const tenantsListToBeUploaded = (tenants: TenantsPreviewDataResponseModel[]): TenantsDataPayloadModel[] => {
  return tenants.map((item) => tenantToBeUploaded(item));
};
