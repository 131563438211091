import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { GatewayModuleType } from '../model';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = ({ host, gatewayId, module }: { host: string; gatewayId: string; module: GatewayModuleType }) => {
  return `${host}/gateways/updates/${gatewayId}/${module}/latest`;
};

export const UpdateGatewayModuleVersion = async ({
  gatewayId,
  module,
  token,
  host,
}: {
  gatewayId: string;
  module: GatewayModuleType;
  token: string;
  host: string;
}): Promise<void> => {
  try {
    const url = getUrl({ host, gatewayId, module });

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<void>;
    return data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
