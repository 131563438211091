import { FC } from 'react';
import CommonAreaContentGrid from 'components/molecules/CommonAreaContentGrid';
import SwitchAtom from 'components/atoms/SwitchAtom';
import DividerAtom from 'components/atoms/DividerAtom';
import TextButton from 'components/atoms/Button/TextButton';
import { CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import './styles.scss';

interface Props {
  commonArea: CommonAreaModel;
  sensorsOptionList: SensorByProjectModel[];
  updateButtonText: string;
  onEnableCommonArea: (checked: boolean) => void;
  onUpdateCommonArea: () => void;
  loading: boolean;
}

const CommonAreaDetailTemplate: FC<Props> = ({
  commonArea,
  sensorsOptionList,
  updateButtonText,
  onEnableCommonArea,
  onUpdateCommonArea,
  loading,
}) => {
  return (
    <div className={'CommonAreaDetailTemplate__body'}>
      <div className={'CommonAreaDetailTemplate__body-form'}>
        <CommonAreaContentGrid commonAreaData={commonArea} sensorOptionList={sensorsOptionList} />
        <div className={'CommonAreaDetailTemplate__body-form-switch'}>
          <SwitchAtom checked={commonArea.enabled} onChange={onEnableCommonArea} loading={loading} />
        </div>
      </div>
      <DividerAtom />
      <div className={'CommonAreaDetailTemplate__body-actions'}>
        <TextButton title={updateButtonText} onClick={onUpdateCommonArea} />
      </div>
    </div>
  );
}

export default CommonAreaDetailTemplate;