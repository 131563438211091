import { IntercomRoomModel } from '../model/intercomModel';
import { PayloadIntercomRoomModel } from '../model/intercomPayloadModel';

export const payloadToRoom = ({
  id,
  identifier,
  password,
  comments,
  asset_id,
  intercom_id,
}: PayloadIntercomRoomModel): IntercomRoomModel => {
  return {
    id: id ?? '',
    identifier: identifier ?? '',
    password: password ?? '',
    comments: comments ?? '',
    assetId: asset_id ?? '',
    intercomId: intercom_id ?? '',
  };
};
