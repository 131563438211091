import { FC } from 'react';
import ComingSoonTemplate from 'components/template/ComingSoonTemplate';
import { useBuildingComingSoon } from './resources/useBuildingComingSoon';

const BuildingComingSoonPage: FC = () => {
  const { forbiddenText } = useBuildingComingSoon();

  return <ComingSoonTemplate text={forbiddenText} />
}

export default BuildingComingSoonPage;