import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { UpdateFormValues, RequiredFormValues } from '../models';
import { useFieldsEventsAndNewsFormErrors } from './useFieldsEventsAndNewsFormErrors';
import { EventsAndNewsFormValues } from '../enums/form';
import { EventsAndNewsModel } from 'core/domain/eventsAndNews/models/eventsAndNewsEntityModels';

interface UseUpdateEventsAndNewsForm {
  eventsAndNewsData: EventsAndNewsModel;
}

export const useUpdateEventsAndNewsForm = ({ eventsAndNewsData }: UseUpdateEventsAndNewsForm) => {
  const [form] = Form.useForm<UpdateFormValues>();
  const { errors, resetErrors, setErrors } = useFieldsEventsAndNewsFormErrors();
  const [areThereChanged, setAreThereChanged] = useState<boolean>(false);

  useEffect(() => {
    if (eventsAndNewsData) {
      form.setFieldsValue(eventsAndNewsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsAndNewsData]);

  const checkAllRequiredFields = ({ title, image, description }: RequiredFormValues) => {
    if (!title || !description) {
      throw new Error('There are some fields not completed');
    }
  };

  const onParseValues = ({ title, image, description, ...values }: UpdateFormValues): UpdateFormValues | undefined => {
    try {
      checkAllRequiredFields({ title, image, description });
      resetErrors();
      return {
        title,
        image,
        description,
        ...values,
      };
    } catch (error: any) {
      setErrors({ title, image, description, ...values });
    }
  };

  const onIsThereModal = ({ title, description, link, linkTitle }: UpdateFormValues): boolean => {
    const isThereTitle = title !== eventsAndNewsData.title;
    const isThereDescription = description !== eventsAndNewsData.description;
    const isThereLink = link !== eventsAndNewsData.link;
    const isThereLinkTitle = linkTitle !== eventsAndNewsData.linkTitle;

    return [isThereTitle, isThereDescription, isThereLink, isThereLinkTitle].some((item) => item);
  };

  const changesManager = {
    [EventsAndNewsFormValues.TITLE]: (values: string): boolean => values !== eventsAndNewsData.title,
    [EventsAndNewsFormValues.IMAGE]: (values: string): boolean => values !== eventsAndNewsData.image,
    [EventsAndNewsFormValues.DESCRIPTION]: (values: string): boolean => values !== eventsAndNewsData.description,
    [EventsAndNewsFormValues.LINK]: (values: string): boolean => values !== eventsAndNewsData.link,
    [EventsAndNewsFormValues.LINK_TITLE]: (values: string): boolean => values !== eventsAndNewsData.linkTitle,
    [EventsAndNewsFormValues.SEND_NOTIFICATION]: (values: string): boolean => values !== eventsAndNewsData.sendNotification?.toString(),
  };

  const onChangedMade = ({ label, value }: { label: EventsAndNewsFormValues; value: string }): void => {
    const item = changesManager[label];
    const isChange = item(value);
    setAreThereChanged(isChange);
  };

  return {
    Form,
    form,
    errors,
    onParseValues,
    onIsThereModal,
    onChangedMade,
    areThereChanged,
  };
};
