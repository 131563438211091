import { FC, ReactElement, useState } from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import AssetEditModal from 'components/organisms/AssetEditModal';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { AssetInAssetDashboard, RegisterAssetAttributes } from 'models/assets.model';
import { messageAtom } from 'components/atoms/MessageAtom';
import AddMaintenanceActionDrawer from './AddMaintenanceActionDrawer';
import ConfirmModal from 'components/molecules/ConfirmModal';

interface AssetMenuExtraProps {
  asset: AssetInAssetDashboard;
  gatewayId: string;
  deleteModalTitle: string;
  onSubmitEdit: (values: RegisterAssetAttributes) => Promise<void>;
  onClickDelete: () => void;
  onCancelDelete: () => void;
  onClickAgree: () => Promise<void>;
  onDeleteAsset: () => Promise<void>;
  showConfirmDeleteModal: boolean;
  showLinkedGatewaysInfoModal: boolean;
  showActiveUsersInfoModal: boolean;
  isEditLoading: boolean;
  isDeleteLoading: boolean;
  deleteDisabled: boolean;
  maintenanceDisabled: boolean;
}

const AssetMenuExtra: FC<AssetMenuExtraProps> = ({
  asset,
  gatewayId,
  deleteModalTitle,
  onSubmitEdit,
  onClickDelete,
  onCancelDelete,
  onClickAgree,
  onDeleteAsset,
  showActiveUsersInfoModal,
  showLinkedGatewaysInfoModal,
  showConfirmDeleteModal,
  isEditLoading,
  isDeleteLoading,
  deleteDisabled,
  maintenanceDisabled,
}) => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showMaintenanceActionDrawer, setShowMaintenanceActionDrawer] = useState<boolean>(false);

  const handleOnClickMaintenance = () => {
    setShowMaintenanceActionDrawer(true);
  };

  const handleOnCloseMaintenance = () => {
    setShowMaintenanceActionDrawer(false);
  };

  const handleOnClickEdit = () => {
    setShowEditModal(true);
  };

  const handleOnCancelEdit = () => {
    setShowEditModal(false);
  };

  const handleOnFinishEdit = async (values: RegisterAssetAttributes) => {
    try {
      await onSubmitEdit(values);
      messageAtom.success(t('successfully_edit'), 3);
      setShowEditModal(false);
    } catch (error) {
      messageAtom.error(t('unsuccessfully_edit'), 3);
    }
  };

  const menuExtra: ReactElement = (
    <Menu>
      <Menu.Item key={'edit'} onClick={handleOnClickEdit}>
        {t('edit')}
      </Menu.Item>
      <Menu.Item
        key={'maintenanceActions'}
        onClick={handleOnClickMaintenance}
        disabled={maintenanceDisabled}
      >
        {t('maintenance_actions')}
      </Menu.Item>
      <Menu.Item
        disabled={deleteDisabled}
        danger={!deleteDisabled}
        key={'deleteAsset'}
        onClick={onClickDelete}
      >
        {t('_ASSET_DELETE')}
      </Menu.Item>
    </Menu >
  );

  return (
    <>
      <ContextualMenu menu={menuExtra} />
      <AddMaintenanceActionDrawer
        visible={showMaintenanceActionDrawer}
        gatewayId={gatewayId}
        onClose={handleOnCloseMaintenance}
      />
      <AssetEditModal
        asset={asset}
        open={showEditModal}
        onCancel={handleOnCancelEdit}
        onSubmit={handleOnFinishEdit}
        loading={isEditLoading}
      />
      <ConfirmModal
        onCancel={onCancelDelete}
        onClickMainButton={onClickAgree}
        visible={showActiveUsersInfoModal}
        loading={false}
        title={deleteModalTitle}
        text={t('_ASSET_DELETE_INFO_ACTIVE_USERS_MODAL_TEXT')}
        mainButtonText={t('_ASSET_DELETE_INFO_ACTIVE_USERS_MODAL_BUTTON')}
        secondaryButtonText={''}
      />
      <ConfirmModal
        onCancel={onCancelDelete}
        onClickMainButton={onClickAgree}
        visible={showLinkedGatewaysInfoModal}
        loading={false}
        title={deleteModalTitle}
        text={t('_ASSET_DELETE_INFO_LINKED_GATEWAYS_MODAL_TEXT')}
        mainButtonText={t('_ASSET_DELETE_INFO_LINKED_GATEWAYS_MODAL_BUTTON')}
        secondaryButtonText={''}
      />
      <ConfirmModal
        onCancel={onCancelDelete}
        onClickMainButton={onDeleteAsset}
        visible={showConfirmDeleteModal}
        loading={isDeleteLoading}
        title={deleteModalTitle}
        text={t('_ASSET_DELETE_MODAL_TEXT')}
        mainButtonText={t('_ASSET_DELETE_MODAL_BUTTON')}
        secondaryButtonText={t('_ASSET_DELETE_MODAL_BUTTON_CANCEL')}
      />
    </>
  );
};

export default AssetMenuExtra;
