import api from 'services/api';
import { commonAreasUpdatedPath } from 'core/endspoints/commonAreas';
import { commonAreaToPayload } from '../mappers/commonAreaToPayload';
import { UpdateCommonAreaModel } from '../models/commonAreaEntityModels';

export const UpdateCommonArea = async ({ id, ...formValues }: Omit<UpdateCommonAreaModel, 'hasBooking' | 'enabled'>): Promise<void> => {
  try {
    const path = commonAreasUpdatedPath(id);
    const data = commonAreaToPayload({ id, ...formValues });
    api.defaults.headers.common['Accept-version'] = '1';
    await api.patch(path, { data });
  } catch (error: any) {
    throw new Error(error);
  }
};
