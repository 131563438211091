import { FC } from 'react';
import './styles.scss';

interface TabSidebarMenuListItemProps {
  itemName: string;
  id: string;
  onClick?: (id: string) => void;
  selected: boolean;
  disabled: boolean;
}

const TabSidebarMenuListItem: FC<TabSidebarMenuListItemProps> = ({ itemName, id, selected, onClick, disabled }) => {
  const selectedItemMenu = selected && 'TabSidebarMenuListItem--active';
  const disabledItemMenu = disabled && 'TabSidebarMenuListItem--disabled';

  const handleOnClick = () => {
    onClick && onClick(id);
  };

  return (
    <li className={`TabSidebarMenuListItem ${selectedItemMenu} ${disabledItemMenu}`} onClick={handleOnClick}>
      {itemName}
    </li>
  );
};

export default TabSidebarMenuListItem;
