import { tenantsTemplatePath } from 'core/endspoints/users';
import api from 'services/api';

export const getDownloadTenantsTemplate = async (): Promise<string | undefined> => {
  api.defaults.headers.common['Accept-version'] = '1';
  const path = tenantsTemplatePath();

  try {
    const { data } = await api.get<string | undefined>(path);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
