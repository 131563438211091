import { FC } from 'react';
import { useMaintenance } from './resources/useMaintenance';
import MaintenanceTemplate from 'components/template/MaintenanceTemplate';

const MaintenancePage: FC = () => {
  const { title, firstParagraph, secondParagraph, buttonText, onRefresh } = useMaintenance();

  return (
    <MaintenanceTemplate
      title={title}
      firstParagraph={firstParagraph}
      secondParagraph={secondParagraph}
      buttonText={buttonText}
      onClick={onRefresh}
    />
  );
}

export default MaintenancePage;