import { GatewayStatusModel, GatewayStatusResponseModel, GatewayStatusUnitsModel } from '../model/gatewayStatus';
import {
  GatewayNetworkStateStatusModel,
  GatewayStatusPayload,
  GatewayStatusResponsePayload,
  GatewayStatusUnitsPayload,
} from '../model/gatewayStatus/gatewayStatusPayload';
import { payloadToThresholds } from './payloadToThresholds';

export const payloadToGatewayStatus = ({
  gateway_id,
  asset_id,
  project_id,
  location,
  vpn_ip,
  gateway_version,
  hw_version,
  knx_version,
  firmware_version,
  helper_version,
  zwave_version,
  deployment_id,
  uptime,
  last_connection,
  time_offline,
  production_date,
  cpu_temperature,
  cpu_ram_used,
  cpu_hdd_used,
  health,
  total_inodes,
  total_swap,
  used_swap,
  used_inodes,
  cpu_load,
  zwave_controller,
  vpn_connection,
  helper_service,
  helper_api,
  assistant_cloud_service,
  active_issues,
  thresholds,
  network_interface,
  network_state,
}: GatewayStatusPayload): GatewayStatusModel => {
  const thresholdsParser = payloadToThresholds(thresholds);

  return {
    gatewayId: gateway_id ?? '',
    assetId: asset_id ?? '',
    projectId: project_id ?? '',
    location: location,
    vpnIp: vpn_ip ?? '',
    gatewayVersion: gateway_version ?? '',
    hwVersion: hw_version ?? '',
    knxVersion: knx_version ?? '',
    firmwareVersion: firmware_version ?? '',
    helperVersion: helper_version ?? '',
    zwaveVersion: zwave_version ?? '',
    deploymentId: deployment_id ?? '',
    uptime: uptime ?? null,
    lastConnection: last_connection ?? null,
    timeOffline: time_offline ?? null,
    productionDate: production_date ?? null,
    cpuTemperature: cpu_temperature ?? null,
    cpuRamUsed: cpu_ram_used ?? null,
    cpuHddUsed: cpu_hdd_used ?? null,
    health: health ?? null,
    totalInodes: total_inodes ?? null,
    totalSwap: total_swap ?? null,
    usedSwap: used_swap ?? null,
    usedInodes: used_inodes ?? null,
    cpuLoad: cpu_load ?? null,
    zwaveController: zwave_controller ?? null,
    vpnConnection: vpn_connection ?? null,
    helperService: helper_service ?? null,
    helperApi: helper_api ?? null,
    assistantCloudService: assistant_cloud_service ?? null,
    activeIssues: active_issues ?? null,
    thresholds: thresholdsParser,
    networkInterface: network_interface ?? null,
    networkState: {
      bandWidth: network_state?.bandwidth ?? 0,
      latency: network_state?.latency ?? 0,
      percentagePackageLost: network_state?.percentage_package_lost ?? 0,
      status: network_state?.status ?? GatewayNetworkStateStatusModel.UNKNOWN,
    },
  };
};

export const payloadToGatewayStatusUnits = ({
  cpu_temperature,
  cpu_load,
  cpu_ram,
  system_time,
  uptime,
  openhab_api,
  openhab_serviced,
  cpu_hdd_used,
  zwave_controller,
  assistant_cloud_service,
  vpn_connection,
  cpu_ram_used,
  cpu_hd_used,
  ohs_api,
  ohs_service,
  cpu_hd_available,
  cpu_ram_available,
  total_swap,
  used_swap,
  total_inodes,
  used_inodes,
  vpn_ip,
  battery_level,
  connectivity,
  internal_temperature,
  latency,
}: GatewayStatusUnitsPayload): GatewayStatusUnitsModel => {
  return {
    cpuTemperature: cpu_temperature ?? '',
    cpuLoad: cpu_load ?? '',
    cpuRam: cpu_ram ?? '',
    systemTime: system_time ?? '',
    uptime: uptime ?? '',
    openhabApi: openhab_api ?? '',
    openhabServiced: openhab_serviced ?? '',
    cpuHddUsed: cpu_hdd_used ?? '',
    zwaveController: zwave_controller ?? '',
    assistantCloudService: assistant_cloud_service ?? '',
    vpnConnection: vpn_connection ?? '',
    cpuRamUsed: cpu_ram_used ?? '',
    cpuHdUsed: cpu_hd_used ?? '',
    ohsApi: ohs_api ?? '',
    ohsService: ohs_service ?? '',
    cpuHdAvailable: cpu_hd_available ?? '',
    cpuRamAvailable: cpu_ram_available ?? '',
    totalSwap: total_swap ?? '',
    usedSwap: used_swap ?? '',
    totalInodes: total_inodes ?? '',
    usedInodes: used_inodes ?? '',
    vpnIp: vpn_ip ?? '',
    batteryLevel: battery_level ?? '',
    connectivity: connectivity ?? '',
    internalTemperature: internal_temperature ?? '',
    latency: latency ?? '',
  };
};

export const payloadToGatewayStatusResponse = ({ data, units, links, meta }: GatewayStatusResponsePayload): GatewayStatusResponseModel => {
  const transformedGatewaysStatus = data.map((item) => payloadToGatewayStatus(item)) ?? [];

  return {
    data: transformedGatewaysStatus,
    units: payloadToGatewayStatusUnits(units),
    links: {
      first: links?.first ?? '',
      last: links?.last ?? '',
      next: links?.next ?? '',
      prev: links?.prev ?? '',
      self: links?.self ?? '',
    },
    meta: {
      allResults: meta?.all_results ?? 1,
      pageSize: meta?.page_size ?? 1,
      results: meta?.results ?? 1,
      totalPages: meta?.total_pages ?? 1,
    },
  };
};
