import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSharedGateways } from '../useSharedGateways';
import { useClientProject } from '../useClientProject';
import { ProjectModel, UpdateProjectModel } from 'core/domain/project/model/projectModel';
import { GetProjectById, UpdateProject } from 'core/domain/project/repositories/projectsCRUD';

export const useProjectCRUD = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState({} as ProjectModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const { onSetData: onSetSharedGateways, filterGatewayTypeShared } = useSharedGateways();
  const { onSetData: onSetClientProject } = useClientProject();

  const getProjectById = async (id: string) => {
    setIsLoading(true);
    try {
      const project = await GetProjectById(projectId);
      if (project) {
        const { client, sharedGateways } = project;
        setProject(project);
        onSetClientProject(client);
        const gateways = filterGatewayTypeShared(sharedGateways);
        onSetSharedGateways(gateways);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsError(false);
      setIsLoading(false);
    }
  };

  const updateProject = async (project: UpdateProjectModel) => {
    setIsUpdating(true);
    try {
      await UpdateProject(project);
      message.success(t('_CUSTOMIZATION_PUBLISHED'));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    getProjectById(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return {
    data: project,
    getProjectById,
    updateProject,
    loading: isLoading,
    updating: isUpdating,
    error: isError,
  };
};
