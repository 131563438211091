import { PremiumUser } from 'core/domain/premiumUsers/model/premiumUser';
import { useState } from 'react';
import { GetPremiumUsersByIdClient } from '../core/domain/premiumUsers/repositories/getPremiumUsersByIdClient';

export const usePremiumUser = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<PremiumUser[]>([]);

  const getPremiumUsers = async () => {
    setIsLoading(true);
    try {
      const list = await GetPremiumUsersByIdClient();
      setDataList(list);
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false);
    }
  };

  return { dataList, getPremiumUsers, loading: isLoading };
}
