import { FC, useState } from 'react';
import TableAtom from 'components/atoms/TableAtom';
import { SensorColumns } from './SensorColumns';
import ModalAtom from 'components/atoms/ModalAtom';
import SpaceAtom from 'components/atoms/SpaceAtom';
import TextButton from 'components/atoms/Button/TextButton';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { Alert, Form, Input } from 'antd';
import { SensorEntityModel, SensorEditEntityModel } from 'core/domain/sensors/models/sensorEntityModels';
import './styles.scss';

const MAX_SIZE_RESPONSIVE_SCREEN = 600;
const FORM_ID = 'edit_sensor_form';

interface SensorTableProps {
  data: SensorEntityModel[];
  onEdit: (id: string, sensor: SensorEditEntityModel) => Promise<void>;
  loading: boolean;
}

const SensorTable: FC<SensorTableProps> = ({ data, onEdit, loading }) => {
  const { t } = useTranslation();
  const [isShowModal, setIsShowModal] = useState<boolean>(true);
  const [selectedSensor, setSelectedSensor] = useState<SensorEntityModel>();

  const handleOnEditName = (sensor: SensorEntityModel) => {
    setSelectedSensor(sensor);
    setIsShowModal(true);
  };

  const handleOnVisible = async (sensor: SensorEntityModel, visible: boolean) => {
    await onEdit(sensor.id, { canDataBeFetched: visible });
  };

  const handleOnCancel = () => {
    setIsShowModal(false);
  };

  const handleOnFinish = async ({ newName }: { newName: string }) => {
    selectedSensor && (await onEdit(selectedSensor.id, { name: newName }));
    setIsShowModal(false);
  };

  const columns = SensorColumns(handleOnEditName, handleOnVisible);

  return (
    <>
      <TableAtom<SensorEntityModel>
        key={'OptionList'}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        rowKey={'id'}
        pagination={{ pageSize: 50 }}
      />
      {selectedSensor && (
        <ModalAtom
          visible={isShowModal}
          title={<SpaceAtom>{t('EDIT_NAME')}</SpaceAtom>}
          onCancel={handleOnCancel}
          footer={[
            <TextButton key='back' onClick={handleOnCancel} title={t('to_cancel')} disabled={loading} />,
            <PrimaryButton key='submit' loading={loading} htmlType='submit' form={FORM_ID} title={t('save')} />,
          ]}
          destroyOnClose
        >
          <SpaceAtom direction={'vertical'}>
            <Alert message={t('EDIT_SENSOR_MODAL.TITLE')} description={t('EDIT_SENSOR_MODAL.INFO')} type={'info'} showIcon />
            <Form className={'SensorTable__form'} onFinish={handleOnFinish} id={FORM_ID} layout={'vertical'}>
              <Form.Item
                name={'newName'}
                label={`${t('NEW_NAME')}:`}
                rules={[{ required: true, message: t('field_required'), type: 'string' }]}
              >
                <Input placeholder={selectedSensor.name} />
              </Form.Item>
            </Form>
          </SpaceAtom>
        </ModalAtom>
      )}
    </>
  );
};

export default SensorTable;
