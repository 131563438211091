import { FC } from 'react';
import { Skeleton } from 'antd';
import './styles.scss';

const KpisLoadingTemplate: FC = () => {
  return (
    <div className='KpisLoadingTemplate__container'>
      <div className='KpisLoadingTemplate__header'>
        <Skeleton.Input className='KpisLoadingTemplate__header-skeleton' active />
      </div>
      <div className='KpisLoadingTemplate__content'>
        <div className='KpisLoadingTemplate__skeleton-wrapper'>
          <Skeleton.Input className='KpisLoadingTemplate__skeleton' active />
        </div>
        <div className='KpisLoadingTemplate__skeleton-wrapper'>
          <Skeleton.Input className='KpisLoadingTemplate__skeleton' active />
        </div>
        <div className='KpisLoadingTemplate__skeleton-wrapper'>
          <Skeleton.Input className='KpisLoadingTemplate__skeleton' active />
        </div>
        <div className='KpisLoadingTemplate__skeleton-wrapper'>
          <Skeleton.Input className='KpisLoadingTemplate__skeleton' active />
        </div>
        <div className='KpisLoadingTemplate__skeleton-wrapper'>
          <Skeleton.Input className='KpisLoadingTemplate__skeleton' active />
        </div>
      </div>
    </div>

  );
}

export default KpisLoadingTemplate;