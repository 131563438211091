import i18next from 'i18next';
import { SensorWithPasswordsParametersModel, SensorWithPasswordsPasswordModel } from 'core/domain/sensors/models/sensorEntityModels';

export enum PasswordType {
  ADMIN_PASSWORD = 'ADMIN_NUMERIC_PASSWORD',
  NFC_PASSWORD = 'NFC_CODE',
  USER_PASSWORD = 'NUMERIC_PASSWORD',
}

export enum PasswordBooleanType {
  VISIBILITY = 'isVisible',
  EDITABLE = 'isEditable',
}

export interface TogglePassword {
  label: PasswordType;
  sensorId: string;
  type: PasswordBooleanType;
}

export interface PasswordConfirmation {
  label: PasswordType;
  sensorId: string;
  value: string;
}

export interface PasswordDeletingType {
  sensorId: string;
  label: PasswordType;
}

export interface SensorPasswordModel extends SensorWithPasswordsPasswordModel {
  isVisible: boolean;
  isEditable: boolean;
  isFormatError: boolean;
}

export interface AccessPasswordDrawerTranslations {
  typeLabel: string;
  adminLabel: string;
  userLabel: string;
  nfcLabel: string;
  passwordLabel: string;
  addPasswordText: string;
  showPasswordText: string;
  hidePasswordText: string;
  deletePasswordText: string;
  errorFormatMessage: string;
  infoTitle: string;
  infoMinimumDigitsText: string;
  infoMaximumDigitsText: string;
  infoValidDigitsText: string;
  infoNfcTitle: string;
  infoNfcMinimumDigitsText: string;
  infoNfcMaximumDigitsText: string;
  infoNfcValidDigitsText: string;
  infoNfcExtraText: string;
  saveText: string;
  closeText: string;
}

interface SensorLabel {
  name?: string;
  room?: string;
}

export const getSensorLabel = ({ name, room }: SensorLabel) => {
  const nameLabel = name || '';
  const roomLabel = room || '';
  const label = !!roomLabel || !!nameLabel ? `${roomLabel} ${nameLabel}` : i18next.t('_NAME_NOT_REPORTED');
  return label.trim();
};

export const transformSensorToDrawerSensor = (sensor: SensorWithPasswordsPasswordModel, type: PasswordType): SensorPasswordModel => {
  const isEditable = type === PasswordType.NFC_PASSWORD ? false : true;
  return {
    ...sensor,
    isVisible: false,
    isEditable,
    isFormatError: false,
  };
};

export const transformSensorVisibility = (sensorId: string, sensors: SensorPasswordModel[]) => {
  return sensors.map((sensor) => {
    return sensor.sensorId === sensorId ? { ...sensor, isVisible: !sensor.isVisible } : sensor;
  });
};

export const editPassword = (sensorId: string, sensors: SensorPasswordModel[], password: string, isFormatError: boolean) => {
  return sensors.map((sensor) => {
    return sensor.sensorId === sensorId ? { ...sensor, password, isFormatError } : sensor;
  });
};

export const checkPasswordFormatErrors = (value: string, deviceParameters: SensorWithPasswordsParametersModel): boolean => {
  const { maxDigits, minDigits, validDigits } = deviceParameters;
  const isValidValue = value.split('').every((singleValue) => validDigits.includes(singleValue));
  const isMaximumDigitsError = Number(value.length) > Number(maxDigits);
  const isMinimumDigitsError = Number(value.length) < Number(minDigits);
  return !isValidValue || isMaximumDigitsError || isMinimumDigitsError;
};

export const checkNfcPasswordFormatErrors = (value: string, nfcParameters: SensorWithPasswordsParametersModel): boolean => {
  const { maxDigits, minDigits, validDigits } = nfcParameters;
  const isValidValue = value.split('').every((singleValue) => validDigits.includes(singleValue));
  const isPair = value.length % 2 === 0;
  const isMaximumDigitsError = Number(value.length) > Number(maxDigits);
  const isMinimumDigitsError = Number(value.length) < Number(minDigits);
  return !isValidValue || !isPair || isMaximumDigitsError || isMinimumDigitsError;
};

export const checkPasswordSensorsChanges = (allSensors: SensorPasswordModel[], initialSensors: SensorPasswordModel[]) => {
  return allSensors.some((sensor) => {
    const initialSelectedSensor = initialSensors.filter((initialSensor) => initialSensor.sensorId === sensor.sensorId)[0];
    return initialSelectedSensor.password !== sensor.password && !sensor.isFormatError;
  });
};

export const getChangedPasswordSensors = (allSensors: SensorPasswordModel[], initialSensors: SensorPasswordModel[]) => {
  return allSensors.filter((sensor) => {
    const initialSelectedSensor = initialSensors.filter((initialSensor) => initialSensor.sensorId === sensor.sensorId)[0];
    return initialSelectedSensor.password !== sensor.password;
  });
};

export const filterDeletedSensors = (
  sensorId: string,
  sensors: SensorPasswordModel[]
): { selectedSensor: SensorPasswordModel; filteredSensors: SensorPasswordModel[] } => {
  const selectedSensor = sensors.filter((sensor) => sensor.sensorId === sensorId)[0];
  const filteredSensors = sensors.filter((sensor) => sensor.sensorId !== sensorId);
  return { selectedSensor, filteredSensors };
};

export const transformDrawerSensorToSensor = (sensors: SensorPasswordModel[]): SensorWithPasswordsPasswordModel[] => {
  return sensors.map(({ deviceId, sensorId, gatewayId, password, sensorType }) => ({
    deviceId,
    sensorId,
    gatewayId,
    password,
    sensorType,
  }));
};
