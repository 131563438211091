import { Asset, AssetGatewaysQrs, AssetIncluded, AssetResponse } from 'models/assets.model';
import {
  AssetGatewaysQrsModel,
  AssetModel,
  AssetResponseModel,
  AssetGatewayConnectionStatusType,
  AssetIncludedModel,
} from '../models/assetsModel';

const payloadToAssetGatewayQrs = ({ gateway_id, qr_code }: AssetGatewaysQrs): AssetGatewaysQrsModel => {
  return {
    gatewayId: gateway_id ?? '',
    qrCode: qr_code ?? '',
  };
};

const payloadToAsset = ({
  id,
  alias,
  country,
  state,
  city,
  type,
  zip_code,
  street_type,
  street_name,
  block_number,
  staircase,
  logo,
  flat,
  door,
  created_at,
  updated_at,
  location,
  apartment,
  asset_qr,
  projects,
  project,
  description,
  gateways_qrs,
  is_occupied,
  gateway_connection_status,
  links,
}: Asset): AssetModel => {
  const transformedGatewaysQrs = gateways_qrs.map((item) => payloadToAssetGatewayQrs(item));

  return {
    id: id ?? '',
    alias: alias ?? '',
    country: country ?? '',
    state: state ?? '',
    city: city ?? '',
    type: type ?? '',
    zipCode: zip_code ?? '',
    streetType: street_type ?? '',
    streetName: street_name ?? '',
    blockNumber: block_number ?? '',
    staircase: staircase ?? '',
    logo: logo ?? '',
    flat: flat ?? '',
    door: door ?? '',
    createdAt: created_at ?? 0,
    updatedAt: updated_at ?? 0,
    location: location ?? '',
    apartment: apartment ?? '',
    assetQr: asset_qr ?? '',
    projects,
    project: project ?? '',
    description: description ?? '',
    gatewaysQrs: transformedGatewaysQrs ?? [],
    isOccupied: is_occupied ?? false,
    gatewayConnectionStatus: gateway_connection_status ?? AssetGatewayConnectionStatusType.NO_LINKED_GATEWAY,
    links,
  };
};

const payloadToAssetIncluded = ({ projects, users }: AssetIncluded): AssetIncludedModel => {
  return {
    projects: {
      id: projects.id ?? '',
    },
    users: users ?? [],
  };
};

export const payloadToAssetResponse = ({ data, included }: AssetResponse): AssetResponseModel => {
  const transformedData = payloadToAsset(data);
  const transformedIncluded = payloadToAssetIncluded(included);

  return {
    data: transformedData,
    included: transformedIncluded,
  };
};
