import { FC } from 'react';
import TabsAtom, { TabsAtomProps } from 'components/atoms/TabsAtom';
import { OfficeAccessesTabType, TabTranslations } from '../../resources/utils';

export interface OfficeTabsProps extends TabsAtomProps {
  translations: TabTranslations;
  activeOfficeKey: OfficeAccessesTabType;
}

export const OfficeTabs: FC<OfficeTabsProps> = ({
  activeOfficeKey,
  translations,
  ...props
}) => {
  const { logListText, accessDevicesListText } = translations;

  return (
    <TabsAtom defaultActiveKey={activeOfficeKey} activeKey={activeOfficeKey} {...props}>
      <TabsAtom.TabPane
        tab={<span>{logListText}</span>}
        key={OfficeAccessesTabType.LOG_LIST}
      />
      <TabsAtom.TabPane
        tab={<span>{accessDevicesListText}</span>}
        key={OfficeAccessesTabType.ACCESS_DEVICES_LIST}
      />
    </TabsAtom>
  );
};
