import { FC, ReactElement, useState } from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import AddMaintenanceActionDrawer from './AddMaintenanceActionDrawer';
import { Device } from 'models/devices.model';
import ContextualMenu from 'components/atoms/ContextualMenu';

interface DeviceMenuExtraProps {
  data: Device;
  gatewayId: string;
}

const DeviceMenuExtra: FC<DeviceMenuExtraProps> = ({ data, gatewayId }) => {
  const { t } = useTranslation();
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);

  const handleOnClick = () => {
    setIsDrawerVisible(true);
  };

  const handleOnClose = () => {
    setIsDrawerVisible(false);
  };

  const menuExtra: ReactElement = (
    <Menu>
      <Menu.Item key={'maintenanceActions'} onClick={handleOnClick}>
        {t('maintenance_actions')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <ContextualMenu menu={menuExtra} />
      <AddMaintenanceActionDrawer visible={isDrawerVisible} gatewayId={gatewayId} onClose={handleOnClose} deviceData={data} />
    </>
  );
};

export default DeviceMenuExtra;
