import { useEffect, useState } from 'react';
import { GetAssetTypeList } from 'core/domain/assets/repositories/getAssetTypeList';

export const useAssetType = () => {
  const [assetTypes, setAssetTypes] = useState<string[]>([]);

  useEffect(() => {
    GetAssetTypeList().then((response) => setAssetTypes(response));
  }, []);

  return { data: assetTypes };
};
