export const clientsListPath = () => {
  return '/clients';
};

export const clientPath = (clientId: string) => {
  return `/clients/${clientId}`;
};

export const updateClientPath = (clientId: string) => {
  return `/clients/${clientId}`;
};

export const getLegalDocumentationListByClientIdPath = (clientId: string) => {
  return `/clients/${clientId}/legal-documentations`;
};

export const getLegalDocumentationDetailByIdPath = (clientId: string, legalDocumentationId: string) => {
  return `/clients/${clientId}/legal-documentations/${legalDocumentationId}`;
};

export const createLegalDocumentationPath = (clientId: string) => {
  return `/clients/${clientId}/legal-documentations`;
};

export const deleteLegalDocumentationPath = (clientId: string) => {
  return `/clients/${clientId}/legal-documentations/delete`;
};

export const checkLegalDocumentationPath = () => {
  return 'clients/legal-documentations/status';
};
