import { FC, ReactNode } from 'react';
import Paper from '../../../atoms/Paper';
import SubTabTitles from './SubTabs/SubTabTitles';
import { ClientCustomizationSubTab, ClientNavigation, ClientTab } from '../resources/models';

interface Props {
  subTab: string;
  onSubTab: ({ activeTab, activeSubTab }: ClientNavigation) => void;
  children: { key: string; render: ReactNode }[];
}

const TabCustomization: FC<Props> = ({ subTab, onSubTab, children }) => {
  const handleOnChange = (activeSubTab: ClientCustomizationSubTab): void => {
    onSubTab({ activeTab: ClientTab.CUSTOMIZATION, activeSubTab });
  };

  return (
    <Paper>
      <SubTabTitles defaultActiveKey={subTab} onChange={(activeSubTab: any) => handleOnChange(activeSubTab)} />
      {children.map((element) => {
        return subTab === element.key && element.render;
      })}
    </Paper>
  );
};

export default TabCustomization;
