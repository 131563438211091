import { CameraFormValues } from 'components/organisms/AddCameraAction';
import { Camera } from 'core/domain/assets/models/camera';
import { CreateCamera } from 'core/domain/assets/repositories/createCamera';
import { GetCamerasList } from 'core/domain/assets/repositories/getCamerasList';
import { useCallback, useEffect, useState } from 'react';

export const useCameras = (assetId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [cameras, setCameras] = useState<Camera[]>([]);

  const onGet = useCallback((id: string) => {
    setIsLoading(true);
    GetCamerasList(id)
      .then((response) => {
        setCameras(response);
      })
      .catch((error) => {
        setIsError(true);
      })
      .finally(() => {
        setIsError(false);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    onGet(assetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const onSave = async (values: CameraFormValues) => {
    await CreateCamera(assetId, values);
    onGet(assetId);
  };

  return { data: cameras, onSave, loading: isLoading, error: isError };
};
