import { FC } from 'react';
import { TabCommonAreasAndBookingsMenuItem } from '../TabCommonAreasAndBookingsMenu';
import TabSidebarMenuListItem from './TabSidebarMenuListItem';
import './styles.scss';

interface TabSidebarMenuListProps {
  listItems: TabCommonAreasAndBookingsMenuItem[];
  selectedItemId?: string;
  onClick: (id: string) => void;
}

const TabSidebarMenuList: FC<TabSidebarMenuListProps> = ({ listItems, selectedItemId, onClick }) => {
  return (
    <ul className={'TabSidebarMenuList'}>
      {listItems.map(({ id, name, disabled }, key) => {
        return (
          <TabSidebarMenuListItem
            key={key}
            itemName={name}
            id={id}
            selected={id === selectedItemId}
            onClick={disabled ? undefined : onClick}
            disabled={disabled}
          />
        );
      })}
    </ul>
  );
};

export default TabSidebarMenuList;
