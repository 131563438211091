import { FC } from 'react';
import { Svg } from '../model/svg';

const AlfredLogo: FC<Svg> = ({ width = '14', height = '14', style, ...props }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' style={style} {...props}>
    <path
      d='M7.14648 6.09404C6.1205 6.51179 5.63383 7.66857 6.05833 8.67824C6.48283 9.68792 7.65827 10.1669 8.68424 9.74911C9.22832 9.52787 9.61935 9.096 9.80469 8.59116L15.151 6.52474C15.0482 6.18818 14.9561 5.87869 14.8365 5.57979C13.1851 1.45047 9.4053 -1.22787 4.76808 0.567892C0.59961 2.18243 -1.00751 6.6942 0.633097 10.7964L0.65462 10.8506C2.42556 15.2788 6.8882 17.0334 11.1117 15.3976C12.5215 14.8516 13.5989 14.0949 14.4108 13.1853C14.8042 12.744 15.1175 12.2545 15.139 12.2203C15.5863 11.5213 15.8457 10.8741 16 10.3987C15.1187 10.081 14.2374 9.76323 13.3561 9.4455C12.6423 11.0083 11.7215 12.0556 10.0929 12.6852C8.26573 13.3924 6.4039 13.0264 5.09094 11.5578C4.98691 11.4578 4.8769 11.3283 4.7621 11.1577C4.75254 11.1424 4.74417 11.1283 4.73341 11.1142C4.63177 10.973 4.45001 10.667 4.45001 10.667C3.41925 8.94772 3.40251 7.71564 3.40251 7.71564C3.16455 5.69982 4.00877 3.9676 5.81798 3.26742C8.02657 2.4119 9.95894 3.57809 11.0268 5.61156L9.13147 6.34587C8.57783 5.93753 7.82927 5.81632 7.14648 6.09404Z'
      fill='#505050'
    />
  </svg>
);

export default AlfredLogo;
