import i18n from 'config/i18n';
import { InvitationStatusType } from 'core/domain/anonymousAccess/mappers';
import { CheckInHistoryDataModel } from 'core/domain/hotels/models';
import { checkIfDateHasExpired, formatSpanishDateWithSlash, formatTimeDate } from 'utils/date';

export const digitalKeyText = i18n.t('_CHECK_IN_LIST_COLUMNS_DIGITAL_KEY_TEXT');
export const physicalKeyText = i18n.t('_CHECK_IN_LIST_COLUMNS_PHYSICAL_KEY_TEXT');
export const notReportedText = i18n.t('not_reported');

export const checkInLIstLiterals = {
  keyReaderButton: i18n.t('_ACCESS_BUTTON_READ_KEY'),
}

export const qrReaderModalLiterals = {
  title: i18n.t('_ACCESSES_HOTEL_MODAL_TITLE_QR'),
};

export const keyReaderLiterals = {
  title: i18n.t('_ACCESSES_HOTEL_DRAWER_READ_KEY_TITLE'),
  infoText: i18n.t('_ACCESSES_HOTEL_DRAWER_READ_KEY_INFO_TEXT'),
  infoTitle: i18n.t('_ACCESSES_HOTEL_DRAWER_READ_KEY_INFO_TITLE'),
  nfcCodeLabel: i18n.t('_ACCESSES_HOTEL_DRAWER_READ_KEY_TITLE'),
  emptyStateTitle: i18n.t('_ACCESSES_READER_EMPTY_TITLE'),
  emptyStateText: i18n.t('_ACCESSES_READER_EMPTY_TEXT'),
  digitalKey: i18n.t('_ACCESSES_READER_KEYS_LABEL'),
  keysLabel: i18n.t('_ACCESSES_READER_KEYS_LABEL'),
  roomsLabel: i18n.t('_ACCESSES_READER_ROOMS_LABEL'),
  validLabel: i18n.t('_ACCESSES_READER_VALID_LABEL'),
  closeButtonText: i18n.t('close'),
  selectedRoomText: i18n.t('_ACCESSES_HOTEL_DRAWER_SELECTED_ROOM_LABEL'),
  digitalKeyLabel: i18n.t('_ACCESSES_HOTEL_DRAWER_DIGITAL_KEY_LABEL'),
  checkoutButtonText: i18n.t('_ACCESSES_HOTEL_CHECKOUT_BUTTON_TEXT'),
};

export const literalsColumnsCheckInList = {
    nameText: i18n.t('_CHECK_IN_LIST_COLUMNS_NAME_TEXT'),
    keysTypeText: i18n.t('_CHECK_IN_LIST_COLUMNS_KEYS_TYPE_TEXT'),
    validityText: i18n.t('_CHECK_IN_LIST_COLUMNS_VALIDITY_TEXT'),
    copyLinkButtonText: i18n.t('_CHECK_IN_LIST_COLUMNS_ACTIONS_COPY_LINK_TEXT'),
    statusText: {
      [InvitationStatusType.ACTIVE]: i18n.t('_ACCESS_STATUS_ACTIVE'),
      [InvitationStatusType.EXPIRED]: i18n.t('_ACCESS_STATUS_EXPIRED'),
      [InvitationStatusType.REVOKED]: i18n.t('_ACCESS_STATUS_REVOKED'),
    },
    checkInButton: i18n.t('_ACCESS_BUTTON_CHECK_IN'),
    statusTitle: i18n.t('_CHECK_IN_LIST_COLUMNS_STATUS_TEXT'),
    checkoutButtonText: i18n.t('_ACCESSES_HOTEL_CHECKOUT_BUTTON_TEXT'),
};

export const checkoutRoomModalLiterals = {
  title: i18n.t('_ACCESSES_HOTEL_CHECK_OUT_MODAL_TITLE'),
  description: i18n.t('_ACCESSES_HOTEL_CHECK_OUT_MODAL_DESCRIPTION'),
  actionText: i18n.t('_ACCESSES_HOTEL_CHECK_OUT_MODAL_ACTION'),
  cancelButtonText: i18n.t('_ACCESSES_HOTEL_CHECK_OUT_MODAL_CANCEL'),
  acceptButtonText: i18n.t('_ACCESSES_HOTEL_CHECK_OUT_MODAL_CONTINUE'),
}

export interface CustomCheckInDevice {
  label: string;
}

export interface CheckInHistoryCustomDataModel extends CheckInHistoryDataModel {
  createdAtText: string;
  validFromText: string;
  validUntilText: string;
  isExpired: boolean;
  isRevoked: boolean;
}

const transformCheckInToCustomCheckIn = ({
  id,
  isDigital,
  isNfc,
  nfcCodes,
  reservationId,
  state,
  createdAt,
  url,
  validFrom,
  validUntil,
  assets,
}: CheckInHistoryDataModel): CheckInHistoryCustomDataModel => {
  const createdAtText = formatTimeDate(createdAt);
  const validFromText = formatSpanishDateWithSlash(validFrom);
  const validUntilText = formatSpanishDateWithSlash(validUntil);
  const isRevoked = (state as unknown as InvitationStatusType) === InvitationStatusType.REVOKED;
  const isExpired = checkIfDateHasExpired(validUntil);

  return {
    id,
    isDigital,
    isNfc,
    nfcCodes,
    reservationId,
    state,
    createdAt,
    createdAtText,
    url,
    validFrom,
    validFromText,
    validUntil,
    validUntilText,
    assets,
    isExpired,
    isRevoked,
  };
};

export const transformCheckInListToCustomData = (checkInList: CheckInHistoryDataModel[]): CheckInHistoryCustomDataModel[] => {
  return checkInList.map((item) => transformCheckInToCustomCheckIn(item));
};

const ID_CONCAT_ACCESS_SENSOR = '######';
const SEED_PASS_CODE = 'MYBScaQqBqo5EX4$wiGAt@';

const encrypt = (input: string): string => {
    return Buffer.from(input).toString('base64');
};

const generateUidNfcPassCode = (nfcPassCode: string): string => {
    return `${SEED_PASS_CODE}${ID_CONCAT_ACCESS_SENSOR}${nfcPassCode}`;
};

export const encryptNfcPassCode = (nfcPassCode: string): string => {
  const uidNfcPassCode = generateUidNfcPassCode(nfcPassCode);
  return encrypt(uidNfcPassCode);
};
