import { DocumentModel } from '../model/documentsEntityModels';

const DocumentsEntity = ({ id, title, comments, url, size, source, createdAt }: DocumentModel): Readonly<DocumentModel> => {
  return Object.freeze({
    id,
    title: title ?? '',
    comments: comments ?? '',
    url: url ?? '',
    size,
    source: source ?? '',
    createdAt,
  });
};

export default DocumentsEntity;
