import api from 'services/api';
import { sensorsProjectListPath } from 'core/endspoints/sensors';
import { SensorsProjectListValueObject } from '../entities/sensorsProjectListValueObject';
import { payloadToSensorsByProject } from '../mappers/payloadToSensorsByProject';
import { SensorByProjectModel } from '../models/sensorEntityModels';
import { SensorPayloadModel } from '../models/sensorPayloadModels';

export const GetSensorsListByProjectId = async (projectId: string): Promise<SensorByProjectModel[] | undefined> => {
  try {
    const path = sensorsProjectListPath(projectId);
    api.defaults.headers.common['Accept-version'] = '1';
    const { data } = await api.get<SensorPayloadModel[]>(path);
    const devicesData = data.map((item) => payloadToSensorsByProject(item));
    const { list } = SensorsProjectListValueObject(devicesData);
    return list;
  } catch (error) {
    console.error(error);
  }
};
