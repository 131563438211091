import { FC, useEffect, useRef } from 'react';
import { Chart, registerables, ChartConfiguration } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';

interface AsChartProps {
  configuration: ChartConfiguration;
  resetZoom?: (reset: any) => void;
  canvasHeight?: string;
  canvasWidth?: string;
}

Chart.register(...registerables, zoomPlugin);

const AsChart: FC<AsChartProps> = ({
  configuration,
  resetZoom,
  canvasWidth = '800',
  canvasHeight = '300'
}) => {
  const chartRef = useRef<any>(null);

  useEffect(() => {
    const myChart = new Chart(chartRef.current, configuration);
    myChart.render();

    return () => {
      myChart.destroy();
    };
  }, [configuration]);

  return <canvas ref={chartRef} width={canvasWidth} height={canvasHeight} />;
};

export default AsChart;
