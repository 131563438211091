import { LegalDocumentationCreateModel } from '../models/clientEntityModel';
import { LegalDocumentationToBeSentPayloadModel } from '../models/clientPayloadModel';

export const legalDocumentationToPayload = ({
  privacyPolicy,
  privacyPolicyName,
  termsOfService,
  termsOfServiceName,
  enabled,
}: LegalDocumentationCreateModel): LegalDocumentationToBeSentPayloadModel => {
  return {
    privacy_policy: privacyPolicy,
    privacy_policy_name: privacyPolicyName,
    terms_of_service: termsOfService,
    terms_of_service_name: termsOfServiceName,
    enabled,
  };
};
