export const gatewaysPath = (gatewayId: string) => {
  return `/gateways/${gatewayId}`;
};

export const createMaintenanceActionPath = (gatewayId: string, actionType: string) => {
  return `/gateways/${gatewayId}/maintenance/${actionType}`;
};

export const getActiveGatewaysStatisticsPath = () => {
  return '/gateways/statistics/online';
};
