export enum CustomErrorCodes {
  SOME_ERROR = 800,
  REQUEST_SOME_ERROR = 801,
}

export interface RequestErrorModel {
  code: number | 'ECONNABORTED';
  message: string;
}

export const getErrorFormat = (error: any): RequestErrorModel => {
  if (error.response) {
    // Check if it's a network response error (out of 2xx range).
    if (error.response.status === 'ECONNABORTED') {
      // Handle timeout error
      return { code: 'ECONNABORTED', message: 'Timeout response' };
    }

    // Handle other network response errors
    return { code: error.response.status, message: error.response.data };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser or http.ClientRequest in Node.js
    console.warn(error.request);
    return { code: CustomErrorCodes.REQUEST_SOME_ERROR, message: 'The request was made but no response was received' };
  } else if (isErrorOfTypeRequestError(error)) {
    // If the error is already a RequestErrorModel then
    // return the error object as it is.
    return error;
  } else {
    // Something went wrong while preparing the request that threw an Error
    console.warn('Error', error.message);
    return { code: CustomErrorCodes.SOME_ERROR, message: 'Something happened while preparing the request that threw an Error' };
  }
};

// Type guard function for custom request error types
function isErrorOfTypeRequestError(error: any): error is RequestErrorModel {
  return 'code' in error && 'message' in error;
}
