import { useEffect, useState } from 'react';
import { message } from 'antd';
import { BookingModel } from 'core/domain/bookingsAndCommonAreas/models/bookingEntityModels';
import {
  CommonAreaModel,
  CreateCommonAreaModel,
  UpdateCommonAreaModel,
} from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { useBookings } from 'hooks/useBookings';
import { useCommonAreasCRUD } from 'hooks/useCommonAreasCRUD';
import { useSensorsProject } from 'hooks/useSensorsProject';
import { useMenuListItems } from './useMenuListItems';
import { FormValuesResponse } from 'components/molecules/CommonAreaForm/models';
import { DeleteBooking } from 'core/domain/bookingsAndCommonAreas/repositories/deleteBooking';
import { useTranslation } from 'react-i18next';

export enum CommonAreasAndBookingsVisorViews {
  CONTENT = 'CONTENT',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  CONTENT_BOOKINGS = 'CONTENT_BOOKINGS',
  EMPTY_BOOKINGS = 'EMPTY_BOOKINGS',
  EMPTY = 'EMPTY',
}

export const useCommonAreasAndBookings = ({ projectId }: { projectId: string }) => {
  const { t } = useTranslation();
  const [commonAreasList, setCommonAreasList] = useState<CommonAreaModel[]>([]);
  const [bookingList, setBookingList] = useState<BookingModel[]>([]);
  const [selectedCommonArea, setSelectedCommonArea] = useState<CommonAreaModel>({} as CommonAreaModel);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [visibleType, setVisibleType] = useState<CommonAreasAndBookingsVisorViews>(CommonAreasAndBookingsVisorViews.CONTENT_BOOKINGS);
  const { menuListItems, onSetMenuListItems, disabledItemsMenu, enabledItemsMenu, disabledItemMenu } = useMenuListItems();
  const { sensorList } = useSensorsProject({ projectId });
  const { getBookingList } = useBookings();
  const { getCommonAreasList, getCommonArea, createCommonArea, updateCommonArea, enableCommonArea, removeCommonArea } =
    useCommonAreasCRUD();

  const fallback = async (
    selectProjectId: string,
    selectCommonAreaId?: string
  ): Promise<{
    areas: CommonAreaModel[] | undefined;
    area: CommonAreaModel | undefined;
  }> => {
    const refreshAreas = await getCommonAreasList({ projectId: selectProjectId });

    const commonAreaId: string | undefined = selectCommonAreaId || (!!refreshAreas?.length ? refreshAreas[0].id : undefined);
    const area: CommonAreaModel | undefined = refreshAreas?.find((item) => commonAreaId === item.id);

    if (commonAreaId && !area) {
      const refreshArea = await getCommonArea({ commonAreaId });
      return { areas: refreshAreas, area: refreshArea };
    }

    return { areas: refreshAreas, area };
  };

  useEffect(() => {
    setIsLoading(true);
    getBookingList({ projectId })
      .then((bookingsList) => {
        bookingsList && setBookingList(bookingsList);
        !!bookingsList?.length
          ? setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT_BOOKINGS)
          : setVisibleType(CommonAreasAndBookingsVisorViews.EMPTY_BOOKINGS);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setIsLoading(true);
    fallback(projectId)
      .then(({ areas }) => {
        areas && setCommonAreasList(areas);
        areas && onSetMenuListItems(areas);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onUpdateArea = async ({ id, values }: { id: string; values: FormValuesResponse }): Promise<void> => {
    setIsSaving(true);
    const commonArea: Omit<UpdateCommonAreaModel, 'enabled' | 'hasBooking'> = {
      ...values,
      timeAvailability: values.timeAvailability,
      maximumTimeAvailability: { timePeriod: values.maximumTimeAvailabilityPeriod, value: Number(values.maximumTimeAvailabilityValue) },
      maximumBookingsByUser: Number(values.maximumBookingsByUser),
      id,
      backgroundImage: values.backgroundImage || null,
    };

    try {
      await updateCommonArea(commonArea);
      const { areas, area } = await fallback(projectId, id);
      areas && onSetMenuListItems(areas);
      areas && setCommonAreasList(areas);
      area && setSelectedCommonArea(area);
      setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT);
      const messageSuccess = t('_COMMON_AREA_MESSAGE_SAVED');
      message.success(messageSuccess);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsSaving(false);
    }
  };

  const onCreateArea = async (values: FormValuesResponse): Promise<void> => {
    setIsSaving(true);
    const newCommonArea: CreateCommonAreaModel = {
      ...values,
      timeAvailability: values.timeAvailability,
      maximumTimeAvailability: { timePeriod: values.maximumTimeAvailabilityPeriod, value: Number(values.maximumTimeAvailabilityValue) },
      maximumBookingsByUser: Number(values.maximumBookingsByUser),
      backgroundImage: values.backgroundImage || null,
    };

    try {
      await createCommonArea({ projectId, newCommonArea });
      const { areas, area } = await fallback(projectId);
      areas && onSetMenuListItems(areas);
      areas && setCommonAreasList(areas);
      area && setSelectedCommonArea(area);
      area ? setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT) : setVisibleType(CommonAreasAndBookingsVisorViews.EMPTY);
      const messageSuccess = t('_COMMON_AREA_MESSAGE_CREATED');
      message.success(messageSuccess);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsSaving(false);
    }
  };

  const onRemoveArea = async (id: string): Promise<void> => {
    setIsLoading(true);
    try {
      await removeCommonArea({ id });
      const { areas, area } = await fallback(projectId);
      areas && onSetMenuListItems(areas);
      areas && setCommonAreasList(areas);
      area && setSelectedCommonArea(area);
      area ? setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT) : setVisibleType(CommonAreasAndBookingsVisorViews.EMPTY);
      const messageSuccess = t('_COMMON_AREA_MESSAGE_REMOVED');
      message.success(messageSuccess);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteBooking = async (bookingId: string): Promise<void> => {
    setIsLoading(true);
    try {
      await DeleteBooking(bookingId);
      await onVisibleBookingList();
      const messageSuccess = t('_BOOKING_DELETE_MESSAGE_SUCCESS');
      message.success(messageSuccess);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectArea = async (selectedId: string): Promise<void> => {
    const area = commonAreasList.find((item) => selectedId === item.id);
    area && setSelectedCommonArea(area);
    area ? setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT) : setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT);

    if (!area) {
      setIsLoading(true);
      try {
        const area = await getCommonArea({ commonAreaId: selectedId });
        area && setSelectedCommonArea(area);
        area && setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onSelectedFirstItemArea = (): void => {
    !!commonAreasList.length && setSelectedCommonArea(commonAreasList[0]);
  };

  const onUnselectedArea = (): void => {
    setSelectedCommonArea({} as CommonAreaModel);
  };

  const onEnableArea = async (checked: boolean): Promise<void> => {
    setIsSaving(true);
    const filteredSensors: string[] = selectedCommonArea.sensors.map((sensor) => sensor.id);
    const maximumBookingsByUser = selectedCommonArea.maximumBookingsByUser || 1000;
    const maximumTimeAvailability = selectedCommonArea.maximumTimeAvailability || { timePeriod: 'month', value: 6 };
    const transformedSelectedCommonArea: UpdateCommonAreaModel = selectedCommonArea && {
      ...selectedCommonArea,
      maximumBookingsByUser,
      maximumTimeAvailability,
      sensors: filteredSensors,
    };

    try {
      transformedSelectedCommonArea && (await enableCommonArea({ commonArea: transformedSelectedCommonArea, checked }));
      const { areas, area } = await fallback(projectId, selectedCommonArea?.id);
      areas && onSetMenuListItems(areas);
      areas && setCommonAreasList(areas);
      area && setSelectedCommonArea(area);
      const messageSuccess = checked ? t('_COMMON_AREA_ENABLED') : t('_COMMON_AREA_DISABLED');
      message.success(messageSuccess);
    } finally {
      setIsSaving(false);
    }
  };

  const onVisibleUpdate = (): void => {
    !!selectedCommonArea.id && disabledItemMenu(selectedCommonArea.id);
    setVisibleType(CommonAreasAndBookingsVisorViews.UPDATE);
  };

  const onInvisibleUpdate = (): void => {
    enabledItemsMenu();
    !!selectedCommonArea.id
      ? setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT)
      : setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT);
  };

  const onVisibleCreate = (): void => {
    setSelectedCommonArea({} as CommonAreaModel);
    disabledItemsMenu();
    setVisibleType(CommonAreasAndBookingsVisorViews.CREATE);
  };

  const onInvisibleCreate = (): void => {
    enabledItemsMenu();
    !!bookingList.length
      ? setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT_BOOKINGS)
      : setVisibleType(CommonAreasAndBookingsVisorViews.EMPTY_BOOKINGS);
  };

  const onVisibleBookingList = async (): Promise<void> => {
    setIsLoading(true);
    try {
      setSelectedCommonArea({} as CommonAreaModel);
      enabledItemsMenu();
      const response = await getBookingList({ projectId });
      response && setBookingList(response);
      !!response?.length
        ? setVisibleType(CommonAreasAndBookingsVisorViews.CONTENT_BOOKINGS)
        : setVisibleType(CommonAreasAndBookingsVisorViews.EMPTY_BOOKINGS);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    bookingList,
    menuListItems,
    selectedCommonArea,
    sensorList,
    visibleType,
    onCreateArea,
    onEnableArea,
    onInvisibleCreate,
    onInvisibleUpdate,
    onRemoveArea,
    onSelectArea,
    onSelectedFirstItemArea,
    onUnselectedArea,
    onUpdateArea,
    onVisibleBookingList,
    onVisibleCreate,
    onVisibleUpdate,
    onDeleteBooking,
    isLoading,
    isSaving,
  };
};
