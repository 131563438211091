import { Menu } from 'antd';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { IntercomModel } from 'core/domain/intercoms/model/intercomModel';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { hasIntercomsDeletingPermission, hasIntercomsUpdatingPermission } from 'components/pages/ProjectPage/Tabs/TabIntercoms/resources/config';

interface Props {
  onEditIntercom: (intercom: IntercomModel) => void;
  onDeleteIntercom: (intercomId: string) => void;
  name: string;
  provider: string;
  edit: string;
  deleteText: string;
}

export const ProjectIntercomsColumns = ({
  onEditIntercom,
  onDeleteIntercom,
  name,
  provider,
  edit,
  deleteText,
}: Props
): AlfredTableColumn<IntercomModel>[] => {
  return [
    {
      title: name,
      key: 'name',
      width: '40%',
      render: (text, record) => <div>{record.name}</div>,
    },
    {
      title: provider,
      key: 'vendor',
      width: '30%',
      render: (text, record) => <div>{record.vendor}</div>,
    },
    {
      key: 'actions',
      width: '5%',
      render: (text, record) => {
        return (
          <>
            {(hasIntercomsUpdatingPermission || hasIntercomsDeletingPermission) && (
              <ContextualMenu placement={'topRight'} menu={
                <Menu>
                  {hasIntercomsUpdatingPermission && (
                    <Menu.Item key={'edit'} onClick={() => onEditIntercom(record)}>
                      {edit}
                    </Menu.Item>
                  )}
                  {hasIntercomsDeletingPermission && (
                    <Menu.Item key={'notify'} onClick={() => onDeleteIntercom(record.id)}>
                      {deleteText}
                    </Menu.Item>
                  )}
                </Menu>
              } />
            )}
          </>
        );
      },
    },
  ];
};
