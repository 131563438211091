import { GatewayParsed } from 'core/domain/gateways/model/gateway/gatewayParsed';
import { ReportMaintenance } from 'core/domain/gateways/model/reportMaintenance';
import CreateGatewayMaintenanceAction from 'core/domain/gateways/repositories/createGatewayMaintenanceAction';
import { GetGatewayDetail } from 'core/domain/gateways/repositories/getGatewayDetail';
import { useEffect, useState } from 'react';

export const useGateway = (gatewayId?: string) => {
  const [gateway, setGateway] = useState<GatewayParsed>({} as GatewayParsed);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (gatewayId) {
      let isMounted = true;
      GetGatewayDetail(gatewayId)
        .then((response) => {
          if (isMounted) {
            setGateway(response);
          }
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false);
          setIsError(true);
        });

      return () => {
        isMounted = false;
      };
    }
  }, [gatewayId]);

  const onSaveAction = async (gatewayId: string, action: ReportMaintenance): Promise<void> => {
    setIsLoading(true);
    try {
      await CreateGatewayMaintenanceAction(gatewayId, action);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data: gateway,
    loading: isLoading,
    error: isError,
    onSaveAction,
  };
};
