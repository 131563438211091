import { FC } from 'react';
import { Icon } from 'components/atoms/Icons';
import { EventsAndNewsModel } from 'core/domain/eventsAndNews/models/eventsAndNewsEntityModels';
import './styles.scss';
import { useTranslation } from 'react-i18next';

interface EventsAndNewsContentGridProps {
  eventsAndNewsData: EventsAndNewsModel;
}

const EventsAndNewsContentGrid: FC<EventsAndNewsContentGridProps> = ({ eventsAndNewsData: { title, image, description, link, linkTitle, sendNotification } }) => {
  const { t } = useTranslation();
  const buttonText = linkTitle || t('_EVENTS_AND_NEWS_SEE_MORE');

  return (
    <div className={'EventsAndNewsContentGrid__container'}>
      <p className={'EventsAndNewsContentGrid__title'}>
        {title}
      </p>
      <img
        className={'EventsAndNewsContentGrid__image'}
        alt={title}
        src={image}
      />
      <p className={'EventsAndNewsContentGrid__description'}>
        {description}
      </p>
      {link && (
        <div className={'EventsAndNewsContentGrid__button'}>
          <div className={'EventsAndNewsContentGrid__link-button'}>
            <a
              href={link}
              target={'_blank'}
              rel={'noreferrer'}
            >
              {buttonText}
            </a>
            <span className={'EventsAndNewsContentGrid__icon'}>
              <Icon.Link />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default EventsAndNewsContentGrid;
