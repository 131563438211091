import MenuItem from 'components/atoms/MenuItem';
import { Component, Children } from 'react';
import './styles.scss';

interface MenuProps {
  onClick: (key: string) => void;
  className?: string;
  children: any;
  activeKey: string;
  mode?: 'vertical' | 'horizontal';
}

class Menu extends Component<MenuProps> {
  static Item = MenuItem;

  handleOnClick(key: string) {
    this.props.onClick(key);
  }

  render() {
    const childrenWithOnClick = Children.map(this.props.children, (child) => {
      const activeItem = this.props.activeKey === child.key;

      return (
        !child.props.hidden && (
          <li
            className={`${child.props.className} ${activeItem && 'Menu__Item--selected'}`}
            onClick={() => this.handleOnClick(child.key)}
          >
            <span className={'Menu__Icon'}>{child.props.icon}</span>
            <span>{child}</span>
          </li>
        )
      );
    })

    const isVerticalMode = this.props.mode === 'vertical'

    return (
      <ul 
        className={`Menu ${isVerticalMode ? 'Menu--column' : 'Menu--row'} ${this.props.className}`}
      >
        {childrenWithOnClick}
      </ul>
    );
  };
};


export default Menu;
