import { RouteConfig } from 'react-router-config';
import SettingsPage from 'components/pages/SettingsPage';
import ClientDetailPage from 'components/pages/ClientDetailPage';
import { ROUTE_PATH_SETTINGS_CLIENTS, ROUTE_PATH_SETTINGS_CLIENT_SUB_TAB, ROUTE_PATH_SETTINGS_CLIENT_TAB } from './config';

export const clientsRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_SETTINGS_CLIENTS,
    exact: true,
    component: SettingsPage,
  },
  {
    path: ROUTE_PATH_SETTINGS_CLIENT_TAB,
    exact: true,
    component: ClientDetailPage,
  },
  {
    path: ROUTE_PATH_SETTINGS_CLIENT_SUB_TAB,
    exact: true,
    component: ClientDetailPage,
  },
];
