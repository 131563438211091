import { FC } from 'react';
import AsChart from 'components/atoms/AsChart';
import { configuration } from './resources/config';
import { UsersTranslationsTypes } from 'components/pages/DashboardPage/resources/translations';
import { UsersListUnstructuredStatisticsModel } from 'core/domain/users/models/usersModel';
import './styles.scss';

interface Props {
  statistics: UsersListUnstructuredStatisticsModel;
  translations: UsersTranslationsTypes;
}

const DashboardUsersTemplate: FC<Props> = ({ statistics, translations }) => {
  const { average, labels, values } = statistics;
  const { data: { datasets } } = configuration;
  const data = { datasets, labels };
  data.datasets[0].data = values;

  return (
    <div className='UsersTemplate__container'>
      <div className='UsersTemplate__header'>
        <div>
          <p className='UsersTemplate__title'>{translations.TITLE}</p>
          <p className='UsersTemplate__subtitle'>{translations.TITLE_TIME_PERIOD}</p>
        </div>
        <p className='UsersTemplate__average'>{`${average} ${translations.USERS_AVERAGE.toLowerCase()}`}</p>
      </div>
      <div className='UsersTemplate__chart-container'>
        <div className='UsersTemplate__chart-content'>
          <AsChart
            configuration={{ ...configuration, data }}
            canvasWidth={'700'}
            canvasHeight={'260'}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardUsersTemplate;