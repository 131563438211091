export const COLORS = {
  alert: '#eb6759',
  black_01: 'rgba(0, 0, 0, 0.1)',
  black_25: 'rgba(0, 0, 0, 0.25)',
  text: '#505050',
  textSubtitle: '#97999b',
  corporate: '#3919ED',
  gray_extra_light: '#c1c1c1',
  gray: '#53565a',
  green_flat: '#b0ffd0',
  link: '#2d78ea',
  orange: '#f39c12',
  success: '#34d999',
  white: '#fff',
  purple_flat: '#8E2CFF',
  orange_flat: '#ff8273',
  blue_flat: '#38cdff',
  red_flat: '#d25d8c',
};
