import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingModel } from 'core/domain/bookingsAndCommonAreas/models/bookingEntityModels';
import BookingListTable from 'components/organisms/BookingListTable';
import { useModalStatus } from 'components/molecules/CommonAreaForm/hooks/useModalStatus';
import CommonAreaDeleteConfirmModal from 'components/molecules/CommonAreaBookingDeleteModal';

interface BookingListTemplateProps {
  bookingListData: BookingModel[];
  onDeleteBooking: (bookingId: string) => void
}

const BookingListTemplate: FC<BookingListTemplateProps> = ({ bookingListData, onDeleteBooking }) => {
  const { t } = useTranslation();
  const { configModal, onLoadAndConfigModal, onConfirm, onCancel, isVisible, isLoading } = useModalStatus();

  const handleOnConfirmDeleteBooking = (id: string) => {
    onLoadAndConfigModal(id, {
      title: t('_BOOKING_DELETE_MODAL_TITLE'),
      text: t('_BOOKING_DELETE_MODAL_INFO'),
      buttonSuccess: t('_BOOKING_DELETE_MODAL_BUTTON'),
      onConfirm: () => onDeleteBooking(id),
    });
  };

  return (
    <>
      <BookingListTable bookingListData={bookingListData} onDeleteBooking={handleOnConfirmDeleteBooking} />
      <CommonAreaDeleteConfirmModal
        onCancel={onCancel}
        onClickConfirm={onConfirm}
        isLoading={isLoading}
        isVisible={isVisible}
        {...configModal}
      />
    </>
  )
};

export default BookingListTemplate;
