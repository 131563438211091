import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Space } from 'antd';
import DrawerAtom from 'components/atoms/DrawerAtom';
import TextAtom from 'components/atoms/TextAtom';
import { SPACING } from 'constants/spacing';
import { useWindowSize } from 'hooks/useWindowSize';
import { AreaType } from 'core/domain/projectLayoutConfig/model/projectLayoutConfigModels';
import { CloseIcon, SecondarySmallIconFlatButton } from '@alfred_developes/alfred-ui-web-components';
import { ProjectConfigAreaForm, ProjectConfigAreaFormValues } from 'components/molecules/ProjectConfigAreaForm';
import './styles.scss';

interface ProjectConfigAreaDrawerProps {
  title: string;
  initialValues?: ProjectConfigAreaFormValues;
  onCancel: () => void;
  onAdd: (values: any) => void;
  onEdit: (values: any) => void;
  open: boolean;
  loading: boolean;
}

const LARGE_SCREEN = 768;
const FORM_ID = 'form_projectConfigArea';

export const ProjectConfigAreaDrawer: FC<ProjectConfigAreaDrawerProps> = ({
  title,
  initialValues,
  onCancel,
  onAdd,
  onEdit,
  open,
  loading,
}) => {
  const { t } = useTranslation();
  const { width: windowsWidth } = useWindowSize();
  const areaTypeList = [AreaType.VERTICAL, AreaType.HORIZONTAL];
  const isLargeScreen = windowsWidth >= LARGE_SCREEN;

  return (
    <DrawerAtom
      headerStyle={{ padding: `${SPACING.l} ${SPACING.xl}` }}
      title={
        <div className={'ProjectConfigAreaDrawer__header-wrapper'}>
          <TextAtom>{title}</TextAtom>
          <SecondarySmallIconFlatButton Icon={CloseIcon} onClick={onCancel} />
        </div>
      }
      destroyOnClose={true}
      closable={false}
      size={isLargeScreen ? 'large' : 'default'}
      placement={'right'}
      onClose={onCancel}
      maskClosable={false}
      open={open}
      keyboard={false}
      footer={[
        <Row className='ProjectConfigAreaDrawer__footer'>
          <Space size={16}>
            <Button key={'back'} onClick={onCancel}>
              {t('to_cancel')}
            </Button>
            <Button
              form={FORM_ID}
              key={'submit'}
              type={'primary'}
              htmlType={'submit'}
              loading={loading}
            >
              {t('save')}
            </Button>
          </Space>
        </Row>
      ]}
    >
      <ProjectConfigAreaForm
        id={FORM_ID}
        areaTypeList={areaTypeList}
        initialValues={initialValues}
        onFinish={initialValues ? onEdit : onAdd}
        preserve={false}
      />
    </DrawerAtom>
  );
};  