import { FC } from 'react';
import EventsAndNewsLoadingTemplate from 'components/template/EventsAndNewsLoadingTemplate';
import EventsAndNewsTemplate from 'components/template/EventsAndNewsTemplate';
import { useEventsAndNews } from './hooks/useEventsAndNews';

interface SubTabEventsAndNewsProps {
  projectId: string;
}

const SubTabEventsAndNews: FC<SubTabEventsAndNewsProps> = ({ projectId }) => {
  const {
    menuListItems,
    selectedEventsAndNews,
    visibleType,
    onCreateEventsAndNews,
    onInvisibleCreate,
    onInvisibleUpdate,
    onRemoveEventsAndNews,
    onSelectItemEventsAndNews,
    onUpdateItemEventsAndNews,
    onVisibleCreate,
    onVisibleUpdate,
    isLoading,
    isSaving,
  } = useEventsAndNews(projectId);

  return (
    isLoading ? (
      <EventsAndNewsLoadingTemplate
        visibleType={visibleType}
        menuListItems={menuListItems}
        selectedItemId={selectedEventsAndNews.id}
        onVisibleCreate={onVisibleCreate}
        onClickMenuItem={onSelectItemEventsAndNews}
      />
    ) : (
      <EventsAndNewsTemplate
        eventsAndNewsData={selectedEventsAndNews}
        menuListItems={menuListItems}
        selectedItemId={selectedEventsAndNews.id}
        visibleType={visibleType}
        onClickMenuItem={onSelectItemEventsAndNews}
        onCreateEventsAndNews={onCreateEventsAndNews}
        onInvisibleCreate={onInvisibleCreate}
        onInvisibleUpdate={onInvisibleUpdate}
        onRemoveEventsAndNews={onRemoveEventsAndNews}
        onUpdateEventsAndNews={onUpdateItemEventsAndNews}
        onVisibleCreate={onVisibleCreate}
        onVisibleUpdate={onVisibleUpdate}
        isSaving={isSaving}
      />
    )
  )
};

export default SubTabEventsAndNews;
