import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getProjectTabInfoWithPagination } from 'core/domain/project/repositories/project';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';
import { RoomListColumns } from './RoomListColumns';
import { getAccessesAssetPathById } from 'components/pages/App/routes/assets/config';
import { HotelAccessesTabType } from 'components/pages/AccessesPage/resources/utils';
import { useCheckInRoom } from 'hooks/useCheckInRoom/useCheckInRoom';

export const useAccessesRoomList = () => {
  const { projectId } = useParams<{ clientId: string; projectId: string }>();
  const history = useHistory();
  const [isLoadingRoomsData, setIsLoadingRoomsData] = useState<boolean>(false);
  const [assets, setAssets] = useState<ProjectAssetModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [totalPages, setTotalPages] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { checkInStates } = useCheckInRoom();

  const getProjectAssetsWithPagination = async ({ page, size, search }: { page: number; size: number; search?: string }) => {
    try {
      const response = await getProjectTabInfoWithPagination({ projectId, page, size, search });
      if (response) {
        setAssets(response.data);
        setTotalPages(response.meta.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingRoomsData(false);
    }
  };

  useEffect(() => {
    setIsLoadingRoomsData(true);
    getProjectAssetsWithPagination({
      page: currentPage,
      size: pageSize,
      search: searchQuery,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, currentPage, pageSize, searchQuery]);

  useEffect(() => {
    const tab = window.location.pathname.split('/')[8];
    if (tab === HotelAccessesTabType.ROOM_LIST) {
      getProjectAssetsWithPagination({
        page: currentPage,
        size: pageSize,
        search: searchQuery,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const onSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  };

  const onSelectedRoom = (room: ProjectAssetModel) => {
    history.push(getAccessesAssetPathById(room.id));
  };

  const onChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize!);
  };

  const onGotToRoom = (id: string) => {
    history.push(getAccessesAssetPathById(id));
  };

  const checkInCheckOutColumnsList = RoomListColumns(onGotToRoom, checkInStates.drawer.onCheckInRoom);

  return {
    checkInCheckOutColumnsList,
    totalPages,
    currentPage,
    pageSize,
    assets,
    checkInStates,
    onChangePage,
    onSearch,
    onSelectedRoom,
    isLoadingRoomsData,
  };
};
