import { UserPermissionType } from 'models/users.model';
import { hasPermission } from 'services/permissions';

export const hasIntercomsReadingPermission = hasPermission(UserPermissionType.LIST_INTERCOMS);
export const hasIntercomsCreatingPermission = hasPermission(UserPermissionType.CREATE_INTERCOMS);
export const hasIntercomsUpdatingPermission = hasPermission(UserPermissionType.UPDATE_INTERCOMS);
export const hasIntercomsDeletingPermission = hasPermission(UserPermissionType.DELETE_INTERCOMS);

export enum IntercomStateModel {
  EMPTY = 'empty',
  CREATING = 'creating',
  CONTENT = 'content',
  EDITING = 'editing',
  ERROR = 'error',
}

export enum ProjectIntercomFormValuesNames {
  NAME = 'name',
  PROVIDER = 'provider',
}

export interface ProjectIntercomFormValues {
  [ProjectIntercomFormValuesNames.NAME]: string;
  [ProjectIntercomFormValuesNames.PROVIDER]: string;
}
