import { EventsAndNewsModel } from '../models/eventsAndNewsEntityModels';
import { PayloadUpdateEventsAndNewsModel } from '../models/payloadEventsAndNews';

const STATUS = 'publish';
const TYPE = 'new';

export const uploadEventsAndNewsToPayload = ({
  id,
  title,
  description,
  image,
  link,
  linkTitle,
}: EventsAndNewsModel): PayloadUpdateEventsAndNewsModel => {
  return {
    id,
    title,
    description,
    image: image || null,
    link: link || null,
    link_title: linkTitle || null,
    status: STATUS,
    type: TYPE,
  };
};
