import { Typography } from 'antd';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { AccessDeviceModel } from 'core/domain/access/models';

export interface HotelAccessDevicesListColumnsTranslationsModel {
  nameTitle: string;
  roomTitle: string;
  areaTitle: string;
  batteryTitle: string;
}

export interface Props {
  translations: HotelAccessDevicesListColumnsTranslationsModel,
}

export const HotelAccessDevicesListColumns = ({ translations }: Props): AlfredTableColumn<AccessDeviceModel>[] => {
  const { nameTitle, roomTitle, areaTitle, batteryTitle } = translations;

  return [
    {
      title: nameTitle,
      key: 'name',
      width: '40%',
      render: (text, { name }) => <Typography.Text>{!!name ? name : '-'}</Typography.Text>,
    },
    {
      title: roomTitle,
      key: 'room',
      width: '30%',
      render: (text, { room }) => <Typography.Text>{!!room ? room : '-'}</Typography.Text>,
    },
    {
      title: areaTitle,
      key: 'area',
      width: '15%',
      render: (text, { asset }) => <Typography.Text>{!!asset ? asset.alias : '-'}</Typography.Text>,
    },
    {
      title: batteryTitle,
      key: 'battery',
      width: '15%',
      render: (text, { batteryLevel }) => <Typography.Text>{!!batteryLevel ? `${batteryLevel}%` : '-'}</Typography.Text>,
    },
  ];
};
