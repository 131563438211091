import { CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { useState } from 'react';

interface ItemMenu {
  name: string;
  id: string;
  disabled: boolean;
}

export const useMenuListItems = () => {
  const [menuListItems, setMenuListItems] = useState<ItemMenu[]>([]);

  const disabledItemsMenu = () => {
    const listItems: ItemMenu[] = menuListItems.map((item) => ({ ...item, disabled: true }));
    setMenuListItems(listItems);
  };

  const disabledItemMenu = (disabledId: string) => {
    const listItems: ItemMenu[] = menuListItems.map(({ name, id }) => ({ name, id, disabled: id !== disabledId }));
    setMenuListItems(listItems);
  };

  const enabledItemsMenu = () => {
    const listItems: ItemMenu[] = menuListItems.map((item) => ({ ...item, disabled: false }));
    setMenuListItems(listItems);
  };

  const onSetMenuListItems = (newCommonAreasList: CommonAreaModel[]) => {
    const listItems: ItemMenu[] = newCommonAreasList.map(({ name, id }) => ({ name, id, disabled: false }));
    setMenuListItems(listItems);
  };

  return { menuListItems, onSetMenuListItems, disabledItemsMenu, disabledItemMenu, enabledItemsMenu };
};
