import api from 'services/api';
import { getDocumentListByProjectIdPath } from 'core/endspoints/documents';
import { payloadToDocuments } from '../mappers/payloadToDocuments';
import { DocuemntPayloadModel } from '../model/documentPayloadModels';
import DocumentsEntityListValueObject from '../entities/documentsEntityListValueObject';

export const GetDocumentListByProjectId = async (projectId: string) => {
  const path = getDocumentListByProjectIdPath(projectId);
  const { data } = await api.get<DocuemntPayloadModel[]>(path);
  const documents = data.map((item) => payloadToDocuments(item));
  const { list } = DocumentsEntityListValueObject(documents);
  return list;
};
