import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getUser, grantUsersAccessToAsset } from 'services/api/users';
import { revokeUserAccessToAsset } from 'services/api/users';
import { getAssets } from 'services/api/assets';
import { Asset } from 'models/assets.model';
import Paper from 'components/atoms/Paper';
import LinkSearchSelect, { Options } from 'components/organisms/LinkSearchSelect';
import { AssetsTabColumns } from './AssetsTabColumns';
import { getAssetPathById } from 'components/pages/App/routes/assets/config';
import './styles.scss';

const MAX_SIZE_RESPONSIVE_SCREEN = 800;

const TabAssets: FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const [assets, setAssets] = useState<Asset[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUser(userId)
      .then(({ included }) => {
        setAssets(included.assets);
      })
      .finally(() => setLoading(false));
  }, [userId]);

  const handleAssetClick = (asset: Asset) => history.push(getAssetPathById(asset.id));

  const handleUpdateAssetList = async () => {
    setLoading(true);
    try {
      const { included } = await getUser(userId);
      setAssets(included.assets);
    } finally {
      setLoading(false);
    }
  };

  const handleOnRevoke = async (assetId: string, userId: string) => {
    await revokeUserAccessToAsset(userId, assetId);
  };

  const handleOnSelect = async (assetId: string) => {
    await grantUsersAccessToAsset(userId, assetId);
  };

  const createItemAutocomplete = (id: string, alias: string, direction: string) => {
    return {
      value: id,
      label: (
        <Row justify='space-between'>
          {alias ? <Col>{`${direction} (${alias})`}</Col> : <Col>{`${direction}`}</Col>}
          <Col>
            <PlusOutlined />
          </Col>
        </Row>
      ),
    };
  };

  const CheckLinkedAsset = (findedAssets: Asset[]) => {
    const selectAssets: Options[] = [];
    findedAssets.forEach(({ id, alias, street_type, street_name, block_number, apartment, city, state, country }) => {
      const exist = assets.some((asset) => asset.id === id);
      if (!exist)
        selectAssets.push(
          createItemAutocomplete(id, alias, `${street_type} ${street_name} ${block_number}, ${apartment}, ${city}, ${state}, ${country}`)
        );
    });
    return selectAssets;
  };

  const handleSearch = async (query: string) => {
    const { data } = await getAssets({ search: query });

    return CheckLinkedAsset(data);
  };

  const assetColumns = AssetsTabColumns({ userId, revokeAssetAction: handleUpdateAssetList, onRevoke: handleOnRevoke });

  return (
    <Paper>
      <h2>{t('assets')}</h2>
      <Row justify='end' align='top' className='TabAssets__headers'>
        <Col>
          <LinkSearchSelect
            dropdownMatchSelectWidth={550}
            onOk={handleUpdateAssetList}
            onSearch={handleSearch}
            onSelect={handleOnSelect}
            placeholder={t('grant_new_asset')}
          />
        </Col>
      </Row>
      <Table<Asset>
        key='UserList'
        loading={loading}
        columns={assetColumns}
        dataSource={assets}
        rowKey='id'
        pagination={false}
        scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        onRow={(item) => {
          return {
            onClick: () => handleAssetClick(item),
          };
        }}
        sticky
      />
    </Paper>
  );
};

export default TabAssets;
