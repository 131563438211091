import api from 'services/api';
import { thirdPartyServicesListByAssetPath } from 'constants/endpointsPath/thirdPartyServices';
import { ThirdPartyServicesByAssetListValueObject } from '../entities/thirdPartyServicesByAssetListValueObject';
import { payloadToThirdPartyServicesByAsset } from '../mappers/payloadToThirdPartyServicesByAsset';
import { ThirdPartyServicesByAsset } from '../model/thirdPartyServicesByAsset';
import { ThirdPartyServicesByAssetUnparsed } from '../model/thirdPartyServicesByAssetUnparsed';

const GetThirdPartyServicesListByAsset = async (assetId: string): Promise<Readonly<ThirdPartyServicesByAsset>[]> => {
  const path = thirdPartyServicesListByAssetPath(assetId);
  const { data } = await api.get<ThirdPartyServicesByAssetUnparsed[]>(path);
  const dataParser = data.map((item) => payloadToThirdPartyServicesByAsset(item));
  const { list } = ThirdPartyServicesByAssetListValueObject(dataParser);
  return list;
};

export default GetThirdPartyServicesListByAsset;
