import { FC } from 'react';
import { Menu } from 'antd';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { PasswordConfirmation, PasswordDeletingType, PasswordType, SensorPasswordModel, TogglePassword } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/utils';
import AccessPasswordDrawerEditableForm from '../AccessPasswordDrawerEditableForm';
import './styles.scss';

interface Props {
  sensor: SensorPasswordModel;
  label: PasswordType;
  passwordText: string;
  buttonShowText: string;
  buttonHideText: string;
  buttonDeleteText: string;
  errorFormatMessage?: string;
  onTogglePassword: ({ label, sensorId, type }: TogglePassword) => void;
  onChangePassword?: ({ label, sensorId, value }: PasswordConfirmation) => void;
  onDeletePassword: ({ label, sensorId }: PasswordDeletingType) => void;
  disabled: boolean;
}

const AccessPasswordDrawerForm: FC<Props> = ({
  sensor,
  label,
  passwordText,
  buttonShowText,
  buttonHideText,
  buttonDeleteText,
  errorFormatMessage,
  onTogglePassword,
  onChangePassword,
  onDeletePassword,
  disabled,
}) => {
  const {
    password,
    sensorId,
    isVisible,
    isFormatError,
  } = sensor;

  return (
    <div>
      <div className={'AccessPasswordDrawerForm__content'}>
        <AccessPasswordDrawerEditableForm
          password={password}
          sensorId={sensorId}
          isVisible={isVisible}
          isError={isFormatError}
          isDisabled={disabled}
          label={label}
          passwordText={passwordText}
          buttonShowText={buttonShowText}
          buttonHideText={buttonHideText}
          onTogglePassword={onTogglePassword}
          onChangePassword={onChangePassword}
        />
        <div className={'AccessPasswordDrawerForm__contextual-menu'}>
          <ContextualMenu menu={
            <Menu>
              <Menu.Item key={'deletePassword'} onClick={() => onDeletePassword({ sensorId, label })}>{buttonDeleteText}</Menu.Item>
            </Menu>
          }
          />
        </div>
      </div>
      {isFormatError && <p className={'AccessPasswordDrawerForm__error'}>{errorFormatMessage}</p>}
    </div>
  )
}


export default AccessPasswordDrawerForm;