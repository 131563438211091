import { FC } from 'react';
import { Space, Progress, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'constants/colors';
import './styles.scss';
export interface PercentStatusProps {
  text: string;
  value: number | null;
  threshold: number;
  unit?: string;
  reverse?: boolean;
}

const LIMIT_CPU_LOAD = 5;

const getPercent = (value: number | null, threshold: number, unit?: string) => {
  return value !== null ? ((100 * value) / threshold) : 0;
};

const getValue = (value: number | null, threshold: number, unit?: string) => {
  if (!unit && threshold === LIMIT_CPU_LOAD) return value;
  return value !== null ? `${Math.round(value)}${unit}` : 0;
};

const PercentStatus: FC<PercentStatusProps> = ({ text, value, threshold, unit, reverse = false }) => {
  const { t } = useTranslation();

  const handleOnColor = (value: number | null, threshold: number, reverse?: boolean) => {
    const section = !reverse ? { init: 75, end: 99 } : { init: 15, end: 1 };

    const percent = getPercent(value, threshold, unit);
    if (percent < section.init) return COLORS.success;
    if (percent >= section.init && percent <= section.end) return COLORS.orange;
    return COLORS.alert;
  };

  return (
    <Space className='PercentStatus' direction='vertical'>
      <span className='PercentStatus__text'>{text}</span>
      <Progress
        className='PercentStatus__progess'
        type='circle'
        percent={getPercent(value, threshold, unit)}
        width={80}
        strokeColor={handleOnColor(value, threshold, reverse)}
        format={() => (
          <Space direction='vertical'>
            <Popover
              title={text}
              content={
                <>
                  <p className='PercentStatus__popover'>
                    <b>{`${t('exact_reported_value')}: ${value}${unit}`}</b>
                  </p>
                  <p className='PercentStatus__popover-threshold'>
                    <i>{`${t('recommended_maximum_value')}: ${threshold}${unit}`}</i>
                  </p>
                </>
              }
            >
              {value ? (
                <span className='PercentStatus__value'>{`${getValue(value, threshold, unit)}`}</span>
              ) : (
                <span className='PercentStatus__value--not-reported'>{t('not_reported')}</span>
              )}
            </Popover>
          </Space>
        )}
      />
    </Space>
  );
};

export default PercentStatus;
