import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import CommonAreasAndBookingsListMenu from 'components/molecules/CommonAreasAndBookingsListMenu';
import { CommonAreasAndBookingsListMenuItem } from 'components/pages/CommonAreasBookingsListPage/resources/useBookingsList';
import './styles.scss';

interface Props {
  menuListItems: CommonAreasAndBookingsListMenuItem[];
  bookingsListButtonText: string;
  selectedItemId?: string;
  createCommonAreaButtonText: string;
  onSelectCommonArea: (id: string) => void;
  onCreateCommonArea: () => void;
  onShowBookingsList: () => void;
  createCommonAreaButtonDisabled: boolean;
  showBookingListButtonDisabled: boolean;
  children?: React.ReactNode;
}

const CommonAreasAndBookingsMenuTemplate: FC<Props> = ({
  menuListItems,
  bookingsListButtonText,
  createCommonAreaButtonText,
  selectedItemId,
  children,
  onSelectCommonArea,
  onCreateCommonArea,
  onShowBookingsList,
  createCommonAreaButtonDisabled,
  showBookingListButtonDisabled,
}) => {
  return (
    <Paper className={'CommonAreasAndBookingsMenuTemplate__body'}>
      <div className={'CommonAreasAndBookingsMenuTemplate__body-menu'}>
        <CommonAreasAndBookingsListMenu
          selectedItemId={selectedItemId}
          menuListItems={menuListItems}
          bookingsListButtonText={bookingsListButtonText}
          createCommonAreaButtonText={createCommonAreaButtonText}
          onCreateCommonArea={onCreateCommonArea}
          onSelectCommonArea={onSelectCommonArea}
          onShowBookingsList={onShowBookingsList}
          createCommonAreaButtonDisabled={createCommonAreaButtonDisabled}
          showBookingListButtonDisabled={showBookingListButtonDisabled}
        />
      </div>
      <div className={'CommonAreasAndBookingsMenuTemplate__body-info'}>
        {children}
      </div>
    </Paper>
  )
}

export default CommonAreasAndBookingsMenuTemplate;