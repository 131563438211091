import { FC } from 'react';
import { WarningTwoTone } from '@ant-design/icons';
import ModalAtom, { ModalAtomProps } from 'components/atoms/ModalAtom';
import { COLORS } from 'constants/colors';
import './styles.scss';

export interface QrErrorModalLiteralsModel {
  title: string;
  infoText: string;
  requiredText: string;
  scannedText: string;
  confirmText: string;
}

export interface QrErrorModalProps extends ModalAtomProps {
  qidValue: string | null;
  didValue: string | null;
  literals: QrErrorModalLiteralsModel;
  isQidError: boolean;
  isDidError: boolean;
}

const QrErrorModal: FC<QrErrorModalProps> = ({
  qidValue,
  didValue,
  literals,
  onCancel,
  onOk,
  open,
  isQidError,
  isDidError,
}) => {

  const {
    title,
    confirmText,
    infoText,
    requiredText,
    scannedText
  } = literals;

  return (
    <ModalAtom
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      title={<>
        <WarningTwoTone twoToneColor={COLORS.orange} />
        <span className={'QrErrorModal__title'}>{title}</span>
      </>
      }
    >
      <p>{infoText}</p>
      {isQidError && (
        <ul>
          <li>
            <b>{`${requiredText}: `}</b>
            {'QID'}
          </li>
          <li>
            <b>{`${scannedText}: `}</b>
            {qidValue}
          </li>
        </ul>
      )}
      {isDidError && (
        <ul>
          <li>
            <b>{`${requiredText}: `}</b>
            {'DID'}
          </li>
          <li>
            <b>{`${scannedText}: `}</b>
            {didValue}
          </li>
        </ul>
      )}
      <p>{confirmText}</p>
    </ModalAtom>
  )
}

export default QrErrorModal;