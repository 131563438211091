import i18next from 'i18next';

export interface IntercomEmptyTemplateTranslations {
  title: string;
  text: string;
  buttonText: string;
}

export interface IntercomsListTemplateTranslations {
  name: string;
  provider: string;
  deleteText: string;
  edit: string;
  add: string;
}

export interface IntercomErrorTemplateTranslations {
  title: string;
  text: string;
}

export interface IntercomFormTranslations {
  name: string;
  namePlaceholder: string;
  nameError: string;
  provider: string;
  providerError: string;
  providerPlaceholder: string;
  close: string;
  add: string;
  addSuccess: string;
  addError: string;
  save: string;
  saveSuccess: string;
  saveError: string;
}

export interface IntercomDeleteTranslations {
  deleteSuccess: string;
  deleteError: string;
}

export const getIntercomsEmptyTranslations = (): IntercomEmptyTemplateTranslations => {
  return {
    title: i18next.t('_INTERCOMS_EMPTY_TITLE'),
    text: i18next.t('_INTERCOMS_EMPTY_TEXT'),
    buttonText: i18next.t('add'),
  };
};

export const getIntercomsListTranslations = (): IntercomsListTemplateTranslations => {
  return {
    name: i18next.t('_INTERCOMS_TABLE_NAME'),
    provider: i18next.t('_INTERCOMS_TABLE_PROVIDER'),
    deleteText: i18next.t('_INTERCOMS_TABLE_DELETE'),
    edit: i18next.t('_INTERCOMS_TABLE_EDIT'),
    add: i18next.t('_INTERCOMS_TABLE_ADD'),
  };
};

export const getIntercomsErrorTranslations = (): IntercomErrorTemplateTranslations => {
  return {
    title: i18next.t('_INTERCOMS_ERROR_TITLE'),
    text: i18next.t('_INTERCOMS_ERROR_DESCRIPTION'),
  };
};

export const getIntercomsFormTranslations = (): IntercomFormTranslations => {
  return {
    name: i18next.t('_INTERCOMS_TABLE_NAME'),
    nameError: i18next.t('field_required'),
    namePlaceholder: i18next.t('_INTERCOMS_FORM_NAME_PLACEHOLDER'),
    provider: i18next.t('_INTERCOMS_TABLE_PROVIDER'),
    providerError: i18next.t('field_required'),
    providerPlaceholder: i18next.t('_INTERCOMS_FORM_PROVIDERS_PLACEHOLDER'),
    close: i18next.t('close'),
    add: i18next.t('_INTERCOMS_TABLE_ADD'),
    addSuccess: i18next.t('_INTERCOMS_FORM_ADD_SUCCESS'),
    addError: i18next.t('_INTERCOMS_FORM_ADD_ERROR'),
    save: i18next.t('save'),
    saveSuccess: i18next.t('_INTERCOMS_FORM_SAVE_SUCCESS'),
    saveError: i18next.t('_INTERCOMS_FORM_SAVE_ERROR'),
  };
};

export const getIntercomsDeleteTranslations = (): IntercomDeleteTranslations => {
  return {
    deleteSuccess: i18next.t('_INTERCOMS_DELETE_SUCCESS'),
    deleteError: i18next.t('_INTERCOMS_DELETE_ERROR'),
  };
};
