import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Space, Typography } from 'antd';
import AvatarUser from 'components/atoms/AvatarUser';
import { getUserPathById } from 'components/pages/App/routes/users/config';
import './styles.scss';

interface UserAvatarIdentityProps {
  email?: string;
  userId?: string;
  username?: string;
}

const UserAvatarIdentity: FC<UserAvatarIdentityProps> = ({ email, userId, username }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnUserClick = (userId: string) => history.push(getUserPathById(userId));

  return (
    <Space>
      <AvatarUser size='small' letter={!!userId ? username?.charAt(0) : undefined} />
      <Typography.Link
        disabled={!userId}
        className='UserAvatarIdentity__text'
        onClick={() => userId && handleOnUserClick(userId)}
        target='_blank'
      >
        {userId ? username : t('ACCESS_CONTROL.usage_source.anonymous_user')}
        {!!email && <span className='UserAvatarIdentity__text-email'> ({email})</span>}
      </Typography.Link>
    </Space>
  );
};

export default UserAvatarIdentity;
