import { FC } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Menu } from 'antd';
import SpaceAtom from 'components/atoms/SpaceAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import UserInvitationModal from 'components/organisms/UserInvitationModal';
import { useUsers } from 'hooks/useUsers';
import { UsersColumns } from './UsersColumns';
import UsersListTemplate from 'components/template/UsersListTemplate';
import ContextualMenu from 'components/atoms/ContextualMenu';
import UserClientsSelectorModal from 'components/organisms/UsersClientsSelectorModal';
import TenantsPreviewModal from 'components/organisms/TenantPreviewModal';
import './styles.scss';

const UsersPage: FC = () => {
  const columns = UsersColumns();
  const {
    users,
    totalPages,
    currentPage,
    pageSize,
    translations,
    clientsSelectorModalTranslations,
    clientsList,
    clientSelectorForm,
    previewData,
    previewDataError,
    previewDataInfo,
    previewDataTitle,
    previewTranslations,
    previewColumns,
    onChangePage,
    onSearch,
    onSelectUser,
    onOpenInvitationModal,
    onCloseInvitationModal,
    onDownloadTenantsTemplateCsv,
    onDownloadAssetsTemplateCsv,
    onCloseClientsSelectorModal,
    onSelectClient,
    onUploadSelectedFile,
    onClosePreviewModal,
    onUploadTenantsTemplate,
    loadingUsers,
    invitationModalVisible,
    hasInvitationPermissions,
    hasReadWritePermissions,
    clientsSelectorModalVisible,
    loadingClientsList,
    loadingAssetsTemplateDownload,
    loadingPreview,
    previewModalVisible,
    updating,
    errorSendingPreview,
  } = useUsers();
  const {
    invitationUsersButtonText,
    titleText,
    searchInputPlaceholderText,
    uploadCsvFileText,
    downloadTenantsTemplateText,
    downloadAssetsTemplateText,
  } = translations;
  const {
    clientSelectorModalTitle,
    clientSelectorModalMainButtonText,
    clientSelectorModalSecondaryButtonText,
    clientSelectorModalPlaceholderText,
    clientSelectorModalRequiredMessage,
  } = clientsSelectorModalTranslations;
  const {
    title: previewTitle,
    tableTitle,
    mainButtonText: previewMainButtonText,
    secondaryButtonText: previewSecondaryButtonText,
    errorTitle,
    errorDescription,
    errorSendingTitle,
    errorSendingDescription,
  } = previewTranslations;

  return (
    <>
      <UsersListTemplate
        title={titleText}
        columns={columns}
        onRowClick={onSelectUser}
        data={users}
        totalPages={totalPages}
        loading={loadingUsers}
        onChangePagination={onChangePage}
        pageCurrent={currentPage}
        pageSize={pageSize}
        buttons={
          <SpaceAtom>
            <Input.Search
              allowClear
              placeholder={searchInputPlaceholderText}
              onSearch={onSearch}
            />
            <DefaultButton
              hidden={!hasInvitationPermissions}
              onClick={onOpenInvitationModal}
              icon={<PlusOutlined />}
              title={invitationUsersButtonText}
            />
            {hasReadWritePermissions && (
              <ContextualMenu menu={
                <Menu>
                  <Menu.Item key={'download-assets-template'} onClick={onDownloadAssetsTemplateCsv}>
                    {downloadAssetsTemplateText}
                  </Menu.Item>
                  <Menu.Item key={'download-tenants-template'} onClick={onDownloadTenantsTemplateCsv}>
                    {downloadTenantsTemplateText}
                  </Menu.Item>
                  <label className={'UsersPage__custom-contextual-button'}>
                    <input type={'file'} accept={'.csv'} onChange={onUploadSelectedFile} />
                    {uploadCsvFileText}
                  </label>
                </Menu>
              } />
            )}
          </SpaceAtom>
        }
      />
      <UserInvitationModal
        visible={invitationModalVisible}
        onCancel={onCloseInvitationModal}
        onOk={onCloseInvitationModal}
      />
      <UserClientsSelectorModal
        title={clientSelectorModalTitle}
        mainButtonText={clientSelectorModalMainButtonText}
        secondaryButtonText={clientSelectorModalSecondaryButtonText}
        requiredMessage={clientSelectorModalRequiredMessage}
        placeholderText={clientSelectorModalPlaceholderText}
        data={clientsList}
        onSelectClient={onSelectClient}
        onCancel={onCloseClientsSelectorModal}
        form={clientSelectorForm}
        loading={loadingClientsList || loadingAssetsTemplateDownload}
        visible={clientsSelectorModalVisible}
      />
      <TenantsPreviewModal
        visible={previewModalVisible}
        data={previewData}
        columns={previewColumns}
        onUpload={onUploadTenantsTemplate}
        onCancel={onClosePreviewModal}
        loading={loadingPreview || updating}
        dataError={previewDataError}
        errorSendingPreview={errorSendingPreview}
        title={previewTitle}
        name={previewDataTitle}
        tableTitle={tableTitle}
        tableInfoText={previewDataInfo}
        mainButtonText={previewMainButtonText}
        secondaryButtonText={previewSecondaryButtonText}
        errorTitle={errorTitle}
        errorDescription={errorDescription}
        errorSendingTitle={errorSendingTitle}
        errorSendingDescription={errorSendingDescription}
      />
    </>
  );
};

export default UsersPage;
