import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import { Icon } from 'components/atoms/Icons';
import './styles.scss';

interface Props {
  text: string;
}

const ComingSoonTemplate: FC<Props> = ({ text }) => {
  return (
    <Paper className={'ComingSoonTemplate__body'}>
      <div className={'ComingSoonTemplate__body-empty-container'}>
        <div className={'ComingSoonTemplate__body-empty-icon'}>
          <Icon.EmptyAccess />
        </div>
        <p className={'ComingSoonTemplate__body-empty-text'}>{text}</p>
      </div>
    </Paper>
  );
}

export default ComingSoonTemplate;