import { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Divider, PageHeader, Skeleton, Space, Tooltip, message } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useWindowSize } from 'hooks/useWindowSize';
import { getSupportTicket, updateSupportTicketStatus } from 'services/api/supportTickets';
import { DATE_TIME_COMPLETE_FORMAT } from 'constants/date';
import { LARGE_DEVICES } from 'constants/screen-sizes';
import Paper from 'components/atoms/Paper';
import AsSupportTicketStatusSelect from 'components/molecules/AsSupportTicketStatusSelect';
import StatusListHistory from './StatusListHistory';
import ModalStatus from './ModalStatus';
import CarrouselMedia from './CarrouselMedia';
import { getAssetPathById } from '../App/routes/assets/config';
import { SupportTicket } from 'models/supportTickets.model';
import './styles.scss';
import { SupportTicketStatusType } from 'core/domain/supportTickets/models/supportTicketsPayloadModel';

const MAX_SIZE_RESPONSIVE_SCREEN = LARGE_DEVICES;

const TechSupportPage: FC = () => {
  const { supportTicketsId } = useParams<{ supportTicketsId: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { width } = useWindowSize();
  const [ticket, setTicket] = useState<Partial<SupportTicket>>();
  const [status, setStatus] = useState<SupportTicketStatusType>();
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    setLoadingStatus(true);
    getSupportTicket(supportTicketsId)
      .then(({ data: { title, status, id, created_at, description, address, phone_number, asset_id } }) => {
        setStatus(status);
        setTicket({ title, id, created_at, description, address, phone_number, asset_id });
      })
      .finally(() => {
        setLoading(false);
        setLoadingStatus(false);
      });
  }, [supportTicketsId]);

  const handleOnGoToAssetDetail = () => {
    history.push(getAssetPathById(ticket?.asset_id!));
  };

  const handleUpdateStatus = async (ticketId: string, updateStatus: SupportTicketStatusType, updateDescription: string) => {
    if (updateStatus === undefined) {
      return message.info(t('change_state_required'), 3);
    }

    setLoadingStatus(true);
    try {
      await updateSupportTicketStatus(ticketId, { status: updateStatus, description: updateDescription });
      message.success(t('status_changed'), 3);
      const {
        data: { status },
      } = await getSupportTicket(supportTicketsId);
      setStatus(status);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoadingStatus(false);
      setVisibleModal(false);
    }
  };

  return (
    <>
      <PageHeader
        ghost={false}
        title={`${t('support_ticket')}: ${ticket?.title || ''}`}
        onBack={() => history.goBack()}
        className='TechSupportPage__header'
        extra={[
          <Button key='1' icon={<EyeOutlined />} onClick={handleOnGoToAssetDetail}>
            {t('see_full_asset')}
          </Button>,
        ]}
      />
      <div className='TechSupportPage__wrapper'>
        <Paper>
          {ticket && (
            <div className='TechSupportPage__content'>
              <div className='TechSupportPage__main'>
                <div className='TechSupportPage__main-header'>
                  <span>
                    <b>{t('date')}: </b>
                    {loading ? (
                      <Skeleton.Input active />
                    ) : ticket.created_at ? (
                      moment.unix(ticket.created_at).format(DATE_TIME_COMPLETE_FORMAT)
                    ) : (
                      t('not_reported')
                    )}
                  </span>
                  <Space>
                    <b>{t('status')}:</b>
                    <AsSupportTicketStatusSelect
                      key={status}
                      disabled={!status}
                      loading={loadingStatus}
                      status={status}
                      onChange={(updateStatus) => handleUpdateStatus(ticket.id!, updateStatus, '')}
                    />
                    <Tooltip title={t('change_and_comment_status')}>
                      <Button loading={loadingStatus} disabled={!status} icon={<EditOutlined />} onClick={() => setVisibleModal(true)}>
                        {t('comment')}
                      </Button>
                    </Tooltip>
                  </Space>
                </div>
                <div className='TechSupportPage__main-description'>
                  {loading ? (
                    <Skeleton active />
                  ) : (
                    <span>
                      <b>{t('description')}: </b>
                      {ticket.description}
                    </span>
                  )}
                </div>
                <StatusListHistory key={status} ticketId={supportTicketsId} />
              </div>
              <Divider type='vertical' className='TechSupportPage__divider' />
              {width < MAX_SIZE_RESPONSIVE_SCREEN && <Divider orientation='left'>{t('additional_info')}</Divider>}
              <Space direction='vertical' size='large'>
                <CarrouselMedia ticketId={supportTicketsId} />
                <Space direction='vertical'>
                  <span>
                    <b>Dirección:</b> {ticket.address}
                  </span>
                  <span>
                    <b>Teléfono:</b> {ticket.phone_number}
                  </span>
                </Space>
              </Space>
            </div>
          )}
        </Paper>
        {status && (
          <ModalStatus
            status={status}
            visible={visibleModal}
            onFinish={(values) => handleUpdateStatus(ticket?.id!, values.status, values.description)}
            loading={loadingStatus}
            onCancel={() => setVisibleModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default TechSupportPage;
