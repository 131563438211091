import { FC } from 'react';
import './styles.scss';

interface DaySelectorProps {
  name: string;
  onClick: () => void;
  actived: boolean;
  disabled?: boolean;
}

const DaySelector: FC<DaySelectorProps> = ({ name, onClick, actived, disabled = false }) => {
  return (
    <div
      className={`DaySelector__item ${actived && 'DaySelector__item--actived'} ${disabled && 'DaySelector__item--disabled'}`}
      onClick={() => !disabled && onClick()}
    >
      {name}
    </div>
  );
};

export default DaySelector;
