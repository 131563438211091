import { ActiveGatewaysStatisticsModel } from '../model/gateway/gatewayParsed';

export const ActiveGatewaysStatisticsEntity = ({
  totalGatewaysOnline,
  gateways,
}: ActiveGatewaysStatisticsModel): Readonly<ActiveGatewaysStatisticsModel> => {
  return Object.freeze({
    totalGatewaysOnline,
    gateways,
  });
};
