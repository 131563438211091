import { useHistory, useParams } from 'react-router-dom';
import { ClientNavigation, ClientCustomizationSubTab, ClientTab } from 'components/pages/ClientDetailPage/resources/models';
import { getClientPathById, getClientTabListWithPaginationPath } from 'components/pages/App/routes/settings/config';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';

interface ClientTabNavigationOptionsModel {
  activeTab: ClientTab;
  activeSubTab?: ClientCustomizationSubTab;
}

export const useClient = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();

  const getClientCustomizationRoute = ({ activeTab, activeSubTab }: ClientTabNavigationOptionsModel): string => {
    const options = {
      clientId,
      tab: activeTab,
      subTab: activeSubTab || ClientCustomizationSubTab.ASSETS_AND_EMAILS,
    };
    return getClientPathById(options);
  };

  const getClientUsersRoute = ({ activeTab }: ClientTabNavigationOptionsModel): string => {
    const options = { clientId, tab: activeTab };
    return getClientPathById(options);
  };

  const getClientLegalDocumentationRoute = ({ activeTab }: ClientTabNavigationOptionsModel): string => {
    const options = { clientId, tab: activeTab };
    return getClientPathById(options);
  };

  const getClientProjectsRoute = ({ activeTab }: ClientTabNavigationOptionsModel): string => {
    const options = {
      clientId,
      tab: activeTab,
      page: TABLE_PAGINATION_PAGE_DEFAULT,
      size: TABLE_PAGINATION_SIZE_DEFAULT,
    };
    return getClientTabListWithPaginationPath(options);
  };

  const clientTabNavigationManager = {
    [ClientTab.CUSTOMIZATION]: getClientCustomizationRoute,
    [ClientTab.LEGAL_DOCUMENTATION]: getClientLegalDocumentationRoute,
    [ClientTab.PROJECTS]: getClientProjectsRoute,
    [ClientTab.BILLABLE_USERS]: getClientUsersRoute,
  };

  const onSelectClient = ({ activeTab, activeSubTab }: ClientNavigation): void => {
    const route = clientTabNavigationManager[activeTab]({ activeTab, activeSubTab });
    return history.replace(route);
  };

  return { onSelectClient };
};
