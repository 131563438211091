import { QrAssets } from 'core/domain/assets/models/qrAssets';
import { QrGateway } from 'core/domain/gateways/model/qrGateway';
import { downloadCsvFile } from 'utils/csv';

interface useQrCsvResponse {
  processed: (data: (QrAssets | QrGateway)[], itemName: string) => Promise<void>;
}

export const useQrCsv = (): useQrCsvResponse => {
  const processed = (data: (QrAssets | QrGateway)[], itemName: string): Promise<void> => {
    return new Promise((resolve) => {
      const qrs = data.map((item) => item.qr).join('\n');
      downloadCsvFile(qrs, itemName);
      resolve();
    });
  };

  return { processed };
};
