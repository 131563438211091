import api from 'services/api';
import { ProjectResponse, ProjectsResponse } from 'models/project.model';
import { Camera } from 'models/camera.model';
import { Response } from 'models/general.model';

export const getProject = async (projectId: string): Promise<ProjectResponse> => {
  const params = new URLSearchParams();
  params.append('include', 'assets');
  return await api.get(`/projects/${projectId}`, { params });
};

interface GetProjectsOptions {
  include?: 'assets' | 'deployments';
  search?: string;
  client?: string;
  page?: {
    current: number;
    size: number;
  };
}

export const getProjects = async ({ include, search, client, page }: GetProjectsOptions): Promise<ProjectsResponse> => {
  const params = new URLSearchParams();
  if (include) params.append('include', `${include}`);
  if (search) params.append('search', `${search}`);
  if (client) params.append('client', `${client}`);
  if (page) {
    params.append('page[number]', `${page.current}`);
    params.append('page[size]', `${page.size}`);
  }
  return await api.get('/projects', {
    params,
  });
};

export const sendProjectNotification = async (projectId: string, content: string) => {
  return await api.post(`/notifications/project/${projectId}`, {
    data: {
      content: content,
    },
  });
};

export const getProjectCameras = (projectId: string): Promise<Response<Camera[]>> => {
  return api.get(`/projects/${projectId}/cameras`);
};

interface CheckedUsersVerifiersOptions {
  users_require_verification?: boolean;
  user_verifier?: string;
}

export const checkedUsersVerifiers = (projectId: string, data: CheckedUsersVerifiersOptions) => {
  return api.patch(`/projects/${projectId}`, { data });
};
