import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, message } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { IntercomCreateModel, IntercomModel, IntercomUpdateModel } from 'core/domain/intercoms/model/intercomModel';
import { useProjectCRUD } from 'hooks/useProject/useProjectCRUD';
import { IntercomStateModel, ProjectIntercomFormValues } from './config';
import { useIntercomsCRUD } from './useIntercomsCRUD';
import {
  getIntercomsDeleteTranslations,
  getIntercomsEmptyTranslations,
  getIntercomsErrorTranslations,
  getIntercomsFormTranslations,
  getIntercomsListTranslations,
} from './translations';

const initialFormValues: ProjectIntercomFormValues = {
  name: '',
  provider: '',
};

export const useProjectIntercoms = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data } = useProjectCRUD();
  const [form] = Form.useForm<ProjectIntercomFormValues>();
  const [selectedIntercomId, setSelectedIntercomId] = useState<string>();
  const [intercoms, setIntercoms] = useState<IntercomModel[]>([]);
  const [providers, setProviders] = useState<DefaultOptionType[]>([]);
  const [IntercomState, setIntercomState] = useState<IntercomStateModel>(IntercomStateModel.CONTENT);
  const { getIntercomsListByProjectId, createIntercom, updateIntercom, deleteIntercom, loadingIntercoms, processingIntercom } =
    useIntercomsCRUD(projectId);

  const intercomEmptyTemplateTranslations = getIntercomsEmptyTranslations();
  const intercomsListTemplateTranslations = getIntercomsListTranslations();
  const intercomsErrorTemplateTranslations = getIntercomsErrorTranslations();
  const intercomFormTranslations = getIntercomsFormTranslations();
  const intercomDeleteTranslations = getIntercomsDeleteTranslations();

  const onAddIntercom = () => {
    setIntercomState(IntercomStateModel.CREATING);
    form.setFieldsValue(initialFormValues);
  };

  const onEditIntercom = (intercom: IntercomModel) => {
    const { name, vendor, id } = intercom;
    setSelectedIntercomId(id);
    setIntercomState(IntercomStateModel.EDITING);
    form.setFieldsValue({ name, provider: vendor });
  };

  const onDeleteIntercom = async (intercomId: string) => {
    try {
      await deleteIntercom(intercomId);
      message.success(intercomDeleteTranslations.deleteSuccess);
      const list = await getIntercomsListByProjectId();
      list && setIntercoms(list);
      getIntercomsState(list);
    } catch (error) {
      message.error(intercomDeleteTranslations.deleteError);
    }
  };

  const onSubmitCreating = async (formValues: ProjectIntercomFormValues) => {
    const { name, provider } = formValues;
    const values: IntercomCreateModel = { name, vendor: provider, projectId };
    try {
      await createIntercom(values);
      message.success(intercomFormTranslations.addSuccess);
      const list = await getIntercomsListByProjectId();
      list && setIntercoms(list);
      getIntercomsState(list);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitEditing = async (formValues: ProjectIntercomFormValues) => {
    const { name, provider } = formValues;
    const values: IntercomUpdateModel = { id: selectedIntercomId || '', name, vendor: provider };
    try {
      await updateIntercom(values);
      message.success(intercomFormTranslations.saveSuccess);
      const list = await getIntercomsListByProjectId();
      list && setIntercoms(list);
      getIntercomsState(list);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitCreatingFailed = () => {
    message.error(intercomFormTranslations.addError, 3);
  };

  const onSubmitEditingFailed = () => {
    message.error(intercomFormTranslations.saveError, 3);
  };

  const onClose = () => {
    const model = !!intercoms.length ? IntercomStateModel.CONTENT : IntercomStateModel.EMPTY;
    setIntercomState(model);
  };

  const formatProviders = (options: string[]): DefaultOptionType[] => {
    return options.map((option) => ({ value: option, label: option }));
  };

  const getIntercomsState = (values?: IntercomModel[]) => {
    values && setIntercomState(IntercomStateModel.CONTENT);
    values && !values.length && setIntercomState(IntercomStateModel.EMPTY);
    !values && setIntercomState(IntercomStateModel.ERROR);
  };

  useEffect(() => {
    if (data) {
      const { availableIntercomVendors } = data;
      const vendors = !!availableIntercomVendors?.length ? availableIntercomVendors : ['dahua'];
      const formattedProviders = formatProviders(vendors);
      setProviders(formattedProviders);
    }
  }, [data]);

  useEffect(() => {
    getIntercomsListByProjectId()
      .then((response) => {
        response && setIntercoms(response);
        getIntercomsState(response);
      })
      .catch(() => getIntercomsState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    intercomEmptyTemplateTranslations,
    intercomsListTemplateTranslations,
    intercomsErrorTemplateTranslations,
    intercomFormTranslations,
    intercoms,
    IntercomState,
    form,
    providers,
    onAddIntercom,
    onEditIntercom,
    onDeleteIntercom,
    onSubmitCreating,
    onSubmitCreatingFailed,
    onSubmitEditing,
    onSubmitEditingFailed,
    onClose,
    processing: processingIntercom,
    loadingIntercoms,
  };
};
