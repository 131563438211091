import { PlusOutlined } from '@ant-design/icons';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { useSettings } from './resources/useSettings';
import ClientNewModal from 'components/organisms/ClientNewModal';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import AlfredMainTemplate from 'components/template/AlfredMainTemplate';
import AlfredTableTemplate from 'components/template/AlfredTableTemplate';
import './styles.scss';

const SettingsPage = () => {
  const {
    clientsColumns,
    clientsList,
    createClientButtonText,
    createClientModalLiterals,
    currentPage,
    pageSize,
    pageTitle,
    onChangePage,
    onClickTableRow,
    onCloseCreateClientModal,
    onCreateClient,
    onOpenCreateClientModal,
    isCreateClientButtonVisible,
    isCreateClientLoading,
    isCreateClientModalVisible,
    isGetClientListLoading,
  } = useSettings();

  const actionSection = isCreateClientButtonVisible ? (
    <DefaultButton
      onClick={onOpenCreateClientModal}
      icon={<PlusOutlined />}
      className={'ClientsPage__button-create'}
      title={createClientButtonText}
    />
  ) : undefined

  return (
    <>
      <AlfredMainTemplate title={pageTitle}>
        <AlfredTableTemplate<ClientModel>
          actionSectionRight={actionSection}
          columns={clientsColumns}
          data={clientsList}
          totalPages={0}
          pageSize={pageSize}
          pageCurrent={currentPage}
          onPagination={onChangePage}
          onRowClick={onClickTableRow}
          isCharging={isGetClientListLoading}
        />
      </AlfredMainTemplate>
      <ClientNewModal
        open={isCreateClientModalVisible}
        onCreateClient={onCreateClient}
        onCancel={onCloseCreateClientModal}
        onOk={onCloseCreateClientModal}
        loading={isCreateClientLoading}
        {...createClientModalLiterals}
      />
    </>

  );
};

export default SettingsPage;
