import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TabsAtom, { TabsAtomProps } from 'components/atoms/TabsAtom';
import { ProjectServiceSubTabs } from '../constants';

const SubTabsTitles: FC<TabsAtomProps> = (props) => {
  const { t } = useTranslation();

  return (
    <TabsAtom {...props}>
      <TabsAtom.TabPane tab={t('useful_contacts')} key={ProjectServiceSubTabs.USEFUL_CONTACTS} />
      <TabsAtom.TabPane tab={t('news')} key={ProjectServiceSubTabs.NEWS} />
      <TabsAtom.TabPane tab={t('_COMMON_AREA_TITLE_TAB')} key={ProjectServiceSubTabs.COMMON_AREA} />
    </TabsAtom>
  );
};

export default SubTabsTitles;
