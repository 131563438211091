import { ListResponseBasic } from 'models/general.model';
import { Asset } from 'models/assets.model';

export type UserResponse = {
  data: User;
  included: {
    assets: Array<Asset>;
  };
};

export type UserType = 'owner' | 'normal' | 'staff' | 'technician' | 'tech_support' | 'contractor';

export type UserStatus = 'pending' | 'confirmation' | 'withdrawn';

export type User = {
  id: string;
  username: string;
  email: string;
  type: UserType;
  roles: UserRole[];
  lang: string;
  status: number;
  created_at: number;
  confirmed_at: number;
};

export interface UserProfile extends User {
  assets: Asset['id'][];
}

export interface UserInvitation {
  asset_id: string | null;
  client_id: string | null;
  email: string;
  role: UserRole;
}

export enum UserPermissionType {
  ASSIGN_CLIENT_TO_USER = 'assign_client_to_user',
  ASSIGN_GATEWAY = 'assign_gateway',
  UNASSIGN_GATEWAY = 'unassign_gateway',
  CREATE_ASSETS = 'create_assets',
  CREATE_CLIENTS = 'create_clients',
  CREATE_PROJECT = 'create_projects',
  DELETE_ASSETS = 'delete_assets',
  DELETE_CLIENTS = 'delete_clients',
  DELETE_PROJECTS = 'delete_projects',
  DELETE_SUPPORT_TICKETS = 'delete_support_tickets',
  DELETE_USERS = 'delete_users',
  EDIT_CURRENT_USER = 'edit_current_user',
  INVITE_USERS_ROLE_EXPERTS = 'invite_users_role_expert',
  INVITE_USERS_ROLE_USERS = 'invite_users_role_user',
  INVITE_USERS_ROLE_PROPERTY_MANAGER = 'invite_users_role_property_manager',
  INVITE_USERS = 'invite_users',
  LIST_ACADEMY_DOCS = 'list_academy_docs',
  LIST_ASSETS_STATUS = 'list_assets_status',
  LIST_ASSETS = 'list_assets',
  LIST_CHECK_IN = 'list_check_in',
  LIST_CLIENTS = 'list_clients',
  LIST_DIGITAL_SIGNAGE = 'list_digital_signage',
  LIST_GATEWAY_STATUS = 'list_gateway_status',
  LIST_PROJECTS = 'list_projects',
  LIST_SUPPORT_TICKETS = 'list_support_tickets',
  LIST_USERS = 'list_users',
  READ_ASSETS = 'read_assets',
  READ_CLIENTS = 'read_clients',
  READ_COUNT_USERS_DASHBOARD_INDEX = 'read_count_users_dashboard_index',
  READ_PROJECT = 'read_projects',
  READ_SUPPORT_TICKETS = 'read_support_tickets',
  READ_USERS = 'read_users',
  REGISTER_GATEWAYS = 'register_gateways',
  SEND_COMMUNITY_NOTIFICATIONS = 'send_community_notifications',
  UPDATE_ASSETS = 'update_assets',
  UPDATE_CLIENTS = 'update_clients',
  UPDATE_PROJECTS = 'update_projects',
  UPDATE_SUPPORT_TICKETS = 'update_support_tickets',
  UPDATE_USERS = 'update_users',
  CAMERA_SNAPSHOTS = 'camera_snapshots',
  GENERATE_QR_CODES = 'generate_qr_codes',
  READ_INTERCOMS = 'read_intercoms',
  LIST_INTERCOMS = 'list_intercoms',
  CREATE_INTERCOMS = 'create_intercoms',
  UPDATE_INTERCOMS = 'update_intercoms',
  DELETE_INTERCOMS = 'delete_intercoms',
  READ_LEGAL_DOCUMENTATION = 'read_legal_documentation',
}

export enum UserRoleType {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_ALFRED_EXPERT = 'ROLE_ALFRED_EXPERT',
  ROLE_ALFRED_PRO = 'ROLE_ALFRED_PRO',
  ROLE_ASSETS_MANAGER_SUPER_ADMIN = 'ROLE_ASSETS_MANAGER_SUPER_ADMIN',
  ROLE_ASSETS_MANAGER_ADMIN = 'ROLE_ASSETS_MANAGER_ADMIN',
  ROLE_ASSETS_MANAGER_PROPERTY_MANAGER = 'ROLE_ASSETS_MANAGER_PROPERTY_MANAGER',
  ROLE_ASSETS_MANAGER_USER = 'ROLE_ASSETS_MANAGER_USER',
  ROLE_HOME_AUTOMATION_USER = 'ROLE_HOME_AUTOMATION_USER',
  ROLE_PUBLIC_API_USER = 'ROLE_PUBLIC_API_USER',
  ROLE_USER = 'ROLE_USER',
}

export type UserPermissions =
  | 'assign_client_to_user'
  | 'assign_gateway'
  | 'unassign_gateway'
  | 'create_assets'
  | 'create_clients'
  | 'create_projects'
  | 'delete_assets'
  | 'delete_clients'
  | 'delete_projects'
  | 'delete_support_tickets'
  | 'delete_users'
  | 'edit_current_user'
  | 'invite_users_role_expert'
  | 'invite_users_role_property_manager'
  | 'invite_users_role_user'
  | 'invite_users'
  | 'list_academy_docs'
  | 'list_assets_status'
  | 'list_assets'
  | 'list_clients'
  | 'list_check_in'
  | 'list_digital_signage'
  | 'list_gateway_status'
  | 'list_projects'
  | 'list_support_tickets'
  | 'list_users'
  | 'read_assets'
  | 'read_clients'
  | 'read_count_users_dashboard_index'
  | 'read_projects'
  | 'read_support_tickets'
  | 'read_users'
  | 'register_gateways'
  | 'send_community_notifications'
  | 'update_assets'
  | 'update_clients'
  | 'update_projects'
  | 'update_support_tickets'
  | 'update_users'
  | 'camera_snapshots'
  | 'generate_qr_codes'
  | 'read_intercoms'
  | 'list_intercoms'
  | 'create_intercoms'
  | 'update_intercoms'
  | 'delete_intercoms'
  | 'read_legal_documentation'
  | 'read_access_list'
  | 'read_gateways_list'
  | 'edit_gateway';

export type UserRole =
  | 'ROLE_ADMIN'
  | 'ROLE_ALFRED_EXPERT'
  | 'ROLE_ALFRED_PRO'
  | 'ROLE_ASSETS_MANAGER_PROPERTY_MANAGER'
  | 'ROLE_ASSETS_MANAGER_USER'
  | 'ROLE_HOME_AUTOMATION_USER'
  | 'ROLE_PUBLIC_API_USER'
  | 'ROLE_USER'
  | 'ROLE_ASSETS_MANAGER_SUPER_ADMIN'
  | 'ROLE_ASSETS_MANAGER_ADMIN';

export type UsersResponse = ListResponseBasic<User>;

export type RegisterUser = {
  name: string;
  email: string;
  password: string;
  cellphone: string;
  terms_of_service: boolean;
  token: string;
};
