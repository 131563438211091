import { FC } from 'react';
import ClientCustomizationLoadingTemplate from 'components/template/ClientCustomizationLoadingTemplate';
import { useLegalDocumentation } from './resources/useLegalDocumentation';
import LegalDocumentationManager from './resources/LegalDocumentationManager';
import LegalDocumentationCreateModal from 'components/organisms/LegalDocumentationCreateModal';
import Paper from 'components/atoms/Paper';
import LegalDocumentationDeleteConfirmModal from 'components/organisms/LegalDocumentationDeleteConfirmModal';
import LegalDocumentationDisableModal from 'components/organisms/LegalDocumentationDisableModal';

const TabLegalDocumentation: FC = () => {
  const {
    form,
    acceptedFormatFiles,
    emptyTemplateTranslations,
    emptyModalTranslations,
    deleteConfirmationTranslations,
    disableConfirmationTranslations,
    contentTranslations,
    templateVisor,
    legalDocumentation,
    onCreate,
    onCloseCreateModal,
    onEnable,
    onDisable,
    onSaveForm,
    onCloseForm,
    onDeleteForm,
    onChangeValueForm,
    onChangeActionForm,
    onCheckBeforeUploadValueForm,
    onConfirmDeleteForm,
    onCloseDeleteConfirmationModal,
    onCloseConfirmDisable,
    onConfirmDisable,
    onConfirmEnable,
    loading,
    processing,
    enabledSwitch,
    createModalVisible,
    termsOfServiceUploadButtonDisabled,
    termsOfServiceUploadInfoVisible,
    privacyPolicyUploadButtonDisabled,
    privacyPolicyUploadInfoVisible,
    saveButtonDisabled,
    deleteButtonVisible,
    deleteConfirmationModalVisible,
    disableConfirmationModalVisible,
    isDefault,
  } = useLegalDocumentation();
  const { title, text, mainButtonText, secondaryButtonText } = emptyModalTranslations;
  const {
    title: deleteTitle,
    text: deleteText,
    mainButtonText: deleteMainButtonText,
    secondaryButtonText: deleteSecondaryButtonText,
  } = deleteConfirmationTranslations;
  const {
    title: disableTitle,
    text: disableText,
    mainButtonText: disableMainButtonText,
    secondaryButtonText: disableSecondaryButtonText,
  } = disableConfirmationTranslations;


  return (
    <>
      <Paper>
        {loading && <ClientCustomizationLoadingTemplate />}
        {!loading &&
          <LegalDocumentationManager
            form={form}
            acceptedFormatFiles={acceptedFormatFiles}
            templateVisorStatus={templateVisor}
            emptyTranslations={emptyTemplateTranslations}
            contentTranslations={contentTranslations}
            legalDocumentation={legalDocumentation}
            onCreate={onCreate}
            onEnable={onEnable}
            onDisable={onDisable}
            onSaveForm={onSaveForm}
            onCloseForm={onCloseForm}
            onDeleteForm={onDeleteForm}
            onChangeValueForm={onChangeValueForm}
            onChangeActionForm={onChangeActionForm}
            onCheckBeforeUploadValueForm={onCheckBeforeUploadValueForm}
            processing={processing}
            enabledSwitch={enabledSwitch}
            termsOfServiceUploadButtonDisabled={termsOfServiceUploadButtonDisabled}
            termsOfServiceUploadInfoVisible={termsOfServiceUploadInfoVisible}
            privacyPolicyUploadButtonDisabled={privacyPolicyUploadButtonDisabled}
            privacyPolicyUploadInfoVisible={privacyPolicyUploadInfoVisible}
            saveButtonDisabled={saveButtonDisabled}
            deleteButtonVisible={deleteButtonVisible}
            isDefault={isDefault}
          />}
      </Paper>
      <LegalDocumentationCreateModal
        visible={createModalVisible}
        onCancel={onCloseCreateModal}
        onClick={onConfirmEnable}
        title={title}
        text={text}
        mainButtonText={mainButtonText}
        secondaryButtonText={secondaryButtonText}
      />
      <LegalDocumentationDeleteConfirmModal
        visible={deleteConfirmationModalVisible}
        onCancel={onCloseDeleteConfirmationModal}
        onClick={onConfirmDeleteForm}
        title={deleteTitle}
        text={deleteText}
        mainButtonText={deleteMainButtonText}
        secondaryButtonText={deleteSecondaryButtonText}
        loading={processing}
      />
      <LegalDocumentationDisableModal
        visible={disableConfirmationModalVisible}
        onCancel={onCloseConfirmDisable}
        onClick={onConfirmDisable}
        title={disableTitle}
        text={disableText}
        mainButtonText={disableMainButtonText}
        secondaryButtonText={disableSecondaryButtonText}
        loading={processing}

      />
    </>
  );
}

export default TabLegalDocumentation;