import { useEffect, useState } from 'react';
import { GetSensorsListByProjectId } from 'core/domain/sensors/repositories/getSensorsListByProjectId';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';

export const useSensorsProject = ({ projectId }: { projectId: string }) => {
  const [sensorList, setSensorsList] = useState<SensorByProjectModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    GetSensorsListByProjectId(projectId)
      .then((data) => data && setSensorsList(data))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const getSensorById = (id: string): SensorByProjectModel | undefined => {
    return sensorList.find((item) => item.id === id);
  };

  return { sensorList, getSensorById, loading };
};
