import { FC } from 'react';
import { Card } from 'antd';
import Paper from 'components/atoms/Paper';
import { VideosAcademy } from 'models/academy.model';
import dataVideos from './videos.json';
import './styles.scss';

const TabVideos: FC = () => {
  const items = (dataVideos as unknown) as VideosAcademy[];

  return (
    <Paper>
      <div className='TabVideos__grid'>
        {items.map((item, key) => (
          <Card
            key={key}
            className='TabVideos__card'
            bordered
            cover={
              <iframe
                height='315'
                src={item.src}
                title={item.title}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            }
          >
            <Card.Meta title={item.title} description={item.description} />
          </Card>
        ))}
      </div>
    </Paper>
  );
};

export default TabVideos;
