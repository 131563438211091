import { FC } from 'react';
import { renderRoutes, RouteConfig } from 'react-router-config';
import AdminTemplate from 'components/template/AdminTemplate';
import { useAdmin } from './resources/useAdmin';
import { MenuItemsConfig } from './resources/config';
import AdminLoadingTemplate from 'components/template/AdminLoadingTemplate';
import { RouteComponentProps } from 'react-router-dom';

interface PrivatePageProps extends RouteComponentProps {
  route?: RouteConfig;
}

const AdminPage: FC<PrivatePageProps> = ({ route }) => {
  const MenuItems = MenuItemsConfig().filter((item) => !item.hidden);
  const {
    headerCustomization,
    onClickMenuItem,
    onActiveMenuItem,
    onReachLayoutBreakpoint,
    onCloseMenu,
    onClickMenuIcon,
    userProfileLoading,
    visibleDrawer,
    visibleMenuIcon,
    logoRoute,
  } = useAdmin();

  return (
    <>
      {userProfileLoading && (
        <AdminLoadingTemplate
          sideMenuItems={MenuItems}
          onReachLayoutBreakpoint={onReachLayoutBreakpoint}
        />
      )}
      {!userProfileLoading && (
        <AdminTemplate
          headerCustomization={headerCustomization}
          sideMenuItems={MenuItems}
          activeMenuItem={onActiveMenuItem()}
          onClickMenuItem={onClickMenuItem}
          onReachLayoutBreakpoint={onReachLayoutBreakpoint}
          onCloseMenu={onCloseMenu}
          onClickMenuIcon={onClickMenuIcon}
          visibleMenuIcon={visibleMenuIcon}
          visibleDrawer={visibleDrawer}
          logoRoute={logoRoute}
        >
          {renderRoutes(route?.routes)}
        </AdminTemplate>
      )}
    </>
  );
};

export default AdminPage;
