import { QrErrorModalLiteralsModel } from 'components/organisms/QidErrorModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface CheckQrErrorModel {
  qid: string | null;
  did: string;
}

export interface QrErrorModalOptionsModel {
  qidErrorValue: string | null;
  didErrorValue: string | null;
  qrErrorModalLiterals: QrErrorModalLiteralsModel;
  onAcceptErrors: () => void;
  qidError: boolean;
  didError: boolean;
  qrErrorModalVisible: boolean;
}

const INITIAL_QID_LETTER = 'Q';
const INITIAL_DID_LETTER = 'D';

export const useQrError = () => {
  const { t } = useTranslation();
  const [qid, setQid] = useState<string | null>(null);
  const [did, setDid] = useState<string | null>(null);
  const [qidError, setQidError] = useState<boolean>(false);
  const [didError, setDidError] = useState<boolean>(false);
  const [qrErrorModalVisible, setQrErrorModalVisible] = useState<boolean>(false);
  const [errorsChecked, setErrorsChecked] = useState<boolean>(false);

  const qrErrorModalLiterals: QrErrorModalLiteralsModel = {
    title: t('attention'),
    infoText: t('MESSAGE_QR_CREATE_ASSET.info'),
    requiredText: t('required'),
    scannedText: t('qr_scan'),
    confirmText: t('MESSAGE_QR_CREATE_ASSET.confirm'),
  };

  const onCloseQrErrorModal = () => {
    setQrErrorModalVisible(false);
  };

  const onAcceptErrors = () => {
    onCloseQrErrorModal();
    setQidError(false);
    setDidError(false);
  };

  const resetQrErrorsValues = async () => {
    setErrorsChecked(false);
  };

  const checkForQidError = async (qid: string | null): Promise<boolean> => {
    const isQidError = !!qid && qid[0] !== INITIAL_QID_LETTER;
    setQidError(isQidError);
    setQid(qid);
    return isQidError;
  };

  const checkForDidError = async (did: string): Promise<boolean> => {
    const isDidError = did[0] !== INITIAL_DID_LETTER;
    setDidError(isDidError);
    setDid(did);
    return isDidError;
  };

  const checkIfQrErrors = async ({ qid, did }: CheckQrErrorModel) => {
    setErrorsChecked(false);
    try {
      const isQidError = await checkForQidError(qid);
      const isDidError = await checkForDidError(did);
      const hasErrors = isQidError || isDidError;
      setQrErrorModalVisible(hasErrors);
      setErrorsChecked(true);
    } catch (error) {
      console.warn(error);
    }
  };

  const qrErrorModalOptions: QrErrorModalOptionsModel = {
    qidErrorValue: qid,
    didErrorValue: did,
    qrErrorModalLiterals,
    onAcceptErrors,
    qidError,
    didError,
    qrErrorModalVisible,
  };

  return { qrErrorModalOptions, checkIfQrErrors, resetQrErrorsValues, onCloseQrErrorModal, errorsChecked };
};
