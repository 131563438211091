import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleTwoTone,
  WarningTwoTone,
  ClockCircleTwoTone,
  ScheduleTwoTone,
  LockTwoTone,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { COLORS } from 'constants/colors';
import { SupportTicketStatusType } from 'core/domain/supportTickets/models/supportTicketsPayloadModel';

interface AsSupportTicketStatusProps {
  status: SupportTicketStatusType;
  showIcon?: boolean;
}

const ICONS = {
  accepted: <CheckCircleTwoTone twoToneColor={COLORS.success} />,
  revoked: <WarningTwoTone twoToneColor={COLORS.alert} />,
  opened: <ClockCircleTwoTone twoToneColor={COLORS.link} />,
  assigned: <ScheduleTwoTone twoToneColor={COLORS.corporate} />,
  closed: <LockTwoTone twoToneColor={COLORS.gray_extra_light} />,
  all_statuses: <InfoCircleOutlined className='Icon__gray-extra-light' />,
};

const AsSupportTicketStatus: FC<AsSupportTicketStatusProps> = ({ status, showIcon = true }) => {
  const { t } = useTranslation();
  const formattedStatus = status.toLowerCase() as SupportTicketStatusType;

  return (
    <span>
      {showIcon && ICONS[formattedStatus]} {t(formattedStatus)}
    </span>
  );
};

export default AsSupportTicketStatus;
