import { FC } from 'react';
import { Table } from 'antd';
import { SupportTicketModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import { useSupportTicketSummary } from 'components/pages/AssetPage/Tabs/TabStart/resources/useSupportTicketSummary';
import './styles.scss';

const MAX_SIZE_RESPONSIVE_SCREEN = 600;

interface SupportTicketsSummaryProps {
  assetId?: string;
  className?: string;
}

const SupportTicketsSummary: FC<SupportTicketsSummaryProps> = ({ className }) => {
  const {
    columns,
    loadingSupportTickets,
    onRowClick,
    supportTickets,
    title,
  } = useSupportTicketSummary();

  return (
    <div className={className}>
      <div className='SupportTicketsSummary'>
        <h2>{title}</h2>
      </div>
      <Table<SupportTicketModel>
        key={'SupportTicketsSummary'}
        loading={loadingSupportTickets}
        dataSource={supportTickets}
        columns={columns}
        pagination={false}
        scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        rowKey={'id'}
        onRow={(item) => {
          return {
            onClick: () => onRowClick(item),
          };
        }}
      />
    </div>
  );
};

export default SupportTicketsSummary;
