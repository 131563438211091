import { FC } from 'react';

interface Props {
  url: string | null;
  children: string;
  styleLink: boolean;
}

const GatewayLink: FC<Props> = ({ url, children, styleLink = true }) => {
  const isStyleLink = styleLink ? 'Text__link' : '';

  return (
    <>
      {url && <a
        className={isStyleLink}
        href={url}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        {children}
      </a>}
      {!url && <span className={'Text__disabled'}>{children}</span>}
    </>
  )
}

export default GatewayLink;
