import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import AsSupportTicketStatus from 'components/atoms/AsSupportTicketStatus';
import { SupportTicketStatusType } from 'core/domain/supportTickets/models/supportTicketsPayloadModel';
import './styles.scss';

export type SupportTicketStatusAll = 'all_statuses' | SupportTicketStatusType;

interface AsSupportTicketStatusSelectProps {
  disabled?: boolean;
  loading?: boolean;
  status?: SupportTicketStatusType;
  showIcon?: boolean;
  onChange?: (newStatus: SupportTicketStatusType) => void;
}

const AsSupportTicketStatusSelect: FC<AsSupportTicketStatusSelectProps> = ({ disabled = false, loading = false, status, showIcon = true, onChange }) => {
  const { t } = useTranslation();

  const handleOnChange = async (newStatus: SupportTicketStatusType) => {
    onChange && (await onChange(newStatus));
  };

  return (
    <Select
      disabled={disabled}
      loading={loading}
      defaultValue={status}
      className='AsSupportTicketStatusSelect__selected'
      onChange={(newStatus) => handleOnChange(newStatus)}
    >
      {Object.keys(SupportTicketStatusType).map((status) => {
        return status ? (
          <Select.Option key={status} value={status.toLowerCase()}>
            <AsSupportTicketStatus status={status as SupportTicketStatusType} showIcon={showIcon} />
          </Select.Option>
        ) : (
          t('not_reported')
        );
      })}
    </Select>
  );
};

export default AsSupportTicketStatusSelect;
