import { FC, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { getCameraSnapshot } from 'services/api/cameras';
import './styles.scss'

interface CameraSnapshotProp {
  snapshotUrl: string
}

const POLLING_INTERVAL = 5000;

const CameraSnapshot: FC<CameraSnapshotProp> = ({snapshotUrl}) => {
  const [img, setImg] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSnapshot = () => {
      getCameraSnapshot(snapshotUrl)
        .then((response: string) => {
          setImg(response)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    setLoading(true);
    getSnapshot()
    const interval = setInterval(async () => {
      try {
        await getSnapshot()
      } catch (e){
        clearInterval(interval)
      }

    }, POLLING_INTERVAL)

    return () => clearInterval(interval);
  }, [snapshotUrl])

  return (
    <div className='CameraSnapshot'>
      {loading ?
        <LoadingOutlined style={{ fontSize: 24 }} spin /> :
        <img width='100%' src={img} alt={'snapshot'}/>
      }
    </div>
  )
}

export default CameraSnapshot;
