import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { GetCommonAreaListByProjectId } from 'core/domain/bookingsAndCommonAreas/repositories/getCommonAreaListByProjectId';
import { CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import {
  getCommonAreaCreatePath,
  getCommonAreaDetailPath,
  getCommonAreasBookingsListPath,
} from 'components/pages/App/routes/commonAreas/config';

export interface CommonAreasAndBookingsListMenuItem {
  id: string;
  name: string;
  disabled: boolean;
}

enum RouteSegmentType {
  CLIENT_AND_PROJECT_SEGMENT = 8,
  COMMON_AREA_DETAIL_SEGMENT = 9,
  COMMON_AREA_UPDATE_SEGMENT = 10,
}

const BOOKINGS_LIST_ROUTE = 'bookings';
const COMMON_AREA_DETAIL_ROUTE = 'common-area';

export const useCommonAreasAndBookingsMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>();
  const [isLoadingCommonAreasList, setIsLoadingCommonAreasList] = useState<boolean>(false);
  const [isCreateCommonAreaButtonDisabled, setIsCreateCommonAreaButtonDisabled] = useState<boolean>(false);
  const [isShowBookingListButtonDisabled, setIsShowBookingListButtonDisabled] = useState<boolean>(false);
  const [commonAreasList, setCommonAreasList] = useState<CommonAreasAndBookingsListMenuItem[]>([]);
  const [selectedCommonArea, setSelectedCommonArea] = useState<string>('');
  const [isPageAlreadyLoaded, setIsPageAlreadyLoaded] = useState<boolean>(false);
  const [isUpdatingCommonArea, setIsUpdatingCommonArea] = useState<boolean>(false);

  const translations = {
    bookingsListButtonText: t('_COMMON_AREA_BOOKINGS'),
    createCommonAreaButtonText: t('_COMMON_AREA_ADD_SPACE'),
  };

  const transformCommonAreasList = (commonAreas: CommonAreaModel[]): CommonAreasAndBookingsListMenuItem[] => {
    return commonAreas.map(({ id, name }) => ({ id, name, disabled: false }));
  };

  const getCommonAreasList = async (): Promise<void> => {
    setIsLoadingCommonAreasList(true);

    try {
      const response = await GetCommonAreaListByProjectId(projectId);
      if (!!response) {
        const transformedCommonAreasList = transformCommonAreasList(response);
        setCommonAreasList(transformedCommonAreasList);
        setIsPageAlreadyLoaded(true);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingCommonAreasList(false);
    }
  };

  const updateCommonAreasListState = (disabled: boolean) => {
    setSelectedCommonArea('');
    const newCommonAreasList = commonAreasList.map(({ id, name }) => ({ id, name, disabled }));
    setCommonAreasList(newCommonAreasList);
  };

  const navigateToCommonAreaCreatePage = () => {
    const commonAreaCreateRoute = getCommonAreaCreatePath({ clientId, projectId });
    history.push(commonAreaCreateRoute);
  };

  const navigateToCommonAreaDetailPage = (commonAreaId: string) => {
    const commonAreaDetailRoute = getCommonAreaDetailPath({ clientId, projectId, commonAreaId });
    history.push(commonAreaDetailRoute);
  };

  const navigateToCommonAreasBookingsList = () => {
    const bookingsListRoute = getCommonAreasBookingsListPath({ clientId, projectId });
    history.push(bookingsListRoute);
  };

  const onCreateCommonArea = () => {
    setSelectedCommonArea('');
    setIsCreateCommonAreaButtonDisabled(true);
    setIsShowBookingListButtonDisabled(false);
    updateCommonAreasListState(true);
    navigateToCommonAreaCreatePage();
  };

  const onSelectCommonArea = (commonAreaId: string) => {
    setSelectedCommonArea(commonAreaId);
    setIsCreateCommonAreaButtonDisabled(false);
    setIsShowBookingListButtonDisabled(false);
    navigateToCommonAreaDetailPage(commonAreaId);
  };

  const checkIfCommonAreasListHasToBeRefreshed = () => {
    const isNavigationGoingBack = history.action === 'POP';
    return !isNavigationGoingBack && isUpdatingCommonArea;
  };

  const refreshCommonAreasList = () => {
    setIsUpdatingCommonArea(false);
    getCommonAreasList();
  };

  const onShowBookingsList = () => {
    setSelectedCommonArea('');
    setIsCreateCommonAreaButtonDisabled(false);
    setIsShowBookingListButtonDisabled(true);
    const hasCommonAreasListToBeRefreshed = checkIfCommonAreasListHasToBeRefreshed();
    hasCommonAreasListToBeRefreshed ? refreshCommonAreasList() : updateCommonAreasListState(false);
    navigateToCommonAreasBookingsList();
  };

  const onCreateCommonAreaFromChildrenPage = () => {
    setIsCreateCommonAreaButtonDisabled(true);
    setIsShowBookingListButtonDisabled(false);
  };

  const updateCommonAreaAndBookingListButtons = (section: string) => {
    section.includes(BOOKINGS_LIST_ROUTE) && onShowBookingsList();
    section.includes(COMMON_AREA_DETAIL_ROUTE) && onCreateCommonAreaFromChildrenPage();
  };

  const updateCommonAreasList = () => {
    setIsCreateCommonAreaButtonDisabled(false);
    setIsShowBookingListButtonDisabled(false);
    updateCommonAreasListState(false);
    getCommonAreasList();
  };

  const routeManager = {
    [RouteSegmentType.CLIENT_AND_PROJECT_SEGMENT]: (section: string) => updateCommonAreaAndBookingListButtons(section),
    [RouteSegmentType.COMMON_AREA_DETAIL_SEGMENT]: () => updateCommonAreasList(),
    [RouteSegmentType.COMMON_AREA_UPDATE_SEGMENT]: () => setIsUpdatingCommonArea(true),
  };

  useEffect(() => {
    const splitRoute = window.location.pathname.split('/');
    const index = splitRoute.length;
    routeManager[index as RouteSegmentType](splitRoute[7]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    const splitRoute = window.location.pathname.split('/');
    splitRoute.length === 9 && setSelectedCommonArea(splitRoute[8]);
  }, [commonAreasList]);

  useEffect(() => {
    getCommonAreasList();
    onShowBookingsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, projectId]);

  return {
    commonAreasList,
    translations,
    selectedCommonArea,
    onCreateCommonArea,
    onSelectCommonArea,
    onShowBookingsList,
    loadingCommonAreasList: isLoadingCommonAreasList,
    createCommonAreaButtonDisabled: isCreateCommonAreaButtonDisabled,
    showBookingListButtonDisabled: isShowBookingListButtonDisabled,
    pageAlreadyLoaded: isPageAlreadyLoaded,
  };
};
