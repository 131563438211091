import {
  GatewayDetailStatusPayload,
  GatewaySharedSensorPayload,
  GatewayUnparsed,
  LinksPayloadType,
  ManagementLinksPayload,
} from '../model/gateway/gatewayUnparsed';
import {
  GatewayDetailStatusModel,
  GatewayParsed,
  GatewaySharedSensorModel,
  LinksModelType,
  ManagementLinksModel,
} from '../model/gateway/gatewayParsed';
import { payloadToGatewayStatusUnits } from './payloadToGatewayStatus';
import { payloadToGatewayDevices } from './payloadToGatewayDevices';
import { payloadToGatewaySensor } from './payloadToGatewaySensor';
import { payloadToThresholds } from './payloadToThresholds';
import { GatewayNetworkStateStatusModel } from '../model/gatewayStatus/gatewayStatusPayload';

const linksManager = (managementLinks?: ManagementLinksPayload): ManagementLinksModel => {
  const control_panel = (managementLinks && managementLinks[LinksPayloadType.CONTROL_PANEL]) || '';
  const openhab = (managementLinks && managementLinks[LinksPayloadType.OPENHAB]) || '';
  const ssh_teleport = (managementLinks && managementLinks[LinksPayloadType.SSH_TELEPORT]) || '';

  return {
    [LinksModelType.CONTROL_PANEL]: control_panel.length ? control_panel : null,
    [LinksModelType.OPENHAB]: openhab.length ? openhab : null,
    [LinksModelType.SSH_TELEPORT]: ssh_teleport.length ? ssh_teleport : null,
  };
};

const payloadToGatewayDetailStatus = ({
  gateway_id,
  asset_id,
  project_id,
  location,
  vpn_ip,
  gateway_version,
  hw_version,
  knx_version,
  firmware_version,
  helper_version,
  zwave_version,
  deployment_id,
  uptime,
  last_connection,
  time_offline,
  production_date,
  cpu_temperature,
  cpu_ram_used,
  cpu_hdd_used,
  health,
  total_inodes,
  total_swap,
  used_swap,
  used_inodes,
  cpu_load,
  zwave_controller,
  vpn_connection,
  helper_service,
  helper_api,
  assistant_cloud_service,
  active_issues,
  thresholds,
  network_interface,
  network_state,
  units,
}: GatewayDetailStatusPayload): GatewayDetailStatusModel => {
  const transformedThresholds = payloadToThresholds(thresholds);
  const transformedUnits = !!units ? payloadToGatewayStatusUnits(units) : null;

  return {
    gatewayId: gateway_id ?? '',
    assetId: asset_id ?? '',
    projectId: project_id ?? '',
    location: location,
    vpnIp: vpn_ip ?? '',
    gatewayVersion: gateway_version ?? '',
    hwVersion: hw_version ?? '',
    knxVersion: knx_version ?? '',
    firmwareVersion: firmware_version ?? '',
    helperVersion: helper_version ?? '',
    zwaveVersion: zwave_version ?? '',
    deploymentId: deployment_id ?? '',
    uptime: uptime ?? null,
    lastConnection: last_connection ?? null,
    timeOffline: time_offline ?? null,
    productionDate: production_date ?? null,
    cpuTemperature: cpu_temperature ?? null,
    cpuRamUsed: cpu_ram_used ?? null,
    cpuHddUsed: cpu_hdd_used ?? null,
    health: health ?? null,
    totalInodes: total_inodes ?? null,
    totalSwap: total_swap ?? null,
    usedSwap: used_swap ?? null,
    usedInodes: used_inodes ?? null,
    cpuLoad: cpu_load ?? null,
    zwaveController: zwave_controller ?? null,
    vpnConnection: vpn_connection ?? null,
    helperService: helper_service ?? null,
    helperApi: helper_api ?? null,
    assistantCloudService: assistant_cloud_service ?? null,
    activeIssues: active_issues ?? null,
    thresholds: transformedThresholds,
    networkInterface: network_interface ?? null,
    networkState: {
      bandWidth: network_state?.bandwidth ?? null,
      latency: network_state?.latency ?? null,
      percentagePackageLost: network_state?.percentage_package_lost ?? null,
      status: network_state?.status ?? GatewayNetworkStateStatusModel.UNKNOWN,
    },
    units: transformedUnits,
  };
};

const payloadToGatewaySharedSensor = ({ sensor_uuid, assets }: GatewaySharedSensorPayload): GatewaySharedSensorModel => {
  return {
    sensorUuid: sensor_uuid ?? '',
    assets: assets ?? [],
  };
};

export const payloadToGateway = ({
  id,
  assistant_cloud_url,
  assistant_cloud,
  batch_id,
  batch_name,
  daemon_version,
  deployed_at,
  deployment_date,
  devices,
  firmware_version,
  gateway_version,
  helper_version,
  hw_version,
  knx_version,
  management_links,
  master_user,
  os_version,
  production_date,
  project_id,
  qr_code,
  sku,
  status,
  voice_assistant_user,
  zwave_version,
  type,
  sensors,
  shared_sensors_visibility,
}: GatewayUnparsed): GatewayParsed => {
  const gatewayDevices = !!devices ? devices.map((item) => payloadToGatewayDevices(item)) : [];
  const gatewaysSensors = !!sensors ? sensors.map((item) => payloadToGatewaySensor(item)) : [];
  const gatewayStatusParser = !!status ? payloadToGatewayDetailStatus(status) : null;
  const managementLinksParsed = linksManager(management_links);
  const transformSharedSensors = shared_sensors_visibility?.map((sharedSensor) => payloadToGatewaySharedSensor(sharedSensor)) ?? null;

  return {
    id,
    assistantCloud: assistant_cloud ?? '',
    assistantCloudUrl: assistant_cloud_url ?? '',
    batchId: batch_id ?? '',
    batchName: batch_name ?? '',
    daemonVersion: daemon_version ?? '',
    deployedAt: deployed_at,
    deploymentDate: deployment_date || null,
    devices: gatewayDevices,
    firmwareVersion: firmware_version ?? '',
    gatewayVersion: gateway_version ?? '',
    helperVersion: helper_version ?? '',
    hwVersion: hw_version ?? '',
    knxVersion: knx_version ?? '',
    managementLinks: managementLinksParsed,
    masterUser: master_user ?? '',
    osVersion: os_version ?? '',
    productionDate: Number(production_date) ?? 0,
    projectId: project_id ?? '',
    qrCode: qr_code ?? '',
    sku: sku ?? null,
    status: gatewayStatusParser,
    voiceAssistantUser: voice_assistant_user ?? '',
    zwaveVersion: zwave_version ?? '',
    type: type ?? '',
    sensors: gatewaysSensors,
    sharedSensors: transformSharedSensors,
  };
};
