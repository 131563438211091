import { UsersListUnstructuredStatisticsModel } from '../models/usersModel';

const UsersListStatisticsEntity = ({
  average,
  labels,
  values,
}: UsersListUnstructuredStatisticsModel): Readonly<UsersListUnstructuredStatisticsModel> => {
  return Object.freeze({ average, labels, values });
};

export default UsersListStatisticsEntity;
