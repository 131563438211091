import { uploadTenantsTemplatePath } from 'core/endspoints/users';
import { TenantsPreviewDataResponseModel } from '../models/userModel';
import { tenantsListToBeUploaded } from '../mappers/tenantsToPayload';
import api from 'services/api';

export const UploadTenantsTemplate = async (tenants: TenantsPreviewDataResponseModel[]): Promise<void> => {
  const url = uploadTenantsTemplatePath();
  api.defaults.headers.common['Accept-version'] = '1';
  const previewData = tenantsListToBeUploaded(tenants);

  try {
    return await api.post(url, { data: { preview_data: previewData } });
  } catch (error: any) {
    throw new Error(error);
  }
};
