import { ClientModel } from '../models/clientEntityModel';

const ClientEntity = ({
  id,
  name,
  description,
  email,
  taxId,
  clientCode,
  createdAt,
  updatedAt,
  lookAndFeel,
}: ClientModel): Readonly<ClientModel> => {
  return Object.freeze({
    id,
    name,
    description,
    email,
    taxId,
    clientCode,
    createdAt,
    updatedAt,
    lookAndFeel,
  });
};

export default ClientEntity;
