import { FC } from 'react';
import ModalAtom from 'components/atoms/ModalAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { Checkbox } from 'antd';
import QRScanner from 'components/atoms/QRScanner';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import Spinner from 'components/atoms/Spinner';
import './styles.scss';

interface Props {
  title: string;
  cancelText: string;
  refreshText: string;
  checkText: string;
  onCancel: () => void;
  onScanQr: (qrCode: string) => void;
  onRefresh: () => void;
  onFollowingScan: (checked: boolean) => void;
  isVisible: boolean;
  isLoading: boolean;
  isFollowingScan: boolean;
}

const CheckInScannerQRModal: FC<Props> = ({
  title,
  cancelText,
  refreshText,
  checkText,
  onCancel,
  onScanQr,
  onRefresh,
  onFollowingScan,
  isVisible,
  isLoading,
  isFollowingScan,
}) => {

  const Loading = () => (
    <div className={'CheckInQRModal__loading'}>
      <Spinner />
    </div>
  )

  const Scan = () => (
    <div className={'CheckInQRModal__scan'}>
      <QRScanner onScan={onScanQr} />
    </div>
  )

  return (
    <ModalAtom
      width={'496px'}
      title={title}
      className={'CheckInQRModal'}
      onCancel={onCancel}
      open={isVisible}
      footer={
        <div className={'CheckInQRModal__footer'}>
          <Checkbox
            checked={isFollowingScan}
            onChange={() => onFollowingScan(!isFollowingScan)}
          >
            {checkText}
          </Checkbox>
          <div>
            <DefaultButton
              key={'cancel'}
              onClick={onCancel}
              title={cancelText}
            />
            <PrimaryButton
              key={'refresh'}
              onClick={onRefresh}
              title={refreshText}
            />
          </div>
        </div>
      }
      destroyOnClose
    >
      <>
        {!isLoading ? <Scan /> : <Loading />}
      </>
    </ModalAtom>
  )
}


export default CheckInScannerQRModal;
