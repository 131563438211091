import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import AlfredTableTemplate from '../AlfredTableTemplate';
import { CheckInHistoryCustomDataModel } from 'components/pages/AccessesVerticals/hotel/CheckInListTab/resources/utils';
import SpaceAtom from 'components/atoms/SpaceAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import './styles.scss';

interface Props {
  keyReaderButton: string;
  checkInList: CheckInHistoryCustomDataModel[];
  totalPages: number;
  currentPage: number;
  pageSize: number;
  columns: AlfredTableColumn<CheckInHistoryCustomDataModel>[]
  onKeyReader: () => void;
  onChangePage: (page: number, pageSize?: number | undefined) => void;
  loading: boolean;
}

const CheckInListTemplate: FC<Props> = ({
  columns,
  checkInList,
  totalPages,
  currentPage,
  pageSize,
  keyReaderButton,
  onKeyReader,
  onChangePage,
  loading,
}) => {

  return (
    <Paper className={'CheckInListTemplate__wrapper'}>
      <AlfredTableTemplate<CheckInHistoryCustomDataModel>
        columns={columns}
        data={checkInList}
        totalPages={totalPages}
        pageCurrent={currentPage}
        pageSize={pageSize}
        onPagination={onChangePage}
        isCharging={loading}
        actionSectionRight={
          <SpaceAtom>
            <DefaultButton
              onClick={onKeyReader}
              title={keyReaderButton}
            />
          </SpaceAtom>
        }
      />
    </Paper>
  )
}

export default CheckInListTemplate;