import api from 'services/api';
import { fileToBase64 } from 'utils/documents';
import { createDocumentsByAssetIdPath } from 'core/endspoints/documents';
import { DocumentUploadFile } from '../model/documentPayloadModels';

interface CreateDocumentByAssetIdParams {
  assetId: string;
  files: any[];
}

export const CreateDocumentsByAssetId = async ({ assetId, files }: CreateDocumentByAssetIdParams): Promise<void> => {
  const path = createDocumentsByAssetIdPath(assetId);
  const documents = await Promise.all(
    files.map(async (file) => {
      const fileContents = await fileToBase64(file);
      return { name: file.name, base64: fileContents } as DocumentUploadFile;
    }) as Promise<DocumentUploadFile>[]
  );
  await Promise.all(
    documents.map((document) => {
      const data = {
        title: document.name,
        comments: '',
        document: document.base64,
      };
      return api.post(path, { data });
    })
  );
};
