import {
  SensorWithPasswordsModel,
  SensorWithPasswordsParametersModel,
  SensorWithPasswordsPasswordModel,
} from '../models/sensorEntityModels';
import {
  SensorWithPasswordsParametersPayloadModel,
  SensorWithPasswordsPasswordPayloadModel,
  SensorWithPasswordsPayloadModel,
} from '../models/sensorPayloadModels';

const payloadToSensorWithPasswordParameters = ({
  max_digits,
  min_digits,
  valid_digits,
}: SensorWithPasswordsParametersPayloadModel): SensorWithPasswordsParametersModel => {
  return {
    maxDigits: max_digits ?? '',
    minDigits: min_digits ?? '',
    validDigits: valid_digits ?? '',
  };
};

const payloadToSensorWithPasswordPassword = ({
  device_id,
  gateway_id,
  sensor_id,
  sensor_type,
  password,
}: SensorWithPasswordsPasswordPayloadModel): SensorWithPasswordsPasswordModel => {
  return {
    deviceId: device_id ?? '',
    gatewayId: gateway_id ?? '',
    sensorId: sensor_id ?? '',
    sensorType: sensor_type ?? '',
    password: password ?? '',
  };
};

const payloadToSensorWithPassword = ({
  device_name,
  device_parameters,
  device_type,
  sensor_passwords,
}: SensorWithPasswordsPayloadModel): SensorWithPasswordsModel => {
  const transformedDeviceParameters = payloadToSensorWithPasswordParameters(device_parameters);
  const transformedSensorPasswords = sensor_passwords.map((item) => payloadToSensorWithPasswordPassword(item));

  return {
    deviceName: device_name ?? '',
    deviceType: device_type ?? '',
    deviceParameters: transformedDeviceParameters,
    sensorPasswords: transformedSensorPasswords,
  };
};

export const payloadToSensorsListWithPasswords = (sensors: SensorWithPasswordsPayloadModel[]): SensorWithPasswordsModel[] => {
  return sensors.map((item) => payloadToSensorWithPassword(item));
};
