import { FC, useState } from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DocumentManagementModal from '../DocumentManagementModal';
import { DocumentManagementFormValues } from '../DocumentManagementForm';
import DefaultButton from 'components/atoms/Button/DefaultButton';

interface DocumentManagementButtonProps {
  onOk?: () => void;
  onSave: (values: DocumentManagementFormValues) => Promise<void>;
  loading?: boolean;
}

const DocumentManagementButton: FC<DocumentManagementButtonProps> = ({ onOk, onSave, loading = false }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <DefaultButton onClick={() => setShowModal(true)} icon={<CloudUploadOutlined />} loading={loading} title={t('publish_document')} />
      <DocumentManagementModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => {
          setShowModal(false);
          onOk && onOk();
        }}
        onFinish={onSave}
      />
    </>
  );
};

export default DocumentManagementButton;
