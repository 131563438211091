import { FC } from 'react';
import { Svg } from '../model/svg';

const Communities: FC<Svg> = ({
  width = '20',
  height = '16',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 20 16'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <path
      d='M15 16C15.55 16 16 15.55 16 15V4.76C16 4.49 15.89 4.24 15.71 4.05L11.95 0.29C11.76 0.11 11.51 0 11.24 0C10.35 0 9.9 1.08 10.53 1.71L13.85 5.03C13.95 5.12 14 5.25 14 5.38V15C14 15.55 14.45 16 15 16ZM19 16C19.55 16 20 15.55 20 15V3.11C20 2.85 19.89 2.59 19.71 2.4L17.61 0.29C17.42 0.11 17.16 0 16.9 0C16 0 15.56 1.08 16.19 1.71L17.86 3.38C17.95 3.47 18 3.6 18 3.73V15C18 15.55 18.45 16 19 16ZM6 11C6.55 11 7 11.45 7 12V16H11C11.55 16 12 15.55 12 15V6.41C12 6.14 11.89 5.89 11.71 5.7L6.71 0.7C6.32 0.31 5.69 0.31 5.3 0.7L0.3 5.7C0.11 5.89 0 6.15 0 6.41V15C0 15.55 0.45 16 1 16H5V12C5 11.45 5.45 11 6 11Z'
    />
  </svg>
);

export default Communities;
