import { VerticalArea } from "../model/projectLayoutConfigModels";
import { PayloadVerticalArea } from "../model/projectLayoutConfigPayloadModels";
import { payloadToSecondLevelHorizontalArea } from "./payloadToSecondLevelHorizontalArea";

export const payloadToVerticalArea = ({ 
  id, 
  type, 
  image_url, 
  name_en, 
  name_es, 
  project_id, 
  horizontal,
}: PayloadVerticalArea): VerticalArea => {
  const parsedHorizontal = horizontal?.map((item) => payloadToSecondLevelHorizontalArea(item));
  
    return {
      id: id ?? '',
      type: type,
      imageURL: image_url ?? '',
      nameEn: name_en ?? '',
      nameEs: name_es ?? '',
      projectId: project_id ?? '',
      horizontal: parsedHorizontal ?? [],
    }
};