import {
  LogHistoryDataModel,
  LogHistoryDataPayload,
  LogHistoryLinksModel,
  LogHistoryLinksPayload,
  LogHistoryMetaModel,
  LogHistoryMetaPayload,
  LogHistoryResponseModel,
  LogHistoryResponsePayload,
} from "../models";
import { DEFAULT_PAGE_SIZE } from 'components/template/AlfredTableTemplate';

const payloadToLogListData = ({
  action,
  lock_type,
  ts,
  usage_source,
  user_agent,
  user_email,
  user_id,
  username,
  name,
  room,
}: LogHistoryDataPayload): LogHistoryDataModel => {
  return {
    action: action ?? '',
    lockType: lock_type ?? '',
    createdAt: ts ?? 0,
    usageSource: usage_source ?? '',
    userAgent: user_agent ?? '',
    userEmail: user_email ?? '',
    userId: user_id ?? '',
    userName: username ?? '',
    name: name ?? '',
    room: room ?? '',
  };
};

const payloadToLogListMeta = ({
  all_results,
  current_page,
  from,
  page_size,
  results,
  total_pages,
  unit,
  until,
}: LogHistoryMetaPayload): LogHistoryMetaModel => {
  return {
    allResults: all_results ?? 0,
    pageSize: page_size ?? DEFAULT_PAGE_SIZE,
    results: results ?? 0,
    totalPages: total_pages ?? 1,
    currentPage: current_page ?? 1,
    from: from ?? 0,
    unit: unit ?? '',
    until: until ?? 0,
  };
};

const payloadToLogListLinks = ({ first, last, next, prev, self }: LogHistoryLinksPayload): LogHistoryLinksModel => {
  return {
    first: first ?? '',
    last: last ?? '',
    next: next ?? '',
    prev: prev ?? '',
    self: self ?? '',
  };
};

export const payloadToLogList = ({ data, meta, links }: LogHistoryResponsePayload): LogHistoryResponseModel => {
  const transformedData = data.map((item) => payloadToLogListData(item));
  const transformedMeta = payloadToLogListMeta(meta);
  const transformedLinks = payloadToLogListLinks(links);

  return {
    data: transformedData,
    meta: transformedMeta,
    links: transformedLinks,
  };
};