import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CommonAreaModel, UpdateCommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { GetCommonAreaListByProjectId } from 'core/domain/bookingsAndCommonAreas/repositories/getCommonAreaListByProjectId';
import { useSensorsProject } from 'hooks/useSensorsProject';
import { useCommonAreasCRUD } from 'hooks/useCommonAreasCRUD';
import { messageAtom } from 'components/atoms/MessageAtom';
import { GetCommonAreaById } from 'core/domain/bookingsAndCommonAreas/repositories/getCommonAreaById';
import { getCommonAreaUpdatePath } from 'components/pages/App/routes/commonAreas/config';

export const useCommonAreaDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, projectId, commonAreaId } = useParams<{ clientId: string; projectId: string; commonAreaId: string }>();
  const [commonArea, setCommonArea] = useState<CommonAreaModel>();
  const [isLoadingCommonArea, setIsLoadingCommonArea] = useState<boolean>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { sensorList } = useSensorsProject({ projectId });
  const { enableCommonArea } = useCommonAreasCRUD();

  const translations = {
    updateButtonText: t('_COMMON_AREA_MODIFY'),
  };

  const getCommonArea = async () => {
    setIsLoadingCommonArea(true);
    try {
      const commonAreaDetail = await GetCommonAreaById(commonAreaId);
      !!commonAreaDetail && setCommonArea(commonAreaDetail);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingCommonArea(false);
    }
  };

  const onUpdateCommonArea = () => {
    const updateCommonAreaRoute = getCommonAreaUpdatePath({ clientId, projectId, commonAreaId });
    history.push(updateCommonAreaRoute);
  };

  const onEnableCommonArea = async (checked: boolean): Promise<void> => {
    setIsSaving(true);
    if (commonArea) {
      const filteredSensors: string[] = commonArea.sensors.map((sensor) => sensor.id);
      const newCommonArea: UpdateCommonAreaModel = { ...commonArea, sensors: filteredSensors };

      try {
        await enableCommonArea({ commonArea: newCommonArea, checked });
        const response = await GetCommonAreaListByProjectId(projectId);
        if (!!response?.length) {
          const selectedCommonArea = response.find(({ id }) => id === commonArea.id);
          selectedCommonArea && setCommonArea(selectedCommonArea);
        }
        const messageSuccess = checked ? t('_COMMON_AREA_ENABLED') : t('_COMMON_AREA_DISABLED');
        messageAtom.success(messageSuccess);
      } catch (error) {
        console.warn(error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  useEffect(() => {
    getCommonArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, commonAreaId]);

  return {
    sensorList,
    commonArea,
    translations,
    onUpdateCommonArea,
    onEnableCommonArea,
    saving: isSaving,
    loadingCommonArea: isLoadingCommonArea,
  };
};
