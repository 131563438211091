import { FC } from 'react';
import { CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { getColumns } from './resources';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import './styles.scss';

interface CommonAreaContentGridProps {
  commonAreaData: CommonAreaModel;
  sensorOptionList: SensorByProjectModel[];
}

const CommonAreaContentGrid: FC<CommonAreaContentGridProps> = (props) => {
  const columns = getColumns({ ...props });

  return (
    <div className={'CommonAreaContentGrid__container'}>
      {columns.map((item, key) => {
        const { renderComponent, values, title } = item;

        const Component = renderComponent;

        return (
          <div key={key} className={'CommonAreaContentGrid__grid-item'}>
            <div className={'CommonAreaContentGrid__grid-item-title'}>{title}</div>
            <div className={'CommonAreaContentGrid__grid-item-text'}>
              <Component values={values} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CommonAreaContentGrid;
