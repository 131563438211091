import { Menu } from 'antd';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { InvitationStatusType } from 'core/domain/anonymousAccess/mappers';
import AccessStatusTagManager from '../../../molecules/AccessStatusTagManager';
import { CheckInHistoryCustomDataModel } from 'components/pages/AccessesVerticals/hotel/CheckInListTab/resources/utils';
import './styles.scss';

export interface AccessStatusTypeText {
  [InvitationStatusType.ACTIVE]: string;
  [InvitationStatusType.EXPIRED]: string;
  [InvitationStatusType.REVOKED]: string;
}

export interface CheckInListColumn {
  nameText: string;
  keysTypeText: string;
  validityText: string;
  statusTitle: string;
  copyLinkButtonText: string;
  statusText: AccessStatusTypeText;
  checkoutButtonText: string;
  onCopyCheckInLink: (url: string) => void;
  onCheckout: (idList: string[]) => void;
  onPrintKeysTypeColumnText: ({ isDigital, isNfc }: { isDigital: boolean, isNfc: boolean }) => string;
}

export const CheckInListColumns = ({
  nameText,
  keysTypeText,
  validityText,
  statusTitle,
  copyLinkButtonText,
  statusText,
  checkoutButtonText,
  onCheckout,
  onCopyCheckInLink,
  onPrintKeysTypeColumnText,
}: CheckInListColumn): AlfredTableColumn<CheckInHistoryCustomDataModel>[] => {
  return [
    {
      title: nameText,
      key: 'name',
      width: '15%',
      render: (text, { assets }) => <span>{assets[0].alias}</span>,
    },
    {
      title: validityText,
      key: 'valid',
      width: '20%',
      render: (text, { validFromText, validUntilText }) => <span>{`${validFromText} - ${validUntilText}`}</span>,
    },
    {
      title: keysTypeText,
      key: 'reservationId',
      width: '15%',
      render: (text, { isDigital, isNfc }) => <span>{onPrintKeysTypeColumnText({ isDigital, isNfc })}</span>,
    },

    {
      title: statusTitle,
      key: 'status',
      width: '10%',
      render: (text, { state }) => <span>{<AccessStatusTagManager status={state} text={statusText} />}</span>,
    },
    {
      key: 'actions',
      width: '5%',
      render: (text, record) => {
        const isDisabled = record.isDigital ? record.isExpired || record.isRevoked : true;
        return (
          <>
            <ContextualMenu placement={'topRight'} menu={
              <Menu className={'Columns__wrapper'}>
                <Menu.Item
                  key={'checkout'}
                  onClick={() => onCheckout([record.id])}
                  disabled={record.isExpired || record.isRevoked}
                  className={'Columns__wrapper-button'}
                >
                  {checkoutButtonText}
                </Menu.Item>
                <Menu.Item
                  key={'copy'}
                  disabled={isDisabled}
                  onClick={() => record.url && onCopyCheckInLink(record.url)}
                  className={'Columns__wrapper-button'}
                >
                  {copyLinkButtonText}
                </Menu.Item>
              </Menu>
            } />
          </>
        )
      }
    },
  ];
};
