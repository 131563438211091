import { FC } from 'react';
import TabTemplate from 'components/template/TabTemplate';
import { useParams } from 'react-router-dom';
import DocumentsTable from 'components/organisms/DocumentsTable';
import DocumentManagementButton from 'components/organisms/DocumentManagementButton';
import { useDocumentsProject } from 'hooks/useDocumentsProject';
import { SourceType } from 'components/organisms/DocumentsTable/DocumentsTableActionsManager';
import ConfirmModal from 'components/molecules/ConfirmModal';

const TabDocuments: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const {
    data,
    documentTableActionsMenu,
    wrongSourceText,
    deleteModalTitle,
    deleteModalText,
    deleteModalMainButtonText,
    deleteModalSecondaryButtonText,
    onSave,
    onRefresh,
    onFormatToBytes,
    onRowClick,
    onConfirmDeleteDocument,
    onCloseConfirmModal,
    loading,
    deleteModalVisible,
  } = useDocumentsProject(projectId);

  return (
    <>
      <TabTemplate>
        <DocumentsTable
          button={<DocumentManagementButton onOk={onRefresh} loading={loading} onSave={onSave} />}
          documents={data}
          documentTableActionsMenu={documentTableActionsMenu}
          wrongSourceText={wrongSourceText}
          onFormat={onFormatToBytes}
          isLoading={loading}
          source={SourceType.PROJECT}
          onRowClick={onRowClick}
        />
      </TabTemplate>
      <ConfirmModal
        onCancel={onCloseConfirmModal}
        onClickMainButton={onConfirmDeleteDocument}
        visible={deleteModalVisible}
        loading={loading}
        title={deleteModalTitle}
        text={deleteModalText}
        mainButtonText={deleteModalMainButtonText}
        secondaryButtonText={deleteModalSecondaryButtonText}
      />
    </>
  );
};

export default TabDocuments;
