// Note: Temporary Project Layouts Endpoints

export const getProjectLayoutConfigPath = (projectId: string) => {
  return `/projects/${projectId}/layout-config`;
};

export const createProjectLayoutConfigPath = (projectId: string) => { 
  return `/projects/${projectId}/layout-config`;
}

export const updateProjectLayoutConfigPath = (projectId: string) => {
  return `/projects/${projectId}/layout-config`;
};

export const deleteProjectLayoutConfigAreaPath = (areaId: string) => {
  return `/projects/layout-config/area/${areaId}`;
};

export const deleteProjectLayoutAreaPath = (areaId: string) => {
  return `/projects/layout-config/area/${areaId}`;
};
