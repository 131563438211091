import { ReactNode, VFC } from 'react';
import TableAtom from 'components/atoms/TableAtom';
import { CamerasAssetColumns } from './CamerasAssetColumns';
import { Camera } from 'core/domain/assets/models/camera';

interface CamerasAssetTableProps {
  button: ReactNode;
  data: Camera[];
  onRowClick: (item: Camera) => void;
  loading: boolean;
}

const MAX_SIZE_RESPONSIVE_SCREEN = 600;

const CamerasAssetTable: VFC<CamerasAssetTableProps> = ({ button, data, onRowClick, loading }) => {
  const camerasColumns = CamerasAssetColumns();

  const handleRowClick = (item: Camera): void => {
    onRowClick(item);
  };

  return (
    <>
      <p>{button}</p>
      <TableAtom<Camera>
        dataSource={data}
        columns={camerasColumns}
        rowKey='id'
        sticky
        pagination={false}
        loading={loading}
        scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        onRow={(item) => {
          return {
            onClick: () => handleRowClick(item),
          };
        }}
      />
    </>
  );
};

export default CamerasAssetTable;
