import { FC } from 'react';
import { Divider, Form, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { RcFile } from 'antd/lib/upload';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import FileUploader from '../FileUploader';
import { Icon } from 'components/atoms/Icons';
import DangerTextButton from 'components/atoms/Button/DangerTextButton';
import {
  LegalDocumentationActionsNames,
  LegalDocumentationContentTranslationsModel,
  LegalDocumentationFormChangesModel,
  LegalDocumentationFormValues,
  LegalDocumentationFormValuesNames,
} from 'components/pages/ClientDetailPage/TabLegalDocumentation/resources/config';
import './styles.scss';

interface Props {
  contentTranslations: LegalDocumentationContentTranslationsModel;
  form: FormInstance<LegalDocumentationFormValues>;
  acceptedTypes: string;
  termsOfServiceName: string;
  privacyPolicyName: string;
  onSave: () => void;
  onClose: () => void;
  onDelete: () => void;
  onChange: ({ label, file }: LegalDocumentationFormChangesModel) => void;
  onChangeAction: (label: LegalDocumentationActionsNames) => void;
  onCheckBeforeUpload: (file: RcFile) => void;
  processing: boolean;
  termsOfServiceUploadButtonDisabled: boolean;
  termsOfServiceUploadInfoVisible: boolean;
  termsOfServiceShowButtonVisible: boolean;
  privacyPolicyUploadButtonDisabled: boolean;
  privacyPolicyUploadInfoVisible: boolean;
  privacyPolicyShowButtonVisible: boolean;
  saveButtonDisabled: boolean;
  deleteButtonVisible: boolean;
  cancelButtonDisabled?: boolean;
  deleteButtonDisabled?: boolean;
  termsOfServiceInfoDisabled?: boolean;
  privacyPolicyInfoDisabled?: boolean;
  disabledTemplate?: boolean;
}

const LegalDocumentationForm: FC<Props> = ({
  form,
  contentTranslations,
  acceptedTypes,
  termsOfServiceName,
  privacyPolicyName,
  onSave,
  onClose,
  onChange,
  onDelete,
  onChangeAction,
  onCheckBeforeUpload,
  processing,
  termsOfServiceUploadButtonDisabled,
  termsOfServiceUploadInfoVisible,
  termsOfServiceShowButtonVisible,
  privacyPolicyUploadButtonDisabled,
  privacyPolicyUploadInfoVisible,
  privacyPolicyShowButtonVisible,
  saveButtonDisabled,
  deleteButtonVisible,
  deleteButtonDisabled = false,
  cancelButtonDisabled = false,
  termsOfServiceInfoDisabled = false,
  privacyPolicyInfoDisabled = false,
  disabledTemplate = false,
}) => {
  const {
    termsOfServiceLabel,
    termsOfServiceRequirements,
    privacyPolicyLabel,
    mainButtonText,
    secondaryButtonText,
    deleteButtonText,
    uploadButtonText,
  } = contentTranslations;

  return (
    <Form
      form={form}
      layout={'vertical'}
      requiredMark={false}
      className={'LegalDocumentationForm__wrapper'}
    >
      <div className={`LegalDocumentationForm__uploaders ${disabledTemplate && 'LegalDocumentationForm__uploaders--disabled'}`}>
        <Form.Item
          label={
            <>
              <span className={'LegalDocumentationForm__extra-info'}>{`${termsOfServiceLabel}*`}</span>
              <Tooltip title={termsOfServiceRequirements} overlayInnerStyle={{ width: 290, textAlign: 'center', marginLeft: -20 }}>
                <Icon.InfoCircleFilled />
              </Tooltip>
            </>
          }
          name={LegalDocumentationFormValuesNames.TERMS_OF_SERVICE}
        >
          <FileUploader
            buttonText={uploadButtonText}
            fileName={termsOfServiceName}
            acceptedTypes={acceptedTypes}
            onChange={(file) => onChange({ file, label: LegalDocumentationFormValuesNames.TERMS_OF_SERVICE })}
            onCheckBeforeUpload={(file) => onCheckBeforeUpload(file)}
            onShow={() => onChangeAction(LegalDocumentationActionsNames.SHOW_TERMS_OF_SERVICE)}
            onDelete={() => onChangeAction(LegalDocumentationActionsNames.DELETE_TERMS_OF_SERVICE)}
            disabledUploadButton={termsOfServiceUploadButtonDisabled}
            showActionVisible={termsOfServiceShowButtonVisible}
            showInfo={termsOfServiceUploadInfoVisible}
            disabledInfo={termsOfServiceInfoDisabled}
            loading={processing}
          />
        </Form.Item>
        <Form.Item
          label={
            <>
              <span className={'LegalDocumentationForm__extra-info'}>{`${privacyPolicyLabel}*`}</span>
              <Tooltip title={termsOfServiceRequirements} overlayInnerStyle={{ width: 290, textAlign: 'center', marginLeft: -20 }}>
                <Icon.InfoCircleFilled />
              </Tooltip>
            </>
          }
          name={LegalDocumentationFormValuesNames.PRIVACY_POLICY}
        >
          <FileUploader
            buttonText={uploadButtonText}
            fileName={privacyPolicyName}
            acceptedTypes={acceptedTypes}
            onChange={(file) => onChange({ file, label: LegalDocumentationFormValuesNames.PRIVACY_POLICY })}
            onCheckBeforeUpload={(file) => onCheckBeforeUpload(file)}
            onShow={() => onChangeAction(LegalDocumentationActionsNames.SHOW_PRIVACY_POLICY)}
            onDelete={() => onChangeAction(LegalDocumentationActionsNames.DELETE_PRIVACY_POLICY)}
            disabledUploadButton={privacyPolicyUploadButtonDisabled}
            showInfo={privacyPolicyUploadInfoVisible}
            showActionVisible={privacyPolicyShowButtonVisible}
            loading={processing}
            disabledInfo={privacyPolicyInfoDisabled}
          />
        </Form.Item>
      </div>
      <Divider />
      <footer className={'LegalDocumentationForm__footer'}>
        <div className={'LegalDocumentationForm__footer-wrapper'}>
          {deleteButtonVisible && <DangerTextButton title={deleteButtonText} onClick={onDelete} disabled={deleteButtonDisabled} />}
          <div className={'LegalDocumentationForm__right-buttons'}>
            <DefaultButton
              title={secondaryButtonText}
              onClick={onClose}
              disabled={cancelButtonDisabled}
            />
            <PrimaryButton
              htmlType={'submit'}
              title={mainButtonText}
              loading={processing}
              disabled={saveButtonDisabled}
              onClick={onSave}
            />
          </div>
        </div>
      </footer>
    </Form>
  );
}

export default LegalDocumentationForm;