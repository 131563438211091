import { CameraFormValues } from 'components/organisms/AddCameraAction';

export const cameraFormValuesToPayload = ({
  name,
  vendor,
  model,
  comments,
  enabled,
  localUrl,
  remoteUrl,
  localApiPort,
  remoteApiPort,
  deviceSerial,
}: CameraFormValues) => {
  return {
    vendor,
    local_url: localUrl,
    remote_url: remoteUrl,
    local_api_port: localApiPort,
    remote_api_port: remoteApiPort,
    name,
    comments,
    model,
    device_serial: deviceSerial,
    enabled,
  };
};
