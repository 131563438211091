import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import CardAtom from 'components/atoms/CardAtom';
import InputAtom from 'components/atoms/InputAtom';
import SpaceAtom from 'components/atoms/SpaceAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { DownloadOutlined, RedoOutlined, QrcodeOutlined } from '@ant-design/icons';
import './styles.scss';

export type Title = 'assets' | 'gateways'

interface CardQrToolProps {
  title: Title;
  onClick: (type: Title, value: string) => void;
  loading: boolean;
  disabled: boolean;
}

const CardQrTool: FC<CardQrToolProps> = ({ 
  title, 
  onClick,
  loading,
  disabled,
}) => {
  const [value, setValue] = useState<string>('');
  const { t } = useTranslation();

  const handleOnChange = (event: any) => {
    setValue(event.target.value);
  }

  const handleOnClick = () => {
    onClick(title, value);
  }

  const handleOnReset = () => {
    setValue('');
  }

  return (
    <div className={'CardQrTool__container'}>
      <CardAtom
        title={t(title)} 
        className={'CardQrTool__card'}
      >
        <SpaceAtom  direction={'vertical'}>
          <InputAtom
            value={value}
            type={'number'}
            placeholder={t('qr_number')} 
            onChange={handleOnChange}
            suffix={<QrcodeOutlined />}
            maxLength={3}
          />
          <div className={'CardQrTool__buttons'}>
            <SpaceAtom>
              <PrimaryButton
                disabled={!value.length || disabled}
                icon={<DownloadOutlined />}
                title={t('download_csv')} 
                onClick={handleOnClick}
                loading={loading}
              />
              <DefaultButton
                disabled={!value.length || disabled}
                icon={<RedoOutlined />}
                title={t('reset')}
                onClick={handleOnReset}
                loading={loading}
              />
            </SpaceAtom>
          </div>
        </SpaceAtom>
      </CardAtom>
    </div>
  )
}

export default CardQrTool;