import { CurrentUserModel } from '../models/userModel';
import api from 'services/api';
import { PayloadCurrentUser } from '../models/userPayloadModel';
import { payloadToCurrentUser } from '../mappers/payloadToCurrentUser';
import { CurrentUserEntity } from '../entities/currentUserEntity';
import { currentUserPath } from 'constants/endpointsPath/users';

export const GetCurrentUser = async (): Promise<Readonly<CurrentUserModel>> => {
  api.defaults.headers.common['Accept-version'] = '1';
  const path = currentUserPath();

  try {
    const { data } = await api.get<PayloadCurrentUser>(path);
    const mappedCurrentUser = payloadToCurrentUser(data);
    return CurrentUserEntity(mappedCurrentUser);
  } catch (error: any) {
    throw new Error(error);
  }
};
