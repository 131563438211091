import { GatewayDevicesUnparsed } from '../gatewayDevices/gatewayDevicesUnparsed';
import { GatewayStatusPayload, GatewayStatusUnitsPayload } from '../gatewayStatus/gatewayStatusPayload';
import { GatewaySensorPayloadModel } from '../gatewaysSensors/gatewaysSensorPayloadModel';
import { Sku } from '../sku';

export enum LinksPayloadType {
  CONTROL_PANEL = 'control_panel',
  OPENHAB = 'openhab',
  SSH_TELEPORT = 'ssh_teleport',
}

export interface ManagementLinksPayload {
  [LinksPayloadType.CONTROL_PANEL]: string | null;
  [LinksPayloadType.OPENHAB]: string | null;
  [LinksPayloadType.SSH_TELEPORT]: string | null;
}

export enum GatewayType {
  PRIVATE = 'private',
  SHARED = 'shared',
}

export interface GatewayDetailStatusPayload extends GatewayStatusPayload {
  units: GatewayStatusUnitsPayload | null;
}

export interface GatewaySharedSensorPayload {
  sensor_uuid: string;
  assets: string[];
}

export interface GatewayUnparsed {
  id: string;
  master_user: string;
  qr_code: string;
  gateway_version: string;
  project_id: string;
  hw_version: string;
  os_version: string;
  assistant_cloud: string;
  assistant_cloud_url: string;
  voice_assistant_user: string;
  daemon_version: string | null;
  helper_version: string | null;
  production_date: number;
  deployment_date: string | number;
  knx_version: string | null;
  status: GatewayDetailStatusPayload | null;
  batch_id: string | null;
  batch_name: string | null;
  firmware_version: string | null;
  sku: Sku | null;
  zwave_version: string | null;
  devices: GatewayDevicesUnparsed[];
  deployed_at: number | null;
  management_links: ManagementLinksPayload;
  type: GatewayType;
  sensors: GatewaySensorPayloadModel[] | null;
  shared_sensors_visibility: GatewaySharedSensorPayload[] | null;
}

export interface ActiveGatewaysMonthStatisticsPayload {
  label: string;
  value: number;
}

export interface ActiveGatewaysStatisticsPayloadModel {
  total_gateways_online: number;
  gateways: ActiveGatewaysMonthStatisticsPayload[];
}
