import { FC } from 'react';
import { Button } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import ModalAtom from 'components/atoms/ModalAtom';
import { DeleteGatewayOptionsModel } from 'components/pages/GatewaysListPage/resources/useDeleteGateway';
import DeleteGatewayForm, { DeleteGatewayFormTranslations } from 'components/molecules/DeleteGatewayForm';

export interface DeleteGatewayModalTranslations {
  title: string;
  mainButtonText: string;
  secondaryButtonText: string;
  description: string;
  formTranslations: DeleteGatewayFormTranslations,
}

interface Props extends ModalProps {
  options: DeleteGatewayOptionsModel;
}

const DeleteGatewayModal: FC<Props> = ({ options, onCancel, ...props }) => {
  const {
    translations,
    form,
    errorGidMessage,
    errorQidMessage,
    onFinish,
    deleting,
    qidValidationVisible,
    errorGid,
    errorQid,
  } = options;

  const {
    title,
    description,
    mainButtonText,
    secondaryButtonText,
    formTranslations,
  } = translations;

  return (
    <ModalAtom
      {...props}
      title={title}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button key={'back'} onClick={onCancel}>
          {secondaryButtonText}
        </Button>,
        <Button key={'submit'} type={'primary'} loading={deleting} onClick={() => form.submit()}>
          {mainButtonText}
        </Button>,
      ]}
    >
      <p>{description}</p>
      <DeleteGatewayForm
        form={form}
        formTranslations={formTranslations}
        errorGidMessage={errorGidMessage}
        errorQidMessage={errorQidMessage}
        onFinish={onFinish}
        qidValidationVisible={qidValidationVisible}
        deleting={deleting}
        errorGid={errorGid}
        errorQid={errorQid}
      />
    </ModalAtom>
  );
};

export default DeleteGatewayModal;
