import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import TextButton from 'components/atoms/Button/TextButton';
import DividerAtom from 'components/atoms/DividerAtom';
import CommonAreaContentGrid from 'components/molecules/CommonAreaContentGrid';
import { CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import './styles.scss';

interface CommonAreaContentTemplateProps {
  commonAreaData: CommonAreaModel;
  sensorOptionList: SensorByProjectModel[];
  onEnableArea: (checked: boolean) => void;
  onVisibleUpdate: () => void;
  isSaving: boolean;
}

const CommonAreaContentTemplate: FC<CommonAreaContentTemplateProps> = ({
  commonAreaData,
  sensorOptionList,
  onEnableArea,
  onVisibleUpdate,
  isSaving,
}) => {
  const { t } = useTranslation();

  return (
    <div className={'CommonAreaContentVisorTemplate__container'}>
      <div className={'CommonAreaContentVisorTemplate__content'}>
        <CommonAreaContentGrid commonAreaData={commonAreaData} sensorOptionList={sensorOptionList} />
        <div className={'CommonAreaContentVisorTemplate__switch'}>
          <Switch checked={commonAreaData.enabled} onChange={onEnableArea} loading={isSaving} />
        </div>
      </div>
      <DividerAtom />
      <div className={'CommonAreaContentVisorTemplate__actions'}>
        <TextButton title={t('_COMMON_AREA_MODIFY')} onClick={onVisibleUpdate} />
      </div>
    </div>
  );
};

export default CommonAreaContentTemplate;
