import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { CheckInHistoryResponseModel, CheckInHistoryResponsePayload } from '../models';
import { payloadToCheckInHistoryList } from '../mappers';

const getUrl = ({
  host,
  projectId,
  pageNumber,
  pageSize,
  nfcCode,
}: {
    host: string;
    projectId: string;
    pageNumber: number;
    pageSize: number;
    nfcCode?: string;
}) => {
  const params = new URLSearchParams();
  pageNumber && params.append('page[number]', `${pageNumber}`);
  pageSize && params.append('page[size]', `${pageSize}`);
  nfcCode && params.append('nfc_code', nfcCode);

  return `${host}/hotels/assets/check-in/projects/${projectId}?${params}`;
};

export const GetCheckInListByProjectId = async ({
  projectId,
  token,
  host,
  pageNumber,
  pageSize,
  nfcCode,
}: {
  projectId: string;
  token: string;
  host: string;
  pageNumber: number;
  pageSize: number;
  nfcCode?: string;
}): Promise<CheckInHistoryResponseModel> => {
  try {
    const url = getUrl({ host, projectId, pageNumber, pageSize, nfcCode });
    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data: responseData } = (await axios(options)) as AxiosResponse<CheckInHistoryResponsePayload>;
    return payloadToCheckInHistoryList(responseData);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
