import { assetDeletePath } from 'core/endspoints/assets';
import api from 'services/api';

export const DeleteAsset = async (assetId: string) => {
  const path = assetDeletePath(assetId);
  try {
    await api.delete(path);
  } catch (error: any) {
    throw new Error(error);
    }
};
