import { FC } from 'react';
import { Space } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, QuestionCircleTwoTone } from '@ant-design/icons';
import { COLORS } from 'constants/colors';
import './styles.scss';

export interface CheckStatusProps {
  check: boolean | null;
  text: string;
}

const CheckStatus: FC<CheckStatusProps> = ({ check, text }) => {
  const handleOnIconStatus = (status: boolean | null) => {
    switch (status) {
      case true:
        return <CheckCircleTwoTone className='CheckStatus__icon' twoToneColor={COLORS.success} />;
      case false:
        return <CloseCircleTwoTone className='CheckStatus__icon' twoToneColor={COLORS.alert} />;
      case null:
      default:
        return <QuestionCircleTwoTone className='CheckStatus__icon' twoToneColor={COLORS.corporate} />;
    }
  };

  return (
    <Space>
      {handleOnIconStatus(check)}
      <span>{text}</span>
    </Space>
  );
};

export default CheckStatus;
