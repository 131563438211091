import { FC } from 'react';
import CommonAreaCreateTemplate from 'components/template/CommonAreaCreateTemplate';
import useCommonAreaCreate from './resources/useCommonAreaCreate';
import CommonAreasAndBookingsListNewLoadingTemplate from 'components/template/CommonAreasAndBookingsNewLoadingTemplate';

const CommonAreaCreatePage: FC = () => {
  const {
    form,
    Form,
    translations,
    sensorList,
    timeAvailability,
    bookingDurationOptionList,
    errors,
    selectedDays,
    onAddTimeAvailabilityInterval,
    onChangeTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    getTimeAvailabilityErrorMessage,
    onChangeBackgroundImage,
    onSelectDay,
    onUnselectDay,
    onFinishCreateCommonArea,
    onSelectSensors,
    onSelectBookingDuration,
    onCloseCreateCommonArea,
    loadingSensors,
    saving,
  } = useCommonAreaCreate();
  const { createCommonAreaRequiredFieldsText } = translations;
  return (
    <>
      {loadingSensors && <CommonAreasAndBookingsListNewLoadingTemplate />}
      {!loadingSensors && (
        <CommonAreaCreateTemplate
          createCommonAreaRequiredFieldsText={createCommonAreaRequiredFieldsText}
          bookingDurationOptionList={bookingDurationOptionList}
          errors={errors}
          form={form}
          Form={Form}
          selectedDays={selectedDays}
          sensorOptionList={sensorList}
          timeAvailability={timeAvailability}
          onChangeTimeAvailability={onChangeTimeAvailabilityInterval}
          onAddTimeAvailabilityInterval={onAddTimeAvailabilityInterval}
          onDeleteTimeAvailabilityInterval={onDeleteTimeAvailabilityInterval}
          getTimeAvailabilityErrorMessage={getTimeAvailabilityErrorMessage}
          onCloseCreateCommonArea={onCloseCreateCommonArea}
          onFinishCreateCommonArea={onFinishCreateCommonArea}
          onSelectBookingDuration={onSelectBookingDuration}
          onSelectDay={onSelectDay}
          onUnSelectDay={onUnselectDay}
          onSelectSensors={onSelectSensors}
          onChangeBackgroundImage={onChangeBackgroundImage}
          saving={saving}
        />
      )}
    </>
  );
}

export default CommonAreaCreatePage;