import { GatewayDevices } from '../gatewayDevices';
import { GatewayStatusModel, GatewayStatusUnitsModel } from '../gatewayStatus';
import { GatewaySensorEntityModel } from '../gatewaysSensors/gatewaySensorEntityModel';
import { Sku } from '../sku';
import { GatewayType } from './gatewayUnparsed';

export enum LinksModelType {
  CONTROL_PANEL = 'control_panel',
  OPENHAB = 'openhab',
  SSH_TELEPORT = 'ssh_teleport',
}

export interface ManagementLinksModel {
  [LinksModelType.CONTROL_PANEL]: string | null;
  [LinksModelType.OPENHAB]: string | null;
  [LinksModelType.SSH_TELEPORT]: string | null;
}

export interface GatewayDetailStatusModel extends GatewayStatusModel {
  units: GatewayStatusUnitsModel | null;
}

export interface GatewaySharedSensorModel {
  sensorUuid: string;
  assets: string[];
}

export interface GatewayParsed {
  assistantCloud: string;
  assistantCloudUrl: string;
  batchId: string;
  batchName: string;
  daemonVersion: string;
  deployedAt: number | null;
  deploymentDate: string | number | null;
  devices: GatewayDevices[];
  firmwareVersion: string;
  gatewayVersion: string;
  helperVersion: string;
  hwVersion: string;
  id: string;
  knxVersion: string;
  managementLinks: ManagementLinksModel;
  masterUser: string;
  osVersion: string;
  productionDate: number;
  projectId: string;
  qrCode: string;
  sku: Sku | null;
  sensors: GatewaySensorEntityModel[] | null;
  sharedSensors: GatewaySharedSensorModel[] | null;
  status: GatewayDetailStatusModel | null;
  type: GatewayType;
  voiceAssistantUser: string;
  zwaveVersion: string;
}

export interface ActiveGatewaysMonthStatisticsModel {
  label: string;
  value: number;
}

export interface ActiveGatewaysStatisticsModel {
  totalGatewaysOnline: number;
  gateways: ActiveGatewaysMonthStatisticsModel[];
}
