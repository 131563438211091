import { InvitationModel } from 'core/domain/anonymousAccess/models';
import { checkIfDateHasExpired, formatTimeDate } from 'utils/date';

export interface CustomInvitationDevice {
  label: string;
}

export interface CustomInvitationModel {
  uid: string;
  alias?: string;
  validFrom: number;
  validFromText: string;
  validUntil: number;
  validUntilText: string;
  revokedAt: number | null;
  createdAt: number;
  createdAtText: string;
  createdBy: string;
  url: string;
  devices: CustomInvitationDevice[];
  isNewInvitation: boolean;
  isRevoked: boolean;
  isExpired: boolean;
}

export interface InvitationData {
  accesses: InvitationModel[];
  pageNumber: number;
  allResults: number;
}

export interface InvitationsListItemTranslations {
  revokedStateText: string;
  revokeText: string;
  expiredStateText: string;
  invitationStateText: string;
  createdByTitle: string;
  createdAtTitle: string;
  validityTitle: string;
  accessesTitle: string;
  notReportedText: string;
}

export interface RevokeInvitationModel {
  invitationId: string;
  isNewInvitation: boolean;
}

const transformInvitationToData = ({
  createdAt,
  revokedAt,
  uid,
  url,
  validFrom,
  validUntil,
  alias,
  createdBy,
  assets,
  isNewInvitation,
}: InvitationModel): CustomInvitationModel => {
  const devices = assets[0].devices.map((item) => ({ label: item.label })) ?? [];
  const createdAtText = formatTimeDate(createdAt);
  const validFromText = formatTimeDate(validFrom);
  const validUntilText = formatTimeDate(validUntil);
  const isRevoked = revokedAt !== null;
  const isExpired = checkIfDateHasExpired(validUntil);

  return {
    uid,
    alias,
    createdAt,
    createdAtText,
    revokedAt,
    createdBy,
    devices,
    url,
    validFrom,
    validFromText,
    validUntil,
    validUntilText,
    isNewInvitation,
    isRevoked,
    isExpired,
  };
};

export const transformInvitationsListToCustomData = (accesses: InvitationModel[]): CustomInvitationModel[] => {
  return accesses.map((item) => transformInvitationToData(item));
};
