import { FC, ReactElement } from 'react';
import { Menu } from 'antd';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { TableActionsMenuProps } from 'components/atoms/TableAtom';

interface Props {
  menu: TableActionsMenuProps[];
  id: string;
}

const getTableActionContentMenu = ({ menu, id }: Props): ReactElement => {
  return (
    <Menu>
      {menu.map((item) => {
        const { key, disabled, text, onClick } = item;

        return (
          <Menu.Item key={key} onClick={() => onClick(id)} disabled={disabled}>{text}</Menu.Item>
        );
      })}
    </Menu>
  );
}

const TableActionsContent: FC<Props> = ({ menu, id }) => {
  const menuComponent = getTableActionContentMenu({ menu, id });

  return <ContextualMenu menu={menuComponent} />;
}

export default TableActionsContent;