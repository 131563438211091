import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { payloadToProjectAssetsResponse } from '../mappers/payloadToProjectAssets';
import { ProjectAssetsResponseModel } from '../model/projectModel';
import { PayloadProjectAssetsResponseModel } from '../model/projectPayloadModel';

const getUrl = ({ host, projectId }: { host: string; projectId: string }): string => {
  return `${host}/projects/${projectId}/assets`;
};

export const GetAssetsListByProjectId = async ({
  host,
  token,
  projectId,
}: {
  host: string;
  token: string;
  projectId: string;
}): Promise<ProjectAssetsResponseModel> => {
  try {
    const url = getUrl({ host, projectId });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<PayloadProjectAssetsResponseModel>;
    return payloadToProjectAssetsResponse(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
