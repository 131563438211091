import { FC } from 'react';
import SensorTable from 'components/organisms/SensorTable';
import { useSensorsAsset } from 'hooks/useSensorsAsset';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { SensorEditEntityModel } from 'core/domain/sensors/models/sensorEntityModels';

interface OptionListProps {
  assetId: string;
}

const OptionList: FC<OptionListProps> = ({ assetId }) => {
  const { t } = useTranslation();
  const { data, onEdit, onRefresh, loading } = useSensorsAsset(assetId, true);

  const handleOnEdit = async (id: string, sensor: SensorEditEntityModel) => {
    try {
      await onEdit(id, sensor);
      await onRefresh(assetId);
      message.success(t('successfully_updated'), 3);
    } catch (error) {
      message.error(t('EDIT_ERROR'), 3);
    }
  };

  return <SensorTable data={data} loading={loading} onEdit={handleOnEdit} />;
};

export default OptionList;
