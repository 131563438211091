import api from 'services/api';
import { ListResponseSuperSearch, SuperSearchData } from 'models/superSearch.model';
import { History } from 'history';

export const superSearch = async (query: string): Promise<ListResponseSuperSearch<SuperSearchData>> => {
  const params = new URLSearchParams();
  params.append('q', query);
  return await api.get('/search', { params });
};

export const getQuery = (pathSearch: History.Search): string => {
  const queryParams = new URLSearchParams(pathSearch);
  return queryParams.get('q') || '';
};
