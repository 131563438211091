import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button, message, Space } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { invitationUser } from 'services/api/users';
import { UserInvitation } from 'models/users.model';
import UserInvitationForm from 'components/organisms/UserInvitationForm';
import './styles.scss';

const FORM_ID = 'form_invitation';

const UserInvitationModal: FC<ModalProps> = ({ onCancel, ...props }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleOnFinish = async (values: UserInvitation) => {
    try {
      setLoading(true);
      await invitationUser(values);
      message.success(t('send_invitation'));
      form.resetFields();
      if (props.onOk) props.onOk({} as any);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      {...props}
      title={<Space>{t('invite_user')}</Space>}
      onCancel={onCancel}
      footer={[
        <Button key='back' onClick={onCancel}>
          {t('to_cancel')}
        </Button>,
        <Button key='submit' type='primary' loading={loading} htmlType='submit' form={FORM_ID}>
          {t('send')}
        </Button>,
      ]}
      destroyOnClose
    >
      <UserInvitationForm id={FORM_ID} onFinish={handleOnFinish} />
    </Modal>
  );
};

export default UserInvitationModal;
