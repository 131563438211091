import { FC } from 'react';
import CountUp from 'react-countup';
import { AssetSupportTicketsStatisticsTranslationsTypes } from 'components/pages/AssetPage/Tabs/TabStart/resources/translations';
import { SupportTicketsStatisticsModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import './styles.scss';

interface Props {
  tickets: SupportTicketsStatisticsModel;
  onClick?: () => void;
  translations: AssetSupportTicketsStatisticsTranslationsTypes;
}

const DATA_LOADING_DURATION = 2.75;

const AssetSupportTicketsStatisticsTemplate: FC<Props> = ({ tickets, translations, onClick }) => {
  const {
    ticketsClosed,
    ticketsClosedTimeAverage,
    ticketsClosedTimeAverageUnits,
    firstResponseTimeAverage,
    firstResponseTimeAverageUnits,
    ticketsReceived,
    ticketsRevoked,
    ticketsInProgress,
  } = tickets;

  return (
    <div className='AssetSupportTicketsStatisticsTemplate__container'>
      <div className='AssetSupportTicketsStatisticsTemplate__header'>
        <div>
          <p className='AssetSupportTicketsStatisticsTemplate__title'>{
            translations.TITLE}
          </p>
          <p className='AssetSupportTicketsStatisticsTemplate__subtitle'>
            {translations.TITLE_TIME_PERIOD}
          </p>
        </div>
      </div>
      <div className='AssetSupportTicketsStatisticsTemplate__chart-container'>
        <div className='AssetSupportTicketsStatisticsTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsTemplate__chart-element-title'>
            {translations.TICKETS_CLOSED_TIME_AVERAGE}
          </p>
          <span className='AssetSupportTicketsStatisticsTemplate__chart-element-data'>
            {`${ticketsClosedTimeAverage}${ticketsClosedTimeAverageUnits}`}
          </span>
        </div>
        <div className='AssetSupportTicketsStatisticsTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsTemplate__chart-element-title'>
            {translations.FIRST_RESPONSE_TIME_AVERAGE}
          </p>
          <span className='AssetSupportTicketsStatisticsTemplate__chart-element-data'>
            {`${firstResponseTimeAverage}${firstResponseTimeAverageUnits}`}
          </span>
        </div>
        <div className='AssetSupportTicketsStatisticsTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsTemplate__chart-element-title'>
            {translations.TICKETS_RECEIVED}
          </p>
          <span className='AssetSupportTicketsStatisticsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsReceived} duration={DATA_LOADING_DURATION} useEasing />
          </span>
        </div>
        <div className='AssetSupportTicketsStatisticsTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsTemplate__chart-element-title'>
            {translations.TICKETS_CLOSED}
          </p>
          <span className='AssetSupportTicketsStatisticsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsClosed} duration={DATA_LOADING_DURATION} useEasing />
          </span>
        </div>
        <div className='AssetSupportTicketsStatisticsTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsTemplate__chart-element-title'>
            {translations.TICKETS_IN_PROGRESS}
          </p>
          <span className='AssetSupportTicketsStatisticsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsInProgress} duration={DATA_LOADING_DURATION} useEasing />
          </span>
        </div>
        <div className='AssetSupportTicketsStatisticsTemplate__chart-element'>
          <p className='AssetSupportTicketsStatisticsTemplate__chart-element-title'>
            {translations.TICKETS_REVOKED}
          </p>
          <span className='AssetSupportTicketsStatisticsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsRevoked} duration={DATA_LOADING_DURATION} useEasing />
          </span>
        </div>
      </div>
    </div>
  );
}

export default AssetSupportTicketsStatisticsTemplate;