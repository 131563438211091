import { RouteConfig } from 'react-router-config';
import { ROUTE_PATH_GATEWAYS_LIST } from './config';
import GatewaysListPage from 'components/pages/GatewaysListPage';

export const gatewaysRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_GATEWAYS_LIST,
    exact: true,
    component: GatewaysListPage,
  },
];
