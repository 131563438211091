import { FC } from 'react';
import LayoutAtom from 'components/atoms/LayoutAtom';
import { Skeleton } from 'antd';
import { useLegalDocumentation } from './resources/useLegalDocumentation';
import LegalDocumentationModal from 'components/organisms/LegalDocumentationModal';
import './styles.scss';

const LegalDocumentationPage: FC = () => {
  const {
    MenuItems,
    legalDocumentationModalTranslations,
    onAcceptLegalDocumentation,
    onOpenPrivacyPolicyLink,
    onOpenTermsOfServiceLink,
    onRevokeLegalDocumentation,
    legalDocumentationModalVisible,
    loading,
  } = useLegalDocumentation();
  const { title,
    text,
    mainButtonText,
    secondaryButtonText,
    termsOfServiceLinkText,
    privacyPolicyLinkText,
  } = legalDocumentationModalTranslations;

  return (
    <>
      <LayoutAtom className={'LegalDocumentationPage'}>
        <LayoutAtom.Header className={'LegalDocumentationPage__header'}>
          <Skeleton.Input active className={'LegalDocumentationPage__logo'} />
          <Skeleton.Input active className={'LegalDocumentationPage__topBarRight'} />
        </LayoutAtom.Header>
        <LayoutAtom>
          <LayoutAtom.Sider
            className={'LegalDocumentationPage__sider'}
            breakpoint={'lg'}
            collapsedWidth={0}
            zeroWidthTriggerStyle={{ display: 'none' }}
          >
            {MenuItems.map((menuItem, index) => {
              return (
                <Skeleton.Input active key={index} className={'LegalDocumentationPage__menuItem'} />
              );
            })}
          </LayoutAtom.Sider>
        </LayoutAtom>
      </LayoutAtom>
      <LegalDocumentationModal
        visible={legalDocumentationModalVisible}
        title={title}
        text={text}
        termsOfServiceLinkText={termsOfServiceLinkText}
        privacyPolicyLinkText={privacyPolicyLinkText}
        mainButtonText={mainButtonText}
        secondaryButtonText={secondaryButtonText}
        loading={loading}
        onClick={onAcceptLegalDocumentation}
        onRevoke={onRevokeLegalDocumentation}
        onOpenTermsOfServiceLink={onOpenTermsOfServiceLink}
        onOpenPrivacyPolicyLink={onOpenPrivacyPolicyLink}
      />

    </>
  );
}

export default LegalDocumentationPage;