import api from 'services/api';
import { commonAreaPath } from 'core/endspoints/commonAreas';
import { CommonAreaModel } from '../models/commonAreaEntityModels';
import { PayloadCommonAreaModel } from '../models/payloadCommonAreaModels';
import { payloadToCommonArea } from '../mappers/payloadToCommonArea';
import CommonAreaEntity from '../entities/commonAreaEntity';

export const GetCommonAreaById = async (id: string): Promise<CommonAreaModel | undefined> => {
  try {
    const path = commonAreaPath(id);
    api.defaults.headers.common['Accept-version'] = '1';
    const { data } = await api.get<PayloadCommonAreaModel>(path);
    const commonAreaParsed = payloadToCommonArea(data);
    const commonArea = commonAreaParsed && CommonAreaEntity(commonAreaParsed);
    return commonArea;
  } catch (error: any) {
    throw new Error(error);
  }
};
