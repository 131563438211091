import { CheckInRoomModel } from 'core/domain/hotels/models';
import { Moment } from 'moment';

export enum CheckInStateKey {
  CARD_1 = 'card1',
  CARD_2 = 'card2',
  CARD_3 = 'card3',
  CARD_LIST_BOX = 'cardListBox',
  DIGITAL_BOX = 'digitalBox',
  DRAWER = 'drawer',
  EMAILS = 'emails',
  END_TIME = 'endTime',
  MODAL_FORCE = 'modalForce',
  QR_MODAL = 'qrModal',
  RANGE = 'rangeDate',
  START_TIME = 'startTime'
}

export type CardKeys = CheckInStateKey.CARD_1 | CheckInStateKey.CARD_2 | CheckInStateKey.CARD_3;

export interface CheckInDrawerProps {
  closeButtonText: string;
  keysSectionDescription: string;
  keysSectionTitle: string;
  requiredFields: string;
  saveButtonText: string;
  selectedCard: CheckInStateKey;
  selectedRoom: { name: string; id: string; };
  title: string;
  onClose: () => void;
  onSave: () => void;
  onCheckInRoom: (name: string, id: string) => void;
  onShowQr: (key: CardKeys) => void;
  isDisabledSaveButton: boolean;
  isLoading: boolean;
  isVisible: boolean;
}

export interface CheckInModalForceProps {
  acceptButtonText: string;
  cancelButtonText: string;
  checkInData: CheckInRoomModel;
  description: string;
  title: string;
  onAccept: () => void;
  onCancel: () => void;
  isLoading: boolean;
  isVisible: boolean;
}

export interface CheckInQrModalProps {
  title: string;
  cancelText: string;
  refreshText: string;
  checkText: string;
  selectedCard: CardKeys;
  onCancel: () => void;
  onScanQr: (value: string) => void;
  onRefresh: () => void;
  onFollowingScan: (checked: boolean) => void;
  isVisible: boolean;
  isLoading: boolean;
  isFollowingScan: boolean;
}

interface CheckInFormTextInputProps {
  errorMessage: string;
  isDisabled: boolean;
  isError: boolean;
  isRequired: boolean;
  label: string;
  placeholder: string;
  type?: string;
}

export interface CheckInFormEmailInputValueProps extends CheckInFormTextInputProps {
  value: string;
  valueList: string[];
  onChangeEmail: (value: string) => void;
  onRemoveEmailItemList: (emailItem: string) => void;
  onEmailSelected: (emailItem: string) => void;
  isValid: boolean;
}

export interface CheckInFormTextInputValueProps extends CheckInFormTextInputProps {
  value: string;
  onChangeValue: (value: string) => void;
}

export interface CheckInFormTextInputRangeProps extends CheckInFormTextInputProps {
  formatDate: string;
  values: Moment[];
  onChangeRange: (event: Moment[]) => void;
  onDisabledDate: (current: Moment) => boolean;
}

export interface CheckInFormTextInputTimeProps extends CheckInFormTextInputProps {
  formatTime: string;
  value: Moment | null;
  onChangeTime: ((value: Moment | null, dateString: string) => void) | undefined;
  onDisabledTime: (date: Moment | null) => {
    disabledHours: () => number[];
    disabledMinutes: () => number[];
  } | {
    disabledHours?: undefined;
    disabledMinutes?: undefined;
  };
}

export interface CheckInFormTextInputCardsProps extends CheckInFormTextInputProps {
  value: string;
  key: CardKeys;
  followingKey: CardKeys | null;
  ref: any | null;
  onChangeValue: (value: string) => void;
}

export interface CheckInFormBoxProps {
  title: string;
  onShowContent: (isShow: boolean) => void;
  isContentVisible: boolean;
  isError: boolean;
}

export interface CheckInInitialStatesModel {
  [CheckInStateKey.DRAWER]: CheckInDrawerProps;
  [CheckInStateKey.MODAL_FORCE]: CheckInModalForceProps;
  [CheckInStateKey.QR_MODAL]: CheckInQrModalProps;
  [CheckInStateKey.RANGE]: CheckInFormTextInputRangeProps;
  [CheckInStateKey.START_TIME]: CheckInFormTextInputTimeProps;
  [CheckInStateKey.END_TIME]: CheckInFormTextInputTimeProps;
  [CheckInStateKey.DIGITAL_BOX]: CheckInFormBoxProps;
  [CheckInStateKey.EMAILS]: CheckInFormEmailInputValueProps;
  [CheckInStateKey.CARD_LIST_BOX]: CheckInFormBoxProps;
  [CheckInStateKey.CARD_1]: CheckInFormTextInputCardsProps;
  [CheckInStateKey.CARD_2]: CheckInFormTextInputCardsProps;
  [CheckInStateKey.CARD_3]: CheckInFormTextInputCardsProps;
}

