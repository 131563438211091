import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { GetClientsList } from 'core/domain/client/repositories/getClientsList';
import { ClientsColumns } from '../ClientsColumns';
import { getClientPathById } from 'components/pages/App/routes/settings/config';
import { hasPermission } from 'services/permissions';
import { UserPermissionType } from 'models/users.model';
import { CreateClientModel } from 'core/domain/client/models/createClientEntityModel';
import { CreateClient } from 'core/domain/client/repositories/createClient';
import { TABLE_PAGINATION_PAGE_DEFAULT } from 'constants/table';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';

const DEFAULT_PAGE_SIZE = 50;

export const useSettings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageSuccess, setMessageError } = useMessage();
  const [clientsList, setClientsList] = useState<ClientModel[]>([]);
  const [isGetClientListLoading, setIsGetClientListLoading] = useState<boolean>(false);
  const [isCreateClientLoading, setIsCreateClientLoading] = useState<boolean>(false);
  const [isCreateClientModalVisible, setIsCreateClientModalVisible] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));

  const pageTitle: string = t('SETTINGS_TITLE');
  const createClientButtonText: string = t('SETTINGS_CLIENT_CREATE_BUTTON');

  const createClientModalLiterals = {
    title: t('SETTINGS_MODAL_CLIENT_CREATE_BUTTON'),
    mainButtonText: t('SETTINGS_MODAL_SEND_CLIENT_BUTTON'),
    secondaryButtonText: t('SETTINGS_MODAL_TO_CANCEL_CLIENT_BUTTON'),
  };

  const clientsColumns = ClientsColumns();

  const isCreateClientButtonVisible = hasPermission(UserPermissionType.CREATE_CLIENTS);

  const onClickTableRow = (data: ClientModel) => {
    history.push(getClientPathById({ clientId: data.id }));
  };

  const onOpenCreateClientModal = () => {
    setIsCreateClientModalVisible(true);
  };

  const onCloseCreateClientModal = () => {
    setIsCreateClientModalVisible(false);
  };

  const onCreateClient = async (values: CreateClientModel) => {
    setIsCreateClientLoading(true);
    try {
      await CreateClient({ host, token, client: values });
      setMessageSuccess({ description: t('SETTINGS_CLIENTS_LIST_SUCCESS') });
      setIsCreateClientModalVisible(false);
      await getDataClientsList();
    } catch (error) {
      setMessageError({ description: t('SETTINGS_CLIENTS_CREATE_CLIENT_ERROR') });
    } finally {
      setIsCreateClientLoading(false);
    }
  };

  const getDataClientsList = async () => {
    setIsGetClientListLoading(true);
    try {
      const clients = await GetClientsList();
      !!clients && setClientsList(clients);
    } catch (error) {
      setMessageSuccess({ description: t('SETTINGS_CLIENTS_LIST_ERROR') });
    } finally {
      setIsGetClientListLoading(false);
    }
  };

  useEffect(() => {
    getDataClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  };

  return {
    clientsColumns,
    clientsList,
    createClientButtonText,
    createClientModalLiterals,
    currentPage,
    pageSize: DEFAULT_PAGE_SIZE,
    pageTitle,
    onChangePage,
    onClickTableRow,
    onCloseCreateClientModal,
    onCreateClient,
    onOpenCreateClientModal,
    isCreateClientButtonVisible,
    isCreateClientLoading,
    isCreateClientModalVisible,
    isGetClientListLoading,
  };
};
