import { UpdateProjectModel } from '../model/projectModel';
import { PayloadUpdateProjectModel } from '../model/projectPayloadModel';

export const updateProjectToPayload = ({
  id,
  name,
  usersRequireVerification,
  usersVerifiers,
  logo,
  backgroundImage,
  type,
  supportEmail,
  status,
}: UpdateProjectModel): PayloadUpdateProjectModel => {
  return {
    id,
    users_require_verification: usersRequireVerification,
    name,
    users_verifiers: usersVerifiers,
    logo: logo || null,
    background_image: backgroundImage || null,
    type,
    support_email: supportEmail || null,
    status,
  };
};
