import { FC } from 'react';
import { TabsAtomProps } from 'components/atoms/TabsAtom';
import { HotelAccessesTabType, OfficeAccessesTabType, TabTranslations } from '../resources/utils';
import { ProjectVerticalsEnum } from 'constants/verticals';
import { HotelTabs } from './HotelTabs';
import { OfficeTabs } from './OfficeTabs';

export interface TabTitle {
  title: string;
  key: string;
}

export interface TabTitleProps extends TabsAtomProps {
  translations: TabTranslations;
  projectType: ProjectVerticalsEnum | null;
  activeHotelKey: HotelAccessesTabType;
  activeOfficeKey: OfficeAccessesTabType;
}

const AccessesTabsTitles: FC<TabTitleProps> = ({ translations, activeHotelKey, activeOfficeKey, projectType, ...props }) => {
  const typeTabsManager = {
    [ProjectVerticalsEnum.BUILDING]: null,
    [ProjectVerticalsEnum.HOME]: null,
    [ProjectVerticalsEnum.HOTEL_ROOM]: null,
    [ProjectVerticalsEnum.HOTEL]: <HotelTabs activeHotelKey={activeHotelKey} translations={translations} {...props} />,
    [ProjectVerticalsEnum.OFFICE]: <OfficeTabs activeOfficeKey={activeOfficeKey} translations={translations} {...props} />,
    [ProjectVerticalsEnum.PARKING_SLOT]: null,
    [ProjectVerticalsEnum.RENT]: null,
    [ProjectVerticalsEnum.VACATIONAL_RENTAL]: null,
  };
  const Component = !!projectType ? typeTabsManager[projectType as ProjectVerticalsEnum] : null;

  return Component;
};

export default AccessesTabsTitles;
