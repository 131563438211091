import { uploadTenantsTemplatePreviewPath } from 'core/endspoints/users';
import api from 'services/api';
import { TenantsPreviewResponsePayloadModel } from '../models/userPayloadModel';
import { payloadToTenantsPreview } from '../mappers/payloadToTenantsPreview';
import { TenantsPreviewEntity } from '../entities/tenantsPreviewEntity';
import { TenantsPreviewResponseModel } from '../models/userModel';

export const UploadTenantsTemplatePreview = async (file: string): Promise<TenantsPreviewResponseModel> => {
  const url = uploadTenantsTemplatePreviewPath();
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    const { data: previewData } = await api.post<TenantsPreviewResponsePayloadModel>(url, {
      data: { tenants_template_file: file },
    });
    const mappedData = payloadToTenantsPreview(previewData);
    return TenantsPreviewEntity(mappedData);
  } catch (error: any) {
    throw new Error(error);
  }
};
