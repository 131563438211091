import i18next from 'i18next';

export enum MonthName {
  JANUARY = 'january',
  FEBRUARY = 'february',
  MARCH = 'march',
  APRIL = 'april',
  MAY = 'may',
  JUNE = 'june',
  JULY = 'july',
  AUGUST = 'august',
  SEPTEMBER = 'september',
  OCTOBER = 'october',
  NOVEMBER = 'november',
  DECEMBER = 'december',
}

const monthTranslationManager = {
  [MonthName.JANUARY]: '_JANUARY_ABBREVIATION',
  [MonthName.FEBRUARY]: '_FEBRUARY_ABBREVIATION',
  [MonthName.MARCH]: '_MARCH_ABBREVIATION',
  [MonthName.APRIL]: '_APRIL_ABBREVIATION',
  [MonthName.MAY]: '_MAY_ABBREVIATION',
  [MonthName.JUNE]: '_JUNE_ABBREVIATION',
  [MonthName.JULY]: '_JULY_ABBREVIATION',
  [MonthName.AUGUST]: '_AUGUST_ABBREVIATION',
  [MonthName.SEPTEMBER]: '_SEPTEMBER_ABBREVIATION',
  [MonthName.OCTOBER]: '_OCTOBER_ABBREVIATION',
  [MonthName.NOVEMBER]: '_NOVEMBER_ABBREVIATION',
  [MonthName.DECEMBER]: '_DECEMBER_ABBREVIATION',
};

export const getMonthAbbreviationTranslations = (month: MonthName) => {
  return i18next.t(monthTranslationManager[month]);
};
