import api from 'services/api';
import { commonAreasListPath } from 'core/endspoints/commonAreas';
import { CommonAreaModel } from '../models/commonAreaEntityModels';
import { PayloadCommonAreaModel } from '../models/payloadCommonAreaModels';
import { payloadToCommonArea } from '../mappers/payloadToCommonArea';
import CommonAreasValueObject from '../entities/commonAreasListValueObject';

export const GetCommonAreaListByProjectId = async (projectId: string): Promise<CommonAreaModel[] | undefined> => {
  try {
    const path = commonAreasListPath(projectId);
    api.defaults.headers.common['Accept-version'] = '1';
    const { data } = await api.get<PayloadCommonAreaModel[]>(path);
    const commonAreaParsed = data.map((item) => payloadToCommonArea(item));
    const { list } = CommonAreasValueObject(commonAreaParsed);
    return list.reverse();
  } catch (error: any) {
    throw new Error(error);
  }
};
