import { ThirdPartyServicesByAsset } from '../model/thirdPartyServicesByAsset';
import { ThirdPartyServicesByAssetEntity } from './thirdPartyServicesByAssetEntity';

export const ThirdPartyServicesByAssetListValueObject = (list: ThirdPartyServicesByAsset[]): Readonly<{
  list: Readonly<ThirdPartyServicesByAsset>[];
}> => {
  return Object.freeze({
    list: list.map(item => ThirdPartyServicesByAssetEntity(item)),
  });
};
