import { FC, ReactNode } from 'react';
import { PageHeader } from 'antd';
import Paper from 'components/atoms/Paper';
import './styles.scss';

interface AlfredMainTemplateProps {
  title: string;
  onBack?: () => void;
  extra?: ReactNode;
  children?: ReactNode;
}

const AlfredMainTemplate: FC<AlfredMainTemplateProps> = ({
  title,
  onBack,
  children,
  extra,
}) => {
  return (
    <>
      <PageHeader
        ghost={false}
        title={title}
        className='AlfredMainTemplate__header'
        onBack={onBack}
      />
      {extra && <div className='AlfredMainTemplate__extra'>
        {extra}
      </div>}
      <div className='AlfredMainTemplate__wrapper'>
        <Paper>{children}</Paper>
      </div>
    </>
  );
};

export default AlfredMainTemplate;
