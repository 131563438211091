import { FC } from 'react';
import { ModalProps, Space } from 'antd';
import ModalAtom from 'components/atoms/ModalAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { UnAssignToAssetModalOptions } from 'components/pages/GatewaysListPage/resources/utils';

export interface UnAssignGatewayToAssetModalTranslations {
  title: string,
  description: string;
  mainButtonText: string,
  secondaryButtonText: string,
}

interface Props extends ModalProps {
  modalOptions: UnAssignToAssetModalOptions;
}

const UnAssignGatewayToAssetModal: FC<Props> = ({ modalOptions, onCancel, ...props }) => {
  const { unAssignGatewayToAssetModalTranslations, onUnAssignGatewayToAsset, unAssigning } = modalOptions;

  const {
    title,
    description,
    mainButtonText,
    secondaryButtonText,
  } = unAssignGatewayToAssetModalTranslations;

  return (
    <ModalAtom
      {...props}
      title={<Space>{title}</Space>}
      onCancel={onCancel}
      destroyOnClose
      width={'50%'}
      footer={[
        <DefaultButton key={'back'} onClick={onCancel} title={secondaryButtonText} />,
        <PrimaryButton key={'submit'} loading={unAssigning} onClick={onUnAssignGatewayToAsset} title={mainButtonText} />,
      ]}
    >
      {description}
    </ModalAtom>
  );
}

export default UnAssignGatewayToAssetModal;