import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateFormValues } from 'components/molecules/EventsAndNewsForm/models';
import { useModalStatus } from 'components/molecules/CommonAreaForm/hooks/useModalStatus';
import ModalFormConfirm from 'components/molecules/CommonAreaForm/components/ModalFormConfirm';
import EventsAndNewsFormGrid from 'components/molecules/EventsAndNewsForm/components/EventsAndNewsFormGrid';
import { useUpdateEventsAndNewsForm } from 'components/molecules/EventsAndNewsForm/hooks/useUpdateEventsAndNewsForm';
import { EventsAndNewsVisorViews } from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabEventsAndNews/enums/visorViews';
import { EventsAndNewsModel } from 'core/domain/eventsAndNews/models/eventsAndNewsEntityModels';
import './styles.scss';

interface EventsAndNewsFormUpdateTemplateProps {
  eventsAndNewsData: EventsAndNewsModel;
  onRemoveEventsAndNews: (id: string) => void;
  onUpdateEventsAndNews: (id: string, values: UpdateFormValues) => void;
  onInvisibleUpdate: () => void;
  isSaving: boolean;
}

enum TRANSLATION {
  saveTitle = '_EVENTS_AND_NEWS_MODAL_TITLE_SAVE',
  saveInfo = '_EVENTS_AND_NEWS_MODAL_INFO_SAVE',
  buttonSave = '_EVENTS_AND_NEWS_MODAL_BUTTON_SAVE',
  removeTitle = '_EVENTS_AND_NEWS_MODAL_TITLE_REMOVE',
  removeInfo = '_EVENTS_AND_NEWS_MODAL_INFO_REMOVE',
  buttonRemove = '_EVENTS_AND_NEWS_MODAL_BUTTON_REMOVE',
  requiredFields = '_EVENTS_AND_NEWS_REQUIRED_FIELDS',
}

const EventsAndNewsFormUpdateTemplate: FC<EventsAndNewsFormUpdateTemplateProps> = ({
  eventsAndNewsData,
  onUpdateEventsAndNews,
  onRemoveEventsAndNews,
  onInvisibleUpdate,
  isSaving,
}) => {
  const { t } = useTranslation();
  const {
    configModal,
    onCancel,
    onConfirm,
    onLoadAndConfigModal,
    isLoading,
    isVisible,
  } = useModalStatus();

  const {
    errors,
    form,
    Form,
    onChangedMade,
    onIsThereModal,
    onParseValues,
    areThereChanged,
  } = useUpdateEventsAndNewsForm({ eventsAndNewsData });

  const confirmUpdateEventsAndNews = ({ id, values, isModal }: {
    id: string;
    values: UpdateFormValues;
    isModal: boolean;
  }) => {
    isModal &&
      onLoadAndConfigModal(values, {
        title: t(TRANSLATION.saveTitle),
        text: t(TRANSLATION.saveInfo),
        buttonSuccess: t(TRANSLATION.buttonSave),
        onConfirm: () => onUpdateEventsAndNews(id, values),
      });

    !isModal && onUpdateEventsAndNews(id, values);
  };

  const handleOnFinish = ({ id, values }: {
    id: string;
    values: UpdateFormValues;
  }) => {
    const isModal = onIsThereModal({ ...values });

    const parsedValues = onParseValues(values);
    const data = { ...parsedValues, image: !!values.image ? values.image : undefined } as UpdateFormValues;
    parsedValues && id && confirmUpdateEventsAndNews({ id, values: data, isModal });
  };

  const handleOnConfirmRemoveArea = (id: string) => {
    onLoadAndConfigModal(id, {
      title: t(TRANSLATION.removeTitle),
      text: t(TRANSLATION.removeInfo),
      buttonSuccess: t(TRANSLATION.buttonRemove),
      onConfirm: () => onRemoveEventsAndNews(id),
    });
  };

  return (
    <div className={'EventsAndNewsFormUpdateTemplate__container'}>
      <div className={'EventsAndNewsFormUpdateTemplate__required-info'}>
        <p>{t(TRANSLATION.requiredFields)}</p>
      </div>
      <EventsAndNewsFormGrid<UpdateFormValues>
        typeForm={EventsAndNewsVisorViews.UPDATE}
        errors={errors}
        form={form}
        Form={Form}
        id={eventsAndNewsData.id}
        isDisabledSaved={!areThereChanged}
        isSaving={isSaving}
        onChangedMade={onChangedMade}
        onClose={onInvisibleUpdate}
        onFinish={({ id, values }) => id && handleOnFinish({ id, values })}
        onRemoveArea={handleOnConfirmRemoveArea}
        tempImageUrl={eventsAndNewsData.image}
      />
      <ModalFormConfirm<UpdateFormValues>
        onCancel={onCancel}
        onClickConfirm={onConfirm}
        isLoading={isLoading}
        isVisible={isVisible}
        {...configModal}
      />
    </div>
  );
};

export default EventsAndNewsFormUpdateTemplate;
