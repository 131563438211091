import api from 'services/api';
import { eventsAndNewsPath } from 'core/endspoints/eventsAndNews';
import { CreateEventsAndNewsModel } from '../models/eventsAndNewsEntityModels';
import { createEventsAndNewsToPayload } from '../mappers/createEventsAndNewsToPayload';

type CreateModel = (projectId: string, values: CreateEventsAndNewsModel) => Promise<void>

export const CreateEventsAndNews: CreateModel = async (projectId, values) => {
  try {
    const path = eventsAndNewsPath(projectId);
    const data = createEventsAndNewsToPayload(values);
    await api.post(path, { data });
  } catch (error) {
    console.error(error);
  }
};
