import { ProjectTab } from 'models/project.model';

export const getProjectByIdPath = (projectId: string) => {
  return `/projects/${projectId}`;
};

export const updateProjectPath = (projectId: string) => {
  return `/projects/${projectId}`;
};

export const getProjectTabInfoPath = ({ projectId, tab = ProjectTab.ASSETS }: { projectId: string; tab?: ProjectTab }) => {
  return `/projects/${projectId}/${tab}`;
};

export const getProjectsListByClientId = (clientId: string) => {
  return `/projects?client=${clientId}`;
};
