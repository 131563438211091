import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { ProjectsListResponsePayloadModel } from '../model/projectPayloadModel';
import { payloadToProjectsListResponse } from '../mappers/payloadToProjectsListResponse';
import { ProjectsListResponseModel } from '../model/projectModel';

const getUrl = ({
  host,
  clientId,
  pageNumber,
  pageSize,
  search,
}: {
  host: string;
  clientId: string;
  pageNumber: number;
  pageSize: number;
  search?: string;
}) => {
  const params = new URLSearchParams();
  pageNumber && params.append('page[number]', String(pageNumber));
  pageSize && params.append('page[size]', String(pageSize));
  !!search && params.append('search', search);

  return `${host}/projects?client=${clientId}&${params}`;
};

export const GetProjectsListByClientIdWithPagination = async ({
  token,
  host,
  clientId,
  pageNumber,
  pageSize,
  search,
}: {
  token: string;
  host: string;
  clientId: string;
  pageNumber: number;
  pageSize: number;
  search?: string;
}): Promise<ProjectsListResponseModel> => {
  try {
    const url = getUrl({ host, clientId, pageNumber, pageSize, search });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<ProjectsListResponsePayloadModel>;
    return payloadToProjectsListResponse(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
