import { FC } from 'react';
import { useProjectAppCustomization } from './resources/useProjectAppCustomization';
import ProjectAppCustomizationTemplate from 'components/template/ProjectAppCustomizationTemplate';
import ProjectCustomizationLoadingTemplate from 'components/template/ProjectCustomizationLoadingTemplate';

const SubTabAppAndWebapp: FC = () => {
  const {
    initialFormValues,
    customizationFormValues,
    customizationPreviewValues,
    translations,
    form,
    Form,
    onChangeMade,
    onCancel,
    onPublish,
    loading,
    updating,
    isCancelEnable,
    areThereValidChanges,
    formValuesCanceled,
  } = useProjectAppCustomization();

  return (
    <>
      {loading && <ProjectCustomizationLoadingTemplate />}
      {!loading && (
        <ProjectAppCustomizationTemplate
          initialFormValues={initialFormValues}
          customizationFormValues={customizationFormValues}
          customizationPreviewValues={customizationPreviewValues}
          translations={translations}
          form={form}
          Form={Form}
          onChangeMade={onChangeMade}
          onCancel={onCancel}
          onPublish={onPublish}
          disabled={!areThereValidChanges}
          updating={updating}
          canceled={formValuesCanceled}
          cancelButtonDisabled={!isCancelEnable}
        />)}
    </>
  );
}

export default SubTabAppAndWebapp;