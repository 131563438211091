import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { UpdateGatewayDataModel, UpdateGatewayDataPayload } from '../model';
import { gatewayDetailToPayload } from '../mappers';

const getUrl = ({ host, gatewayId }: { host: string; gatewayId: string }) => {
  return `${host}/gateways/${gatewayId}`;
};

export const UpdateGatewayDetail = async ({
  gatewayId,
  gateway,
  host,
  token,
}: {
  gatewayId: string;
  gateway: UpdateGatewayDataModel;
  token: string;
  host: string;
}) => {
  try {
    const url = getUrl({ host, gatewayId });
    const mappedData: UpdateGatewayDataPayload = gatewayDetailToPayload(gateway);

    const options: AxiosRequestConfig = {
      url,
      method: 'PATCH',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: mappedData },
    };

    const { data } = (await axios(options)) as AxiosResponse<void>;
    return data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
