import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { GetSupportTicketsList } from 'core/domain/supportTickets/repositories/getSupportTicketsList';
import { SupportTicketModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import { updateSupportTicketStatus } from 'services/api/supportTickets';
import { getSupportTicketPathById } from 'components/pages/App/routes/supportTickets/config';
import { TechSupportsColumns } from 'components/pages/TechSupportsPage/TechSupportsColumns';
import { ColumnSearchTranslationsType } from 'components/pages/TechSupportsPage/TechSupportsColumns/ColumnsSearchProps';
import {
  SupportTicketCategoryType,
  SupportTicketStatusType,
  SupportTicketTypeType,
} from 'core/domain/supportTickets/models/supportTicketsPayloadModel';

export type DataIndex = keyof SupportTicketModel;

export const useSupportTicketSummary = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { assetId } = useParams<{ assetId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [supportTickets, setSupportTickets] = useState<SupportTicketModel[]>([]);
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<any>(null);

  const title: string = t('summary_support_ticket');

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
  };

  const filteredSupportTicketsTypes: string[] = Object.values(SupportTicketTypeType).filter(
    (type) => type !== SupportTicketTypeType.ALL_SOURCES
  );
  const sourcesFilters: { text: string; value: string }[] = filteredSupportTicketsTypes.map((sourceType) => ({
    text: t(sourceType.toLowerCase()),
    value: sourceType.toLowerCase(),
  }));

  const filteredSupportTicketsCategoryTypes: string[] = Object.values(SupportTicketCategoryType);

  const categoriesFilters: { text: string; value: string }[] = filteredSupportTicketsCategoryTypes.map((categoryType) => {
    return { text: t(categoryType), value: categoryType };
  });

  const filteredSupportTicketsStatusType: string[] = Object.values(SupportTicketStatusType).filter(
    (type) => type !== SupportTicketStatusType.ALL_STATUSES
  );

  const statusFilters: { text: string; value: string }[] = filteredSupportTicketsStatusType.map((status) => {
    return { text: t(status), value: status };
  });

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const onUpdateStatus = async ({ id, status, description }: { id: string; status: SupportTicketStatusType; description: string }) => {
    try {
      setIsLoading(true);
      await updateSupportTicketStatus(id, { status, description });
      message.success(t('status_changed'), 3);
    } finally {
      setIsLoading(false);
    }
  };

  const onRowClick = (techSupportData: SupportTicketModel) => {
    history.push(getSupportTicketPathById(techSupportData.id));
  };

  const columns = TechSupportsColumns({
    sourcesFilters,
    categoriesFilters,
    statusFilters,
    columnSearchTranslations,
    searchedColumn,
    searchInput,
    onUpdateStatus,
    onSearch,
    onReset,
    loading: isLoading,
  });

  const getSupportTicketList = async () => {
    setIsLoading(true);

    try {
      const { data } = await GetSupportTicketsList({ assetId });
      setSupportTickets(data);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSupportTicketList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return {
    supportTickets,
    columns,
    title,
    onUpdateStatus,
    onRowClick,
    loadingSupportTickets: isLoading,
  };
};
