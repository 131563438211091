import { useTranslation } from 'react-i18next';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { BookingModel } from 'core/domain/bookingsAndCommonAreas/models/bookingEntityModels';
import moment from 'moment';
import { DATE_FORMAT_SLASH, DAY_WEEK_FORMAT } from 'constants/date';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { Menu } from 'antd';

const getPasedDate = (timestamp: number) => {
  const date = moment.unix(timestamp).format(DATE_FORMAT_SLASH);
  const day = moment.unix(timestamp).format(DAY_WEEK_FORMAT);
  const dayCapitalized = `${day.charAt(0).toUpperCase()}${day.slice(1)}`;

  return `${dayCapitalized} ${date}`;
};

export const BookingListColumns = ({ onDeleteBooking }: { onDeleteBooking: (bookingId: string) => void }): AlfredTableColumn<BookingModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('_BOOKINGS_COMMON_AREA'),
      key: 'commonArea',
      width: '25%',
      render: (text, { commonArea: { name } }) => {
        return <span>{name}</span>;
      },
    },
    {
      title: t('_BOOKINGS_DATE'),
      key: 'date',
      width: '25%',
      render: (text, { dateBooking }) => {
        const dateBookingParsed = getPasedDate(dateBooking);
        return <span>{dateBookingParsed}</span>;
      },
    },
    {
      title: t('_BOOKINGS_HOUR'),
      key: 'hour',
      width: '20%',
      render: (text, { hourBooking: { start } }) => {
        return <span>{start}</span>;
      },
    },
    {
      title: t('_BOOKINGS_BOOKED_BY'),
      key: 'user',
      width: '20%',
      render: (text, { user: { name, surname } }) => {
        return <span>{`${name} ${surname || ''}`}</span>;
      },
    },
    {
      title: t(''),
      key: 'actions',
      width: '10%',
      render: (text, record) => {
        return (
          <>
            <ContextualMenu placement={'topRight'} menu={
              <Menu>
                <Menu.Item key={'edit'} onClick={() => onDeleteBooking(record.id)}>
                  {t('_BOOKINGS_DELETE')}
                </Menu.Item>
              </Menu>
            } />
          </>
        );
      },
    },
  ];
};
