import { FC } from 'react';
import { Skeleton } from 'antd';
import './styles.scss';

const DashboardSupportTicketsLoadingTemplate: FC = () => {
  return (
    <div className='SupportTicketsLoading__container'>
      <div className='SupportTicketsLoading__header'>
        <Skeleton.Input className='SupportTicketsLoading__header-skeleton' active />
      </div>
      <div className='SupportTicketsLoading__content'>
        <div className='SupportTicketsLoading__skeleton-wrapper'>
          <Skeleton.Input className='SupportTicketsLoading__skeleton' active />
        </div>
        <div className='SupportTicketsLoading__skeleton-wrapper'>
          <Skeleton.Input className='SupportTicketsLoading__skeleton' active />
        </div>
        <div className='SupportTicketsLoading__skeleton-wrapper'>
          <Skeleton.Input className='SupportTicketsLoading__skeleton' active />
        </div>
        <div className='SupportTicketsLoading__skeleton-wrapper'>
          <Skeleton.Input className='SupportTicketsLoading__skeleton' active />
        </div>
        <div className='SupportTicketsLoading__skeleton-wrapper'>
          <Skeleton.Input className='SupportTicketsLoading__skeleton' active />
        </div>
        <div className='SupportTicketsLoading__skeleton-wrapper'>
          <Skeleton.Input className='SupportTicketsLoading__skeleton' active />
        </div>
      </div>
    </div>

  );
}

export default DashboardSupportTicketsLoadingTemplate;