import { LegalDocumentationModel } from '../models/clientEntityModel';

const LegalDocumentationEntity = ({
  id,
  clientId,
  privacyPolicy,
  privacyPolicyName,
  termsOfService,
  termsOfServiceName,
  enabled,
  isDefault,
}: LegalDocumentationModel): Readonly<LegalDocumentationModel> => {
  return Object.freeze({
    id,
    clientId,
    privacyPolicy,
    privacyPolicyName,
    termsOfService,
    termsOfServiceName,
    enabled,
    isDefault,
  });
};

export default LegalDocumentationEntity;
