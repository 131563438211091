import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { User } from 'models/users.model';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constants/date';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import AvatarUser from 'components/atoms/AvatarUser';
import './styles.scss';

export const UsersColumns = (): Array<AlfredTableColumn<User>> => {
  const { t } = useTranslation();

  return [
    {
      title: t('username'),
      key: 'username',
      render: (text, record) => (
        <Space>
          <AvatarUser letter={record.username!.toLowerCase() !== 'unknown' ? record.username[0] : undefined} />
          <div className='UsersPage__cell'>
            <b>{record.username!.toLowerCase() === 'unknown' ? t('unknown') : record.username}</b>
            <span className='Text__date'>{moment.unix(record.created_at).format(DATE_TIME_FORMAT)}</span>
          </div>
        </Space>
      ),
    },
    {
      title: t('email'),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: t('roles'),
      key: 'roles',
      render: (text, record) => record.roles.map((role, key) => <div key={key}>{t(`ROLES.${role}`)}</div>),
    },
    {
      title: t('added'),
      key: 'confirmed_at',
      render: (text, record) => {
        return record.confirmed_at ? moment.unix(record.created_at).format(DATE_TIME_FORMAT) : t('pending');
      },
    },
  ];
};
