import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { AccessListModel } from '../../resources/useAccess';

export const AccessColumn = (): ColumnType<AccessListModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('door'),
      key: 'door',
      width: '25%',
      render: (text, { deviceName }) => <Typography.Text>{!!deviceName ? deviceName : t('not_reported')}</Typography.Text>,
    },
    {
      title: t('name'),
      key: 'name',
      width: '25%',
      render: (text, { parameterName }) => <Typography.Text>{!!parameterName ? parameterName : t('not_reported')}</Typography.Text>,
    },
    {
      title: t('room'),
      key: 'room',
      width: '25%',
      render: (text, { parameterRoom }) => <Typography.Text>{!!parameterRoom ? parameterRoom : t('not_reported')}</Typography.Text>,
    },
    {
      title: `${t('type')}`,
      key: 'deviceType',
      width: '25%',
      render: (text, { deviceType }) => <Typography.Text>{!!deviceType ? deviceType : t('not_reported')}</Typography.Text>,
    },
  ];
};
