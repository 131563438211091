export const sensorsProjectListPath = (projectId: string) => {
  return `/projects/${projectId}/sensors`;
};

export const sensorsAssetListPath = (assetId: string) => {
  return `/assets/${assetId}/sensors`;
};

export const sensorEditPath = (sensorId: string) => {
  return `/sensors/${sensorId}`;
};

export const sensorsListWithPasswordsPath = (assetId: string) => {
  return `/sensor-passwords/deployment/${assetId}`;
};

export const passwordSensorUpdatePath = ({ gatewayId, deviceId, sensorId }: { gatewayId: string; deviceId: string; sensorId: string }) => {
  return `/gateways/${gatewayId}/devices/${deviceId}/update-password/${sensorId}`;
};
