import { UploadFile } from 'antd/lib/upload/interface';

export const formatBytes = ({ bytes, decimals = 0 }: { bytes: number; decimals?: number }): string => {
  if (bytes === 0) return '0 Bytes';

  const kylobyte = 1024;
  const _decimals = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(kylobyte));

  return parseFloat((bytes / Math.pow(kylobyte, i)).toFixed(_decimals)) + ' ' + sizes[i];
};

export const fileToBase64 = (file: UploadFile) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const utf8ToBase64 = (utf8Text: string) => {
  const encodedStringArray: number[] = utf8Text.split('').map((item) => item.charCodeAt(0));
  const base64String: string = btoa(String.fromCharCode(...encodedStringArray));
  return base64String;
};

export const csvStringFileToBase64 = (file: string) => {
  const encodedFile = utf8ToBase64(file);
  return `data:text/csv;base64,${encodedFile}`;
};

export const validateFileSize = (fileSize: number, maximumFileSize: number): boolean => {
  return fileSize < maximumFileSize * 1024 * 1024;
};

export const isUtf8ValidFormat = (inputString: string) => {
  try {
    new TextDecoder('utf-8').decode(new TextEncoder().encode(inputString));
    return true;
  } catch (error) {
    return false;
  }
};
