import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { getUserPathById, getUsersWithPaginationPath } from 'components/pages/App/routes/users/config';
import { User, UserRole, UserRoleType } from 'models/users.model';
import { getUsers } from 'services/api/users';
import { hasPermission } from 'services/permissions';
import UserProfileContext, { UserProfileContextState } from 'context/UserProfileContext';
import { useUploadCsv } from 'components/pages/UsersPage/resources/useUploadCsv';
import { useUsersAssets } from 'components/pages/UsersPage/resources/useUsersAssets';
import { useTenantsCsv } from 'components/pages/UsersPage/resources/useTenantsCsv';

export const useUsers = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const { userProfile } = useContext<UserProfileContextState>(UserProfileContext);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isInvitationModalVisible, setIsInvitationModalVisible] = useState<boolean>(false);
  const { onDownloadTenantsTemplateCsv } = useTenantsCsv();
  const {
    onUploadSelectedFile,
    onClosePreviewModal,
    onUploadTenantsTemplate,
    previewData,
    previewColumns,
    previewDataTitle,
    previewDataInfo,
    previewTranslations,
    previewModalVisible,
    loadingPreview,
    previewDataError,
    updating,
    errorSendingPreview,
  } = useUploadCsv();
  const {
    clientsList,
    clientsSelectorModalTranslations,
    clientSelectorForm,
    onDownloadAssetsTemplateCsv,
    onSelectClient,
    onCloseClientsSelectorModal,
    clientsSelectorModalVisible,
    loadingAssetsTemplateDownload,
    loadingClientsList,
  } = useUsersAssets();

  const acceptedRoles: UserRole[] = [
    UserRoleType.ROLE_ASSETS_MANAGER_ADMIN as UserRole,
    UserRoleType.ROLE_ASSETS_MANAGER_SUPER_ADMIN as UserRole,
  ];

  const hasInvitationPermissions = hasPermission('invite_users');
  const hasReadWritePermissions = userProfile?.roles.some((role) => acceptedRoles.includes(role));

  const translations = {
    titleText: t('users'),
    invitationUsersButtonText: t('invite_user'),
    searchInputPlaceholderText: t('search_with_ellipsis'),
    uploadCsvFileText: t('_UPLOAD_CSV_FILE'),
    downloadTenantsTemplateText: t('_DOWNLOAD_TENANTS_TEMPLATE'),
    downloadAssetsTemplateText: t('_DOWNLOAD_ASSETS_TEMPLATE'),
  };

  const getUsersWithPagination = async ({ page, size, search }: { page: number; size: number; search?: string }): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await getUsers({ current: page, size, search });
      response && setUsers(response.data);
      response && setTotalPages(response.meta.total_pages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectUser = (user: User) => {
    history.push(getUserPathById(user.id));
  };

  const onChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize!);
    history.push(getUsersWithPaginationPath({ page: String(page), size: String(pageSize!) }));
  };

  const onSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  };

  const onOpenInvitationModal = () => {
    setIsInvitationModalVisible(true);
  };

  const onCloseInvitationModal = () => {
    setIsInvitationModalVisible(false);
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    setCurrentPage(Number(newPage));
    setPageSize(Number(newSize));
  }, [page, size]);

  useEffect(() => {
    getUsersWithPagination({
      page: Number(page) || Number(TABLE_PAGINATION_PAGE_DEFAULT),
      size: Number(size) || Number(TABLE_PAGINATION_SIZE_DEFAULT),
      search: searchQuery,
    });
  }, [page, size, searchQuery]);

  return {
    users,
    totalPages,
    currentPage,
    pageSize,
    translations,
    clientsSelectorModalTranslations,
    clientsList,
    clientSelectorForm,
    previewData,
    previewDataTitle,
    previewDataInfo,
    previewTranslations,
    previewColumns,
    onChangePage,
    onSearch,
    onSelectUser,
    onOpenInvitationModal,
    onCloseInvitationModal,
    onDownloadTenantsTemplateCsv,
    onDownloadAssetsTemplateCsv,
    onCloseClientsSelectorModal,
    onSelectClient,
    onUploadSelectedFile,
    onClosePreviewModal,
    onUploadTenantsTemplate,
    loadingUsers: isLoading,
    invitationModalVisible: isInvitationModalVisible,
    hasInvitationPermissions,
    hasReadWritePermissions,
    clientsSelectorModalVisible,
    loadingClientsList,
    loadingAssetsTemplateDownload,
    previewModalVisible,
    loadingPreview,
    previewDataError,
    updating,
    errorSendingPreview,
  };
};
