import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import DividerAtom from 'components/atoms/DividerAtom';
import SkeletonAtom from 'components/atoms/SkeletonAtom';
import './styles.scss';

const CommonAreasAndBookingsMenuLoadingTemplate: FC = () => {
  return (
    <Paper className={'CommonAreasAndBookingsMenuLoadingTemplate__body'}>
      <div className={'CommonAreasAndBookingsMenuLoadingTemplate__body-menu'}>
        <div>
          <SkeletonAtom.Input className={'CommonAreasAndBookingsMenuLoadingTemplate__body-menu-skeleton'} active />
          <DividerAtom />
          <SkeletonAtom.Input className={'CommonAreasAndBookingsMenuLoadingTemplate__body-menu-skeleton'} active />
        </div>
      </div>
    </Paper>
  )
}

export default CommonAreasAndBookingsMenuLoadingTemplate;