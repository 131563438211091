import { FC } from 'react';
import DividerAtom from 'components/atoms/DividerAtom';
import TabSidebarMenuList from '../TabSidebarMenuList';
import { CommonAreasAndBookingsListMenuItem } from 'components/pages/CommonAreasBookingsListPage/resources/useBookingsList';
import './styles.scss';

interface Props {
  menuListItems: CommonAreasAndBookingsListMenuItem[];
  selectedItemId?: string;
  bookingsListButtonText: string;
  createCommonAreaButtonText: string;
  onSelectCommonArea: (commonAreaId: string) => void;
  onCreateCommonArea: () => void;
  onShowBookingsList: () => void;
  createCommonAreaButtonDisabled: boolean;
  showBookingListButtonDisabled: boolean;
}

const CommonAreasAndBookingsListMenu: FC<Props> = ({
  menuListItems,
  selectedItemId,
  bookingsListButtonText,
  createCommonAreaButtonText,
  onSelectCommonArea,
  onShowBookingsList,
  onCreateCommonArea,
  createCommonAreaButtonDisabled,
  showBookingListButtonDisabled,
}) => {
  return (
    <div>
      <button
        className={'CommonAreasAndBookingsMenu__title'}
        onClick={onShowBookingsList}
        disabled={showBookingListButtonDisabled}
      >
        {bookingsListButtonText}
      </button>
      <DividerAtom />
      <button
        className={'CommonAreasAndBookingsMenu__title'}
        onClick={onCreateCommonArea}
        disabled={createCommonAreaButtonDisabled}
      >
        {createCommonAreaButtonText}
      </button>
      <TabSidebarMenuList
        listItems={menuListItems}
        selectedItemId={selectedItemId}
        onClick={onSelectCommonArea}
      />
    </div>
  );
};

export default CommonAreasAndBookingsListMenu;
