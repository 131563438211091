import { RouteConfig } from 'react-router-config';
import UsersPage from 'components/pages/UsersPage';
import UserPage from 'components/pages/UserPage';
import { ROUTE_PATH_USER, ROUTE_PATH_USERS } from './config';

export const usersRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_USERS,
    exact: true,
    component: UsersPage,
  },
  {
    path: ROUTE_PATH_USER,
    exact: true,
    component: UserPage,
  },
];
