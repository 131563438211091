interface ClientEditRequiredFormValues {
  name: string;
  email: string;
}

export interface ClientEditFormValues extends ClientEditRequiredFormValues {
  description: string;
}

export enum ClientEditFormValuesNames {
  NAME = 'name',
  EMAIL = 'email',
  DESCRIPTION = 'description',
}

export interface ClientEditFormInputValue {
  label: ClientEditFormValuesNames;
  value: string;
}

export enum ClientMenuExtraPlacement {
  BOTTOM_RIGHT = 'bottomRight',
}
