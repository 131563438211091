import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { GetBookingListByProjectId } from 'core/domain/bookingsAndCommonAreas/repositories/getBookingListByProjectId';
import { BookingModel } from 'core/domain/bookingsAndCommonAreas/models/bookingEntityModels';
import { DeleteBooking } from 'core/domain/bookingsAndCommonAreas/repositories/deleteBooking';
import { messageAtom } from 'components/atoms/MessageAtom';
import { getCommonAreaCreatePath } from 'components/pages/App/routes/commonAreas/config';

export interface CommonAreasAndBookingsListMenuItem {
  id: string;
  name: string;
  disabled: boolean;
}

export const useBookingsList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>();
  const [isLoadingBookingsList, setIsLoadingBookingsList] = useState<boolean>(false);
  const [bookingsList, setBookingsList] = useState<BookingModel[]>([]);
  const [selectedBooking, setSelectedBooking] = useState<string>('');
  const [isConfirmDeleteBookingModalVisible, setIsConfirmDeleteBookingModalVisible] = useState<boolean>(false);
  const [isDeletingBooking, setIsDeletingBooking] = useState<boolean>(false);

  const isBookingsListEmpty = !bookingsList.length;

  const emptyTemplateTranslations = {
    emptyMessageTitle: t('_COMMON_AREA_BOOKINGS_EMPTY_TITLE'),
    emptyMessageText: t('_COMMON_AREA_BOOKINGS_EMPTY_TEXT'),
    emptyMessageLinkText: t('_COMMON_AREA_BOOKINGS_ADD_SPACE'),
  };

  const confirmDeleteBookingModalTranslations = {
    confirmModalTitle: t('_BOOKING_DELETE_MODAL_TITLE'),
    confirmModalText: t('_BOOKING_DELETE_MODAL_INFO'),
    confirmModalMainButtonText: t('_BOOKING_DELETE_MODAL_BUTTON'),
    confirmModalSecondaryButtonText: t('cancel'),
  };

  const getBookingsList = async (): Promise<void> => {
    setIsLoadingBookingsList(true);
    try {
      const response = await GetBookingListByProjectId(projectId);
      response && setBookingsList(response);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingBookingsList(false);
    }
  };

  const onDeleteBooking = (bookingId: string) => {
    setIsConfirmDeleteBookingModalVisible(true);
    setSelectedBooking(bookingId);
  };

  const onConfirmDeleteBooking = async () => {
    setIsDeletingBooking(true);
    try {
      await DeleteBooking(selectedBooking);
      await getBookingsList();
      const messageSuccess = t('_BOOKING_DELETE_MESSAGE_SUCCESS');
      messageAtom.success(messageSuccess);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsDeletingBooking(false);
    }
  };

  const onCloseConfirmDeleteBookingModal = () => {
    setIsConfirmDeleteBookingModalVisible(false);
  };

  const onCreateCommonArea = () => {
    const commonAreaCreateRoute = getCommonAreaCreatePath({ clientId, projectId });
    history.push(commonAreaCreateRoute);
  };

  useEffect(() => {
    getBookingsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, projectId]);

  return {
    bookingsList,
    emptyTemplateTranslations,
    confirmDeleteBookingModalTranslations,
    onDeleteBooking,
    onCloseConfirmDeleteBookingModal,
    onConfirmDeleteBooking,
    onCreateCommonArea,
    loadingBookingsList: isLoadingBookingsList,
    bookingsListEmpty: isBookingsListEmpty,
    confirmDeleteBookingModalVisible: isConfirmDeleteBookingModalVisible,
    deletingBooking: isDeletingBooking,
  };
};
