import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CreateInvitationModel, CreateInvitationResponseModel, CreateInvitationResponsePayloadModel } from '../models';
import { createInvitationToPayload, payloadToCreateInvitationResponse } from '../mappers';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string) => {
  return `${host}/access/create`;
};

export const CreateInvitation = async ({
  host,
  token,
  data,
}: {
  host: string;
  token: string;
  data: CreateInvitationModel;
}): Promise<CreateInvitationResponseModel> => {
  try {
    const url = getUrl(host);
    const transformedData = createInvitationToPayload(data);

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: transformedData },
    };

    const { data: responseData } = (await axios(options)) as AxiosResponse<CreateInvitationResponsePayloadModel>;
    return payloadToCreateInvitationResponse(responseData);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
