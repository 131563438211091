import { LegalDocumentationCheckedModel } from '../models/clientEntityModel';

export const LegalDocumentationCheckedEntity = ({
  isAccepted,
  isEnabled,
  linkPrivacyPolicy,
  linkTermsOfService,
}: LegalDocumentationCheckedModel): Readonly<LegalDocumentationCheckedModel> => {
  return Object.freeze({ isAccepted, isEnabled, linkTermsOfService, linkPrivacyPolicy });
};
