import { FC } from 'react';
import { Modal, Button, Space, Table } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { TenantsPreviewDataResponseModel } from 'core/domain/users/models/userModel';
import { Icon } from 'components/atoms/Icons';
import './styles.scss';

interface Props extends ModalProps {
  title: string;
  name: string;
  tableTitle: string;
  tableInfoText: string;
  mainButtonText: string;
  secondaryButtonText: string;
  errorTitle: string;
  errorDescription: string;
  errorSendingTitle: string;
  errorSendingDescription: string;
  data: TenantsPreviewDataResponseModel[];
  columns: any;
  onUpload: (data: any) => void;
  loading: boolean;
  dataError: boolean;
  errorSendingPreview: boolean;
}

const TABLE_HEIGHT = 300;
const TABLE_HEIGHT_WITH_ERRORS = 200;
const ICON_SIZE = 24;

const TenantsPreviewModal: FC<Props> = ({
  title,
  name,
  tableTitle,
  tableInfoText,
  mainButtonText,
  secondaryButtonText,
  errorTitle,
  errorDescription,
  errorSendingTitle,
  errorSendingDescription,
  data,
  columns,
  onCancel,
  onUpload,
  loading,
  dataError,
  errorSendingPreview,
  ...props
}) => {
  return (
    <Modal
      {...props}
      title={<Space>{title}</Space>}
      onCancel={onCancel}
      destroyOnClose
      width={'75%'}
      footer={[
        <Button key={'back'} onClick={onCancel}>
          {secondaryButtonText}
        </Button>,
        <Button key={'submit'} type={'primary'} loading={loading} onClick={onUpload} disabled={dataError || errorSendingPreview}>
          {mainButtonText}
        </Button>,
      ]}
    >
      <div>
        <p className={'TenantsPreviewModal__csv-title'}>{name}</p>
        <p >{tableInfoText}</p>
        <p className={'TenantsPreviewModal__csv-table-title'}>{tableTitle}</p>
        <Table<TenantsPreviewDataResponseModel>
          loading={loading}
          columns={columns}
          rowKey='email'
          dataSource={data}
          pagination={false}
          size={'small'}
          className={`${dataError && 'TenantsPreviewModal__table-error'}`}
          scroll={{ y: dataError ? TABLE_HEIGHT_WITH_ERRORS : TABLE_HEIGHT }}
        />
        {dataError && (
          <div className={'TenantsPreviewModal__error-wrapper'}>
            <Icon.Exclamation className={'TenantsPreviewModal__error-icon'} style={{ fontSize: ICON_SIZE }} />
            <div>
              <p className={'TenantsPreviewModal__error-title'}>{errorTitle}</p>
              <p className={'TenantsPreviewModal__error-description'}>{errorDescription}</p>
            </div>
          </div>
        )}
        {errorSendingPreview && (
          <div className={'TenantsPreviewModal__error-sending-wrapper'}>
            <Icon.Exclamation className={'TenantsPreviewModal__error-sending-icon'} style={{ fontSize: ICON_SIZE }} />
            <div>
              <p className={'TenantsPreviewModal__error-sending-title'}>{errorSendingTitle}</p>
              <p className={'TenantsPreviewModal__error-sending-description'}>{errorSendingDescription}</p>
            </div>
          </div>
        )}
      </div>

    </Modal>
  );
};

export default TenantsPreviewModal;
