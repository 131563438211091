import { useTranslation } from 'react-i18next';
import { MenuItemProps } from 'components/atoms/MenuItem';
import { ROUTE_PATH_ASSETS } from 'components/pages/App/routes/assets/config';
import { ROUTE_PATH_ALFRED_ACADEMY } from 'components/pages/App/routes/academy/config';
import { ROUTE_PATH_DASHBOARD } from 'components/pages/App/routes/dashboard/config';
import { ROUTE_PATH_USERS } from 'components/pages/App/routes/users/config';
import { ROUTE_PATH_SETTINGS, ROUTE_PATH_SETTINGS_CLIENTS } from 'components/pages/App/routes/settings/config';
import { ROUTE_PATH_PROJECTS } from 'components/pages/App/routes/projects/config';
import { ROUTE_PATH_SUPPORT_TICKETS } from 'components/pages/App/routes/supportTickets/config';
import { ROUTE_PATH_DIGITAL_SIGNAGE } from 'components/pages/App/routes/digitalSignage/config';
// import { ROUTE_PATH_CHECK_IN } from 'components/pages/App/routes/checkIn/config';
import { ROUTE_PATH_PROVISIONED } from 'components/pages/App/routes/provisioned/config';
import { ROUTE_PATH_COMMON_AREAS_CLIENT_AND_PROJECT_SELECTOR } from 'components/pages/App/routes/commonAreas/config';
import { ROUTE_PATH_ACCESSES_CLIENT_AND_PROJECT_SELECTOR } from 'components/pages/App/routes/access/config';
import { hasPermission } from 'services/permissions';
import { Icon } from 'components/atoms/Icons';
import { getGatewaysListWithPaginationPath, ROUTE_PATH_GATEWAYS_LIST } from 'components/pages/App/routes/gateways/config';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import { ROUTE_PATH_CHECK_IN } from 'components/pages/App/routes/checkIn/config';

export const keysMenuToPathManager = {
  [ROUTE_PATH_ALFRED_ACADEMY]: ROUTE_PATH_ALFRED_ACADEMY,
  [ROUTE_PATH_ASSETS]: ROUTE_PATH_ASSETS,
  [ROUTE_PATH_CHECK_IN]: ROUTE_PATH_CHECK_IN,
  [ROUTE_PATH_DASHBOARD]: ROUTE_PATH_DASHBOARD,
  [ROUTE_PATH_DIGITAL_SIGNAGE]: ROUTE_PATH_DIGITAL_SIGNAGE,
  [ROUTE_PATH_PROJECTS]: ROUTE_PATH_PROJECTS,
  [ROUTE_PATH_PROVISIONED]: ROUTE_PATH_PROVISIONED,
  [ROUTE_PATH_SUPPORT_TICKETS]: ROUTE_PATH_SUPPORT_TICKETS,
  [ROUTE_PATH_USERS]: ROUTE_PATH_USERS,
  [ROUTE_PATH_COMMON_AREAS_CLIENT_AND_PROJECT_SELECTOR]: ROUTE_PATH_COMMON_AREAS_CLIENT_AND_PROJECT_SELECTOR,
  [ROUTE_PATH_ACCESSES_CLIENT_AND_PROJECT_SELECTOR]: ROUTE_PATH_ACCESSES_CLIENT_AND_PROJECT_SELECTOR,
  [ROUTE_PATH_SETTINGS]: ROUTE_PATH_SETTINGS_CLIENTS,
  [ROUTE_PATH_GATEWAYS_LIST]: getGatewaysListWithPaginationPath({ page: TABLE_PAGINATION_PAGE_DEFAULT, size: TABLE_PAGINATION_SIZE_DEFAULT })
}

export const MenuItemsConfig = (): MenuItemProps[] => {
  const { t } = useTranslation();
  return [
    {
      key: ROUTE_PATH_DASHBOARD,
      icon: <Icon.Home />,
      children: t('MENU_START_TITLE'),
    },
    {
      key: ROUTE_PATH_ASSETS,
      icon: <Icon.List />,
      children: t('MENU_ASSETS_TITLE'),
    },
    {
      key: ROUTE_PATH_USERS,
      icon: <Icon.Users />,
      children: t('MENU_USERS_TITLE'),
    },
    {
      key: ROUTE_PATH_PROJECTS,
      icon: <Icon.Communities />,
      children: t('MENU_PROJECTS_TITLE'),
    },
    {
      key: ROUTE_PATH_SUPPORT_TICKETS,
      icon: <Icon.Flag />,
      children: t('MENU_SUPPORT_TICKETS_TITLE'),
    },
    {
      key: ROUTE_PATH_COMMON_AREAS_CLIENT_AND_PROJECT_SELECTOR,
      icon: <Icon.Invitation />,
      children: t('MENU_COMMON_AREAS_TITLE'),
    },
    {
      key: ROUTE_PATH_ACCESSES_CLIENT_AND_PROJECT_SELECTOR,
      icon: <Icon.DoorOpen />,
      children: t('MENU_ACCESSES_TITLE'),
      hidden: !hasPermission('read_access_list'),
    },
    {
      key: ROUTE_PATH_ALFRED_ACADEMY,
      icon: <Icon.Folder />,
      children: t('MENU_ALFRED_ACADEMY_TITLE'),
      hidden: !hasPermission('list_academy_docs'),
    },
    {
      key: ROUTE_PATH_GATEWAYS_LIST,
      icon: <Icon.Gateway />,
      children: t('MENU_GATEWAYS_TITLE'),
      hidden: !hasPermission('read_gateways_list'),
    },
    {
      key: ROUTE_PATH_DIGITAL_SIGNAGE,
      icon: <Icon.DigitalSignage />,
      children: t('MENU_DIGITAL_SIGNAGE_TITLE'),
      hidden: !hasPermission('list_digital_signage'),
    },
    // {
    //   key: ROUTE_PATH_CHECK_IN,
    //   icon: <Icon.CheckIn />,
    //   children: t('check_in'),
    //   hidden: !hasPermission('list_check_in'),
    // },
    {
      key: ROUTE_PATH_PROVISIONED,
      icon: <Icon.Provisioned />,
      children: t('MENU_PROVISIONED_TITLE'),
      hidden: !hasPermission('register_gateways'),
    },
    {
      key: ROUTE_PATH_SETTINGS,
      icon: <Icon.Setting />,
      children: t('MENU_SETTINGS_TITLE'),
    },
  ];
}
