import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProjectListResponseDataModel } from 'core/domain/project/model/projectModel';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { getProjectPathById } from 'components/pages/App/routes/projects/config';
import { ProjectTab } from 'models/project.model';
import { ClientsProjectsListColumns } from '../ClientProjectsListColumns';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { GetProjectsListByClientIdWithPagination } from 'core/domain/project/repositories/getProjectsListByClientIdWithPagination';
import { getClientTabListWithPaginationPath } from 'components/pages/App/routes/settings/config';
import { ClientTab } from '../../resources/models';

export const useClientProjectsList = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const history = useHistory();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const [projects, setProjects] = useState<ProjectListResponseDataModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [searchQuery, setSearchQuery] = useState<string>('');

  const translations = {
    projects: t('projects'),
    searchPlaceholder: t('search_with_ellipsis'),
  };

  const projectsColumns = ClientsProjectsListColumns();

  const getProjectsListByClientIdWithPagination = async ({
    page,
    size,
    search,
  }: {
    page: number;
    size: number;
    search?: string;
  }): Promise<void> => {
    setIsLoading(true);
    try {
      const { data, meta } = await GetProjectsListByClientIdWithPagination({
        host,
        token,
        clientId,
        pageNumber: page,
        pageSize: size,
        search,
      });
      setProjects(data);
      setTotalPages(meta.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize!);
    history.push(getClientTabListWithPaginationPath({ clientId, tab: ClientTab.PROJECTS, page: String(page), size: String(pageSize!) }));
  };

  const onSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  };

  const onSelectProject = (project: ProjectListResponseDataModel, tab?: ProjectTab) => {
    history.push(getProjectPathById({ projectId: project.id, tab }));
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    setCurrentPage(Number(newPage));
    setPageSize(Number(newSize));
  }, [page, size]);

  useEffect(() => {
    getProjectsListByClientIdWithPagination({
      page: Number(page) || Number(TABLE_PAGINATION_PAGE_DEFAULT),
      size: Number(size) || Number(TABLE_PAGINATION_SIZE_DEFAULT),
      search: searchQuery,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, searchQuery]);

  return {
    projects,
    projectsColumns,
    totalPages,
    currentPage,
    pageSize,
    translations,
    onChangePage,
    onSearch,
    onSelectProject,
    loadingProjects: isLoading,
  };
};
