import { FC } from 'react';
import { Svg } from '../model/svg';

const EmptyBooking: FC<Svg> = ({ width = '96', height = '96', ...props }) => (
  <svg width={width} height={height} viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.15'
      d='M46.3903 -2.02779e-06C55.6166 -2.43108e-06 64.4298 1.80392 72 6.6C78.1626 10.5043 83.5014 16.7345 87.5686 22.8C94.2071 32.7 96 40.0273 96 49.8C96 63.2175 93 75 84 84C75.9049 92.0546 63.4085 96 48 96C33.2614 96 19.322 89.502 10.8 79.8C5.00269 73.2 -1.59745e-06 59.4545 -2.1506e-06 46.8C-2.69531e-06 34.3385 1.24987 21.4513 8.4 13.2C19.8383 -8.49656e-06 31.2874 -1.36762e-06 46.3903 -2.02779e-06Z'
      fill='#96C0C4'
    />
    <path
      d='M0.499989 49.6097C0.49999 40.4462 2.29175 31.7344 7.02237 24.2676C10.8739 18.1882 17.0404 12.8955 23.0785 8.84667C32.8927 2.26566 40.1224 0.499995 49.8 0.499996C63.1456 0.499997 74.7754 3.48292 83.6458 12.3529C91.5729 20.3199 95.5 32.6658 95.5 48C95.5 62.6001 89.0599 76.4007 79.47 84.8243C76.2357 87.6653 71.2151 90.338 65.4103 92.3003C59.611 94.2608 53.0652 95.5 46.8 95.5C34.3287 95.5 21.623 94.2372 13.5274 87.2221C6.9989 81.5649 3.75588 75.9282 2.13307 69.8501C0.503146 63.7454 0.499988 57.1766 0.499989 49.6097Z'
      stroke='#96C0C4'
    />
    <g clip-path='url(#clip0_10499_3301)'>
      <path
        d='M55.7277 50.07L49.9321 48.5171C48.8696 48.2324 47.7673 48.8688 47.4826 49.9313L45.9297 55.7268C45.645 56.7894 46.2814 57.8916 47.3439 58.1763L53.1395 59.7293C54.202 60.014 55.3043 59.3776 55.589 58.315L57.1419 52.5195C57.4266 51.457 56.7902 50.3547 55.7277 50.07ZM60.9041 30.7515L60.3864 32.6833L44.9316 28.5422L45.4492 26.6104C45.7339 25.5479 45.0975 24.4456 44.035 24.1609C42.9725 23.8762 41.8702 24.5126 41.5855 25.5751L41.0679 27.5069L39.136 26.9893C36.9917 26.4147 34.8258 27.6979 34.2564 29.8229L26.9901 56.8637C26.4207 58.9887 27.6742 61.1881 29.8186 61.7626L56.8645 69.0096C58.9895 69.579 61.1941 68.3062 61.7635 66.1811L69.0104 39.1352C69.5798 37.0102 68.307 34.8056 66.182 34.2362L64.2501 33.7186L64.7678 31.7868C65.0525 30.7242 64.4161 29.622 63.3535 29.3373C62.291 29.0526 61.1888 29.689 60.9041 30.7515ZM55.9679 64.6282L32.7857 58.4166C31.7232 58.1319 31.0868 57.0296 31.3715 55.9671L36.5479 36.6486L63.5938 43.8955L58.4174 63.214C58.1327 64.2765 57.0304 64.9129 55.9679 64.6282Z'
        fill='#96C0C4'
      />
    </g>
    <defs>
      <clipPath id='clip0_10499_3301'>
        <rect
          x='31.0293'
          y='18.6055'
          width='48'
          height='48'
          rx='4'
          transform='rotate(15 31.0293 18.6055)'
          fill='white'
        />
      </clipPath>
    </defs>
  </svg>
)

export default EmptyBooking;
