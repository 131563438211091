import { FC } from 'react';
import moment, { Moment } from 'moment';
import { BookingDuration, CommonAreaTimeAvailabilityModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { Divider, Input, Select, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import WeekDaysSelector from '../WeekDaysSelector';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { FormValues } from '../../models';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import DangerTextButton from 'components/atoms/Button/DangerTextButton';
import { TIME_FORMAT } from 'constants/date';
import { SensorModel } from 'core/domain/bookingsAndCommonAreas/models/sensorEntityModels';
import { Icon } from 'components/atoms/Icons';
import CommonAreaTimeAvailabilitySelector from 'components/molecules/CommonAreaTimeAvailabilitySelector';
import ImagePickerDisplay from 'components/molecules/ImagePickerDisplay';
import './styles.scss';

interface CommonAreaFormGridProps {
  bookingDurationOptionList: { id: BookingDuration; name: string }[];
  errors: any;
  form: FormInstance<FormValues>;
  Form: any;
  id?: string;
  selectedDays: number[];
  selectedSensors?: SensorModel[];
  sensorOptionList: SensorByProjectModel[];
  timeAvailability: Array<CommonAreaTimeAvailabilityModel | null>;
  backgroundImage?: string;
  onChangeInputFieldsValues?: ({ label, values }: { label: string; values: string[] }) => void;
  onChangeTimeAvailability: (values: [Moment, Moment] | null, index: number) => void;
  onClose: () => void;
  onFinish: ({ id, values }: { id?: string; values: FormValues }) => void;
  onRemoveArea?: (id: string) => void;
  onSelectBookingDuration: (bookingDurationId: BookingDuration) => void;
  onSelectDay: (day: number) => void;
  onSelectSensors: (sensorIds: string[]) => void;
  onUnselectDay: (day: number) => void;
  onAddTimeAvailabilityInterval: () => void;
  onDeleteTimeAvailabilityInterval: (index: number) => void;
  getTimeAvailabilityErrorMessage: () => void;
  onChangeBackgroundImage: (image: string) => void;
  isDisabledSaved?: boolean;
  isSaving: boolean;
}

const NAMES_FORMA_VALUES = {
  name: 'name',
  access: 'access',
  capacity: 'capacity',
  daysAvailability: 'daysAvailability',
  timeAvailability: 'timeAvailability',
  bookingDuration: 'bookingDuration',
  backgroundImage: 'backgroundImage',
  infoUserTitle: 'infoUserTitle',
  infoUserDescription: 'infoUserDescription',
  maximumBookingsByUser: 'maximumBookingsByUser',
  maximumTimeAvailabilityValue: 'maximumTimeAvailabilityValue',
  maximumTimeAvailabilityPeriod: 'maximumTimeAvailabilityPeriod',
};

const CHARACTERS_MAX_LENGTH = {
  title: 60,
  description: 256,
};

const CommonAreaFormGrid: FC<CommonAreaFormGridProps> = ({
  bookingDurationOptionList,
  errors,
  form,
  Form,
  id,
  selectedDays,
  selectedSensors,
  sensorOptionList,
  timeAvailability,
  backgroundImage,
  onChangeInputFieldsValues,
  onChangeTimeAvailability,
  onClose,
  onFinish,
  onRemoveArea,
  onSelectBookingDuration,
  onSelectDay,
  onSelectSensors,
  onUnselectDay,
  onAddTimeAvailabilityInterval,
  onDeleteTimeAvailabilityInterval,
  getTimeAvailabilityErrorMessage,
  onChangeBackgroundImage,
  isDisabledSaved,
  isSaving,
}) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const handleOnChangeName = (value: string) => {
    onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.name, values: [value] });
  };

  const handleOnChangeAccess = (sensorIds: string[]) => {
    onSelectSensors(sensorIds);
    onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.access, values: sensorIds });
  };

  const handleOnChangeCapacity = (value: string) => {
    onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.capacity, values: [value] });
  };

  const handleOnChangeTimeAvailability = (values: [Moment, Moment] | null, index: number) => {
    if (!!values) {
      const [start, end] = values;
      const startParsed = moment(start).format(TIME_FORMAT);
      const endParsed = moment(end).format(TIME_FORMAT);
      onChangeTimeAvailability([start, end], index);
      onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.timeAvailability, values: [`${startParsed} ${endParsed}`] });
    }
    !values && onChangeTimeAvailability(null, index);
  };

  const handleOnChangeBookingDuration = (bookingDurationId: BookingDuration) => {
    onSelectBookingDuration(bookingDurationId);
    onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.bookingDuration, values: [String(bookingDurationId)] });
  };

  const handleOnChangeInfoUserTitle = (value: string) => {
    onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.infoUserTitle, values: [value] });
  };

  const handleOnChangeInforUserDescription = (value: string) => {
    onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.infoUserDescription, values: [value] });
  };

  const handleOnChangeMaximumBookingsByUser = (value: string) => {
    onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.maximumBookingsByUser, values: [value] });
  }

  const handleOnChangeMaximumTimeAvailabilityPeriod = (value: string) => {
    onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.maximumTimeAvailabilityPeriod, values: [value] });
  }

  const handleOnChangeMaximumTimeAvailabilityValue = (value: string) => {
    onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.maximumTimeAvailabilityValue, values: [value] });
  }

  const handleOnChangeBackgroundImage = (value: string) => {
    onChangeInputFieldsValues?.({ label: NAMES_FORMA_VALUES.backgroundImage, values: [value] });
    onChangeBackgroundImage(value);
  }

  const filteredSelectedSensors = selectedSensors?.map((sensor) => sensor.id) || [];

  return (
    <Form
      form={form}
      onFinish={(values: FormValues) => onFinish({ id, values })}
      layout={'vertical'}
      requiredMark={false}
      initialValues={{ [NAMES_FORMA_VALUES.access]: filteredSelectedSensors }}
    >
      <div className={'CommonAreaFormGrid__container'}>
        <Form.Item
          className={'CommonAreaFormGrid__grid-item'}
          label={`${t('_COMMON_AREA_FORM_LABEL_NAME')} *`}
          name={NAMES_FORMA_VALUES.name}
          validateStatus={errors.name ? 'error' : undefined}
          help={errors.name ? t('field_required') : undefined}
        >
          <Input disabled={isSaving} onChange={(value) => handleOnChangeName(value.target.value)} />
        </Form.Item>
        <Form.Item
          className={'CommonAreaFormGrid__grid-item'}
          label={`${t('_COMMON_AREA_FORM_LABEL_ACCESS')}`}
          name={NAMES_FORMA_VALUES.access}
        >
          <Select placeholder={t('select_a_option')} onChange={handleOnChangeAccess} disabled={isSaving} allowClear mode='multiple'>
            {sensorOptionList.map((option, key) => (
              <Select.Option key={key} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={'CommonAreaFormGrid__grid-item'}
          label={`${t('_COMMON_AREA_FORM_LABEL_CAPACITY')} *`}
          name={NAMES_FORMA_VALUES.capacity}
          validateStatus={errors.capacity ? 'error' : undefined}
          help={errors.capacity ? t('field_required') : undefined}
        >
          <Input
            type={'number'}
            min={1}
            max={9999}
            maxLength={4}
            disabled={isSaving}
            onChange={(value) => handleOnChangeCapacity(value.target.value)}
          />
        </Form.Item>
        <Form.Item
          className={'CommonAreaFormGrid__grid-item'}
          label={`${t('_COMMON_AREA_FORM_LABEL_AVAILABILITY')} *`}
          name={NAMES_FORMA_VALUES.daysAvailability}
          validateStatus={errors.daysAvailability ? 'error' : undefined}
          help={errors.daysAvailability ? t('field_required') : undefined}
        >
          <WeekDaysSelector days={selectedDays} onSelectDay={onSelectDay} onUnselectDay={onUnselectDay} disabled={isSaving} />
        </Form.Item>
        <Form.Item
          className={'CommonAreaFormGrid__grid-item'}
          label={`${t('_COMMON_AREA_FORM_LABEL_TIME_AVAILABILITY')} *`}
          name={NAMES_FORMA_VALUES.timeAvailability}
          validateStatus={errors.timeAvailability ? 'error' : undefined}
          help={errors.timeAvailability ? getTimeAvailabilityErrorMessage() : undefined}
        >
          <CommonAreaTimeAvailabilitySelector
            timeAvailability={timeAvailability}
            disabled={isSaving}
            onAddTimeAvailabilityInterval={onAddTimeAvailabilityInterval}
            onChangeTimeAvailability={handleOnChangeTimeAvailability}
            onDeleteTimeAvailabilityInterval={onDeleteTimeAvailabilityInterval}
          />
        </Form.Item>
        <div className={'CommonAreaFormGrid__grid-item'}>
          <Form.Item
            label={(
              <>
                <span className={'CommonAreaFormGrid__info-label'}>{`${t('_COMMON_AREA_FORM_LABEL_BOOKING_DURATION')} *`}</span>
                <Tooltip title={t('_COMMON_AREA_FORM_INFO_FIELD')}>
                  <Icon.InfoCircleFilled />
                </Tooltip>
              </>
            )}
            name={NAMES_FORMA_VALUES.bookingDuration}
            validateStatus={errors.bookingDuration ? 'error' : undefined}
            help={errors.bookingDuration ? t('field_required') : undefined}
          >
            <Select placeholder={t('select_a_option')} onChange={handleOnChangeBookingDuration} disabled={isSaving}>
              {bookingDurationOptionList.map((option, key) => (
                <Select.Option key={key} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          className={'CommonAreaFormGrid__grid-item'}
          label={(
            <>
              <span className={'CommonAreaFormGrid__info-label'}>{`${t('_COMMON_AREA_FORM_LABEL_MAXIMUM_BOOKINGS_BY_USER')} *`}</span>
              <Tooltip title={t('_COMMON_AREA_FORM_LABEL_MAXIMUM_BOOKINGS_BY_USER_INFO')}>
                <Icon.InfoCircleFilled />
              </Tooltip>
            </>
          )}
          name={NAMES_FORMA_VALUES.maximumBookingsByUser}
          validateStatus={errors.maximumBookingsByUser ? 'error' : undefined}
          help={errors.maximumBookingsByUser ? t('field_required') : undefined}
        >
          <Input
            type={'number'}
            min={1}
            max={1000}
            maxLength={4}
            disabled={isSaving}
            onChange={(value) => handleOnChangeMaximumBookingsByUser(value.target.value)}
          />
        </Form.Item>
        <Form.Item
          className={'CommonAreaFormGrid__grid-item'}
          label={(
            <>
              <span className={'CommonAreaFormGrid__info-label'}>{`${t('_COMMON_AREA_FORM_LABEL_MAXIMUM_TIME_AVAILABILITY')} *`}</span>
              <Tooltip title={t('_COMMON_AREA_FORM_LABEL_MAXIMUM_TIME_AVAILABILITY_INFO')}>
                <Icon.InfoCircleFilled />
              </Tooltip>
            </>
          )}
          validateStatus={errors.maximumTimeAvailability ? 'error' : undefined}
          help={errors.maximumTimeAvailability ? t('field_required') : undefined}
        >
          <Input.Group compact>
            <Form.Item name={NAMES_FORMA_VALUES.maximumTimeAvailabilityValue} noStyle>
              <Input
                style={{ width: '50%' }}
                type={'number'}
                min={1}
                max={12}
                maxLength={2}
                disabled={isSaving}
                placeholder={t('_COMMON_AREA_FORM_LABEL_MAXIMUM_TIME_AVAILABILITY_VALUE_PLACEHOLDER')}
                onChange={(value) => handleOnChangeMaximumTimeAvailabilityValue(value.target.value)}
              />
            </Form.Item>
            <Form.Item name={NAMES_FORMA_VALUES.maximumTimeAvailabilityPeriod} noStyle>
              <Select placeholder={t('_COMMON_AREA_FORM_LABEL_MAXIMUM_TIME_AVAILABILITY_PERIOD_PLACEHOLDER')} style={{ width: '50%' }} onChange={(value) => handleOnChangeMaximumTimeAvailabilityPeriod(value)}>
                <Option value={'month'}>{t('_TIME_PERIOD_MONTH')}</Option>
                <Option value={'week'}>{t('_TIME_PERIOD_WEEK')}</Option>
                <Option value={'day'}>{t('_TIME_PERIOD_DAY')}</Option>
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          className={'CommonAreaFormGrid__grid-item'}
          label={(
            <>
              <span className={'CommonAreaFormGrid__image-wrapper-info'}>{t('_COMMON_AREA_BACKGROUND_IMAGE')}</span>
              <Tooltip title={t('_COMMON_AREA_FORM_BACKGROUND_IMAGE_REQUIREMENTS')} overlayInnerStyle={{ width: 290, textAlign: 'center', marginLeft: -20 }}>
                <Icon.InfoCircleFilled />
              </Tooltip>
            </>
          )}
          name={NAMES_FORMA_VALUES.backgroundImage}
        >
          <div className={'CommonAreaFormGrid__image-wrapper'}>
            <ImagePickerDisplay
              onChange={(value: string) => handleOnChangeBackgroundImage(value)}
              initialValues={backgroundImage || ''}
              sizeLimit={3}
              acceptedTypes={['jpg', 'jpeg', 'png']}
              disabled={isSaving}
            />
          </div>
        </Form.Item>
        <div className={'CommonAreaFormGrid__grid-item'}>
          <p className={'CommonAreaFormGrid__label'}>{t('_COMMON_AREA_FORM_LABEL_INFORMATION_TO_USERS')}</p>
          <Form.Item name={NAMES_FORMA_VALUES.infoUserTitle} className={'CommonAreaFormGrid__userTitle'}>
            <Input
              showCount
              maxLength={CHARACTERS_MAX_LENGTH.title}
              disabled={isSaving}
              onChange={(value) => handleOnChangeInfoUserTitle(value.target.value)}
            />
          </Form.Item>
          <Form.Item name={NAMES_FORMA_VALUES.infoUserDescription} className={'CommonAreaFormGrid__userDescription'}>
            <Input.TextArea
              showCount
              maxLength={CHARACTERS_MAX_LENGTH.description}
              disabled={isSaving}
              onChange={(value) => handleOnChangeInforUserDescription(value.target.value)}
            />
          </Form.Item>
        </div>
      </div>
      <Divider />
      <div className={`CommonAreaFormGrid__actions CommonAreaFormGrid__actions${onRemoveArea ? '--update' : '--create'}`}>
        {onRemoveArea && <DangerTextButton title={t('_COMMON_AREA_DELETE')} onClick={() => id && onRemoveArea(id)} disabled={isSaving} />}
        <div>
          <DefaultButton
            title={t('_COMMON_AREA_CLOSE')}
            onClick={onClose}
            className={'CommonAreaFormGrid__close-button'}
            disabled={isSaving}
          />
          <PrimaryButton
            htmlType={'submit'}
            title={t('_COMMON_AREA_FORM_SAVE')}
            loading={isSaving}
            disabled={isDisabledSaved || isSaving}
          />
        </div>
      </div>
    </Form>
  );
};

export default CommonAreaFormGrid;
