import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TabsAtom, { TabsAtomProps } from 'components/atoms/TabsAtom';

const TabsTitles: FC<TabsAtomProps> = (props) => {
  const { t } = useTranslation();

  return (
    <TabsAtom defaultActiveKey='start' {...props}>
      <TabsAtom.TabPane tab={<span>{t('start')}</span>} key='start' />
      <TabsAtom.TabPane tab={<span>{t('users')}</span>} key='users' />
      <TabsAtom.TabPane tab={<span>{t('accesses')}</span>} key='accesses' />
      <TabsAtom.TabPane tab={<span>{t('documents')}</span>} key='documents' />
      <TabsAtom.TabPane tab={<span>{t('cameras')}</span>} key='cameras' />
      <TabsAtom.TabPane tab={<span>{t('devices')}</span>} key='devices' />
      <TabsAtom.TabPane tab={<span>{t('gateways')}</span>} key='gateway' />
      <TabsAtom.TabPane tab={<span>{t('consumptions')}</span>} key='consumptions' />
      <TabsAtom.TabPane tab={<span>{t('events')}</span>} key='events' />
      <TabsAtom.TabPane tab={<span>{t('useful_contacts')}</span>} key='useful-contacts' />
    </TabsAtom>
  );
};

export default TabsTitles;
