import { FC } from 'react';
import { Empty, Skeleton } from 'antd';
import { ChartSwitch } from 'components/pages/AssetPage/Tabs/TabConsumption/OptionChart/ChartSwitch';
import { useChartConsumption } from './resources/useChartConsumption';
import AssetConsumptionsForm from 'components/molecules/AssetConsumptionsForm';
import './styles.scss';

const OptionChart: FC = () => {
  const {
    sensors,
    dateRange,
    selectedSensor,
    dataPresence,
    consumptionsData,
    formTranslations,
    initialSensorText,
    noDataText,
    Form,
    form,
    initialAssetConsumptionFormValues,
    disabledDate,
    disabledDateMonth,
    onDownloadCSV,
    onSelectSensor,
    onGetSensorById,
    onChangeCalendar,
    onChangeMonth,
    onSubmit,
    areThereConsumptionData,
    isLoadingConsumptions,
    isOnSubmitButtonEnabled,
  } = useChartConsumption();

  return (
    <div className='TabConsumption__container'>
      <AssetConsumptionsForm
        Form={Form}
        form={form}
        initialValues={initialAssetConsumptionFormValues}
        sensors={sensors}
        dateRange={dateRange}
        onSelectSensor={onSelectSensor}
        onChangeCalendar={onChangeCalendar}
        onChangeMonth={onChangeMonth}
        onDownloadCSV={onDownloadCSV}
        onSubmit={onSubmit}
        disabledDate={disabledDate}
        disabledDateMonth={disabledDateMonth}
        translations={formTranslations}
        isMainButtonDisabled={!isOnSubmitButtonEnabled}
        isSecondaryButtonDisabled={!areThereConsumptionData}
        isLoadingConsumptions={isLoadingConsumptions}

      />
      <div className='TabConsumption__chart'>
        {isLoadingConsumptions ? (
          <Skeleton active />
        ) : (
          <div>
            {consumptionsData && !!consumptionsData.meta.total ? (
              <ChartSwitch allData={consumptionsData} dataPresence={dataPresence} dateRange={dateRange} onGetSensorById={onGetSensorById} />
            ) : (
              <Empty description={!selectedSensor && !areThereConsumptionData ? initialSensorText : noDataText} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionChart;
