import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UnassignGateway } from 'core/domain/gateways/repositories/unassignGateway';
import { messageAtom } from 'components/atoms/MessageAtom';
import { AssignGateway } from 'core/domain/gateways/repositories/assignGateway';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';

export const useAssignGateway = () => {
  const { t } = useTranslation();
  const { token } = useUserSession();
  const { host } = useEnvironment();
  const { assetId } = useParams<{ assetId: string }>();
  const [unlinkingGatewayId, setUnlinkingGatewayId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasLinkedGateway, setHasLinkedGateway] = useState<boolean>(false);
  const [showConfirmationOnUnassignGatewayModal, setShowConfirmationOnUnassignGatewayModal] = useState<boolean>(false);

  const unassignTranslations = {
    confirmationOnUnassignGatewayModalTitle: t('_UNASSIGN_GATEWAY_CONFIRMATION_MODAL_TITLE'),
    confirmationOnUnassignGatewayModalText: t('_UNASSIGN_GATEWAY_CONFIRMATION_MODAL_TEXT'),
    confirmationOnUnassignGatewayModalMainButtonText: t('unlink_gateway'),
    confirmationOnUnassignGatewayModalSecondaryButtonText: t('cancel'),
  };

  const onConfirmUnassignGateway = async () => {
    setIsLoading(true);
    try {
      await UnassignGateway({ host, token, gatewayId: unlinkingGatewayId, assetId });
      setHasLinkedGateway(false);
      setShowConfirmationOnUnassignGatewayModal(false);
      setUnlinkingGatewayId('');
    } catch (error) {
      messageAtom.error(t('unassign_gateway_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const onUnassignGateway = (unAssignedGatewayId: string) => {
    setShowConfirmationOnUnassignGatewayModal(true);
    setUnlinkingGatewayId(unAssignedGatewayId);
  };

  const onAssignGateway = async (gatewayQid: string) => {
    setIsLoading(true);
    try {
      await AssignGateway({ host, token, gatewayQid, assetId });
      setHasLinkedGateway(true);
      messageAtom.success(t('assign_gateway_success'));
    } catch (error) {
      messageAtom.error(t('assign_gateway_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelConfirmationOnUnAssignGatewayModal = () => {
    setShowConfirmationOnUnassignGatewayModal(false);
    setUnlinkingGatewayId('');
  };

  const updateHasLinkedGateways = (value: boolean) => {
    setHasLinkedGateway(value);
  };

  return {
    unassignTranslations,
    onAssignGateway,
    onUnassignGateway,
    onConfirmUnassignGateway,
    onCancelConfirmationOnUnAssignGatewayModal,
    updateHasLinkedGateways,
    loadingGatewayAssignation: isLoading,
    hasLinkedGateway,
    showConfirmationOnUnassignGatewayModal,
  };
};
