import { FC } from 'react';
import { Icon } from 'components/atoms/Icons';
import './styles.scss';

interface Props {
  title: string;
  firstParagraph: string;
  secondParagraph: string;
  buttonText: string;
  onClick: () => void;
}

const MaintenanceTemplate: FC<Props> = ({ title, firstParagraph, secondParagraph, buttonText, onClick }) => {
  return (
    <div className={'MaintenanceTemplate__container'}>
      <div className={'MaintenanceTemplate__empty'}>
        <div className='MaintenanceTemplate__container-info'>
          <div className='MaintenanceTemplate__icon'>
            <Icon.Maintenance />
          </div>
          <h2 className='MaintenanceTemplate__title'>{title}</h2>
          <p className='MaintenanceTemplate__text'>{firstParagraph}</p>
          <p className='MaintenanceTemplate__text'>{secondParagraph}</p>
          <div className='MaintenanceTemplate__wrapper-link MaintenanceTemplate__link' onClick={onClick}>
            {buttonText}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaintenanceTemplate;