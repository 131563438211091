import { FC } from 'react';
import AccessesLogListTemplate from 'components/template/AccessesLogListTemplate';
import { useAccessesLogList } from './resources/useAccessesLogList';
import AccessDownloadLogModal from 'components/organisms/AccessDownloadLogModal';

const AccessesLogListTab: FC = () => {
  const {
    logList,
    logListColumnsLiterals,
    downloadLogModalLiterals,
    totalPages,
    currentPage,
    pageSize,
    form,
    onChangePage,
    onSubmitDownloadLogForm,
    onShowDownloadLog,
    onCancelDownloadLog,
    getIsDisabledFromDate,
    getIsDisabledUntilDate,
    isLoading,
    isLoadingDownloadLog,
    isVisibleDownloadLogModal,
  } = useAccessesLogList();

  return (
    <>
      <AccessesLogListTemplate
        logList={logList}
        totalPages={totalPages}
        pageSize={pageSize}
        currentPage={currentPage}
        translations={logListColumnsLiterals}
        onChangePage={onChangePage}
        onShowDownloadLog={onShowDownloadLog}
        isLoading={isLoading}
      />
      <AccessDownloadLogModal
        {...downloadLogModalLiterals}
        form={form}
        onFinish={onSubmitDownloadLogForm}
        onCancel={onCancelDownloadLog}
        getIsDisabledFromDate={getIsDisabledFromDate}
        getIsDisabledUntilDate={getIsDisabledUntilDate}
        isVisible={isVisibleDownloadLogModal}
        isLoading={isLoadingDownloadLog}
      />
    </>
  )
}

export default AccessesLogListTab;