import i18next from 'i18next';

export interface SupportTicketsTranslationsTypes {
  TITLE: string;
  TITLE_TIME_PERIOD: string;
  OPEN_TICKETS: string;
  TICKETS_CLOSED: string;
  TICKETS_CLOSED_TIME_AVERAGE: string;
  FIRST_RESPONSE_TIME_AVERAGE: string;
  TICKETS_RECEIVED: string;
  TICKETS_REVOKED: string;
  TICKETS_IN_PROGRESS: string;
}

export interface UsersTranslationsTypes {
  TITLE: string;
  TITLE_TIME_PERIOD: string;
  USERS_AVERAGE: string;
  ERROR_TITLE: string;
  ERROR_DESCRIPTION: string;
}

export interface OccupationTranslationTypes {
  TITLE: string;
  TITLE_TIME_PERIOD: string;
  OCCUPIED: string;
  UNOCCUPIED: string;
  ERROR_TITLE: string;
  ERROR_DESCRIPTION: string;
}

export enum SupportTicketsStatisticsUnit {
  MINUTES = 'minutes',
  MINUTE = 'minute',
  SECONDS = 'seconds',
  HOURS = 'hours',
  HOUR = 'hour',
  DAY = 'day',
  DAYS = 'days',
  DEFAULT = '',
}

export const supportTicketsUnitsTranslationsManager = {
  [SupportTicketsStatisticsUnit.DAY]: () => ` ${i18next.t('_SUPPORT_TICKETS_UNITS_DAY')}`,
  [SupportTicketsStatisticsUnit.DAYS]: () => ` ${i18next.t('_SUPPORT_TICKETS_UNITS_DAYS')}`,
  [SupportTicketsStatisticsUnit.MINUTES]: () => i18next.t('_SUPPORT_TICKETS_UNITS_MINUTES'),
  [SupportTicketsStatisticsUnit.HOURS]: () => i18next.t('_SUPPORT_TICKETS_UNITS_HOURS'),
  [SupportTicketsStatisticsUnit.MINUTE]: () => i18next.t('_SUPPORT_TICKETS_UNITS_MINUTE'),
  [SupportTicketsStatisticsUnit.HOUR]: () => i18next.t('_SUPPORT_TICKETS_UNITS_HOUR'),
  [SupportTicketsStatisticsUnit.SECONDS]: () => i18next.t('_SUPPORT_TICKETS_UNITS_SECONDS'),
  [SupportTicketsStatisticsUnit.DEFAULT]: () => '',
};

export const getSupportTicketsTranslations = (): SupportTicketsTranslationsTypes => {
  return {
    TITLE: i18next.t('issues'),
    TITLE_TIME_PERIOD: i18next.t('_SUPPORT_TICKETS_MONTH_PERIOD_IN_DAYS'),
    OPEN_TICKETS: i18next.t('_SUPPORT_TICKETS_OPENED'),
    TICKETS_CLOSED: i18next.t('_SUPPORT_TICKETS_CLOSED'),
    TICKETS_CLOSED_TIME_AVERAGE: i18next.t('_SUPPORT_TICKETS_CLOSED_TIME_AVERAGE'),
    FIRST_RESPONSE_TIME_AVERAGE: i18next.t('_SUPPORT_TICKETS_FIRST_RESPONSE_TIME_AVERAGE'),
    TICKETS_RECEIVED: i18next.t('_SUPPORT_TICKETS_RECEIVED'),
    TICKETS_REVOKED: i18next.t('_SUPPORT_TICKETS_REVOKED'),
    TICKETS_IN_PROGRESS: i18next.t('_SUPPORT_TICKETS_IN_PROGRESS'),
  };
};

export const getUsersTranslations = (): UsersTranslationsTypes => {
  return {
    TITLE: i18next.t('_USERS_ACTIVE'),
    TITLE_TIME_PERIOD: i18next.t('_USERS_ACTIVE_TIME_PERIOD'),
    USERS_AVERAGE: i18next.t('_USERS_ACTIVE_AVERAGE'),
    ERROR_TITLE: i18next.t('_DASHBOARD_ERROR_TITLE'),
    ERROR_DESCRIPTION: i18next.t('_DASHBOARD_ERROR_DESCRIPTION'),
  };
};

export const getOccupationTranslations = (): OccupationTranslationTypes => {
  return {
    TITLE: i18next.t('_OCCUPATION_TITLE'),
    TITLE_TIME_PERIOD: i18next.t('_OCCUPATION_TITLE_TIME_PERIOD'),
    OCCUPIED: i18next.t('_OCCUPATION_OCCUPIED'),
    UNOCCUPIED: i18next.t('_OCCUPATION_UNOCCUPIED'),
    ERROR_TITLE: i18next.t('_DASHBOARD_ERROR_TITLE'),
    ERROR_DESCRIPTION: i18next.t('_DASHBOARD_ERROR_DESCRIPTION'),
  };
};
