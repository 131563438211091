import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormProps, message, Upload } from 'antd';
import UploadDragger from 'components/atoms/UploadDragger';
import { FilePdfTwoTone } from '@ant-design/icons';
import { COLORS } from 'constants/colors';
import { RcFile } from 'antd/lib/upload';

export interface DocumentManagementFormValues {
  files: any[];
}

const dummyRequest = ({ f, onSuccess }: any) => {
  onSuccess('ok');
};

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const MAX_SIZE_PDF_MB = 10;

const DocumentManagementFrom: FC<FormProps> = ({ onFinish, ...props }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const beforeUpload = (file: RcFile) => {
    const isPdf15M = file.size / 1024 / 1024 > MAX_SIZE_PDF_MB;
    if (!!isPdf15M) {
      const errorMessage = t('DOCUMENT_MANAGEMENT.document_max_size', {
        name: file.name,
        size: '10MB',
      });
      message.error(t(errorMessage), 5);
      return Upload.LIST_IGNORE;
    }
    return file;
  };

  return (
    <Form<DocumentManagementFormValues> form={form} layout='vertical' onFinish={onFinish} {...props}>
      <Form.Item
        name='files'
        valuePropName='fileList'
        getValueFromEvent={normFile}
        noStyle
        rules={[{ required: true, message: t('field_required') }]}
      >
        <UploadDragger accept='.pdf' name='file' customRequest={dummyRequest} multiple beforeUpload={beforeUpload}>
          <p className='ant-upload-drag-icon'>
            <FilePdfTwoTone twoToneColor={COLORS.corporate} />
          </p>
          <h3>{t('DOCUMENT_MANAGEMENT.document_title')}</h3>
        </UploadDragger>
      </Form.Item>
    </Form>
  );
};

export default DocumentManagementFrom;
