import TabEmpty from 'components/molecules/TabEmpty';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface EventsAndNewsContentEmptyTemplateProps {
  onVisibleCreate: () => void;
}

enum TRANSLATION {
  title = '_EVENTS_AND_NEWS_EMPTY_TITLE',
  text = '_EVENTS_AND_NEWS_EMPTY_TEXT',
  add = '_EVENTS_AND_NEWS_ADD_SPACE',
}

const EventsAndNewsContentEmptyTemplate: FC<EventsAndNewsContentEmptyTemplateProps> = ({
  onVisibleCreate
}) => {
  const { t } = useTranslation();

  return (
    <div className={'EventsAndNewsContentEmptyTemplate__container'}>
      <div className={'EventsAndNewsContentEmptyTemplate__empty'}>
        <TabEmpty
          title={t(TRANSLATION.title)}
          text={t(TRANSLATION.text)}
          linkText={t(TRANSLATION.add)}
          onClick={onVisibleCreate}
        />
      </div>
    </div>
  );
};

export default EventsAndNewsContentEmptyTemplate;
