import { useTranslation } from 'react-i18next';
import { Spin, Table, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import './styles.scss';

const MAX_SIZE_RESPONSIVE_SCREEN = 600;

interface BlockProps<T> {
  loading: boolean;
  title: string;
  dataSource: T[];
  columns: ColumnsType<T>;
  onRowClick?: (data: T) => void;
}

const Block = <T extends {}>({ loading, title, dataSource, columns, onRowClick }: BlockProps<T>) => {
  const { t } = useTranslation('global');

  return (
    <div>
      <Space className='Block__title' size='small'>
        <Typography.Title level={4}>{`${title}:`}</Typography.Title>
        {loading ? (
          <Spin />
        ) : (
          <>
            {dataSource?.length ? (
              <p>{`${dataSource.length} ${t('SUPER_SEARCH.there_are_results')}`}</p>
            ) : (
              <p className='Text__gray'>{t('no_results')}</p>
            )}
          </>
        )}
      </Space>

      <Table<T>
        loading={loading}
        className='Block__table'
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        rowKey='id'
        onRow={(item) => {
          return {
            onClick: () => onRowClick && onRowClick(item),
          };
        }}
        sticky
      />
    </div>
  );
};

export default Block;
