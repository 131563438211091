import { Thresholds } from '../model/thresholds';
import { ThresholdsUnparsed } from '../model/thresholds/thresholdsUnparsed';

export const payloadToThresholds = ({
  time_offline,
  cpu_temperature,
  cpu_ram_used,
  cpu_hdd_used,
  cpu_load,
  used_inodes,
  used_swap,
}: ThresholdsUnparsed): Thresholds => {
  return {
    timeOffline: time_offline ?? 0,
    cpuTemperature: cpu_temperature ?? 0,
    cpuRamUsed: cpu_ram_used ?? 0,
    cpuHddUsed: cpu_hdd_used ?? 0,
    cpuLoad: cpu_load ?? 0,
    usedInodes: used_inodes ?? 0,
    usedSwap: used_swap ?? 0,
  };
};
