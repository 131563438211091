import api from 'services/api';
import {
  createLegalDocumentationPath,
  deleteLegalDocumentationPath,
  getLegalDocumentationDetailByIdPath,
  getLegalDocumentationListByClientIdPath,
} from 'core/endspoints/clients';
import { LegalDocumentationCreateRequestModel, LegalDocumentationModel } from '../models/clientEntityModel';
import { LegalDocumentationPayloadModel } from '../models/clientPayloadModel';
import { payloadToLegalDocumentation } from '../mappers/payloadToLegalDocumentation';
import LegalDocumentationListValueObject from '../entities/legalDocumentationListValueObject';
import LegalDocumentationEntity from '../entities/legalDocumentationEntity';
import { legalDocumentationToPayload } from '../mappers/legalDocumentationToPayload';

export const GetLegalDocumentationListByClientId = async (clientId: string): Promise<LegalDocumentationModel[] | undefined> => {
  api.defaults.headers.common['Accept-version'] = '1';
  const url = getLegalDocumentationListByClientIdPath(clientId);

  try {
    const { data } = await api.get<LegalDocumentationPayloadModel[]>(url);
    const mappedData = data.map((item) => payloadToLegalDocumentation(item));
    const { list } = LegalDocumentationListValueObject(mappedData);
    return list;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const GetLegalDocumentationDetailById = async (
  clientId: string,
  legalDocumentationId: string
): Promise<LegalDocumentationModel | undefined> => {
  api.defaults.headers.common['Accept-version'] = '1';
  const url = getLegalDocumentationDetailByIdPath(clientId, legalDocumentationId);

  try {
    const { data } = await api.get<LegalDocumentationPayloadModel>(url);
    const mappedData = payloadToLegalDocumentation(data);
    const legalDocumentation = LegalDocumentationEntity(mappedData);
    return legalDocumentation;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const CreateLegalDocumentation = async ({
  clientId,
  legalDocumentation,
}: LegalDocumentationCreateRequestModel): Promise<void | undefined> => {
  api.defaults.headers.common['Accept-version'] = '1';
  const url = createLegalDocumentationPath(clientId);

  try {
    const data = legalDocumentationToPayload(legalDocumentation);
    return await api.post(url, { data });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const DeleteLegalDocumentation = async ({
  clientId,
  legalDocumentation,
}: LegalDocumentationCreateRequestModel): Promise<void | undefined> => {
  api.defaults.headers.common['Accept-version'] = '1';
  const url = deleteLegalDocumentationPath(clientId);

  try {
    const data = legalDocumentationToPayload(legalDocumentation);
    return await api.post(url, { data });
  } catch (error: any) {
    throw new Error(error);
  }
};
