import { useState } from 'react';
import { hasPermission } from 'services/permissions';

export const useProjectNotification = () => {
  const [isNotificationModalVisible, setIsNotificationModalVisible] = useState<boolean>(false);
  const hasNotificationPermission = hasPermission('send_community_notifications');

  const onOpenNotificationModal = () => setIsNotificationModalVisible(true);
  const onCloseNotificationModal = () => setIsNotificationModalVisible(false);

  return {
    onOpenNotificationModal,
    onCloseNotificationModal,
    notificationModalVisible: isNotificationModalVisible,
    hasNotificationPermission,
  };
};
