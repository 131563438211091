import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectListResponseDataModel, ProjectModel } from 'core/domain/project/model/projectModel';
import { GetProjectsListByClientId } from 'core/domain/project/repositories/projectsCRUD';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { GetClientsList } from 'core/domain/client/repositories/getClientsList';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { MessageErrorModel, useMessage } from 'hooks/useMessage';
import { AssignGatewayToProjectModalTranslations } from 'components/organisms/AssignGatewayToProjectModal';
import { AssignGatewayToProjectModalColumns } from 'components/organisms/AssignGatewayToProjectModal/AssignGatewayToProjectModalColumns';
import { getProjects } from 'services/api/projects';
import { UpdateGatewayDataModel } from 'core/domain/gateways/model';
import { UpdateGatewayDetail } from 'core/domain/gateways/repositories/updateGatewayDetail';
import {
  AssignToAssetModalClientsOptions,
  AssignToProjectModalOptions,
  AssignToProjectModalProjectsOptions,
  ClientCustomData,
  CustomGatewaysListDataModel,
  ProjectCustomData,
} from './utils';

const MINIMUM_SEARCH_VALUE_CHARACTERS = 3;
const ERROR_MESSAGE_DURATION = 8;

export const useAssignToProject = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [selectedGateway, setSelectedGateway] = useState<CustomGatewaysListDataModel>();
  const [errorSearching, setErrorSearching] = useState<boolean>(false);
  const [errorAssigning, setErrorAssigning] = useState<boolean>(false);
  const [isAssignProjectModalVisible, setIsAssignProjectModalVisible] = useState<boolean>(false);
  const [searchTableVisible, setSearchTableVisible] = useState<boolean>(false);
  const [searchingVisible, setSearchingVisible] = useState<boolean>(true);
  const [manualSearchingVisible, setManualSearchingVisible] = useState<boolean>(false);
  const [loadingClients, setLoadingClients] = useState<boolean>(false);
  const [clients, setClients] = useState<ClientCustomData[]>([]);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);
  const [loadingManualProjects, setLoadingManualProjects] = useState<boolean>(false);
  const [projects, setProjects] = useState<ProjectCustomData[]>([]);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [successAssigningGatewayToProject, setSuccessAssigningGatewayToProject] = useState<boolean>(false);
  const [assigning, setAssigning] = useState<boolean>(false);

  const translations: AssignGatewayToProjectModalTranslations = {
    title: t('_GATEWAYS_LIST_ASSIGN_TO_PROJECT'),
    mainButtonText: t('assign'),
    secondaryButtonText: t('to_cancel'),
    searchText: t('_GATEWAY_LIST_PROJECTS_SEARCH_TEXT'),
    searchPlaceholderText: t('search_with_ellipsis'),
    errorSearchingMessage: t('_GATEWAYS_LIST_ERROR_SEARCHING_PROJECT_LENGTH'),
    errorProjectNotSelectedMessage: t('_GATEWAYS_LIST_ERROR_PROJECT_NOT_SELECTED'),
    manualSearchButtonText: t('_GATEWAYS_LIST_ASSIGN_PROJECT_MODAL_MANUAL_SEARCH_TEXT'),
    clientPlaceholderText: t('_GATEWAYS_LIST_ASSIGN_PROJECT_MODAL_CLIENT_PLACEHOLDER'),
    projectPlaceholderText: t('_GATEWAYS_LIST_ASSIGN_PROJECT_MODAL_PROJECT_PLACEHOLDER'),
  };

  const assignGatewayToProjectModalColumns = AssignGatewayToProjectModalColumns();

  const onOpenAssignToProjectModal = (gateway: CustomGatewaysListDataModel) => {
    setIsAssignProjectModalVisible(true);
    setSelectedGateway(gateway);
  };

  const resetValues = () => {
    setErrorSearching(false);
    setSearchTableVisible(false);
    setManualSearchingVisible(false);
    setSelectedClient(null);
    setSelectedProject(null);
    setSelectedGateway(undefined);
    setSearchingVisible(true);
    setClients([]);
    setProjects([]);
    setIsAssignProjectModalVisible(false);
    setSuccessAssigningGatewayToProject(false);
    setErrorAssigning(false);
  };

  const onCloseAssignGatewayToProjectModal = () => {
    resetValues();
  };

  const transformProjectsListData = (assets: ProjectModel[]): ProjectCustomData[] => {
    return assets.map(({ id, name }) => ({ value: id, label: name }));
  };

  const setProjectsList = (projects: ProjectModel[]) => {
    const transformedAssets = transformProjectsListData(projects);
    setProjects(transformedAssets);
  };

  const getProjectsList = async (searchValue?: string) => {
    setLoadingProjects(true);
    try {
      const projectsResponse = await getProjects({ search: searchValue });
      !!projectsResponse ? setProjectsList(projectsResponse.data) : setMessageError({ description: t('') });
    } catch (error) {
      console.warn(error);
    } finally {
      setLoadingProjects(false);
    }
  };

  const transformClientsListData = (clients: ClientModel[]): ClientCustomData[] => {
    return clients.map(({ id, name }) => ({ value: id, label: name }));
  };

  const getClientsList = async () => {
    setLoadingClients(true);
    try {
      const clients = await GetClientsList();
      if (!!clients?.length) {
        const transformedClients = transformClientsListData(clients);
        setClients(transformedClients);
        transformedClients.length === 1 && setSelectedClient(transformedClients[0].value);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setLoadingClients(false);
    }
  };

  const transformManualProjectsListData = (projectsList: ProjectListResponseDataModel[]): ProjectCustomData[] => {
    return projectsList.map(({ id, name }) => ({ value: id, label: name }));
  };

  const getProjectsListByClientId = async (clientId: string) => {
    setLoadingManualProjects(true);
    try {
      const response = await GetProjectsListByClientId(clientId);
      if (!!response?.data?.length) {
        const transformedProjects = transformManualProjectsListData(response.data);
        setProjects(transformedProjects);
        response.data.length === 1 && setSelectedProject(response.data[0].id);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setLoadingManualProjects(false);
    }
  };

  const onSearchProjects = (value: string) => {
    getProjectsList(value);
    setErrorSearching(false);
    setSearchTableVisible(true);
    setManualSearchingVisible(false);
    setSelectedClient(null);
    setSelectedProject(null);
    setClients([]);
    setProjects([]);
  };

  const onErrorSearchProjects = () => {
    setProjects([]);
    setErrorSearching(true);
    setSearchTableVisible(false);
  };

  const onValidateSearchProjects = (value: string) => {
    value.length >= MINIMUM_SEARCH_VALUE_CHARACTERS ? onSearchProjects(value) : onErrorSearchProjects();
  };

  const onSelectProjectToBeLinked = (projectId: string) => {
    setSelectedProject(projectId);
    setErrorAssigning(false);
  };

  const onClickAssignGatewayToProjectModaSearch = () => {
    setManualSearchingVisible(false);
    setSearchingVisible(true);
    setSelectedClient(null);
    setClients([]);
    setSelectedProject(null);
    setProjects([]);
  };

  const onClickAssignGatewayToProjectModalManualSearch = () => {
    setManualSearchingVisible(true);
    setSearchingVisible(false);
    setSearchTableVisible(false);
    setErrorSearching(false);
    setSelectedProject(null);
    setProjects([]);
    getClientsList();
  };

  const onSelectClient = (value: string) => {
    setSelectedClient(value);
    setSelectedProject(null);
  };

  const checkErrors = (error: MessageErrorModel) => {
    const description =
      error.code === 409 ? t('_GATEWAYS_LIST_ASSIGN_ASSET_MODAL_ERROR_409_MESSAGE') : t('_GATEWAYS_LIST_ASSIGN_ASSET_MODAL_ERROR_MESSAGE');
    setMessageError({ description }, ERROR_MESSAGE_DURATION);
  };

  const onAssignGatewayToProject = async () => {
    setAssigning(true);
    try {
      if (!!selectedGateway && !!selectedProject) {
        const gateway: UpdateGatewayDataModel = { projectId: selectedProject };
        await UpdateGatewayDetail({ host, token, gatewayId: selectedGateway.id, gateway });
        setSuccessAssigningGatewayToProject(true);
        setMessageSuccess({ description: t('_GATEWAYS_LIST_ASSIGN_ASSET_MODAL_SUCCESS_MESSAGE') });
        resetValues();
      }
      !selectedProject && setErrorAssigning(true);
    } catch (error) {
      checkErrors(error as MessageErrorModel);
    } finally {
      setAssigning(false);
    }
  };

  useEffect(() => {
    !!selectedClient && getProjectsListByClientId(selectedClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  const projectsOptions: AssignToProjectModalProjectsOptions = {
    projects,
    selectedProject,
    assignGatewayToProjectModalColumns,
    onSelectProjectToBeLinked,
    onValidateSearchProjects,
    loadingProjects,
    loadingManualProjects,
  };

  const clientsOptions: AssignToAssetModalClientsOptions = {
    clients,
    selectedClient,
    onSelectClient,
    loadingClients,
  };

  const assignToProjectModalOptions: AssignToProjectModalOptions = {
    assignGatewayToProjectModalTranslations: translations,
    onCloseAssignGatewayToProjectModal,
    onClickAssignGatewayToProjectModaSearch,
    onClickAssignGatewayToProjectModalManualSearch,
    onAssignGatewayToProject,
    isAssignProjectModalVisible,
    errorSearching,
    errorAssigning,
    searchTableVisible,
    searchingVisible,
    manualSearchingVisible,
  };

  return {
    clientsOptions,
    projectsOptions,
    assignToProjectModalOptions,
    onOpenAssignToProjectModal,
    successAssigningGatewayToProject,
    assigningToProject: assigning,
  };
};
