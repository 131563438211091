import { FC } from 'react';
import DaySelector from './DaySelector';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const day: { [key: string]: number } = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

const WEEK = [day.MONDAY, day.TUESDAY, day.WEDNESDAY, day.THURSDAY, day.FRIDAY, day.SATURDAY, day.SUNDAY];

interface WeekDaysSelectorProps {
  days?: number[];
  onSelectDay: (day: number) => void;
  onUnselectDay: (day: number) => void;
  disabled?: boolean;
}

const WeekDaysSelector: FC<WeekDaysSelectorProps> = ({ days = [], onSelectDay, onUnselectDay, disabled = false }) => {
  const { t } = useTranslation();
  const activeWeekDays = WEEK.map((item) => days.some((day) => day === item));
  const [Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday] = activeWeekDays;

  const handleOnClick = (day: number): void => {
    const isAlreadySelected = days.some((item) => item === day);
    isAlreadySelected ? onUnselectDay(day) : onSelectDay(day);
  };

  return (
    <div className={'WeekRadioButtons__container'}>
      <DaySelector
        name={t('_COMMON_AREA_WEEK_DAY_MONDAY')}
        actived={Monday}
        onClick={() => handleOnClick(day.MONDAY)}
        disabled={disabled}
      />
      <DaySelector
        name={t('_COMMON_AREA_WEEK_DAY_TUESDAY')}
        actived={Tuesday}
        onClick={() => handleOnClick(day.TUESDAY)}
        disabled={disabled}
      />
      <DaySelector
        name={t('_COMMON_AREA_WEEK_DAY_WEDNESDAY')}
        actived={Wednesday}
        onClick={() => handleOnClick(day.WEDNESDAY)}
        disabled={disabled}
      />
      <DaySelector
        name={t('_COMMON_AREA_WEEK_DAY_THURSDAY')}
        actived={Thursday}
        onClick={() => handleOnClick(day.THURSDAY)}
        disabled={disabled}
      />
      <DaySelector
        name={t('_COMMON_AREA_WEEK_DAY_FRIDAY')}
        actived={Friday}
        onClick={() => handleOnClick(day.FRIDAY)}
        disabled={disabled}
      />
      <DaySelector
        name={t('_COMMON_AREA_WEEK_DAY_SATURDAY')}
        actived={Saturday}
        onClick={() => handleOnClick(day.SATURDAY)}
        disabled={disabled}
      />
      <DaySelector
        name={t('_COMMON_AREA_WEEK_DAY_SUNDAY')}
        actived={Sunday}
        onClick={() => handleOnClick(day.SUNDAY)}
        disabled={disabled}
      />
    </div>
  );
};

export default WeekDaysSelector;
