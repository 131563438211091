import { FC } from 'react';
import { FormInstance } from 'antd';
import { RcFile } from 'antd/lib/upload';
import {
  CustomLegalDocumentationModel,
  LegalDocumentationActionsNames,
  LegalDocumentationContentTranslationsModel,
  LegalDocumentationEmptyTranslationsType,
  LegalDocumentationFormChangesModel,
  LegalDocumentationFormValues,
  LegalDocumentationStateType,
} from './config';
import LegalDocumentationEmptyTemplate from 'components/template/LegalDocumentationEmptyTemplate';
import LegalDocumentationContentTemplate from 'components/template/LegalDocumentationContentTemplate';
import LegalDocumentationDisabledContentTemplate from 'components/template/LegalDocumentationDisabledContentTemplate';

interface Props {
  form: FormInstance<LegalDocumentationFormValues>;
  acceptedFormatFiles: string;
  templateVisorStatus: LegalDocumentationStateType;
  emptyTranslations: LegalDocumentationEmptyTranslationsType;
  contentTranslations: LegalDocumentationContentTranslationsModel;
  legalDocumentation: CustomLegalDocumentationModel
  onCreate: () => void;
  onEnable: () => void;
  onDisable: () => void;
  onSaveForm: () => void;
  onCloseForm: () => void;
  onDeleteForm: () => void;
  onChangeValueForm: ({ label, file }: LegalDocumentationFormChangesModel) => void;
  onChangeActionForm: (label: LegalDocumentationActionsNames) => void;
  onCheckBeforeUploadValueForm: (file: RcFile) => void;
  enabledSwitch: boolean;
  processing: boolean;
  termsOfServiceUploadButtonDisabled: boolean;
  termsOfServiceUploadInfoVisible: boolean;
  privacyPolicyUploadButtonDisabled: boolean;
  privacyPolicyUploadInfoVisible: boolean;
  saveButtonDisabled: boolean;
  deleteButtonVisible: boolean;
  isDefault: boolean;
}

const LegalDocumentationTemplateManager = {
  [LegalDocumentationStateType.EMPTY]: LegalDocumentationEmptyTemplate,
  [LegalDocumentationStateType.CONTENT]: LegalDocumentationContentTemplate,
  [LegalDocumentationStateType.DISABLED_CONTENT]: LegalDocumentationDisabledContentTemplate,
};

const LegalDocumentationManager: FC<Props> = ({ templateVisorStatus, ...props }) => {
  const Component = LegalDocumentationTemplateManager[templateVisorStatus];

  return <Component {...props} />
};

export default LegalDocumentationManager;
