import { useContext, useEffect, useState } from 'react';
import { Form } from 'antd';
import UserProfileContext, { UserProfileContextState } from 'context/UserProfileContext';
import { UserRole, UserRoleType } from 'models/users.model';
import { downloadCsvFile } from 'utils/csv';
import { getDownloadAssetsTemplate } from 'core/domain/users/repositories/getDownloadAssetsTemplate';
import { GetClientsList } from 'core/domain/client/repositories/getClientsList';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { useTranslation } from 'react-i18next';

export interface ClientSelectorFormValues {
  clientId: string;
}

export const useUsersAssets = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ClientSelectorFormValues>();
  const { userProfile } = useContext<UserProfileContextState>(UserProfileContext);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingClientsList, setIsLoadingClientsList] = useState<boolean>(false);
  const [clientsList, setClientsList] = useState<ClientModel[]>([]);

  const isSuperAdminRole = userProfile?.roles.includes(UserRoleType.ROLE_ASSETS_MANAGER_SUPER_ADMIN as UserRole);
  const isPropertyManagerRole = userProfile?.roles.includes(UserRoleType.ROLE_ASSETS_MANAGER_ADMIN as UserRole);

  const translations = {
    clientSelectorModalTitle: t('_USERS_CLIENTS_SELECTOR_MODAL_TITLE'),
    clientSelectorModalMainButtonText: t('_USERS_CLIENTS_SELECTOR_MODAL_MAIN_BUTTON_TEXT'),
    clientSelectorModalSecondaryButtonText: t('_USERS_CLIENTS_SELECTOR_MODAL_SECONDARY_BUTTON_TEXT'),
    clientSelectorModalPlaceholderText: t('_USERS_CLIENTS_SELECTOR_MODAL_PLACEHOLDER_TEXT'),
    clientSelectorModalRequiredMessage: t('field_required'),
  };

  const downloadAssetsTemplateCsv = async (clientId: string) => {
    setIsLoading(true);
    try {
      const csvData = await getDownloadAssetsTemplate(clientId);
      const csvFileName = 'plantilla_listado_activos.csv';
      !!csvData && downloadCsvFile(csvData, csvFileName);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDownloadAssetsTemplateCsv = () => {
    !!isSuperAdminRole && setIsModalVisible(true);
    !isSuperAdminRole && !!isPropertyManagerRole && userProfile && userProfile.client && downloadAssetsTemplateCsv(userProfile.client.id);
  };

  const getSuperAdminClientsList = async () => {
    setIsLoadingClientsList(true);
    try {
      const clientsList = await GetClientsList();
      !!clientsList && setClientsList(clientsList);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingClientsList(false);
    }
  };

  const onSelectClient = async (values: ClientSelectorFormValues) => {
    const { clientId } = values;
    try {
      await downloadAssetsTemplateCsv(clientId);
      setIsModalVisible(false);
    } catch (error) {
      console.warn(error);
    }
  };

  const onCloseClientsSelectorModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    !!isModalVisible && getSuperAdminClientsList();
  }, [isModalVisible]);

  return {
    clientsList,
    clientsSelectorModalTranslations: translations,
    clientSelectorForm: form,
    onDownloadAssetsTemplateCsv,
    onSelectClient,
    onCloseClientsSelectorModal,
    clientsSelectorModalVisible: isModalVisible,
    loadingAssetsTemplateDownload: isLoading,
    loadingClientsList: isLoadingClientsList,
  };
};
