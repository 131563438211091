import { FC } from 'react';
import CommonAreasAndBookingsListEmptyTemplate from 'components/template/CommonAreasAndBookingsListEmptyTemplate';
import BookingDeleteConfirmModal from 'components/molecules/BookingDeleteModal';
import { useBookingsList } from './resources/useBookingsList';
import CommonAreasAndBookingsListNewLoadingTemplate from 'components/template/CommonAreasAndBookingsNewLoadingTemplate';
import CommonAreasBookingsListTemplate from 'components/template/CommonAreasBookingsListTemplate';

const CommonAreasBookingsListPage: FC = () => {
  const {
    bookingsList,
    emptyTemplateTranslations,
    confirmDeleteBookingModalTranslations,
    onDeleteBooking,
    onCloseConfirmDeleteBookingModal,
    onConfirmDeleteBooking,
    onCreateCommonArea,
    bookingsListEmpty,
    confirmDeleteBookingModalVisible,
    deletingBooking,
    loadingBookingsList,
  } = useBookingsList();
  const { emptyMessageTitle, emptyMessageText, emptyMessageLinkText } = emptyTemplateTranslations;
  const { confirmModalTitle, confirmModalText, confirmModalMainButtonText, confirmModalSecondaryButtonText } = confirmDeleteBookingModalTranslations;

  return (
    <>
      {loadingBookingsList && (
        <CommonAreasAndBookingsListNewLoadingTemplate />
      )}
      {!loadingBookingsList && bookingsListEmpty && (
        <CommonAreasAndBookingsListEmptyTemplate
          emptyMessageTitle={emptyMessageTitle}
          emptyMessageText={emptyMessageText}
          emptyMessageLinkText={emptyMessageLinkText}
          onCreateCommonArea={onCreateCommonArea}
        />
      )}
      {!loadingBookingsList && !bookingsListEmpty && (
        <CommonAreasBookingsListTemplate
          bookingsList={bookingsList}
          onDeleteBooking={onDeleteBooking}
        />
      )}
      <BookingDeleteConfirmModal
        title={confirmModalTitle}
        text={confirmModalText}
        mainButtonText={confirmModalMainButtonText}
        secondaryButtonText={confirmModalSecondaryButtonText}
        onCancel={onCloseConfirmDeleteBookingModal}
        onConfirm={onConfirmDeleteBooking}
        loading={deletingBooking}
        visible={confirmDeleteBookingModalVisible}
      />
    </>
  )
}

export default CommonAreasBookingsListPage;