import { CommonAreaTimeAvailabilityModel } from '../models/commonAreaEntityModels';
import { PayloadCommonAreaTimeAvailabilityModel } from '../models/payloadCommonAreaModels';

export const commonAreaTimeAvailabilityToPayload = ({
  start,
  end,
}: CommonAreaTimeAvailabilityModel): PayloadCommonAreaTimeAvailabilityModel => {
  return {
    time_availability_start: start,
    time_availability_end: end,
  };
};
