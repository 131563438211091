import api from 'services/api';
import { commonAreasCreatePath } from 'core/endspoints/commonAreas';
import { createCommonAreaToPayload } from '../mappers/createCommonAreaToPayload';
import { CreateCommonAreaModel } from '../models/commonAreaEntityModels';
interface Response {
  id: string;
  name: string;
}

export const CreateCommonArea = async (projectId: string, values: CreateCommonAreaModel): Promise<Response | undefined> => {
  try {
    const path = commonAreasCreatePath(projectId);
    const data = createCommonAreaToPayload(values);
    api.defaults.headers.common['Accept-version'] = '1';
    const response: Response = await api.post(path, { data });
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};
