import { FC } from 'react';
import { ProjectAppCustomizationFormValues } from 'components/pages/ProjectPage/Tabs/TabCustomization/SubTabs/SubTabAppAndWebApp/resources/models';
import './styles.scss';

interface Props {
  previewValues: ProjectAppCustomizationFormValues;
}

const ProjectAppCustomizationPreView: FC<Props> = ({ previewValues }) => {
  const { backgroundImage } = previewValues;

  return (
    <div className={'AppPreview__container'}>
      <div className={'AppPreview__header'}>
        <div>
          <div className={'AppPreview__icon'} />
        </div>
        <div className={'AppPreview__header-right'}>
          <div className={'AppPreview__icon'} />
          <div className={'AppPreview__icon'} />
        </div>
      </div>
      <div className={'AppPreview__body'}>
        <div className={'AppPreview__body-image'}>
          <img src={backgroundImage} alt={''} />
        </div>
        <div className={'AppPreview__body-info'}>
          <div />
        </div>
      </div>
    </div>
  );
}

export default ProjectAppCustomizationPreView;