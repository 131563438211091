import { FC } from 'react';
import { Skeleton } from 'antd';
import './styles.scss';

const DashboardOccupationLoadingTemplate: FC = () => {
  return (
    <div className='OccupationLoading__container'>
      <div className={'OccupationLoading__title'}>
        <Skeleton.Input className={'OccupationLoading__title-skeleton'} active />
      </div>
      <div className='OccupationLoading__content'>
        <Skeleton.Input className={'OccupationLoading__content-skeleton'} active />
      </div>
    </div>
  );
}

export default DashboardOccupationLoadingTemplate;