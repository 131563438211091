import { FC } from 'react';
import { Input, Pagination } from 'antd';
import Paper from '../../../atoms/Paper';
import TableAtom from 'components/atoms/TableAtom';
import { useClientProjectsList } from './resources/useClientProjectsList';
import { ProjectListResponseDataModel } from 'core/domain/project/model/projectModel';
import { TABLE_PAGE_SIZE_DEFAULT_OPTIONS, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import './styles.scss';

const MAX_SIZE_RESPONSIVE_SCREEN = 600;

const TabProjectsList: FC = () => {
  const {
    projects,
    projectsColumns,
    translations,
    currentPage,
    pageSize,
    totalPages,
    onChangePage,
    onSearch,
    onSelectProject,
    loadingProjects,
  } = useClientProjectsList();

  return (
    <Paper>
      <div className='TabProjects__search'>
        <Input.Search autoFocus allowClear placeholder={translations.searchPlaceholder} onSearch={onSearch} />
      </div>
      <TableAtom<ProjectListResponseDataModel>
        loading={loadingProjects}
        columns={projectsColumns}
        dataSource={projects}
        rowKey={'projectId'}
        pagination={false}
        scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        onRow={(item) => {
          return {
            onClick: () => onSelectProject(item),
          };
        }}
      />
      <Pagination
        className={'TabProjects__pagination'}
        size={'small'}
        showSizeChanger={true}
        defaultPageSize={Number(TABLE_PAGINATION_SIZE_DEFAULT)}
        pageSizeOptions={TABLE_PAGE_SIZE_DEFAULT_OPTIONS}
        current={currentPage}
        total={pageSize * totalPages}
        pageSize={pageSize}
        onChange={onChangePage}
      />

    </Paper>
  );
};

export default TabProjectsList;
