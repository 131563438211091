import { useTranslation } from 'react-i18next';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { Asset } from 'models/assets.model';
import RevokeAction from 'components/molecules/RevokeAction';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constants/date';

interface AssetsTabColumnsOptions {
  onRevoke: (userId: string, assetId: string) => Promise<void>;
  revokeAssetAction: () => void;
  userId: string;
}

export const AssetsTabColumns = ({ onRevoke, revokeAssetAction, userId }: AssetsTabColumnsOptions): AlfredTableColumn<Asset>[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('address'),
      key: 'address',
      render: (text, record: Asset) => {
        return (
          <>
            <div>
              <b>{`${record.street_type} ${record.street_name} ${record.block_number}, ${record.apartment}, ${record.zip_code}, ${record.city}, ${record.state}, ${record.country}`}</b>
            </div>
            {record.created_at && (
              <span className='Text__date Text__secondary'>{moment.unix(Number(record.created_at)).format(DATE_TIME_FORMAT)}</span>
            )}
          </>
        );
      },
    },
    {
      title: t('alias'),
      key: 'alias',
      width: 150,
      render: (text, record: Asset) =>
        record.alias ? <div>{record.alias}</div> : <span className='TabAssets__disabled'>{t('without_alias')}</span>,
    },
    {
      title: t('actions'),
      key: 'action',
      width: 150,
      render: (text, record) => (
        <RevokeAction
          revokeId={record.id}
          id={userId}
          onSuccess={revokeAssetAction}
          popConfirmText={t('revoke_asset_confirm')}
          onRevoke={(revokeId: string, id?: string | undefined) => onRevoke(revokeId, id as string)}
          messageInfo={t('unlinked_correctly')}
          buttonText={t('unlink')}
        />
      ),
    },
  ];
};
