import { FC } from 'react';
import { Button, DatePicker, Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from 'constants/date';
import ModalAtom from 'components/atoms/ModalAtom';
import 'moment/min/locales';
import './styles.scss';

export enum SupportTicketsListDownloadModalFormValues {
  FROM_DATE = 'fromDate',
  UNTIL_DATE = 'untilDate',
}

export interface SupportTicketsListDownloadModalFormValuesModel {
  [SupportTicketsListDownloadModalFormValues.FROM_DATE]: Moment;
  [SupportTicketsListDownloadModalFormValues.UNTIL_DATE]: Moment;
}

export interface SupportTicketsListDownloadModalLiteralModel {
  titleText: string;
  descriptionText: string;
  cancelButtonText: string;
  confirmButtonText: string;
  fromDateLabelText: string;
  untilDateLabelText: string;
  datePickerPlaceholderText: string;
  requireFieldText: string;
}

interface Props extends SupportTicketsListDownloadModalLiteralModel {
  form: FormInstance;
  onCancel: () => void;
  onFinish: (data: SupportTicketsListDownloadModalFormValuesModel) => void;
  getIsDisabledFromDate: (current: moment.Moment) => boolean;
  getIsDisabledUntilDate: (current: moment.Moment) => boolean;
  isLoading: boolean;
  isVisible: boolean;
}
const FORM_ID = 'supportTicketsListDownloadForm';

const SupportTicketsListDownloadModal: FC<Props> = ({
  form,
  titleText,
  cancelButtonText,
  confirmButtonText,
  fromDateLabelText,
  untilDateLabelText,
  descriptionText,
  datePickerPlaceholderText,
  requireFieldText,
  onCancel,
  onFinish,
  getIsDisabledFromDate,
  getIsDisabledUntilDate,
  isLoading,
  isVisible,
}) => {
  return (
    <ModalAtom
      className={'SupportTicketsListDownloadModal'}
      title={titleText}
      destroyOnClose
      onCancel={onCancel}
      visible={isVisible}
      footer={[
        <Button
          key={'back'}
          onClick={onCancel}
        >
          {cancelButtonText}
        </Button>,
        <Button
          type={'primary'}
          key={'submit'}
          loading={isLoading}
          htmlType={'submit'}
          form={FORM_ID}
        >
          {confirmButtonText}
        </Button>,
      ]}
    >
      <p>{descriptionText}:</p>
      <Form
        id={FORM_ID}
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
      >
        <div className='SupportTicketsListDownloadModal__form-wrapper-row'>
          <div className={'SupportTicketsListDownloadModal__form-wrapper-column'}>
            <Form.Item
              name={SupportTicketsListDownloadModalFormValues.FROM_DATE}
              label={`${fromDateLabelText}:`}
              rules={[{ required: true, message: requireFieldText }]}
            >
              <DatePicker
                disabledDate={getIsDisabledFromDate}
                format={DATE_FORMAT}
                placeholder={datePickerPlaceholderText}
              />
            </Form.Item>
          </div>
          <div className={'SupportTicketsListDownloadModal__form-wrapper-column'}>
            <Form.Item
              name={SupportTicketsListDownloadModalFormValues.UNTIL_DATE}
              label={`${untilDateLabelText}:`}
              rules={[{ required: true, message: requireFieldText }]}
            >
              <DatePicker
                disabledDate={getIsDisabledUntilDate}
                format={DATE_FORMAT}
                placeholder={datePickerPlaceholderText}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </ModalAtom>
  );
};
export default SupportTicketsListDownloadModal;