import { CameraFormValues } from 'components/organisms/AddCameraAction';
import { camerasCreatePath } from 'core/endspoints/assets';
import api from 'services/api';
import { cameraFormValuesToPayload } from '../mappers/cameraFormValuesToPayload';

export const CreateCamera = async (assetId: string, data: CameraFormValues) => {
  const path = camerasCreatePath(assetId);
  const dataUparser = cameraFormValuesToPayload(data);
  return await api.post(path, { data: dataUparser });
};
