import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  CaptionRegularText,
  LabelRegularText,
  Picture,
  PrimarySmallLeftIconOutlinedButton,
  PrimarySmallTextOutlinedButton,
  SpinnerIcon,
} from "@alfred_developes/alfred-ui-web-components";
import PopconfirmAtom from "components/atoms/PopconfirmAtom";
import { ProjectLayoutConfigAreaModel, VerticalArea } from "core/domain/projectLayoutConfig/model/projectLayoutConfigModels";
import { getAreaNamesByBrowserLanguage } from "components/pages/ProjectPage/utils";
import './styles.scss';

interface Props {
  area: ProjectLayoutConfigAreaModel;
  tableData: ProjectLayoutConfigAreaModel[];
  deletingRowId: string;
  onClickEdit: (area: VerticalArea) => void;
  onClickDelete: (id: string) => void;
  onConfirm: (id: string) => void;
}

export const TableHeading: FC<Props> = ({
  area,
  tableData,
  deletingRowId,
  onClickEdit,
  onClickDelete,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const { nameEn, nameEs } = area;
  const { mainNameText, secondaryNameText } = getAreaNamesByBrowserLanguage({ nameEn, nameEs });

  return (
    <div className={'TableHeading__wrapper'}>
      <div className={'TableHeading__info-area'}
      >
        <div>
          <Picture
            height={70}
            imageURL={area.imageURL}
            style={{ objectFit: 'cover', borderRadius: 6, width: 120 }}
          />
        </div>
        <div>
          <LabelRegularText>{mainNameText}</LabelRegularText>
          <CaptionRegularText>{secondaryNameText}</CaptionRegularText>
        </div>
      </div>
      <div className={'TableHeading__actions-area'}>
        <PrimarySmallTextOutlinedButton
          text={t('_PROJECT_LAYOUT_CONFIG_ACTION_BUTTON_EDIT')}
          onClick={(event) => {
            event.stopPropagation();
            const selectedRow = tableData.find(row => row.id === area.id);
            selectedRow && onClickEdit(area as VerticalArea);
          }}
          disabled={deletingRowId === area.id}
        />
        {deletingRowId !== area.id &&
          <PopconfirmAtom
            overlayInnerStyle={{ borderRadius: '8px' }}
            placement="topRight"
            okButtonProps={{ style: { borderRadius: '8px' } }}
            cancelButtonProps={{ style: { borderRadius: '8px' } }}
            title={t('_PROJECT_LAYOUT_CONFIG_TABLE_DELETE_AREA_POP_CONFIRM_TITLE')}
            onConfirm={(event) => {
              event?.stopPropagation();
              const selectedRow = tableData.find(row => row.id === area.id);
              if (selectedRow) {
                onClickDelete(selectedRow.id);
                onConfirm(selectedRow.id);
              }
            }}
            onCancel={event => event?.stopPropagation()}
            okText={t('_PROJECT_LAYOUT_CONFIG_TABLE_DELETE_AREA_POP_CONFIRM_OK_TEXT')}
            cancelText={t('_PROJECT_LAYOUT_CONFIG_TABLE_DELETE_AREA_POP_CONFIRM_CANCEL_TEXT')}
          >
            <PrimarySmallTextOutlinedButton
              text={t('_PROJECT_LAYOUT_CONFIG_ACTION_BUTTON_DELETE')}
              onClick={event => event?.stopPropagation()}
            />
          </PopconfirmAtom>
        }
        {deletingRowId === area.id &&
          <PrimarySmallLeftIconOutlinedButton
            text={t('_PROJECT_LAYOUT_CONFIG_ACTION_BUTTON_DELETE')}
            Icon={SpinnerIcon}
            disabled
          />
        }
      </div>
    </div >
  )
}