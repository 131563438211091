import { PremiumUserUnparsed } from '../model/premiumUserUnparsed';
import { payloadToPremiumUsers } from '../mappers/payloadToPremiumUsers';
import PremiumUserValueObject from '../entities/premiumUserValueObject';
import { premiumUsersPath } from 'core/endspoints/premiumUsers';
import api from 'services/api';

export const GetPremiumUsersByIdClient = async () => {
  const url = premiumUsersPath();
  const { data } = await api.get<PremiumUserUnparsed[]>(url);
  const premiumUserListParser = data.map((item) => payloadToPremiumUsers(item));
  const { list } = PremiumUserValueObject(premiumUserListParser);
  return list;
};
