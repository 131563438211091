import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getQuery, superSearch } from 'services/api/superSearch';
import { Asset } from 'models/assets.model';
import { User } from 'models/users.model';
import Results from './Results';
import TitleBar from './TitleBar';
import Paper from 'components/atoms/Paper';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { ProjectModel } from 'core/domain/project/model/projectModel';
import './styles.scss';

const MIN_LENGTH_TO_SEARCH = 2;

const SuperSearchPage = () => {
  const { t } = useTranslation('global');
  const path = useLocation();
  const [query, setQuery] = useState('');
  const [assets, setAssets] = useState<Asset[]>([]);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [projects, setProjects] = useState<ProjectModel[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const pathSearch = getQuery(path.search);
    setQuery(pathSearch);
    if (pathSearch.length >= MIN_LENGTH_TO_SEARCH) {
      superSearch(pathSearch)
        .then(({ data }) => {
          setAssets(data.assets);
          setClients(data.clients);
          setProjects(data.projects);
          setUsers(data.users);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [path.search, query]);

  return (
    <div className='SuperSearchPage__wrapper'>
      <Paper>
        <TitleBar query={query} searching={query.length >= MIN_LENGTH_TO_SEARCH} />
        <Divider className='SuperSearchPage__divider' />
        {query.length >= MIN_LENGTH_TO_SEARCH ? (
          <Results loading={loading} assets={assets} clients={clients} projects={projects} users={users} />
        ) : (
          <p>
            <InfoCircleOutlined /> {t('SUPER_SEARCH.init_info')}
          </p>
        )}
      </Paper>
    </div>
  );
};

export default SuperSearchPage;
