import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = ({ host, gid }: { host: string; gid: string }) => {
  return `${host}/gateways/${gid}`;
};

export const DeleteGatewaysFromSystemPermanently = async ({
  token,
  host,
  gid,
}: {
  token: string;
  host: string;
  gid: string;
}): Promise<void> => {
  try {
    const url = getUrl({ host, gid });

    const options: AxiosRequestConfig = {
      url,
      method: 'DELETE',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    (await axios(options)) as AxiosResponse<void>;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
