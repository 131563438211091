import { ProjectModel } from '../model/projectModel';

const ProjectEntity = ({
  id,
  name,
  logo,
  backgroundImage,
  isBackgroundImageDefault,
  allowedUsersToVerifyAccounts,
  supportEmail,
  label,
  assets,
  client,
  intercoms,
  usersRequireVerification,
  usersVerifiers,
  sharedGateways,
  type,
  status,
  availableIntercomVendors,
}: ProjectModel): Readonly<ProjectModel> => {
  return Object.freeze({
    id,
    allowedUsersToVerifyAccounts,
    name,
    logo,
    backgroundImage,
    isBackgroundImageDefault,
    supportEmail,
    label,
    assets,
    client,
    intercoms,
    usersRequireVerification,
    usersVerifiers,
    sharedGateways,
    type,
    status,
    availableIntercomVendors,
  });
};

export default ProjectEntity;
