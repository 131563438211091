import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AsChart from 'components/atoms/AsChart';
import moment, { Moment } from 'moment';
import { ChartConfiguration } from 'chart.js';
import { Data } from 'models/dataAcquisition.model';
import { DATE_TIME_FORMAT } from 'constants/date';

interface InstantChartProps {
  data: Data[];
  dataPresence?: Data[];
  title?: string;
  unit?: string;
  dateRange: [Moment, Moment];
  color?: string;
}

const InstantChart: FC<InstantChartProps> = ({ data, dataPresence, unit, title, dateRange, color }) => {
  const { t } = useTranslation();
  const labels: number[] = [];
  const dataValues: { x: number; y: number }[] = [];
  const dataPresenceValues: { x: number; y: number }[] = [];
  const startDate = dateRange[0].startOf('day').valueOf();
  const endDate = dateRange[1].endOf('day').valueOf();

  data?.forEach((item) => {
    dataValues.push({ x: moment.unix(item.ts).valueOf(), y: parseFloat(Number(item.v).toFixed(2)) });
    labels.push(moment.unix(item.ts).valueOf());
  });

  dataPresence?.forEach((item, index, array) => {
    const value = Number(item.v);
    const freeValue = 0;
    const busyValue = 1;
    const free = !!value;
    const busy = !value;
    const date = moment.unix(item.ts);
    const firstIndex = !!index;
    const lastIndex = array.length - 1;

    if (!firstIndex && busy) dataPresenceValues.push({ x: startDate, y: busyValue });
    if (free) dataPresenceValues.push({ x: date.subtract(1, 'second').valueOf(), y: freeValue });
    if (firstIndex && busy) dataPresenceValues.push({ x: date.subtract(1, 'second').valueOf(), y: busyValue });
    dataPresenceValues.push({ x: date.valueOf(), y: value });
    if (index === lastIndex && free) dataPresenceValues.push({ x: endDate, y: busyValue });
  });

  const chartConfiguration: ChartConfiguration = {
    type: 'line',
    data: {
      labels: labels,
      datasets: [
        {
          label: title,
          backgroundColor: color,
          borderColor: 'transparent',
          data: dataValues,
          fill: true,
          stepped: true,
          pointStyle: 'rectRot',
          order: 0,
        },
        {
          label: t('presence'),
          borderColor: 'transparent',
          data: dataPresenceValues,
          fill: true,
          stepped: true,
          pointStyle: 'triangle',
          pointRadius: 0,
          yAxisID: 'y1',
          order: 1,
        },
      ],
    },
    options: {
      responsive: true,
      interaction: {
        intersect: false,
        mode: 'x',
      },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: false,
        },
        tooltip: {
          mode: 'nearest',
          intersect: false,
          usePointStyle: true,
          callbacks: {
            title: (item) => moment(item[0]?.label).format(DATE_TIME_FORMAT),
            label: (tooltipItems: any) => {
              if (tooltipItems.dataset.yAxisID === 'y1') {
                if (tooltipItems.formattedValue === '1') return t('CHART.presence_mode_activated');
                else return t('CHART.presence_mode_deactivated');
              }
              const _unit = unit || '';
              return `${tooltipItems.formattedValue} ${_unit}`;
            },
          },
        },
      },
      hover: {
        mode: 'x',
        intersect: true,
      },
      scales: {
        x: {
          type: 'time',
          time: {
            displayFormats: {
              hour: 'HH:mm',
            },
            minUnit: 'hour',
            stepSize: 6,
          },
          min: startDate,
          max: endDate,
        },
        x1: {
          display: true,
          type: 'time',
          time: {
            unit: 'day',
          },
          min: startDate,
          max: endDate,
        },
        y: {
          ticks: {
            callback: (value) => value + ' ' + unit,
          },
        },
        y1: {
          display: false,
          max: 1,
        },
      },
    },
  };

  return <AsChart configuration={chartConfiguration} />;
};

export default InstantChart;
