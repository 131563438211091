import { FC } from 'react';
import { DatePicker, FormInstance } from 'antd';
import { Moment } from 'moment';
import { AssetConsumptionsFormTranslationsType, AssetConsumptionsFormValues, AssetConsumptionsFormValuesNames } from 'components/pages/AssetPage/Tabs/TabConsumption/OptionChart/resources/models';
import SelectSensor from 'components/pages/AssetPage/Tabs/TabConsumption/OptionChart/SelectSensor';
import { SensorEntityModel } from 'core/domain/sensors/models/sensorEntityModels';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { DATE_FORMAT } from 'constants/date';
import './styles.scss';

interface Props {
  Form: any;
  form: FormInstance<AssetConsumptionsFormValues>;
  initialValues: AssetConsumptionsFormValues;
  sensors: SensorEntityModel[];
  dateRange: [Moment, Moment] | null;
  translations: AssetConsumptionsFormTranslationsType;
  onSelectSensor: (sensor: SensorEntityModel) => void;
  onChangeCalendar: (dates: any) => void;
  onChangeMonth: (value: Moment | null) => void;
  onDownloadCSV: () => void;
  onSubmit: () => void;
  disabledDate: (current: Moment) => boolean;
  disabledDateMonth: (current: Moment) => boolean;
  isLoadingConsumptions: boolean;
  isMainButtonDisabled: boolean;
  isSecondaryButtonDisabled: boolean;
}

const AssetConsumptionsForm: FC<Props> = ({
  Form,
  form,
  initialValues,
  sensors,
  dateRange,
  translations,
  onSelectSensor,
  onChangeCalendar,
  onChangeMonth,
  onDownloadCSV,
  onSubmit,
  disabledDate,
  disabledDateMonth,
  isLoadingConsumptions,
  isMainButtonDisabled,
  isSecondaryButtonDisabled,
}) => {
  const { initialDateText, secondaryTextButton, textButton } = translations;

  return (
    <div>
      <Form form={form} layout={'inline'} requiredMark={false} initialValues={initialValues}>
        <div className={'AssetConsumptionForm__element'}>
          <Form.Item name={AssetConsumptionsFormValuesNames.SENSOR}>
            <SelectSensor sensorList={sensors} onChange={onSelectSensor} />
          </Form.Item>
        </div>
        <div className={'AssetConsumptionForm__element'}>
          <Form.Item name={AssetConsumptionsFormValuesNames.TIME_RANGE}>
            <DatePicker.RangePicker
              onCalendarChange={(values) => onChangeCalendar(values)}
              value={dateRange && dateRange}
              format={DATE_FORMAT}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </div>
        <div className={'AssetConsumptionForm__element'}>
          <Form.Item name={AssetConsumptionsFormValuesNames.MONTH}>
            <DatePicker
              picker={'month'}
              placeholder={initialDateText}
              disabledDate={disabledDateMonth}
              onChange={(value) => onChangeMonth(value)}
            />
          </Form.Item>
        </div>
        <div className={'AssetConsumptionForm__button'}>
          <PrimaryButton
            htmlType={'submit'}
            title={textButton}
            loading={isLoadingConsumptions}
            disabled={isMainButtonDisabled}
            onClick={onSubmit}
          />
        </div>
        <PrimaryButton disabled={isSecondaryButtonDisabled} loading={false} onClick={onDownloadCSV} title={secondaryTextButton} />
      </Form>
    </div>
  );
}

export default AssetConsumptionsForm;