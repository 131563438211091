import { FC } from 'react';
import { Alert, FormInstance, Tooltip } from 'antd'
import { Icon } from 'components/atoms/Icons';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import {
  ClientCustomizationFormValuesNames,
  ClientCustomizationTranslations,
  ClientCustomizationFormValues,
  ClientCustomizationInputValue
} from 'components/pages/ClientDetailPage/TabCustomization/SubTabs/SubTabAssetsManagerAndEmail/resources/models';
import ColorPickerWithSample from 'components/organisms/ColorPickerWithSample';
import ImagePickerDisplay from '../ImagePickerDisplay';
import './styles.scss';

interface Props {
  translations: ClientCustomizationTranslations;
  initialValues: ClientCustomizationFormValues;
  customizationFormValues: ClientCustomizationFormValues;
  Form: any;
  form: FormInstance<ClientCustomizationFormValues>;
  onChangeMade: ({ label, value }: ClientCustomizationInputValue) => void;
  onCancel: () => void;
  onPublish: () => void;
  disabled: boolean;
  updating: boolean;
  canceled: boolean;
}

const ClientCustomizationForm: FC<Props> = ({
  translations,
  Form,
  form,
  onChangeMade,
  onCancel,
  onPublish,
  initialValues,
  customizationFormValues,
  disabled,
  updating,
  canceled,
}) => {
  const { logo, backgroundColor } = customizationFormValues;
  const { logo: addLogoLabel, logoRequirements, backgroundColor: SelectBackgroundColorLabel, info, cancel, publish } = translations;

  return (
    <Form form={form} layout={'vertical'} requiredMark={false}>
      <Form.Item
        name={ClientCustomizationFormValuesNames.LOGO}
        label={(
          <>
            <span className={'CustomizationForm__logo-info'}>{addLogoLabel}</span>
            <Tooltip title={logoRequirements} overlayInnerStyle={{ width: 290, textAlign: 'center', marginLeft: -20 }}>
              <Icon.InfoCircleFilled />
            </Tooltip>
          </>
        )}
      >
        <ImagePickerDisplay
          onChange={(value: string) => onChangeMade({ label: ClientCustomizationFormValuesNames.LOGO, value })}
          initialValues={initialValues.logo}
          temporaryImageUrl={logo}
          canceled={canceled}
          sizeLimit={5}
          acceptedTypes={['jpg', 'jpeg', 'png']}
          disabled={updating}
        />
      </Form.Item>
      <Form.Item
        name={ClientCustomizationFormValuesNames.BACKGROUND_COLOR}
        label={SelectBackgroundColorLabel}
      >
        <ColorPickerWithSample
          color={backgroundColor}
          onChangeColor={(value: string) => onChangeMade({ label: ClientCustomizationFormValuesNames.BACKGROUND_COLOR, value })}
          disabled={updating}
        />
      </Form.Item>
      <div className={'CustomizationForm__alert'}>
        <Alert message={info} type={'info'} showIcon />
      </div>
      <div className={'CustomizationForm__actions'}>
        <DefaultButton
          title={cancel}
          onClick={onCancel}
          className={'CustomizationForm__cancel-button'}
          disabled={disabled || updating}
        />
        <PrimaryButton
          htmlType={'submit'}
          title={publish}
          loading={updating}
          disabled={disabled}
          onClick={onPublish}
        />
      </div>
    </Form>
  )
}

export default ClientCustomizationForm;