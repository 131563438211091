import { ProjectSharedGatewayManagementLinksModel } from '../model/projectModel';
import { PayloadProjectSharedGatewayManagementLinksModel } from '../model/projectPayloadModel';

export const payloadToManagementLinks = ({
  openhab,
  ssh_teleport,
  control_panel,
}: PayloadProjectSharedGatewayManagementLinksModel): ProjectSharedGatewayManagementLinksModel => {
  return {
    openhab: openhab ?? '',
    sshTeleport: ssh_teleport ?? '',
    controlPanel: control_panel ?? '',
  };
};
