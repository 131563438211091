import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DATE_FORMAT } from 'constants/date';
import { ProjectSharedGatewayModel } from 'core/domain/project/model/projectModel';
import { Space } from 'antd';

const UsersTabColumns = (): AlfredTableColumn<ProjectSharedGatewayModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: 'GID',
      key: 'id',
      width: '20%',
      render: (text, { id, productionDate }) => (
        <Space>
          <div className={'UsersTabColumns__cell'}>
            <b>{id}</b>
            {!!productionDate && <span className={'Text__date'}>{moment.unix(productionDate).format(DATE_FORMAT)}</span>}
          </div>
        </Space>
      ),
    },
    {
      title: 'QID',
      width: '20%',
      dataIndex: 'qrCode',
      key: 'qrCode'
    },
    {
      title: 'Hadware',
      width: '20%',
      dataIndex: 'hwVersion',
      key: 'hwVersion',
    },
    {
      title: t('_GATEWAY_VERSION'),
      width: '20%',
      dataIndex: 'osVersion',
      key: 'osVersion',
    },
    {
      title: t('_AASS_VERSION_TITLE'),
      width: '20%',
      dataIndex: 'daemonVersion',
      key: 'daemonVersion',
    },
  ];
};

export default UsersTabColumns;
