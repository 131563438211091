import { updateLegalDocumentationStatusPath } from 'core/endspoints/users';
import api from 'services/api';

export const UpdateLegalDocumentationStatus = async (): Promise<void | undefined> => {
  const url = updateLegalDocumentationStatusPath();
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    return await api.post(url);
  } catch (error: any) {
    throw new Error(error);
  }
};
