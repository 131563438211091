import api from 'services/api';
import { sensorsAssetListPath } from 'core/endspoints/sensors';
import { SensorsAssetListValueObject } from '../entities/sensorsAssetListValueObject';
import { payloadToSensorsAssets } from '../mappers/payloadToSensorsAsset';
import { SensorEntityModel } from '../models/sensorEntityModels';
import { SensorPayloadModel } from '../models/sensorPayloadModels';

const sortOrder = (a: SensorEntityModel, b: SensorEntityModel) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
};

export const GetSensorsListByAssetId = async (assetId: string, showAll: boolean): Promise<SensorEntityModel[] | undefined> => {
  try {
    const path = sensorsAssetListPath(assetId);
    api.defaults.headers.common['Accept-version'] = '1';
    const params = new URLSearchParams();
    showAll && params.append('show', 'all');
    const { data } = await api.get<SensorPayloadModel[]>(`${path}?${params}`);
    const devicesData = data.map((item) => payloadToSensorsAssets(item));
    const { list } = SensorsAssetListValueObject(devicesData);
    return list.sort(sortOrder);
  } catch (error) {
    console.error(error);
  }
};
