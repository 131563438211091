import { RemoveEventsAndNews } from 'core/domain/eventsAndNews/repositories/removeEventsAndNews';
import { CreateEventsAndNews } from 'core/domain/eventsAndNews/repositories/createEventsAndNews';
import { GetEventsAndNewsList } from 'core/domain/eventsAndNews/repositories/getEventsAndNewsList';
import { CreateEventsAndNewsModel, EventsAndNewsModel } from 'core/domain/eventsAndNews/models/eventsAndNewsEntityModels';
import { GetEventsAndNewsById } from 'core/domain/eventsAndNews/repositories/getEventsAndNewsById';
import { UpdateEventsAndNews } from 'core/domain/eventsAndNews/repositories/updateEventsAndNews';

type GetListModel = (projectId: string) => Promise<EventsAndNewsModel[] | undefined>;
type GetModel = (projectId: string, eventsAndNewsId: string) => Promise<EventsAndNewsModel | undefined>;
type CreateModel = (projectId: string, eventsAndNews: CreateEventsAndNewsModel) => Promise<void>;
type UpdateModel = (eventsAndNews: EventsAndNewsModel) => Promise<void>;
type RemoveModel = (eventsAndNewsId: string) => Promise<void>;

type UseEventsAndNewsModel = () => {
  getEventsAndNewsList: GetListModel;
  getEventsAndNews: GetModel;
  createEventsAndNews: CreateModel;
  updateEventsAndNews: UpdateModel;
  removeEventsAndNews: RemoveModel;
};

export const useEventsAndNewsCRUD: UseEventsAndNewsModel = () => {
  const getEventsAndNewsList: GetListModel = async (projectId) => {
    try {
      const list = await GetEventsAndNewsList(projectId);
      return list;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getEventsAndNews: GetModel = async (projectId, eventsAndNewsId) => {
    try {
      return await GetEventsAndNewsById(projectId, eventsAndNewsId);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const createEventsAndNews: CreateModel = async (projectId, eventsAndNews) => {
    try {
      return await CreateEventsAndNews(projectId, eventsAndNews);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const updateEventsAndNews: UpdateModel = async (eventsAndNews) => {
    try {
      await UpdateEventsAndNews(eventsAndNews);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const removeEventsAndNews: RemoveModel = async (eventsAndNewsId) => {
    try {
      await RemoveEventsAndNews(eventsAndNewsId);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return {
    getEventsAndNewsList,
    getEventsAndNews,
    createEventsAndNews,
    updateEventsAndNews,
    removeEventsAndNews,
  };
};
