import { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { RouteConfig, renderRoutes } from 'react-router-config';
import CommonAreasClientAndProjectSelectorTemplate from 'components/template/CommonAreasClientAndProjectSelectorTemplate';
import { useCommonAreasClientAndProjectSelector } from './resources/useCommonAreasClientAndProjectSelector';

interface PrivatePageProps extends RouteComponentProps {
  route?: RouteConfig;
}

const CommonAreasClientAndProjectSelectorPage: FC<PrivatePageProps> = ({ route }) => {
  const {
    title,
    currentClient,
    currentProject,
    clientPlaceholderText,
    projectPlaceholderText,
    emptyText,
    clientsList,
    projectsList,
    onSelectClient,
    onSelectProject,
    loadingClientsList,
    loadingProjectsList,
    showEmptyMessage,
  } = useCommonAreasClientAndProjectSelector();

  return (
    <CommonAreasClientAndProjectSelectorTemplate
      title={title}
      clientId={currentClient}
      projectId={currentProject}
      clientPlaceholderText={clientPlaceholderText}
      clientListOptions={clientsList}
      onSelectClient={onSelectClient}
      emptyText={emptyText}
      loadingClientsList={loadingClientsList}
      projectPlaceholderText={projectPlaceholderText}
      projectListOptions={projectsList}
      onSelectProject={onSelectProject}
      loadingProjectsList={loadingProjectsList}
      showEmptyMessage={showEmptyMessage}
    >
      {renderRoutes(route?.routes)}
    </CommonAreasClientAndProjectSelectorTemplate>
  )
}

export default CommonAreasClientAndProjectSelectorPage;