import { FC } from 'react';
import './styles.scss';

interface PaperProps {
  className?: string;
  children?: React.ReactNode;
}

const Paper: FC<PaperProps> = ({ className, children }) => {
  return <div className={`Paper ${className}`}>{children}</div>;
};

export default Paper;
