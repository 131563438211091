import { FC } from 'react';
import { Card, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { FilePdfOutlined } from '@ant-design/icons';
import { ProductsAcademy } from 'models/academy.model';
import { IMAGES } from 'constants/images';
import Paper from 'components/atoms/Paper';
import dataProducts from './products.json';
import './styles.scss';

const TabProducts: FC = () => {
  const { t } = useTranslation();
  const items = (dataProducts as unknown) as ProductsAcademy[];

  return (
    <Paper>
      <div className='TabProducts__grid'>
        {items.map((item, key) => (
          <div key={key} className='TabProducts__wrap'>
            <Card
              className='TabProducts__card'
              bordered
              cover={<img className='TabProducts__card-image' alt={t(`ALFRED_ACADEMY.${item.key}.title`)} src={IMAGES.devices[item.key]} />}
              actions={[
                <span
                  onClick={() => {
                    navigator.clipboard.writeText(item.SKU);
                    message.success(`${t('copied')}`);
                  }}
                >
                  SKU: {item.SKU}
                </span>,
                <a target='_blank' rel='noopener noreferrer' href={item.link}>
                  <FilePdfOutlined key='filePDF' />
                </a>,
              ]}
            >
              <Card.Meta title={t(`ALFRED_ACADEMY.${item.key}.title`)} description={t(`ALFRED_ACADEMY.${item.key}.description`)} />
            </Card>
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default TabProducts;
