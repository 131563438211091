import { FC } from 'react';
import { SupportTicketsTranslationsTypes } from 'components/pages/DashboardPage/resources/translations';
import './styles.scss';

interface Props {
  translations: SupportTicketsTranslationsTypes;
}

const DashboardSupportTicketsErrorTemplate: FC<Props> = ({ translations }) => {
  return (
    <div className='SupportTicketsErrorTemplate__container'>
      <div className='SupportTicketsErrorTemplate__header'>
        <div>
          <p className='SupportTicketsErrorTemplate__title'>
            {translations.TITLE}
          </p>
          <p className='SupportTicketsErrorTemplate__subtitle'>
            {translations.TITLE_TIME_PERIOD}
          </p>
        </div>
        <div>
          <p className='SupportTicketsErrorTemplate__average'>
            {`${translations.OPEN_TICKETS}: --`}
          </p>
        </div>
      </div>
      <div className='SupportTicketsErrorTemplate__chart-container'>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {translations.TICKETS_CLOSED_TIME_AVERAGE}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {translations.FIRST_RESPONSE_TIME_AVERAGE}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {translations.TICKETS_RECEIVED}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {translations.TICKETS_CLOSED}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {translations.TICKETS_IN_PROGRESS}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {translations.TICKETS_REVOKED}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
      </div>
    </div>
  );
}

export default DashboardSupportTicketsErrorTemplate;