import { FC } from "react";
import Spinner from "components/atoms/Spinner";
import './styles.scss';

// this is a temporary loading template. It should have a Skeleton component.

export const ProjectLayoutConfigLoadingTemplate: FC = () => {
  return (
    <div className={'ProjectLayoutConfigLoadingTemplate__wrapper'}>
      <Spinner />
    </div>
  )
}