import { FC } from 'react';
import ComingSoonTemplate from 'components/template/ComingSoonTemplate';
import { useHotelRoomComingSoon } from './resources/useHotelRoomComingSoon';

const HotelRoomComingSoonPage: FC = () => {
  const { forbiddenText } = useHotelRoomComingSoon();

  return <ComingSoonTemplate text={forbiddenText} />
}

export default HotelRoomComingSoonPage;