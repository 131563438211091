import { assetDetailPath } from 'core/endspoints/assets';
import { AssetResponseModel } from '../models/assetsModel';
import { AssetResponse } from 'models/assets.model';
import api from 'services/api';
import { payloadToAssetResponse } from '../mappers/payloadToAsset';
import { AssetResponseEntity } from '../entities/assetResponseEntity';

export const GetAssetDetail = async (assetId: string, include?: 'users'): Promise<AssetResponseModel> => {
  const url = assetDetailPath(assetId);
  api.defaults.headers.common['Accept-version'] = '1';
  const params = new URLSearchParams();
  include && params.append('include', include);

  try {
    const response: AssetResponse = await api.get(url, { params });
    const mappedData = payloadToAssetResponse(response);
    return AssetResponseEntity(mappedData);
  } catch (error: any) {
    throw new Error(error);
  }
};
