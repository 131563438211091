import { FC } from 'react';
import './styles.scss';

interface Props {
  color: string;
  onClick: () => void;
  disabled: boolean;
}

const ColorSample: FC<Props> = ({ color, onClick, disabled }) => {
  return (
    <button className={'ColorSample'} style={{ backgroundColor: color }} onClick={onClick} disabled={disabled} />
  );
}

export default ColorSample;