import { FC } from 'react';
import ComingSoonTemplate from 'components/template/ComingSoonTemplate';
import { useVacationalRentalComingSoon } from './resources/useVacationalRentalComingSoon';

const VacationalRentalComingSoonPage: FC = () => {
  const { forbiddenText } = useVacationalRentalComingSoon();

  return <ComingSoonTemplate text={forbiddenText} />
}

export default VacationalRentalComingSoonPage;