import { FC, useEffect, useState } from 'react';
import { message } from 'antd';
import ImageUploadButton from 'components/atoms/ImageUploadButton';
import ImageActionsMolecule from 'components/molecules/ImageActionsMolecule';
import { RcFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import TextAtom from 'components/atoms/TextAtom';
import './styles.scss';

interface Props {
  initialValues?: string;
  disabled?: boolean;
  canceled?: boolean;
  onChange: (imageBase64: string) => void;
  error?: boolean;
  temporaryImageUrl?: string;
  sizeLimit: number;
  acceptedTypes: string[];
}

interface ImageFormat {
  imageType: string;
  types: string[];
  messageType: string;
}

interface ImageSize {
  size: number;
  limit: number;
  messageSize: string;
}

interface ImageBeforeUpload {
  file: RcFile;
  messageType: string;
  messageSize: string;
  sizeLimit: number;
  acceptedTypes: string[];
}

const getBase64 = (img: any, callback: any): void => {
  if (img) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
};

const checkImageFormat = ({ imageType, types, messageType }: ImageFormat): boolean => {
  const transformedImageType = imageType.split('/');
  const isFormatCorrect = types.some((type) => type === transformedImageType[1])
  !isFormatCorrect && message.error(messageType);
  return isFormatCorrect;
}

const checkImageSize = ({ size, limit, messageSize }: ImageSize): boolean => {
  const isSizeUnderLimit = size / 1024 / 1024 < limit;
  !isSizeUnderLimit && message.error(messageSize);
  return isSizeUnderLimit;
}

const checkImageBeforeUpload = ({ file, messageType, messageSize, sizeLimit, acceptedTypes }: ImageBeforeUpload): boolean => {
  const isImageFormatCorrect = checkImageFormat({ imageType: file.type, types: acceptedTypes, messageType })
  const isImageSizeBigger = checkImageSize({ size: file.size, limit: sizeLimit, messageSize })
  return isImageFormatCorrect && isImageSizeBigger;
};

const ImagePickerDisplay: FC<Props> = ({
  error = false, initialValues = '', disabled = false, canceled = false, temporaryImageUrl = '', onChange, sizeLimit, acceptedTypes = ['jpeg']
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tempImage, setTempImage] = useState<string>(temporaryImageUrl);
  const [imageBase64, setImageBase64] = useState<string>('');
  const transformedAcceptedTypes = acceptedTypes.map((type) => `.${type}`).join(', ').trim();

  useEffect(() => {
    if (!!initialValues.length) {
      setTempImage(initialValues);
      setImageBase64(initialValues);
    }
    return () => {
      setTempImage('');
      setImageBase64('');
    };
  }, [initialValues]);

  useEffect(() => {
    if (canceled) {
      setTempImage(initialValues);
      setImageBase64(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canceled])

  useEffect(() => {
    onChange(imageBase64);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageBase64]);

  const previewFile = (file: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        setTempImage(reader.result);
      }
      setIsLoading(false);
    };
    if (file) {
      reader.readAsDataURL(file);
      return file;
    } else {
      setTempImage('');
    }
    setIsLoading(false);
  };

  const onBase = (result: any) => {
    setImageBase64(result);
  };

  const handleChange = (info: any) => {
    setIsLoading(true);
    previewFile(info.file.originFileObj);
    getBase64(info.file.originFileObj, onBase);
  };

  const handleTrash = () => {
    setImageBase64('');
    setTempImage('');
  };

  const handleOnBeforeUpload = (file: RcFile) => {
    const messageSize = `${t('UPLOAD.size')} ${sizeLimit}MB!`;
    const types = acceptedTypes.map((type) => type.toUpperCase()).join('/');
    const messageType = `${t('UPLOAD.type')} ${types}!`;
    return checkImageBeforeUpload({ file, messageType, messageSize, sizeLimit, acceptedTypes });
  };

  return (
    <div className={'ImagePickerDisplay__container'}>
      <ImageUploadButton
        loading={isLoading}
        onChange={handleChange}
        beforeUpload={handleOnBeforeUpload}
        disabled={disabled || !!imageBase64 || !!tempImage.length}
        accept={transformedAcceptedTypes}
      />
      {!!tempImage.length && (
        <ImageActionsMolecule
          imageURL={tempImage}
          onTrashClick={handleTrash}
          disabledDelete={disabled}
        />
      )}
      {error && (
        <TextAtom
          type='danger'>{t('field_required')}
        </TextAtom>
      )}
    </div>
  );
};

export default ImagePickerDisplay;
