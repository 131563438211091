import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { ChartConfiguration } from 'chart.js';
import AsChart from 'components/atoms/AsChart';
import { Data } from 'models/dataAcquisition.model';
import { DATE_TIME_FORMAT } from 'constants/date';
import { COLORS } from 'constants/colors';

interface ConsumptionChartProps {
  data: Data[];
  dataPresence?: Data[];
  title?: string;
  unit?: string;
  color?: string;
  dateRange: [Moment, Moment];
}

const ConsumptionChart: FC<ConsumptionChartProps> = ({ data, dataPresence, unit, title, dateRange, color }) => {
  const { t } = useTranslation();
  const labels: Date[] = [];
  const dataItems: any[] = [];
  const dataPresenceValues: any[] = [];
  const startDate = dateRange[0].startOf('day').valueOf();
  const endDate = dateRange[1].endOf('day').valueOf();

  let _lastMoment: Moment | undefined;
  data?.forEach((item) => {
    const x = moment.unix(item.ts).startOf('hour'); // clean minutes and seconds
    // TODO: limpiamos los datos duplicados, se dede de eliminar cuando
    if (_lastMoment?.isSame(x)) {
      return;
    } else {
      _lastMoment = x;
      dataItems.push({
        y: parseFloat(Number(item.v).toFixed(2)),
        x: moment.unix(item.ts).toDate(),
      });
      labels.push(moment.unix(item.ts).toDate());
    }
  });

  const sum = dataItems.map((item) => item.y).reduce((a, b) => a + b, 0);
  let avg = sum / dataItems.length || 0;
  avg = parseFloat(Number(avg).toFixed(2));
  const avgDataItem = Array(dataItems.length).fill(parseFloat(Number(avg).toFixed(2)));

  dataPresence?.forEach((item, index, array) => {
    const value = Number(item.v);
    const freeValue = 0;
    const busyValue = 1;
    const free = !!value;
    const busy = !value;
    const date = moment.unix(item.ts);
    const firstIndex = !!index;
    const lastIndex = array.length - 1;

    if (!firstIndex && busy) dataPresenceValues.push({ x: startDate, y: busyValue });
    if (free) dataPresenceValues.push({ x: date.subtract(1, 'second').valueOf(), y: freeValue });
    if (firstIndex && busy) dataPresenceValues.push({ x: date.subtract(1, 'second').valueOf(), y: busyValue });
    dataPresenceValues.push({ x: date.valueOf(), y: value });
    if (index === lastIndex && free) dataPresenceValues.push({ x: endDate, y: busyValue });
  });

  const chartConfiguration: ChartConfiguration = {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [
        {
          label: t('presence'),
          type: 'line',
          borderColor: 'transparent',
          data: dataPresenceValues,
          fill: true,
          stepped: true,
          pointStyle: 'triangle',
          pointRadius: 0,
          yAxisID: 'y1',
          order: 2,
        },
        {
          label: 'Average',
          type: 'line',
          fill: false,
          pointStyle: 'star',
          pointRadius: 0,
          data: avgDataItem,
          borderColor: COLORS.green_flat,
          backgroundColor: COLORS.green_flat,
          order: 1,
        },
        {
          label: title,
          backgroundColor: color,
          fill: true,
          data: dataItems,
          order: 0,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: false,
        },
        tooltip: {
          mode: 'nearest',
          intersect: false,
          usePointStyle: true,
          callbacks: {
            title: (item) => moment(item[0]?.label).format(DATE_TIME_FORMAT),
            label: (tooltipItems: any) => {
              if (tooltipItems.dataset.yAxisID === 'y1') {
                if (tooltipItems.formattedValue === '1') return t('CHART.presence_mode_activated');
                else return t('CHART.presence_mode_deactivated');
              }
              const _unit = unit || '';
              return `${tooltipItems.formattedValue} ${_unit}`;
            },
          },
        },
      },
      hover: {
        mode: 'x',
        intersect: true,
      },
      scales: {
        x: {
          type: 'time',
          time: {
            displayFormats: {
              hour: 'HH:mm',
            },
            minUnit: 'hour',
            stepSize: 6,
          },
          min: startDate,
          max: endDate,
          // grid: {
          //   drawBorder: false,
          //   color: (context) => {
          //     const date = new Date(context.tick.value);
          //     const diffDays = moment(date).diff(moment(), 'days');
          //     const diffHours = moment(date).diff(moment(), 'hours');
          //     if (!diffDays) {
          //       if (diffHours > 0) return COLORS.alert;
          //     }
          //     return COLORS.black_01;
          //   },
          // },
        },
        x1: {
          type: 'time',
          display: true,
          time: {
            unit: 'day',
          },
          min: startDate,
          max: endDate,
        },
        y: {
          ticks: {
            callback: (value) => value + ' ' + unit,
          },
        },
        y1: {
          display: false,
          max: 1,
        },
      },
    },
  };

  return <AsChart configuration={chartConfiguration} />;
};

export default ConsumptionChart;
