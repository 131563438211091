import { DrawerAtomProps } from 'components/atoms/DrawerAtom';
import { CreateAssetDrawerOptionsModel } from 'components/pages/AssetsPage/resources/useCreateAsset';
import { DidScannerOptionsModel } from 'components/pages/AssetsPage/resources/useDidScanner';
import { QrScannerOptionsModel } from 'components/pages/AssetsPage/resources/useQrScanner';

export interface CreateAssetFormValues {
  qid: string | null;
  did: string | null;
  clientId: string | null;
  community: string | null;
  alias: string | null;
  type: string | null;
  streetName: string | null;
  streetType: string | null;
  blockNumber: string | null;
  staircase: string | null;
  flat: string | null;
  door: string | null;
  postalCode: string | null;
  city: string | null;
  province: string | null;
  country: string | null;
  description: string | null;
}

export enum CreateAssetFormValuesNames {
  QID = 'qid',
  DID = 'did',
  CLIENT_ID = 'clientId',
  COMMUNITY = 'community',
  ALIAS = 'alias',
  TYPE = 'type',
  STREET_TYPE = 'streetType',
  STREET_NAME = 'streetName',
  BLOCK_NUMBER = 'blockNumber',
  STAIRCASE = 'staircase',
  FLAT = 'flat',
  DOOR = 'door',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  PROVINCE = 'province',
  COUNTRY = 'country',
  DESCRIPTION = 'description',
}

export const CREATE_FORM_INITIAL_VALUES: CreateAssetFormValues = {
  qid: null,
  did: null,
  clientId: null,
  community: null,
  alias: null,
  type: null,
  streetName: null,
  streetType: null,
  blockNumber: null,
  staircase: null,
  flat: null,
  door: null,
  postalCode: null,
  city: null,
  province: null,
  country: null,
  description: null,
};

export interface CreateAssetDrawerLiteralsType {
  title: string;
  closeButtonText: string;
  createAssetButtonText: string;
  requiredFieldText: string;
  assetDescription: string;
  clientPlaceholder: string;
  createClientButtonText: string;
  projectPlaceholder: string;
  createProjectButtonText: string;
  qidPlaceholder: string;
  qidLabel: string;
  qidMinimumLengthText: string;
  qidMaximumLengthText: string;
  didLabel: string;
  didPlaceholder: string;
  didMinimumLengthText: string;
  didMaximumLengthText: string;
  aliasPlaceholder: string;
  typePlaceholder: string;
  streetTypePlaceholder: string;
  streetNamePlaceholder: string;
  blockNumberPlaceholder: string;
  staircasePlaceholder: string;
  flatPlaceholder: string;
  doorPlaceholder: string;
  postalCodePlaceholder: string;
  cityPlaceholder: string;
  provincePlaceholder: string;
  countryPlaceholder: string;
  descriptionPlaceholder: string;
}

export interface CreateAssetDrawerProps extends DrawerAtomProps {
  options: CreateAssetDrawerOptionsModel;
  qrScannerOptions: QrScannerOptionsModel;
  didScannerOptions: DidScannerOptionsModel;
  onOpenCreateClient: () => void;
}
