import { FC } from 'react';
import ModalAtom from 'components/atoms/ModalAtom';
import QRScanner from 'components/atoms/QRScanner';

interface Props {
  title: string;
  onCancel: () => void;
  onScanQr: (qrCode: string) => void;
  visible: boolean;
}

const AccessHotelKeyReaderQRModal: FC<Props> = ({
  title,
  onCancel,
  onScanQr,
  visible,
}) => {
  return (
    <ModalAtom
      className={'KeyReaderQRModal'}
      title={title}
      onCancel={onCancel}
      open={visible}
      destroyOnClose
    >
      <QRScanner onScan={onScanQr} />
    </ModalAtom>
  );
};

export default AccessHotelKeyReaderQRModal;
