import { FC } from 'react';
import { Switch } from 'antd';
import Paper from 'components/atoms/Paper';
import { FormInstance } from 'antd/es/form/Form';
import LegalDocumentationForm from 'components/molecules/LegalDocumentationForm';
import { CustomLegalDocumentationModel, LegalDocumentationContentTranslationsModel, LegalDocumentationFormValues } from 'components/pages/ClientDetailPage/TabLegalDocumentation/resources/config';
import './styles.scss';

interface Props {
  contentTranslations: LegalDocumentationContentTranslationsModel;
  form: FormInstance<LegalDocumentationFormValues>;
  acceptedFormatFiles: string;
  legalDocumentation: CustomLegalDocumentationModel
  onEnable: () => void;
  onDisable: () => void;
  enabledSwitch: boolean;
}

const LegalDocumentationDisabledContentTemplate: FC<Props> = ({
  form,
  contentTranslations,
  acceptedFormatFiles,
  legalDocumentation,
  onEnable,
  onDisable,
  enabledSwitch,
}) => {
  const { title } = contentTranslations;
  const { termsOfServiceName, privacyPolicyName } = legalDocumentation;

  return (
    <Paper>
      <div className={'LegalDocumentationDisabledContentTemplate__header'}>
        <h2>{title}</h2>
        <Switch checked={enabledSwitch} onChange={!!enabledSwitch ? onDisable : onEnable} loading={false} />
      </div>
      <div className={'LegalDocumentationDisabledContentTemplate__form'}>
        <LegalDocumentationForm
          contentTranslations={contentTranslations}
          form={form}
          acceptedTypes={acceptedFormatFiles}
          termsOfServiceName={termsOfServiceName}
          privacyPolicyName={privacyPolicyName}
          onSave={() => { }}
          onClose={() => { }}
          onDelete={() => { }}
          onChange={() => { }}
          onChangeAction={() => { }}
          onCheckBeforeUpload={() => { }}
          processing={false}
          disabledTemplate={true}
          termsOfServiceUploadButtonDisabled={true}
          termsOfServiceUploadInfoVisible={true}
          termsOfServiceShowButtonVisible={true}
          termsOfServiceInfoDisabled={true}
          privacyPolicyUploadButtonDisabled={true}
          privacyPolicyUploadInfoVisible={true}
          privacyPolicyShowButtonVisible={true}
          privacyPolicyInfoDisabled={true}
          saveButtonDisabled={true}
          cancelButtonDisabled={true}
          deleteButtonVisible={true}
          deleteButtonDisabled={true}
        />
      </div>
    </Paper>
  );
}

export default LegalDocumentationDisabledContentTemplate;