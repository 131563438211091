import { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Image } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import { IMAGES } from 'constants/images';
import AssignGatewayModal from 'components/molecules/AssignGatewayModal';
import FormAtom from 'components/atoms/FormAtom';
import ModalQRScanner from 'components/pages/ProvisionedPage/ModalQRScanner';

const FORM_ID = 'assignGateway_form';
const ONLY_NUMBERS_AND_CAPITAL_LETTERS_VALUES_CONTROLLER = /^[A-Z0-9]+$/;

const trimID = (qr: string, indexQueryString: number) => {
  return qr.slice(indexQueryString + 1, qr.length);
};

export interface AssignGatewayFormValues {
  gatewayQid: string;
  assetId: string;
}

interface AssignGatewayActionProps {
  assetId: string;
  onFinish: (values: AssignGatewayFormValues) => void;
  onCancel: () => void;
  isShown: boolean;
}

const AssignGatewayAction: FC<AssignGatewayActionProps> = ({
  assetId,
  onFinish,
  isShown,
  onCancel
}) => {
  const { t } = useTranslation();
  const [isModalQRScannerVisible, setIsModalQRScannerVisible] = useState<boolean>(false);
  const [isModalQRScannerButtonDisable, setIsModalQRScannerButtonDisable] = useState<boolean>(true);
  const [form] = Form.useForm();

  const handleOnScanQr = (qr: string) => {
    const indexQueryString = qr.indexOf('=');

    if (indexQueryString === -1) form.setFieldsValue({ gatewayQid: qr });
    if (indexQueryString !== -1) {
      const _qr = trimID(qr, indexQueryString);
      form.setFieldsValue({ gatewayQid: _qr });
    }
    setIsModalQRScannerVisible(false);
    setIsModalQRScannerButtonDisable(false);
  };

  const handleOnFinish = (values: AssignGatewayFormValues) => {
    onFinish(values);
    form.resetFields();
    setIsModalQRScannerButtonDisable(true);
  };

  const handleOnCancel = () => {
    onCancel();
    form.resetFields();
    setIsModalQRScannerButtonDisable(true);
  }

  const handleOnChangeQr = (value: string) => {
    const isQIDValueCorrect = ONLY_NUMBERS_AND_CAPITAL_LETTERS_VALUES_CONTROLLER.test(value);
    setIsModalQRScannerButtonDisable(!isQIDValueCorrect);
  }

  useEffect(() => {
    isShown && form.setFieldsValue({ assetId });
  }, [isShown, assetId, form])

  return (
    <>
      <AssignGatewayModal formId={FORM_ID} visible={isShown} onCancel={handleOnCancel} disabled={isModalQRScannerButtonDisable}>
        <FormAtom onFinish={handleOnFinish} id={FORM_ID} layout={'vertical'} form={form}>
          <Form.Item
            name='gatewayQid'
            label={`${t('enter_the')} QID:`}
            rules={[
              { required: true, message: t('field_required') },
              { pattern: ONLY_NUMBERS_AND_CAPITAL_LETTERS_VALUES_CONTROLLER, message: t('_VALIDATE_QID_VALUE') }
            ]}
            tooltip={<Image src={IMAGES.qid} preview={false} />}
          >
            <Input
              allowClear
              placeholder='Q000000000000'
              addonAfter={<QrcodeOutlined onClick={() => setIsModalQRScannerVisible(true)} />}
              onChange={(value) => handleOnChangeQr(value.target.value)}
            />
          </Form.Item>
          <Form.Item name='assetId' label={'DID:'} >
            <Input disabled />
          </Form.Item>
        </FormAtom>
      </AssignGatewayModal>
      <ModalQRScanner
        title={`${t('scan')} QID`}
        onCancel={() => setIsModalQRScannerVisible(false)}
        onScan={(qr) => handleOnScanQr(qr)}
        visible={isModalQRScannerVisible}
      />
    </>
  );
};

export default AssignGatewayAction;
