import { FC } from 'react';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { Icon } from 'components/atoms/Icons';
import ModalAtom, { ModalAtomProps } from 'components/atoms/ModalAtom';
import './styles.scss';

export interface CreateAssetConfirmationLiteralsModel {
  text: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}

interface CreateAssetConfirmationModalProps extends ModalAtomProps {
  literals: CreateAssetConfirmationLiteralsModel,
}

const CreateAssetConfirmationModal: FC<CreateAssetConfirmationModalProps> = ({ onCancel, onOk, literals, ...props }) => {
  const { text, primaryButtonText, secondaryButtonText } = literals;

  return (
    <ModalAtom
      {...props}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <DefaultButton key={'back'} onClick={onCancel} title={secondaryButtonText} />,
        <PrimaryButton key={'submit'} onClick={onOk} title={primaryButtonText} />
      ]}
    >
      <div className={'CreateAssetConfirmationModal'}>
        <Icon.Check className='CreateAssetConfirmationModal__icon' />
        <span>{text}</span>
      </div>
    </ModalAtom>
  );
};

export default CreateAssetConfirmationModal;
