import { useTranslation } from 'react-i18next';
import { FilePdfOutlined } from '@ant-design/icons';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { DocumentModel } from 'core/domain/documents/model/documentsEntityModels';
import { TableActionsMenuProps } from 'components/atoms/TableAtom';
import DocumentsTableActionsManager, { SourceType } from './DocumentsTableActionsManager';

interface DocumentsColumnsParams {
  source: SourceType;
  wrongSourceText: string;
  onFormat: (document: { bytes: number; decimals?: number }) => string;
  documentTableActionsMenu: TableActionsMenuProps[];

}

export const DocumentsColumns = ({ source, wrongSourceText, onFormat, documentTableActionsMenu }: DocumentsColumnsParams): AlfredTableColumn<DocumentModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('name'),
      key: 'title',
      dataIndex: 'title',
      width: '50%',
      editable: true,
      render: (text, { title }) => (
        <span>
          <FilePdfOutlined /> {title}
        </span>
      ),
    },
    {
      title: t('size'),
      key: 'size',
      align: 'right',
      width: '35%',
      render: (text, { size }) => {
        const formatSyze = onFormat({ bytes: size });
        return <span>{formatSyze}</span>;
      },
    },
    {
      title: t('actions'),
      dataIndex: 'actions',
      align: 'right',
      width: '15%',
      render: (text, document) => {
        return (
          <div key={document.id} onClick={(event) => event.stopPropagation()}>
            <DocumentsTableActionsManager
              id={document.id}
              documentSource={document.source as SourceType}
              menu={documentTableActionsMenu}
              source={source}
              wrongSourceText={wrongSourceText}
            />
          </div>
        );
      },
    },
  ];
};
