import { FC } from 'react';
import { Form, FormProps } from 'antd';
import AssetFormCreateEditInputs, { TypeForm } from './AssetFormCreateEditInputs';

interface AssetEditFormProps extends FormProps {
  assetTypesList: string[];
  isBlocked?: boolean;
  onBlocked?: (isBlocked: boolean) => void;
}

const AssetEditForm: FC<AssetEditFormProps> = ({
  assetTypesList,
  isBlocked = false,
  onBlocked,
  ...props
}) => {
  return (
    <Form {...props}>
      <AssetFormCreateEditInputs
        type={TypeForm.edit}
        assetTypesList={assetTypesList}
        isBlocked={isBlocked}
        onBlocked={onBlocked}
      />
    </Form>
  );
};

export default AssetEditForm;
