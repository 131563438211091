import { FC } from 'react';
import { CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import { FormValuesResponse } from 'components/molecules/CommonAreaForm/models';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import CommonAreaContentTemplate from 'components/template/CommonAreaContentTemplate';
import CommonAreaFormUpdateTemplate from 'components/template/CommonAreaFormUpdateTemplate';
import CommonAreaFormCreateTemplate from 'components/template/CommonAreaFormCreateTemplate';
import CommonAreaContentEmptyTemplate from 'components/template/CommonAreaContentEmptyTemplate';
import CommonAreaBookingsEmptyTemplate from 'components/template/CommonAreaBookingsEmptyTemplate';
import BookingListTemplate from 'components/template/BookingListTemplate';
import { BookingModel } from 'core/domain/bookingsAndCommonAreas/models/bookingEntityModels';
import { CommonAreasAndBookingsVisorViews } from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabCommonAreasAndBookings/hook/useCommonAreasAndBookings';

interface CommonAreaVisorManagerProps {
  bookingListData: BookingModel[];
  commonAreaData: CommonAreaModel;
  sensorOptionList: SensorByProjectModel[];
  visibleType: CommonAreasAndBookingsVisorViews;
  onEnableArea: (checked: boolean) => void;
  onCreateArea: (values: FormValuesResponse) => void;
  onInvisibleCreate: () => void;
  onInvisibleUpdate: () => void;
  onRemoveArea: (id: string) => void;
  onUpdateArea: ({ id, values }: { id: string; values: FormValuesResponse }) => void;
  onVisibleCreate: () => void;
  onVisibleUpdate: () => void;
  onDeleteBooking: (bookingId: string) => void;
  isSaving: boolean;
}

const visorContentManager = {
  [CommonAreasAndBookingsVisorViews.CONTENT_BOOKINGS]: BookingListTemplate,
  [CommonAreasAndBookingsVisorViews.EMPTY_BOOKINGS]: CommonAreaBookingsEmptyTemplate,
  [CommonAreasAndBookingsVisorViews.CONTENT]: CommonAreaContentTemplate,
  [CommonAreasAndBookingsVisorViews.CREATE]: CommonAreaFormCreateTemplate,
  [CommonAreasAndBookingsVisorViews.EMPTY]: CommonAreaContentEmptyTemplate,
  [CommonAreasAndBookingsVisorViews.UPDATE]: CommonAreaFormUpdateTemplate,
};

const CommonAreasAndBookingsVisorManager: FC<CommonAreaVisorManagerProps> = ({ visibleType, ...props }) => {
  const Component = visorContentManager[visibleType];

  return <Component {...props} />;
};

export default CommonAreasAndBookingsVisorManager;
