import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';
import { AccessDeviceModel } from 'core/domain/access/models';
import { GetAccessDevicesListByProjectId } from 'core/domain/access/repository/getAccessDevicesListByProjectId';
import { useMessage } from 'hooks/useMessage';
import { HotelAccessDevicesListColumns, HotelAccessDevicesListColumnsTranslationsModel } from './AccessDevicesListColumns';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { getHotelAccessDevicesListWithPaginationPath } from 'components/pages/App/routes/access/config';

const DEFAULT_TOTAL_PAGES_VALUE = 1;

export const useHotelAccessDevicesList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [accessDevicesList, setAccessDevicesList] = useState<AccessDeviceModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(DEFAULT_TOTAL_PAGES_VALUE);
  const [pageCurrent, setPageCurrent] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState(Number(TABLE_PAGINATION_SIZE_DEFAULT));

  const columnsTranslations: HotelAccessDevicesListColumnsTranslationsModel = {
    nameTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_NAME_TITLE'),
    roomTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_ROOM_TITLE'),
    areaTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_AREA_TITLE'),
    batteryTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_BATTERY_LEVEL_TITLE'),
  };

  const getAccessDevicesList = async ({ page, size }: { page: number; size: number }) => {
    try {
      const { data, meta } = await GetAccessDevicesListByProjectId({ host, token, projectId, page, size });
      setAccessDevicesList(data);
      setTotalPages(meta.totalPages);
    } catch (error) {
      setMessageError({ description: t('_ACCESS_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    setPageCurrent(Number(newPage));
    setPageSize(Number(newSize));
  }, [page, size]);

  const onChangePage = (page: number, pageSize?: number) => {
    setPageCurrent(page);
    setPageSize(pageSize!);
    history.push(getHotelAccessDevicesListWithPaginationPath({ clientId, projectId, page: String(page), size: String(pageSize!) }));
  };

  useEffect(() => {
    setLoading(true);
    getAccessDevicesList({
      page: Number(page) || Number(TABLE_PAGINATION_PAGE_DEFAULT),
      size: Number(size) || Number(TABLE_PAGINATION_SIZE_DEFAULT),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  const columns = HotelAccessDevicesListColumns({ translations: columnsTranslations });

  return {
    accessDevicesList,
    pageCurrent,
    pageSize,
    totalPages,
    columns,
    onChangePage,
    loadingAccessDevices: loading,
  };
};
