import { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { RouteConfig, renderRoutes } from 'react-router-config';
import { useAccessesClientAndProjectSelector } from './resources/useAccessesClientAndProjectSelector';
import AccessesClientAndProjectSelectorTemplate from 'components/template/AccessesClientAndProjectSelectorTemplate';
import { HotelAccessesTabType } from './resources/utils';
import AccessesTabsTitles from './Tabs/TabTitles';
import './styles.scss';

interface PrivatePageProps extends RouteComponentProps {
  route?: RouteConfig;
}

const AccessesPage: FC<PrivatePageProps> = ({ route }) => {
  const {
    title,
    currentClient,
    currentProject,
    projectType,
    clientPlaceholderText,
    projectPlaceholderText,
    emptyText,
    clientsList,
    projectsList,
    selectedHotelTab,
    selectedOfficeTab,
    tabsTranslations,
    onSelectClient,
    onSelectProject,
    onSelectTab,
    loadingClientsList,
    loadingProjectsList,
    showEmptyMessage,
    tabsVisible,
  } = useAccessesClientAndProjectSelector();

  return (
    <AccessesClientAndProjectSelectorTemplate
      title={title}
      clientId={currentClient}
      projectId={currentProject}
      clientPlaceholderText={clientPlaceholderText}
      clientListOptions={clientsList}
      onSelectClient={onSelectClient}
      emptyText={emptyText}
      loadingClientsList={loadingClientsList}
      projectPlaceholderText={projectPlaceholderText}
      projectListOptions={projectsList}
      onSelectProject={onSelectProject}
      loadingProjectsList={loadingProjectsList}
      showEmptyMessage={showEmptyMessage}
      tabTitles={(
        tabsVisible
          ? (
            <AccessesTabsTitles
              translations={tabsTranslations}
              onChange={(tab: string) => onSelectTab(tab as HotelAccessesTabType)}
              projectType={projectType}
              activeHotelKey={selectedHotelTab}
              activeOfficeKey={selectedOfficeTab}
            />
          )
          : (
            <div className={'AccessesClientAndProjectSelectorPage__headerTabs'} />
          )
      )}
    >
      {renderRoutes(route?.routes)}
    </AccessesClientAndProjectSelectorTemplate>
  )
}

export default AccessesPage;