import { FC } from 'react';
import { Button, Input } from 'antd';
import { PasswordBooleanType, PasswordConfirmation, PasswordType, TogglePassword } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/utils';
import './styles.scss';

interface Props {
  password: string;
  sensorId: string;
  label: PasswordType;
  passwordText: string;
  buttonShowText: string;
  buttonHideText: string;
  onTogglePassword: ({ label, sensorId, type }: TogglePassword) => void;
  onChangePassword?: ({ label, sensorId, value }: PasswordConfirmation) => void;
  isVisible: boolean;
  isError: boolean;
  isDisabled: boolean;
}

const AccessPasswordDrawerEditableForm: FC<Props> = ({
  password,
  sensorId,
  label,
  passwordText,
  buttonShowText,
  buttonHideText,
  onTogglePassword,
  onChangePassword,
  isVisible,
  isError,
  isDisabled,
}) => {
  return (
    <div className={'AccessPasswordDrawerEditableForm__content'}>
      <div className={'AccessPasswordDrawerEditableForm__input-wrapper'}>
        <p className={'AccessPasswordDrawerEditableForm__input-label'}>{`${passwordText} ${sensorId}`}</p>
        <div className={'AccessPasswordDrawerEditableForm__input-content'}>
          {isVisible && (
            <Input
              className={'AccessPasswordDrawerEditableForm__input'}
              defaultValue={password}
              status={isError ? 'error' : ''}
              disabled={isDisabled}
              onChange={(value) => onChangePassword && onChangePassword({ label, sensorId, value: value.target.value })}
            />
          )}
          {!isVisible && (
            <Input.Password
              className={'AccessPasswordDrawerEditableForm__input'}
              defaultValue={password}
              disabled={isDisabled}
              visibilityToggle={false}
              status={isError ? 'error' : ''}
              onChange={(value) => onChangePassword && onChangePassword({ label, sensorId, value: value.target.value })}
            />
          )}
        </div>
      </div>
      <Button className={'AccessPasswordDrawerEditableForm__show-button'} onClick={() => onTogglePassword({ label, sensorId, type: PasswordBooleanType.VISIBILITY })}>
        {isVisible ? buttonHideText : buttonShowText}
      </Button>
    </div>
  );
}

export default AccessPasswordDrawerEditableForm;