import { FC } from 'react';
import { Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { SensorEntityModel } from 'core/domain/sensors/models/sensorEntityModels';

interface SelectSensorProps {
  onChange: (value: SensorEntityModel) => void;
  sensorList: SensorEntityModel[];
}

const SelectSensor: FC<SelectSensorProps> = ({ sensorList, onChange }) => {
  const { t } = useTranslation();

  const handleOnChange = (id: string) => {
    const selectedSensor = sensorList.find((element) => id === element.id);
    selectedSensor && onChange(selectedSensor);
  };

  return (
    <Space className='TabConsumption__buttons'>
      {sensorList.length ? (
        <Select
          showSearch
          className='TabConsumption__select-sensor'
          dropdownStyle={{ width: '400px !important' }}
          onChange={handleOnChange}
          placeholder={t('SELECT_A_SENSOR')}
          filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
        >
          {sensorList.map(
            ({ name, id, canDataBeFetched }) =>
              canDataBeFetched && (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              )
          )}
        </Select>
      ) : (
        <span>{t('there_are_not_sensors')}</span>
      )}
    </Space>
  );
};

export default SelectSensor;
