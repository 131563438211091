import { CreateCommonAreaModel } from '../models/commonAreaEntityModels';
import { PayloadCreateCommonAreaModel } from '../models/payloadCommonAreaModels';
import { commonAreaTimeAvailabilityToPayload } from './commonAreaTimeAvailabilityToPayload';

export const createCommonAreaToPayload = ({
  name,
  capacity,
  bookingDuration,
  backgroundImage,
  sensors,
  daysAvailability,
  timeAvailability,
  infoUserTitle,
  infoUserDescription,
  maximumBookingsByUser,
  maximumTimeAvailability,
}: CreateCommonAreaModel): PayloadCreateCommonAreaModel => {
  const parsedDaysAvailability = daysAvailability.join();
  const transformTimeAvailability = timeAvailability.map((time) => commonAreaTimeAvailabilityToPayload(time));

  return {
    name,
    capacity: capacity,
    booking_duration: bookingDuration,
    background_image: backgroundImage || null,
    sensor_ids: sensors,
    days_availability: parsedDaysAvailability,
    time_availability: transformTimeAvailability,
    info_user_title: infoUserTitle || null,
    info_user_description: infoUserDescription || null,
    maximum_bookings_by_user: maximumBookingsByUser,
    maximum_time_availability: {
      time_period: maximumTimeAvailability.timePeriod,
      value: maximumTimeAvailability.value,
    },
  };
};
