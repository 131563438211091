import { ThirdPartyServicesByProject } from '../model/thirdPartyServicesByProject';
import { ThirdPartyServicesByProjectUnparsed } from '../model/thirdPartyServicesByProjectUnparsed';

export const payloadToThirdPartyServicesByProject = ({
  id,
  title,
  description,
  link,
}: ThirdPartyServicesByProjectUnparsed): ThirdPartyServicesByProject => {
  return {
    id: id ?? '',
    title: title ?? '',
    description: description ?? '',
    link: link ?? '',
  };
};
