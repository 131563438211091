import { SupportTicketsResponseModel } from '../models/supportTicketsModel';

export const SupportTicketsListResponseEntity = ({
  data,
  meta,
  links,
  totalValues,
}: SupportTicketsResponseModel): Readonly<SupportTicketsResponseModel> => {
  return Object.freeze({
    data,
    meta,
    links,
    totalValues,
  });
};
