import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnAssignGatewayToAssetModalTranslations } from 'components/organisms/UnassignGatewayToAssetModal';
import { useMessage } from 'hooks/useMessage';
import { UnassignGateway } from 'core/domain/gateways/repositories/unassignGateway';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { UnAssignToAssetModalOptions } from './utils';

export const useUnAssignToAsset = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [unAssigning, setUnAssigning] = useState<boolean>(false);
  const [successUnAssigningGateway, setSuccessUnAssigningGateway] = useState<boolean>(false);
  const [selectedGateway, setSelectedGateway] = useState<string | null>(null);
  const [selectedAsset, setSelectedAsset] = useState<string | null>(null);

  const translations: UnAssignGatewayToAssetModalTranslations = {
    title: t('_GATEWAYS_LIST_ASSIGN_TO_ASSET'),
    description: t('_GATEWAYS_LIST_UN_ASSIGN_MODAL_DESCRIPTION'),
    mainButtonText: t('_GATEWAYS_LIST_UN_ASSIGN_MODAL_MAIN_BUTTON_TEXT'),
    secondaryButtonText: t('to_cancel'),
  };

  const onOpenUnassignToAssetModal = ({ gatewayId, assetId }: { gatewayId: string; assetId: string }) => {
    setModalVisible(true);
    setSelectedGateway(gatewayId);
    setSelectedAsset(assetId);
  };

  const onCloseUnAssignToAssetModal = () => {
    setModalVisible(false);
    setSuccessUnAssigningGateway(false);
    setSelectedGateway(null);
    setSelectedAsset(null);
  };

  const onUnAssignGatewayToAsset = async () => {
    setUnAssigning(true);
    try {
      !!selectedGateway && !!selectedAsset && (await UnassignGateway({ host, token, gatewayId: selectedGateway, assetId: selectedAsset }));
      setSuccessUnAssigningGateway(true);
      setMessageSuccess({ description: t('_GATEWAYS_LIST_UN_ASSIGN_MODAL_SUCCESS') });
      onCloseUnAssignToAssetModal();
    } catch (error) {
      setMessageError({ description: t('_GATEWAYS_LIST_UN_ASSIGN_MODAL_ERROR') });
    } finally {
      setUnAssigning(false);
    }
  };

  const unAssigningToAssetModalOptions: UnAssignToAssetModalOptions = {
    unAssignGatewayToAssetModalTranslations: translations,
    onCloseUnAssignToAssetModal,
    onUnAssignGatewayToAsset,
    unAssignToAssetModalVisible: modalVisible,
    unAssigning,
  };

  return {
    unAssigningToAssetModalOptions,
    onOpenUnassignToAssetModal,
    successUnAssigningGateway,
  };
};
