import { FirstLevelHorizontalArea } from "../model/projectLayoutConfigModels";
import { PayloadFirstLevelHorizontalArea } from "../model/projectLayoutConfigPayloadModels";

export const payloadToFistLevelHorizontalArea = ({ 
  id, 
  type, 
  image_url, 
  name_en, 
  name_es, 
  project_id, 
}: PayloadFirstLevelHorizontalArea): FirstLevelHorizontalArea => {  
    return {
      id: id ?? '',
      type: type,
      imageURL: image_url ?? '',
      nameEn: name_en ?? '',
      nameEs: name_es ?? '',
      projectId: project_id ?? '',
    }
};