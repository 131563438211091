import { payloadToGatewayDevicesList } from 'core/domain/gateways/mappers';
import { AssetDeviceGatewayModel, AssetDeviceGatewayPayload, AssetDevicesModel, AssetDevicesPayload } from '../models';

const payloadToAssetDeviceGateway = ({ devices, uuid }: AssetDeviceGatewayPayload): AssetDeviceGatewayModel => {
  const transformedDevices = payloadToGatewayDevicesList(devices);
  return {
    devices: transformedDevices,
    uuid: uuid ?? '',
  };
};

export const payloadToAssetDevices = ({ gateways }: AssetDevicesPayload): AssetDevicesModel => {
  const transformedGateways = gateways.map((gateway) => payloadToAssetDeviceGateway(gateway));

  return { gateways: transformedGateways };
};
