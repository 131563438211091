import { LoginModel, LoginPayload } from '../models';

export const payloadToLogin = ({ session }: LoginPayload): LoginModel => {
  return {
    expiresAt: session.expires_at ?? '',
    permissions: session.permissions ?? [],
    token: session.token ?? '',
    tokenType: session.token_type ?? '',
    userType: session.user_type ?? '',
  };
};
