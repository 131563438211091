import { FC, useState } from 'react';
import { Button, Col, Form, FormInstance, Input, Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { MaintenanceAction } from 'core/domain/gateways/model/reportMaintenance';

export interface MaintenanceActionsFormValues {
  action: MaintenanceAction;
  comments: string;
  batteryVoltage?: string;
  batteryVendor?: string;
}

interface MaintenanceActionsFormProps {
  form: FormInstance;
  availableActions: MaintenanceAction[];
  onFinish: (values: MaintenanceActionsFormValues) => void;
  onCancel: () => void;
  loading: boolean;
}

const COMMENT_MAX_LENGTH = 500;

const MaintenanceActionsForm: FC<MaintenanceActionsFormProps> = ({ form, availableActions, onFinish, onCancel, loading }) => {
  const [selectedAction, setSelectedAction] = useState<string>('');
  const { t } = useTranslation();

  const handleChangeSelectedAction = (value: string) => {
    setSelectedAction(value);
  };

  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'}>
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item name={'action'} rules={[{ required: true, message: t('field_required'), type: 'string' }]} label={t('add_action')}>
            <Select placeholder={`${t('select')}`} onChange={handleChangeSelectedAction} allowClear>
              {availableActions.map((actionType, key) => (
                <Select.Option key={key} value={actionType}>
                  {t(`AVAILABLE_ACTIONS.${actionType}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {selectedAction === 'battery_replacement' && (
        <Row justify={'space-between'} gutter={12}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={t('battery_voltage')}
              name={'batteryVoltage'}
              rules={[{ required: true, message: t('field_required'), type: 'string' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={t('battery_vendor')}
              name={'batteryVendor'}
              rules={[{ required: true, message: t('field_required'), type: 'string' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Form.Item name={'comments'} label={t('comments')}>
            <Input.TextArea allowClear autoSize={{ minRows: 6, maxRows: 6 }} maxLength={COMMENT_MAX_LENGTH} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={'end'}>
        <Space>
          <Button key={'back'} onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button key={'submit'} type={'primary'} loading={loading} htmlType={'submit'}>
            {t('add')}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default MaintenanceActionsForm;
