import { FC } from 'react';
import { List, Avatar, Space, Tag, Menu, Typography } from 'antd';
import { CalendarOutlined, ArrowRightOutlined, KeyOutlined, UserOutlined, FieldTimeOutlined, AppstoreOutlined } from '@ant-design/icons';
import { CustomInvitationModel, InvitationsListItemTranslations, RevokeInvitationModel } from '../resources/utils';
import SpaceAtom from 'components/atoms/SpaceAtom';
import ContextualMenu from 'components/atoms/ContextualMenu';
import './styles.scss';

interface InvitationItemProps {
  invitation: CustomInvitationModel;
  translations: InvitationsListItemTranslations;
  onRevoke: ({ invitationId, isNewInvitation }: RevokeInvitationModel) => Promise<void>;
}

const InvitationItem: FC<InvitationItemProps> = ({ invitation, translations, onRevoke }) => {
  const {
    revokedAt,
    validUntilText,
    validFromText,
    uid,
    alias,
    url,
    createdAtText,
    createdBy,
    devices,
    isRevoked,
    isExpired,
    isNewInvitation,
  } = invitation;
  const {
    accessesTitle,
    createdAtTitle,
    createdByTitle,
    expiredStateText,
    invitationStateText,
    notReportedText,
    revokedStateText,
    revokeText,
    validityTitle,
  } = translations;

  return (
    <List.Item
      key={alias}
      extra={
        <SpaceAtom>
          {isRevoked && <Tag color={'volcano'} className={'Text__secondary'}>{revokedStateText}</Tag>}
          {isExpired && <Tag color={'orange'} className={'Text__secondary'}>{expiredStateText}</Tag>}
          {!isRevoked && !isExpired && <Typography.Paragraph type={'secondary'} copyable={{ text: url }}>{invitationStateText}</Typography.Paragraph>}
          <ContextualMenu
            menu={
              <Menu>
                <Menu.Item disabled={isExpired || isRevoked} key={'revoke'} onClick={() => onRevoke({ invitationId: uid, isNewInvitation })}>
                  {revokeText}
                </Menu.Item>
              </Menu>
            } />
        </SpaceAtom>
      }
    >
      <List.Item.Meta
        title={<b>{alias}</b>}
        avatar={
          <Avatar
            size={'small'}
            className={!revokedAt && !isExpired ? 'InvitationItem__icon' : 'InvitationItem__icon--no-active'}
            icon={<KeyOutlined rotate={180} />}
          />
        }
        description={
          <Space direction={'vertical'}>
            <Space>
              <UserOutlined />
              <b>{createdByTitle}:</b>
              <span className='Text'>{createdBy}</span>
            </Space>
            <Space>
              <FieldTimeOutlined />
              <b>{createdAtTitle}:</b>
              <span className='Text'>{createdAtText}</span>
            </Space>
            <Space direction='horizontal'>
              <CalendarOutlined />
              <b>{`${validityTitle}:`}</b>
            </Space>
            <Space direction='horizontal'>
              <span className='Text'>{validFromText}</span>
              <ArrowRightOutlined />
              <span className='Text'>{validUntilText}</span>
            </Space>
            <Space direction='horizontal'>
              <AppstoreOutlined />
              <b>{`${accessesTitle}:`}</b>
            </Space>
            <div className='InvitationItem__list-access'>
              {devices.map(({ label }, key) =>
                label.trim().length
                  ? <Tag key={key} color={'gold'}>{label}</Tag>
                  : <Tag key={key} color={'gray'}>{notReportedText}</Tag>
              )}
            </div>
          </Space>
        }
      />
    </List.Item>
  );
};

export default InvitationItem;
