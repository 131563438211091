import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { TenantsPreviewDataResponseModel } from 'core/domain/users/models/userModel';
import './styles.scss';
import { ColumnsTranslations } from '../useUploadCsv';

export const TenantsPreviewColumns = (translations: ColumnsTranslations): AlfredTableColumn<TenantsPreviewDataResponseModel>[] => {
  const { nameText, emailText, addressText, assetIdText } = translations;

  return [
    {
      title: nameText,
      key: 'name',
      width: '25%',
      render: (text, { name: { label, error } }) => {
        return (<p className={`TenantsPreviewColumns__text ${error && 'TenantsPreviewColumns__error'}`}>{label}</p>);
      },
    },
    {
      title: emailText,
      key: 'email',
      width: '20%',
      render: (text, { email: { label, error } }) => {
        return (<p className={`TenantsPreviewColumns__text ${error && 'TenantsPreviewColumns__error'}`}>{label}</p>);
      },
    },
    {
      title: addressText,
      key: 'address',
      width: '40%',
      render: (text, { address: { label, error } }) => {
        return (<p className={`TenantsPreviewColumns__text ${error && 'TenantsPreviewColumns__error'}`}>{label}</p>);
      },
    },
    {
      title: assetIdText,
      key: 'assetId',
      width: '15%',
      render: (text, { assetId: { label, error } }) => {
        return (<p className={`TenantsPreviewColumns__text ${error && 'TenantsPreviewColumns__error'}`}>{label}</p>);
      },
    },
  ];
};
