import api from 'services/api';
import { thirdPartyServicesListByProjectPath } from 'constants/endpointsPath/thirdPartyServices';
import { ThirdPartyServicesByProjectListValueObject } from '../entities/thirdPartyServicesByProjectListValueObject';
import { payloadToThirdPartyServicesByProject } from '../mappers/payloadToThirdPartyServicesByProject';
import { ThirdPartyServicesByProject } from '../model/thirdPartyServicesByProject';
import { ThirdPartyServicesByProjectUnparsed } from '../model/thirdPartyServicesByProjectUnparsed';

const GetThirdPartyServicesListByProject = async (projectId: string): Promise<Readonly<ThirdPartyServicesByProject>[]> => {
  const path = thirdPartyServicesListByProjectPath(projectId);
  const { data } = await api.get<ThirdPartyServicesByProjectUnparsed[]>(path);
  const dataParser = data ? data.map((item) => payloadToThirdPartyServicesByProject(item)) : [];
  const { list } = ThirdPartyServicesByProjectListValueObject(dataParser);
  return list;
};

export default GetThirdPartyServicesListByProject;
