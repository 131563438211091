import { Checkbox, Divider, Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import DangerTextButton from 'components/atoms/Button/DangerTextButton';
import ImagePickerDisplay from 'components/molecules/ImagePickerDisplay';
import TextArea from 'components/atoms/TextArea';
import { RequiredFieldsError } from '../../models';
import { CharactersMaxLength, EventsAndNewsFormValues } from '../../enums/form';
import { TRANSLATION } from '../../enums/translations';
import './styles.scss';
import { EventsAndNewsVisorViews } from 'components/pages/ProjectPage/Tabs/TabServices/Subtabs/SubtabEventsAndNews/enums/visorViews';
import { Icon } from 'components/atoms/Icons';

interface InputValue {
  label: EventsAndNewsFormValues;
  value: string;
}

interface Finish<TResponse> {
  id?: string;
  values: TResponse;
}

interface EventsAndNewsFormGridProps<TResponse> {
  errors: RequiredFieldsError;
  form: any;
  Form: any;
  id?: string;
  typeForm: EventsAndNewsVisorViews;
  tempImageUrl?: string;
  onChangedMade?: ({ label, value }: InputValue) => void;
  onClose: () => void;
  onFinish: ({ id, values }: Finish<TResponse>) => void;
  onRemoveArea?: (id: string) => void;
  isDisabledSaved?: boolean;
  isSaving: boolean;
}

const EventsAndNewsFormGrid = <TResponse extends unknown>({
  errors,
  form,
  Form,
  id,
  typeForm,
  tempImageUrl,
  onChangedMade,
  onClose,
  onFinish,
  onRemoveArea,
  isDisabledSaved,
  isSaving,
}: EventsAndNewsFormGridProps<TResponse>) => {
  const { t } = useTranslation();

  const handleOnChangeTitle = (value: string) => {
    onChangedMade?.({ label: EventsAndNewsFormValues.TITLE, value });
  };

  const handleOnChangeDescription = (value: string) => {
    onChangedMade?.({ label: EventsAndNewsFormValues.DESCRIPTION, value });
  };

  const handleOnChangeImage = (imageBase64: string) => {
    onChangedMade?.({ label: EventsAndNewsFormValues.IMAGE, value: imageBase64 });
  }

  const handleOnChangeLink = (value: string) => {
    onChangedMade?.({ label: EventsAndNewsFormValues.LINK, value });
  };

  const handleOnChangeLinkTitle = (value: string) => {
    onChangedMade?.({ label: EventsAndNewsFormValues.LINK_TITLE, value });
  };

  const handleOnChangeNotificationCheckbox = (value: string) => {
    onChangedMade?.({ label: EventsAndNewsFormValues.SEND_NOTIFICATION, value });
  };

  const classNameActions = `EventsAndNewsFormGrid__actions${onRemoveArea ? '--update' : '--create'}`

  return (
    <Form
      form={form}
      onFinish={(values: TResponse) => onFinish({ id, values })}
      layout={'vertical'}
      requiredMark={false}
    >
      <div className={'EventsAndNewsFormGrid__container'}>
        <Form.Item
          name={EventsAndNewsFormValues.TITLE}
          label={t(TRANSLATION.title)}
          validateStatus={errors.title ? 'error' : undefined}
          help={errors.title ? t(TRANSLATION.fieldRequired) : undefined}
          className={'EventsAndNewsFormGrid__title'}
        >
          <Input
            value={EventsAndNewsFormValues.TITLE}
            maxLength={CharactersMaxLength.TITLE}
            showCount
            disabled={isSaving}
            onChange={(event) => handleOnChangeTitle(event.target.value)}
          />
        </Form.Item>
        <span>
          {t(TRANSLATION.image)}
          <Tooltip title={t(TRANSLATION.tooltip)}>
            <Icon.InfoCircle />
          </Tooltip>
        </span>
        <Form.Item
          name={EventsAndNewsFormValues.IMAGE}
          validateStatus={errors.image ? 'error' : undefined}
          help={errors.image ? t(TRANSLATION.fieldRequired) : undefined}
        >
          <ImagePickerDisplay
            onChange={handleOnChangeImage}
            disabled={isSaving}
            temporaryImageUrl={tempImageUrl}
            sizeLimit={2}
            acceptedTypes={['jpg', 'jpeg', 'png']}
          />
        </Form.Item>
        <Form.Item
          name={EventsAndNewsFormValues.DESCRIPTION}
          label={t(TRANSLATION.description)}
          validateStatus={errors.description ? 'error' : undefined}
          help={errors.description ? t(TRANSLATION.fieldRequired) : undefined}
        >
          <TextArea
            value={EventsAndNewsFormValues.DESCRIPTION}
            maxLength={CharactersMaxLength.DESCRIPTION}
            showCount
            rows={5}
            disabled={isSaving}
            onChange={(event) => handleOnChangeDescription(event.target.value)}
          />
        </Form.Item>
        <div className={'EventsAndNewsFormGrid__links'}>
          <Form.Item
            name={EventsAndNewsFormValues.LINK}
            label={t(TRANSLATION.link)}
          >
            <Input
              placeholder={'https:// ...'}
              value={EventsAndNewsFormValues.LINK}
              className={'EventsAndNewsFormGrid__link'}
              disabled={isSaving}
              onChange={(event) => handleOnChangeLink(event.target.value)}
            />
          </Form.Item>
          <Form.Item
            name={EventsAndNewsFormValues.LINK_TITLE}
            label={t(TRANSLATION.linkTitle)}
          >
            <Input
              value={EventsAndNewsFormValues.LINK_TITLE}
              maxLength={CharactersMaxLength.LINK_TITLE}
              showCount
              disabled={isSaving}
              onChange={(event) => handleOnChangeLinkTitle(event.target.value)}
            />
          </Form.Item>
        </div>
        {typeForm === EventsAndNewsVisorViews.CREATE &&
          <div>
            <Form.Item name={EventsAndNewsFormValues.SEND_NOTIFICATION} valuePropName="checked">
              <Checkbox
                defaultChecked={false}
                onChange={(event) => handleOnChangeNotificationCheckbox(event.target.checked.toString())}
              >
                {t('_EVENTS_AND_NEWS_PUSH_NOTIFICATION')}
              </Checkbox>
            </Form.Item>
          </div>}
      </div>
      <Divider />
      <div className={`EventsAndNewsFormGrid__actions ${classNameActions}`}>
        {onRemoveArea && (
          <DangerTextButton
            title={t(TRANSLATION.delete)}
            onClick={() => id && onRemoveArea(id)}
            disabled={isSaving}
          />
        )}
        <div>
          <DefaultButton
            title={t(TRANSLATION.close)}
            onClick={onClose}
            className={'EventsAndNewsFormGrid__close-button'}
            disabled={isSaving}
          />
          {typeForm === EventsAndNewsVisorViews.CREATE ? (
            <PrimaryButton
              htmlType={'submit'}
              title={t(TRANSLATION.create)}
              loading={isSaving}
              disabled={isDisabledSaved || isSaving}
            />
          ) : (
            <PrimaryButton
              htmlType={'submit'}
              title={t(TRANSLATION.save)}
              loading={isSaving}
              disabled={isDisabledSaved || isSaving}
            />
          )}
        </div>
      </div>
    </Form >
  );
};

export default EventsAndNewsFormGrid;
