import { SensorByProjectModel } from '../models/sensorEntityModels';
import { SensorByProjectPayloadModel } from '../models/sensorPayloadModels';

export const payloadToSensorsByProject = ({ id, name, room, usage }: SensorByProjectPayloadModel): SensorByProjectModel => {
  return {
    id,
    name: name ?? '',
    room: room ?? '',
    usage: usage ?? '',
  };
};
