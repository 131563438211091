import { DeviceWithGatewayId } from 'models/devices.model';
import {
  CreateInvitationAttributesAssetDeviceModel,
  CreateInvitationAttributesAssetModel,
  CreateInvitationModel,
} from 'core/domain/anonymousAccess/models';
import { AccessesForm } from './index';
import { getSensorLabel } from '../../resources/utils';
import { AssetVerticalsEnum } from 'core/domain/assets/models';

export const createAccess = (
  assetId: string,
  { description, accesses, datePicker }: AccessesForm,
  locks?: DeviceWithGatewayId[]
): CreateInvitationModel => {
  const parsedAccess = accesses.map((access) => access.split('::::'));
  const selectedLocks = locks && locks.filter((lock) => parsedAccess.some((access) => access[0] === lock.device_id.toString()));

  const devices: CreateInvitationAttributesAssetDeviceModel[] = [];

  selectedLocks?.forEach((lock) => {
    const selectedParsedAccess = parsedAccess.filter((access) => access[0] === lock.device_id.toString());

    selectedParsedAccess.forEach((access) => {
      const selectedIndex = access[1];
      const selectedSensor = lock.sensors.find((sensor) => sensor.sensor_id === selectedIndex);
      const label = getSensorLabel({ name: selectedSensor?.name, room: selectedSensor?.room });

      const newLock: CreateInvitationAttributesAssetDeviceModel = {
        type: selectedSensor?.sensor_type || '',
        label,
        deviceId: lock.device_id,
        sensorId: selectedSensor?.sensor_id || '',
        gatewayId: lock.gatewayId,
      };

      devices.push(newLock);
    });
  });

  const assets: CreateInvitationAttributesAssetModel[] = [{ assetId, devices }];

  return {
    attributes: {
      alias: description,
      validFrom: datePicker[0].unix(),
      validUntil: datePicker[1].unix(),
      type: AssetVerticalsEnum.HOME,
      assets,
    },
  };
};
