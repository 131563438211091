export const tenantsTemplatePath = () => {
  return '/users/download-tenants-template';
};

export const assetsTemplatePath = () => {
  return '/users/download-assets-template ';
};

export const uploadTenantsTemplatePreviewPath = () => {
  return '/users/upload-tenants-template-preview';
};

export const uploadTenantsTemplatePath = () => {
  return '/users/upload-tenants-template';
};

export const updateLegalDocumentationStatusPath = () => {
  return `/users/legal-documentations/accepted`;
};
