import { FC } from 'react';

interface DefaultContentProps {
  values: any[];
}

export const DefaultContent: FC<DefaultContentProps> = ({ values }) => {
  return (
    <div>
      {values.map((value: any, key: number) => {
        return <div key={key}>{value}</div>;
      })}
    </div>
  );
};
