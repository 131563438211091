import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Typography, Space } from 'antd';
import { hasSuperSearchHistory } from 'services/superSearchHistory';
import Close from 'assets/svg/icon/Close';
import './styles.scss';

interface TitleBarProps {
  query: string;
  searching: boolean
}

const TitleBar: FC<TitleBarProps> = ({ query, searching }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnGoBack = () => {
    if (hasSuperSearchHistory() !== null) history.push(hasSuperSearchHistory());
  };

  return (
    <div className='TitleBar'>
      {searching ? (
        <Space className='TitleBar__title' size='small'>
          <Typography.Title level={3}>{`${t('searching')}:`}</Typography.Title>
          <p className='TitleBar__query'>{`"${query}"`}</p>
        </Space>
      ) : (
        <Typography.Title level={3}>{t('SUPER_SEARCH.init_title')}</Typography.Title>
      )}
      <div className={'TitleBar__icon'} onClick={handleOnGoBack}>
        <Close />
      </div>
    </div>
  );
};

export default TitleBar;
