import SkeletonAtom from 'components/atoms/SkeletonAtom';
import { FC } from 'react';
import './styles.scss';

const TabContentLoading: FC = () => {
  return (
    <div className={'TabContentLoading'}>
      <SkeletonAtom active />
      <SkeletonAtom active />
      <SkeletonAtom active />
    </div>
  );
};

export default TabContentLoading;
