import { ActiveGatewaysMonthStatisticsModel, ActiveGatewaysStatisticsModel } from '../model/gateway/gatewayParsed';
import { ActiveGatewaysMonthStatisticsPayload, ActiveGatewaysStatisticsPayloadModel } from '../model/gateway/gatewayUnparsed';

const payloadToActiveGatewaysMonthStatistics = ({
  label,
  value,
}: ActiveGatewaysMonthStatisticsPayload): ActiveGatewaysMonthStatisticsModel => {
  return {
    label: label ?? '',
    value: value ?? 0,
  };
};

export const payloadToActiveGatewaysStatistics = ({
  total_gateways_online,
  gateways,
}: ActiveGatewaysStatisticsPayloadModel): ActiveGatewaysStatisticsModel => {
  const transformedGateways = gateways.map((item) => payloadToActiveGatewaysMonthStatistics(item));

  return {
    totalGatewaysOnline: total_gateways_online ?? 0,
    gateways: transformedGateways ?? [],
  };
};
