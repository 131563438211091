import { FC } from 'react';
import { Icon } from 'components/atoms/Icons';
import './styles.scss';

interface Props {
  infoTitle: string;
  infoMinimumDigitsText: string;
  infoMaximumDigitsText: string;
  infoValidDigitsText: string;
  infoNfcTitle: string;
  infoNfcMinimumDigitsText: string;
  infoNfcMaximumDigitsText: string;
  infoNfcValidDigitsText: string;
  infoNfcExtraText: string;
  isNfcInfoVisible: boolean;
}

const ICON_SIZE = 24;

const AccessPasswordDrawerInfoAlert: FC<Props> = ({
  infoTitle,
  infoNfcTitle,
  infoMinimumDigitsText,
  infoNfcMinimumDigitsText,
  infoMaximumDigitsText,
  infoNfcMaximumDigitsText,
  infoValidDigitsText,
  infoNfcValidDigitsText,
  infoNfcExtraText,
  isNfcInfoVisible,
}) => {
  return (
    <div className={'AccessPasswordDrawerInfoAlert__info'}>
      <div className={'AccessPasswordDrawerInfoAlert__info-icon'}>
        <Icon.InfoCircleFilled style={{ fontSize: ICON_SIZE }} />
      </div>
      <div>
        <p className={'AccessPasswordDrawerInfoAlert__info-title'}>{infoTitle}</p>
        <p className={'AccessPasswordDrawerInfoAlert__info-text'}>{infoMinimumDigitsText}</p>
        <p className={'AccessPasswordDrawerInfoAlert__info-text'}>{infoMaximumDigitsText}</p>
        <p className={'AccessPasswordDrawerInfoAlert__info-text'}>{infoValidDigitsText}</p>
        {isNfcInfoVisible && (
          <>
            <p></p>
            <p className={'AccessPasswordDrawerInfoAlert__info-title'}>{infoNfcTitle}</p>
            <p className={'AccessPasswordDrawerInfoAlert__info-text'}>{infoNfcMinimumDigitsText}</p>
            <p className={'AccessPasswordDrawerInfoAlert__info-text'}>{infoNfcMaximumDigitsText}</p>
            <p className={'AccessPasswordDrawerInfoAlert__info-text'}>{infoNfcValidDigitsText}</p>
            <p className={'AccessPasswordDrawerInfoAlert__info-text'}>{infoNfcExtraText}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default AccessPasswordDrawerInfoAlert;