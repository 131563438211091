import { FC } from 'react';
import { Menu } from 'antd';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { ProjectContextualMenuTranslations } from 'components/pages/ProjectPage/resources/models';

interface Props {
  onClickEditButton: () => void;
  onClickNotifyButton: () => void;
  NotifyButtonEnabled: boolean;
  translations: ProjectContextualMenuTranslations;
}

const ProjectMenuExtra: FC<Props> = ({ onClickEditButton, onClickNotifyButton, NotifyButtonEnabled, translations }) => {
  return (
    <ContextualMenu placement={'bottomRight'} menu={
      <Menu>
        <Menu.Item key={'edit'} onClick={onClickEditButton}>
          {translations.editButton}
        </Menu.Item>
        {NotifyButtonEnabled && (
          <Menu.Item key={'notify'} onClick={onClickNotifyButton}>
            {translations.notifyButton}
          </Menu.Item>
        )}
      </Menu>
    } />
  );
}

export default ProjectMenuExtra;