import { FC } from 'react';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiDoor, mdiDoorClosed } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { Timeline } from 'antd';
import { AccessControl, LockType } from 'models/assets.model';
import { COLORS } from 'constants/colors';
import { DATE_TIME_FORMAT } from 'constants/date';
import UserAvatarIdentity from '../UserAvatarIdentity';
import './styles.scss';

interface EventItemProps {
  accessControl: AccessControl[];
}

const EventItem: FC<EventItemProps> = ({ accessControl }) => {
  const { t } = useTranslation();

  const getActionText = (lockTipe: LockType, action?: string) => {
    if (action && lockTipe === 'HOME_DOOR') {
      return t(`ACCESS_CONTROL.action.${action}`);
    }
    if (action && lockTipe === 'BUILDING_DOOR') {
      return t('ACCESS_CONTROL.action.OFF');
    }
    return t('not_reported');
  };

  const iconModifier = (lockType: LockType, action?: string) => {
    if (action && lockType === 'HOME_DOOR') {
      if (action === 'ON') return { color: COLORS.alert, type: mdiDoorClosed };
      if (action === 'OFF') return { color: COLORS.success, type: mdiDoor };
    }
    if (action && lockType === 'BUILDING_DOOR') {
      return { color: COLORS.success, type: mdiDoor };
    }
    return { color: COLORS.gray, type: mdiDoor };
  };

  return (
    <Timeline mode='left' className='EventItem'>
      {accessControl?.map(({ ts, action, usage_source, room, username, user_email, user_id, lock_type }) => {
        const { type, color } = iconModifier(lock_type, action);

        return (
          <Timeline.Item label={moment.unix(ts).format(DATE_TIME_FORMAT)} dot={<Icon size={1} path={type} color={color} />}>
            <UserAvatarIdentity email={user_email} userId={user_id} username={username} />
            <div>
              <b>{`${t('access')}:`}</b> {room || t('not_reported')}
            </div>
            <div>
              <b>{`${t('action')}:`}</b> {getActionText(lock_type, action)}
            </div>
            <div>
              <b>{`${t('usage_source')}:`}</b> {t(`ACCESS_CONTROL.usage_source.${usage_source}`)}
            </div>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

export default EventItem;
