import { BookingModel } from '../models/bookingEntityModels';
import BookingEntity from './bookingEntity';

const BookingListValueObject = (
  list: BookingModel[]
): Readonly<{
  list: Readonly<BookingModel>[];
}> => {
  return Object.freeze({
    list: list.map((item: BookingModel) => BookingEntity(item)),
  });
};

export default BookingListValueObject;
