import { FC } from 'react';
import { ClientProjectContextProvider } from './ClientProjectContext';
import { SharedGatewaysContextProvider } from './SharedGatewaysContext';
import { UserProfileContextProvider } from './UserProfileContext';

interface Props {
  children: React.ReactNode;
}
export const ContextProvider: FC<Props> = ({ children }) => {
  return (
    <UserProfileContextProvider>
      <ClientProjectContextProvider>
        <SharedGatewaysContextProvider>{children}</SharedGatewaysContextProvider>
      </ClientProjectContextProvider>
    </UserProfileContextProvider>
  );
};
