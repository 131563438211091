import { passwordSensorUpdatePath } from 'core/endspoints/sensors';
import { SensorPasswordUpdateModel } from '../models/sensorEntityModels';
import api from 'services/api';

export const UpdatePasswordSensor = (sensor: SensorPasswordUpdateModel) => {
  const { gatewayId, deviceId, sensorId, password } = sensor;
  const path = passwordSensorUpdatePath({ gatewayId, deviceId, sensorId });
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    return api.post(path, { data: { password } });
  } catch (error: any) {
    throw new Error(error);
  }
};
