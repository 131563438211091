import { FC } from 'react';
import { useTechSupportList } from 'hooks/useTechSupportList';
import AlfredTableTemplate from 'components/template/AlfredTableTemplate';
import KpisLoadingTemplate from 'components/template/KpisLoadingTemplate';
import KpisErrorTemplate from 'components/template/KpisErrorTemplate';
import Paper from 'components/atoms/Paper';
import { SupportTicketModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import KpisTemplate from 'components/template/KpisTemplate';
import './styles.scss';
import SupportTicketsListDownloadModal from 'components/organisms/SupportTicketDownloadModal';
import SpaceAtom from 'components/atoms/SpaceAtom';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { Menu } from 'antd';

const TechSupportsPage: FC = () => {
  const {
    form,
    supportTicketList,
    totalPages,
    totalValues,
    pageSize,
    currentPage,
    columns,
    openedSupportTicketsTranslation,
    acceptedSupportTicketsTranslation,
    inProgressSupportTicketsTranslation,
    lastWeekSupportTicketTranslation,
    lastMonthSupportTicketsTranslation,
    title,
    subtitle,
    downloadCsvButtonText,
    downloadModal,
    onChangePage,
    onSelectSupportTicket,
    onCancelDownloadSupportTicketsList,
    onShowDownloadSupportTicketsList,
    onSubmitDownloadSupportTicketsListForm,
    getIsDisabledFromDate,
    getIsDisabledUntilDate,
    loadingSupportTickets,
    errorSupportTickets,
    isLoadingDownloadCsv,
    isVisibleDownloadCsvModal,
  } = useTechSupportList();

  return (
    <>
      {loadingSupportTickets && <KpisLoadingTemplate />}
      {!loadingSupportTickets && errorSupportTickets && (
        <KpisErrorTemplate
          openedText={openedSupportTicketsTranslation}
          acceptedText={acceptedSupportTicketsTranslation}
          inProgressText={inProgressSupportTicketsTranslation}
          lastMonthCreatedText={lastMonthSupportTicketsTranslation}
          lastWeekCreatedText={lastWeekSupportTicketTranslation}
          title={title}
          subtitle={subtitle}
        />
      )}
      {!loadingSupportTickets && !errorSupportTickets && totalValues && (
        <KpisTemplate
          openedText={openedSupportTicketsTranslation}
          acceptedText={acceptedSupportTicketsTranslation}
          inProgressText={inProgressSupportTicketsTranslation}
          lastMonthCreatedText={lastMonthSupportTicketsTranslation}
          lastWeekCreatedText={lastWeekSupportTicketTranslation}
          title={title}
          subtitle={subtitle}
          totalValues={totalValues}
        />
      )}
      <div className={'TechSupportsPage__table-wrapper'}>
        <Paper>
          <AlfredTableTemplate<SupportTicketModel>
            actionSectionRight={
              <SpaceAtom>
                <ContextualMenu menu={
                  <Menu>
                    <Menu.Item
                      key={'download-tickets'}
                      onClick={onShowDownloadSupportTicketsList}
                      disabled={!supportTicketList.length}
                    >
                      {downloadCsvButtonText}
                    </Menu.Item>
                  </Menu>
                } />
              </SpaceAtom>
            }
            columns={columns}
            onRowClick={onSelectSupportTicket}
            data={supportTicketList}
            totalPages={totalPages}
            isCharging={loadingSupportTickets}
            pageSize={pageSize}
            pageCurrent={currentPage}
            onPagination={onChangePage}
          />
        </Paper>
      </div>
      <SupportTicketsListDownloadModal
        form={form}
        {...downloadModal}
        onCancel={onCancelDownloadSupportTicketsList}
        onFinish={onSubmitDownloadSupportTicketsListForm}
        getIsDisabledFromDate={getIsDisabledFromDate}
        getIsDisabledUntilDate={getIsDisabledUntilDate}
        isLoading={isLoadingDownloadCsv}
        isVisible={isVisibleDownloadCsvModal}
      />
    </>
  );
};

export default TechSupportsPage;
