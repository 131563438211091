import { FC, LegacyRef, useRef, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  FormProps,
  Input,
  Row,
  Select,
  Tooltip
} from 'antd';
import { useTranslation } from 'react-i18next';
import { AreaType } from 'core/domain/projectLayoutConfig/model/projectLayoutConfigModels';
import ImagePickerDisplay from 'components/molecules/ImagePickerDisplay';
import { Icon } from 'components/atoms/Icons';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TextAtom from 'components/atoms/TextAtom';
import { COLORS } from 'constants/colors';
import './styles.scss';

export interface ProjectConfigAreaFormValues {
  type: AreaType;
  nameEs: string;
  nameEn: string;
  imageURL: string;
  image: string;
  horizontal: {
    nameEs: string;
    nameEn: string;
    image: string;
  }[];
}

interface Props extends FormProps {
  areaTypeList: AreaType[];
  initialValues?: ProjectConfigAreaFormValues;
}

export const ProjectConfigAreaForm: FC<Props> = ({
  areaTypeList,
  initialValues,
  onFinish,
  ...props
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ProjectConfigAreaFormValues>();
  const [selectedAreaType, setSelectedAreaType] = useState<AreaType | undefined>(initialValues?.type || AreaType.VERTICAL);
  const lastInHorizontalsListRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);

  const isInEditMode = !!initialValues?.type;

  const scrollToNewHorizontal = () => {
    setTimeout(() => lastInHorizontalsListRef.current?.scrollIntoView({ behavior: 'smooth' }), 0);
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      {...props}>
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <Form.Item
            label={t('_PROJECT_LAYOUT_CONFIG_FORM_LABEL_AREA_TYPE')}
            name={'type'}
            initialValue={initialValues?.type || AreaType.VERTICAL}
            rules={[{ required: true, message: t('field_required'), type: 'string' }]}
          >
            <Select
              onChange={(type) => setSelectedAreaType(type)}
              disabled={initialValues?.type !== undefined}
            >
              {areaTypeList.map((areaType, key) => (
                <Select.Option
                  key={key}
                  value={areaType}
                  disabled={areaType === AreaType.HORIZONTAL}
                >
                  {t(`${areaType}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={t('_PROJECT_LAYOUT_CONFIG_FORM_LABEL_NAME_ES')}
            name={'nameEs'}
            initialValue={initialValues?.nameEs || ''}
            rules={[{ required: true, message: t('field_required'), type: 'string' }]}
          >
            <Input placeholder={`${t('_PROJECT_LAYOUT_CONFIG_FORM_PLACEHOLDER_NAME_ES')}`} autoFocus />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={t('_PROJECT_LAYOUT_CONFIG_FORM_LABEL_NAME_EN')}
            name={'nameEn'}
            rules={[{ required: true, message: t('field_required'), type: 'string' }]}
            initialValue={initialValues?.nameEn || ''}
          >
            <Input placeholder={`${t('_PROJECT_LAYOUT_CONFIG_FORM_PLACEHOLDER_NAME_EN')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            label={(
              <>
                <span className={'ProjectConfigAreaForm__image-info'}>{'Imagen'}</span>
                <Tooltip
                  title={t('_PROJECT_LAYOUT_CONFIG_FORM_IMAGE_RESTRICTION_INFO_TEXT')}
                  overlayInnerStyle={{ width: 210, textAlign: 'center', marginLeft: 55 }}>
                  <Icon.InfoCircleFilled />
                </Tooltip>
              </>
            )}
            name={'image'}
          >
            <ImagePickerDisplay
              onChange={() => null}
              temporaryImageUrl={''}
              initialValues={initialValues?.imageURL || ''}
              sizeLimit={1}
              acceptedTypes={['jpg', 'jpeg', 'png']}
            />
          </Form.Item>
        </Col>
      </Row>
      {selectedAreaType === AreaType.VERTICAL &&
        <div className={isInEditMode ? 'ProjectConfigAreaForm__horizontalConfig-hidden' : ''}>
          <Divider />
          <div className={'ProjectConfigAreaForm__horizontalConfig-title'}>
            <TextAtom style={{ color: COLORS.text }}>{t('_PROJECT_LAYOUT_CONFIG_FORM_HORIZONTAL_HEADER_TEXT')}</TextAtom>
          </div>
          <Row gutter={16}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.List name="horizontal" initialValue={initialValues?.horizontal || []}>
                {(fields, { add, remove }) => (
                  <div className={'ProjectConfigAreaForm__horizontalConfig-item'}>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Row
                        key={key}
                        gutter={24}
                        className={'ProjectConfigAreaForm__horizontalConfig-item-content'}
                      >
                        {index === fields.length - 1 && <div ref={lastInHorizontalsListRef} />}
                        <Col lg={12} md={12} sm={24} xs={24}>
                          <Form.Item
                            name={[name, 'nameEs']}
                            initialValue={''}
                            className={'ProjectConfigAreaForm__horizontalConfig-item-input'}
                            rules={[{ required: true, message: t('field_required'), type: 'string' }]}
                            {...restField}
                          >
                            <Input placeholder={t("_PROJECT_LAYOUT_CONFIG_FORM_PLACEHOLDER_NAME_ES")} autoFocus />
                          </Form.Item>
                          <Form.Item
                            name={[name, 'nameEn']}
                            initialValue={''}
                            rules={[{ required: true, message: t('field_required'), type: 'string' }]}
                            {...restField}
                          >
                            <Input placeholder={t("_PROJECT_LAYOUT_CONFIG_FORM_PLACEHOLDER_NAME_EN")} />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                          <Form.Item
                            name={[name, 'imageBASE64']}
                            initialValue={''}
                            {...restField}
                          >
                            <ImagePickerDisplay
                              onChange={() => null}
                              temporaryImageUrl={''}
                              sizeLimit={1}
                              acceptedTypes={['jpg', 'jpeg', 'png']}
                              disabled={false}
                            />
                          </Form.Item>
                        </Col>
                        <Col className={'ProjectConfigAreaForm__delete-button'}>
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(name);
                              window.scrollBy(0, 10);
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                    <div className={'ProjectConfigAreaForm__add-button'}>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                          scrollToNewHorizontal();
                        }} icon={<PlusOutlined />}
                        block
                      >
                        {t('_PROJECT_LAYOUT_CONFIG_ADD_NEW_HORIZONTAL_BUTTON_TEXT')}
                      </Button>
                    </div>
                  </div>
                )}
              </Form.List>
            </Col>
          </Row>
        </div>
      }
    </Form>
  );
};