import { getProjectTabInfoPath } from 'core/endspoints/projects';
import api from 'services/api';
import { payloadToProjectAssetsResponse } from '../mappers/payloadToProjectAssets';
import { PayloadProjectAssetsResponseModel } from '../model/projectPayloadModel';
import { ProjectAssetsResponseEntity } from '../entities/projectAssetsEntity';

export const getProjectTabInfoWithPagination = async ({
  projectId,
  page,
  size,
  search,
}: {
  projectId: string;
  page?: number;
  size?: number;
  search?: string;
}) => {
  const url = getProjectTabInfoPath({ projectId });
  api.defaults.headers.common['Accept-version'] = '1';
  const params = new URLSearchParams();
  page && params.append('page[number]', String(page));
  size && params.append('page[size]', String(size));
  search && params.append('search', String(search));

  try {
    const response: PayloadProjectAssetsResponseModel = await api.get(url, { params });
    const mappedResponse = payloadToProjectAssetsResponse(response);
    return ProjectAssetsResponseEntity(mappedResponse);
  } catch (error: any) {
    throw new Error(error);
  }
};
