import { FC } from 'react';
import { FormInstance } from 'antd';
import { ProjectAppCustomizationFormValues, ProjectAppCustomizationInputValue, ProjectAppCustomizationTranslations } from 'components/pages/ProjectPage/Tabs/TabCustomization/SubTabs/SubTabAppAndWebApp/resources/models';
import ProjectAppCustomizationForm from 'components/molecules/ProjectAppCustomizationForm';
import ProjectAppCustomizationPreView from 'components/molecules/ProjectAppCustomizationPreview';
import './styles.scss';

interface Props {
  initialFormValues: ProjectAppCustomizationFormValues;
  customizationFormValues: ProjectAppCustomizationFormValues;
  customizationPreviewValues: ProjectAppCustomizationFormValues;
  translations: ProjectAppCustomizationTranslations;
  Form: any;
  form: FormInstance<ProjectAppCustomizationFormValues>
  onChangeMade: ({ label, value }: ProjectAppCustomizationInputValue) => void;
  onCancel: () => void;
  onPublish: () => void;
  disabled: boolean;
  updating: boolean;
  canceled: boolean;
  cancelButtonDisabled: boolean;
}

const ProjectAppCustomizationTemplate: FC<Props> = ({
  initialFormValues,
  customizationFormValues,
  customizationPreviewValues,
  translations,
  Form,
  form,
  onChangeMade,
  onCancel,
  onPublish,
  disabled,
  updating,
  canceled,
  cancelButtonDisabled,
}) => {

  return (
    <div className={'AppCustomizationTemplate__container'}>
      <div className={'AppCustomizationTemplate__form'}>
        <span className={'AppCustomizationTemplate__title'}>{translations.title}</span>
        <ProjectAppCustomizationForm
          translations={translations}
          customizationFormValues={customizationFormValues}
          initialValues={initialFormValues}
          Form={Form} form={form}
          onChangeMade={onChangeMade}
          onCancel={onCancel}
          onPublish={onPublish}
          disabled={disabled}
          updating={updating}
          canceled={canceled}
          cancelButtonDisabled={cancelButtonDisabled}
        />
      </div>
      <div className={'AppCustomizationTemplate__preview'}>
        <ProjectAppCustomizationPreView previewValues={customizationPreviewValues} />
      </div>
    </div>
  )
}

export default ProjectAppCustomizationTemplate;