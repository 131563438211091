import { FC } from 'react';
import ModalAtom, { ModalAtomProps } from 'components/atoms/ModalAtom';
import SpaceAtom from 'components/atoms/SpaceAtom';
import TextButton from 'components/atoms/Button/TextButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';

interface Props extends ModalAtomProps {
  text: string;
  title: string;
  mainButtonText: string;
  secondaryButton: string;
  onClick: () => void;
}

const ConfirmUnAssignGatewayModal: FC<Props> = ({ onClick, text, title, mainButtonText, secondaryButton, ...props }) => {
  return (
    <ModalAtom
      {...props}
      title={<SpaceAtom>{title}</SpaceAtom>}
      footer={[
        <TextButton key={'back'} onClick={props.onCancel} title={secondaryButton} />,
        <PrimaryButton key={'submit'} htmlType={'submit'} title={mainButtonText} onClick={onClick} />,
      ]}
      destroyOnClose
    >
      <p>{text}</p>
    </ModalAtom>
  );
};

export default ConfirmUnAssignGatewayModal;
