import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScript } from 'hooks/useScript';
import AlfredMainTemplate from 'components/template/AlfredMainTemplate';
import './styles.scss';

const TicketsPage: FC = () => {
  const { t } = useTranslation('global');
  const [formCreated, setFormCreated] = useState(false);
  const [loaded] = useScript('https://js.hsforms.net/forms/v2.js');

  useEffect(() => {
    if ((loaded && (process as any)).browser) {
      const windowWithHubspot: WindowWithHubspot = window;
      if (windowWithHubspot.hbspt && !formCreated) {
        windowWithHubspot.hbspt.forms.create({
          target: '#TicketsPage__embed',
          portalId: '7074878',
          formId: 'f25c80ca-75a2-40c9-b85a-674d7c1e5f16',
        });
        setFormCreated(true);
      }
    }
  }, [formCreated, loaded]);

  return (
    <AlfredMainTemplate title={t('create_ticket')}>
      {formCreated && <div className='TicketsPage__form' id='TicketsPage__embed'></div>}
    </AlfredMainTemplate>
  );
};

export default TicketsPage;
