import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select, Input, FormProps, message } from 'antd';
// import getRoleUser 'services/network/users';
import { UserRole } from 'models/users.model';
import { getAssets } from 'services/api/assets';
import { hasPermission } from 'services/permissions';
import DebounceSelect from 'components/atoms/DebounceSelect';
import { UserInvitation } from 'models/users.model';
import { GetClientsList } from 'core/domain/client/repositories/getClientsList';

interface Options {
  value: string;
  label: string;
}

// TODO: Por parte de backend. Terminar de habilitar todos los roles para las invitaciones.
const getRoles = () => {
  const roles: UserRole[] = [];

  hasPermission('invite_users_role_user') && roles.push('ROLE_USER');
  hasPermission('invite_users_role_expert') && roles.push('ROLE_ALFRED_EXPERT');
  hasPermission('invite_users_role_property_manager') && roles.push('ROLE_ASSETS_MANAGER_PROPERTY_MANAGER');

  return roles;
};

const UserInvitationForm: FC<FormProps> = ({ onFinish, ...props }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [visibleSelectedClient, setVisibleSelectedClient] = useState(false);
  const [visibleSelectedAsset, setVisibleSelectedAsset] = useState(false);
  // const [roles, setRoles] = useState<UserRole[]>([]);
  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingAsset, setLoadingAsset] = useState(false);
  const [valueAssets, setValueAssets] = useState<string[]>([]);
  const [valueClients, setValueClients] = useState<string[]>([]);
  const [optionsClient, setOptionsClient] = useState<Options[]>([]);
  const [optionsAsset, setOptionsAsset] = useState<Options[]>([]);

  // useEffect(() => {
  //   if (props.visible) {
  //     setLoading(true);
  //     form.resetFields();
  //     getRoleUser()
  //       .then((UserRoles) => {
  //         setRoles(UserRoles);
  //       })
  //       .finally(() => setLoading(false));
  //   }
  // }, [form, props.visible]);

  const handleOnFinish = (values: UserInvitation) => {
    onFinish && onFinish(values);
    setVisibleSelectedClient(false);
    setVisibleSelectedAsset(false);
  };

  const handleOnClientSearch = async (query: string) => {
    try {
      setLoadingClient(true);
      const data = await GetClientsList(query);
      const clients = data?.map((client) => ({
        label: client.name,
        value: client.id,
      }));
      clients && setOptionsClient(clients);
    } finally {
      setLoadingClient(false);
    }
  };

  const handleOnAssetSearch = async (query: string) => {
    try {
      setLoadingAsset(true);
      const { data } = await getAssets({ search: query });
      if (!!data.length) {
        const assets = data.map((asset) => ({
          label: `${asset.street_type} ${asset.street_name} ${asset.block_number}, ${asset.apartment}, ${asset.zip_code}, ${asset.city}, ${asset.country}`,
          value: asset.id,
        }));
        setOptionsAsset(assets);
        return;
      }
      message.warning(t('no_results'), 5);
    } finally {
      setLoadingAsset(false);
    }
  };

  const handleOnVisibleInputs = (value: UserRole) => {
    if (value === 'ROLE_ALFRED_EXPERT') {
      setVisibleSelectedClient(false);
      setVisibleSelectedAsset(false);
    }
    if (value === 'ROLE_ASSETS_MANAGER_PROPERTY_MANAGER') {
      setVisibleSelectedClient(true);
      setVisibleSelectedAsset(false);
    }
    if (value === 'ROLE_USER') {
      setVisibleSelectedAsset(true);
      setVisibleSelectedClient(false);
    }
  };

  const ROLES = getRoles();

  return (
    <Form {...props} onFinish={handleOnFinish} form={form} layout='vertical' name='control-hooks'>
      <Form.Item name='email' label={`${t('email')}:`} rules={[{ required: true, type: 'email', message: t('email_valid') }]} hasFeedback>
        <Input />
      </Form.Item>
      <Form.Item
        name='role'
        label={`${t('rol_user')}:`}
        rules={[{ required: true, type: 'string', message: t('required_field') }]}
        hasFeedback
      >
        <Select<UserRole> placeholder={t('select_a_option')} allowClear onChange={(value) => handleOnVisibleInputs(value)}>
          {ROLES.map((rol, key) => {
            return (
              <Select.Option key={key} value={rol}>
                {t(`ROLES.${rol}`)}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      {visibleSelectedClient && (
        <Form.Item
          name='client_id'
          label={`${t('company')}:`}
          rules={[{ required: visibleSelectedClient, type: 'string', message: t('required_field') }]}
          hasFeedback
        >
          <DebounceSelect
            loading={loadingClient}
            value={valueAssets}
            onChange={(newClient: string[]) => {
              setValueClients(newClient);
            }}
            options={optionsClient}
            onSearch={handleOnClientSearch}
            placeholder={t('DEBOUNCE_SELECT.placeholder_client')}
          />
        </Form.Item>
      )}
      {visibleSelectedAsset && (
        <Form.Item
          name='asset_id'
          label={`${t('asset')}: (${t('search_by_the_address_of_the_facility')})`}
          rules={[{ required: visibleSelectedAsset, type: 'string', message: t('required_field') }]}
          hasFeedback
        >
          <DebounceSelect
            loading={loadingAsset}
            value={valueClients}
            onChange={(newAsset: string[]) => {
              setValueAssets(newAsset);
            }}
            options={optionsAsset}
            onSearch={handleOnAssetSearch}
            placeholder={t('DEBOUNCE_SELECT.placeholder_asset')}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default UserInvitationForm;
