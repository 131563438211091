import { FC } from 'react';
import { Row, Col, Space, Form, Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { FormInstance } from 'antd/es/form/Form';
import Paper from 'components/atoms/Paper';
import { SensorWithPasswordsModel } from 'core/domain/sensors/models/sensorEntityModels';
import LinkButton from 'components/atoms/Button/LinkButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import './styles.scss';
import { AccessListModel } from '../resources/useAccess';

interface AccessProps {
  devices: AccessListModel[];
  accessColumn: ColumnType<AccessListModel>[];
  form: FormInstance<any>;
  title: string;
  createInvitationButtonText: string;
  createInvitationInfoText: string;
  listInvitationsButtonText: string;
  listInvitationsInfoText: string;
  createBetaInvitationButtonText: string;
  onOpenCreateInvitation: () => void;
  onOpenInvitationsListModal: () => void;
  onOpenCreateBetaInvitationModal: () => void;
  assignRoomButtonText: string;
  updateButtonText: string;
  onRowClick: (device: SensorWithPasswordsModel) => void;
  onClickAssignRoom: () => void;
  onRefresh: () => void;
  loading: boolean;
  isCreateAndListInvitationsForbidden: boolean;
  isAssignRoomButtonVisible: boolean;
}

const Access: FC<AccessProps> = ({
  devices,
  accessColumn,
  form,
  title,
  createInvitationButtonText,
  createInvitationInfoText,
  createBetaInvitationButtonText,
  listInvitationsButtonText,
  listInvitationsInfoText,
  onOpenCreateInvitation,
  onOpenInvitationsListModal,
  onOpenCreateBetaInvitationModal,
  assignRoomButtonText,
  updateButtonText,
  onRowClick,
  onClickAssignRoom,
  onRefresh,
  loading,
  isCreateAndListInvitationsForbidden,
  isAssignRoomButtonVisible,
}) => {

  return (
    <Paper>
      <h2>{title}</h2>
      {!isCreateAndListInvitationsForbidden && (
        <Row justify={'start'} align={'top'} className='TabUsers__headers'>
          <Col className={'Access__buttons'}>
            <Space>
              <Tooltip placement={'top'} title={createInvitationInfoText}>
                <DefaultButton onClick={onOpenCreateInvitation} title={createInvitationButtonText} />
              </Tooltip>
              <Tooltip placement={'top'} title={listInvitationsInfoText}>
                <DefaultButton onClick={onOpenInvitationsListModal} title={listInvitationsButtonText} />
              </Tooltip>
              <DefaultButton onClick={onOpenCreateBetaInvitationModal} title={createBetaInvitationButtonText} />
            </Space>
            {isAssignRoomButtonVisible && (
              <Space>
                <DefaultButton onClick={onClickAssignRoom} title={assignRoomButtonText} />
                <LinkButton onClick={onRefresh} title={updateButtonText} />
              </Space>
            )}
          </Col>
        </Row>
      )}
      <Form form={form} component={false}>
        <Table<AccessListModel>
          size={'middle'}
          key={devices.length}
          loading={loading}
          columns={accessColumn}
          onRow={(item) => {
            return {
              onClick: () => onRowClick({ ...item }),
            };
          }}
          rowKey={'deviceName'}
          dataSource={devices}
          pagination={false}
        />
      </Form>
    </Paper>
  );
};

export default Access;
