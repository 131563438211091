import { FC } from 'react';
import { UsersTranslationsTypes } from 'components/pages/DashboardPage/resources/translations';
import Error from 'assets/svg/icon/Error';
import './styles.scss';

interface Props {
  translations: UsersTranslationsTypes;
}

const DashboardUsersErrorTemplate: FC<Props> = ({ translations }) => {
  const { TITLE, TITLE_TIME_PERIOD, ERROR_TITLE, ERROR_DESCRIPTION } = translations;

  return (
    <div className='UsersErrorTemplate__container'>
      <div className='UsersErrorTemplate__header'>
        <p className='UsersErrorTemplate__title'>{TITLE}</p>
        <p className='UsersErrorTemplate__subtitle'>{TITLE_TIME_PERIOD}</p>
      </div>
      <div className='UsersErrorTemplate__message-container'>
        <div className='UsersErrorTemplate__message-content'>
          <Error />
          <p className='UsersErrorTemplate__message-title'>{ERROR_TITLE}</p>
          <p className='UsersErrorTemplate__message-description'>{ERROR_DESCRIPTION}</p>
        </div>
      </div>
    </div>
  );
}

export default DashboardUsersErrorTemplate;