import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CommonAreaFormGrid from 'components/molecules/CommonAreaForm/components/CommonAreaFormGrid';
import { SensorByProjectModel } from 'core/domain/sensors/models/sensorEntityModels';
import { FormValues, FormValuesResponse } from 'components/molecules/CommonAreaForm/models';
import { useCreateCommonAreasForm } from 'components/molecules/CommonAreaForm/hooks/useCreateCommonAreasForm';
import { CommonAreaModel } from 'core/domain/bookingsAndCommonAreas/models/commonAreaEntityModels';
import './styles.scss';

interface CommonAreaFormCreateTemplateProps {
  commonAreaData: CommonAreaModel;
  sensorOptionList: SensorByProjectModel[];
  onCreateArea: (values: FormValuesResponse) => void;
  onInvisibleCreate: () => void;
  isSaving: boolean;
}

const CommonAreaFormCreateTemplate: FC<CommonAreaFormCreateTemplateProps> = ({
  commonAreaData,
  sensorOptionList,
  onCreateArea,
  onInvisibleCreate,
  isSaving,
}) => {
  const { t } = useTranslation();
  const {
    bookingDurationOptionList,
    errors,
    form,
    Form,
    selectedDays,
    timeAvailability,
    onChangeTimeAvailabilityInterval,
    onParseValues,
    onSelectBookingDuration,
    onSelectDay,
    onSelectSensors,
    onUnselectDay,
    onAddTimeAvailabilityInterval,
    onDeleteTimeAvailabilityInterval,
    getTimeAvailabilityErrorMessage,
    onChangeBackgroundImage,
  } = useCreateCommonAreasForm();

  const handleOnFinish = (values: FormValues) => {
    const parsedValues = onParseValues(values);
    parsedValues && onCreateArea(parsedValues);
  };

  return (
    <div className={'CommonAreaFormAddTemplate__container'}>
      <div className={'CommonAreaFormAddTemplate__required-info'}>
        <p>{t('_COMMON_AREA_REQUIRED_FIELDS')}</p>
      </div>
      <CommonAreaFormGrid
        bookingDurationOptionList={bookingDurationOptionList}
        errors={errors}
        form={form}
        Form={Form}
        selectedDays={selectedDays}
        sensorOptionList={sensorOptionList}
        timeAvailability={timeAvailability}
        onChangeTimeAvailability={onChangeTimeAvailabilityInterval}
        onClose={onInvisibleCreate}
        onFinish={({ values }) => handleOnFinish(values)}
        onSelectBookingDuration={onSelectBookingDuration}
        onSelectDay={onSelectDay}
        onSelectSensors={onSelectSensors}
        onUnselectDay={onUnselectDay}
        onAddTimeAvailabilityInterval={onAddTimeAvailabilityInterval}
        onDeleteTimeAvailabilityInterval={onDeleteTimeAvailabilityInterval}
        getTimeAvailabilityErrorMessage={getTimeAvailabilityErrorMessage}
        onChangeBackgroundImage={onChangeBackgroundImage}
        isSaving={isSaving}
      />
    </div>
  );
};

export default CommonAreaFormCreateTemplate;
