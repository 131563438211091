import { useState, useEffect } from 'react';

export const useTab = (currentTab: string) => {
  const [activeTab, setActiveTab] = useState(currentTab);

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  return { activeTab }
}