import { useEffect, useState } from "react";
import { UserListColumns } from "./UserListColumns";
import { hasPermission } from "services/permissions";
import { UserPermissionType } from "models/users.model";
import { usePremiumUser } from "hooks/usePremiumUser";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TOTAL_PAGES = 1000;
const PAGE_SIZE = 1000;
const PAGE_CURRENT = 1;
const PAGINATION_SHOWED = false;

export const useTabBillableUserList = () => {
  const { t } = useTranslation();
  const { clientId } = useParams<{ clientId: string }>();
  const { dataList, getPremiumUsers, loading } = usePremiumUser();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const isAddExpertButtonVisible = hasPermission(UserPermissionType.INVITE_USERS_ROLE_EXPERTS);

  const addBillableUsersButton = t('SETTINGS_CLIENT_TAB_ADD_BILLABLE_USER_BUTTON');

  const columns = UserListColumns();

  useEffect(() => {
    getPremiumUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const onClick = () => {
    setIsModalVisible(true);
  };

  const onVisible = () => {
    setIsModalVisible(false);
  };

  const onPagination = () => null;
  const onRowClick = () => null;

  return {
    clientId,
    addBillableUsersButton,
    columns,
    dataList,
    totalPages: TOTAL_PAGES,
    pageSize: PAGE_SIZE,
    pageCurrent: PAGE_CURRENT,
    onClick,
    onVisible,
    onPagination,
    onRowClick,
    paginationShowed: PAGINATION_SHOWED,
    isAddExpertButtonVisible,
    isModalVisible,
    isLoading: loading,
  }

}