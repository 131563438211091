import api from 'services/api';
import { getDocumentListByAssetIdPath } from 'core/endspoints/documents';
import { payloadToDocuments } from '../mappers/payloadToDocuments';
import { DocuemntPayloadModel } from '../model/documentPayloadModels';
import DocumentsEntityListValueObject from '../entities/documentsEntityListValueObject';

export const GetDocumentListByAssetId = async (assetId: string) => {
  const path = getDocumentListByAssetIdPath(assetId);
  const { data } = await api.get<DocuemntPayloadModel[]>(path);
  const documents = data.map((item) => payloadToDocuments(item));
  const { list } = DocumentsEntityListValueObject(documents);
  return list;
};
