import { CommonAreaModel } from '../models/commonAreaEntityModels';
import CommonAreaEntity from './commonAreaEntity';

const CommonAreasValueObject = (
  list: CommonAreaModel[]
): Readonly<{
  list: Readonly<CommonAreaModel>[];
}> => {
  return Object.freeze({
    list: list.map((item: CommonAreaModel) => CommonAreaEntity(item)),
  });
};

export default CommonAreasValueObject;
