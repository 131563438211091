import { RouteConfig } from 'react-router-config';
import SuperSearchPage from 'components/pages/SuperSearchPage';
import { ROUTE_PATH_SUPER_SEARCH } from './config';

export const superSearchRoutes: RouteConfig[] = [
  {
    path: ROUTE_PATH_SUPER_SEARCH,
    exact: true,
    component: SuperSearchPage,
  },
];
