import { CurrentUserModel } from '../models/userModel';
import { PayloadCurrentUser } from '../models/userPayloadModel';

export const payloadToCurrentUser = ({
  id,
  username,
  email,
  type,
  roles,
  lang,
  status,
  created_at,
  confirmed_at,
  assets,
  client,
}: PayloadCurrentUser): CurrentUserModel => {
  return {
    id: id ?? '',
    username: username ?? '',
    email: email ?? '',
    type: type ?? null,
    roles: roles ?? [],
    lang: lang ?? '',
    status: status ?? null,
    createdAt: created_at ?? 0,
    confirmedAt: confirmed_at ?? null,
    assets: assets ?? [],
    client: client
      ? {
          id: client.client_id ?? '',
          assetsManagerLogo: client.assets_manager.logo ?? '',
          assetsManagerBackgroundColor: client.assets_manager.header_background_color ?? '',
        }
      : null,
  };
};
