import { FC } from 'react';
import { Descriptions } from 'antd';

interface HeaderDescriptionProps {
  clientEmailLabel: string;
  clientDescriptionLabel: string;
  totalNumberUsersClientLabel: string;
  clientEmail: string;
  clientDescription: string;
  totalNumberUsersClient: string;
}

const HeaderDescription: FC<HeaderDescriptionProps> = ({
  clientDescription,
  clientDescriptionLabel,
  clientEmail,
  clientEmailLabel,
  totalNumberUsersClient,
  totalNumberUsersClientLabel
}) => (
  <Descriptions column={2} size='small'>
    <Descriptions.Item key='email' label={<b>{clientEmailLabel}</b>}>
      {clientEmail}
    </Descriptions.Item>
    <Descriptions.Item key='description' label={<b>{clientDescriptionLabel}</b>}>
      {clientDescription}
    </Descriptions.Item>
    <Descriptions.Item key='description' label={<b>{totalNumberUsersClientLabel}</b>}>
      {totalNumberUsersClient}
    </Descriptions.Item>
  </Descriptions>
);

export default HeaderDescription;
