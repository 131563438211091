export const downloadCsvFile = (data: string, fileName: string = 'data') => {
  const downloadLink = document.createElement("a");
  const blob = new Blob(["\ufeff", data]);
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `${fileName}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
