import QrScanner from 'qr-scanner';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

QrScanner.WORKER_PATH = '/qr-scanner-worker.min.js';

interface QRScannerProps {
  onError?: (error: string) => void;
  onScan: (qr: string) => void;
}

const QRScanner: FC<QRScannerProps> = ({ onError, onScan }) => {
  const { t } = useTranslation();
  const [hasCamera, setHasCamera] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = (videoRef?.current as unknown) as HTMLVideoElement;

    const qrScanner = new QrScanner(
      videoElement,
      (result) => {
        onScan(result);
        qrScanner.stop();
        qrScanner.destroy();
      },
      (error: any) => {
        if (error !== 'No QR code found') onError && onError(error);
      }
    );
    qrScanner.start();

    return () => qrScanner.destroy();
  }, [onError, onScan]);

  useEffect(() => {
    QrScanner.hasCamera().then((value) => setHasCamera(value));
  }, []);

  return <>{hasCamera ? <video className='QRScanner' ref={videoRef} /> : <p>{t('there_isnt_camera')}</p>}</>;
};

export default QRScanner;
