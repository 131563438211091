import { useState } from 'react';

interface ConfigModal {
  title: string;
  text: string;
  buttonSuccess: string;
  onConfirm: () => void;
}

export const useModalStatus = () => {
  const [data, setData] = useState<any>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [configModal, setConfigModal] = useState<ConfigModal>({} as ConfigModal);

  const onLoadAndConfigModal = <Z>(loadValues: Z, config: ConfigModal): void => {
    setData(loadValues);
    setConfigModal(config);
    setIsVisible(true);
  };

  const onConfirm = async <T>(callback: (values: T) => void): Promise<void> => {
    setIsLoading(true);
    try {
      callback(data);
    } finally {
      setIsVisible(false);
      setIsLoading(false);
    }
  };

  const onCancel = (): void => {
    setIsVisible(false);
  };

  return { configModal, onLoadAndConfigModal, onConfirm, onCancel, isVisible, isLoading };
};
