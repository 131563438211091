import { RouteConfig } from 'react-router-config';
import AccessesClientAndProjectSelectorPage from 'components/pages/AccessesPage';
import AccessesCheckInCheckOutListPage from 'components/pages/AccessesVerticals/hotel/AccessesRoomListTab';
import RentComingSoonPage from 'components/pages/AccessesVerticals/rent/ComingSoonTab';
import HomeComingSoonPage from 'components/pages/AccessesVerticals/home/ComingSoonTab';
import BuildingComingSoonPage from 'components/pages/AccessesVerticals/building/ComingSoonTab';
import HotelRoomComingSoonPage from 'components/pages/AccessesVerticals/hotelRoom/ComingSoonTab';
import ParkingSlotComingSoonPage from 'components/pages/AccessesVerticals/parkingSlot/ComingSoonTab';
import VacationalRentalComingSoonPage from 'components/pages/AccessesVerticals/vacationalRental/ComingSoonTab';
import CheckInListTab from 'components/pages/AccessesVerticals/hotel/CheckInListTab';
import AccessesLogListTab from 'components/pages/AccessesVerticals/office/AccessesLogListTab';
import {
  ROUTE_PATH_ACCESSES_CLIENT_AND_PROJECT_SELECTOR,
  ROUTE_PATH_BUILDING_ACCESSES_ROOM_LIST,
  ROUTE_PATH_HOME_ACCESSES_ROOM_LIST,
  ROUTE_PATH_HOTEL_ACCESSES_ROOM_LIST,
  ROUTE_PATH_HOTEL_CHECK_IN_LIST,
  ROUTE_PATH_HOTEL_ROOM_ACCESSES_ROOM_LIST,
  ROUTE_PATH_HOTEL_ACCESS_DEVICES_LIST,
  ROUTE_PATH_OFFICE_ACCESSES_LOG_LIST,
  ROUTE_PATH_PARKING_SLOT_ACCESSES_ROOM_LIST,
  ROUTE_PATH_RENT_ACCESSES_ROOM_LIST,
  ROUTE_PATH_VACATIONAL_RENTAL_ACCESSES_ROOM_LIST,
  ROUTE_PATH_OFFICE_ACCESS_DEVICES_LIST,
} from './config';
import AccessDevicesListTab from 'components/pages/AccessesVerticals/hotel/AccessDevicesListTab';

export const accessesRoutes: RouteConfig = {
  path: ROUTE_PATH_ACCESSES_CLIENT_AND_PROJECT_SELECTOR,
  component: AccessesClientAndProjectSelectorPage,
  routes: [
    {
      path: ROUTE_PATH_HOTEL_ACCESSES_ROOM_LIST,
      exact: true,
      component: AccessesCheckInCheckOutListPage,
    },
    {
      path: ROUTE_PATH_RENT_ACCESSES_ROOM_LIST,
      exact: true,
      component: RentComingSoonPage,
    },
    {
      path: ROUTE_PATH_HOTEL_CHECK_IN_LIST,
      exact: true,
      component: CheckInListTab,
    },
    {
      path: ROUTE_PATH_OFFICE_ACCESSES_LOG_LIST,
      exact: true,
      component: AccessesLogListTab,
    },
    {
      path: ROUTE_PATH_HOME_ACCESSES_ROOM_LIST,
      exact: true,
      component: HomeComingSoonPage,
    },
    {
      path: ROUTE_PATH_BUILDING_ACCESSES_ROOM_LIST,
      exact: true,
      component: BuildingComingSoonPage,
    },
    {
      path: ROUTE_PATH_HOTEL_ROOM_ACCESSES_ROOM_LIST,
      exact: true,
      component: HotelRoomComingSoonPage,
    },
    {
      path: ROUTE_PATH_PARKING_SLOT_ACCESSES_ROOM_LIST,
      exact: true,
      component: ParkingSlotComingSoonPage,
    },
    {
      path: ROUTE_PATH_VACATIONAL_RENTAL_ACCESSES_ROOM_LIST,
      exact: true,
      component: VacationalRentalComingSoonPage,
    },
    {
      path: ROUTE_PATH_HOTEL_ACCESS_DEVICES_LIST,
      exact: true,
      component: AccessDevicesListTab,
    },
    {
      path: ROUTE_PATH_OFFICE_ACCESS_DEVICES_LIST,
      exact: true,
      component: AccessDevicesListTab,
    },
  ],
};
