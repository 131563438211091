import { IntercomModel } from '../model/intercomModel';

export const IntercomEntity = ({
  id,
  vendor,
  projectId,
  name,
  availableIntercomVendors,
  deepLinks,
  remote,
  local,
  model,
  comments,
  serialNumber,
  handlerId,
  rooms,
}: IntercomModel): Readonly<IntercomModel> => {
  return Object.freeze({
    id,
    vendor,
    projectId,
    name,
    availableIntercomVendors,
    deepLinks,
    remote,
    local,
    model,
    comments,
    serialNumber,
    handlerId,
    rooms,
  });
};

export const IntercomsEntityListValueObject = (
  list: IntercomModel[]
): Readonly<{
  list: Readonly<IntercomModel>[];
}> => {
  return Object.freeze({ list: list.map((item) => IntercomEntity(item)) });
};
