import { AccessKeys, LocalStorageAccessKeys } from "../models";

export const getLastClientIdAndProjectId = (): AccessKeys => {
  const tab = localStorage.getItem(LocalStorageAccessKeys.TAB);
  const clientId = localStorage.getItem(LocalStorageAccessKeys.CLIENT_ID);
  const projectId = localStorage.getItem(LocalStorageAccessKeys.PROJECT_ID);
  const projectType = localStorage.getItem(LocalStorageAccessKeys.PROJECT_TYPE);

  return {
    tab: tab ?? undefined,
    clientId: clientId ?? undefined,
    projectId: projectId ?? undefined,
    projectType: projectType ?? undefined,
  }
}
