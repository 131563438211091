interface RequiredFormValues {
  backgroundColor: string;
}
export interface ClientCustomizationFormValues extends RequiredFormValues {
  logo: string;
}

export enum ClientCustomizationFormValuesNames {
  LOGO = 'logo',
  BACKGROUND_COLOR = 'backgroundColor',
}

export interface ClientCustomizationInputValue {
  label: ClientCustomizationFormValuesNames;
  value: string;
}

export interface ClientCustomizationTranslations {
  logo: string;
  logoRequirements: string;
  backgroundColor: string;
  info: string;
  cancel: string;
  publish: string;
  published: string;
}
