import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { User } from 'models/users.model';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constants/date';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import AvatarUser from 'components/atoms/AvatarUser';
import TableActionsContent from 'components/organisms/TableActionsContent';
import { TableActionsMenuProps } from 'components/atoms/TableAtom';
import './styles.scss';

interface UsersTabColumnsOptions {
  usersTableActionsMenu: TableActionsMenuProps[];
}

export const UsersTabColumns = ({ usersTableActionsMenu }: UsersTabColumnsOptions): Array<AlfredTableColumn<User>> => {
  const { t } = useTranslation();

  return [
    {
      title: t('username'),
      key: 'username',
      width: 250,
      render: (text, record) => (
        <Space>
          <AvatarUser letter={record.username!.toLowerCase() !== 'unknown' ? record.username[0] : undefined} />
          <div className='UsersTabColumns__cell'>
            <b>{record.username!.toLowerCase() === 'unknown' ? t('unknown') : record.username}</b>
            <span className='Text__date Text__secondary'>{moment.unix(Number(record.created_at)).format(DATE_TIME_FORMAT)}</span>
          </div>
        </Space>
      ),
    },
    {
      title: t('email'),
      key: 'email',
      width: 250,
      render: (text, record) => record.email,
    },
    {
      title: t('roles'),
      key: 'roles',
      width: 200,
      render: (text, record) => (
        <div className='UsersTabColumns__cell'>
          {record.roles.map((role: string, index: number) => {
            return <span key={index}>{t(`ROLES.${role}`)}</span>;
          })}
        </div>
      ),
    },
    {
      title: t('confirmed_at'),
      key: 'confirmed_at',
      width: 200,
      render: (text, record) => {
        return !record.confirmed_at ? (
          <span>{t('pending')}</span>
        ) : (
          <span>{moment.unix(Number(record.created_at)).format(DATE_TIME_FORMAT)}</span>
        );
      },
    },
    {
      title: t('actions'),
      key: 'action',
      width: 150,
      align: 'left',
      render: (text, record) => (
        <div key={record.id} onClick={(event) => event.stopPropagation()}>
          <TableActionsContent menu={usersTableActionsMenu} id={record.id} />
        </div>
      ),
    },
  ];
};
