import { FC } from 'react';
import TextButton from 'components/atoms/Button/TextButton';
import DividerAtom from 'components/atoms/DividerAtom';
import { useTranslation } from 'react-i18next';
import EventsAndNewsContentGrid from 'components/molecules/EventsAndNewsContentGrid';
import { EventsAndNewsModel } from 'core/domain/eventsAndNews/models/eventsAndNewsEntityModels';
import './styles.scss';

interface EventsAndNewsContentTemplateProps {
  eventsAndNewsData: EventsAndNewsModel;
  onVisibleUpdate: () => void;
}

enum TRANSLATION {
  modify = '_EVENTS_AND_NEWS_MODIFY',
}

const EventsAndNewsContentTemplate: FC<EventsAndNewsContentTemplateProps> = ({
  eventsAndNewsData,
  onVisibleUpdate,
}) => {
  const { t } = useTranslation();
  return (
    <div className={'EventsAndNewsContentTemplate__container'}>
      <EventsAndNewsContentGrid eventsAndNewsData={eventsAndNewsData} />
      <DividerAtom />
      <div className={'EventsAndNewsContentTemplate__actions'}>
        <TextButton title={t(TRANSLATION.modify)} onClick={onVisibleUpdate} />
      </div>
    </div>
  );
};

export default EventsAndNewsContentTemplate;
