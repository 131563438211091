import { ChartConfiguration } from 'chart.js';

export const configuration: ChartConfiguration = {
  type: 'pie',
  data: {
    labels: [] as string[],
    datasets: [
      {
        label: '',
        data: [] as number[],
        backgroundColor: ['#E9CB79', '#96C0C4'],
        hoverOffset: 0,
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: false,
      },
    },
  },
};
