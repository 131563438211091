import { FC } from 'react';
import { Switch } from 'antd';
import TabEmpty from 'components/molecules/TabEmpty';
import { LegalDocumentationEmptyTranslationsType } from 'components/pages/ClientDetailPage/TabLegalDocumentation/resources/config';
import './styles.scss';

interface Props {
  onCreate: () => void;
  emptyTranslations: LegalDocumentationEmptyTranslationsType;
  enabledSwitch: boolean;
}

const LegalDocumentationEmptyTemplate: FC<Props> = ({ onCreate, emptyTranslations, enabledSwitch }) => {
  const { title, infoTitle, infoDescription, infoButtonText } = emptyTranslations;
  return (
    <>
      <div className={'LegalDocumentationEmptyTemplate__switch'}>
        <h2>{title}</h2>
        <Switch checked={enabledSwitch} onChange={onCreate} loading={false} />
      </div>
      <div className={'LegalDocumentationEmptyTemplate__container'}>
        <div className={'LegalDocumentationEmptyTemplate__empty'}>
          <TabEmpty
            title={infoTitle}
            text={infoDescription}
            linkText={infoButtonText}
            onClick={onCreate}
          />
        </div>
      </div>
    </>
  );
};

export default LegalDocumentationEmptyTemplate;
